import React from 'react';
import styled from 'styled-components';
import CreateDeliveryZoneForm from './CreateDeliveryZoneForm';
import DeliveryZonesCreateShape from './DeliveryZoneCreateShape';
import { Button } from 'merchant-portal-components';
import {
  ComponentCard,
  HorizontalLine,
  ContainerDiv,
  GridWrapper,
} from '../../common/styledComponents';

const CreateDeliveryZoneContainer = styled.div`
  padding: 35px;
`;

let DeliveryZonesCreate = (props) => {
  const {
    deliveryZoneObjects,
    handleDeliveryZoneVisibility,
    editingColour,
    handleSaveChangesButtonClick,
    handleCreateDeliveryZoneFormSubmit,
    handleCancelButtonClick,
    locationCoords,
  } = props;

  return (
    <ComponentCard>
      <CreateDeliveryZoneForm
        handleCreateDeliveryZoneFormSubmit={handleCreateDeliveryZoneFormSubmit}
      />
      <HorizontalLine />
      <CreateDeliveryZoneContainer>
        <DeliveryZonesCreateShape
          deliveryZoneObjects={deliveryZoneObjects}
          handleDeliveryZoneVisibility={handleDeliveryZoneVisibility}
          editingColour={editingColour}
          locationCoords={locationCoords}
        />
      </CreateDeliveryZoneContainer>
      <ContainerDiv margin="35px 35px 0 35px">
        <GridWrapper>
          <ContainerDiv>
            <Button secondary onClick={() => handleCancelButtonClick()}>
              Cancel
            </Button>
          </ContainerDiv>
          <ContainerDiv align="right">
            <Button onClick={() => handleSaveChangesButtonClick()}>
              Save Changes
            </Button>
          </ContainerDiv>
        </GridWrapper>
      </ContainerDiv>
    </ComponentCard>
  );
};

export default DeliveryZonesCreate;
