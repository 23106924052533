import React, { useEffect, Fragment, useState } from 'react';
import styled from 'styled-components';
import {
  SortableContainer,
  SortableElement,
  arrayMove
} from 'react-sortable-hoc';

const Wrapper = styled.div`
  display: grid;
  grid-gap: 25px;
  padding: 20px;
  grid-template-columns: 1fr 1fr 4fr 3fr 3fr 2fr 3fr 2fr;
  align-items: center;
`;

const ImageWrapper = styled.div`
  display: grid;
  grid-template: 50px / 50px auto 1fr;
  grid-gap: 24x;
  align-items: center;
  height: 50px;
`;

const ChooseImage = styled.div`
  border: 2px dashed #979797;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 12px;
`;

const SubItemAmount = styled.div`
  color: #6f6f76;
  font-size: 14px;
  width: 100px;
  border-bottom: 2px dashed #6f6f76;
`;

const SortableCardRow = styled.div`
  display: grid;
  grid-gap: 25px;
  padding: 20px;
  height: 102px;
  background-color: white;
  grid-template-columns: 1fr 50px 4fr 3fr 3fr 2fr 3fr 2fr;
  align-items: center;
  border-top: 1px solid #e1e1e1;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'move')};
`;

const SortableItem = SortableElement(({ item }) => {
  return (
    <SortableCardRow>
      <div>
        <i className="far fa-equals" />
      </div>
      {!item.image_url ? <ChooseImage /> : <Image src={item.image_url} />}
      <div>{item.name}</div>
      <div>{item.maximum_pick}</div>
      <div>{item.count_as}</div>
      <div>{item.is_active ? 'Active' : 'Not Active'}</div>
      <div />
      <div />
    </SortableCardRow>
  );
});

const SortableList = SortableContainer(({ items, arrayHelpers }) => {
  return (
    <div>
      {items.map((item, index) => (
        <SortableItem
          key={`item-${index}`}
          index={index}
          item={item}
          itemIndex={index}
          items={items}
          arrayHelpers={arrayHelpers}
        />
      ))}
    </div>
  );
});

const ItemRow = ({
  items,
  optionIndex,
  itemIndex,
  arrayHelpers,
  isSubItem
}) => {
  return (
    <Fragment>
      <SortableList
        items={items}
        arrayHelpers={arrayHelpers}
        isSubItem={isSubItem}
        onSortEnd={({ oldIndex, newIndex }) => {
          let newOption = { ...arrayHelpers.form.values.options[optionIndex] };
          if (!isSubItem) {
            newOption.items = [
              ...arrayMove(newOption.items, oldIndex, newIndex)
            ];
            let newOptions = [...arrayHelpers.form.values.options];
            newOptions[optionIndex] = { ...newOption };
            arrayHelpers.form.setFieldValue(`options[${optionIndex}]`, {
              ...newOption
            });
          } else {
            // newOption.items[itemIndex].sub_items = [
            //   ...arrayMove(
            //     newOption.items[itemIndex].sub_items,
            //     oldIndex,
            //     newIndex
            //   )
            // ];
            // newOption = { ...newOption };
            // let newOptions = [...arrayHelpers.form.values.options];
            // newOptions[optionIndex] = { ...newOption };
            arrayHelpers.form.setFieldValue(
              `options[${optionIndex}].items[${itemIndex}].sub_items`,
              [
                ...arrayMove(
                  newOption.items[itemIndex].sub_items,
                  oldIndex,
                  newIndex
                )
              ]
            );
          }
          // setHasSorted(true);
        }}
      />
    </Fragment>
  );
};

export default ItemRow;
