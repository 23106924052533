import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../productsActions';
import ProductBusinessesOverridesForm from '../components/ProductBusinessesOverridesForm';
import { finalStructureSummaryData } from '../utils/helper';
import { selectFormattedVariants } from '../../../productsSelectors';

const ProductBusinessesOverridesContainer = ({
  getProductOverrides,
  getProductAssignedBusinesses,
  productId,
  productVariantsInitialValues,
  productOverrides,
  formattedBusinesses,
  allVariants,
  isSubmiting,
  isRequestingProductVariants,
  reassociateProductOverrides,
  currentAssociatedBusinesses,
}) => {
  const associatedBusinessIdList = currentAssociatedBusinesses?.map(
    (business) => business.business_id
  );
  const filteredAssociatedBusinesses = formattedBusinesses?.filter((business) =>
    associatedBusinessIdList.includes(business.id)
  );
  const selectedAssociatedBusinesses = filteredAssociatedBusinesses?.map(
    (business) => {
      return {
        id: business.id,
        name: business.name,
        display_name: business.display_name,
        business_name: business.business_name,
      };
    }
  );
  const [isCreate, setIsCreate] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [summaryData, setSummaryData] = useState(null);

  const [isOverrideModal, setIsOverrideModal] = useState({
    isShowing: false,
    prices: null,
    assignedBusinesses: [],
  });
  const [productVariantsModified, setProductVariantsModified] = useState([]);

  useEffect(() => {
    if (productId) {
      getProductOverrides(productId);
      getProductAssignedBusinesses(productId);
    }
  }, [productId]);
  const updateSummaryData = useCallback(() => {
    if (productOverrides && productId && allVariants) {
      let newBusinessProductOverride = productOverrides.filter((override) =>
        associatedBusinessIdList.includes(override.business_id)
      );
      setSummaryData(
        finalStructureSummaryData(
          newBusinessProductOverride,
          productId,
          productVariantsInitialValues,
          formattedBusinesses,
          allVariants
        )
      );
    }
  }, [productOverrides, productId, allVariants, productVariantsInitialValues]);
  useEffect(() => {
    updateSummaryData();
  }, [updateSummaryData]);

  useEffect(() => {
    let newProductVariantsModified = [];
    allVariants.map((variant) => {
      productVariantsInitialValues.map((prVariant) => {
        if (variant.id === prVariant.product_variant_id) {
          newProductVariantsModified.push({
            ...prVariant,
            name: variant.name,
            label: variant.label,
          });
        }
      });
    });
    setProductVariantsModified(newProductVariantsModified);
  }, [productVariantsInitialValues]);

  // reassociate overrides when a variant deleted
  const getBusinessObject = (businessId, prices) => {
    let business = { business_id: businessId, prices: prices };
    return business;
  };
  const reassociateOverrides = useCallback(() => {
    let finalBusinessOverrides = [];
    let hasDifference = false;
    if (productOverrides?.length > 0) {
      let newBusinessProductOverride = productOverrides.filter((override) =>
        associatedBusinessIdList.includes(override.business_id)
      );
      const currentVariantIdList = productVariantsModified.map(
        (variant) => variant.product_variant_id
      );
      if (productVariantsModified?.length === 0) {
      } else {
        var grupedOverrideByBusiness = newBusinessProductOverride.reduce(
          function (results, org) {
            (results[org.business_id] = results[org.business_id] || []).push(
              org
            );
            return results;
          },
          {}
        );
        for (const [key, value] of Object.entries(grupedOverrideByBusiness)) {
          value.map((override) => {
            if (!currentVariantIdList.includes(override.product_variant_id)) {
              hasDifference = true;
            }
          });
        }

        if (hasDifference) {
          finalBusinessOverrides = [];
          for (const [businessId, businessOverrides] of Object.entries(
            grupedOverrideByBusiness
          )) {
            let prices = [];
            businessOverrides.map((override) => {
              if (currentVariantIdList.includes(override.product_variant_id)) {
                prices.push({
                  product_variant_id: override.product_variant_id,
                  price: override.price,
                  start_time: null,
                  end_time: null,
                });
              }
            });
            if (prices.length !== 0) {
              finalBusinessOverrides.push(
                getBusinessObject(Number(businessId), prices)
              );
            }
          }
          reassociateProductOverrides(
            productId,
            finalBusinessOverrides,
            false,
            true
          );
          hasDifference = false;
        }
      }
    }
  }, [productVariantsModified, productOverrides]);
  useEffect(() => {
    reassociateOverrides();
  }, [reassociateOverrides]);
  return (
    <>
      <ProductBusinessesOverridesForm
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
        disabled={!productId}
        summaryData={summaryData}
        isOverrideModal={isOverrideModal}
        setIsOverrideModal={setIsOverrideModal}
        formattedBusinesses={formattedBusinesses}
        productId={productId}
        productVariantsModified={productVariantsModified}
        productVariantsInitialValues={productVariantsInitialValues}
        isCreate={isCreate}
        setIsCreate={setIsCreate}
        isSubmiting={isSubmiting}
        isRequestingProductVariants={isRequestingProductVariants}
        productOverrides={productOverrides}
        reassociateProductOverrides={reassociateProductOverrides}
        selectedAssociatedBusinesses={selectedAssociatedBusinesses}
        associatedBusinessIdList={associatedBusinessIdList}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  productId: state.productsReducer.productId,
  productVariantsInitialValues:
    state.productsReducer.productVariantsInitialValues,
  isRequestingProductVariants:
    state.productsReducer.isRequestingProductVariants,
  productOverrides: state.productsReducer.productOverrides,
  formattedBusinesses: state.productsReducer.formattedBusinesses,
  isSubmiting: state.productsReducer.isSubmiting,
  currentAssociatedBusinesses:
    state.productsReducer.productSelectedBusinessesInitialValues,
  allVariants: selectFormattedVariants(state),
});

export default connect(
  mapStateToProps,
  actions
)(ProductBusinessesOverridesContainer);
