import {
  GET_UPSELL_MENU_REQUEST,
  GET_UPSELL_MENU_SUCCESS,
  GET_UPSELL_MENU_FAILURE,
  ADD_PRODUCTS_TO_UPSELL_MENU_DRAFT,
  REMOVE_PRODUCT_FROM_UPSELL_MENU_DRAFT,
  SORT_UPSELL_MENU_DRAFT,
  EDIT_UPSELL_MENU_REQUEST,
  EDIT_UPSELL_MENU_SUCCESS,
  EDIT_UPSELL_MENU_FAILURE,
  UNDO_UNSAVED_UPSELL_MENU_CHANGES,
  GET_CATEGORIES_UPSELL_MODAL,
  GET_CATEGORIES_UPSELL_MODAL_REQUEST,
  GET_CATEGORIES_UPSELL_MODAL_FAILURE,
  GET_PRODUCTS_UPSELL_MODAL_REQUEST,
  GET_PRODUCTS_UPSELL_MODAL,
  GET_PRODUCTS_UPSELL_MODAL_FAILURE,
  RESET_UPSELL_MODAL
} from './upsellTypes';

export default function(
  state = {
    requestingGetUpsellMenu: false,
    upsellMenu: null,
    upsellMenuDraft: null,
    upsellCategoriesModal: null,
    requestingGetCategoriesUpsellModal: true,
    upsellProductsModal: null,
    requestingGetProductsUpsellModal: false
  },
  action
) {
  switch (action.type) {
    case GET_UPSELL_MENU_REQUEST:
      return {
        ...state,
        requestingGetUpsellMenu: true
      };
    case GET_UPSELL_MENU_SUCCESS:
      return {
        ...state,
        requestingGetUpsellMenu: false,
        upsellMenu: action.upsellMenu,
        upsellMenuDraft: action.upsellMenu
      };
    case GET_UPSELL_MENU_FAILURE:
      return {
        ...state,
        requestingGetUpsellMenu: false,
        error: action.error
      };
    case ADD_PRODUCTS_TO_UPSELL_MENU_DRAFT:
      return {
        ...state,
        upsellMenuDraft: [...action.products, ...state.upsellMenuDraft]
      };
    case REMOVE_PRODUCT_FROM_UPSELL_MENU_DRAFT:
      return {
        ...state,
        upsellMenuDraft: state.upsellMenuDraft.filter(
          ({ id }) => id !== action.productId
        )
      };
    case SORT_UPSELL_MENU_DRAFT:
      return {
        ...state,
        upsellMenuDraft: action.sortedUpsellMenu
      };
    case EDIT_UPSELL_MENU_REQUEST:
      return {
        ...state,
        requestingEditUpsellMenu: true
      };
    case EDIT_UPSELL_MENU_SUCCESS:
      return {
        ...state,
        requestingEditUpsellMenu: false
      };
    case EDIT_UPSELL_MENU_FAILURE:
      return {
        ...state,
        requestingEditUpsellMenu: false
      };
    case UNDO_UNSAVED_UPSELL_MENU_CHANGES:
      return {
        ...state,
        upsellMenuDraft: state.upsellMenu
      };

    case GET_CATEGORIES_UPSELL_MODAL_REQUEST:
      return {
        ...state,
        requestingGetCategoriesUpsellModal: true
      };

    case GET_CATEGORIES_UPSELL_MODAL:
      return {
        ...state,
        requestingGetCategoriesUpsellModal: false,
        upsellCategoriesModal: action.upsellCategoriesModal
      };

    case GET_CATEGORIES_UPSELL_MODAL_FAILURE:
      return {
        ...state,
        requestingGetCategoriesUpsellModal: false,
        error: action.error
      };

    case GET_PRODUCTS_UPSELL_MODAL_REQUEST:
      return {
        ...state,
        requestingGetProductsUpsellModal: true
      };

    case GET_PRODUCTS_UPSELL_MODAL:
      return {
        ...state,
        requestingGetProductsUpsellModal: false,
        upsellProductsModal: action.upsellProductsModal
      };

    case GET_PRODUCTS_UPSELL_MODAL_FAILURE:
      return {
        ...state,
        requestingGetProductsUpsellModal: false,
        error: action.error
      };

    case RESET_UPSELL_MODAL:
      return {
        ...state,
        upsellCategoriesModal: null,
        requestingGetCategoriesUpsellModal: true,
        upsellProductsModal: null,
        requestingGetProductsUpsellModal: false
      };
    default:
      return state;
  }
}
