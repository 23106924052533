import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import * as actions from '../customerActions';
import AccountInformation from '../components/AccountInformation';
import AccountActivationConfirmModal from '../components/modals/AccountActivationConfirmModal';
import AccountInformationModal from '../components/modals/AccountInformationModal';
import { LoadingBlueLarge } from 'merchant-portal-components';

/**
 * Class based component handling how components work
 */
class AccountInformationContainer extends Component {
  state = {
    accountActivationCheckboxIsChecked: false,
    height: null,
  };

  
  /**
   * toggle account activation confirm modal visibility
   */
  accountActivationConfirmModalToggleVisibility = () => {
    this.props.accountActivationConfirmModalToggleVisibility();
    this.setState({
      accountActivationCheckboxIsChecked: false,
    });
  };

  /**
   * handle toggle of account activation 'I understand' checkbox
   */
  handleAccountActivationCheckboxToggle = () => {
    this.setState({
      accountActivationCheckboxIsChecked: !this.state
        .accountActivationCheckboxIsChecked,
    });
  };

  /**
   * handle account activation submit
   */
  handleAccountActivationSubmit = () => {
    const { customerDetails } = this.props.customerReducer;
    this.props.patchAccountIsActive(
      this.props.customerId,
      !customerDetails.is_active
    );
  };

  /**
   * toggle account edit modal visibility
   */
  accountInformationEditModalToggleVisibility = () => {
    
    this.props.accountInformationEditModalToggleVisibility();
  };

  /**
   * handle account information edit submission
   */
  handleAccountInformationSubmit = (values) => {
    this.props.patchAccountInformation(this.props.customerId, values);
  };

  /**
   * handle confirm phone number submit
   */
  handleConfirmPhoneNumber = () => {
    this.props.confirmAccountPhoneNumber(this.props.customerId);
  };
  
  /**
   * handle customer delete props
   */
  CustomerDelete = (customerdel) => {
    this.props.customerClick(customerdel);
  };
  /**
   * update view component height so loading component is same height
   */
  updateHeight = (element) => {
    if (
      element &&
      element.offsetHeight &&
      this.state.height != element.offsetHeight
    ) {
      this.setState({ height: element.offsetHeight + 'px' });
    }
  };

  render() {
    const { accountActivationCheckboxIsChecked } = this.state;
    const {
      customerDetails,
      activationConfirmModalIsVisible,
      patchAcountIsActiveIsRequesting,
      accountInformationEditModalIsVisible,
      patchAccountInformationIsRequesting,
      getCustomerDetailsIsRequesting,
    } = this.props.customerReducer;
    
    return (
      <Fragment>
        {getCustomerDetailsIsRequesting ? (
          <LoadingBlueLarge height={this.state.height} marginTop="0px" />
        ) : (
          <div ref={this.updateHeight}>
            <AccountInformation
              customerDetails={customerDetails}
              accountActivationConfirmModalToggleVisibility={
                this.accountActivationConfirmModalToggleVisibility
              
              }
              accountInformationEditModalToggleVisibility={
                this.accountInformationEditModalToggleVisibility
              }
              handleConfirmPhoneNumber={this.handleConfirmPhoneNumber}
              Accontclick = {this.CustomerDelete}
            />
          </div>
        )}

        {accountInformationEditModalIsVisible ? (
          <AccountInformationModal
            handleAccountInformationSubmit={this.handleAccountInformationSubmit}
            accountInformationEditModalToggleVisibility={
              this.accountInformationEditModalToggleVisibility
            }
            patchAccountInformationIsRequesting={
              patchAccountInformationIsRequesting
            }
            customerDetails={this.props.customerReducer.customerDetails}
          />
        ) : null}

        {activationConfirmModalIsVisible ? (
          <AccountActivationConfirmModal
            accountIsActive={customerDetails.is_active}
            customerName={customerDetails.customerName()}
            handleAccountActivationCheckboxToggle={
              this.handleAccountActivationCheckboxToggle
            }
            accountActivationCheckboxIsChecked={
              accountActivationCheckboxIsChecked
            }
            accountActivationConfirmModalToggleVisibility={
              this.accountActivationConfirmModalToggleVisibility
            }
            handleAccountActivationSubmit={this.handleAccountActivationSubmit}
            patchAcountIsActiveIsRequesting={patchAcountIsActiveIsRequesting}
          />
        ) : null}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({ customerReducer: state.customerReducer });

export default connect(mapStateToProps, actions)(AccountInformationContainer);
