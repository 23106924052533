import {GET_COMBOS_REQUEST, GET_COMBOS_RESPONSE} from './types';

export default function(
  state = {
    requestingCombos: false,
    response: null
  },
  action
) {
  switch (action.type) {
    case GET_COMBOS_REQUEST:
      return {...state, requestingCombos: true};
    case GET_COMBOS_RESPONSE:
      return {...state, requestingCombos: false, response: action.response};
    default:
      return state;
  }
}
