import api from '../../../../api';
import { createAlert } from '../../../Alert/actions';
import {
  GET_AUTOMATED_MESSAGES_LIST,
  GET_AUTOMATED_MESSAGES_LIST_REQUEST,
  SAVE_MESSAGE_REQUEST,
  SAVE_MESSAGE_SUCCESS,
  SAVE_MESSAGE_FAILURE,
} from './automatedMessagesTypes';

export function getAutomatedMessagesList() {
  return function (dispatch) {
    dispatch({ type: GET_AUTOMATED_MESSAGES_LIST_REQUEST });
    return api
      .get('/notification-configurations')
      .then((response) => {
        dispatch({
          type: GET_AUTOMATED_MESSAGES_LIST,
          payload: response.data.data,
        });
      })
      .catch((err) => {
        dispatch(
          createAlert({
            type: 'error',
            message: err?.response?.data?.errors?.message || 'An error occurred trying to get automated messages list',
          })
        );
      });
  };
}

export function saveMessage(values, id) {
  return function (dispatch) {
    dispatch({ type: SAVE_MESSAGE_REQUEST, id });
    const body = createPatchStructure(values);
    return api
      .patch(`/notification-configurations/${id}`, body)
      .then((response) => {
        dispatch(
          createAlert({
            type: 'success',
            message: `${response.data.data.notification_type.name} updated successfully`,
          })
        );
        dispatch({ type: SAVE_MESSAGE_SUCCESS, id });
      })
      .catch((err) => {
        dispatch(
          createAlert({
            type: 'error',
            message: err?.response?.data?.errors?.message || 'An error occurred trying to update automated push message.',
          })
        );
        dispatch({ type: SAVE_MESSAGE_FAILURE, id });
      });
  };
}

function createPatchStructure(values) {
  let body = {
    configuration: values.configurations,
    messages: values.messages,
    is_email: false,
    is_push_message: true,
    is_sms: false,
    is_active: true,
  };

  return body;
}
