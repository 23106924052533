import {
  UPDATE_SEGMENTS_REQUEST_PARAMS,
  UPDATE_OFFERS_REQUEST_PARAMS,
  UPDATE_MOBILE_MESSAGES_REQUEST_PARAMS,
  UPDATE_COUPONS_REQUEST_PARAMS,
  UPDATE_MEDIA_REQUEST_PARAMS,
  UPDATE_VARIANTS_REQUEST_PARAMS,
  UPDATE_OPTIONS_REQUEST_PARAMS,
  UPDATE_COMBOS_REQUEST_PARAMS,
  UPDATE_PRODUCTS_REQUEST_PARAMS,
  UPDATE_CATEGORIES_REQUEST_PARAMS,
  UPDATE_ITEMS_REQUEST_PARAMS,
  UPDATE_PROMO_REQUEST_PARAMS,
} from './types';

export function updateRequestParams(requestParams, type) {
  switch (type) {
    case 'segments':
      return {
        type: UPDATE_SEGMENTS_REQUEST_PARAMS,
        payload: requestParams,
      };
    case 'offers':
      return {
        type: UPDATE_OFFERS_REQUEST_PARAMS,
        payload: requestParams,
      };
    case 'mobile':
      return {
        type: UPDATE_MOBILE_MESSAGES_REQUEST_PARAMS,
        payload: requestParams,
      };
    case 'coupons':
      return {
        type: UPDATE_COUPONS_REQUEST_PARAMS,
        payload: requestParams,
      };
    case 'media':
      return {
        type: UPDATE_MEDIA_REQUEST_PARAMS,
        payload: requestParams,
      };
    case 'variants':
      return {
        type: UPDATE_VARIANTS_REQUEST_PARAMS,
        payload: requestParams,
      };
    case 'promo':
      return {
        type: UPDATE_PROMO_REQUEST_PARAMS,
        payload: requestParams,
      };
    case 'options':
      return {
        type: UPDATE_OPTIONS_REQUEST_PARAMS,
        payload: requestParams,
      };
    case 'combos':
      return {
        type: UPDATE_COMBOS_REQUEST_PARAMS,
        payload: requestParams,
      };
    case 'products':
      return {
        type: UPDATE_PRODUCTS_REQUEST_PARAMS,
        payload: requestParams,
      };
    case 'categories':
      return {
        type: UPDATE_CATEGORIES_REQUEST_PARAMS,
        payload: requestParams,
      };
    case 'items':
      return {
        type: UPDATE_ITEMS_REQUEST_PARAMS,
        payload: requestParams,
      };
    case 'fees':
      return {
        type: 'UPDATE_FEES_REQUEST_PARAMS',
        payload: requestParams,
      };
    case 'summaryReport':
      return {
        type: 'UPDATE_SUMMARY_REPORT_REQUEST_PARAMS',
        payload: requestParams,
      };
    case 'filterLocationsInSummary':
      return {
        type: 'UPDATE_SUMMARY_REPORT_FILTER_REQUEST_PARAMS',
        payload: requestParams,
      };
    case 'transactionReport':
      return {
        type: 'UPDATE_TRANSACTION_REPORT_REQUEST_PARAMS',
        payload: requestParams,
      };
    case 'loyaltyReport':
      return {
        type: 'UPDATE_LOYALTY_REPORT_REQUEST_PARAMS',
        payload: requestParams,
      };
    case 'productReport':
      return {
        type: 'UPDATE_PRODUCT_REPORT_REQUEST_PARAMS',
        payload: requestParams,
      };
    case 'comboReport':
      return {
        type: 'UPDATE_COMBO_REPORT_REQUEST_PARAMS',
        payload: requestParams,
      };
    case 'locationReport':
      return {
        type: 'UPDATE_LOCATION_REPORT_REQUEST_PARAMS',
        payload: requestParams,
      };
    case 'customerReport':
      return {
        type: 'UPDATE_CUSTOMER_REPORT_REQUEST_PARAMS',
        payload: requestParams,
      };
    case 'offerReport':
      return {
        type: 'UPDATE_OFFER_REPORT_REQUEST_PARAMS',
        payload: requestParams,
      };
    case 'couponReport':
      return {
        type: 'UPDATE_COUPON_REPORT_REQUEST_PARAMS',
        payload: requestParams,
      };
    case 'locationFiltering':
      return {
        type: 'UPDATE_LOCATION_FILTERING_REQUEST_PARAMS',
        payload: requestParams,
      };
    case 'orderHistory':
      return {
        type: 'UPDATE_ORDER_HISTORY_REQUEST_PARAMS',
        payload: requestParams,
      };
    case 'comboReordering':
      return {
        type: 'UPDATE_COMBO_REORDERING_REQUEST_PARAMS',
        payload: requestParams,
      };
    case 'offerReordering':
      return {
        type: 'UPDATE_OFFER_REORDERING_REQUEST_PARAMS',
        payload: requestParams,
      };
  }
}
