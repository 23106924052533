import React, { Fragment, useEffect } from 'react';
import styled from 'styled-components';
import { TableWrapper } from 'merchant-portal-components';
import CustomerInfo from './CustomerInfo';
import OrderInfo from './OrderInfo';
import BasicTransactionInfo from './BasicOrderInfo';
import ProductsOrderedInfo from './ProductsOrderedInfo';
import OrderTransactionInfo from './OrderTransactionInfo';
import OrderActionButtons from './OrderActionButtons';
import RefundOrderModal from './modals/RefundOrderModal';
import SendLoyaltyModal from './modals/SendLoyaltyModal';
import { LoadingBlueLarge } from 'merchant-portal-components';
import NoDataFound from '../../../../components/NoDataFound/NoDataFound';
import {
  ContainerDiv,
  HorizontalLine,
  WrapperLoyaltyPoints,
  GridWrapper,
  StyledP,
  DividerLine,
} from './styles/commonStyledComponents';

export const OrderDetails = (props) => {
  const {
    requestingOrderDetails,
    orderDetails,
    isCancelOrderModalVisible,
    handleVisibilityCancelOrderModal,
    handleOrderCancel,
    handleIsOrderCancelBoxChecked,
    isOrderCancelBoxChecked,
    isSendLoyaltyModalVisible,
    handleVisibilitySendLoyaltyModal,
    handleSendLoyaltySubmit,
    requestingCancelOrderDetails,
    getTypesOfRewardOptions,
    orderId,
    requestingSendLoyaltyPointsByCustomerId,
    redirectPage,
    orderDetailCredit,
    resendReceiptEmail,
  } = props;
  const handleResendReceiptEmail = () => {
    const orderId = orderDetails.orderId;
    resendReceiptEmail(orderId);
  };
  if (requestingOrderDetails) {
    return (
      <div>
        <LoadingBlueLarge data-test='component-LoadingBlueLarge' />
      </div>
    );
  } else if (!orderDetails) {
    return (
      <div>
        <NoDataFound
          data-test='component-NoDataFound'
          rootItem={{
            pathname: `/${redirectPage || 'order-history'}`,
            name: `${
              redirectPage === 'customer-feedback'
                ? 'Customer Feedback'
                : redirectPage === 'order-management'
                ? 'Order Management'
                : 'Order History'
            }`,
          }}
          message="You don't have permission to check this order."
        />
      </div>
    );
  } else
    return (
      <TableWrapper data-test='component-OrderDetails'>
        <ContainerDiv padding='30px 30px 10px 30px'>
          <CustomerInfo orderDetails={orderDetails} />
          <DividerLine />
          <OrderInfo orderDetails={orderDetails} />
          <DividerLine />
          <ProductsOrderedInfo orderDetails={orderDetails} />
          <DividerLine />
          <OrderTransactionInfo
            data-test='component-TransactionHistory'
            reports={orderDetails.reports}
            transactions={orderDetails.transactions}
            subtotal={orderDetails.subtotal}
            discount={orderDetails.discount}
            loyalty={orderDetails.loyalty}
            tip={orderDetails.tip}
            tax={orderDetails.tax}
            creditsUsed={orderDetails.creditsUsed}
            total={orderDetails.total}
            additionalFees={orderDetails.additionalFees}
            serviceFees={orderDetails.serviceFees}
            orderDetailCredit={orderDetailCredit}
          />
          <OrderActionButtons
            data-test='component-OrderActionButtons'
            history={props.history}
            orderDetails={orderDetails}
            handleVisibilityCancelOrderModal={handleVisibilityCancelOrderModal}
            handleVisibilitySendLoyaltyModal={handleVisibilitySendLoyaltyModal}
            refundIsDisabled={orderDetails.refundIsDisabled}
            refundedIsHidden={
              orderDetails?.transactions?.length === 0 ||
              (orderDetails?.transactions[0]?.type == 'purchase' &&
                orderDetails?.transactions[0]?.status == 'failed')
            }
            handleResendReceiptEmail={handleResendReceiptEmail}
          />
        </ContainerDiv>
        {isCancelOrderModalVisible && (
          <RefundOrderModal
            data-test='component-RefundOrderModal'
            customerName={orderDetails.customerName}
            handleOrderCancel={handleOrderCancel}
            handleIsOrderCancelBoxChecked={handleIsOrderCancelBoxChecked}
            handleVisibilityCancelOrderModal={handleVisibilityCancelOrderModal}
            isOrderCancelBoxChecked={isOrderCancelBoxChecked}
            requestingCancelOrderDetails={requestingCancelOrderDetails}
          />
        )}
        {isSendLoyaltyModalVisible && (
          <SendLoyaltyModal
            data-test='component-SendLoyaltyModal'
            getTypesOfRewardOptions={getTypesOfRewardOptions}
            customerName={orderDetails.customerName}
            handleVisibilitySendLoyaltyModal={handleVisibilitySendLoyaltyModal}
            handleSendLoyaltySubmit={handleSendLoyaltySubmit}
            requestingSendLoyaltyPointsByCustomerId={
              requestingSendLoyaltyPointsByCustomerId
            }
          />
        )}
      </TableWrapper>
    );
};

export default OrderDetails;
