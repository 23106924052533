import React, { useEffect, Suspense, lazy, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../ReportTableActions.js';
import ReportSearch from '../components/ReportSearch';
const GenericTable = lazy(() =>
  import('../../../components/Table/GenericTable')
);

function CustomerReportContainer({
  allCustomers,
  requestParams,
  getCustomerReport,
  setExportingData,
  setExportingHeaders,
  setExportingFileName,
  clearCustomerReport,
  updateReportsRequestParams,
  setMessage,
  resetAllReports,
}) {
  const columnHeaders = [
    'Customer Name',
    'Customer Phone',
    'Customer Email',
    'Registration Date',
    'Loyalty Tier #',
    'Points Balance',
    '# of Transactions',
    'Gross Spend $',
    'Net Spend $',
    '# of Offers Redeemed',
    'Global Credits Received',
    '# of Coupons Redeemed',
    '# of Completed Referrals',
    'AVG Basket Size',
  ];
  const columnNames = [
    'customer_name',
    'customer_phone',
    'customer_email',
    'registration_date',
    'loyalty_tier',
    'points_balance',
    'num_transactions',
    'gross_spend',
    'net_spend',
    'num_offers_redeemed',
    'global_credits_received',
    'num_coupons_redeemed',
    'num_completed_referrals',
    'avg_basket_size',
  ];
  const columnTypes = [
    'string',
    'string',
    'string',
    'string',
    'string',
    'string',
    'string',
    'money',
    'money',
    'string',
    'money',
    'string',
    'string',
    'money',
  ];
  const columnWidth = [
    '200px',
    '200px',
    '300px',
    '200px',
    '200px',
    '200px',
    '200px',
    '200px',
    '200px',
    '200px',
    '200px',
    '200px',
    '200px',
    '200px',
  ];

  const columnHeaderIsSortableList = [
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
  ];
  const getExportHeaders = (headers, fields) => {
    const exportedHeaders = [];
    headers.forEach((header, index) =>
      exportedHeaders.push({ label: header, key: fields[index] })
    );
    return exportedHeaders;
  };
  const searchField = '';
  useEffect(() => {
    getCustomerReport(requestParams);
    setExportingFileName('customer_report');
    setExportingHeaders(getExportHeaders(columnHeaders, columnNames));
    setMessage('');
    return () => {
      clearCustomerReport();
      setExportingData([]);
      updateReportsRequestParams({
        page_number: 1,
        page_size: 25,
      });
      setMessage('');
      resetAllReports();
    };
  }, []);
  useEffect(() => {
    if (allCustomers?.length === 0) {
      setExportingData([]);
    }
    // console.log('curentCustomersList=>', allCustomers);
  }, [allCustomers]);
  const [curentPageNumber, setCurentPageNumber] = useState();
  const [currentPageSize, setCurrentPageSize] = useState();
  useEffect(() => {
    // console.log(
    //   'C page_number=',
    //   curentPageNumber,
    //   'C page_size=',
    //   currentPageSize
    // );
    // console.log('new param=', requestParams);
    // console.log('length=', allCustomers?.length);
    // console.log(
    //   'res=',
    //   parseInt(requestParams.page_number) * parseInt(requestParams.page_size)
    // );
    if (
      (!curentPageNumber ||
        (curentPageNumber && curentPageNumber < requestParams.page_number) ||
        currentPageSize !== requestParams.page_size) &&
      allCustomers?.length <
        parseInt(requestParams.page_number) * parseInt(requestParams.page_size)
    ) {
      getCustomerReport(requestParams);
      setCurrentPageSize(requestParams.page_size);
      setCurentPageNumber(requestParams.page_number);
    }
    // console.log('request, params=>', requestParams);
  }, [requestParams]);
  return (
    <>
      {/* <ReportSearch generateReport={getCustomerReport} /> */}
      {allCustomers?.length > 0 && (
        <div style={{ marginTop: '30px' }}>
          <Suspense fallback={<div>Loading...</div>}>
            <GenericTable
              dataList={allCustomers}
              columnHeaders={columnHeaders}
              columnHeaderIsSortableList={columnHeaderIsSortableList}
              columnNames={columnNames}
              columnWidth={columnWidth}
              columnTypes={columnTypes}
              searchField={searchField}
              placeholder=""
              requestParams={requestParams}
              isCustomerReport={true}
              requestParamsType={'transactionReport'}
              handleClickRow={null}
              handleCheckboxSelect={null}
              checkboxList={[]}
              setCheckboxList={null}
              setExportingData={() => setExportingData(allCustomers)}
            />
          </Suspense>
        </div>
      )}
    </>
  );
}
function mapStateToProps(state) {
  return {
    allCustomers: state.ReportTableReducer.allCustomers,
    requestParams: state.ReportTableReducer.requestParams,
  };
}

export default connect(mapStateToProps, actions)(CustomerReportContainer);
