import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from './CustomerList/customerListActions';
import CustomerListContainer from './CustomerList/CustomerListContainer';
import CustomerDetailsContainer from './CustomerDetails/CustomerDetailsContainer';
const initialRequestParams = {
  page_number: 1,
  page_size: 25,
};
const CustomerContainer = (props) => {
  const [startUpdating, setStartUpdating] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [currentSearchRecordes, setCurrentSearchRecordes] = useState([]);

  useEffect(() => {
    return () => {
      // props.getCustomersOfMerchant(initialRequestParams);
      setSearchString('');
    };
  }, []);
  useEffect(() => {
    const debounce = setTimeout(() => {
      setStartUpdating(true);
    }, 1500);
    return () => {
      setStartUpdating(false);
      clearTimeout(debounce);
    };
  }, [searchString]);
  if (props.currentPage === 'list') {
    return (
      <CustomerListContainer
        searchString={searchString}
        customers={props.customers}
        setSearchString={setSearchString}
        setCurrentPage={props.updateCustomerCurrentPage}
        setCustomerId={props.updateCustomerId}
        currentSearchRecordes={currentSearchRecordes}
        setCurrentSearchRecordes={setCurrentSearchRecordes}
        startUpdating={startUpdating}
        setStartUpdating={setStartUpdating}
        {...props}
      />
    );
  } else if (props.currentPage === 'detail') {
    return (
      <CustomerDetailsContainer
        customerId={props.customerId}
        currentPage={props.currentPage}
        setCurrentPage={props.updateCustomerCurrentPage}
        history={props.history}
      />
    );
  }
};

const mapStateToProps = (state) => ({
  currentPage: state.customerListReducer.currentPage,
  customers: state.customerListReducer.customers,
  customerId: state.customerListReducer.customerId,
});
export default connect(mapStateToProps, actions)(CustomerContainer);
