import React, { Fragment, useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { Formik, FieldArray, Field } from 'formik';
import {
  RenderSelect,
  RenderTextField,
  RenderToggleSwitch,
} from '../../../../components/FormElements/FormikElements';
import Switch from '@material-ui/core/Switch';
import FilterButton from '../../../../components/FilterModal/FilterButton';
import LocationFilterModal from '../../../../components/FilterModal/LocationFilterModal';
const Title = styled.p`
  color: #191818;
  font-size: 17px;
  font-weight: 600;
  margin: 23px 0;
`;

const ItemVariantsDiv = styled.div`
  margin-top: 40px;
`;

const HeaderWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr 1fr 1fr;
  grid-gap: 5px;
  margin-bottom: 30px;
`;

const Header = styled.p`
  font-size: 15px;
  font-weight: 600;
`;

const BodyDiv = styled.div``;

const RowWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr 1fr 1fr;
  grid-gap: 5px;
  padding-top: 10px;
`;

const RowCol = styled.div`
  font-size: 14px;
`;

const LineDivider = styled.hr`
  border: 1px solid rgba(0, 0, 0, 0.09);
  margin: 15px 0;
`;

const NoLocationSelectedDiv = styled.p`
  color: #959595;
  font-size: 20px;
  letter-spacing: 0.03;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
`;

const SelectLocationScreen = ({
  formattedBusinesses,
  item,
  selectedOption,
  priceKey,
  selectedLocations,
  setSelectedLocations,
  setFieldValue,
  singleItemOverrides,
  isPageEdit,
  editingVariant,
}) => {
  useEffect(() => {
    const currentSelectedlocations = editingVariant?.assignedBusinesses;
    if (currentSelectedlocations?.length > 0) {
      setSelectedLocations(currentSelectedlocations);
    }
  }, [editingVariant]);
  const itemModified = editingVariant.item ? editingVariant.item : item;
  const [isOpen, setIsOpen] = useState(false);
  const handleSelectBusinesses = (businessIDs) => {
    const newSelectedLocations = formattedBusinesses.filter((item) =>
      businessIDs.includes(item.id)
    );
    setSelectedLocations(newSelectedLocations);
    setFieldValue('assignedBusinesses', businessIDs);
  };
  return (
    <div>
      <Title>Select a Location</Title>
      <FilterButton
        message={
          formattedBusinesses?.length === selectedLocations?.length
            ? 'All Locations Selected'
            : `Selected Locations (${selectedLocations?.length || 0})`
        }
        height='55px'
        setIsOpen={setIsOpen}
      />

      {!selectedLocations.length && !isPageEdit ? (
        <NoLocationSelectedDiv>
          Select a location to add overrides
        </NoLocationSelectedDiv>
      ) : (
        <ItemVariantsDiv>
          <HeaderWrapper>
            <Header>Variant</Header>
            <Header>
              {selectedOption?.included_pick > 0 ? 'Additional Price' : 'Price'}
            </Header>
            <Header>Active</Header>
            <Header>Available</Header>
          </HeaderWrapper>

          <FieldArray
            name='item'
            render={(arrayHelpers) => {
              return (
                <Fragment>
                  <BodyDiv>
                    {itemModified.prices.map((variant, priceIndex) => {
                      return (
                        <div key={priceIndex}>
                          <RowWrapper>
                            <RowCol>
                              {editingVariant.item
                                ? `${variant.name} - ${variant.label}`
                                : variant.variantName}
                            </RowCol>
                            <RowCol style={{ paddingRight: '20px' }}>
                              <Field
                                id='price'
                                name={`item.prices[${priceIndex}][${priceKey}]`}
                                component={RenderTextField}
                                isPrice
                                placeholder={
                                  item.prices[priceIndex].price
                                    ? `${item.prices[priceIndex].price}`
                                    : ''
                                }
                              />
                            </RowCol>
                            <RowCol>
                              <Switch
                                checked={
                                  arrayHelpers.form.values.item.prices[
                                    priceIndex
                                  ].is_active
                                }
                                onChange={(e) => {
                                  setFieldValue(
                                    `item.prices[${priceIndex}].is_active`,
                                    e.target.checked
                                  );
                                }}
                                value='checkedB'
                                color='primary'
                                inputProps={{
                                  'aria-label': 'primary checkbox',
                                }}
                              />
                            </RowCol>
                            <RowCol>
                              <Switch
                                checked={
                                  arrayHelpers.form.values.item.prices[
                                    priceIndex
                                  ].is_available
                                }
                                onChange={(e) => {
                                  setFieldValue(
                                    `item.prices[${priceIndex}].is_available`,
                                    e.target.checked
                                  );
                                }}
                                value='checkedB'
                                color='primary'
                                inputProps={{
                                  'aria-label': 'primary checkbox',
                                }}
                              />
                            </RowCol>
                          </RowWrapper>
                          <LineDivider />
                        </div>
                      );
                      // } else return null;
                    })}
                  </BodyDiv>
                </Fragment>
              );
            }}
          />
        </ItemVariantsDiv>
      )}
      {isOpen && (
        <LocationFilterModal
          placeholder='Enter Internal Location Name'
          searchLabel='Search by Internal Location Name'
          filteredLocations={selectedLocations.map((item) => item.id)}
          setFilteredLocations={handleSelectBusinesses}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          emptyIsEqualToAll={false}
          isEmptyAccepted={true}
        />
      )}
    </div>
  );
};

export default SelectLocationScreen;
