import { FieldArray, Formik } from 'formik';
import React, { Fragment, useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { Button } from 'merchant-portal-components';
import { Modal } from 'merchant-portal-components';
import { TextField, Paper } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PaginationControl from '../../../../../components/Pagination/PaginationControl';
import { paginate } from '../../../../../components/FilterModal/paginate';

const ModalTitle = styled.div`
  display: grid;
  grid-template-columns: auto 20px;
  padding: 24px 24px 10px;
  font-size: 20px;
`;

const MessageWrapper = styled.div`
  padding: 5px 24px 0px 24px;
`;
const TableWrapper = styled.div`
  padding: 15px 30px 0px 30px;
`;
const RowWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 120px;
  grid-gap: 20px;
  align-items: center;
  padding: 5px 30px;
  font-weight: ${(props) => (props.isHeader ? '600' : 'normal')};
  border-bottom: ${(props) =>
    props.isHeader ? '2px solid #000000' : '1px solid #d3d3d3'};
  border-left: ${(props) => (props.isHeader ? 'none' : '1px solid #d3d3d3')};
  border-right: ${(props) => (props.isHeader ? 'none' : '1px solid #d3d3d3')};
`;
const TD = styled.div``;
const ModelContentWrapper = styled.div`
  width: 800px;
`;

const ButtonsWrapper = styled.div`
  text-align: right;
  padding: 20px;
  box-shadow: 0 -2px 9px 0 rgba(0, 0, 0, 0.12);
`;

const ModalBody = styled.div`
  height: 60vh;
`;

const LineDivider = styled.hr`
  margin-top: 11px;
  margin-bottom: 11px;
  border: solid 1px #ebebeb;
`;
const PaginationWrapper = styled.div`
  padding: 25px 37px;
  display: flex;
  justify-content: right;
`;
const CombosSortModal = ({
  combosList,
  setIsSortModalOpen,
  allCombosPositions,
  comboReorderRequestParams,
  updateComboRequestParams,
  updateCombosPosition,
}) => {
  const [allPositions, setAllPositions] = useState(allCombosPositions);
  const [currentPaginatedPositions, setCurrentPaginatedPositions] = useState(
    []
  );
  let postions = allCombosPositions.map((_, index) => {
    return { value: index + 1, label: `${index + 1}` };
  });
  useEffect(() => {
    return () => {
      updateComboRequestParams({
        page_number: 1,
        page_size: '10',
        sorting_option: 'title-asc',
        search_string: '',
      });
    };
  }, []);
  const filterPositionList = useCallback(() => {
    let paginatedList = paginate(
      allPositions,
      comboReorderRequestParams.page_number,
      comboReorderRequestParams.page_size
    );
    setCurrentPaginatedPositions(paginatedList);
  }, [comboReorderRequestParams, allPositions, allCombosPositions]);
  useEffect(() => {
    filterPositionList();
  }, [filterPositionList]);
  const closeComboSortModal = () => {
    setIsSortModalOpen(false);
  };
  const handleChangePosition = (newPosition, id, removeIndex) => {
    const pageNumber = comboReorderRequestParams.page_number;
    const pageSize = parseInt(comboReorderRequestParams.page_size);
    const overallRemoveIndex = (pageNumber - 1) * pageSize + removeIndex;
    const newAllCombosPositions = [...allPositions];
    const filteredCombo = allCombosPositions.find(
      (combo) => combo.combo_id === id
    );
    newAllCombosPositions.splice(overallRemoveIndex, 1);
    newAllCombosPositions.splice(newPosition - 1, 0, filteredCombo);
    let reorderedPositions = newAllCombosPositions.map((item, index) => {
      return { ...item, position: index + 1 };
    });
    setAllPositions(reorderedPositions);
  };
  const selectedValue = (position) => {
    const selectedPosition = postions.find((item) => item.value === position);
    return selectedPosition;
  };
  const handleSavePositions = () => {
    const modifiedAllPositions = allPositions.map((pos) => {
      return { id: parseInt(pos.combo_id), position: pos.position };
    });
    updateCombosPosition(modifiedAllPositions);
    setIsSortModalOpen(false);
  };
  return (
    <Modal borderRadius>
      <ModelContentWrapper>
        <Formik
          initialValues={{ combos: combosList }}
          enableReinitialize={true}
        >
          {({ values, handleReset, dirty, errors, setFieldValue }) => (
            <Fragment>
              <ModalTitle>
                <div>Sort Combos</div>
                <div
                  onClick={closeComboSortModal}
                  style={{ cursor: 'pointer', justifySelf: 'center' }}
                >
                  <i class='fa fa-times' />
                </div>
              </ModalTitle>
              <MessageWrapper>
                <div>
                  Search and/or Choose a combo position in the dropdown.
                </div>
                <div>
                  Max combo position :{' '}
                  <span style={{ color: '#2440AF', fontWeight: '600' }}>
                    {allPositions.length}
                  </span>
                </div>
              </MessageWrapper>
              <LineDivider />
              <TableWrapper>
                <RowWrapper isHeader>
                  <TD>Combo Name</TD>
                  <TD>Position</TD>
                </RowWrapper>
                {currentPaginatedPositions?.map((item, index) => (
                  <RowWrapper key={index}>
                    <TD>{item.name}</TD>
                    <TD>
                      <Autocomplete
                        disableClearable={true}
                        id='combo-box-demo'
                        size='small'
                        options={postions}
                        getOptionLabel={(option) => option.label}
                        value={selectedValue(item.position)}
                        onChange={(_, selectedItem) =>
                          handleChangePosition(
                            selectedItem.value,
                            item.combo_id,
                            index
                          )
                        }
                        renderInput={(params) => (
                          <TextField {...params} variant='outlined' />
                        )}
                        PaperComponent={({ children }) => (
                          <Paper style={{ background: '#edf0ed' }}>
                            {children}
                          </Paper>
                        )}
                      />
                    </TD>
                  </RowWrapper>
                ))}
              </TableWrapper>
              <PaginationWrapper>
                <PaginationControl
                  response={{
                    ...currentPaginatedPositions,
                    page: comboReorderRequestParams.page_number,
                    page_size: comboReorderRequestParams.page_size,
                    count: allPositions?.length,
                  }}
                  requestParams={comboReorderRequestParams}
                  pageInterval={[
                    { value: 10, label: '10' },
                    { value: 25, label: '25' },
                  ]}
                  type='comboReordering'
                />
              </PaginationWrapper>
              <ButtonsWrapper>
                <Button onClick={handleSavePositions}>Save</Button>
              </ButtonsWrapper>
            </Fragment>
          )}
        </Formik>
      </ModelContentWrapper>
    </Modal>
  );
};

export default CombosSortModal;
