export default [
  {
    value: {
      interval_type: 'day',
      interval_value: 7
    },
    label: 'Past 7 Days'
  },
  {
    value: {
      interval_type: 'day',
      interval_value: 30
    },
    label: 'Past 30 Days'
  },
  {
    value: {
      interval_type: 'day',
      interval_value: 90
    },
    label: 'Past 90 Days'
  },
  {
    value: {
      interval_type: 'year',
      interval_value: 1
    },
    label: 'Past 12 Months'
  }
];
