import React from 'react';
import styled from 'styled-components';
import BreadCrumb from '../../../components/BreadCrumb/BreadCrumb';
import SearchCard from './components/SearchCard';
import FeedLayoutList from './components/FeedLayoutList';

const Wrapper = styled.div`
  width: 740px;
  margin: 0 auto;
`;

const AppFeedLayout = ({
  initiateCreateLayout,
  listAppFeed,
  editAppFeedLayout,
  setSearchResult,
  deepDuplicateFeed,
  deleteAppFeedLayout,
  search,
  appIdentifier,
  history
}) => {
  return (
    <Wrapper>
      <BreadCrumb />
      <SearchCard
        list={listAppFeed}
        initiateCreateLayout={initiateCreateLayout}
        search={search}
        setSearchResult={setSearchResult}
      />
      <FeedLayoutList
        list={listAppFeed}
        editAppFeedLayout={editAppFeedLayout}
        deepDuplicateFeed={deepDuplicateFeed}
        deleteAppFeedLayout={deleteAppFeedLayout}
        appIdentifier={appIdentifier}
        history={history}
      ></FeedLayoutList>
    </Wrapper>
  );
};

export default AppFeedLayout;
