import React from 'react';
import styled from 'styled-components';
import SegmentCard, { ButtonRow } from '../SegmentCard/SegmentCard';
import LocationsTargeted from '../LocationsTargeted/LocationsTargeted';
import GoogleMapsWithOverlayZones from '../../../GoogleMapsWithOverlayZones/GoogleMapsWithOverlayZones';
import YourCriteria from './YourCriteria/YourCriteria';
import YourTargetAudience from './YourTargetAudience/YourTargetAudience';
import { Button } from 'merchant-portal-components';

const YourCriteriaAndTargetAudienceWrapper = styled.div`
  // display: grid;
  // grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  border-top: 1px solid lightgray;
  margin-top: 24px;
  padding-top: 4px;
`;

const YourCriteriaWrapper = styled.div`
  padding: 15px;
  padding-left: 0;
  width: 50%;
  @media (max-width: 767px) {
    padding: 15px 0;
  }
`;

const YourTargetAudienceWrapper = YourCriteriaWrapper.extend`
  border-left: 1px solid lightgray;
  padding-left: 15px;
  @media (max-width: 904px) {
    border-left: none;
    padding-left: 0;
  }
`;

const Subtitle = styled.div`
  margin-bottom: 16px;
  font-weight: bold;
  color: ${(props) => props.theme.darkBlue};
  text-transform: uppercase;
`;

const ReviewYourAudience = (props) => {
  const renderZone = () => {
    switch (props.zone) {
      case 'live_in_zone':
        return 'Lives in area';
      case 'live_outside_zone':
        return 'Lives outside area';
      case 'last_located_in_zone':
        return 'Last seen in area';
      case 'last_located_outside_zone':
        return 'Last seen outside area';
      case 'purchase_in_zone':
        return 'Purchased in area';
      case 'purchase_outside_zone':
        return 'Purchased outside area';
      default:
        return 'All Areas';
    }
  };
  const title = 'review your audience';
  const instructions =
    'Review your current target audience. You may go back to refine your filters.';
  return (
    <SegmentCard
      title={title}
      instructions={instructions}
      cancelEditSegment={props.cancelEditSegment}
    >
      <div>
        <Subtitle>Marketing Zone: {renderZone()} </Subtitle>
        {props.selectedLocations && props.overlay ? (
          <GoogleMapsWithOverlayZones
            selectedLocations={props.selectedLocations.filter(
              (location) => location.lat && location.lng
            )}
            overlayObjects={[props.overlay]}
            zoom={4}
          />
        ) : (
          <GoogleMapsWithOverlayZones
            selectedLocations={props.selectedLocations.filter(
              (location) => location.lat && location.lng
            )}
            zoom={4}
          />
        )}

        <YourCriteriaAndTargetAudienceWrapper>
          <YourCriteriaWrapper>
            <YourCriteria
              criteria={props.criteria}
              allTags={props.allTags}
              allProducts={props.allProducts}
              businesses={props.businesses}
            />
          </YourCriteriaWrapper>
          {/* <YourTargetAudienceWrapper>
            <YourTargetAudience audienceData={props.targetAudienceData} />
          </YourTargetAudienceWrapper> */}
        </YourCriteriaAndTargetAudienceWrapper>
      </div>

      {/* <LocationsTargeted
        selectedLocations={props.selectedLocations}
        drawnOverlayZones={props.overlayWithMap ? [props.overlayWithMap] : null}
      /> */}

      <ButtonRow>
        <Button type="button" onClick={() => props.previousPage()} secondary>
          Back
        </Button>

        <Button type="button" onClick={() => props.onSubmit()}>
          Continue
        </Button>
      </ButtonRow>
    </SegmentCard>
  );
};

export default ReviewYourAudience;
