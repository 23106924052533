import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../combosAction';
import ComboBusinessesOverridesForm from '../components/ComboBusinessesOverridesForm';
import {
  formatProductBusinessesAssigned,
  finalStructureSummaryData,
} from '../utils/helper';
import _ from 'lodash';

const ComboBusinessesOverridesContainer = ({
  combo,
  comboOverrides,
  getComboOverrides,
  comboBusinesses,
  allBusinesses,
  isSubmitting,
  reassociateCombosOverrides,
}) => {
  const formattedBusinesses = formatProductBusinessesAssigned(
    comboBusinesses,
    allBusinesses
  );

  const [isCreate, setIsCreate] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const [summaryData, setSummaryData] = useState(null);

  const [isOverrideModal, setIsOverrideModal] = useState({
    isShowing: false,
    price: null,
    assignedBusinesses: [],
  });

  useEffect(() => {
    if (combo.id) {
      getComboOverrides(combo.id);
    }
  }, [combo]);
  useEffect(() => {
    if (comboOverrides && combo) {
      setSummaryData(
        finalStructureSummaryData(comboOverrides, formattedBusinesses)
      );
    }
  }, [comboOverrides, combo]);
  const reassociateOverrides = useCallback(() => {
    let updatedCombosBusinesessOverrideIDs = [];
    let updatedCombosOverride = [];
    const currentCombosBusinesessIDs = comboBusinesses?.map(
      (business) => business.business_id
    );
    const currentCombosBusinesessOverrideIDs = comboOverrides?.map(
      (business) => business.business_id
    );
    if (
      currentCombosBusinesessIDs?.length > 0 &&
      currentCombosBusinesessOverrideIDs
    ) {
      // console.log('***comboOverrides=', comboOverrides);
      // console.log('***currentCombosBusinesessIDs=', currentCombosBusinesessIDs);
      // console.log(
      //   '***currentCombosBusinesessOverrideIDs=',
      //   currentCombosBusinesessOverrideIDs
      // );
      currentCombosBusinesessOverrideIDs.forEach((overrideId) => {
        if (currentCombosBusinesessIDs.includes(overrideId)) {
          updatedCombosBusinesessOverrideIDs.push(overrideId);
        }
      });
      // console.log(
      //   'updatedCombosBusinesessOverrideIDs=',
      //   updatedCombosBusinesessOverrideIDs
      // );
      if (
        !_.isEqual(
          currentCombosBusinesessOverrideIDs,
          updatedCombosBusinesessOverrideIDs
        )
      ) {
        comboOverrides.forEach((override) => {
          if (
            updatedCombosBusinesessOverrideIDs.includes(override.business_id)
          ) {
            updatedCombosOverride.push({
              business_id: override.business_id,
              fixed_price: override.fixed_price,
            });
          }
        });
        // console.log('update Overrides=', updatedCombosOverride);
        reassociateCombosOverrides(
          combo.id,
          updatedCombosOverride,
          false,
          true
        );
      }
    }
  }, [comboBusinesses, comboOverrides]);
  useEffect(() => {
    reassociateOverrides();
  }, [reassociateOverrides]);
  return (
    <ComboBusinessesOverridesForm
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}
      disabled={!combo.id}
      summaryData={summaryData}
      isOverrideModal={isOverrideModal}
      setIsOverrideModal={setIsOverrideModal}
      combo={combo}
      formattedBusinesses={formattedBusinesses}
      isCreate={isCreate}
      setIsCreate={setIsCreate}
      isSubmitting={isSubmitting}
      reassociateCombosOverrides={reassociateCombosOverrides}
      comboOverrides={comboOverrides}
    />
  );
};

const mapStateToProps = (state) => ({
  comboBusinesses: state.combosReducer.comboBusinesses,
  comboOverrides: state.combosReducer.comboOverrides,
  allBusinesses: state.businessLocationsList.businessLocationsOfMerchant,
  isSubmitting: state.combosReducer.isSubmitting,
});

export default connect(
  mapStateToProps,
  actions
)(ComboBusinessesOverridesContainer);
