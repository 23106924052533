import styled from "styled-components";
export const Wrapper = styled.div`
  width: 90%;
  margin: 0 auto;

  @media (max-width: 1313px) {
    width: 100%;
  }
`;
export const CardWrapper = styled.div`
  width: 100%;
  margin: 0 0 20px 0;
`;
export const BackText = styled.div`
  display: grid;
  grid-gap: 5px;
  grid-template-columns: 7px 1fr;
  cursor: pointer;
  align-items: center;
  margin-top: 20px;
  &:hover {
    color: #3051d4;
  }
`;
export const StyledCard = styled.div`
  width: 100%;
  padding: 27px 50px 24px 50px;
  border-radius: 12px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
  background-color: ${(props) => props.backgroundColor || "#fff"};
  height: 100%;
  min-height: 800px;
`;
export const HeaderWrapper = styled.div`
  padding: ${(props) => props.padding || "0 0 0 0"};
  margin: ${(props) => props.margin || "0 0 0 0"};
  width: ${(props) => props.width || "inherit"};
  display: grid;
  grid-template-columns: 1fr 30px;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    width: 80%;
  }
`;
export const SearchWrapper = styled.div`
  margin: 0 0 28px 0;
`;
export const CollapseWrapper = styled.div`
  display: grid;
  grid-template-columns: 11fr 1fr;
  grid-gap: 20px;
  justify-content: space-between;
`;
export const BusinessOverridesWrapper = styled.div`
  display: grid;
  grid-template-columns: 10fr 70px 90px;
  grid-gap: 20px;
  justify-content: space-between;
  margin: 35px 0 35px 0;
`;
export const SectionTitle = styled.div`
  width: 100%;
  display: ${(props) => props.display || "block"};
  margin: ${(props) => props.margin || "0px 0 12px 0"};
  font-family: "Montserrat";
  font-size: ${(props) => props.fontSize || "21px"};
  font-weight: ${(props) => props.fontWeight || "600"};
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.03px;
  color: ${(props) => props.color || "#191818"};
  text-align: ${(props) => props.textAlign || "left"};
`;
export const SectionSubTitle = styled.div`
  display: ${(props) => props.display || "block"};
  font-family: "Montserrat";
  font-size: ${(props) => props.fontSize || "14px"};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
  margin: ${(props) => props.margin || "0"};
`;
export const LocationsCounter = styled.div`
  margin: 20px 0 26px 0;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.03px;
  color: #2440ae;
`;

export const SortWrapper = styled.div`
  text-align: right;
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3051d4;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;
export const SortDiv = styled.div`
  width: 100%;
  text-align: right;
`;

export const RowWrapper = styled.div`
  display: grid;
  padding: 0;
  grid-template-columns: 1fr 100px 100px;
  margin: 0;
  padding: 7px 0;
  border-bottom: ${(props) =>
    props.isHeader ? "2px solid #000000" : "1px solid #ebebeb"};
  &:hover {
    background-color: ${(props) => props.hoverBGColor || "transparent"};
    cursor: pointer;
  }
`;
export const TH = styled.div`
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.03px;
  color: #000;
  text-align: ${(props) => props.textAlign || "left"};
  align-items: center;
  flex-wrap: wrap;
`;
export const TD = styled.div`
  display: flex;
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.03px;
  color: "#000";
  justify-content: ${(props) => props.textAlign || "left"};
  align-items: center;
  flex-wrap: wrap;
`;
export const PaginationWrapper = styled.div`
  padding: 10px 0;
  display: flex;
  justify-content: right;
`;

export const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  padding: ${(props) => props.padding || "0"};
  margin: ${(props) => props.margin || "30px 0 0 0"};
`;
export const Left = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0;
  text-align: left;
`;
export const Right = styled.div`
  margin: 0;
  text-align: right;
`;
export const CancelButton = styled.button`
  height: 20px;
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2440ae;
  cursor: pointer;
  border: none;
  background-color: inherit;
`;
// Service Fee Form
export const FieldLabel = styled.span`
  font-size: 14px;
  font-weight: ${(props) => props.weight || "normal"};
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.01px;
  color: #191818;
`;
export const CentralizedWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;
export const CurrencyInputWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: ${(props) => props.width + "px" || "260px"};
  padding-left: 15px;
  height: 48px;
  margin: 0 5px;
  padding: 0;
  border-radius: 6px;
  border: ${(props) => `solid 1px ${props.borderColor}`};
  background-color: #fff;
`;
export const CurrencyInput = styled.input`
  width: 148px;
  padding: 0 5px;
  border: none;
  height: 44px;
  -webkit-appearance: none;
  -moz-appearance: textfield;
  border: none;
  outline: none;
`;
export const Symbol = styled.span`
  margin-left: 5px;
`;

export const IconText = styled.span`
  margin-right: 5px;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #191818;
`;
export const OverrideFields = styled.div`
  height: 18px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.01px;
  color: #191818;
  margin-bottom: 15px;
`;
export const CloseIcon = styled.i`
  font-size: 20px;
  cursor: pointer;
`;
export const DefaultFilterButton = styled.button`
  border-radius: 4px;
  padding: 7px 10px;
  cursor: pointer;
  background-color: #ffffff;
  color: #757575;
  border: 1px solid #c4c4c4;
  width: 100%;
  height: ${(props) => props.height};
  &:hover {
    color: #000000;
    border: 1px solid #000000;
  }
`;
