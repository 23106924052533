import React from 'react';
import { PageTitle, BackText } from '../styles/styles';
import GenericDetailsTemplate from './GenericDetailsTemplate';

const AutomatedMessagesDetails = ({
  details,
  setDetailView,
  saveMessage,
  savingIds,
  appIdentifier,
}) => {
  return (
    <div>
      <BackText
        onClick={() => {
          setDetailView(null);
        }}
      >
        <i className="fa fa-chevron-left" />
        <div>Back To Automated Messages</div>
      </BackText>
      <PageTitle>{details.title}</PageTitle>
      <div>
        {details.data.map((data, i) => (
          <GenericDetailsTemplate
            key={i}
            data={data}
            saveMessage={saveMessage}
            isSavingDisabled={savingIds.some((id) => id === data.id)}
            appIdentifier={appIdentifier}
          />
        ))}
      </div>
    </div>
  );
};

export default AutomatedMessagesDetails;
