import {SELECT_BUSINESS} from './types';

export function selectBusiness(business) {
  return {
    type: SELECT_BUSINESS,
    business
  };
}

export function selectBusinessById(businessId) {
  return function(dispatch, getState) {
    const allBusinesses = getState().businessLocationsList
      .businessLocationsOfMerchant;
    dispatch({
      type: SELECT_BUSINESS,
      business: allBusinesses.find(business => business.id == businessId)
    });
  };
  return;
}
