const renderQueryString = params => {
  let queryString = '?';

  Object.entries(params).forEach(([key, value]) => {
    if (key !== 'export') {
      queryString = `${queryString}${key}=${value}&`;
    }
  });

  return queryString.slice(0, -1);
};

export default renderQueryString;
