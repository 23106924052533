import React from 'react';
import styled from 'styled-components';
import PermissionsTable from './PermissionsTable';
const Title = styled.div`
  font-weight: 600;
  font-size: 18px;
`;
const TitleDiv = styled.div`
  padding: 30px 30px 20px 30px;
`;
const Roles = ({ accounts }) => {
  return (
    <div>
      <TitleDiv>
        <Title>Roles and Permissions</Title>
        <div>View the different permission levels for each page</div>
      </TitleDiv>
      <PermissionsTable accounts={accounts} />
    </div>
  );
};
export default Roles;
