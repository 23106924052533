export const renderOptionsModified = options => {
  const modifiedOptions = [];
  options.map(option => {
    if (option.sub_options.length) {
      option.sub_options.map(subOption => {
        modifiedOptions.push({
          ...subOption,
          name: `${option.name} - ${subOption.name}`
        });
      });
    } else modifiedOptions.push(option);
  });
  return modifiedOptions;
};

export const renderItemsModified = (items, variants, optionPrices) => {
  variants = variants.reduce((acc, val) => {
    acc[val.id] = val.name;
    return acc;
  }, {});

  if (optionPrices) {
    optionPrices.items.map(itemOptionPrice => {
      items = items.map(item => {
        if (itemOptionPrice.id === item.id) {
          if (item.sub_items.length && itemOptionPrice.sub_items.length) {
            item.sub_items.map(subItem => {
              itemOptionPrice.sub_items.map(subItemOptionPrice => {
                if (subItem.id === subItemOptionPrice.id) {
                  subItem = { ...subItem, prices: subItemOptionPrice.prices };
                  item = {
                    ...item,
                    sub_items: item.sub_items.map(sub => {
                      if (sub.id === subItem.id) return subItem;
                      else return sub;
                    })
                  };
                }
              });
            });
          } else {
            item = { ...item, prices: itemOptionPrice.prices };
          }
        }
        return item;
      });
    });

    items = items.map(item => {
      if (item.sub_items.length) {
        return item.sub_items.map(subItem => ({
          ...subItem,
          name: `${item.name} - ${subItem.name}`,
          prices: subItem.prices.map(variant => {
            return { ...variant, name: variants[variant.variant_id] };
          })
        }));
      } else {
        return {
          ...item,
          prices: item.prices.map(variant => {
            return { ...variant, name: variants[variant.variant_id] };
          })
        };
      }
    });

    items = items.flat(1);

    items = items.map(item => {
      item.pos_mappings = item.pos_mappings.reduce((acc, val) => {
        acc[val.product_variant_id] = val;
        return acc;
      }, {});
      return item;
    });
  }

  return items;
};
