import React from 'react';
import styled from 'styled-components';
import BreadCrumb from '../../components/BreadCrumb/BreadCrumb';
import { StyledP } from '../../components/FormElements/FormElements';
import { ComponentCard } from '../../components/FormElements/FormElements';
import {
  getEmployeeData,
  searchInformation,
  postNewEmployee,
  getEmployeeInformation,
  patchEmployee,
} from './OrderTabletUsersActions';
import { connect } from 'react-redux';

import AddNewUser from './components/AddNewUser';
import SettingsTable from './components/Table/SettingsTable';
import SearchUser from './components/SearchUser';
import { formatLocations } from '../../utils/childLocations/formatLocations';
import Alert from '../Alert/Alert';

const ContainerDiv = styled.div`
  padding: ${(props) => props.padding || '0 0 0 0'};
  margin: ${(props) => props.margin || '0 0 0 0'};
  width: ${(props) => props.width || 'inherit'};

  @media (max-width: 768px) {
    width: 80%;
  }
`;

function OrderTabletUsers(props) {
  const {
    employeeReducer,
    businesses,
    postNewEmployee,
    getEmployeeInformation,
    selectedEmployee,
    patchEmployee,
    passwordVerifier,
  } = props;

  const [open, setOpen] = React.useState(false);
  const [userSearch, setUserSearch] = React.useState(null);
  const [searchString, setSearchString] = React.useState('');

  const onSearchUsers = (ev) => {
    setSearchString(ev.target.value.toLowerCase().trim());
    setUserSearch(
      searchInformation(
        ev.target.value.toLowerCase().trim(),
        props.employeeReducer.data
      )
    );
  };

  React.useEffect(() => {
    props.getEmployeeData();
  }, []);
  const handleClearSearch = () => {
    setUserSearch(null);
    setSearchString('');
  };
  return (
    <React.Fragment>
      <Alert />
      <BreadCrumb pageName="Order Tablet Users" />
      <ComponentCard
        style={{
          borderRadius: '12px',
          boxShadow: '0 2px 4px 0 rgba(0,0,0,0.09)',
        }}
      >
        <ContainerDiv padding="32px 32px 32px 32px">
          <StyledP>
            View and manage the different users for your in-store Order
            Tablet(s)
          </StyledP>
        </ContainerDiv>
        <SearchUser
          open={() => setOpen(true)}
          onSearchUsers={onSearchUsers}
          searchString={searchString}
          handleClearSearch={handleClearSearch}
        />
        <ContainerDiv>
          <SettingsTable
            columns={['Location', 'Email']}
            rows={
              employeeReducer.data.length > 0 && !userSearch
                ? employeeReducer.data
                : userSearch
            }
            fields={['business.name', 'email']}
            getEmployeeInformation={(employee) =>
              getEmployeeInformation(employee)
            }
            selectedEmployee={selectedEmployee}
            businessesLocations={formatLocations(businesses)}
            employeeLocations={employeeReducer.data.map(
              (employee) => employee.business.name
            )}
            patchEmployee={(employee, data, cb) =>
              patchEmployee(employee, data, cb)
            }
            passwordVerifier={passwordVerifier}
          />
        </ContainerDiv>
        <AddNewUser
          open={open}
          close={() => setOpen(false)}
          businessesLocations={formatLocations(businesses)}
          employeeLocations={employeeReducer.data.map(
            (employee) => employee.business.name
          )}
          addNewEmployee={(data, cb) => postNewEmployee(data, cb)}
          passwordVerifier={passwordVerifier}
        />
      </ComponentCard>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  employeeReducer: state.OrderTabletUsersReducer,
  businesses: state.businessLocationsList.businessLocationsOfMerchant,
  selectedEmployee: state.OrderTabletUsersReducer.selectedEmployee,
  passwordVerifier: state.passwordVerifierReducer,
});

export default connect(mapStateToProps, {
  getEmployeeData,
  postNewEmployee,
  getEmployeeInformation,
  patchEmployee,
})(OrderTabletUsers);
