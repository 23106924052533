import {
  GET_OFFERS_REQUEST,
  GET_OFFERS_RESPONSE,
  GET_OFFERS_SUCCESS
} from './types';

export default function(
  state = {
    requestingOffers: false,
    response: null
  },
  action
) {
  switch (action.type) {
    case GET_OFFERS_REQUEST:
      return { ...state, requestingOffers: true };
    case GET_OFFERS_RESPONSE:
      return {
        ...state,
        requestingOffers: false,
        response: action.response.data
      };
    case GET_OFFERS_SUCCESS:
      return {
        ...state,
        requestingOffers: false,
        response: action.allOffers
      };
    default:
      return state;
  }
}
