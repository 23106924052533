import { clearFields } from "redux-form";

export const getMaxPageNoLimit = (itemList, itemCountPerPg) => {
    return Math.ceil(itemList.length / itemCountPerPg );
}

export const getCustomerViewLazyLoader = (pageNo, itemCount, itemList, conditionMapperFn)=>  {
    let count = 0;
    let page = 0;
    let newSelectViewList = [];
    

    itemList.map(item => {
        const val = conditionMapperFn(item);

        if (page < pageNo) {
            count++;
        }

        if (page == pageNo && val && count < itemCount) {
            newSelectViewList.push(item);
            count++;
        }

        if(count > itemCount) {
            page++;
            count = 0;
        }
        
        if(page > pageNo) {
            return newSelectViewList;
        }
    })
    return newSelectViewList;
}

export const noCondition = (item) => {
    return item;
}