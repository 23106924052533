import styled from 'styled-components'

export const Wrapper = styled.div`
    padding: 30px;
    display: grid;
`;

export const Title = styled.div`
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 30px;
`;

export const ImageAndFieldWrapper = styled.div`
    display: grid;
    grid-template-columns: 245px auto;
    grid-gap: 40px;

`;
export const FieldWrapper = styled.div`
    display: grid;
    grid-template-columns: auto 50px;
    grid-gap: 20px;
`;
export const RightWrapper = styled.div`
    display: grid;
    padding: 20px;
`;

export const ImageWrapper = styled.div`
    border: ${props => props.src ? 'none' : '2px dashed #2440ae'};
    border-radius: 6px;
    height: 245px;
 
`;

export const HelperTextAndImageUploadButton = styled.div`
    display: grid;
    #imageUpload {
        display: none;
    }
    p {
        font-family: Montserrat;
        font-size: 14px;
        margin: 50px 40px 0 40px;
    }
    label {
        background: #e6ebff;
        padding: 10px;
        text-decoration: none;
        border-radius: 8px;
        margin-top: 10px;
        font-weight: 600;
        font-family: Montserrat;
        color: #2440ae;
        margin: 5% 12%;
        width: 180px;
        cursor: pointer;
    }
`;

export const DeleteAndCropWrapper = styled.div`
    margin-left: 60px;
    .fas {
        color: #2440ae;
        border: 1px solid #2440ae;
        border-radius: 50%;
        padding: 8px;
        margin: 10px;
    }
    .fas:hover {
        color: #fff;
        background: #2440ae;
        border-radius: 50%;
    }
`;

export const Image = styled.img`
    border-radius: 5%;
    object-fit: cover;
`;

export const ImageError = styled.p`
    color: rgb(244,67,54);
    font-size: 12px;
    padding: 5px;
`;