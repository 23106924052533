import React, { Fragment, useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import { TextField, Checkbox, InputAdornment } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Formik, Form, Field } from "formik";
import { RenderTextField } from "../../../../components/FormElements/FormikElements";
import { SmallToggleSwitch } from "../../../../components/ToggleSwitch/ToggleSwitch";
import StickyButtons from "../../../../components/StickyButtons";
import { Icon } from "merchant-portal-components";
import OptionOverridesModalContainer from "../OptionOverridesModalContainer";
import { LoadingBlueLarge } from "merchant-portal-components";
import { SELECT_LOCATION_SCREEN, SUMMARY_SCREEN } from "../priceTypes";
import { finalStructureSummaryData } from "../utils/helper";
import _ from "lodash";

const Card = styled.div`
  background-color: #fff;
  border-radius: 20px;
  padding-bottom: 48px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
`;
const Title = styled.div`
  margin-bottom: 15px;
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
`;
const SearchWrapper = styled.div`
  padding: 25px 50px 35px;
`;
const HeaderWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  padding: 48px 51px 9px 51px;
  grid-gap: 10px;
`;
const HorizontalLine = styled.hr`
  border-top: 1px solid #e1e1e1;
`;
const Header = styled.div`
  font-weight: 600;
`;

const RowWrapper = styled.div`
  padding: 24px 32px;
  display: grid;
  grid-gap: 10px;
  border: 1px solid #e1e1e1;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
`;

const VariantName = styled.div`
  color: ${(props) => (props.isSelected ? null : "red")};
`;

const VariantWrapper = styled.div`
  display: grid;
  grid-gap: 15px;
  align-items: center;
`;

const OverridesWrapper = styled.div`
  text-align: center;
`;

const IconWrapper = styled(Icon)`
  margin-left: 6px;
  font-size: 14px;
`;

const validatePrices = (items, priceKey) => {
  let errors = [];
  items.forEach((item) => {
    if (item.sub_items) {
      item.sub_items.forEach((subItem) => {
        subItem.prices.forEach((price) => {
          if (
            price.isSelected &&
            (price.price === null || price.price === "")
          ) {
            errors = [
              ...errors,
              {
                id: item.id,
                sub_item_id: subItem.id,
                variant_id: price.variant_id,
                error: "Required",
              },
            ];
          }
          if (
            price.isSelected &&
            (price.additional_price === null || price.additional_price === "")
          ) {
            errors = [
              ...errors,
              {
                id: item.id,
                sub_item_id: subItem.id,
                variant_id: price.variant_id,
                error: "Required",
              },
            ];
          }
        });
      });
    } else {
      item.prices.forEach((price) => {
        if (price.isSelected && (price.price === null || price.price === "")) {
          errors = [
            ...errors,
            { id: item.id, variant_id: price.variant_id, error: "Required" },
          ];
        }
        if (
          price.isSelected &&
          (price.additional_price === null || price.additional_price === "")
        ) {
          errors = [
            ...errors,
            { id: item.id, variant_id: price.variant_id, error: "Required" },
          ];
        }
      });
    }
  });

  return errors;
};

const OptionPricesForm = ({
  items,
  selectedOption,
  optionPrices,
  includedPick,
  handleSave,
  isRequesting,
  itemOverrides,
  setSelectedOption,
  isRequestingItemOverrides,
  formattedBusinesses,
  isOptionOverridesModal,
  setIsOptionOverridesModal,
  page,
  setPage,
  isOptionDisabled,
  setIsOptionDisabled,
}) => {
  const [selectedItemIdList, setSelectedItemIdList] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);
  const handleSelectItem = (e, selectedItems) => {
    let selcetedItemsIdList = [];
    let newSelectedItems = [];
    selectedItems?.map((item, index) => {
      if (!selcetedItemsIdList.includes(item.id)) {
        selcetedItemsIdList.push(item.id);
      } else {
        const removeIndex = selcetedItemsIdList.indexOf(item.id);
        selcetedItemsIdList.splice(removeIndex, 1);
      }
    });
    selcetedItemsIdList?.map((id) => {
      newSelectedItems.push(selectedItems.find((item) => item.id === id));
    });
    if (selcetedItemsIdList?.length > 0) {
      if (selcetedItemsIdList.includes(0)) {
        if (selcetedItemsIdList.length < items.length + 1) {
          items?.map((item) => {
            if (!selcetedItemsIdList.includes(item.id)) {
              selcetedItemsIdList.push(item.id);
            }
          });
          setSelectedItemIdList(selcetedItemsIdList);
          setSelectedValue(items);
        } else {
          setSelectedValue([]);
          setSelectedItemIdList(null);
        }
      } else {
        setSelectedItemIdList(selcetedItemsIdList);
        setSelectedValue(newSelectedItems);
      }
    } else {
      setSelectedValue([]);
      setSelectedItemIdList(null);
    }
  };
  const modifyItemList = useCallback(() => {
    const oldValueIds = selectedValue.map((item) => item.id);
    let newItems = JSON.parse(JSON.stringify(items));
    newItems.unshift({ id: 0, name: "All Option Items" });
    setSelectedItems(newItems);
    let newSelectedValue = [];
    if (selectedValue?.length > 0) {
      items.forEach((item) => {
        if (oldValueIds.includes(item.id)) {
          newSelectedValue.push(item);
        }
      });
      setSelectedValue(newSelectedValue);
      if (newSelectedValue.length === 0) {
        setSelectedItemIdList(null);
        handleSelectItem(_, []);
      }
    } else {
      setSelectedValue([]);
      setSelectedItemIdList(null);
      handleSelectItem(_, []);
    }
  }, [items]);
  useEffect(() => {
    modifyItemList();
  }, [modifyItemList]);

  if (!itemOverrides || isRequestingItemOverrides) return <LoadingBlueLarge />;
  const priceKey = includedPick === 0 ? "price" : "additional_price";
  if (setSelectedOption && itemOverrides?.length) {
    itemOverrides = itemOverrides.map((itemOverride) => itemOverride.data);

    let totSubItems = 0;

    items = items.map((item, index) => {
      let subItemsModified = [];

      if (index != 0) {
        const addition = items[index - 1].sub_items
          ? items[index - 1].sub_items.length
          : 1;
        totSubItems = totSubItems + addition;
      }

      if (item.sub_items) {
        item.sub_items?.map((subItem, subItemIndex) => {
          const overridesData = itemOverrides[subItemIndex + totSubItems];

          if (overridesData && !overridesData?.length) {
            subItemsModified.push({ ...subItem, overrides: 0 });
          } else {
            const summaryData = finalStructureSummaryData(
              itemOverrides.find((overrides) =>
                overrides.some(
                  (override) => override.product_option_item_id === subItem.id
                )
              ) || [],
              subItem,
              formattedBusinesses,
              priceKey
            );

            let summaryDataModified;
            summaryDataModified = summaryData?.map((data) => ({
              ...data,
              item: {
                prices: data.item.prices.filter(
                  (variant) => variant.name && variant.isSelected
                ),
              },
            }));

            summaryDataModified = summaryDataModified.filter(
              (data) => data.item.prices.length > 0
            );

            subItemsModified.push({
              ...subItem,
              overrides: summaryDataModified.length,
            });
          }
        });
        return {
          ...item,
          sub_items: subItemsModified,
        };
      } else {
        const overridesData = itemOverrides[totSubItems];
        if (!overridesData?.length) {
          return {
            ...item,
            overrides: 0,
          };
        }

        const summaryData = finalStructureSummaryData(
          itemOverrides.find((overrides) =>
            overrides.some(
              (override) => override.product_option_item_id === item.id
            )
          ) || [],
          item,
          formattedBusinesses,
          priceKey
        );

        let summaryDataModified;
        summaryDataModified = summaryData?.map((data) => ({
          ...data,
          item: {
            prices: data.item.prices.filter(
              (variant) => variant.name && variant.isSelected
            ),
          },
        }));

        summaryDataModified = summaryDataModified.filter(
          (data) => data.item.prices.length > 0
        );

        return {
          ...item,
          overrides: summaryDataModified.length,
        };
      }
    });
  }
  let totSubItem = 0;
  const submitHandler = (filteredItems) => {
    const filterdItemsIDs = filteredItems.map((item) => item.id);
    let itemsToSave = [];
    items?.forEach((item) => {
      if (filterdItemsIDs.includes(item.id)) {
        const findFilteredItem = filteredItems.find(
          (filterdItem) => filterdItem.id === item.id
        );
        itemsToSave.push(findFilteredItem);
      } else {
        itemsToSave.push(item);
      }
    });
    handleSave(itemsToSave, filteredItems);
  };
  return (
    <div>
      <Card>
        <SearchWrapper>
          <Title>Search or Select Items</Title>
          <Autocomplete
            multiple
            id="checkboxes-tags-demo"
            options={selectedItems}
            disableCloseOnSelect
            getOptionLabel={(item) => item.name}
            onChange={(e, selectedItems) => {
              handleSelectItem(e, selectedItems);
            }}
            value={selectedValue}
            renderOption={(item, option) => (
              <div style={{ fontWeight: item.id === 0 ? "bold" : "normal" }}>
                <Checkbox
                  color="primary"
                  style={{
                    marginRight: "5px",
                    transform: "scale(0.9)",
                  }}
                  checked={
                    selectedItemIdList?.includes(0) ||
                    selectedItemIdList?.length === items?.length
                      ? true
                      : selectedItemIdList?.includes(item.id)
                      ? true
                      : option.selected
                  }
                />
                {item.name}
              </div>
            )}
            style={{ width: "100%" }}
            renderInput={(params) => (
              <TextField {...params} label="Select Items" variant="outlined" />
            )}
            disabled={isOptionDisabled}
          />
        </SearchWrapper>
        {selectedItemIdList && (
          <Formik
            initialValues={{ items: selectedValue }}
            enableReinitialize={true}
          >
            {({ dirty, values, setValues, setFieldValue, handleReset }) => (
              <>
                <Form onLoad={setIsOptionDisabled(dirty)}>
                  <HeaderWrapper>
                    <Header>Items</Header>
                    <Header>Price Equal Across Variants?</Header>
                    <Header>Variants</Header>
                    <Header>Price</Header>
                    <Header>Additional Price</Header>
                    <Header>Add Overrides</Header>
                  </HeaderWrapper>
                  <HorizontalLine />
                  {selectedItemIdList &&
                    values.items?.map((item, index) => {
                      if (item.sub_items && index != 0) {
                        const addition = items[index - 1].sub_items
                          ? items[index - 1].sub_items?.length
                          : 1;
                        totSubItem = totSubItem + addition;
                        // totSubItem = totSubItem + items[index - 1].sub_items.length;
                      }
                      return (
                        <Fragment key={index}>
                          <RowWrapper>
                            <div>{item.name}</div>
                            {!item.sub_items && (
                              <Fragment>
                                <div style={{ textAlign: "center" }}>
                                  <SmallToggleSwitch
                                    checked={item.variantsAreEqual}
                                    onChange={(e) => {
                                      setFieldValue(
                                        `items[${index}].variantsAreEqual`,
                                        e.target.checked
                                      );

                                      item.prices.forEach(
                                        (price, priceIndex) => {
                                          priceIndex !== 0 &&
                                            setFieldValue(
                                              `items[${index}].prices[${priceIndex}].price`,
                                              item.prices[0].price
                                            );
                                          priceIndex !== 0 &&
                                            setFieldValue(
                                              `items[${index}].prices[${priceIndex}].additional_price`,
                                              item.prices[0].additional_price
                                            );
                                        }
                                      );
                                    }}
                                  />
                                </div>
                                <VariantWrapper>
                                  {!item.variantsAreEqual ? (
                                    item.prices?.map((price, priceIndex) => (
                                      <VariantName
                                        isSelected={price.isSelected}
                                        key={priceIndex}
                                      >
                                        {price.variantName}
                                      </VariantName>
                                    ))
                                  ) : (
                                    <div>
                                      {
                                        item.prices.filter(
                                          (price) => price.isSelected
                                        ).length
                                      }{" "}
                                      Variants
                                    </div>
                                  )}
                                </VariantWrapper>
                                <VariantWrapper>
                                  {!item.variantsAreEqual ? (
                                    item.prices?.map((price, priceIndex) =>
                                      price.isSelected ? (
                                        <Field
                                          key={priceIndex}
                                          name={`items[${index}].prices[${priceIndex}].price`}
                                          component={RenderTextField}
                                          required
                                          label="Price"
                                          isPrice={true}
                                          InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                $
                                              </InputAdornment>
                                            ),
                                          }}
                                        />
                                      ) : (
                                        <div style={{ height: "55px" }} />
                                      )
                                    )
                                  ) : (
                                    <Field
                                      name={`items[${index}].prices[0].price`}
                                      component={RenderTextField}
                                      InputProps={{
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            $
                                          </InputAdornment>
                                        ),
                                      }}
                                      required
                                      label="Price"
                                      isPrice={true}
                                      onChange={(e) =>
                                        item.prices.forEach(
                                          (price, priceIndex) => {
                                            priceIndex !== 0 &&
                                              setFieldValue(
                                                `items[${index}].prices[${priceIndex}].price`,
                                                e.target.value
                                              );
                                          }
                                        )
                                      }
                                    />
                                  )}
                                </VariantWrapper>
                                <VariantWrapper>
                                  {!item.variantsAreEqual ? (
                                    item.prices?.map((price, priceIndex) =>
                                      price.isSelected ? (
                                        <Field
                                          key={priceIndex}
                                          name={`items[${index}].prices[${priceIndex}].additional_price`}
                                          component={RenderTextField}
                                          required
                                          label="Additional Price"
                                          isPrice={true}
                                          InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                $
                                              </InputAdornment>
                                            ),
                                          }}
                                        />
                                      ) : (
                                        <div style={{ height: "55px" }} />
                                      )
                                    )
                                  ) : (
                                    <Field
                                      name={`items[${index}].prices[0].additional_price`}
                                      component={RenderTextField}
                                      required
                                      label="Additional Price"
                                      isPrice={true}
                                      onChange={(e) =>
                                        item.prices.forEach(
                                          (price, priceIndex) => {
                                            priceIndex !== 0 &&
                                              setFieldValue(
                                                `items[${index}].prices[${priceIndex}].additional_price`,
                                                e.target.value
                                              );
                                          }
                                        )
                                      }
                                      InputProps={{
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            $
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  )}
                                </VariantWrapper>
                                <OverridesWrapper>
                                  {item.overrides === 0
                                    ? "No Overrides"
                                    : `Overrides`}
                                  {item.overrides === 0 ? (
                                    <IconWrapper
                                      className="fal fa-plus"
                                      onClick={() => {
                                        setIsOptionOverridesModal({
                                          isShowing: true,
                                          item,
                                          itemIndex: index,
                                        });
                                        setPage(SELECT_LOCATION_SCREEN);
                                      }}
                                    />
                                  ) : (
                                    <IconWrapper
                                      className="fas fa-pencil"
                                      onClick={() => {
                                        setIsOptionOverridesModal({
                                          isShowing: true,
                                          item,
                                          itemIndex: index,
                                        });
                                        setPage(SUMMARY_SCREEN);
                                      }}
                                    />
                                  )}
                                </OverridesWrapper>
                              </Fragment>
                            )}
                          </RowWrapper>
                          {item.sub_items &&
                            item.sub_items?.map((subItem, subItemIndex) => (
                              <RowWrapper key={subItemIndex}>
                                <div style={{ paddingLeft: "20px" }}>
                                  {subItem.name}
                                </div>
                                <div>
                                  <SmallToggleSwitch
                                    checked={subItem.variantsAreEqual}
                                    onChange={(e) =>
                                      setFieldValue(
                                        `items[${index}].sub_items[${subItemIndex}].variantsAreEqual`,
                                        e.target.checked
                                      )
                                    }
                                  />
                                </div>
                                <VariantWrapper>
                                  {!subItem.variantsAreEqual ? (
                                    subItem.prices?.map((price, priceIndex) => (
                                      <VariantName
                                        isSelected={price.isSelected}
                                        key={priceIndex}
                                      >
                                        {price.variantName}
                                      </VariantName>
                                    ))
                                  ) : (
                                    <div>
                                      {
                                        subItem.prices.filter(
                                          (price) => price.isSelected
                                        ).length
                                      }{" "}
                                      Variants
                                    </div>
                                  )}
                                </VariantWrapper>
                                <VariantWrapper>
                                  {!subItem.variantsAreEqual ? (
                                    subItem.prices?.map((price, priceIndex) =>
                                      price.isSelected ? (
                                        <Field
                                          key={priceIndex}
                                          name={`items[${index}].sub_items[${subItemIndex}].prices[${priceIndex}].price`}
                                          component={RenderTextField}
                                          required
                                          label="Price"
                                          isPrice={true}
                                          InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                $
                                              </InputAdornment>
                                            ),
                                          }}
                                        />
                                      ) : (
                                        <div style={{ height: "55px" }} />
                                      )
                                    )
                                  ) : (
                                    <Field
                                      name={`items[${index}].sub_items[${subItemIndex}].prices[0].price`}
                                      component={RenderTextField}
                                      required
                                      label="Price"
                                      isPrice={true}
                                      onChange={(e) =>
                                        subItem.prices.forEach(
                                          (price, priceIndex) => {
                                            priceIndex !== 0 &&
                                              setFieldValue(
                                                `items[${index}].sub_items[${subItemIndex}].prices[${priceIndex}].price`,
                                                e.target.value
                                              );
                                          }
                                        )
                                      }
                                      InputProps={{
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            $
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  )}
                                </VariantWrapper>
                                <VariantWrapper>
                                  {!subItem.variantsAreEqual ? (
                                    subItem.prices?.map((price, priceIndex) =>
                                      price.isSelected ? (
                                        <Field
                                          key={priceIndex}
                                          name={`items[${index}].sub_items[${subItemIndex}].prices[${priceIndex}].additional_price`}
                                          component={RenderTextField}
                                          required
                                          label="Additional Price"
                                          isPrice={true}
                                          InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                $
                                              </InputAdornment>
                                            ),
                                          }}
                                        />
                                      ) : (
                                        <div style={{ height: "55px" }} />
                                      )
                                    )
                                  ) : (
                                    <Field
                                      name={`items[${index}].sub_items[${subItemIndex}].prices[0].additional_price`}
                                      component={RenderTextField}
                                      required
                                      label="Additional Price"
                                      isPrice={true}
                                      onChange={(e) =>
                                        subItem.prices.forEach(
                                          (price, priceIndex) => {
                                            priceIndex !== 0 &&
                                              setFieldValue(
                                                `items[${index}].sub_items[${subItemIndex}].prices[${priceIndex}].additional_price`,
                                                e.target.value
                                              );
                                          }
                                        )
                                      }
                                      InputProps={{
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            $
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  )}
                                </VariantWrapper>
                                <OverridesWrapper>
                                  {subItem.overrides === 0
                                    ? "No Overrides"
                                    : `Overrides`}
                                  {subItem.overrides === 0 ? (
                                    <IconWrapper
                                      className="fal fa-plus"
                                      onClick={() => {
                                        setIsOptionOverridesModal({
                                          isShowing: true,
                                          item: subItem,
                                          itemIndex: subItemIndex + totSubItem,
                                        });
                                        setPage(SELECT_LOCATION_SCREEN);
                                      }}
                                    />
                                  ) : (
                                    <IconWrapper
                                      className="fas fa-pencil"
                                      onClick={() => {
                                        setIsOptionOverridesModal({
                                          isShowing: true,
                                          item: subItem,
                                          itemIndex: subItemIndex + totSubItem,
                                        });
                                        setPage(SUMMARY_SCREEN);
                                      }}
                                    />
                                  )}
                                </OverridesWrapper>
                              </RowWrapper>
                            ))}
                          <StickyButtons
                            isVisible={dirty}
                            handleDiscard={() => handleReset()}
                            saveIsDisabled={
                              validatePrices(values.items, priceKey).length ||
                              isRequesting
                            }
                            handleSave={() => {
                              submitHandler(values.items);
                            }}
                            title="Price Management"
                          />
                        </Fragment>
                      );
                    })}
                </Form>
              </>
            )}
          </Formik>
        )}
      </Card>

      {isOptionOverridesModal.isShowing && (
        <Fragment>
          <OptionOverridesModalContainer
            item={isOptionOverridesModal.item}
            setIsOptionOverridesModal={setIsOptionOverridesModal}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            priceKey={priceKey}
            singleItemOverrides={
              itemOverrides.find((overrides) =>
                overrides.some(
                  (override) =>
                    override.product_option_item_id ===
                    isOptionOverridesModal.item.id
                )
              ) || []
            }
            page={page}
            setPage={setPage}
            items={items}
            formattedBusinesses={formattedBusinesses}
          />
        </Fragment>
      )}
    </div>
  );
};

export default OptionPricesForm;
