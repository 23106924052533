import React, { useState, useEffect, useCallback } from "react";
import _ from "lodash";
import { TooltipIcon } from "../Icon";
import { LoadingBlueLarge } from "merchant-portal-components";
import PaginationControl from "../Pagination/PaginationControl";
import { paginate } from "./paginate";
import { Button } from "merchant-portal-components";
import { Checkbox, TextField, InputAdornment } from "@material-ui/core";
import {
  StyledCard,
  HeaderWrapper,
  SearchWrapper,
  SectionTitle,
  SectionSubTitle,
  LocationsCounter,
  RowWrapper,
  TH,
  TD,
  PaginationWrapper,
  ButtonWrapper,
  Left,
  Right,
  CancelButton,
  CloseIcon,
} from "./filterModalStyles";

const initiallRequestParams = {
  page_number: 1,
  page_size: "10",
  sorting_option: "title-asc",
};
const ServiceFeeLocationList = ({
  list,
  requestingGetBusinessLocationsOfMerchant,
  filteredLocations,
  setFilteredLocations,
  requestParams,
  updateRequestParams,
  paginationType,
  setIsOpen,
  placeholder,
  searchLabel,
}) => {
  const initialActiveBusinesses = filteredLocations.active || [];
  const initialInactiveBusinesses = filteredLocations.inactive || [];
  const [activeBusinesses, setActiveBusinesses] = useState(
    initialActiveBusinesses
  );
  const [inactiveBusinesses, setInactiveBusinesses] = useState(
    initialInactiveBusinesses
  );
  const [businessList, setBusinessList] = useState([]);
  const [businessListLength, setBusinessListLength] = useState([]);
  const [showActives, setShowActive] = useState(false);
  const [showInactives, setShowInactive] = useState(false);
  const [showAssigned, setShowAssigned] = useState(false);
  const [showUnassigned, setShowUnassigned] = useState(false);
  // search
  const [searchString, setSearchString] = useState("");
  useEffect(() => {
    const filteredList = list.filter((item) =>
      item.business_name?.toLowerCase().includes(searchString?.toLowerCase())
    );
    const paginatedList = paginate(filteredList, 1, requestParams.page_size);
    updateRequestParams({ ...requestParams, page_number: 1 });
    setBusinessList(paginatedList);
    setBusinessListLength(filteredList.length);
  }, [searchString]);

  //
  const filterBusinessList = useCallback(() => {
    let filteredList = list;
    if (showActives) {
      filteredList = filteredList
        .filter((item) => activeBusinesses.includes(item.id))
        .slice();
      setShowInactive(false);
    }
    if (showInactives) {
      filteredList = filteredList
        .filter((item) => inactiveBusinesses.includes(item.id))
        .slice();
      setShowActive(false);
    }
    if (showAssigned) {
      filteredList = filteredList
        .filter(
          (item) =>
            activeBusinesses.includes(item.id) ||
            inactiveBusinesses.includes(item.id)
        )
        .slice();
      setShowUnassigned(false);
    }
    if (showUnassigned) {
      filteredList = filteredList
        .filter(
          (item) =>
            !(
              activeBusinesses.includes(item.id) ||
              inactiveBusinesses.includes(item.id)
            )
        )
        .slice();
      setShowAssigned(false);
    }
    if (searchString) {
      filteredList = filteredList?.filter((item) =>
        item.business_name?.toLowerCase().includes(searchString?.toLowerCase())
      );
    }
    let paginatedList = paginate(
      filteredList,
      requestParams.page_number,
      requestParams.page_size
    );
    setBusinessList(paginatedList);
    setBusinessListLength(filteredList.length);
  }, [
    requestParams.page_number,
    requestParams.page_size,
    activeBusinesses,
    inactiveBusinesses,
    showActives,
    showInactives,
    showAssigned,
    showUnassigned,
    JSON.stringify(list),
  ]);
  useEffect(() => {
    updateRequestParams(initiallRequestParams);
    return () => {
      updateRequestParams(initiallRequestParams);
    };
  }, []);
  useEffect(() => {
    filterBusinessList();
  }, [filterBusinessList]);
  const handleAssignBusinesses = (e, id) => {
    let newActiveBusinesses = [...activeBusinesses];
    let newInactiveBusinesses = [...inactiveBusinesses];
    let isActive = activeBusinesses.includes(id);
    if (isActive) {
      newActiveBusinesses = activeBusinesses.filter(
        (currentId) => currentId !== id
      );
      newInactiveBusinesses.push(id);
    } else {
      newActiveBusinesses.push(id);
      newInactiveBusinesses = inactiveBusinesses.filter(
        (currentId) => currentId !== id
      );
    }
    setActiveBusinesses(newActiveBusinesses);
    setInactiveBusinesses(newInactiveBusinesses);
  };
  const handleAssignAllBusinesses = (e) => {
    let isActive = e.target.checked;
    let newActiveBusinesses = [];
    let newInactiveBusinesses = [];
    if (isActive) {
      list.map((business) => {
        newActiveBusinesses.push(business.id);
      });
    }
    setActiveBusinesses(newActiveBusinesses);
    setInactiveBusinesses(newInactiveBusinesses);
  };
  const handleAddBusinesses = () => {
    let assignedBusinesses = {
      active: activeBusinesses,
      inactive: inactiveBusinesses,
    };
    setFilteredLocations(assignedBusinesses);
    setIsOpen(false);
  };
  const handleReset = () => {
    setActiveBusinesses(initialActiveBusinesses.sort());
    setInactiveBusinesses(initialInactiveBusinesses.sort());
  };
  return (
    <StyledCard>
      <HeaderWrapper padding="0">
        <div>
          <SectionTitle>Locations</SectionTitle>
          <LocationsCounter>
            {`${activeBusinesses.length} locations selected`}
          </LocationsCounter>
        </div>
        <div style={{ textAlign: "right" }}>
          <CloseIcon
            className="fa fa-times"
            onClick={() => setIsOpen(false)}
          ></CloseIcon>
        </div>
      </HeaderWrapper>
      <SearchWrapper>
        <TextField
          id="outlined-basic"
          label={searchLabel}
          placeholder={placeholder}
          variant="outlined"
          style={{ width: "100%" }}
          value={searchString}
          onChange={(e) => setSearchString(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <i className="fa fa-search" style={{ color: "#b5b5b5" }}></i>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                {`${businessListLength} results`}
              </InputAdornment>
            ),
          }}
        />
      </SearchWrapper>
      <RowWrapper>
        <TH>
          <Checkbox
            checked={list.length === activeBusinesses.length}
            onChange={handleAssignAllBusinesses}
            color="primary"
            style={{
              transform: "scale(1.3)",
            }}
          />
        </TH>
        <TH>Select All Locations</TH>
      </RowWrapper>
      {businessList.map((businessLocation, index) => (
        <RowWrapper
          key={index}
          hoverBGColor="#f1f1fa"
          onClick={(e) => handleAssignBusinesses(e, businessLocation.id)}
        >
          <TD bgColor="yellow">
            <Checkbox
              checked={activeBusinesses.includes(businessLocation.id)}
              onChange={(e) => handleAssignBusinesses(e, businessLocation.id)}
              color="primary"
              style={{
                transform: "scale(1.3)",
              }}
            />
          </TD>
          <TD isAssigned={activeBusinesses.includes(businessLocation.id)}>
            {businessLocation.business_name}
          </TD>
        </RowWrapper>
      ))}
      <PaginationWrapper>
        <PaginationControl
          response={{
            ...businessList,
            page: requestParams.page_number,
            page_size: requestParams.page_size,
            count: businessListLength,
          }}
          requestParams={requestParams}
          type={paginationType}
        />
      </PaginationWrapper>
      <ButtonWrapper>
        <Left>
          <CancelButton onClick={handleReset}>Cancel</CancelButton>
        </Left>
        <Right>
          <Button
            onClick={handleAddBusinesses}
            disabled={_.isEqual(initialActiveBusinesses, activeBusinesses)}
          >
            Save
          </Button>
        </Right>
      </ButtonWrapper>
      {requestingGetBusinessLocationsOfMerchant ? <LoadingBlueLarge /> : null}
    </StyledCard>
  );
};

export default ServiceFeeLocationList;
