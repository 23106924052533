import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Field } from 'formik';
import _, { template } from 'lodash';
import Card from '../../../components/Card/Card';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Requirement from '../components/Requirement';
import { RenderTextField } from '../../../components/FormElements/FormikElements';
import { MenuItem } from '@material-ui/core';
import RewardDetails from '../components/RewardDetail';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MiniToggleSwitch from '../../../components/ToggleSwitchSmall/ToggleSwitchSmall';

const rewardOptions = [
  { value: 'Select Offer Type', label: 'Select Offer Type' },
  { value: 'first-time', label: 'First-time' },
  { value: 'free-item', label: 'Free products' },
  { value: 'discounted-item', label: 'Percentage discount' },
  { value: 'dollar-discount', label: 'Dollar discount' },
  { value: 'bonus-points', label: 'Bonus loyalty points' },
  { value: 'points-multiplier', label: 'Points Multiplier' },
  { value: 'carryover-loyalty', label: 'Carry-over' },
];

const Wrapper = styled.div`
  display: grid;
  align-items: center;
  .mb25 {
    margin-bottom: 25px;
  }
  .mb25 .MuiSelect-select {
    border: 1px solid #cdcdcd;
    padding: 18px 15px;
    border-radius: 4px;
    min-width: 350px;
  }
  .MuiInput-underline:before,
  .MuiInput-underline:after {
    border: 0px !important;
  }
`;
const TierMain = styled.div`
  padding-left: 15px;
`;
const NoteInside = styled.div`
  padding-left: 15px;
  font-size: 15px;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 10px;
`;
const SubnoteInside = styled.div`
  padding-left: 15px;
  font-size: 12px;
  margin-top: -10px;
  margin-bottom: 10px;
`;
const WrapperInner = styled.div`
  display: grid;
  align-items: center;
  margin-left: 30px;
`;
const Subtitle = styled.div`
  font-size: 21px;
  font-weight: 600;
  letter-spacing: 0.03px;
  color: #191818;
`;
const WrapperInside = styled.div`
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 10px;
  padding-top: 10px;
  .addProduct {
    background: none;
    border: 0px;
    font-size: 15px;
    font-weight: 600;
    margin-top: 20px;
    cursor: pointer;
  }
`;
const FullWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 65px;
  align-items: center;
  .formSection {
    display: grid;
  }
`;
const NoteText = styled.div`
  font-size: 14px;
  color: #191818;
  margin-bottom: 25px;
`;
const NoteHead = styled.div`
  font-size: 17px;
  color: #191818;
  font-weight: 600;
  margin-bottom: 5px;
`;
const NoteHeadNormal = styled.span`
  font-size: 17px;
  color: #191818;
  font-weight: normal;
`;
const SubHead = styled.div`
  font-size: 17px;
  color: #191818;
  font-weight: 600;
  margin-bottom: 15px;
`;
const SubHeadDummy = styled.div`
  font-size: 14px;
  color: #6f6f76;
  font-weight: normal;
  margin-bottom: 15px;
  max-width: 360px;
  margin: 0 auto;
  text-align: center;
`;
const IconDummy = styled.div`
  font-size: 60px;
  color: #ababab;
  text-align: center;
  width: 60px;
  margin-bottom: 15px;
`;
const NotAvailable = styled.div`
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
`;
const Divider = styled.hr`
  border-bottom: 1px solid #ebebeb;
  margin-top: 15px;
  /* margin-bottom: 26px; */
`;
const ActionBox = styled.div`
  display: flex;
  padding-left: 15px;
  max-width: 120px;
`;
const SubtitleBlock = styled.div`
  margin-bottom: 15px;
`;
const CheckBoxContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 80px auto;
  align-items: center;
`;

const SlideTwo = ({
  values,
  setFieldValue,
  isSegmentVisible,
  setisSegmentVisible,
  isConsumerVisible,
  conditionValue,
  setisConsumerVisible,
  isMobileRequire,
  setisMobileRequire,
  isWebRequire,
  setisWebRequire,
  isScanRequire,
  setisScanRequire,
  offerEngineSource,
  offerEngineProducts,
  allProductVariants,
  errors,
  loyaltyTiers,
}) => {
  const [currentSortedSources, setCurrentSortedSources] = useState([]);
  const [loyaltyHasError, setLoyaltyHasError] = useState(false);
  const [pointPropertyIsEnabled, setPointPropertyIsEnabled] = useState(false);
  const [birthdayPropertyIsEnabled, setBirthdayPropertyIsEnabled] =
    useState(false);
  const [tierPropertyIsEnabled, setTierPropertyIsEnabled] = useState(false);
  const [posMappingStringValue, setPosMappingStringValue] = useState('');
  const [isKeepSourceModalOpen, setIsKeepSourceModalOpen] = useState(false);
  const [hasFreeItemAndPoints, setHasFreeItemAndPoints] = useState(false);
  const isTierEnabled = localStorage.getItem('is_tiers_enabled');
  let mobileIndex = values.conditionValue
    .map((e) => e.source)
    .indexOf('mobile');
  let webIndex = values.conditionValue.map((e) => e.source).indexOf('web');
  let scanIndex = values.conditionValue
    .map((e) => e.source)
    .findIndex((source) => !['web', 'mobile'].includes(source));
  const pointIndex =
    values.conditionValue[0]?.requirements.length > 0
      ? values.conditionValue[0]?.requirements?.findIndex(
          (x) => x['points'] != undefined
        )
      : 0;

  const birthdateIndex =
    values.conditionValue[0]?.requirements.length > 0
      ? values.conditionValue[0]?.requirements?.findIndex(
          (x) => x['birthdate'] != undefined
        )
      : 0;
  const tierIndex =
    values.conditionValue[0]?.requirements.length > 0
      ? values.conditionValue[0]?.requirements?.findIndex(
          (x) => x['tier'] != undefined
        )
      : 0;
  const handleSelectSource = (event) => {
    const sourseIsEnable = event.target.checked;
    const sourceType = event.target.name;
    let newConditions = [...values.conditionValue];
    if (sourceType == 'mobile') {
      if (sourseIsEnable) {
        let currentReward;
        let currentFixedRequirements = [];
        if (newConditions.length > 0) {
          if (values.conditionValue[0]?.requirements.length > 0) {
            values.conditionValue[0]?.requirements.forEach((req) => {
              if ('points' in req) currentFixedRequirements.push(req);
              if ('birthdate' in req) currentFixedRequirements.push(req);
              if ('tier' in req) currentFixedRequirements.push(req);
            });
          }
          currentReward = values.conditionValue[0]?.reward;
        }
        const newMobileSource = {
          source: 'mobile',
          is_active: true,
          requirements: currentFixedRequirements,
          reward: currentReward || {
            type: 'dollar',
            amount: 10,
            products: [],
          },
        };
        newConditions.push(newMobileSource);
      } else {
        newConditions.splice(mobileIndex, 1);
      }
      setFieldValue('conditionValue', newConditions);
      setisMobileRequire(sourseIsEnable);
    } else if (sourceType == 'web') {
      if (sourseIsEnable) {
        let currentReward;
        let currentFixedRequirements = [];
        if (newConditions.length > 0) {
          if (values.conditionValue[0]?.requirements.length > 0) {
            values.conditionValue[0]?.requirements.forEach((req) => {
              if ('points' in req) currentFixedRequirements.push(req);
              if ('birthdate' in req) currentFixedRequirements.push(req);
              if ('tier' in req) currentFixedRequirements.push(req);
            });
          }
          currentReward = values.conditionValue[0]?.reward;
        }
        const newWebSource = {
          source: 'web',
          is_active: true,
          requirements: currentFixedRequirements,
          reward: currentReward || {
            type: 'dollar',
            amount: 10,
            products: [],
          },
        };
        newConditions.push(newWebSource);
      } else {
        newConditions.splice(webIndex, 1);
      }
      setFieldValue('conditionValue', newConditions);
      setisWebRequire(sourseIsEnable);
    } else {
      const filteredSource = offerEngineSource?.sources.filter(
        (source) => !['web', 'mobile'].includes(source)
      )[0];
      if (sourseIsEnable) {
        let currentReward;
        let currentFixedRequirements = [];
        if (newConditions.length > 0) {
          if (values.conditionValue[0]?.requirements.length > 0) {
            values.conditionValue[0]?.requirements.forEach((req) => {
              if ('points' in req) currentFixedRequirements.push(req);
              if ('birthdate' in req) currentFixedRequirements.push(req);
              if ('tier' in req) currentFixedRequirements.push(req);
            });
          }
          currentReward = values.conditionValue[0]?.reward;
        }
        const newScanSource = {
          source: filteredSource,
          is_active: true,
          requirements: currentFixedRequirements,
          reward: currentReward || {
            type: 'dollar',
            amount: 10,
            products: [],
          },
        };
        newConditions.push(newScanSource);
      } else {
        newConditions.splice(scanIndex, 1);
      }
      setFieldValue('conditionValue', newConditions);
      setisScanRequire(sourseIsEnable);
    }
  };
  const handleActivateSource = (e, source) => {
    const isActivated = e.target.checked;
    const newIndex = values.conditionValue?.findIndex(
      (s) => s.source?.toLowerCase() === source?.toLowerCase()
    );
    setFieldValue(`conditionValue[${newIndex}].is_active`, isActivated);
  };
  const isSourceActive = (source) => {
    const newSource = values.conditionValue?.find(
      (s) => s.source?.toLowerCase() === source?.toLowerCase()
    );
    const isActive = newSource?.is_active || false;
    return isActive;
  };
  const isSourceActiveDisabled = (source) => {
    let isDisabled = true;
    const newIndex = values.conditionValue?.findIndex(
      (s) => s.source?.toLowerCase() === source?.toLowerCase()
    );
    if (newIndex >= 0) {
      isDisabled = false;
    }
    return isDisabled;
  };
  useEffect(() => {
    const currentTemplateName = values.offer_details.template_name;
    if (currentTemplateName === 'birthday-free-item') {
      setFieldValue('offer_details.template_name', 'free-item');
    } else if (currentTemplateName === 'birthday-discounted-item') {
      const rewardType = values.conditionValue[0].reward.type;
      if (rewardType === 'dollar') {
        setFieldValue('offer_details.template_name', 'dollar-discount');
      } else {
        setFieldValue('offer_details.template_name', 'discounted-item');
      }
    }
    if (values.conditionValue?.length === 0) {
      setFieldValue('offer_details.template_name', '');
    }
    let isPointsActive = false;
    let isBirthdateActive = false;
    let isTireActive = false;
    values.conditionValue.forEach((source, index) => {
      source?.requirements.forEach((req) => {
        if ('points' in req) {
          isPointsActive = true;
        }
        if ('birthdate' in req) {
          isBirthdateActive = true;
        }
        if ('tier' in req) {
          isTireActive = true;
        }
      });

      /// does loyalty have error?
      if (
        values.conditionValue[0]?.requirements.filter((req) => 'tire' in req) &&
        values.conditionValue[0]?.requirements[tierIndex]?.tier.length === 0
      ) {
        setLoyaltyHasError(true);
      } else {
        setLoyaltyHasError(false);
      }
    }, []);
    setPointPropertyIsEnabled(isPointsActive);
    setBirthdayPropertyIsEnabled(isBirthdateActive);
    setTierPropertyIsEnabled(isTireActive);
    // setting sorted sources to display rewards and requirements in a specific order
    const initialConditions = values.conditionValue;
    let sources = initialConditions.map((c) => c.source);
    const sortedSource = _.sortBy(sources, function (element) {
      var rank = {
        mobile: 1,
        web: 2,
      };
      return rank[element];
    });
    setCurrentSortedSources(sortedSource);
    // check if web and mobile are available as client's sources
    // checkRequirementsSililarity(sortedSource);
    // sort apply multiple
    let hasPoints = false;
    values.conditionValue[0]?.requirements.forEach((req) => {
      if (req?.points) {
        hasPoints = true;
      }
    });
    if (hasPoints && values.offer_details.template_name === 'free-item') {
      setFieldValue('offer_details.sort_price', 'DESC');
      setFieldValue('offer_details.apply_multiple', false);
      setHasFreeItemAndPoints(true);
    } else {
      setHasFreeItemAndPoints(false);
    }
  }, [values]);
  // Points, Birthday,Tier are static parat of all sources
  const handlePointsBirthdayTireActivation = (event, keyValue) => {
    let newConditionValue = [];
    if (event.target.checked) {
      if (keyValue == 'points') {
        values.conditionValue.forEach((condition) => {
          let newCondition = { ...condition };
          newCondition?.requirements.push({ points: { point_cost: 0 } });
          newConditionValue.push(newCondition);
        });
        // setFieldValue('conditionValue', newConditionValue);
      }
      if (keyValue == 'birthday') {
        values.conditionValue.forEach((condition) => {
          let newCondition = { ...condition };
          newCondition?.requirements.push({ birthdate: { days_available: 7 } });
          newConditionValue.push(newCondition);
        });
        // setFieldValue('conditionValue', newConditionValue);
      }
      if (keyValue == 'tier') {
        values.conditionValue.forEach((condition) => {
          let newCondition = { ...condition };
          newCondition?.requirements.push({ tier: [] });
          newConditionValue.push(newCondition);
        });
      }
      setFieldValue('conditionValue', newConditionValue);
    } else {
      if (keyValue == 'points') {
        values.conditionValue.forEach((condition) => {
          let newCondition = { ...condition };
          const newReq = condition?.requirements.filter(
            (req) => !('points' in req)
          );
          newCondition.requirements = newReq;
          newConditionValue.push(newCondition);
        });
      }
      if (keyValue == 'birthday') {
        values.conditionValue.forEach((condition) => {
          const newReq = condition?.requirements.filter(
            (req) => !('birthdate' in req)
          );
          newConditionValue.push({ ...condition, requirements: newReq });
        });
      }
      if (keyValue == 'tier') {
        let newConditionValue = [];
        values.conditionValue.forEach((condition) => {
          const newReq = condition?.requirements.filter(
            (req) => !('tier' in req)
          );
          newConditionValue.push({ ...condition, requirements: newReq });
        });
      }
    }
    setFieldValue('conditionValue', newConditionValue);
  };
  const handlePointsBirthdayTireInputChange = (e) => {
    const keyValue = e.target.name;
    const newValue = e.target.value;
    let newConditionValue = [];
    if (keyValue == 'points') {
      values.conditionValue.forEach((condition, conIndex) => {
        let newCondition = { ...condition };
        condition?.requirements.forEach((req, reqIndex) => {
          if ('points' in req) {
            newCondition.requirements[reqIndex].points.point_cost =
              parseFloat(newValue);
          }
        });
        newConditionValue.push(newCondition);
      });
    }
    if (keyValue == 'birthday') {
      values.conditionValue.forEach((condition, conIndex) => {
        let newCondition = { ...condition };
        condition?.requirements.forEach((req, reqIndex) => {
          if ('birthdate' in req) {
            newCondition.requirements[reqIndex].birthdate.days_available =
              parseFloat(newValue);
          }
        });
        newConditionValue.push(newCondition);
      });
    }
    if (keyValue == 'tier') {
      let newConditionValue = [];
      values.conditionValue.forEach((condition) => {
        const newReq = condition?.requirements.filter(
          (req) => !('tier' in req)
        );
        newConditionValue.push({ ...condition, requirements: newReq });
      });
    }
    setFieldValue('conditionValue', newConditionValue);
  };
  const getSourceTitle = (source) => {
    let title = '';
    let len = source.length;
    if (['web', 'mobile'].includes(source.toLowerCase())) {
      title =
        source.charAt(0)?.toUpperCase() +
        source.toLowerCase().substring(1, len);
    } else {
      title = 'Scan (POS)';
    }
    return title;
  };
  const getItemIndex = (source) => {
    let itemIndex;
    if (source.toLowerCase() === 'web') {
      itemIndex = webIndex;
    } else if (source.toLowerCase() === 'mobile') {
      itemIndex = mobileIndex;
    } else {
      itemIndex = scanIndex;
    }
    return itemIndex;
  };
  const handleCopyRewards = (copyFrom) => {
    const newConditionValue = [...values.conditionValue];
    if (copyFrom === 'web') {
      newConditionValue[mobileIndex].reward =
        values.conditionValue[webIndex].reward;
    } else {
      newConditionValue[webIndex].reward =
        values.conditionValue[mobileIndex].reward;
    }
    setFieldValue('conditionValue', newConditionValue);
  };
  const handleCopyRequirements = (copyFrom) => {
    const newConditionValue = [...values.conditionValue];
    if (copyFrom === 'web') {
      newConditionValue[mobileIndex].requirements =
        values.conditionValue[webIndex].requirements;
    } else {
      newConditionValue[webIndex].requirements =
        values.conditionValue[mobileIndex].requirements;
    }
    setFieldValue('conditionValue', newConditionValue);
  };
  const handleModifiedLoyaltyTiers = (e, tierType) => {
    let newConditionValues = [...values.conditionValue];
    const isChecked = e.target.checked;
    const currentTiers = values.conditionValue[0].requirements[tierIndex].tier;
    if (isChecked) {
      values.conditionValue.forEach((con, index) => {
        newConditionValues[index].requirements[tierIndex].tier.push(tierType);
      });
    } else {
      values.conditionValue.forEach((con, index) => {
        const newTier = con.requirements[tierIndex].tier.filter(
          (ti) => ti !== tierType
        );
        newConditionValues[index].requirements[tierIndex].tier = newTier;
      });
    }
    setFieldValue('conditionValue', newConditionValues);
    setFieldValue(
      'offer_details.apply_multiple',
      values.offer_details.apply_multiple
    );
  };
  const handleToggleLoyaltyRequierments = (e) => {
    let newConditionValues = [...values.conditionValue];
    const isChecked = e.target.checked;
    setTierPropertyIsEnabled((prevState) => !prevState);
    if (isChecked) {
      handlePointsBirthdayTireActivation(e, 'tier');
    } else {
      values.conditionValue.forEach((_, index) => {
        newConditionValues[index].requirements = newConditionValues[
          index
        ]?.requirements.filter((req) => !('tier' in req));
      });
    }
    setFieldValue('conditionValue', newConditionValues);
  };
  return (
    <Wrapper>
      <Subtitle>Offer Source</Subtitle>
      <Card>
        <SubHead>Which source(s) does the offer apply to?</SubHead>
        <FormGroup>
          {offerEngineSource?.sources?.map((source, index) => (
            <CheckBoxContainer key={index}>
              <FormControlLabel
                control={
                  <Checkbox
                    name={source}
                    checked={
                      source === 'mobile'
                        ? isMobileRequire
                        : source === 'web'
                        ? isWebRequire
                        : isScanRequire
                    }
                    onChange={handleSelectSource}
                    color='primary'
                  />
                }
                label={
                  source === 'mobile'
                    ? 'Mobile'
                    : source === 'web'
                    ? 'Web'
                    : 'Scan (POS)'
                }
              />
              <span>Active</span>
              <MiniToggleSwitch
                checked={isSourceActive(source)}
                onChange={(e) => {
                  handleActivateSource(e, source);
                }}
                disabled={isSourceActiveDisabled(source)}
              />
            </CheckBoxContainer>
          ))}
        </FormGroup>
      </Card>
      <Subtitle>Offer Type</Subtitle>
      <Card>
        <SubHead> Select offer type</SubHead>
        <FormControl>
          <Select
            className='mb25'
            labelId='select-reward-label'
            id='select-reward'
            value={values.offer_details.template_name || 'Select Offer Type'}
            placeholder='Select an Offer Type'
            label=''
            disabled={values.conditionValue?.length === 0}
            onChange={(e) => {
              if (e.target.value === 'first-time') {
                values.conditionValue.forEach((_, i) => {
                  setFieldValue(`conditionValue[${i}].reward.type`, 'dollar');
                  setFieldValue(`conditionValue[${i}].reward.amount`, '');
                  setFieldValue(`conditionValue[${i}].reward.products`, []);
                  setFieldValue('offer_details.apply_multiple', false);
                  setFieldValue('offer_details.is_carryover', false);
                  setFieldValue('offer_details.sort_price', 'DESC');
                });
              } else if (e.target.value === 'free-item') {
                values.conditionValue.forEach((_, i) => {
                  setFieldValue(`conditionValue[${i}].reward.type`, 'dollar');
                  setFieldValue(`conditionValue[${i}].reward.amount`, '');
                  setFieldValue(`conditionValue[${i}].reward.products`, []);
                  setFieldValue('offer_details.apply_multiple', false);
                  setFieldValue('offer_details.sort_price', 'DESC');
                });
              } else if (e.target.value === 'discounted-item') {
                values.conditionValue.forEach((_, i) => {
                  setFieldValue(`conditionValue[${i}].reward.type`, 'discount');
                  setFieldValue(`conditionValue[${i}].reward.amount`, '');
                  setFieldValue(`conditionValue[${i}].reward.products`, []);
                });
              } else if (e.target.value === 'dollar-discount') {
                values.conditionValue.forEach((_, i) => {
                  setFieldValue(`conditionValue[${i}].reward.type`, 'dollar');
                  setFieldValue(`conditionValue[${i}].reward.amount`, '');
                  setFieldValue(`conditionValue[${i}].reward.products`, []);
                });
              } else if (e.target.value === 'bonus-points') {
                values.conditionValue.forEach((_, i) => {
                  setFieldValue(`conditionValue[${i}].reward.type`, 'points');
                  setFieldValue(`conditionValue[${i}].reward.amount`, '');
                  setFieldValue(`conditionValue[${i}].reward.products`, []);
                  setFieldValue('offer_details.apply_multiple', false);
                  setFieldValue('offer_details.sort_price', 'DESC');
                });
              } else if (e.target.value === 'points-multiplier') {
                values.conditionValue.forEach((_, i) => {
                  setFieldValue(
                    `conditionValue[${i}].reward.type`,
                    'points-multiplier'
                  );
                  setFieldValue(`conditionValue[${i}].reward.amount`, 2);
                  setFieldValue(`conditionValue[${i}].reward.products`, []);
                  setFieldValue('offer_details.apply_multiple', false);
                  setFieldValue('offer_details.sort_price', 'DESC');
                });
              } else if (e.target.value === 'carryover-loyalty') {
                values.conditionValue.forEach((_, i) => {
                  setFieldValue(`conditionValue[${i}].reward.type`, 'dollar');
                  setFieldValue(`conditionValue[${i}].reward.amount`, '');
                  setFieldValue(`conditionValue[${i}].reward.products`, []);
                  setFieldValue('offer_details.is_carryover', true);
                  setFieldValue('offer_details.apply_multiple', false);
                  setFieldValue('offer_details.sort_price', 'DESC');
                });
              } else {
                values.conditionValue.forEach((_, i) => {
                  setFieldValue(`conditionValue[${i}].reward`, null);
                });
              }
              setPosMappingStringValue('');
              setFieldValue('offer_details.template_name', e.target.value);
            }}
          >
            {rewardOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Card>
      <Card>
        {((!isMobileRequire && !isWebRequire && !isScanRequire) ||
          !values.offer_details.template_name ||
          values.offer_details.template_name === 'Select Offer Type') && (
          <NotAvailable>
            <div>
              <IconDummy className='fas fa-coins'></IconDummy>
            </div>
            <SubHeadDummy>
              You must select at least one offer source and an offer type before
              you can specify offer details
            </SubHeadDummy>
          </NotAvailable>
        )}
        {values.offer_details.template_name &&
          values.offer_details.template_name !== 'Select Offer Type' && (
            <>
              <SubHead>Offer Details</SubHead>
              {currentSortedSources.map((source, index) => (
                <RewardDetails
                  title={getSourceTitle(source)}
                  values={values}
                  setFieldValue={setFieldValue}
                  isSegmentVisible={isSegmentVisible}
                  setisSegmentVisible={setisSegmentVisible}
                  isConsumerVisible={isConsumerVisible}
                  setisConsumerVisible={setisConsumerVisible}
                  indexItem={getItemIndex(source)}
                  rewardOptions={rewardOptions}
                  offerEngineProducts={offerEngineProducts}
                  allProductVariants={allProductVariants}
                  errors={errors}
                  posMappingStringValue={posMappingStringValue}
                  setPosMappingStringValue={setPosMappingStringValue}
                  isLastItemInTheCard={
                    index === currentSortedSources.length - 1
                  }
                  isWebRequire={isWebRequire}
                  isMobileRequire={isMobileRequire}
                  handleCopyRewards={handleCopyRewards}
                />
              ))}
            </>
          )}
      </Card>
      {values.offer_details.template_name !== 'first-time' && (
        <>
          <Subtitle>
            Requirements and Cost <NoteHeadNormal>(Optional)</NoteHeadNormal>
          </Subtitle>
          <Card>
            {(!isMobileRequire && !isWebRequire && !isScanRequire) ||
            !values.offer_details.template_name ||
            values.offer_details.template_name === 'Select Offer Type' ? (
              <NotAvailable>
                <div>
                  <IconDummy className='fas fa-coins'></IconDummy>
                </div>
                <SubHeadDummy>
                  You must select at least one offer source and an offer type
                  before you can specify requirements and cost
                </SubHeadDummy>
              </NotAvailable>
            ) : (
              <>
                {/* {values.offer_details.template_name !== 'carryover-loyalty' &&
                  isWebRequire &&
                  isMobileRequire && (
                    <>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isRequirementsSame}
                            onChange={(e) =>
                              updateRequirementSmilarity(e.target.checked)
                            }
                            color='primary'
                          />
                        }
                        label='Mobile and web have the same requirements and cost'
                      />
                      <Divider />
                    </>
                  )} */}
                <WrapperInside>
                  <FullWrapper>
                    <div className='formSection'>
                      <NoteHead>Point cost</NoteHead>
                      <NoteText>Offer has a point cost to redeem</NoteText>
                    </div>
                    <div>
                      <MiniToggleSwitch
                        name={'points'}
                        label=''
                        checked={pointPropertyIsEnabled}
                        onChange={(e) => {
                          handlePointsBirthdayTireActivation(e, 'points');
                          setPointPropertyIsEnabled((prevState) => !prevState);
                        }}
                      />
                    </div>
                  </FullWrapper>
                  {pointPropertyIsEnabled && (
                    <>
                      <NoteInside>
                        How many points does this offer cost to redeem?
                      </NoteInside>
                      <ActionBox>
                        <Field
                          name={'points'}
                          value={
                            values?.conditionValue[0]?.requirements[pointIndex]
                              ?.points?.point_cost
                          }
                          component={RenderTextField}
                          isPrice
                          label='Point cost'
                          onChange={handlePointsBirthdayTireInputChange}
                        />
                      </ActionBox>
                    </>
                  )}
                </WrapperInside>
                {values.offer_details.template_name !== 'carryover-loyalty' && (
                  <WrapperInside hasBlackDivider>
                    <FullWrapper>
                      <div className='formSection'>
                        <NoteHead>Birthday requirements</NoteHead>
                        <NoteText>
                          Customer is eligible for this offer during a specified
                          time from their birthday
                        </NoteText>
                      </div>
                      <div>
                        <MiniToggleSwitch
                          name={'birthday'}
                          label=''
                          checked={birthdayPropertyIsEnabled}
                          onChange={(e) => {
                            handlePointsBirthdayTireActivation(e, 'birthday');
                            setBirthdayPropertyIsEnabled(
                              (prevState) => !prevState
                            );
                          }}
                        />
                      </div>
                    </FullWrapper>
                    {birthdayPropertyIsEnabled && (
                      <>
                        <NoteInside>
                          When is this birthday offer available until?
                        </NoteInside>
                        <SubnoteInside>
                          The offer will begin on the customer’s birthday
                        </SubnoteInside>
                        <ActionBox>
                          <Field
                            name='birthday'
                            value={
                              values?.conditionValue[0]?.requirements[
                                birthdateIndex
                              ]?.birthdate?.days_available
                            }
                            component={RenderTextField}
                            placeholder='E.g. 3'
                            isPrice
                            label=''
                            onChange={handlePointsBirthdayTireInputChange}
                          />
                        </ActionBox>
                      </>
                    )}
                  </WrapperInside>
                )}
                {isTierEnabled === 'true' &&
                  values.offer_details.template_name !==
                    'carryover-loyalty' && (
                    <WrapperInside>
                      <FullWrapper>
                        <div className='formSection'>
                          <NoteHead>Loyalty requirements</NoteHead>
                          <NoteText>
                            Offer is available to customers in specified loyalty
                            tiers
                          </NoteText>
                        </div>
                        <div>
                          <MiniToggleSwitch
                            name={'tier'}
                            label=''
                            checked={tierPropertyIsEnabled}
                            onChange={handleToggleLoyaltyRequierments}
                          ></MiniToggleSwitch>
                        </div>
                      </FullWrapper>
                      {tierPropertyIsEnabled && (
                        <TierMain>
                          {loyaltyTiers.length > 0 &&
                            loyaltyTiers.map((tier, index) => (
                              <div>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name='tier 1'
                                      value={index + 1}
                                      checked={values.conditionValue[0].requirements[
                                        tierIndex
                                      ].tier.includes(index + 1)}
                                      onClick={(e) =>
                                        handleModifiedLoyaltyTiers(e, index + 1)
                                      }
                                    />
                                  }
                                  label={tier.name}
                                />
                              </div>
                            ))}
                        </TierMain>
                      )}
                      {loyaltyHasError && (
                        <div
                          style={{
                            color: 'red',
                            fontSize: '12px',
                            marginLeft: '15px',
                          }}
                        >
                          Select at least 1 tier
                        </div>
                      )}
                    </WrapperInside>
                  )}
                <br />
                {values.offer_details.template_name !== 'carryover-loyalty' && (
                  <>
                    {currentSortedSources.map((source, index) => (
                      <Requirement
                        values={values}
                        title={getSourceTitle(source)}
                        subTitle={'Specify as many as you’d like'}
                        setFieldValue={setFieldValue}
                        isSegmentVisible={isSegmentVisible}
                        setisSegmentVisible={setisSegmentVisible}
                        isConsumerVisible={isConsumerVisible}
                        setisConsumerVisible={setisConsumerVisible}
                        indexItem={getItemIndex(source)}
                        allProductVariants={allProductVariants}
                        isLastItemInTheCard={
                          index === currentSortedSources.length - 1
                        }
                        isWebRequire={isWebRequire}
                        isMobileRequire={isMobileRequire}
                        handleCopyRequirements={handleCopyRequirements}
                      />
                    ))}
                  </>
                )}
              </>
            )}
          </Card>
        </>
      )}
      {(values?.offer_details?.template_name === 'first-time' ||
        values?.offer_details?.template_name === 'carryover-loyalty') && (
        <>
          <Subtitle>Dollar Discount Carryover</Subtitle>
          <Card>
            <SubHead>
              If the dollar discount is not used up in the purchase, can it
              carry over to the following purchase?
            </SubHead>
            <RadioGroup
              aria-labelledby='radio-availability-group-label'
              value={
                values.offer_details.template_name === 'carryover-loyalty'
                  ? 'true'
                  : values.offer_details.is_carryover == true
                  ? 'true'
                  : 'false'
              }
              id='radio-availability-group'
              name='radio-availability-group'
              onChange={(event) => {
                setFieldValue(
                  'offer_details.is_carryover',
                  event.target.value == 'true' ? true : false
                );
              }}
            >
              <FormControlLabel
                value='true'
                control={<Radio />}
                label='Yes'
                disabled={
                  values.offer_details.template_name === 'carryover-loyalty'
                }
              />
              <FormControlLabel
                value='false'
                control={<Radio />}
                label='No - discount must be used up in one purchase'
                disabled={
                  values.offer_details.template_name === 'carryover-loyalty'
                }
              />
            </RadioGroup>
          </Card>
        </>
      )}

      <>
        <Subtitle>Apply Multiple</Subtitle>
        <Card>
          {(!isMobileRequire && !isWebRequire && !isScanRequire) ||
          !values.offer_details.template_name ||
          values.offer_details.template_name === 'Select Offer Type' ? (
            <NotAvailable>
              <div>
                <IconDummy className='fas fa-coins'></IconDummy>
              </div>
              <SubHeadDummy>
                You must select at least one offer source and an offer type
                before you can apply multiple
              </SubHeadDummy>
            </NotAvailable>
          ) : (
            <>
              <SubHead>
                If the customer has more than one eligible product in their
                cart, should the discount apply to just one selected product or
                all selected products?
                {/* {values?.offer_details?.template_name === 'free-item' && (
                  <>
                    <br />
                    <br />
                    <span>
                      For a free item offer, that may or may not require
                      customers to redeem points, you should choose false for
                      this, as you would not want to deduct the customer's
                      points for every matching product in their cart.
                    </span>
                  </>
                )} */}
              </SubHead>
              <RadioGroup
                aria-labelledby='radio-availability-group-label'
                value={
                  values.offer_details.apply_multiple == true ? 'true' : 'false'
                }
                id='radio-availability-group'
                name='radio-availability-group'
                onChange={(event) => {
                  setFieldValue(
                    'offer_details.apply_multiple',
                    event.target.value == 'true' ? true : false
                  );
                }}
              >
                <FormControlLabel
                  value='false'
                  control={<Radio />}
                  label='One'
                  disabled={
                    values.offer_details.template_name === 'first-time' ||
                    values.offer_details.template_name ===
                      'carryover-loyalty' ||
                    hasFreeItemAndPoints
                  }
                />
                {values.offer_details.apply_multiple == false && (
                  <div>
                    <FormControl>
                      <Select
                        className='mb25'
                        labelId='select-audience-label'
                        id='select-audience'
                        value={values.offer_details.sort_price}
                        label='Age'
                        onChange={(event) => {
                          setFieldValue(
                            'offer_details.sort_price',
                            event.target.value
                          );
                        }}
                        disabled={
                          values.offer_details.template_name === 'first-time' ||
                          values.offer_details.template_name ===
                            'carryover-loyalty' ||
                          hasFreeItemAndPoints
                        }
                      >
                        <MenuItem value='DESC'>
                          Highest-priced eligible product in basket
                        </MenuItem>
                        <MenuItem value='ASC'>
                          Lowest-priced eligible product in basket
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                )}
                <FormControlLabel
                  value='true'
                  control={<Radio />}
                  label='All'
                  disabled={
                    values.offer_details.template_name === 'first-time' ||
                    values.offer_details.template_name ===
                      'carryover-loyalty' ||
                    hasFreeItemAndPoints
                  }
                />
              </RadioGroup>
            </>
          )}
        </Card>
      </>
    </Wrapper>
  );
};
export default SlideTwo;
