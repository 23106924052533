import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { location } from 'react-router';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import { makeStyles } from '@material-ui/core/styles';
import BreadCrumb from '../../components/BreadCrumb/BreadCrumb';
import Button from '@material-ui/core/Button';
import getPageName from './utils/getPageName';
import {
  Wrapper,
  HeaderWrapper,
  ExportButtonWrapper,
  ReportWrapper,
  MessageWrapper,
} from './styles/reportContainerStyles';
import exportCSVWrapper from '../HOCs/exportCSVWrapper';
import TransactionReportContainer from './TransactionReport/TransactionReport';
import LoyaltyReportContainer from './LoyaltyReport/LoyaltyReport';
import ProductReportContainer from './ProductReport/ProductReport';
import ComboReportContainer from './ComboReport/ComboReport';
import LocationReportContainer from './LocationReport/LocationReport';
import CustomerReportContainer from './CustomerReport/CustomerReport';
import OfferReport from './OfferReport/OfferReport';
import CouponReport from './CouponReport/CouponReport';
const TransactionMessageWrapper = styled.div`
  margin: 15px 0;
  padding: 0 12px;
`;
const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  button: {
    width: '148px',
    backgroundColor: '#e6ebff',
    color: '#2440ae',
    height: '43px',
    borderRadius: '8px',
    fontSize: '14px',
    fontWeight: '600',
    fontStretch: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#d9e0ff',
    },
    '&:disabled': {
      backgroundColor: '#eff2ff',
      color: '#9facdf',
    },
  },
}));
const ReportContainer = (props) => {
  const classes = useStyles();
  const appIdentifier = localStorage.getItem('appIdentifier');
  // for filter modal
  const [filteredLocations, setFilteredLocations] = useState([]);
  const currentPathName = props.history.location.pathname;
  const [selectedBusinessesIDs, setSelectedBusinessesIDs] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [maxDueDate, setMaxDueDate] = useState(null);
  const [exportingData, setExportingData] = useState([]);
  const [exportingHeaders, setExportingHeaders] = useState([]);
  const [exportingFileName, setExportingFileName] = useState('');
  const [message, setMessage] = useState('');
  const [quaryParams, setQuaryParams] = useState('');
  useEffect(() => {
    const selectedStartDate = moment(startDate)
      .set({ hour: 0, minute: 0, second: 0 })
      .format('YYYY-MM-DD HH:mm:ss');
    const selectedEndDate = moment(endDate)
      .set({ hour: 23, minute: 59, second: 59 })
      .format('YYYY-MM-DD HH:mm:ss');
    const params = {
      page_number: 1,
      page_size: 3000,
      start_datetime: selectedStartDate,
      end_datetime: selectedEndDate,
      export: true,
    };
    setQuaryParams(params);
  }, [startDate, endDate, selectedBusinessesIDs]);
  return (
    <Wrapper>
      <HeaderWrapper>
        <BreadCrumb pageName={getPageName(currentPathName)} />
        <ExportButtonWrapper>
          {currentPathName === '/transaction-report' ? (
            <Button
              className={classes.button}
              disabled={exportingData?.length === 0}
              onClick={() =>
                props.exportCSV({
                  requestPath: 'transactions',
                  fileName: `transaction_report`,
                  requestParams: quaryParams,
                })
              }
            >
              Export as CSV
            </Button>
          ) : (
            <CSVLink
              data={exportingData}
              headers={exportingHeaders}
              filename={`${exportingFileName}.csv`}
              disabled={exportingData?.length === 0}
            >
              <Button
                className={classes.button}
                disabled={exportingData?.length === 0}
              >
                Export as CSV
              </Button>
            </CSVLink>
          )}
        </ExportButtonWrapper>
      </HeaderWrapper>
      {currentPathName === '/transaction-report' &&
        appIdentifier === 'mary_browns' && (
          <TransactionMessageWrapper>
            We're updating the transaction report functionality to limit data
            retrieval to a single day at a time. This modification allows
            clients with extensive transaction volumes to generate reports
            successfully. Currently, certain clients attempt to pull data for an
            entire month, which proves challenging when dealing with a high
            volume of transactions.
          </TransactionMessageWrapper>
        )}
      <ReportWrapper>
        {currentPathName === '/transaction-report' && (
          <TransactionReportContainer
            setExportingData={setExportingData}
            setExportingHeaders={setExportingHeaders}
            setExportingFileName={setExportingFileName}
            setMessage={setMessage}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            maxDueDate={maxDueDate}
            setMaxDueDate={setMaxDueDate}
            selectedBusinessesIDs={selectedBusinessesIDs}
            setSelectedBusinessesIDs={setSelectedBusinessesIDs}
          />
        )}

        {currentPathName === '/loyalty-report' && (
          <LoyaltyReportContainer
            setExportingData={setExportingData}
            setExportingHeaders={setExportingHeaders}
            setExportingFileName={setExportingFileName}
            setMessage={setMessage}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            selectedBusinessesIDs={selectedBusinessesIDs}
            setSelectedBusinessesIDs={setSelectedBusinessesIDs}
          />
        )}
        {currentPathName === '/product-performance-report' && (
          <ProductReportContainer
            setExportingData={setExportingData}
            setExportingHeaders={setExportingHeaders}
            setExportingFileName={setExportingFileName}
            setMessage={setMessage}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            selectedBusinessesIDs={selectedBusinessesIDs}
            setSelectedBusinessesIDs={setSelectedBusinessesIDs}
            filteredLocations={filteredLocations}
            setFilteredLocations={setFilteredLocations}
          />
        )}
        {currentPathName === '/coupon-report' && (
          <CouponReport
            setExportingData={setExportingData}
            setExportingHeaders={setExportingHeaders}
            setExportingFileName={setExportingFileName}
            setMessage={setMessage}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            selectedBusinessesIDs={selectedBusinessesIDs}
            setSelectedBusinessesIDs={setSelectedBusinessesIDs}
            filteredLocations={filteredLocations}
            setFilteredLocations={setFilteredLocations}
          />
        )}
        {currentPathName === '/offer-report' && (
          <OfferReport
            setExportingData={setExportingData}
            setExportingHeaders={setExportingHeaders}
            setExportingFileName={setExportingFileName}
            setMessage={setMessage}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            selectedBusinessesIDs={selectedBusinessesIDs}
            setSelectedBusinessesIDs={setSelectedBusinessesIDs}
            filteredLocations={filteredLocations}
            setFilteredLocations={setFilteredLocations}
          />
        )}
        {currentPathName === '/combo-performance-report' && (
          <ComboReportContainer
            setExportingData={setExportingData}
            setExportingHeaders={setExportingHeaders}
            setExportingFileName={setExportingFileName}
            setMessage={setMessage}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            selectedBusinessesIDs={selectedBusinessesIDs}
            setSelectedBusinessesIDs={setSelectedBusinessesIDs}
            filteredLocations={filteredLocations}
            setFilteredLocations={setFilteredLocations}
          />
        )}
        {currentPathName === '/location-transaction-report' && (
          <LocationReportContainer
            setExportingData={setExportingData}
            setExportingHeaders={setExportingHeaders}
            setExportingFileName={setExportingFileName}
            setMessage={setMessage}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            selectedBusinessesIDs={selectedBusinessesIDs}
            setSelectedBusinessesIDs={setSelectedBusinessesIDs}
            filteredLocations={filteredLocations}
            setFilteredLocations={setFilteredLocations}
          />
        )}
        {currentPathName === '/customer-report' && (
          <CustomerReportContainer
            setExportingData={setExportingData}
            setExportingHeaders={setExportingHeaders}
            setExportingFileName={setExportingFileName}
            setMessage={setMessage}
          />
        )}
      </ReportWrapper>
      {message && exportingData.length === 0 && (
        <MessageWrapper>{message}</MessageWrapper>
      )}
    </Wrapper>
  );
};

export default exportCSVWrapper(ReportContainer);
