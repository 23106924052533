import { api } from '../../../api';
import { GET_OFFERS_REQUEST, GET_OFFERS_RESPONSE } from './types';

export function getOffers() {
  return function (dispatch) {
    dispatch({ type: GET_OFFERS_REQUEST });
    api
      .get('offers?page_number=1&page_size=1000')
      .then((response) => {
        dispatch({ type: GET_OFFERS_RESPONSE, response: response.data });
      })
      .catch((error) => {
        // console.log(error);
      });
  };
}
