import {
  GET_CUSTOMERS_BY_QUERY_REQUEST,
  GET_CUSTOMERS_BY_QUERY_SUCCESS,
  GET_CUSTOMERS_BY_QUERY_FAILURE,
  SELECT_CUSTOMER,
  SELECT_CUSTOMER_BY_DEFAULT_VALUE,
  RESET_STATE
} from './types';

export default function(
  state = {
    requestingGetCustomersByQuery: false,
    customersByQuery: [],
    selectedCustomer: false
  },
  action
) {
  switch (action.type) {
    case GET_CUSTOMERS_BY_QUERY_REQUEST:
      return {
        ...state,
        requestingGetCustomersByQuery: true
      };
    case GET_CUSTOMERS_BY_QUERY_SUCCESS:
      return {
        ...state,
        requestingGetCustomersByQuery: false,
        customersByQuery: action.response.data
      };
    case GET_CUSTOMERS_BY_QUERY_FAILURE:
      return {
        ...state,
        requestingGetCustomersByQuery: false,
        error: action.error
      };
    case SELECT_CUSTOMER:
      return {
        ...state,
        selectedCustomer: state.customersByQuery.find(
          customer => customer.customer_id === action.customerId
        )
      };
    case SELECT_CUSTOMER_BY_DEFAULT_VALUE:
      return {
        ...state,
        selectedCustomer: action.customer
      };
    case RESET_STATE:
      return {
        ...state,
        requestingGetCustomersByQuery: false,
        selectedCustomer: false
      };
    default:
      return state;
  }
}
