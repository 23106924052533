import React, { useEffect, useState, useCallback } from 'react';
import { Formik, Field } from 'formik';
import styled from 'styled-components';
import Moment from 'moment';
import {
  RenderTextField,
  RenderTimePicker,
  RenderCheckBox,
  maxLengthValidationForNameField,
  required,
} from '../../../../../../components/FormElements/FormikElements';
import { Button, ButtonRow } from 'merchant-portal-components';
import { SmallToggleSwitch } from '../../../../../../components/ToggleSwitch/ToggleSwitch';
import Collapse from '@material-ui/core/Collapse';
import Tooltip from '@material-ui/core/Tooltip';
import MenuItem from '@material-ui/core/MenuItem';
import MediaModalContainer from '../../../../../MediaModal/MediaModalContainer';
import ChooseImage from '../../../../../MediaModal/components/ChooseImage';
import AvailableHoursCommonModal from '../../../../AvailableHoursCommonModal';
import { productAvailableAllTime } from '../../../../utils/helper.js';
import { isProductAvailableAllTimeFunction } from '../../../../utils/helper.js';
import _ from 'lodash';
import moment from 'moment';
import DatesRow from '../../components/DatesRow.js';
const Card = styled.div`
  background: white;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
`;

const FormWrapper = styled.div`
  padding: 48px;
  display: grid;
  grid-gap: 32px;
`;

const NameAndPreview = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  grid-gap: 20px;
`;

const TaxCategoryWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 20px;
  align-items: center;
  grid-gap: 10px;
  justify-content: center;
`;

const ImageWrapper = styled.div`
  display: grid;
  grid-template: 100px / 150px auto 1fr;
  grid-gap: 48px;
  align-items: center;
  height: 100px;
`;

const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 12px;
`;

const TimeWidgetsWrapper = styled.div`
  display: grid !important;
  grid-gap: 32px;
  margin-top: 32px;
`;

const TimeWrapper = styled.div`
  display: grid;
  grid-template-columns: 86px 100px;
  grid-gap: 32px;
  align-items: center;
  font-weight: 600;
  .rw-widget-input {
    border: 0.5px solid rgba(0, 0, 0, 0.24);
  }
  .rw-input {
    background-color: #fff;
  }
  .rw-select-bordered {
    background-color: #fff;
    border: 0.5px solid rgba(0, 0, 0, 0.24);
    border-left: none;
  }
`;

const PreviewButton = styled.div`
  font-weight: 600;
  cursor: pointer;
  justify-self: flex-end;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-gap: 16px;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  color: ${(props) => (props.disabled ? props.theme.gray : 'inherit')};
`;
const DateWrapper = styled.div`
  margin: 20px 0;
`;

const OrderTypeLabel = styled.div`
  font-weight: 700;
`;
const OrderTypeSub = styled.div`
  font-weight: 200;
  font-size: 13px;
`;

const CheckboxWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  margin-top: 15px;
`;
export const AvailabilityButton = styled.button`
  background-color: #efefef;
  border-radius: 8px;
  font-family: 'Montserrat';
  font-size: 15px;
  font-weight: 600;
  color: #191818;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px 20px;
  cursor: pointer;
  border: none;
`;
let ProductInformationForm = ({
  setMediaSelectionModalIsVisible,
  productAvailabilities,
  setIsAvailableAllDay,
  isAvailableAllDay,
  initialValues,
  handleSubmit,
  mediaSelectionModalIsVisible,
  setProductPreviewIsVisible,
  productPreviewIsDisabled,
  isRequesting,
  programType,
  taxCategoryList,
  orderTypesList,
  showTaxCategory,
  orderTypes,
  productId,
}) => {
  const [isProductAvailableAllTime, setIsProductAvailableAllTime] = useState();
  const [emptyAvailableAllTime, setEmptyAvailableAllTime] = useState();
  const [modifieldInitialValues, setModifieldInitialValues] = useState();
  const [expiryFocusedState, setexpiryFocusedState] = useState(false);
  const [startDateFocusedState, setstartDateFocusedState] = useState(false);
  const [expire, setExpire] = useState(false);
  const updateInitialValues = useCallback(() => {
    if (!productId) {
      let newInitialValues = { ...initialValues };
      newInitialValues.productAvailabilities =
        initialValues.productAvailabilities.map((item) => {
          return { ...item, isAvailableAllDay: true };
        });
      setModifieldInitialValues(newInitialValues);
      setIsProductAvailableAllTime(true);
    } else {
      const endYear = Moment(
        initialValues?.productInformation?.end_datetime
      ).year();
      if (initialValues?.productInformation?.end_datetime && endYear !== 2099) {
        setExpire(true);
      } else {
        setExpire(false);
      }
      setModifieldInitialValues(initialValues);
      const filteredAllAvailables = initialValues.productAvailabilities.filter(
        (item) => item.isAvailableAllDay
      );
      if (filteredAllAvailables?.length === 7) {
        setIsProductAvailableAllTime(true);
      } else {
        setIsProductAvailableAllTime(false);
      }
    }
  }, [initialValues]);
  useEffect(() => {
    updateInitialValues();
  }, [updateInitialValues]);
  useEffect(() => {
    let formatproductAvailableHours = [];
    for (let i = 1; i < 8; i++) {
      formatproductAvailableHours.push({
        product_id: initialValues.productInformation.id,
        start_day_of_week: i,
        end_time: moment('23:59:59.000000', 'HH:mm:ss'),
        id: undefined,
        start_time: null,
        isAvailableAllDay: true,
      });
    }
    setEmptyAvailableAllTime(formatproductAvailableHours);
  }, []);
  const updateIsAllTimeAvailabe = useCallback(() => {
    const isAllTimeAvailable = isProductAvailableAllTimeFunction(
      productAvailabilities
    );
    setIsProductAvailableAllTime(isAllTimeAvailable);
  }, [productAvailabilities]);
  useEffect(() => {
    updateIsAllTimeAvailabe();
  }, [updateIsAllTimeAvailabe]);
  const [availableStartEnd, setAvailableStartEnd] = useState(false);
  const [availabilityModalState, setAvailabilityModalState] = useState(false);
  const allTimeCombo = productAvailableAllTime();
  const toggleAvailableHoursModal = (newProductAvailabilities) => {
    const isEqual = _.isEqual(
      modifieldInitialValues?.productAvailabilities,
      newProductAvailabilities
    );
    const isAvailableAllTime = isProductAvailableAllTimeFunction(
      newProductAvailabilities
    );
    if (isEqual && isAvailableAllTime) {
      setIsProductAvailableAllTime(true);
    }
    setAvailabilityModalState(!availabilityModalState);
  };
  const handleisAvailableAllTime = (checked, setFieldValue) => {
    if (checked) {
      setFieldValue('productAvailabilities', emptyAvailableAllTime);
      setIsProductAvailableAllTime(true);
      setAvailabilityModalState(false);
    } else {
      setIsProductAvailableAllTime(false);
      setAvailabilityModalState(true);
    }
    setFieldValue('productIsProductAvailableAllTime', checked);
  };
  const handleChangeExpiry = (checked, setFieldValue) => {
    if (checked) {
      setExpire(false);
      setFieldValue('productInformation.end_datetime', null);
    } else {
      setFieldValue(
        'productInformation.end_datetime',
        modifieldInitialValues.productInformation.end_datetime === null
          ? new Date()
          : moment(modifieldInitialValues.productInformation.end_datetime)
      );
      setExpire(true);
    }
    // setFieldValue('comboNeverExpired', checked);
  };
  return (
    <Card>
      {modifieldInitialValues?.productInformation && (
        <Formik
          initialValues={modifieldInitialValues}
          enableReinitialize={productId}
          validate={(values) => {
            let errors = {};
            if (!values?.productInformation?.name) {
              errors.name = 'Required';
            }
            if (!values?.productInformation?.user_description) {
              errors.user_description = 'Required';
            } else if (
              values?.productInformation?.user_description?.length > 1024
            ) {
              errors.user_description =
                'Cannot be greater than 1024 characters';
            } else if (
              values?.productInformation?.user_description?.split(' ').length >
              150
            ) {
              errors.user_description = 'Cannot be greater than 150 words';
            }
            if (!values?.productInformation?.image_url) {
              errors.image_url = 'Image is Required';
            }
            if (values?.productInformation?.orderType?.length < 1) {
              errors.orderType = 'At least one selection is required';
            }
            return errors;
          }}
        >
          {({
            values,
            isValid,
            dirty,
            touched,
            handleReset,
            setFieldValue,
            errors,
          }) => (
            <FormWrapper>
              <NameAndPreview>
                <Field
                  name='productInformation.name'
                  component={RenderTextField}
                  label='Product Name'
                  error={
                    !touched?.productInformation ||
                    (touched?.productInformation &&
                      !touched?.productInformation?.name) ||
                    values?.productInformation?.name
                      ? null
                      : 'Required'
                  }
                  required
                  validate={maxLengthValidationForNameField}
                />
                <Field
                  name='productInformation.label'
                  component={RenderTextField}
                  label='Product Label (Optional)'
                />
                {showTaxCategory ? (
                  <TaxCategoryWrapper>
                    <Field
                      component={RenderTextField}
                      style={{ position: 'relative', top: '-3px' }}
                      type='text'
                      name='productInformation.taxCategoryId'
                      label='Tax Rate Product Type *'
                      select
                      variant='outlined'
                      margin='normal'
                      validate={required}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      {taxCategoryList.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Field>
                    <Tooltip
                      interactive
                      title={
                        <React.Fragment>
                          For more information regarding the types of taxable
                          supplies &nbsp;
                          <a
                            rel='shortcut icon'
                            target='_blank'
                            style={{
                              color: 'white',
                              textDecoration: 'underline',
                            }}
                            href='https://www.canada.ca/en/revenue-agency/services/tax/businesses/topics/gst-hst-businesses/charge-collect-which-rate.html'
                          >
                            go to the CRA website
                          </a>
                        </React.Fragment>
                      }
                    >
                      <div style={{ cursor: 'pointer' }}>
                        <i
                          className='fal fa-info-circle'
                          style={{ fontSize: '16px' }}
                        ></i>
                      </div>
                    </Tooltip>
                  </TaxCategoryWrapper>
                ) : null}
                {/* <PreviewButton
                disabled={productPreviewIsDisabled}
                onClick={() => setProductPreviewIsVisible(true)}
              >
                <Icon
                  disabled={productPreviewIsDisabled}
                  className="fas fa-eye"
                />
                Preview
              </PreviewButton> */}
              </NameAndPreview>
              <Field
                name='productInformation.user_description'
                component={RenderTextField}
                required
                rows={4}
                label='Product Description'
                error={
                  touched?.productInformation &&
                  touched?.productInformation?.user_description
                    ? values?.productInformation?.user_description
                      ? values.productInformation?.user_description.length <=
                        1024
                        ? values.productInformation?.user_description.split(' ')
                            .length <= 150
                          ? null
                          : 'Cannot be more than 150 words'
                        : 'Cannot be greater than 1024 characters'
                      : 'Required'
                    : null
                }
              />
              <ChooseImage
                imageUrl={values?.productInformation?.image_url}
                setIsImageGallaryVisible={setMediaSelectionModalIsVisible}
              />
              {errors?.image_url && (
                <div style={{ color: 'red', fontSize: '12px' }}>
                  {errors.image_url}
                </div>
              )}
              <div>
                <div>
                  <SmallToggleSwitch
                    checked={isProductAvailableAllTime}
                    onChange={({ target: { checked } }) =>
                      handleisAvailableAllTime(checked, setFieldValue)
                    }
                    label='Available All Day (Customize time & day-based availability here, In-stock availability can be managed at the bottom)'
                  />
                  <Collapse in={!isProductAvailableAllTime}>
                    <AvailabilityButton
                      onClick={() => toggleAvailableHoursModal(true)}
                    >
                      Modify Product Availability
                    </AvailabilityButton>
                  </Collapse>
                  <DateWrapper>
                    <DatesRow
                      values={values}
                      setFieldValue={setFieldValue}
                      startDateFocusedState={startDateFocusedState}
                      setstartDateFocusedState={setstartDateFocusedState}
                      expiryFocusedState={expiryFocusedState}
                      setexpiryFocusedState={setexpiryFocusedState}
                      handleChangeExpiry={handleChangeExpiry}
                      expire={expire}
                    />
                  </DateWrapper>
                  <div style={{ marginTop: '10px' }}>
                    <SmallToggleSwitch
                      name='productInformation.is_combo_only'
                      checked={values?.productInformation?.is_combo_only}
                      onChange={(e) =>
                        setFieldValue(
                          'productInformation.is_combo_only',
                          e.target.checked
                        )
                      }
                      label='Combo Only'
                    />
                  </div>
                  <div style={{ marginTop: '10px' }}>
                    <SmallToggleSwitch
                      name='productInformation.is_alcoholic'
                      checked={values?.productInformation?.is_alcoholic}
                      onChange={(e) =>
                        setFieldValue(
                          'productInformation.is_alcoholic',
                          e.target.checked
                        )
                      }
                      label='Alcoholic Product'
                    />
                  </div>
                  <Collapse in={availableStartEnd}>
                    <TimeWidgetsWrapper>
                      <TimeWrapper>
                        <div>Start Time</div>
                        <Field
                          name='productInformation.start_time'
                          component={RenderTimePicker}
                          onChange={(value) =>
                            setFieldValue(
                              'productInformation.start_time',
                              value
                            )
                          }
                          hideDate
                          max={new Date('January 1, 3999 23:30:00')}
                        />
                      </TimeWrapper>
                      <TimeWrapper>
                        <div>End Time</div>
                        <Field
                          name='productInformation.end_time'
                          component={RenderTimePicker}
                          onChange={(value) =>
                            setFieldValue('productInformation.end_time', value)
                          }
                          min={
                            new Date(
                              Moment(
                                values?.productInformation?.start_time
                              ).add('30', 'm')
                            )
                          }
                          hideDate
                        />
                      </TimeWrapper>
                    </TimeWidgetsWrapper>
                  </Collapse>
                  <div style={{ marginTop: '30px' }}>
                    {programType === 'points' ? (
                      <SmallToggleSwitch
                        name='productInformation.is_points_enabled'
                        checked={values?.productInformation?.is_points_enabled}
                        onChange={(e) =>
                          setFieldValue(
                            'productInformation.is_points_enabled',
                            e.target.checked
                          )
                        }
                        label='Points is enabled'
                      />
                    ) : null}
                  </div>
                </div>

                {/* <div>
                <SmallToggleSwitch
                  checked={isForAllBusinesses}
                  onChange={e => {
                    if (e.target.checked) {
                      setFieldValue(
                        'productBusinesses',
                        allBusinesses.map(business => business.id)
                      );
                    } else {
                      setFieldValue(
                        'productBusinesses',
                        initialValues.productBusinesses
                      );
                    }
                    setIsForAllBusinesses(!isForAllBusinesses);
                  }}
                  label="All Businesses"
                />
                <Collapse in={!isForAllBusinesses}>
                  <ReactSelectWrapper>
                    <Field
                      name="productBusinesses"
                      component={RenderSelect}
                      isMulti
                      options={allBusinesses}
                      getOptionLabel={business => business.display_name}
                      getOptionValue={business => business.id}
                      valueKey="id"
                      placeholder="Select Businesses"
                      setFieldValue={setFieldValue}
                    />
                  </ReactSelectWrapper>
                </Collapse>
              </div> */}
              </div>
              <div>
                <OrderTypeLabel>
                  Order Type*
                  <OrderTypeSub>Select all that apply</OrderTypeSub>
                </OrderTypeLabel>
                <CheckboxWrapper>
                  <Field
                    name='productInformation.orderType'
                    component={RenderCheckBox}
                    options={orderTypesList}
                    error={errors.orderType}
                  />
                </CheckboxWrapper>
              </div>
              <ButtonRow
                style={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                <Button
                  onClick={() => handleSubmit(values)}
                  disabled={
                    Object.keys(errors).length ||
                    !values.productInformation?.name ||
                    !values.productInformation?.image_url ||
                    !values.productInformation?.user_description ||
                    (showTaxCategory &&
                      !values.productInformation.taxCategoryId)
                  }
                >
                  Save
                </Button>
              </ButtonRow>
              {mediaSelectionModalIsVisible && (
                <MediaModalContainer
                  closeModal={() => setMediaSelectionModalIsVisible(false)}
                  selectProduct={(id, url) => {
                    setFieldValue('productInformation.image_url', url);
                  }}
                />
              )}
              {availabilityModalState && (
                <AvailableHoursCommonModal
                  productAvailabilities={values.productAvailabilities}
                  setFieldValue={setFieldValue}
                  toggleAvailableHoursModal={toggleAvailableHoursModal}
                  initValues={modifieldInitialValues.productInformation}
                  handleReset={handleReset}
                  setIsProductAvailableAllTime={setIsProductAvailableAllTime}
                  isProductAvailableAllTime={isProductAvailableAllTime}
                ></AvailableHoursCommonModal>
              )}
            </FormWrapper>
          )}
        </Formik>
      )}
    </Card>
  );
};

export default ProductInformationForm;
