import styled from 'styled-components';

export const ContainerDiv = styled.div`
  padding: ${(props) => props.padding || '0px 0px 0px 0px'};
  margin: ${(props) => props.margin || 'initial'};
  width: ${(props) => props.width || 'initial'};
  display: ${(props) => (props.flex ? 'flex' : 'block')};
  justify-content: ${(props) =>
    props.spaceBetween ? 'space-between' : 'initial'};
  text-align: ${(props) =>
    props.right ? 'right' : props.center ? 'center' : 'inherit'};

  @media only screen and (max-width: 1248px) {
    margin: ${(props) => (props.collapseMargin ? '20px 0 5px 0' : null)};
  }
`;

export const ModalContainerDiv = styled.div`
  margin: ${(props) =>
    props.isTitle ? '20px auto 0 auto' : props.margin || '0 0 0 0'};
  text-align: ${(props) =>
    props.center
      ? 'center'
      : props.left
      ? 'left'
      : props.right
      ? 'right'
      : 'initial'};
  width: ${(props) => props.width || '100%'};
  font-size: ${(props) => props.fontSize || 'inherit'};
`;

export const DividerLine = styled.hr`
  margin: 33px 0;
  border: solid 1px #d8d8d8;
  opacity: 0.4;
`;

export const HorizontalLine = styled.hr`
  width: ${(props) => (props.detail ? '95%' : '100%')};
  opacity: ${(props) => (props.detail ? '0.33' : 'initial')};
  margin: ${(props) => (props.detail ? '0 0 0 auto' : '0 auto')};
  border: ${(props) => (props.modalStyle ? '0' : 'solid 1px #d8d8d8')};
  border-top: ${(props) =>
    props.modalStyle ? '1px solid rgba(0, 0, 0, 0.1)' : null};
  opacity: 0.4;
`;

export const StyledP = styled.p`
  height: 26px;
  margin: 0 5px 40px 0;
  font-family: Montserrat;
  font-size: 21px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.25px;
  text-align: left;
`;

export const ModalStyledP = styled.p`
  color: ${(props) =>
    props.lightBlue ? props.theme.lightBlue : props.theme.darkBlue};
  font-size: ${(props) =>
    props.isTitle ? '36px' : props.fontSize || 'inherit'};
  font-weight: ${(props) => (props.isTitle || props.bold ? 'bold' : 'initial')};
  margin: ${(props) => props.margin || 'initial'};
  text-align: ${(props) => (props.left ? 'left' : 'inherit')};
`;
export const WrapperLoyaltyPoints = styled.div`
  width: 50%;
  margin: 0 25%;
`;
export const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    props.templateColumns || 'repeat(1, 1fr)'};
  padding: ${(props) => props.padding || '0 0 0 0'};
  gap: ${(props) => (props.gap ? props.gap : '0')};
  margin: ${(props) =>
    props.margin
      ? props.margin
      : props.amountOfTransactionDetail
      ? '5px 0 5px 0'
      : '0 0 0 0'};
  background-color: ${(props) =>
    props.grayBackground ? props.theme.lightGray : 'inherit'};

  @media only screen and (max-width: 1248px) {
    display: ${(props) => (props.collapse ? 'block' : null)};
    gap: ${(props) => (props.collapseGap ? '10px' : null)};
  }
`;

export const ModalGridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  gap: 20%;
`;

export const ModalBody = styled.div`
  background: ${(props) => props.theme.white};
  display: grid;
  grid-gap: 24px;
  border-radius: 2px;
  padding: 0 0 30px 0;
  overflow: scroll;
`;
