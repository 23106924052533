import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Select } from 'merchant-portal-components';
import AssociatedProductsModal from './AssociatedProductsModal';
import { faStepForward } from '@fortawesome/free-solid-svg-icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, Checkbox, InputAdornment } from '@material-ui/core';

const Title = styled.div`
  margin-bottom: 15px;
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
`;

const Subtitle = styled.span`
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.01px;
  color: #191818;
`;

const SelectWrapper = styled.div`
  width: 500px;
  margin: ${(props) => props.margin || 0};
`;
const ProductWrapper = styled.div`
  display: grid;
  grid-template-columns: 25px 1fr;
  margin-top: 15px;
`;
const Price = ({
  hasSubOption,
  setHasSubOption,
  setSelectedItems,
  selectedOption,
  setSelectedOption,
  optionPrices,
  options,
  isOptionDisabled,
  accociatedProductList,
  selectedOptionId,
  getAssociatedProductForOption,
  initiateEditProductWizard,
  filterOptionsByVariants,
  selectedOptionOrSubOptionId,
  setSelectedOptionOrSubOptionId,
}) => {
  const [isAssociatedProductsOpen, setIsAssociatedProductsOpen] =
    useState(false);
  const [optionTitle, setOptionTitle] = useState('');
  const [subOptions, setSubOptions] = useState([]);
  const [selectedSubOption, setSelectedSubOption] = useState([]);

  // const [selectedSubOptionId, setSelectedSubOptionId] = useState();
  const openAssociatedProductsModalHandler = () => {
    if (optionTitle) {
      setIsAssociatedProductsOpen(true);
    }
  };
  const closeAssociatedProductsModalHandler = () => {
    setIsAssociatedProductsOpen(false);
  };
  useEffect(() => {
    if (selectedOptionId) {
      getAssociatedProductForOption(selectedOptionId);
    }
  }, [selectedOptionId]);
  const handleSelectSubOption = (subOption) => {
    if (subOption?.id) {
      setSelectedOptionOrSubOptionId(subOption.id);
    } else {
      setSelectedOptionOrSubOptionId(null);
    }
    setSelectedSubOption(subOption);
  };
  const handleSelectOption = (option) => {
    setSelectedItems([]);
    setSelectedSubOption({});
    if (option) {
      if (option.sub_options?.length > 0) {
        setSubOptions(option.sub_options);
        setHasSubOption(true);
      } else {
        setSubOptions([]);
        setHasSubOption(false);
        setSelectedOptionOrSubOptionId(option?.id);
      }
      setSelectedOption(option);
      setOptionTitle(option.name);
    } else {
      setSelectedOption(null);
    }
  };
  useEffect(() => {
    if (optionPrices?.variants?.length > 0) {
      const currentItems = filterOptionsByVariants(optionPrices?.variants);
      if (hasSubOption) {
        if (selectedOptionOrSubOptionId) {
          const filteredSubOptionItems = currentItems.filter(
            (subOptionItem) => subOptionItem.option_id === selectedSubOption.id
          );
          setSelectedItems(filteredSubOptionItems);
        } else {
          setSelectedItems(null);
        }
      } else {
        setSelectedItems(currentItems);
      }
    }
  }, [optionPrices, selectedOptionOrSubOptionId]);
  const [selectedSubOptionInput, setSelectedSubOptionInput] = useState('');
  const [selectedOptionInput, setSelectedOptionInput] = useState('');
  return (
    <>
      <div>
        <SelectWrapper>
          <Title>Select an Option</Title>
          <Autocomplete
            id="checkboxes-tags-demo1"
            options={options}
            getOptionLabel={(option) =>
              option.name ? `${option.name} - ${option.id}` : ''
            }
            onChange={(e, selectedItems) => {
              handleSelectOption(selectedItems);
            }}
            value={selectedOption}
            style={{ width: '100%' }}
            inputValue={selectedOptionInput}
            onInputChange={(event, newInputValue) => {
              setSelectedOptionInput(newInputValue);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Select Option" variant="outlined" />
            )}
            disabled={isOptionDisabled}
          />
        </SelectWrapper>
        {hasSubOption && (
          <SelectWrapper margin="30px 0 0 0">
            <Title>Select a Sub option</Title>
            <Autocomplete
              id="checkboxes-tags-demo"
              options={subOptions}
              getOptionLabel={(subOption) =>
                subOption.name ? `${subOption.name} - ${subOption.id}` : ''
              }
              onChange={(e, selectedItems) => {
                handleSelectSubOption(selectedItems);
              }}
              value={selectedSubOption}
              style={{ width: '100%' }}
              inputValue={selectedSubOptionInput}
              onInputChange={(event, newInputValue) => {
                setSelectedSubOptionInput(newInputValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Sub Option"
                  variant="outlined"
                />
              )}
              disabled={isOptionDisabled}
            />
          </SelectWrapper>
        )}
        <ProductWrapper>
          <div>
            <i
              className="fal fa-eye "
              onClick={openAssociatedProductsModalHandler}
              style={{
                cursor: optionTitle ? 'pointer' : 'default',
                color: optionTitle ? '#191818' : '#6f6f76',
              }}
            ></i>
          </div>
          <div>
            <Subtitle
              onClick={openAssociatedProductsModalHandler}
              style={{
                cursor: optionTitle ? 'pointer' : 'default',
                color: optionTitle ? '#191818' : '#6f6f76',
              }}
            >
              {'View Associated Products'}
            </Subtitle>
          </div>
        </ProductWrapper>
      </div>
      {isAssociatedProductsOpen && (
        <AssociatedProductsModal
          optionTitle={optionTitle}
          onClose={closeAssociatedProductsModalHandler}
          accociatedProductList={accociatedProductList}
          initiateEditProductWizard={initiateEditProductWizard}
        />
      )}
    </>
  );
};

export default Price;
