import React, { Fragment, useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { createOptions, saveEditOptions, loadListPage } from '../optionActions';
import OptionsDetail from './components/OptionsDetail';
import BreadCrumb from '../../../../components/BreadCrumb/BreadCrumb';
import ReactGA from 'react-ga';
const Wrapper = styled.div`
  // width: 1300px;
  margin: auto;

  @media (max-width: 1313px) {
    width: 100%;
  }
`;

const Card = styled.div`
  background-color: white;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
  padding: 24px 48px;
  border-radius: 6px;
  margin: 24px 0;
`;

const BackText = styled.div`
  display: grid;
  grid-gap: 5px;
  grid-template-columns: 7px 1fr;
  cursor: pointer;
  align-items: center;
  margin-top: 20px;

  &:hover {
    color: #3051d4;
  }
`;

const OptionsDetailContainer = ({
  createOptions,
  saveEditOptions,
  isEdit,
  editingOption,
  loadListPage,
}) => {
  useEffect(() => {
    if (isEdit) {
      track.page('menu-management-option-edit');
    } else {
      track.page('menu-management-option-create');
    }
    ReactGA.pageview(window.location.pathname, undefined, 'Option Detail');
  }, []);
  const [optionId, setOptionId] = useState(1000);

  const getNextOptionId = () => {
    setOptionId(optionId + 1);
    return optionId - 1;
  };
  return (
    <Wrapper>
      <BackText onClick={() => loadListPage()}>
        <i className="fa fa-chevron-left" />
        <div>Back To Option List</div>
      </BackText>
      <BreadCrumb
        style={{ margin: ' 0px' }}
        pageName={isEdit ? 'Edit Option' : 'Create An Option'}
      />
      <Card>
        <div>
          An Option must have at least one sub option or at least one item to
          appear on the app. Options and sub options must have at least one item
          to be functional on the app. An option or sub option cannot have both
          sub options and item.
        </div>
      </Card>
      <OptionsDetail
        getNextOptionId={getNextOptionId}
        saveClick={isEdit ? saveEditOptions : createOptions}
        initialValues={
          isEdit
            ? { options: editingOption }
            : {
                options: [
                  {
                    parent_id: null,
                    id: 1000,
                    name: '',
                    minimum_pick: '',
                    maximum_pick: '',
                    included_pick: '',
                    is_active: false,
                  },
                ],
              }
        }
      />
    </Wrapper>
  );
};

const actions = { saveEditOptions, createOptions, loadListPage };

const mapStateToProps = (state) => ({
  editingOption: state.optionReducer.editingOption,
});

export default connect(mapStateToProps, actions)(OptionsDetailContainer);
