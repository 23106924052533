import {
  GET_VARIANTS_REQUEST,
  GET_VARIANTS_SUCCESS,
  GET_VARIANTS_FAILURE,
  CHANGE_COMPONENT_TYPE,
  GET_ACTIVE_REQUEST,
  GET_ACTIVE_SUCCESS,
  GET_ACTIVE_FAILURE,
  SET_EDIT_VARIANT,
  EDIT_VARIANT_REQUEST,
  EDIT_VARIANT_SUCCESS,
  EDIT_VARIANT_FAILURE,
  CREATE_VARIANT_REQUEST,
  CREATE_VARIANT_SUCCESS,
  CREATE_VARIANT_FAILURE,
  RESET_TO_INITIAL_VALUES,
  SET_LOCAL_VARIANT_ISACTIVE,
  UPDATE_SORTED_VARIANTS_LIST,
} from './variantsTypes';

const initialState = {
  allVariants: [],
  isGetVariantRequest: false,
  variantIsSaving: false,
  meta: { page_number: 1, page_size: 10 },
  componentType: 'list',
  isVariantAvailable: false,
  variantToEdit: null,
  requestParams: {
    page_number: 1,
    page_size: '25',
    sorting_option: 'title-asc',
    search_string: '',
    has_next_page: false,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case EDIT_VARIANT_REQUEST:
      return {
        ...state,
        variantIsSaving: true,
      };
    case EDIT_VARIANT_SUCCESS:
      return {
        ...state,
        componentType: 'list',
        variantIsSaving: false,
      };

    case EDIT_VARIANT_FAILURE:
      return {
        ...state,
        variantIsSaving: false,
      };
    case CREATE_VARIANT_REQUEST:
      return {
        ...state,
        variantIsSaving: true,
      };
    case CREATE_VARIANT_SUCCESS:
      return {
        ...state,
        componentType: 'list',
        variantIsSaving: false,
      };
    case CREATE_VARIANT_FAILURE:
      return {
        ...state,
        variantIsSaving: false,
      };
    case GET_VARIANTS_REQUEST:
      return {
        ...state,
        isGetVariantRequest: true,
        isVariantAvailable: false,
      };
    case GET_VARIANTS_SUCCESS:
      return {
        ...state,
        isGetVariantRequest: false,
        allVariants: action.allVariants,
        isVariantAvailable: true,
        requestParams: {
          ...state.requestParams,
          has_next_page: action.hasNextPage,
        },
      };

    case GET_VARIANTS_FAILURE:
      return {
        ...state,
        isGetVariantRequest: false,
        isVariantAvailable: false,
      };
    case CHANGE_COMPONENT_TYPE:
      return {
        ...state,
        componentType: action.componentType,
      };
    case GET_ACTIVE_REQUEST:
      return {
        ...state,
        variantIsSaving: true,
      };
    case GET_ACTIVE_SUCCESS:
      return {
        ...state,
        variantIsSaving: false,
      };
    case GET_ACTIVE_FAILURE:
      return {
        ...state,
        variantIsSaving: false,
      };
    case SET_EDIT_VARIANT:
      return {
        ...state,
        variantToEdit: action.variant,
        componentType: 'edit',
      };
    case SET_LOCAL_VARIANT_ISACTIVE:
      const updatedVariants = state.allVariants.map((variant) =>
        variant.id === action.id
          ? { ...variant, is_active: action.is_active }
          : variant
      );

      return {
        ...state,
        allVariants: updatedVariants,
      };

    case RESET_TO_INITIAL_VALUES:
      return {
        ...initialState,
        allVariants: state.allVariants,
      };
    case 'UPDATE_VARIANTS_REQUEST_PARAMS':
      return {
        ...state,
        requestParams: {
          ...state.requestParams,
          ...action.payload,
        },
      };
    case UPDATE_SORTED_VARIANTS_LIST:
      return {
        ...state,
        allVariants: action.variantsList,
        requestParams: {
          ...state.requestParams,
          sorting_option: action.sortOrder,
        },
      };
    default:
      return {
        ...state,
      };
  }
}
