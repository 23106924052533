import React, { useEffect, useCallback, useState, Suspense, lazy } from 'react';
import styled from 'styled-components';
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import _ from 'lodash';
import moment from 'moment';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { formatedDate } from '../utils/getFormatedDate';
import FilterButton from '../../../components/FilterModal/FilterButton';
import TransactionReport from '../TransactionReport/TransactionReport';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  datePiker: { height: '40px', backgroundColor: 'green' },
  button: {
    backgroundColor: '#2440ae',
    color: '#ffffff',
    maxHeight: '40px',
    width: '100%',
    borderRadius: '28px',
    fontSize: '14px',
    fontWeight: '600',
    fontStretch: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#3151d4',
    },
    '&:disabled': {
      backgroundColor: '#ebebeb',
      color: '#6f6f76',
    },
  },
}));

const Title = styled.div`
  height: 39px;
  font-family: Montserrat;
  font-size: 32px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.04px;
  margin: 25px 0 12px 0;
`;
const Subtitle = styled.div`
  height: 19px;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #191818;
  margin-bottom: 32px;
`;
const SearchCard = styled.div`
  display: grid;
  grid-template-columns: 1fr 180px;
  background-color: #ffffff;
  border-radius: 20px;
  grid-gap: 18px;
`;
const SearchWrapper = styled.div`
  display: grid;
  grid-template-columns: 450px 1fr;
  grid-gap: 15px;
`;
const DatePickerWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  width: 100%;
  /* background-color: yellow; */
`;
const LocationButton = styled.button`
  border-radius: 4px;
  padding: 7px 10px;
  cursor: pointer;
  background-color: #ffffff;
  color: #757575;
  border: 1px solid #c4c4c4;
  margin-bottom: 15px;
  width: 100%;
  &:hover {
    color: #000000;
    border: 1px solid #000000;
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  vertical-align: middle;
  text-align: center;
  width: 100%;
  /* background-color: yellow; */
`;
const ReportSearch = ({
  allBusinesses,
  generateReport,
  haslocationFiltering,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  maxDueDate,
  setMaxDueDate,
  selectedBusinessesIDs,
  setSelectedBusinessesIDs,
  setIsLocationFilterOpen,
  setMessage,
  reportType,
}) => {
  const appIdentifier = localStorage.getItem('appIdentifier');

  const parent_child_clients = ['sodexo', 'feastify'];
  const classes = useStyles();
  const [errorMessage, setErrorMessage] = useState();
  useEffect(() => {
    if ((startDate, setMaxDueDate)) {
      var newMaxDate = new Date(startDate);
      newMaxDate.setDate(newMaxDate.getDate() + 31);
      setMaxDueDate(newMaxDate);
    }
  }, [JSON.stringify(startDate)]);
  useEffect(() => {
    setStartDate(null);
    setEndDate(null);
    setSelectedBusinessesIDs([]);
  }, []);
  const handleGenerateReport = () => {
    const selectedStartDate = moment(startDate)
      .set({ hour: 0, minute: 0, second: 0 })
      .format('YYYY-MM-DD HH:mm:ss');
    const selectedEndDate = moment(endDate)
      .set({ hour: 23, minute: 59, second: 59 })
      .format('YYYY-MM-DD HH:mm:ss');
    const params = {
      page_number: 1,
      page_size: 3000,
      start_datetime: selectedStartDate,
      end_datetime: selectedEndDate,
    };
    if (haslocationFiltering && selectedBusinessesIDs) {
      Object.assign(params, { business_ids: selectedBusinessesIDs });
    }
    generateReport(params, setMessage);
  };
  const handleError = (errorMessage) => {
    setErrorMessage(errorMessage);
  };
  return (
    <SearchCard>
      <SearchWrapper>
        <DatePickerWrapper>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              clearable
              autoOk
              disableFuture
              variant='inline'
              inputVariant='outlined'
              label={
                reportType === 'transaction' && appIdentifier === 'mary_browns'
                  ? 'Select a date'
                  : 'Start Date'
              }
              format='dd/MM/yyyy'
              placeholder='DD/MM/YYYY'
              value={startDate}
              InputAdornmentProps={{ position: 'end' }}
              onChange={(date) => {
                setStartDate(date);
                if (
                  reportType === 'transaction' &&
                  appIdentifier === 'mary_browns'
                ) {
                  setEndDate(date);
                }
              }}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              style={{ width: '100%' }}
              size='small'
              onError={handleError}
            />
          </MuiPickersUtilsProvider>
          {((appIdentifier === 'mary_browns' && reportType !== 'transaction') ||
            appIdentifier !== 'mary_browns') && (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                clearable
                autoOk
                disableFuture
                disabled={!startDate}
                variant='inline'
                inputVariant='outlined'
                label='End Date'
                format='dd/MM/yyyy'
                placeholder='DD/MM/YYYY'
                value={endDate}
                onChange={(date) => setEndDate(date)}
                InputAdornmentProps={{
                  position: 'end',
                  backgroundColor: 'red',
                }}
                style={{ width: '100%' }}
                size='small'
                minDate={startDate}
                maxDate={reportType === 'transaction' ? maxDueDate : null}
                maxDateMessage={`End Date should not be more than 1 month after start date: ${startDate
                  ?.toString()
                  .substring(4, 16)}`}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                  padding: '0',
                  height: '14px',
                  color: 'blue',
                  display: 'none',
                }}
                onError={handleError}
              />
            </MuiPickersUtilsProvider>
          )}
        </DatePickerWrapper>

        <div>
          {haslocationFiltering && (
            <FilterButton
              message={
                selectedBusinessesIDs?.length === 0
                  ? 'All Locations Selected'
                  : `Selected Locations (${selectedBusinessesIDs?.length})`
              }
              setIsOpen={setIsLocationFilterOpen}
            />
          )}
        </div>
      </SearchWrapper>
      <ButtonWrapper>
        <Button
          className={classes.button}
          disabled={!(startDate && endDate && !errorMessage)}
          onClick={handleGenerateReport}
        >
          Generate Report
        </Button>
      </ButtonWrapper>
    </SearchCard>
  );
};

export default ReportSearch;
