import React from 'react';
import styled from 'styled-components';

const Title = styled.p`
  color: #191818;
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 10px;
`;

const Description = styled.p`
  color: #191818;
  font-size: 15px;
  margin-bottom: 20px;
`;

const LineDivider = styled.hr`
  margin-top: 11px;
  margin-bottom: 11px;
  border: solid 1px #ebebeb;
`;

const EventTypeRow = styled.div`
  padding: 25px 0;
  cursor: pointer;
`;

const EventTypeTitle = styled.p`
  color: #000000;
  font-size: 19px;
  font-weight: 600;
`;

const EventTypeDescription = styled.p`
  color: #383838;
  margin: 9px 0;
`;

const HeaderRow = styled.p`
  color: #191818;
  font-size: 15px;
  font-weight: 600;
  margin-top: 36px;
`;

const AutomatedMessagesList = ({ automatedMessagesList, setDetailView }) => {
  return (
    <div>
      <Title>Automated Messages</Title>
      <Description>
        Create messages that will automatically send to customers' mobile
        devices, either as a push notification or text message. Automated
        messages are a way to speak directly to customers but will only reach
        those who enable these notifications.
      </Description>
      <HeaderRow>Type</HeaderRow>
      <LineDivider />
      {Object.values(automatedMessagesList)
      .map((automatedMessage, index) => {
        return (
          <div key={index}>
            <EventTypeRow
              onClick={() => {
                setDetailView(automatedMessage);
              }}
            >
              <EventTypeTitle>{automatedMessage.title}</EventTypeTitle>
              <EventTypeDescription>
                {automatedMessage.description}
              </EventTypeDescription>
            </EventTypeRow>
            {Object.keys(automatedMessagesList).length !== index + 1 && (
              <LineDivider />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default AutomatedMessagesList;
