export const GET_OPTIONS_REQUEST = 'GET_OPTIONS_REQUEST';
export const GET_OPTIONS_SUCCESS = 'GET_OPTIONS_SUCCESS';
export const GET_OPTIONS_FAILURE = 'GET_OPTIONS_FAILURE';

export const GET_OPTIONS_SUCCESS_NORMALIZED = 'GET_OPTIONS_SUCCESS_NORMALIZED';

export const CREATE_OPTION_SUCCESS = 'CREATE_OPTION_SUCCESS';

export const LOAD_CREATE_OPTION_PAGE = 'LOAD_CREATE_OPTION_PAGE';
export const LOAD_EDIT_OPTION_PAGE = 'LOAD_EDIT_OPTION_PAGE';
export const LOAD_LIST_OPTION_PAGE = 'LOAD_LIST_OPTION_PAGE';

export const TOGGLE_OPTION_IS_ACTIVE_SUCCESS =
  'TOGGLE_OPTION_IS_ACTIVE_SUCCESS';

export const EDIT_OPTION_SUCCESS = 'EDIT_OPTION_SUCCESS';

export const RESET_OPTION_REDUCER = 'RESET_OPTION_REDUCER';

export const UPDATE_SORTED_OPTIONS_LIST = 'UPDATE_SORTED_OPTIONS_LIST';
