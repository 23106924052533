import { api } from '../../../api';
import {
  GET_SERVING_SIZE,
  GET_NUTRITION_FACTS,
  SORT_NUTRITION_FACTS,
  GET_NUTRITION_UNITS,
  GET_NUTRITION_PIVOT_DATA,
  GET_PRODUCT_SERVING_SIZE,
  PUT_PRODUCT_NUTRITION_VALUES,
} from './nutritionTypes';
import { createAlert } from '../../Alert/actions';

export function getServingSize() {
  return function (dispatch) {
    return api
      .get('/menu/nutrition/serving-sizes?page_number=1&page_size=1000')
      .then((response) => {
        dispatch({
          type: GET_SERVING_SIZE,
          servingSize: response.data.data[0],
        });
      })
      .catch((err) => {
        dispatch(
          createAlert({
            type: 'error',
            message: err?.response?.data?.errors?.message || 
              'An error occurred trying to get the nutrition serving size.',
          })
        );
      });
  };
}

export function getNutritionFacts() {
  return function (dispatch) {
    return api
      .get('/menu/nutrition/facts?page_number=1&page_size=1000')
      .then((response) => {
        const orderedByPosition = response.data.data.sort((a, b) => {
          return a.position - b.position;
        });
        dispatch({
          type: GET_NUTRITION_FACTS,
          nutritionFactsList: orderedByPosition,
        });
      })
      .catch((err) => {
        dispatch(
          createAlert({
            type: 'error',
            message: err?.response?.data?.errors?.message || 'An error occurred trying to get the nutrition facts.',
          })
        );
      });
  };
}

export function postNutritionFact(values) {
  return function (dispatch) {
    return Promise.all(
      values.nutritionFacts.map((value, index) => {
        if (value.id === null) {
          const nutritionFactValue = {
            name: value.display_name,
            display_name: value.display_name,
            description: null,
            parent_id: null,
            position: index,
          };
          return api.post(`/menu/nutrition/facts`, nutritionFactValue);
        } else {
          return api.patch(`/menu/nutrition/facts/${value.id}`, {
            display_name: value.display_name,
            name: value.display_name,
            position: index,
          });
        }
      })
    )
      .then((responses) => {
        const nutritionList = responses.map((response) => response.data.data);
        dispatch({
          type: GET_NUTRITION_FACTS,
          nutritionFactsList: nutritionList,
        });
        dispatch(
          createAlert({
            type: 'success',
            message: 'Nutrition Fact successfully added',
          })
        );
      })
      .catch((err) =>
        dispatch(
          createAlert({
            type: 'error',
            message: err.response.data,
          })
        )
      );
  };
}

export function sortNutritionFacts(nutritionFactsList) {
  return function (dispatch) {
    return Promise.all(
      nutritionFactsList.map((nutritionFact, newPosition) => {
        return api.patch(`/menu/nutrition/facts/${nutritionFact.id}`, {
          position: newPosition,
        });
      })
    )
      .then(() => {
        dispatch({
          type: SORT_NUTRITION_FACTS,
          payload: nutritionFactsList,
        });
        dispatch(
          createAlert({
            type: 'success',
            message: 'Nutrition Fact successfully sorted',
          })
        );
      })
      .catch((err) =>
        dispatch(
          createAlert({
            type: 'error',
            message: err.response.data,
          })
        )
      );
  };
}

export function getProductNutritionValues(productId, productVariantId) {
  return function (dispatch) {
    return api
      .get(`/menu/products/${productId}/nutritions`)
      .then((response) => {
        const filteredList = [];
        response.data.data.forEach((singleItem) => {
          if (singleItem.product_variant_id === productVariantId)
            filteredList.push(singleItem);
        });
        dispatch({
          type: GET_NUTRITION_PIVOT_DATA,
          nutritionPivotData: response.data.data,
          nutritionPivotDataFiltered: filteredList,
          productId,
          productVariantId,
        });
      })
      .catch((err) => {
        dispatch(
          createAlert({
            type: 'error',
            message: err?.response?.data?.errors?.message || 'An error occurred trying to get the nutritions. ',
          })
        );
      });
  };
}

export function getNutritionUnits() {
  return function (dispatch) {
    return api
      .get('/menu/nutrition/units?page_number=1&page_size=1000')
      .then((response) => {
        dispatch({
          type: GET_NUTRITION_UNITS,
          nutritionUnitsList: response.data.data,
        });
      })
      .catch((err) => {
        dispatch(
          createAlert({
            type: 'error',
            message: err?.response?.data?.errors?.message || 'An error occurred trying to get the nutrition units.',
          })
        );
      });
  };
}

export function putProductNutritionValues(
  productId,
  value,
  servingSizeId,
  servingSizeName
) {
  return function (dispatch, getState) {
    let nutritionPivotDataAll = getState().nutritionReducer.nutritionPivotData;
    let putBody = [];
    nutritionPivotDataAll.forEach((item) => {
      if (item.product_variant_id === value[0].product_variant_id) return;

      putBody.push({
        is_featured: item.is_featured,
        nutrition_fact_daily: item.nutrition_fact_daily,
        nutrition_fact_id: item.nutrition_fact_id,
        nutrition_fact_max_value: item.nutrition_fact_max_value,
        nutrition_fact_min_value: item.nutrition_fact_min_value,
        nutrition_fact_unit_id: item.nutrition_fact_unit_id,
        product_variant_id: item.product_variant_id,
        serving_sizes_id: item.serving_sizes_id,
      });
    });
    putBody = [...putBody, ...value];

    let apiCall = null;
    if (!servingSizeId) {
      apiCall = api.post(`/menu/nutrition/serving-sizes`, {
        name: servingSizeName,
        description: null,
      });
    } else {
      apiCall = api.patch(`/menu/nutrition/serving-sizes/${servingSizeId}`, {
        name: servingSizeName,
      });
    }
    return apiCall
      .then((responseServingSize) => {
        value.map((v) => ({
          ...v,
          serving_sizes_id: responseServingSize.data.data.id,
        }));
        const productServingSize = responseServingSize.data.data;
        return api
          .put(`/menu/products/${productId}/nutritions`, {
            nutritions: putBody,
          })
          .then((response) => {
            dispatch({
              type: PUT_PRODUCT_NUTRITION_VALUES,
              nutritionPivotData: response.data.data,
              productServingSize,
            });
            dispatch(
              createAlert({
                type: 'success',
                message: 'Nutrition Values successfully updated',
              })
            );
          })
          .catch((err) =>
            dispatch(
              createAlert({
                type: 'error',
                message: err?.response?.data?.errors?.message || 'An error occurred trying to save nutrition values.',
              })
            )
          );
      })
      .catch((err) =>
        dispatch(
          createAlert({
            type: 'error',
            message: err?.response?.data?.errors?.message || 'An error occurred trying to save serving size.',
          })
        )
      );
  };
}

export function getProductServingSize(productId) {
  return function (dispatch) {
    return api
      .get(`/menu/products/${productId}/nutritions`)
      .then((responseGet) => {
        if (responseGet.data.data.length) {
          const servingSizeId = responseGet.data.data[0].serving_sizes_id;
          api
            .get(`/menu/nutrition/serving-sizes/${servingSizeId}`)
            .then((responseGet2) => {
              dispatch({
                type: GET_PRODUCT_SERVING_SIZE,
                productServingSize: responseGet2.data.data,
              });
            });
        } else {
          dispatch({
            type: GET_PRODUCT_SERVING_SIZE,
            productServingSize: '',
          });
        }
      });
    // .catch(err => {
    //   dispatch(
    //     createAlert({
    //       type: 'error',
    //       message: 'An error occurred trying to get the product serving size.'
    //     })
    //   );
    // });
  };
}
