import React, { useState, Fragment, useEffect } from "react";
import DeactivateLocation from "./components/DeactivateLocation";
import ReactivateLocation from "./components/ReactivateLocation";
import LocationDeactivationModal from "./components/LocationDeactivationModal";
import LocationReactivationModal from "./components/LocationReactivationModal";
import { connect } from "react-redux";
import { updateBusinessInformation } from "../../actions";

const DeactivateLocationContainer = ({
  accountReducer,
  businessLocationDetailsReducer,
  updateBusinessInformation,
}) => {
  const { role } = accountReducer;

  const { businessInformationInitialValues, businessId } =
    businessLocationDetailsReducer;

  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  return (
    <Fragment>
      {role === "ROLE_SUPER_ADMIN" &&
        businessInformationInitialValues &&
        businessInformationInitialValues.master_is_active && (
          <DeactivateLocation
            isLocationActive={businessInformationInitialValues.master_is_active}
            setOpenConfirmationModal={setOpenConfirmationModal}
          />
        )}
      {role === "ROLE_SUPER_ADMIN" &&
        businessInformationInitialValues &&
        !businessInformationInitialValues.master_is_active && (
          <ReactivateLocation
            businessId={businessId}
            cancel={() => setOpenConfirmationModal(false)}
            isLocationActive={businessInformationInitialValues.master_is_active}
            setOpenConfirmationModal={setOpenConfirmationModal}
          />
        )}
      {openConfirmationModal &&
        businessInformationInitialValues &&
        businessInformationInitialValues.master_is_active && (
          <div onClick={(e) => e.stopPropagation()}>
            <LocationDeactivationModal
              businessId={businessId}
              location={businessInformationInitialValues}
              cancel={() => setOpenConfirmationModal(false)}
              updateBusinessInformation={updateBusinessInformation}
            />
          </div>
        )}

      {openConfirmationModal &&
        businessInformationInitialValues &&
        !businessInformationInitialValues.master_is_active && (
          <div onClick={(e) => e.stopPropagation()}>
            <LocationReactivationModal
              businessId={businessId}
              location={businessInformationInitialValues}
              cancel={() => setOpenConfirmationModal(false)}
              updateBusinessInformation={updateBusinessInformation}
            />
          </div>
        )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  accountReducer: state.accountReducer,
  businessLocationDetailsReducer: state.businessLocationDetails,
});

export default connect(mapStateToProps, { updateBusinessInformation })(
  DeactivateLocationContainer
);
