import {
  GET_OFFERS_ENGINE_REQUEST,
  GET_OFFERS_ENGINE_SUCCESS,
  GET_OFFERS_ENGINE_FAILURE,
  TOGGLE_OFFER_ENGINE_AVAILABILITY_SUCCESS,
  INITIATE_CREATE_OFFER_ENGINE,
  INITIATE_CREATE_EDIT_OFFER_ENGINE,
  CREATE_OFFER_ENGINE_REQUEST,
  CREATE_OFFER_ENGINE_SUCCESS,
  CREATE_OFFER_ENGINE_FAILURE,
  INITIATE_EDIT_OFFER_ENGINE,
  EDIT_OFFER_ENGINE_REQUEST,
  EDIT_OFFER_ENGINE_SUCCESS,
  EDIT_OFFER_ENGINE_FAILURE,
  RESET_OFFER_ENGINE_STATE,
  GET_PROMO_REQUEST,
  GET_PROMO_SUCCESS,
  GET_PROMO_FAILURE,
  SORT_OFFER_LIST,
  UPDATE_SORTED_OFFERS_LIST,
  UPDATE_PROMO_REQUEST_PARAMS,
  UPDATE_OFFERS_REQUEST_PARAMS,
  GET_OFFERS_POSITIONS,
} from './OfferEngineTypes';
import { api } from '../../api';
import { createAlert } from '../Alert/actions';
import moment from 'moment';
import _ from 'lodash';
import { getPurePayload, getSearchUrl } from '../../utils/purePayload';

const getEndDayOfWeek = (product) => {
  const startTime = moment(product.start_time);
  const endTime = moment(product.end_time);
  let endDayOfWeek = product.start_day_of_week;
  if (startTime.isAfter(endTime)) {
    endDayOfWeek = endDayOfWeek + 1 > 7 ? 1 : endDayOfWeek + 1;
  }
  return endDayOfWeek;
};

export function getOffersEngine(
  requestParams = {
    page_number: 1,
    page_size: '25',
    sorting_option: 'title-asc',
    search_string: '',
    has_next_page: true,
  }
) {
  return function (dispatch, getState) {
    dispatch({ type: GET_OFFERS_ENGINE_REQUEST });
    const urlwithOutSearchString = `v2/offers?page_number=${requestParams.page_number}&page_size=${requestParams.page_size}`;
    let url = getSearchUrl(
      urlwithOutSearchString,
      requestParams.search_string,
      3
    );

    const options = getState().OfferEngineReducer.options;
    return api.get(url).then(
      (response) => {
        if (!options) {
          api.get(`/offers/config`).then((res) => {
            dispatch({
              type: 'GET_OFFER_OPTIONS_SUCCESS',
              options: res.data.data,
            });
          });
        }
        dispatch({
          type: GET_OFFERS_ENGINE_SUCCESS,
          allOffersEngine: response.data.data,
          has_next_page: response.data.meta.has_next_page,
        });
      },
      (error) => {
        dispatch({ type: GET_OFFERS_ENGINE_FAILURE, error });
      }
    );
  };
}
export function getLoyaltyTiers() {
  return function (dispatch) {
    dispatch({ type: GET_OFFERS_ENGINE_REQUEST });
    return api.get(`/loyalty-tiers`).then(
      (response) => {
        dispatch({
          type: 'GET_LOYALTY_TIERS_SUCCESS',
          loyaltyTiers: response.data.data,
        });
      },
      (error) => {
        dispatch({ type: GET_OFFERS_ENGINE_FAILURE, error });
      }
    );
  };
}
const getError = (allErrors, dispatch) => {
  let messages = '';
  if (allErrors) {
    Object.keys(allErrors).forEach((key, index) => {
      if (index === 0) {
        messages = `${key} : ${allErrors[key]}`;
      } else {
        messages.concat(`\n${key} : ${allErrors[key]}`);
      }
    });
  } else {
    messages = error.response.data?.errors?.message;
  }
  dispatch(
    createAlert({
      type: 'error',
      message: messages,
    })
  );
};
export function getOffersPosition() {
  return function (dispatch) {
    return api.get(`v2/offer/positions`).then((response) => {
      const newAllOffersPositions = response.data.data?.map((item) => {
        return {
          offer_id: item.offer_id,
          name: item.offer_name,
          position: item.position,
        };
      });
      let sortedPositions = _.sortBy(
        newAllOffersPositions,
        ['position'],
        'asc'
      );
      dispatch({
        type: GET_OFFERS_POSITIONS,
        allOffersPositions: sortedPositions,
      });
    });
  };
}
export function updateOffersPosition(offerPositions) {
  return function (dispatch) {
    const purePayload = getPurePayload({ offers: offerPositions });
    return api.post(`v2/offers/re-order`, purePayload).then(() => {
      dispatch(getOffersPosition());
      dispatch(getOffersEngine());
    });
  };
}
export function updateOfferReorderingRequestParams(requestParams) {
  return function (dispatch) {
    dispatch({
      type: 'UPDATE_OFFER_REORDERING_REQUEST_PARAMS',
      payload: requestParams,
    });
  };
}
export function getPromos(
  requestParamsPromo = {
    page_number: 1,
    page_size: '10',
    sorting_option: 'title-asc',
    search_string: '',
  },
  offer_id
) {
  return function (dispatch, getState) {
    const editingOfferId =
      getState().OfferEngineReducer.editingOfferEngine?.offer_details?.offer_id;
    dispatch({ type: GET_PROMO_REQUEST });
    const urlWithoutSearch = `/v2/offers/${
      offer_id || editingOfferId
    }/activation-codes?page_number=${
      requestParamsPromo.page_number
    }&page_size=${requestParamsPromo.page_size}`;
    const url = getSearchUrl(
      urlWithoutSearch,
      requestParamsPromo.search_string,
      3
    );
    api.get(url).then((response) => {
      const hasNextPage = response.data.meta.has_next_page;
      dispatch({
        type: GET_PROMO_SUCCESS,
        offerPromoCode: response.data.data,
        requestParamsPromo: response.data.meta,
        hasNextPage: hasNextPage,
      });
    }),
      (error) => {
        dispatch({ type: GET_PROMO_FAILURE, error });
      };
  };
}

export function toggleOfferAvailability(offer) {
  const formatedOder = toggleOfferFormat(offer);
  const pureToggleOfferPayload = getPurePayload(formatedOder);
  return function (dispatch) {
    return api
      .patch(`v2/offers/${offer.offer_id}`, pureToggleOfferPayload)
      .then(() => {
        dispatch({ type: TOGGLE_OFFER_ENGINE_AVAILABILITY_SUCCESS, offer });
        dispatch(
          createAlert({
            type: 'success',
            message: 'Offer updated successfully.',
          })
        );
      });
  };
}

export function updateProductsRequestParams(requestParams) {
  return {
    type: 'UPDATE_PRODUCTS_REQUEST_PARAMS',
    payload: requestParams,
  };
}

export function updatePromoRequestParams(requestParamsPromo) {
  return {
    type: UPDATE_PROMO_REQUEST_PARAMS,
    payload: requestParamsPromo,
  };
}

export function initiateCreateOffer() {
  return function (dispatch) {
    return Promise.all([
      api.get(`/customers?page_number=1&page_size=1000`),
      api.get(`/segments`),
      api.get(`/businesses`),
      api.get(`/offers/config`),
      api.get(`/menu/products?page_number=1&page_size=10000&include=prices`),
    ]).then((response) => {
      dispatch({
        type: INITIATE_CREATE_OFFER_ENGINE,
        customerList: response[0].data.data,
        segmentList: response[1].data.data,
        businessList: response[2].data.data,
        offerEngineSource: response[3].data.data,
        offerEngineProducts: response[4].data.data,
      });
    });
  };
}
export function initiateCreateEditOffer() {
  return function (dispatch) {
    return Promise.all([
      api.get(`/customers?page_number=1&page_size=1000`),
      api.get(`/segments`),
      api.get(`/businesses`),
      api.get(`/offers/config`),
    ]).then((response) => {
      dispatch({
        type: INITIATE_CREATE_EDIT_OFFER_ENGINE,
        customerList: response[0].data.data,
        segmentList: response[1].data.data,
        businessList: response[2].data.data,
        offerEngineSource: response[3].data.data,
      });
    });
  };
}
export function createOffer(updatedOffers) {
  const pureUpdatedOffer = getPurePayload(updatedOffers);
  let offerEditID = updatedOffers.offer_details.offer_id;
  const offer = _.cloneDeep(updatedOffers);
  return function (dispatch, getState) {
    let dataOffer = {
      title: offer.offer_details.title,
      label: offer.offer_details.label,
      subtitle: offer.offer_details.subtitle,
      image_url: offer.offer_details.image_url,
      details: '',
      description: offer.offer_details.description,
      label: offer.offer_details.label,
      is_active: offer.offer_details.is_active,
      auto_redeem: offer.offer_details.auto_redeem,
      reusable: offer.offer_details.reusable,
      is_visible: offer.offer_details.is_visible,
      is_carryover: offer.offer_details.is_carryover,
      apply_multiple: offer.offer_details.apply_multiple,
      sort_price: offer.offer_details.sort_price,
      start_date: offer.offer_details.start_date,
      end_date: offer.offer_details.end_date || '',
      template_name: offer.offer_details.template_name,
      businesses: offer.offer_details.businesses,
      segments: offer.segments,
      order_types: offer.order_types,
      conditions: offer.conditionValue,
      availability: offer.offerAvailabilities.map((products) => ({
        start_time:
          products.start_time &&
          products.start_time != NaN &&
          products.end_time != 'NaN' &&
          products.end_time != 'Invalid date'
            ? moment(products.start_time).format('HH:mm:ss')
            : '00:00:00',
        end_time:
          products.end_time &&
          products.end_time != NaN &&
          products.end_time != 'NaN' &&
          products.end_time != 'Invalid date'
            ? moment(products.end_time).format('HH:mm:ss')
            : '00:00:00',
        start_day_of_week: products.start_day_of_week,
        end_day_of_week: getEndDayOfWeek(products),
      })),
    };
    // pure payload
    const pureOfferPayload = getPurePayload(dataOffer);
    const pureOffer = getPurePayload(offer);
    //
    let newOfferPromoCodes = updatedOffers.offerPromoCode;
    if (offerEditID === undefined) {
      // 1 prepare promo code
      let postShareablePromoCodes = [];
      let postNonShareablePromoCodes = [];
      if (newOfferPromoCodes.length > 0) {
        newOfferPromoCodes.forEach((promo) => {
          if (promo.is_shareable) {
            postShareablePromoCodes.push(promo.code);
          } else {
            postNonShareablePromoCodes.push(promo.code);
          }
        });
      }
      api
        .post(`/v2/offers`, pureOfferPayload)
        .then((response) => {
          let str = response.headers.location;
          var rest = str.substring(0, str.lastIndexOf('/') + 1);
          var last = str.substring(str.lastIndexOf('/') + 1, str.length);
          let offerIdCreate = Number(last);

          if (offer.customerList.length > 0) {
            api.post(`/v2/offers/${offerIdCreate}/customers`, {
              customers: pureOffer.customerList,
            });
          }
          // 1 post promo codes
          if (postShareablePromoCodes.length > 0) {
            api.post(`/v2/offers/${offerIdCreate}/activation-codes`, {
              codes: postShareablePromoCodes,
              is_shareable: true,
            });
          }
          if (postNonShareablePromoCodes.length > 0) {
            api.post(`/v2/offers/${offerIdCreate}/activation-codes`, {
              codes: postNonShareablePromoCodes,
              is_shareable: false,
            });
          }
          //
          dispatch(
            createAlert({
              type: 'success',
              message: 'Offer created successfully',
            })
          );
          dispatch({ type: CREATE_OFFER_ENGINE_SUCCESS });
        })
        .catch((error) => {
          const allErrors = error.response.data?.errors?.details;
          getError(allErrors, dispatch);
        });
    } else {
      // add and delete customers
      const currentCustomerList = pureUpdatedOffer.currentAssignedCustomers;
      let postCustomerList = [];
      let deleteCustomerList = [];
      if (pureUpdatedOffer.customerList.length > 0) {
        pureUpdatedOffer.customerList.forEach((id) => {
          if (!currentCustomerList.includes(id)) {
            postCustomerList.push(id);
          }
        });
      }
      if (currentCustomerList.length > 0) {
        currentCustomerList.forEach((id) => {
          if (!pureUpdatedOffer.customerList.includes(id)) {
            deleteCustomerList.push(id);
          }
        });
      }
      // add and delete promo codes
      const currentPromoCodes =
        getState().OfferEngineReducer.offerPromoCode.map((promo) => promo.code);
      let postShareablePromoCodes = [];
      let postNonShareablePromoCodes = [];
      let deletePromoCodes = [];
      if (newOfferPromoCodes.length > 0) {
        newOfferPromoCodes.forEach((promo) => {
          if (!currentPromoCodes.includes(promo.code)) {
            if (promo.is_shareable) {
              postShareablePromoCodes.push(promo.code);
            } else {
              postNonShareablePromoCodes.push(promo.code);
            }
          }
        });
      }
      if (currentPromoCodes.length > 0) {
        currentPromoCodes.forEach((code) => {
          const modifiedOfferPromoCodes = newOfferPromoCodes.map(
            (promo) => promo.code
          );
          if (!modifiedOfferPromoCodes.includes(code)) {
            deletePromoCodes.push(code);
          }
        });
      }
      if (
        pureOfferPayload.segments.length > 0 &&
        currentCustomerList.length > 0
      ) {
        api
          .delete(`/v2/offers/${offerEditID}/customers`, {
            data: {
              customers: pureUpdatedOffer.currentAssignedCustomers,
            },
          })
          .then(() => {
            api
              .patch(`/v2/offers/${offerEditID}`, pureOfferPayload)
              .then(() => {
                if (postShareablePromoCodes.length > 0) {
                  api.post(`/v2/offers/${offerEditID}/activation-codes`, {
                    codes: postShareablePromoCodes,
                    is_shareable: true,
                  });
                }
                if (postNonShareablePromoCodes.length > 0) {
                  api.post(`/v2/offers/${offerEditID}/activation-codes`, {
                    codes: postNonShareablePromoCodes,
                    is_shareable: false,
                  });
                }
                if (deletePromoCodes.length > 0) {
                  api.delete(`/v2/offers/${offerEditID}/activation-codes`, {
                    data: {
                      codes: deletePromoCodes,
                    },
                  });
                }

                dispatch(
                  createAlert({
                    type: 'success',
                    message: 'Offer created successfully',
                  })
                );
                dispatch({ type: CREATE_OFFER_ENGINE_SUCCESS });
              });
          })
          .catch((error) => {
            const allErrors = error.response.data?.errors?.details;
            getError(allErrors, dispatch);
          });
      } else {
        api
          .patch(`/v2/offers/${offerEditID}`, pureOfferPayload)
          .then(() => {
            if (deleteCustomerList.length > 0) {
              api.delete(`/v2/offers/${offerEditID}/customers`, {
                data: {
                  customers: deleteCustomerList,
                },
              });
            }
            if (postCustomerList.length > 0) {
              api.post(`/v2/offers/${offerEditID}/customers`, {
                customers: postCustomerList,
              });
            }
            if (postShareablePromoCodes.length > 0) {
              api.post(`/v2/offers/${offerEditID}/activation-codes`, {
                codes: postShareablePromoCodes,
                is_shareable: true,
              });
            }
            if (postNonShareablePromoCodes.length > 0) {
              api.post(`/v2/offers/${offerEditID}/activation-codes`, {
                codes: postNonShareablePromoCodes,
                is_shareable: false,
              });
            }
            if (deletePromoCodes.length > 0) {
              api.delete(`/v2/offers/${offerEditID}/activation-codes`, {
                data: {
                  codes: deletePromoCodes,
                },
              });
            }
            dispatch(
              createAlert({
                type: 'success',
                message: 'Offer created successfully',
              })
            );
            dispatch({ type: CREATE_OFFER_ENGINE_SUCCESS });
          })
          .catch((error) => {
            const allErrors = error.response.data?.errors?.details;
            getError(allErrors, dispatch);
          });
      }
    }
  };
}

export function editOffer(offer) {
  return function (dispatch, getState) {
    const { editingOfferEngineId } = getState().offersReducer;
    if (offer.offer_details?.template_name === 'first-time') {
      delete offer.conditions;
    }
    const editingOfferEngine = createOfferStructure(offer);
    const pureOfferPayload = getPurePayload(editingOfferEngine);
    dispatch({ type: EDIT_OFFER_ENGINE_REQUEST });
    api
      .put(`/offers/${editingOfferEngineId}`, pureOfferPayload)
      .then(() => {
        dispatch(
          createAlert({
            type: 'success',
            message: 'Offer edited successfully',
          })
        );
        dispatch({ type: EDIT_OFFER_ENGINE_SUCCESS });
      })
      .catch((error) => {
        const allErrors = error.response.data?.errors?.details;
        getError(allErrors, dispatch);
      });
  };
}

export function initiateEditOfferEngine(offer_id, isCopyingOfferEngine) {
  return function (dispatch) {
    return Promise.all([
      api.get(`v2/offers/${offer_id}`),
      api.get(`v2/offers/${offer_id}/customers?page_number=1&page_size=100`),
      api.get(`/customers?page_number=1&page_size=1000`),
      api.get(`/segments`),
      api.get(`/businesses`),
      api.get(`/offers/config`),
      api.get(`/menu/products?page_number=1&page_size=10000&include=prices`),
      api.get(`v2/offers/${offer_id}/customers?page_number=1&page_size=10000`),
    ]).then((response) => {
      const segmentArray = [];
      response[0].data.data.segments.forEach((segment) => {
        segmentArray.push(segment.segment_id);
      });
      response[0].data.data.segments = segmentArray;

      const consumerDataList = [];
      response[8]?.data?.data.forEach((consumer) => {
        consumerDataList.push(consumer.customer_id);
      });
      var offer_details = {
        offer_details: response[0].data.data,
      };
      let editingOfferEngine = offer_details;
      editingOfferEngine.offer_details.never_expires =
        response[0].data.data.end_date === null ||
        response[0].data.data.end_date === ''
          ? true
          : false;
      if (isCopyingOfferEngine) {
        delete editingOfferEngine.offer_details.offer_id;
      }
      dispatch({
        type: INITIATE_EDIT_OFFER_ENGINE,
        editingOfferEngine,
        offerAvailability: editingOfferEngine.offer_details.availability,
        editingOfferEngineId: isCopyingOfferEngine ? null : offer_id,
        customerList: response[2].data.data,
        segmentList: response[3].data.data,
        businessList: response[4].data.data,
        offerEngineSource: response[5].data.data,
        offerEngineProducts: response[6].data.data,
        offerPromoCode: response[7]?.data?.data,
        customerData: consumerDataList,
        currentAssignedCustomers: response[1].data.data,
        isCopyingOfferEngine: isCopyingOfferEngine,
      });
    });
  };
}

export function resetOfferEngineStates() {
  return {
    type: RESET_OFFER_ENGINE_STATE,
  };
}

const toggleOfferFormat = (offer) => {
  return {
    is_active: !offer.is_active,
  };
};

const createOfferStructure = (offer) => {
  let newOfferStructure = offer;
};

export function updateOffersRequestParams(requestParams) {
  return {
    type: 'UPDATE_OFFERS_REQUEST_PARAMS',
    payload: requestParams,
  };
}

export function updateSortedOffersList(offersList, sortOrder) {
  return {
    type: 'UPDATE_SORTED_OFFERS_LIST',
    offersList: offersList,
    sortOrder: sortOrder,
  };
}

export function sortOffer(productList, callback, updatedParentCategory) {
  return function (dispatch) {
    let offer_ids = productList.map((offer) => offer.offer_id);
    const purePayload = getPurePayload({ offer_ids });
    return api
      .post(`/v2/offers/re-order`, purePayload)
      .then(() => {
        dispatch(
          createAlert({
            type: 'success',
            message: 'Offer List successfully updated',
          })
        );
        dispatch({
          type: GET_OFFERS_ENGINE_SUCCESS,
          allOffersEngine: productList,
        });
        callback();
      })
      .catch((err) =>
        dispatch(
          createAlert({
            type: 'error',
            message:
              err?.response?.data?.errors?.message ||
              'An error occurred trying to Offer List',
          })
        )
      );
  };
}
