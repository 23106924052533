import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
const developmentFirebaseConfig = {
  apiKey: 'AIzaSyBlHQkKhaYL8k9EJMnk0y0_SCAqhTXdPOA',
  authDomain: 'develop-orders.firebaseapp.com',
  databaseURL: 'https://develop-orders-a6c93.firebaseio.com',
  projectId: 'develop-orders',
  storageBucket: 'develop-orders.appspot.com',
  messagingSenderId: '128462065576',
  appId: '1:128462065576:web:f1955dacfda39eef7e179d',
  measurementId: 'G-1TKR1H5360',
};
const stagingFirebaseConfig = {
  apiKey: 'AIzaSyALtG59qFL66pYfvE02q8uP4Xb5rjAVDTI',
  authDomain: 'test-firebase-php.firebaseapp.com',
  databaseURL: 'https://test-firebase-php-86f70.firebaseio.com',
  projectId: 'test-firebase-php',
  storageBucket: 'test-firebase-php.appspot.com',
  messagingSenderId: '798173583438',
  appId: '1:798173583438:web:a906ecb1fdc18ff8cd8b1f',
  measurementId: 'G-QX0Y754YNK',
};
const sandboxFirebaseConfig = {
  apiKey: 'AIzaSyB_7LqOxCkHYUjCjc3En-ywzMjf8KQVk3M',
  authDomain: 'sandbox-orders-cb8e8.firebaseapp.com',
  databaseURL: 'https://sandbox-orders-tracker.firebaseio.com/',
  projectId: 'sandbox-orders-cb8e8',
  storageBucket: 'sandbox-orders-cb8e8.appspot.com',
  messagingSenderId: '1060394646511',
  appId: '1:1060394646511:web:759f03bafa94311eac941a',
  measurementId: 'G-Y1J5QTFWF0',
};

var productionFirebaseConfig = {
  apiKey: 'AIzaSyBCkJDorGA0Rmj1VdsKgONA-NJBVYId_I0',
  authDomain: 'smoothcommerce-orders.firebaseapp.com',
  databaseURL: 'https://smoothcommerce-orders-tracker.firebaseio.com',
  projectId: 'smoothcommerce-orders',
  storageBucket: 'smoothcommerce-orders.appspot.com',
  messagingSenderId: '91076220229',
  appId: '1:91076220229:web:15ee834894d9df9055da2c',
};
const firebaseConfig =
  ENV === 'development'
    ? developmentFirebaseConfig
    : ENV === 'staging'
    ? stagingFirebaseConfig
    : ENV === 'sandbox'
    ? sandboxFirebaseConfig
    : productionFirebaseConfig;
const secondDatabaseName =
  ENV === 'development'
    ? 'develop-orders-a6c93'
    : ENV === 'staging'
    ? 'test-firebase-php-86f70'
    : ENV === 'sandbox'
    ? 'sandbox-orders-tracker'
    : 'smoothcommerce-orders-tracker';
let app2 = null;
const initFirebase = () => {
  // firebase.initializeApp(firebaseConfig);
  app2 = firebase.initializeApp(firebaseConfig, secondDatabaseName);
  const firebaseToken = localStorage.getItem('firebase_token');
  if (firebaseToken) {
    app2
      .auth()
      .signInWithCustomToken(localStorage.getItem('firebase_token'))
      .then((userCredential) => {
        console.log('successful firebase sign in');
      })
      .catch((error) => {
        console.log('firebase token error:', error.message);
      });
  }
};
initFirebase();

export default app2;
