import React, { useState, Fragment } from 'react';
import styled from 'styled-components';
import { Formik, Field, FieldArray } from 'formik';
import { Icon } from 'merchant-portal-components';
import { LoadingBlueLarge } from 'merchant-portal-components';
import StickyButtons from '../../../../components/StickyButtons';
import RenderNutritionFacts from './RenderNutritionFacts';
import { RenderTextField } from '../../../../components/FormElements/FormikElements';

const MainDiv = styled.div`
  margin-top: 24px;
`;

const ButtonRow = styled.div`
  margin-bottom: 24px;
`;

const AddButton = styled.button`
  background: none;
  border: none;
  letter-spacing: 0.02px;
  color: #231f20;
  font-weight: 600;
  font-size: 15px;
  cursor: pointer;

  & span {
    margin-left: 8px;
  }

  :disabled {
    color: #666666;
  }
`;

const EditButton = styled(AddButton)`
  margin-left: 24px;
`;

const FactsList = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
`;

const NutritionalInformationList = ({
  nutritionTemplatePage,
  handleNutritionFactSave,
  sortNutritionFacts,
}) => {
  const nutritionFactsList = nutritionTemplatePage.nutritionFactsList;
  const servingSize = nutritionTemplatePage.servingSize;
  const [isEditable, setIsEditable] = useState(false);

  const handleNutritionFactsSave = (values) => {
    handleNutritionFactSave(values);
  };

  return (
    // nutritionTemplatePage.isRequestingGetServingSize
    <MainDiv>
      <FactsList>
        <div>
          {nutritionTemplatePage.isRequestingGetNutritionFacts ? (
            <LoadingBlueLarge />
          ) : (
            <div>
              <Formik
                initialValues={{
                  nutritionFacts: nutritionFactsList,
                  //servingSize: servingSize.name
                }}
                enableReinitialize={true}
              >
                {({
                  dirty,
                  values,
                  setValues,
                  setFieldValue,
                  handleReset,
                  errors,
                }) => (
                  <Fragment>
                    <FieldArray
                      name="nutritionFacts"
                      render={(arrayHelpers) => (
                        <Fragment>
                          <ButtonRow>
                            <AddButton
                              disabled={
                                isEditable ||
                                arrayHelpers.form.values.nutritionFacts[
                                  arrayHelpers.form.values.nutritionFacts
                                    .length - 1
                                ].display_name === undefined
                              }
                              onClick={() =>
                                arrayHelpers.push({
                                  id: null,
                                  nutritionFactsList: [],
                                })
                              }
                            >
                              <Icon className="fal fa-plus" />
                              <span>Add a Field</span>
                            </AddButton>
                            <EditButton
                              onClick={() => setIsEditable(!isEditable)}
                            >
                              <Icon className="fas fa-pencil" />
                              <span>Sort Fields</span>
                            </EditButton>
                          </ButtonRow>
                          {/* <Field
                            name="servingSize"
                            component={RenderTextField}
                            disabled={true}
                          ></Field> */}
                          <RenderNutritionFacts
                            arrayHelpers={arrayHelpers}
                            nutritionFacts={values.nutritionFacts}
                            sortNutritionFacts={sortNutritionFacts}
                            isEditable={isEditable}
                          />
                          <StickyButtons
                            isVisible={dirty}
                            handleDiscard={() => handleReset()}
                            // saveIsDisabled={
                            //   !dirty ||
                            //   arrayHelpers.form.values.nutritionFacts[
                            //     arrayHelpers.form.values.nutritionFacts.length -
                            //       1
                            //   ].display_name === undefined
                            // }
                            saveIsDisabled={
                              !dirty || Object.keys(errors).length
                            }
                            handleSave={() => handleNutritionFactsSave(values)}
                            title="Nutritional Template"
                          />
                        </Fragment>
                      )}
                    />
                  </Fragment>
                )}
              </Formik>
            </div>
          )}
        </div>
        <div />
      </FactsList>
    </MainDiv>
  );
};

export default NutritionalInformationList;
