export const GET_IMAGES_REQUEST = 'GET_IMAGES_REQUEST';
export const GET_IMAGES_SUCCESS = 'GET_IMAGES_SUCCESS';
export const GET_IMAGES_FAILURE = 'GET_IMAGES_FAILURE';

export const SHOW_CREATE_IMAGE_FORM = 'SHOW_CREATE_IMAGE_FORM';
export const HIDE_CREATE_IMAGE_FORM = 'HIDE_CREATE_IMAGE_FORM';

export const SHOW_EDIT_IMAGE_FORM = 'SHOW_EDIT_IMAGE_FORM';
export const HIDE_EDIT_IMAGE_FORM = 'HIDE_EDIT_IMAGE_FORM';

export const CREATE_IMAGE_REQUEST = 'CREATE_IMAGE_REQUEST';
export const CREATE_IMAGE_SUCCESS = 'CREATE_IMAGE_SUCCESS';
export const CREATE_IMAGE_FAILURE = 'CREATE_IMAGE_FAILURE';

export const EDIT_IMAGE_REQUEST = 'EDIT_IMAGE_REQUEST';
export const EDIT_IMAGE_SUCCESS = 'EDIT_IMAGE_SUCCESS';
export const EDIT_IMAGE_FAILURE = 'EDIT_IMAGE_FAILURE';
export const DELETE_IMAGE_REQUEST = 'DELETE_IMAGE_REQUEST';
export const DELETE_IMAGE_SUCCESS = 'DELETE_IMAGE_SUCCESS';
export const DELETE_IMAGE_FAILURE = 'DELETE_IMAGE_FAILURE';
export const REINIT_IMAGES = 'REINIT_IMAGES';

export const RESET_MEDIA_REDUCER_STATE = "RESET_MEDIA_REDUCER_STATE";
export const UPDATE_MEDIA_REQUEST_PARAMS = "UPDATE_MEDIA_REQUEST_PARAMS";
export const UPDATE_SORTED_IMAGES_LIST = 'UPDATE_SORTED_IMAGES_LIST';
export const DISPLAY_CONFIRM_MODAL = 'DISPLAY_CONFIRM_MODAL';