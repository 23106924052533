import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { submitUser, toggleCreateUserModal } from '../../rolesActions';
import {
  setMenuManagementSelectedChildLocation,
  setMenuManagementSelectedParentLocation,
} from '../../../BusinessLocationDetails/LocationDetailsManager/actions';
import CreateEditUserModal from './CreateEditUserModal';
import CreateEditUserParentChildModal from './CreateEditUserParentChildModal';

const CreateEditUserModalContainer = ({
  roles,
  toggleCreateUserModal,
  submitUser,
  businesses,
  account,
  isModal,
  parentLocations,
}) => {
  const [isParentChild, setIsParentChild] = useState(false);
  useEffect(() => {
    let findChild = parentLocations.find(
      (parentBusiness) => parentBusiness.sub_businesses.length > 0
    );
    if (findChild) {
      setIsParentChild(true);
    }
  }, []);
  return (
    <div>
      {isParentChild ? (
        <CreateEditUserParentChildModal
          roles={roles}
          businesses={businesses}
          submitUser={submitUser}
          toggleCreateUserModal={toggleCreateUserModal}
          account={account}
          isModal={isModal}
          parentLocations={parentLocations}
        />
      ) : (
        <CreateEditUserModal
          roles={roles}
          businesses={businesses}
          submitUser={submitUser}
          toggleCreateUserModal={toggleCreateUserModal}
          account={account}
          isModal={isModal}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  account: state.rolesReducer.account,
  parentLocations: state.businessLocationsList.businessLocationsOfMerchant,
});

export default connect(mapStateToProps, {
  submitUser,
  setMenuManagementSelectedParentLocation,
  setMenuManagementSelectedChildLocation,
})(CreateEditUserModalContainer);
