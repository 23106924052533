import {
  GET_USERS_SUMMARY,
  GET_ACTIVE_USERS,
  GET_NEW_USER_SIGN_UPS,
  GET_REFERRAL_STATS,
  GET_ACTIVE_USER_AGE,
  GET_CUMULATIVE_USERS,
  LOADING,
  SET_PORTAL_TIER_UPGRADE_MODAL_IS_VISIBLE
} from './types';

const initialFormValues = {
  time_period: {
    interval_type: 'day',
    interval_value: 30
  }
};

export default function(
  state = {
    ...initialFormValues,
    portalTierUpgradeModalIsVisible: false
  },
  action
) {
  switch (action.type) {
    case GET_USERS_SUMMARY:
      return {
        ...state,
        summaryData: action.payload.data,
        loading: true
      };
    case GET_ACTIVE_USERS:
      return {
        ...state,
        activeUsersData: action.payload.data,
        innerLoading: true
      };
    case GET_NEW_USER_SIGN_UPS:
      return {...state, userSignUpsData: action.payload.data};
    case GET_REFERRAL_STATS:
      return {...state, referralStatsData: action.payload.data};
    case GET_ACTIVE_USER_AGE:
      return {...state, usersAgeData: action.payload.data};
    case GET_CUMULATIVE_USERS:
      return {...state, cumulativeUsersData: action.payload.data};
    case LOADING:
      return {...state, loading: false};
    // case INNER_LOADING:
    //   return { ...state, innerLoading:false}
    case SET_PORTAL_TIER_UPGRADE_MODAL_IS_VISIBLE:
      return {
        ...state,
        portalTierUpgradeModalIsVisible: action.isVisible
      }
    default:
      return {...state};
  }
  return state;
}
