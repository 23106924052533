import React, { useState } from "react";
import styled from "styled-components";
import { Icon } from "merchant-portal-components";
import { SmallToggleSwitch } from "../../../../../../components/ToggleSwitch/ToggleSwitch";
import LocationAvailibilityModal from "../containers/LocationAvailibilityModal";
import { Button } from "merchant-portal-components";
const Card = styled.div`
  background: ${(props) => (props.disabled ? "#ebebeb" : "white")};
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
  border-radius: 20px;
  padding: 32px 48px;
  color: ${(props) => (props.disabled ? "#959595" : "initial")};
  display: grid;
  grid-gap: 26px;
`;

const PreviewButton = styled.div`
  font-weight: 600;
  cursor: pointer;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  grid-gap: 8px;
  margin-bottom: 6px;
`;

const ToggleWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 28px;
  align-items: center;
`;

const ProductTogglesForm = ({
  is_featured,
  is_available,
  is_active,
  patchProductIsFeatured,
  patchProductIsAvailable,
  patchProductIsActive,
  disabled,
  putProductBusinesses,
  selectedProduct,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Card disabled={disabled}>
      <ToggleWrapper>
        <SmallToggleSwitch
          disabled={disabled}
          checked={is_available}
          onChange={(e) => patchProductIsAvailable(e.target.checked)}
        />
        <div>Product is Available</div>
      </ToggleWrapper>
      <ToggleWrapper>
        <SmallToggleSwitch
          disabled={disabled}
          checked={is_active}
          onChange={(e) => patchProductIsActive(e.target.checked)}
        />
        <div>Product is Active</div>
      </ToggleWrapper>
      <Button onClick={() => setIsOpen(true)}>
        Location-wise Product Availability
      </Button>
      {isOpen && (
        <LocationAvailibilityModal
          placeholder="Enter Internal Location Name"
          searchLabel="Search by Internal Location Name"
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          putProductBusinesses={putProductBusinesses}
          selectedProduct={selectedProduct}
        />
      )}
    </Card>
  );
};

export default ProductTogglesForm;
