import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from './actions';
import BatchDetail from './components/BatchDetail';
import BatchDetailVersion2 from './components/BatchDetailVersion2';
import exportCSVWrapper from '../../HOCs/exportCSVWrapper';
import BreadCrumb from '../../../components/BreadCrumb/BreadCrumb';
import NoDataFound from '../../../components/NoDataFound/NoDataFound';
import { LoadingBlueLarge } from 'merchant-portal-components';

class BatchDetailContainer extends Component {
  state = {
    requestParams: {
      batch_ids: [this.props.match.params.id],
      export: false,
    },
    detailsAreHidden: false,
  };

  componentDidMount() {
    track.page('finance-settlement-detail');
    this.props.fetchBatchDetail(this.state.requestParams);
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.token && this.props.token) {
      this.props.fetchBatchDetail(this.state.requestParams);
    }
  }

  toggleDetailsAreHidden = () => {
    this.setState({ detailsAreHidden: !this.state.detailsAreHidden });
  };

  render() {
    const { batchDetail, exportCSV, requestingCSV } = this.props;
    if (this.props.batchDetail.isFetchingBatchDetail) {
      return <LoadingBlueLarge />;
    }
    if (this.props.batchDetail.response) {
      return (
        <div>
          <BreadCrumb
            rootItem={{ pathname: '/settlements', name: 'Settlements' }}
            pageName="Batch Detail"
          />

          {this.props.batchDetail.response.summary_data.version !== 'v2' ? (
            <BatchDetail
              detailsAreHidden={this.state.detailsAreHidden}
              toggleDetailsAreHidden={this.toggleDetailsAreHidden}
              exportCSV={exportCSV}
              requestingCSV={requestingCSV}
              batchDetail={batchDetail.response}
              requestParams={this.state.requestParams}
            />
          ) : (
            <BatchDetailVersion2
              detailsAreHidden={this.state.detailsAreHidden}
              toggleDetailsAreHidden={this.toggleDetailsAreHidden}
              exportCSV={exportCSV}
              requestingCSV={requestingCSV}
              batchDetail={batchDetail.response}
              requestParams={this.state.requestParams}
            />
          )}
        </div>
      );
    } else {
      return (
        <NoDataFound
          rootItem={{ pathname: '/settlements', name: 'Settlements' }}
        />
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    batchDetail: state.batchDetail,
    batchDetailCSV: state.batchDetail.batchDetailCSV,
    token: state.accountReducer.token,
  };
}

BatchDetailContainer = exportCSVWrapper(BatchDetailContainer);

export default connect(mapStateToProps, actions)(BatchDetailContainer);
