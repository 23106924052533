import styled from 'styled-components';

export const NewCard = styled.div`
  display: grid;
  grid-gap: 16px;
  padding: 20px;
  margin: 5px 0;
  background: ${props => props.theme.white};
  border-radius: 2px;
  width: 100%;
  box-shadow: 2px 1px 3px rgba(0, 0, 0, 0.12), 1px 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
`;

export const CardRow = styled.div`
  background: ${props => (props.is_visible ? 'white' : 'lightgray')};
  border-radius: 2px;
  border: 1px solid lightgray;
  display: inline-block;
  height: 46px;
  line-height: 46px;
  margin: 5px;
  margin-left: 0;
  position: relative;
  width: 100%;
  padding: 0px 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  cursor: ${props => (props.sortDisabled ? 'not-allowed' : 'move')};

  &:hover {
    box-shadow: ${props =>
      !props.sortDisabled
        ? '0 3px 10px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)'
        : '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)'};
    transition: ${props =>
      !props.sortDisabled ? 'all 0.3s cubic-bezier(.25,.8,.25,1)' : 'none'};
  }
`;

export const FlexRow = styled.div`
  display: flex;
  justify-content: ${props =>
    props.justifyContent ? props.justifyContent : 'space-between'};
  align-items: ${props => (props.alignItems ? props.alignItems : 'center')};
  margin: ${props => (props.margin ? props.margin : 'initial')};
`;

export const DashedRow = styled.div`
  display: flex;
  align-items: center;
  border-radius: 2px;
  height: 46px;
  margin: 5px 0;
  border: dashed 1px #8a8e8c;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  padding-left: 20px;
`;

export const Icon = styled.i`
  cursor: pointer;
  margin: 0 10px;
  font-size: ${props => (props.fontSize ? props.fontSize : '20px')};
  color: #8a8e8c;
`;

export const AddCardWrapper = styled.li`
  background: ${props => props.theme.lightGray};
  padding: 16px;
  margin-bottom: 16px;
  list-style: none;
  height: 100%;
  display: grid;
  grid-gap: 16px;
`;
