import React from 'react';
import styled from 'styled-components';
import { Icon } from 'merchant-portal-components';
import Tooltip from '@material-ui/core/Tooltip';
import TransactionDetailsTable from './TransactionDetailsTable';
import BatchReportHeader from './BatchReportHeader';
import ExportCSVButton from './ExportCSVButton';
import { SmallToggleSwitch } from '../../../../components/ToggleSwitch/ToggleSwitch';
import { BatchDetailPage } from 'merchant-portal-components';

const ReportBox = styled.div`
  padding: 15px;
  background: #f0f0f0;
  border: 1px solid lightgray;
  margin-bottom: 8px;
  @media print {
    margin-bottom: 4px;
    font-size: 14px;
    padding: 5px 15px;
  }
`;

const Report = styled.div`
  background: ${(props) => props.theme.white};
  padding: 16px 16px 8px 16px;
  font-size: 14px;
`;

const Logo = styled.img`
  height: 50px;
  margin-bottom: 8px;
`;

const StyledP = styled.p`
  margin: 0;
  word-break: break-word;
  @media print {
    font-size: 14px;
    padding: ${(props) => props.printPadding || 'inherit'};
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : 'space-between'};
`;

const ReceiptRow = Row.extend`
  font-weight: 700;
  margin: 8px 0;
  color: ${(props) => (props.red ? props.theme.red : props.theme.black)};
  @media print {
    margin: 4px 0;
  }
`;

const SubReceiptRowHeader = ReceiptRow.extend`
  margin-bottom: 0;
`;

const SubReceiptRow = ReceiptRow.extend`
  padding-left: 16px;
  font-weight: 500;
  margin: 0;
`;

const TotalAmount = styled.div`
  border-top: 1px
    ${(props) => (props.red ? props.theme.red : props.theme.black)} solid;
`;

const ToolTipIcon = (props) => {
  const ToolTipIcon = styled(Icon)`
    font-size: ${(props) => props.size || '13px'};
    cursor: default;
    color: ${(props) => (props.red ? props.theme.red : props.theme.blue)};
  `;
  return <ToolTipIcon {...props} className="fal fa-info-circle" />;
};

// This will be removed and imported once Order History branch is merged
const toPriceFormat = (intPrice) => {
  return new Intl.NumberFormat('en-EN', {
    style: 'currency',
    currency: 'USD',
  }).format(Math.abs(intPrice));
};

const BatchDetail = ({
  batchDetail,
  requestParams,
  exportCSV,
  requestingCSV,
  detailsAreHidden,
  toggleDetailsAreHidden,
}) => {
  const batch = batchDetail.summary_data;
  const transactions = batchDetail.transaction_details;
  const businessPluralOrSingular =
    batch.businesses_included.length > 1 ? 'businesses' : 'business';
  const businessString =
    batch.businesses_included.length > 1
      ? 'see next page for list of businesses'
      : 'see next page for business';

  return (
    <div>
      <BatchDetailPage>
        <SmallToggleSwitch
          value={detailsAreHidden}
          label="Hide Details"
          onChange={() => toggleDetailsAreHidden()}
        />
        <ExportCSVButton
          exportCSV={exportCSV}
          requestParams={requestParams}
          requestingCSV={requestingCSV}
        />
        <Report>
          <BatchReportHeader
            detailsAreHidden={detailsAreHidden}
            title="In-App Sales Settlement Report"
            batchDate={batch.date}
            batchReportBusinessName={batch.report_business_name}
            batchSettlementId={batch.settlement_id}
            batchReportBusinessAddress={batch.report_business_address}
            batchAmount={batch.batch_amount}
            batchBusinessesIncluded={batch.businesses_included}
            businessPluralOrSingular={businessPluralOrSingular}
            businessString={businessString}
            batchTransactionPeriodBegin={batch.transaction_period_begin}
            batchTransactionPeriodEnd={batch.transaction_period_end}
          />
          <ReportBox>
            <ReceiptRow>
              <div>Subtotal</div>
              <div>{toPriceFormat(batch.subtotal)}</div>
            </ReceiptRow>
            <ReceiptRow>
              <div>Fees</div>
              <div>{toPriceFormat(batch.order_fees)}</div>
            </ReceiptRow>
            <ReceiptRow>
              <div>Gross Sales</div>
              <TotalAmount>{toPriceFormat(batch.gross_sales)}</TotalAmount>
            </ReceiptRow>
          </ReportBox>
          <ReportBox>
            <SubReceiptRowHeader>Discounts</SubReceiptRowHeader>
            <SubReceiptRow red>
              <div>Offers</div>
              <div>{toPriceFormat(batch.introductory_discount_spent)}</div>
            </SubReceiptRow>
            <SubReceiptRow red>
              <div>Loyalty</div>
              <div>{toPriceFormat(batch.loyalty_rewards)}</div>
            </SubReceiptRow>
            <SubReceiptRow red>
              <div>Head Office Credits</div>
              <div>{toPriceFormat(batch.whitelabel_credits_redeemed)}</div>
            </SubReceiptRow>
            <SubReceiptRow red>
              <div>Combo</div>
              <div>{toPriceFormat(batch.combo_discount)}</div>
            </SubReceiptRow>
            <SubReceiptRow red>
              <div>POS Discount</div>
              <div>{toPriceFormat(batch.pos_discount)}</div>
            </SubReceiptRow>
            <ReceiptRow red>
              <div>Total Discount</div>
              <TotalAmount red>
                {toPriceFormat(batch.total_credit_applied)}
              </TotalAmount>
            </ReceiptRow>
          </ReportBox>
          <ReportBox>
            <ReceiptRow>
              <div>Net Sales</div>
              <div>{toPriceFormat(batch.net_sales)}</div>
            </ReceiptRow>
            <ReceiptRow>
              <div>Tax</div>
              <div>{toPriceFormat(batch.tax)}</div>
            </ReceiptRow>
            <ReceiptRow>
              <div>Tips</div>
              <div>{toPriceFormat(batch.tips)}</div>
            </ReceiptRow>
            <ReceiptRow>
              <div>Total Amount of Customer Receipts</div>
              <TotalAmount>
                {toPriceFormat(batch.total_amount_receipts)}
              </TotalAmount>
            </ReceiptRow>
          </ReportBox>
          <ReportBox>
            <SubReceiptRowHeader>Refunds</SubReceiptRowHeader>
            <SubReceiptRow red>
              <div>In App $ Refund</div>
              <div>{toPriceFormat(batch.in_app_refund)}</div>
            </SubReceiptRow>
            <SubReceiptRow red>
              <div>Loyalty Refund</div>
              <div>{toPriceFormat(batch.loyalty_amount_refunded)}</div>
            </SubReceiptRow>
            <SubReceiptRow red>
              <div>Credit Card Refund</div>
              <div>{toPriceFormat(batch.gift_card_refund)}</div>
            </SubReceiptRow>
            <ReceiptRow red>
              <div>Total refunds</div>
              <TotalAmount red>
                {toPriceFormat(batch.total_amount_refund)}
              </TotalAmount>
            </ReceiptRow>
          </ReportBox>
          <ReportBox>
            <ReceiptRow>
              <div>Net to Account for</div>
              <div>{toPriceFormat(batch.net_amount)}</div>
            </ReceiptRow>
          </ReportBox>
          <ReportBox>
            <SubReceiptRowHeader>Payment Methods</SubReceiptRowHeader>

            <SubReceiptRow>
              <div>Gift Card</div>
              <div>{toPriceFormat(batch.gift_card)}</div>
            </SubReceiptRow>
            <SubReceiptRow>
              <Tooltip title="Payment Outside System">
                <div style={{ cursor: 'default' }}>
                  Loyalty Only {<ToolTipIcon />}
                </div>
              </Tooltip>
              <div>{toPriceFormat(batch.loyalty_purchase_amount)}</div>
            </SubReceiptRow>
            <SubReceiptRow>
              <div>In-App Payment</div>
              <div>{toPriceFormat(batch.paid_in_app)}</div>
            </SubReceiptRow>
            <ReceiptRow>
              <div>Total Payments</div>
              <TotalAmount>{toPriceFormat(batch.net_amount)}</TotalAmount>
            </ReceiptRow>
          </ReportBox>
          <ReportBox>
            <SubReceiptRowHeader>
              <Tooltip title="Non Cash">
                <div style={{ cursor: 'default' }}>
                  Discount Refunds {<ToolTipIcon />}
                </div>
              </Tooltip>
            </SubReceiptRowHeader>
            <SubReceiptRow red>
              <div>Offers</div>
              <div>{toPriceFormat(batch.introductory_discount_refund)}</div>
            </SubReceiptRow>
            <SubReceiptRow red>
              <div>Loyalty</div>
              <div>{toPriceFormat(batch.loyalty_rewards_refund)}</div>
            </SubReceiptRow>
            <SubReceiptRow red>
              <div>Head Office Credits</div>
              <div>{toPriceFormat(batch.whitelabel_credits_refund)}</div>
            </SubReceiptRow>
            <ReceiptRow red>
              <div>Total Credit Refund</div>
              <TotalAmount red>
                {toPriceFormat(batch.total_credit_refund)}
              </TotalAmount>
            </ReceiptRow>
          </ReportBox>
          <ReportBox>
            <SubReceiptRowHeader>
              <Tooltip title="Only for SmoothPay customers">
                <div style={{ cursor: 'default' }}>
                  SmoothPay Settlement Details {<ToolTipIcon />}
                </div>
              </Tooltip>
            </SubReceiptRowHeader>
            <SubReceiptRow red>
              <div>Processing</div>{' '}
              <div>{toPriceFormat(batch.fees_processing)}</div>
            </SubReceiptRow>
            <SubReceiptRow red>
              <div>First Time User</div>{' '}
              <div>{toPriceFormat(batch.fees_first_time)}</div>
            </SubReceiptRow>
            <SubReceiptRow red>
              <div>Loyalty</div> <div>{toPriceFormat(batch.fees_loyalty)}</div>
            </SubReceiptRow>
            <SubReceiptRow red>
              <div>Monthly</div> <div>{toPriceFormat(batch.fees_monthly)}</div>
            </SubReceiptRow>
            <ReceiptRow red>
              <Tooltip title="SmoothPay Fees">
                <div style={{ cursor: 'default' }}>
                  Total Fees {<ToolTipIcon red />}
                </div>
              </Tooltip>
              <TotalAmount red>{toPriceFormat(batch.total_fees)}</TotalAmount>
            </ReceiptRow>
            <ReceiptRow red>
              <div>HST</div> <div>{toPriceFormat(batch.hst)}</div>
            </ReceiptRow>
          </ReportBox>
          <ReportBox>
            <ReceiptRow>
              <div>Settlement Amount Owing</div>
              <div>{toPriceFormat(batch.batch_amount)}</div>
            </ReceiptRow>
          </ReportBox>

          {batch.settlement_id.length >= 94 ? (
            <StyledP printPadding="10px 0">
              Batch ID: {batch.settlement_id}
            </StyledP>
          ) : null}
        </Report>
      </BatchDetailPage>

      <TransactionDetailsTable
        transactions={transactions}
        batchDate={batch.date}
        batchSettlementId={batch.settlement_id}
        batchAmount={batch.batch_amount}
        businessesIncluded={batch.businesses_included}
        transactionPeriodBegin={batch.transaction_period_begin}
        transactionPeriodEnd={batch.transaction_period_end}
      />
    </div>
  );
};

export default BatchDetail;
