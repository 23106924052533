import styled from 'styled-components';
export const Wrapper = styled.div`
  margin: auto;
`;
export const NoAccessWrapper = styled.div`
  margin: 35px auto;
  background-color: white;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  border-radius: 25px;
`;

export const TitleWrapper = styled.div`
  margin: 40px 0 25px 0;
  display: grid;
  grid-template-columns: 330px 70px;
  grid-gap: 10px;
`;
export const PageTitle = styled.span`
  font-size: 32px;
  font-weight: 600;
`;
export const BetaBadge = styled.span`
  font-family: Montserrat;
  padding: 2px 8px;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 600;
  background-color: #e6ebff;
  color: #2440ae;
  text-align: center;
  cursor: default;
`;
export const SearchWrapper = styled.div`
  margin-bottom: 28px;
  display: grid;
  grid-template-columns: 1fr 250px;
  grid-gap: 32px;
`;
export const ListWrapper = styled.div`
  display: flex;
  min-height: 200px;
  justify-content: center;
  align-items: center;
`;
export const StatusWrapper = styled.div`
  padding: 5px 15px;
  border-radius: 25px;
  width: 100px;
  font-size: 17px;
  font-weight: 600;
  color: white;
  background-color: ${(props) => (props.isPaused ? '#c4260a' : '#6cc269')};
`;
export const ToastWrapper = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr;
  width: 600px;
  margin: 10px 0;
  padding: 5px 10px;
  background-color: red;
`;

export const HeaderWrapper = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    props.tab === 0
      ? '1.25fr 1.5fr 0.7fr 0.5fr 1.5fr 1.5fr 0.5fr 0.5fr'
      : '0.5fr 1.5fr 0.7fr 0.5fr 1.5fr 1.5fr 0.5fr 1.25fr'};
  grid-gap: 10px;
  border-bottom: 3px solid #ededed;
  padding: 10px 0;
`;
export const CellWrapper = styled.div`
  width: 100%;
  font-weight: ${(props) => (props.isBold ? '600' : 'normal')};
`;
export const AngeledTabList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;
export const AngeledTab = styled.li`
  display: inline;
  text-align: 'left';
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  padding: 12px 35px 12px 15px;
  color: ${(props) => (props.isSelected ? '#1e3697' : '#848484')};
  background-color: #fcfcfc;
  cursor: pointer;
  clip-path: polygon(0 0, 85% 0, 100% 100%, 0% 100%);
  box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.09);
`;
