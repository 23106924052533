import React from 'react';
import styled from 'styled-components';
import {FlexRow} from '../AppFeedElements';

const ListTitleAndAction = ({
  row,
  margin,
  hideAction,
  actionLabel,
  rowTitle
}) => {
  margin = margin ? margin : '0 0px 6px 0px';

  const ListAction = styled.p`
    font-size: 12px;
    margin: 0;
    text-transform: uppercase;
    color: ${props => props.theme.black};
  `;

  const ListTitle = ListAction.extend`
    text-transform: uppercase;
  `;

  return (
    <FlexRow margin={margin}>
      <ListTitle>{row ? row.title : rowTitle}</ListTitle>
      {!hideAction && (
        <ListAction>{row ? row.list_action_label : actionLabel}</ListAction>
      )}
    </FlexRow>
  );
};

export default ListTitleAndAction;
