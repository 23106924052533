import React from 'react';
import { Modal } from 'merchant-portal-components';
import { ButtonRow, Button } from 'merchant-portal-components';
import styled from 'styled-components';

const ModalBody = styled.div`
  width: 400px;
  background: white;
  padding: 16px;
  display: grid;
  grid-gap: 24px;
  border-radius: 2px;
  p {
    font-size: 18px;
  }
`;

const ConfirmCancelModal = ({ onCancel, onConfirm }) => {
  return (
    <Modal>
      <ModalBody>
        <p>All unsaved data will be lost. Are you sure you want to continue?</p>
        <ButtonRow justifyContent="space-between">
          <Button small type="button" onClick={onCancel}>
            Cancel
          </Button>
          <Button small type="button" onClick={onConfirm}>
            Yes
          </Button>
        </ButtonRow>
      </ModalBody>
    </Modal>
  );
};

export default ConfirmCancelModal;
