import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Field } from 'formik';
import Checkbox from '@material-ui/core/Checkbox';
import {
  RenderTextField,
  required,
  RenderSelect,
  pointsCostValidation,
} from '../../../../../components/FormElements/FormikElements';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 7.5%;
  align-items: center;
  margin-top: 10px;
`;

const Subtitle = styled.div`
  font-size: 18px;
  font-weight: 600;
`;

const CheckboxWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 50px;
  align-items: center;
`;

const FirstTimeOffer = ({ auto_redeem, setFieldValue, touched, errors }) => {
  const validateDiscountAmount = (value) => {
    let error;
    if (!value && value !== 0) {
      error = 'Required';
    } else if (parseFloat(value) > 100000000) {
      error = 'Entered amount is not acceptable.';
    } else if (parseFloat(value) < 0) {
      error = 'No less than Zero';
    } else if (parseFloat(value) === 0) {
      error = 'More than zero is required';
    }
    return error;
  };

  return (
    <div>
      <Subtitle>Offer Details</Subtitle>
      <Wrapper>
        <Field
          name="offer_details.template_values.points_cost"
          component={RenderTextField}
          type="number"
          label="*Points Cost"
          validate={pointsCostValidation}
        />
        <Field
          name="offer_details.template_values.total_amount"
          component={RenderTextField}
          type="number"
          label="*Total Amount"
          validate={validateDiscountAmount}
        />
        {!errors &&
          !touched &&
          errors.offer_details.template_values.total_amount &&
          touched.offer_details.template_values.total_amount && (
            <div>{errors.offer_details.template_values.total_amount}</div>
          )}
      </Wrapper>
    </div>
  );
};

export default FirstTimeOffer;
