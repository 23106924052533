export const IS_SIGNING_IN = 'IS_SIGNING_IN';
export const AUTH_USER = 'AUTH_USER';
export const UNAUTH_USER = 'UNAUTH_USER';
export const SESSION_EXPIRED_UNAUTH_USER = 'SESSION_EXPIRED_UNAUTH_USER';
export const AUTH_ERROR = 'AUTH_ERROR';
export const FETCH_MESSAGE = 'FETCH_MESSAGE';
export const BUSINESS_INFO = 'BUSINESS_INFO';
export const USER_ROLE = 'USER_ROLE';
export const RESET_PASSWORD_FORM = 'RESET_PASSWORD_FORM';
export const ERROR_MESSAGE = 'ERROR_MESSAGE';
export const PROMPT_REAUTH = 'PROMPT_REAUTH';
export const UPDATE_CREATED_AT_TIMER = 'UPDATE_CREATED_AT_TIMER';
export const SIGN_IN_FAILURE = 'SIGN_IN_FAILURE;';
export const PASSWORD_RESET_REQUEST_SENT = 'PASSWORD_RESET_REQUEST_SENT';