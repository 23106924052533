import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Card from '../../../components/Card/Card';
import { Field } from 'formik';
import { MiniToggleSwitch } from '../../../components/ToggleSwitchSmall/ToggleSwitchSmall';
import { TooltipIcon } from '../../../components/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import { RenderTextField } from '../../../components/FormElements/FormikElements';
import OfferImageForm from '../components/OfferEngineImageForm';
const FlexBoxContainer = styled.div`
  display: flex;
`;
const Wrapper = styled.div`
  display: grid;
  align-items: center;
`;
const Subtitle = styled.div`
  font-size: 21px;
  font-weight: 600;
  letter-spacing: 0.03px;
  color: #191818;
`;
const HalfWrapper = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-gap: 7.5%;

  .formSection {
    display: grid;
    grid-gap: 32px;
  }
`;
const NoteText = styled.div`
  font-size: 14px;
  color: #191818;
  margin-bottom: 25px;
`;

const SlideOne = ({
  values,
  setFieldValue,
  initialValues,
  dirty,
  errors,
  touched,
  handleBlur,
}) => {
  const [offerValue, setOfferValue] = useState();
  return (
    <Wrapper>
      <Subtitle>Basic Information</Subtitle>
      <Card>
        <NoteText>
          These offer details will be visible to customers on your app or
          website
        </NoteText>
        <HalfWrapper>
          <div className='formSection'>
            <Field
              name='offer_details.title'
              component={RenderTextField}
              onBlur={handleBlur}
              type='text'
              label='Enter offer name *'
              errors={errors.title && touched.title}
            />
            {errors.title && touched.title ? <div>{errors.title}</div> : null}
            <Field
              name='offer_details.subtitle'
              component={RenderTextField}
              onBlur={handleBlur}
              type='text'
              label='Enter subtitle *'
              errors={
                errors.offer_details?.subtitle &&
                touched.offer_details?.subtitle
              }
            />
            <Field
              name='offer_details.label'
              component={RenderTextField}
              type='text'
              label='Enter label *'
              errors={errors.label && touched.label}
            />
            {errors.label && touched.label ? <div>{errors.label}</div> : null}
            <Field
              name='offer_details.description'
              component={RenderTextField}
              type='text'
              rows={4}
              label='Enter offer description (Optional)'
            />
          </div>
          <div>
            <OfferImageForm
              imageUrl={values.offer_details.image_url}
              setFieldValue={setFieldValue}
              offerValue={offerValue}
            />
          </div>
        </HalfWrapper>
      </Card>
      <Subtitle>Offer Status</Subtitle>
      <Card>
        <FlexBoxContainer>
          <MiniToggleSwitch
            label='Active'
            checked={values.offer_details.is_active}
            onChange={(e) =>
              setFieldValue('offer_details.is_active', e.target.checked)
            }
          ></MiniToggleSwitch>
          <Tooltip
            style={{ marginRight: 'auto' }}
            title='Offers will be visible on your app or website when they are marked Active. You can disable the Active toggle to hide the Offer from your menu.'
          >
            <span>
              <TooltipIcon marginLeft />
            </span>
          </Tooltip>
        </FlexBoxContainer>
      </Card>
    </Wrapper>
  );
};
export default SlideOne;
