import React, { useEffect, Fragment, useState } from 'react';
import { connect } from 'react-redux';
import AppDetail from './components/AppDetail';
import BreadCrumb from '../../components/BreadCrumb/BreadCrumb';
import * as actions from './AppDetailActions';
import { LoadingBlueLarge } from 'merchant-portal-components';

const AppDetailContainer = ({
  appDetailReducer,
  updateAppDetailConfiguration,
  getAppDetails,
  getAppDetailConfigSchema,
  initiateGetAppDetails,
  requestingAppDetails,
}) => {
  const {
    appDetailSchema,
    appDetails,
    appDetailPatchSchema,
  } = appDetailReducer;

  useEffect(() => {
    getAppDetailConfigSchema();
    getAppDetails();
    initiateGetAppDetails();
  }, []);

  const handleSubmit = (values) => {
    updateAppDetailConfiguration(values, appDetailPatchSchema);
  };

  const paymentOptions = [
    { value: 'visa', label: 'visa' },
    {
      value: 'mastercard',
      label: 'mastercard',
    },
    {
      value: 'giftcard',
      label: 'giftcard',
    },
  ];

  return (
    <Fragment>
      <BreadCrumb />
      {requestingAppDetails && <LoadingBlueLarge />}
      {!requestingAppDetails && (
        <AppDetail
          appDetailSchema={appDetailSchema}
          appDetails={appDetails}
          paymentOptions={paymentOptions}
          handleSubmit={handleSubmit}
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  appDetailReducer: state.appDetailReducer,
});

export default connect(mapStateToProps, actions)(AppDetailContainer);
