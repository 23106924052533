import React from 'react';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import { RenderToggleSwitch } from '../../../../components/FormElements/FormikElements';
import { Field } from 'formik';

const CustomField = styled.div``;

const styles = (theme) => ({
  cssLabel: {
    color: '#2949cb',
  },

  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: `${theme.palette.primary.main} !important`,
    },
  },

  cssFocused: {},

  notchedOutline: {
    borderWidth: '1px',
    borderColor: '#2949cb !important',
  },
});

const ToogleFieldPOS = ({ name, onChange }) => {
  return (
    <CustomField>
      <Field
        name={name}
        component={RenderToggleSwitch}
        onChange={(e) => onChange(name, e.target.checked)}
      />
    </CustomField>
  );
};

export default withStyles(styles)(ToogleFieldPOS);
