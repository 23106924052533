import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ReactGA from 'react-ga';
import { EmptyStateMessage, TableWrapper } from 'merchant-portal-components';
import BreadCrumb from '../../../../../components/BreadCrumb/BreadCrumb';
import { LoadingBlueLarge } from 'merchant-portal-components';
import { Button, ButtonRow } from 'merchant-portal-components';
import MobileMessagesList from './components/MobileMessagesList';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Icon } from 'merchant-portal-components';
import { connect } from 'react-redux';
import * as actions from '../mobileMessagesActions';
import { sort } from '../../../../../utils/sort/sort';

const TopDiv = styled.div`
  display: grid;
  grid-template-columns: 4fr 250px;
  grid-gap: 20px;
  justify-content: space-between;
  margin: 32px 32px 18px 32px;
`;

const TextSearch = styled.div`
  // padding: 20px;
`;

const BackText = styled.div`
  display: grid;
  grid-gap: 5px;
  grid-template-columns: 7px 1fr;
  cursor: pointer;
  align-items: center;
  margin-top: 20px;
  margin-bottom: -30px;

  &:hover {
    color: #3051d4;
  }
`;

const Disclaimer = styled.div`
  margin: 0px 32px;
  font-size: 14px;
`;

const MobileMessagesListContainer = (props) => {
  const { mobileMessagesReducer } = props;
  const { response, requestParams } = mobileMessagesReducer;

  useEffect(() => {
    track.page('engagement-mobile-messages-list');
    ReactGA.pageview(
      window.location.pathname,
      undefined,
      'One Time Message List'
    );
  }, []);

  const [searchString, setSearchString] = useState('');

  const slicemessages = (messages) => {
    const indexOfFirst =
      (requestParams.page_number - 1) * requestParams.page_size;
    const indexOfLast = requestParams.page_number * requestParams.page_size;
    return messages
      .filter(
        (message) =>
          message.name.toLowerCase().indexOf(searchString.toLowerCase()) > -1
      )
      .slice(indexOfFirst, indexOfLast);
  };

  useEffect(() => {
    setMessagesActiveList(slicemessages(response.data));
  }, [requestParams, response.data]);

  const [messagesActiveList, setMessagesActiveList] = useState(
    slicemessages(response.data)
  );

  const handleMessagesSearch = (searchString) => {
    props.updateMessagesRequestParams({
      page_number: requestParams.page_number,
      page_size: requestParams.page_size,
    });
    setSearchString(searchString);
  };

  const handleSort = (sortArgs) => {
    let sortOrder = '';
    let order = '';
    const currentOptionAsArray = requestParams.sorting_option.split('-');
    if (currentOptionAsArray[0] === sortArgs.value) {
      order = currentOptionAsArray[1] === 'asc' ? 'desc' : 'asc';
      sortOrder = `${sortArgs.value}-${order}`;
    } else {
      order = 'asc';
      sortOrder = `${sortArgs.value}-${order}`;
    }
    if (messagesActiveList) {
      const result = sort(response.data, {
        ...sortArgs,
        sortOrder: `${order}`,
      });
      props.updateMessagesRequestParams({
        page_number: 1,
        page_size: requestParams.page_size,
      });
      props.updateSortedMessagesList(result, sortOrder);
    }
  };

  return (
    <div className="animated fadeIn">
      <BackText onClick={() => props.history.push('/mobile-messages')}>
        <i className="fa fa-chevron-left" />
        <div>Back To Mobile Messages</div>
      </BackText>
      <ButtonRow justifyContent="space-between" style={{ margin: 'auto' }}>
        <BreadCrumb pageName="One Time Messages" /*rootItem={rootItem}*/ />
      </ButtonRow>
      {response ? (
        <TableWrapper>
          <TopDiv>
            <TextSearch>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon className="fal fa-search" />
                    </InputAdornment>
                  ),
                }}
                style={{ width: '100%' }}
                variant="outlined"
                type="text"
                onChange={(e) => handleMessagesSearch(e.target.value)}
                placeholder="Search mobile messages"
              />
            </TextSearch>
            <Button
              style={{ fontSize: 'initial', width: '100%' }}
              onClick={() => props.initiateCreateMobileMessage()}
            >
              Create Mobile Message
            </Button>
          </TopDiv>
          <Disclaimer>
            Please note: Information on this page can take up to 24h to update.
          </Disclaimer>
          <MobileMessagesList
            {...props}
            requestParams={requestParams}
            messagesActiveList={messagesActiveList}
            handleSort={handleSort}
          />
        </TableWrapper>
      ) : (
        <LoadingBlueLarge />
      )}
      {response && response.data.length == 0 && (
        <EmptyStateMessage>
          There are no Mobile messages to display.
        </EmptyStateMessage>
      )}
    </div>
  );
};

export default connect(null, actions)(MobileMessagesListContainer);
