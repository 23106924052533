import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import * as actions from '../customerActions';
import CustomerLoyaltyPoints from '../components/CustomerLoyaltyPoints';
import { LoadingBlueLarge } from 'merchant-portal-components';
import SendRewardsModal from '../components/modals/SendRewardsModal';

/**
 * Class based component handling how components work
 */
class CustomerListContainer extends Component {
  state = { height: null };
  /**
   * Handle visibility of send rewards modal
   */
  toggleSendLoyaltyModal = () => {
    this.props.sendLoyaltyModalIsOpen(
      !this.props.customerReducer.sendLoyaltyModalIsOpen
    );
  };

  /**
   * Handle send rewards button click functionality
   */
  handleSendRewardsClick = (values) => {
    this.validateRewardValues(values)
      .then((validatedValues) => {
        this.props.postSendRewards(this.props.customerId, validatedValues);
      })
      .catch((error) => {
        this.props.errorAlert(error.message);
      });
  };

  /**
   * Return a Promise state with errors or values
   * @param {Object} values - form values to validate
   * @returns {Promise} - resolve: values. reject: error message
   */
  validateRewardValues = (values) => {
    let validatedValues = {};
    return new Promise((resolve, reject) => {
      if (!values) {
        reject({ message: 'Fields are empty' });
      } else if (!values.amountOfReward) {
        reject({ message: 'Amount must be price unit' });
      } else if (!values.rewardNotes) {
        reject({ message: 'You must put a note' });
      }
      validatedValues.amount = parseFloat(
        values.amountOfReward.replace('$', '')
      );
      if (validatedValues.amount < 0 || validatedValues.amount > 100000) {
        reject({ message: 'Amount must be between 1-100,000' });
      }
      validatedValues.comment =
        localStorage.getItem('displayName') + '\n' + values.rewardNotes;
      resolve(validatedValues);
    });
  };

  updateHeight = (element) => {
    if (
      element &&
      element.offsetHeight &&
      this.state.height != element.offsetHeight
    ) {
      this.setState({ height: element.offsetHeight + 'px' });
    }
  };

  render() {
    const {
      sendLoyaltyModalIsOpen,
      postSendLoyaltyIsRequesting,
      customerDetails,
      loyaltyPoints,
      getCustomerLoyaltyInformationIsRequesting,
      loyaltyPointsAccumulation,
      loyaltyTier,
    } = this.props.customerReducer;
        return (
      <Fragment>
        <CustomerLoyaltyPoints
          customerDetails={customerDetails}
          toggleSendLoyaltyModal={this.toggleSendLoyaltyModal}
          loyaltyPoints={loyaltyPoints}
          loyaltyPointsAccumulation={loyaltyPointsAccumulation}
          loyaltyTier={loyaltyTier}
          customerdelete={this.props.customerdeletedata}
        />
        {sendLoyaltyModalIsOpen ? (
          <SendRewardsModal
            typeOfReward='Points'
            handleSendRewardsClick={this.handleSendRewardsClick}
            toggleModalVisibility={this.toggleSendLoyaltyModal}
            customerName={customerDetails.customerName()}
            isRequesting={postSendLoyaltyIsRequesting}
            amountRange={(value) =>
              value > 0 && value <= 100000
                ? undefined
                : 'Amount must be between 1-100,000'
            }
            normalize={(value) => {
              if (/^-?[0-9]\d*\.?\d*$/.test(value) || value == '') {
                return value;
              }
            }}
          />
        ) : null}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  customerReducer: state.customerReducer,
});

export default connect(mapStateToProps, actions)(CustomerListContainer);
