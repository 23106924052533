import React, { Fragment } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Field } from 'formik';
import Checkbox from '@material-ui/core/Checkbox';
import {
  RenderTextField,
  required,
  RenderSelect,
} from '../../../../../components/FormElements/FormikElements';
import Conditions from './Conditions';

const Wrapper = styled.div`
  display: grid;
  grid-gap: 40px;
`;

const ProductWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 100px;
`;

const FieldWrapper = styled.div`
  display: grid;
  grid-gap: 40px;
  grid-template-rows: 120px auto;
`;

const Subtitle = styled.div`
  font-weight: 600;
`;

const OptionsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 100px;
`;

const OptionGridWrapper = styled.div`
  display: grid;
  grid-gap: 20px;
`;

const CheckboxWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 50px;
  align-items: center;
`;

const ConditionWrapper = styled.div`
  display: grid;
  grid-gap: 15px;
`;

const DiscountOffer = ({
  allProducts,
  allVariants,
  setFieldValue,
  auto_redeem,
  reusable,
  apply_multiple,
  conditions,
  templateName,
  touched,
  errors,
  discountType,
}) => {
  const validateDiscountAmount = (value) => {
    let error;
    if (discountType === 'percent') {
      if (!value && value !== 0) {
        error = 'Required';
      } else if (parseFloat(value) > 100) {
        error = 'No more than 100%';
      } else if (parseFloat(value) < 0) {
        error = 'No less than 0%';
      } else if (parseFloat(value) === 0) {
        error = 'More than zero is required';
      }
    } else {
      if (!value && value !== 0) {
        error = 'Required';
      } else if (parseFloat(value) > 100000000) {
        error = 'Entered amount is not acceptable.';
      } else if (parseFloat(value) < 0) {
        error = 'No less than Zero';
      } else if (parseFloat(value) === 0) {
        error = 'More than zero is required';
      }
    }
    return error;
  };
  return (
    <Wrapper>
      <Conditions
        templateName={templateName}
        componentName='BirthdayDiscountOffer'
        allProducts={allProducts}
        allVariants={allVariants}
        conditions={conditions}
        setFieldValue={setFieldValue}
      />
      <Subtitle>Free Item Options</Subtitle>
      <OptionsWrapper>
        <OptionGridWrapper>
          <Field
            name='offer_details.template_values.discount_type'
            component={RenderSelect}
            options={[
              { value: 'percent', label: 'Percent' },
              { value: 'dollars', label: 'Dollars' },
            ]}
          />
          <Field
            name='offer_details.template_values.discount'
            component={RenderTextField}
            type='number'
            label='*Discount Amount'
            validate={validateDiscountAmount}
            onKeyDown={(evt) =>
              (evt.key === 'e' || evt.key === '-' || evt.key === '+') &&
              evt.preventDefault()
            }
          />
          {!errors &&
            !touched &&
            errors.offer_details.template_values.discount &&
            touched.offer_details.template_values.discount && (
              <div>{errors.offer_details.template_values.discount}</div>
            )}
          <Field
            name='offer_details.template_values.days_available'
            component={RenderTextField}
            type='number'
            label='*Days Available'
            validate={required}
            onKeyDown={(evt) =>
              (evt.key === 'e' || evt.key === '-' || evt.key === '+') &&
              evt.preventDefault()
            }
          />
        </OptionGridWrapper>
        <OptionGridWrapper>
          <CheckboxWrapper>
            <Checkbox
              onChange={(e) =>
                setFieldValue('offer_details.auto_redeem', e.target.checked)
              }
              checked={auto_redeem}
              inputProps={{
                'aria-label': 'secondary checkbox',
              }}
            />
            <div>Auto Redeem</div>
          </CheckboxWrapper>
          <CheckboxWrapper>
            <Checkbox
              onChange={(e) =>
                setFieldValue('offer_details.reusable', e.target.checked)
              }
              checked={reusable}
              inputProps={{
                'aria-label': 'secondary checkbox',
              }}
            />
            <div>Reusable</div>
          </CheckboxWrapper>
        </OptionGridWrapper>
      </OptionsWrapper>
    </Wrapper>
  );
};

const mapStateToProps = (state) => ({
  allProducts: state.productsReducer.allProducts,
  allVariants: state.variantsReducer.allVariants,
});

export default connect(mapStateToProps)(DiscountOffer);
