import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import { Formik, FieldArray } from 'formik';
import TextFieldPOS from '../../components/TextFieldPOS';
import ToogleFieldPOS from '../../components/ToogleFieldPOS';
import { Select } from 'merchant-portal-components';
import ConfirmUnsavedChangesModal from '../../components/ConfirmUnsavedChangesModal';
import StickyButtons from '../../../../../components/StickyButtons';
import Pagination from '../../../../../components/Pagination/Pagination';
import PaginationControl from '../../../../../components/Pagination/PaginationControl';
import { Icon } from 'merchant-portal-components';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tooltip from '@material-ui/core/Tooltip';

const MainDiv = styled.div``;

const ProductsDiv = styled.div``;

const POSDiv = styled.div`
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
`;

export const SelectDiv = styled.div`
  margin-top: 16px;
  margin-bottom: 50px;
`;

const ProductsHeader = styled.div`
  font-size: 15px;
  font-weight: 600;
  color: #191818;
  height: 25px;
  display: grid;
  grid-template-columns: 1fr 1fr;
`;
const ItemNameDiv = styled.div`
  float: left;
  height:80.35px ;
  height:${props=> props.screenWidth > 1280 ? '80px': '80.35px'};
  padding-bottom: 15px;
  padding-right: 15px;
`;

const VariantNameDiv = styled.div`
`;

const POSHeader = styled.div`
  font-size: 15px;
  font-weight: 600;
  color: #2440ae;
  height: 25px;
`;

const Body = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
`;

const POSFieldWrapper = styled.div`
  display: grid;
  grid-template-columns: ${(props) => `repeat(${props.fieldsNumber}, auto)`};
`;

const TextSearch = styled.div``;

const VariantCell = styled.div`
  // height: 80px;
  margin-top: 15px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const POSValueCell = styled.div`
  height: ${props=> props.screenWidth > 1280 ? '81.35px': '81px'};
  margin-top: 15px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  padding: 0 10px 15px 0;
`;

const POSValueCellPagSzGrtFv = styled.div`
  height: ${props=> props.screenWidth > 1280 ? '81.35px': '81.02px'};
  margin-top: 15px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  padding: 0 10px 15px 0;
`;

const LineDivider = styled.hr`
  margin-top: 15px;
  border-color: rgba(0, 0, 0, 0.1);
  grid-column: span ${(props) => `${props.fieldsNumber}`};
`;

const ToogleFieldPOSWrapper = styled.div`
  margin-top: 10px;
  height: 100%;
`;

const PaginationWrapper = styled.div`
  width: 100%;
  padding-top: 15px;
  padding-bottom: 33px;
`;

const ItemsMappingTable = ({
  itemsMappingFields,
  items,
  searchValue,
  meta,
  allItemsLength,
  requestParams,
  submitPOSValuesItems,
  handleItemsSearch,
  selectedOption,
  optionPrices,
  variants,
  initialRequestParams,
}) => {
  const [paginSize,setPaginSize]=useState(5);
  const posFieldNums = itemsMappingFields
    ? Object.keys(itemsMappingFields).length
    : 0;

  // const [isConfirmModal, setIsConfirmModal] = useState(false);

  const initialValues = {
    items: items.map((item, index) => ({
      ...item,
      pos_mappings: item.pos_mappings ? item.pos_mappings : '',
      index,
    })),
  };

  return (
    <MainDiv>
      <Formik initialValues={initialValues} enableReinitialize={true}>
        {({ values, handleReset, dirty, errors, setFieldValue }) => (
          <Fragment>
            <SelectDiv>
              <TextSearch>
                <Tooltip
                  title={
                    'Please save the changes before searching for a new value!'
                  }
                  disableHoverListener={!dirty}
                  disableTouchListener={!dirty}
                  disableFocusListener={!dirty}
                  placement="top"
                >
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Icon className="fal fa-search" />
                        </InputAdornment>
                      ),
                    }}
                    style={{ width: '100%' }}
                    variant="outlined"
                    type="text"
                    onChange={(e) => {
                      handleItemsSearch(e.target.value);
                    }}
                    placeholder="Search items"
                    value={searchValue}
                    disabled={dirty}
                  />
                </Tooltip>
              </TextSearch>
            </SelectDiv>

            <Body>
              <ProductsDiv>
                <ProductsHeader>
                  <div>Item Name</div>
                  <div>Variant Name1</div>
                </ProductsHeader>
                <LineDivider />

                {items.map((item) => {
                  const itemNameModified =
                    item.name?.charAt(0) +
                    item.name?.slice(1, item.name.length).toLowerCase();
                  return (
                    <VariantNameDiv key={item.id}>
                      {!item.prices.length ? (
                        <VariantCell key={`v_${item.id}`}>
                          {
                            <ItemNameDiv screenWidth={window?.screen?.width} key={item.id}>
                              {itemNameModified}
                            </ItemNameDiv>
                          }
                        </VariantCell>
                      ) : (
                        item.prices.map(
                          (variant, index) => {
                          const variantNameModified =
                            variant.name?.charAt(0) +
                            variant.name
                              ?.slice(1, variant.name?.length)
                              .toLowerCase();
                          return (
                            <VariantCell key={`${item.id}_${index}`}>
                              {index > 0 ? (
                                <ItemNameDiv />
                              ) : (
                                <ItemNameDiv key={item.id}>
                                  {itemNameModified}
                                </ItemNameDiv>
                              )}
                              <div
                                style={{
                                  paddingBottom: '15px',
                                }}
                              >
                                {variantNameModified}
                              </div>
                            </VariantCell>
                          );
                        }
                        
                        )
                      )
                      
                      }
                    </VariantNameDiv>
                  );
                })}
              </ProductsDiv>
              <POSDiv>
                <POSFieldWrapper fieldsNumber={posFieldNums}>
                  {Object.values(itemsMappingFields).map((item) => {
                    return (
                      <POSHeader key={`c_${item['key']}`}>
                        {item['label']}
                        {!item.optional && '*'}
                      </POSHeader>
                    );
                  })}
                  <LineDivider fieldsNumber={posFieldNums} />
                  <FieldArray
                    name="items"
                    render={(arrayHelpers) => {
                      return items.map((item, itemIndex) => {
                        return !item.prices.length ? (
                          
                            paginSize > 5?
                            ( 
                              <POSValueCellPagSzGrtFv
                            paginationSize={paginSize}
                            screenWidth={window?.screen?.width}
                            key={itemIndex}
                            style={{
                              gridColumnStart: '1',
                              gridColumnEnd: '4',
                              textAlign: 'center',
                            }}
                          >
                            <p
                              style={{
                                fontStyle: 'italic',
                                fontSize: '14px',
                                color: 'dimgray',
                                verticalAlign: 'middle',
                              }}
                            >
                              No variants assigned to the item/sub-item
                            </p>
                          </POSValueCellPagSzGrtFv>

                            )
                            :
                            (
                              <POSValueCell
                            paginationSize={paginSize}
                            screenWidth={window?.screen?.width}
                            key={itemIndex}
                            style={{
                              gridColumnStart: '1',
                              gridColumnEnd: '4',
                              textAlign: 'center',
                            }}
                          >
                            <p
                              style={{
                                fontStyle: 'italic',
                                fontSize: '14px',
                                color: 'dimgray',
                                verticalAlign: 'middle',
                              }}
                            >
                              No variants assigned to the item/sub-item
                            </p>
                          </POSValueCell>

                            )
                          
                          
                          
                        ) : (
                          item.prices.map((variant, variantIndex) => {
                            return (
                              <Fragment
                                key={`v_${variant.variant_id}_${itemIndex}`}
                              >
                                {Object.keys(itemsMappingFields).map((key) => {
                                  const maxLength = key.includes('product_name')
                                    ? 50
                                    : 10;
                                  return (
                                    paginSize > 5?
                                    <POSValueCellPagSzGrtFv
                                      paginationSize={paginSize}
                                      screenWidth={window?.screen?.width}
                                      key={`p_${variant.variant_id}_${key}`}
                                    >
                                      {itemsMappingFields[key]['type'] ==
                                      'boolean' ? (
                                        <ToogleFieldPOSWrapper>
                                          <ToogleFieldPOS
                                            name={`items[${itemIndex}].pos_mappings[${variant.variant_id}][${key}]`}
                                            onChange={
                                              arrayHelpers.form.setFieldValue
                                            }
                                          />
                                        </ToogleFieldPOSWrapper>
                                      ) : (
                                        <div>
                                          <TextFieldPOS
                                            maxLength={maxLength}
                                            styles={{
                                              minWidth: '100px',
                                              minHeight:'80px'
                                            }}
                                            name={`items[${itemIndex}].pos_mappings[${variant.variant_id}][${key}]`}
                                            value={
                                              item?.pos_mappings[
                                                variant.variant_id
                                              ]
                                                ? item?.pos_mappings[
                                                    variant.variant_id
                                                  ][key]
                                                : ''
                                            }
                                            setFieldValue={
                                              arrayHelpers.form.setFieldValue
                                            }
                                          />
                                        </div>
                                      )}
                                    </POSValueCellPagSzGrtFv>:
                                    <POSValueCell
                                    paginationSize={paginSize}
                                    screenWidth={window?.screen?.width}
                                    key={`p_${variant.variant_id}_${key}`}
                                  >
                                    {itemsMappingFields[key]['type'] ==
                                    'boolean' ? (
                                      <ToogleFieldPOSWrapper>
                                        <ToogleFieldPOS
                                          name={`items[${itemIndex}].pos_mappings[${variant.variant_id}][${key}]`}
                                          onChange={
                                            arrayHelpers.form.setFieldValue
                                          }
                                        />
                                      </ToogleFieldPOSWrapper>
                                    ) : (
                                      <div>
                                        <TextFieldPOS
                                          maxLength={maxLength}
                                          styles={{
                                            minWidth: '100px',
                                            minHeight:'80px'
                                          }}
                                          name={`items[${itemIndex}].pos_mappings[${variant.variant_id}][${key}]`}
                                          value={
                                            item?.pos_mappings[
                                              variant.variant_id
                                            ]
                                              ? item?.pos_mappings[
                                                  variant.variant_id
                                                ][key]
                                              : ''
                                          }
                                          setFieldValue={
                                            arrayHelpers.form.setFieldValue
                                          }
                                        />
                                      </div>
                                    )}
                                  </POSValueCell>
                                  );
                                })}

                                <StickyButtons
                                  isVisible={dirty}
                                  handleDiscard={() => handleReset()}
                                  handleSave={() =>
                                    submitPOSValuesItems(
                                      values,
                                      itemsMappingFields,
                                      selectedOption,
                                      variants,
                                      optionPrices
                                    )
                                  }
                                  title="Items/Sub-items POS Mapping"
                                />
                              </Fragment>
                            );
                          })
                        );
                      });
                    }}
                  />
                </POSFieldWrapper>
              </POSDiv>
            </Body>
            <PaginationWrapper>
              <PaginationControl
                response={{
                  ...items,
                  page: requestParams.page_number,
                  page_size: requestParams.page_size,
                  count: allItemsLength,
                }}
                requestParams={requestParams}
                sendTheData={(newParams)=>{
                  setPaginSize(newParams?.page_size)
                }}
                isResultsPerPageDisabled={false}
                dirty={dirty}
                type="items"
              />
            </PaginationWrapper>
          </Fragment>
        )}
      </Formik>

      {/* {isConfirmModal && (
        <ConfirmUnsavedChangesModal
          toggleConfirmModal={() => setIsConfirmModal(false)}
          handleSave={() => {
            setIsConfirmModal(false);
          }}
        />
      )} */}
    </MainDiv>
  );
};

export default ItemsMappingTable;
