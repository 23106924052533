import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0;
  width: 100%;
  overflow-x: auto;
`;
export const HeaderWrapper = styled.div`
  display: grid;
  grid-template-columns: 3fr 148px;
  width: 100%;
`;
export const ExportButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;
export const ReportWrapper = styled.div`
  background-color: #ffffff;
  padding: 25px 32px;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  width: 100%;
  min-width: 1200px;
`;
export const MessageWrapper = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: 0.25px;
  height: 200px;
  line-height: 200px;
`;
