import { UPDATE_PASSWORD_REQUEST, UPDATE_PASSWORD_RESPONSE } from './types';
import api from '../../api';
import { createAlert } from '../Alert/actions';

export function updatePassword(formValues) {
  const params = {
    old_password: formValues.currentPassword,
    new_password: formValues.newPassword,
  };
  return function (dispatch) {
    dispatch({ type: UPDATE_PASSWORD_REQUEST });
    api.patch('/profile', params).then(
      (response) => {
        dispatch(
          createAlert({
            type: 'success',
            message: 'Password successfully updated.',
          })
        );
        dispatch({
          type: UPDATE_PASSWORD_RESPONSE,
          success: true,
          error: false,
        });
      },
      (error) => {
        dispatch(
          createAlert({
            type: 'error',
            message: error.response.data.errors.message,
          })
        );
        dispatch({
          type: UPDATE_PASSWORD_RESPONSE,
          success: false,
          error: error.response.data.errors.message,
        });
      }
    );
  };
}

export function getAuthUserBusinesses(authUser, accounts) {
  return function (dispatch) {
    const account = accounts.filter(
      (account) => account.username === authUser.username
    );
    return api.get(`/admins/${account[0].id}`).then((response) => {
      dispatch({
        type: 'GET_AUTH_USER_BUSINESS_ACCOUNTS_SUCCESS',
        payload: response.data.data,
      });
    });
  };
}

export function onAuthUserSubmit(authUser, values) {
  return function (dispatch) {
    const body = {
      ...authUser,
      display_name: values.userDisplayName,
    };
    delete body.id;
    delete body.is_password_set;
    delete body.business_ids;
    // delete updatedAccount.modifiedRole;

    return Promise.all([
      api.patch(`/admins/${authUser.id}`, body),
      api.patch(`/admins/${authUser.id}/business`, {
        business_ids: values.userBusinesses,
      }),
    ])
      .then((response) => {
        dispatch({
          type: 'SUBMIT_AUTH_USER',
          payload: response[0].data.data,
          // {
          //   account: response[0].data.data
          //   // isUserEditedSuccessfully: true
          // }
        }),
          dispatch(
            createAlert({
              type: 'success',
              message: 'User was updated successfully',
            })
          );
      })
      .catch((err) => {
        // dispatch({
        //   type: PATCH_EDIT_USER,
        //   payload: { isUserEditedSuccessfully: false }
        // }),
        dispatch(
          createAlert({
            type: 'error',
            message: err?.response?.data?.errors?.message || 'An error occurred trying to update the user',
          })
        );
      });
  };
}
