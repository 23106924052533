import React, { Component } from 'react';
import * as actions from './actions';
import * as selectors from './selectors';
import { connect } from 'react-redux';
import { getOffersEngine } from '../../OfferEngine/OfferEngineActions';
import { newOfferEngineClients } from '../../../utils/clientCustomizations';
function getOffers(WrappedComponent) {
  class Container extends Component {
    state = {
      newOfferClients: newOfferEngineClients,
    };
    componentDidMount() {
      let appIdentifier = localStorage.getItem('appIdentifier');
      if (this.state.newOfferClients.includes(appIdentifier)) {
        this.props.getOffersEngine({
          page_number: 1,
          page_size: '1000',
          sorting_option: 'title-asc',
          search_string: '',
          has_next_page: true,
        });
        return;
      }
      if (!this.props.allOffers) {
        this.props.getOffers();
      }
    }

    componentDidUpdate(prevProps) {
      if (!prevProps.token && this.props.token) {
        let appIdentifier = localStorage.getItem('appIdentifier');
        if (this.state.newOfferClients.includes(appIdentifier)) {
          this.props.getOffersEngine({
            page_number: 1,
            page_size: '1000',
            sorting_option: 'title-asc',
            search_string: '',
            has_next_page: true,
          });
          return;
        }
        if (!this.props.allOffers) {
          this.props.getOffers();
        }
      }
    }

    render() {
      return (
        <WrappedComponent
          requestingOffers={this.props.requestingOffers}
          allOffers={this.props.allOffers}
          {...this.props}
        />
      );
    }
  }

  function mapStateToProps(state) {
    const newOfferClients = newOfferEngineClients;
    let appIdentifier = localStorage.getItem('appIdentifier');
    return {
      allOffers: newOfferClients.includes(appIdentifier)
        ? state.OfferEngineReducer.allOffersEngine
        : selectors.getAllOffers(state),
      requestingOffers: state.offers.requestingOffers,
      token: state.accountReducer.token,
    };
  }

  return connect(mapStateToProps, { ...actions, getOffersEngine })(Container);
}

export default getOffers;
