import moment from 'moment';

export const formatCustomerLoyaltyPointsAccumulation = customerLoyaltyPointsAccumulation =>
  customerLoyaltyPointsAccumulation
    .map(point => ({
      ...point,
      created_at: moment(point.created_at, 'YYYY-MM-DD HH:mm:ss').format(
        'MMMM Do YYYY - h:mm:ss a'
      )
    }))
    .sort((a, b) => {
      a = moment(a.created_at, 'MMM Do YYYY - h:mm:ss a');
      b = moment(b.created_at, 'MMM Do YYYY - h:mm:ss a');
      if (a.isBefore(b)) {
        return 1;
      } else if (b.isBefore(a)) {
        return -1;
      } else {
        return 0;
      }
    });

export default formatCustomerLoyaltyPointsAccumulation;
