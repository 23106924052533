import {
  GET_CUSTOMER_REVIEWS_REQUEST,
  GET_CUSTOMER_REVIEWS_SUCCESS,
  GET_CUSTOMER_REVIEWS_FAILURE
} from './feedbackTypes';

export default function(
  state = {
    meta: [],
    getCustomerReviewsIsRequesting: false,
    reviews: []
  },
  action
) {
  switch (action.type) {
    case GET_CUSTOMER_REVIEWS_REQUEST:
      return {
        ...state,
        getCustomerReviewsIsRequesting: true
      };
    case GET_CUSTOMER_REVIEWS_SUCCESS:
      return {
        ...state,
        getCustomerReviewsIsRequesting: false,
        reviews: action.reviews,
        meta: action.meta
      };
    case GET_CUSTOMER_REVIEWS_FAILURE:
      return {
        ...state,
        getCustomerReviewsIsRequesting: false
      };

    default:
      return state;
  }
}
