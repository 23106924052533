import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import * as actions from './customerActions';
import CustomerDetails from './components/CustomerDetails';
import BreadCrumb from '../../../components/BreadCrumb/BreadCrumb';
import { LoadingBlueLarge } from 'merchant-portal-components';
import { ChevronLeftIcon } from 'merchant-portal-components';
import {
  updateOrderId,
  updateCurrentPage,
} from '../../OrderManager/OrderHistoryList/actions';
const BreadCrumbWrapper = styled.div`
  color: ${(props) => props.theme.darkBlue};
  margin: ${(props) => (props.style ? props.style.margin : '15px 0 0 0 ')};
  margin-left: 0;
  @media print {
    display: none;
  }
`;
const BackButton = styled.div`
  width: 176px;
  cursor: pointer;
  display: grid;
  grid-template-columns: 4px 300px;
  grid-gap: 10px;
  align-items: center;
  margin: 0;

  &:hover {
    color: #3051d4;
  }
`;

const RootLink = styled.span`
  margin: 0px;
  padding: 0px;
  color: black;
  font-size: 16px;

  &:hover {
    color: #3051d4;
  }
  /* padding-right: 4px;
  
  color: ${(props) => props.theme.blue}; */
`;

const RootItemWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const isRapidDeploymentClient = (appIdentifier) =>
  [
    'dennys',
    'tropical_treats',
    'butchies',
    'opus_restaurant',
    'moxies',
    'shark',
    // 'rock_creek',
    'chop',
  ].includes(appIdentifier);

/**
 * Class based component handling how components work
 */
class CustomerDetailsContainer extends Component {
  componentDidMount() {
    this.props.getCustomerDetails(this.props.customerId);
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.customerReducer.customerDetails?.customer_id !==
      this.props.customerReducer.customerDetails?.customer_id
    ) {
      this.props.getCustomerOrderHistory(
        this.props.customerReducer.requestParams
      );
    }
  }

  handleBackButtonClick = () => {
    this.props.history.goBack();
  };

  componentWillUnmount() {
    this.props.resetCustomerDetailsReducerState();
  }

  render() {
    const { customerReducer, accountReducer } = this.props;
    const {
      getCustomerGlobalCreditsIsRequesting,
      getCustomerLoyaltyInformationIsRequesting,
      getCustomerDetailsIsRequesting,
      customerDetails,
      redirectPage,
      customerOrders,
      requestParams,
    } = customerReducer;

    const { appMetaData, appIdentifier } = accountReducer;

    const componentsAreRequesting =
      getCustomerDetailsIsRequesting &&
      getCustomerLoyaltyInformationIsRequesting &&
      getCustomerGlobalCreditsIsRequesting;

    const pageName = 'Customer Details';
    const handleRedirect = () => {
      if (redirectPage === 'customer-feedback') {
        this.props.history.push('/customer-feedback');
      }
      this.props.setCurrentPage('list');
      this.props.handleRedirectFromCustomerDetail('');
    };
    return (
      <Fragment>
        {this.props.currentPage === 'detail' && (
          <BreadCrumbWrapper>
            <RootItemWrapper onClick={handleRedirect}>
              <BackButton>
                <ChevronLeftIcon />
                <RootLink>Customers</RootLink>
              </BackButton>
            </RootItemWrapper>
          </BreadCrumbWrapper>
        )}
        <BreadCrumb pageName={pageName} style={{ margin: '0 0 15px 0' }} />
        {componentsAreRequesting ? (
          <LoadingBlueLarge />
        ) : (
          <CustomerDetails
            customerId={this.props.customerId}
            handleBackButtonClick={this.handleBackButtonClick}
            loyaltyIsVisible={
              appMetaData.configuration.program_type == 'points' &&
              !isRapidDeploymentClient(appIdentifier)
            }
            lockMessage={customerDetails}
            customerOrders={customerOrders}
            requestParams={requestParams}
            getCustomerOrders={this.props.getCustomerOrderHistory}
            history={this.props.history}
            updateOrderId={this.props.updateOrderId}
            updateCurrentPage={this.props.updateCurrentPage}
          />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  customerReducer: state.customerReducer,
  accountReducer: state.accountReducer,
});

export default connect(mapStateToProps, {
  ...actions,
  updateOrderId,
  updateCurrentPage,
})(CustomerDetailsContainer);
