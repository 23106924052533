import styled from 'styled-components';

export const Body = styled.p`
  font-size: 16px;
  font-weight: ${props => props.fontWeight || 'normal'};
  text-align: ${props => props.textAlign || 'inherit'};
  color: ${props => props.color || '#1a191a'};
  text-transform: ${props => props.textTransform || 'unset'};
  margin: 0;
`;

export const Title1 = styled.h1`
  font-size: ${props => '28px'};
  color: ${props => '#000'};
  font-weight: ${props => '600'};
  text-transform: ${props => props.textTransform || 'unset'};
  text-align: ${props => props.textAlign || 'inherit'};
  margin: 0;
`;

export const Subhead = styled.p`
  font-size: 15px;
  color: ${props => '#191818'};
  font-weight: ${props => '600'};
  text-transform: ${props => props.textTransform || 'unset'};
  text-align: ${props => props.textAlign || 'inherit'};
  margin: 0;
`;

export default Subhead;
