import { getDayOfWeek } from "../../../../../utils/actionsFormatters";
const getCandidateCopyHours = (
  day,
  openTime1,
  closeTime1,
  openTime2,
  closeTime2
) => {
  let candidateItem = null;
  const newOpenTime1 = openTime1?.set("second", 0).format("HH:mm.ss");
  const newCloseTime1 = closeTime1?.set("second", 0).format("HH:mm.ss");
  const newOpenTime2 = openTime2?.set("second", 0).format("HH:mm.ss");
  const newCloseTime2 = closeTime2?.set("second", 0).format("HH:mm.ss");
  const newValue1 = newOpenTime1 ? `${newOpenTime1} - ${newCloseTime1}` : "";
  const newValue2 = newOpenTime2 ? `${newOpenTime2} - ${newCloseTime2}` : "";
  if (
    newOpenTime1 &&
    newOpenTime1 !== "Invalid date" &&
    newCloseTime1 &&
    newCloseTime1 !== "Invalid date" &&
    (!newOpenTime2 || newOpenTime2 === "Invalid date") &&
    (!newCloseTime2 || newCloseTime2 === "Invalid date")
  ) {
    candidateItem = {
      dayId: day,
      dayName: getDayOfWeek(day),
      value: `${newValue1}`,
    };
  } else if (
    newOpenTime1 &&
    newOpenTime1 !== "Invalid date" &&
    newCloseTime1 &&
    newCloseTime1 !== "Invalid date" &&
    newOpenTime2 &&
    newOpenTime2 !== "Invalid date" &&
    newCloseTime2 &&
    newCloseTime2 !== "Invalid date"
  ) {
    candidateItem = {
      dayId: day,
      dayName: getDayOfWeek(day),
      value: `${newValue1} / ${newValue2}`,
    };
  }
  return candidateItem;
};
export default getCandidateCopyHours;
