import React, {Component} from 'react';
import * as actions from './actions';
import * as selectors from './selectors';
import {connect} from 'react-redux';

function getCombos(WrappedComponent) {
  class Container extends Component {
    componentDidMount() {
      if (!this.props.allCombos) {
        this.props.getCombos();
      }
    }

    componentDidUpdate(prevProps) {
      if (!prevProps.token && this.props.token) {
        this.props.getCombos();
      }
    }

    render() {
      return (
        <WrappedComponent
          requestingCombos={this.props.requestingCombos}
          allCombos={this.props.allCombos}
          {...this.props}
        />
      );
    }
  }

  function mapStateToProps(state) {
    return {
      allCombos: selectors.getAllCombos(state),
      requestingCombos: state.combos.requestingCombos,
      token: state.accountReducer.token
    };
  }

  return connect(
    mapStateToProps,
    actions
  )(Container);
}

export default getCombos;
