import React from 'react';
import Card from '../../../../components/Card/Card';
import styled from 'styled-components';
import { Icon } from 'merchant-portal-components';

const Wrapper = styled(Card)`
  height: ${(props) => (props.imageSidebarSize ? '170px' : '200px')};
  width: ${(props) => (props.imageSidebarSize ? '150px' : '200px')};
  display: grid;
  grid-template-rows: 70px 130px;
  padding: 0;
  margin: 0;
  border-radius: 4px;
  margin-left: 50%;
  transform: translate(-50%, 0);
  margin-bottom: ${(props) => (props.imageSidebarSize ? '40px' : '0')};

  :hover {
    box-shadow: ${(props) =>
      props.onHover ? '0 16px 16px 0 rgba(0, 0, 0, 0.24)' : 'none'};
  }
`;

const ProductImage = styled.img`
  height: ${(props) => (props.imageSidebarSize ? '100px' : '130px')};
  width: ${(props) => (props.imageSidebarSize ? '150px' : '200px')};
  object-fit: cover;
  background-color: #d3d3d3;
  margin: 0;
`;

const ProductNameWrapper = styled.div`
  background: ${(props) => props.theme.white};
  padding: 8px;
  font-size: 14px;
  font-weight: bold;
  display: grid;
  grid-template-columns: 10fr 1fr;
`;

const ProductCard = ({
  productId,
  imageUrl,
  productName,
  clickToRemove,
  isSelected,
  imageSidebarSize,
  selectedProductRemove,
  onHover,
}) => (
  <Wrapper imageSidebarSize={imageSidebarSize} onHover={onHover}>
    <ProductNameWrapper>
      <div>{productName}</div>
      {clickToRemove && (
        <Icon onClick={clickToRemove} className="fal fa-times" />
      )}
      {selectedProductRemove && (
        <Icon
          onClick={() => selectedProductRemove(productId)}
          className="fal fa-times"
        />
      )}
      {isSelected && (
        <Icon className="fas fa-check-circle" style={{ color: '#4b7cf8' }} />
      )}
    </ProductNameWrapper>
    <ProductImage imageSidebarSize={imageSidebarSize} src={imageUrl} />
  </Wrapper>
);

export default ProductCard;
