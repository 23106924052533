import React from 'react'
import Nvf from "./Nvf.svg"

export const NoItemsFound=(props)=>{
    return(
        <div>
              <div>
                <img src={Nvf} alt="My SVG" />
              </div>
              <div>
                {
                  props.itemType === 'categories'?
                  <span>No {props.itemType} found.</span>:
                  <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;No {props.itemType} found.</span>
                }
                
              </div>
            </div>
    )
}