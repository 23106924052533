import React, { Fragment } from 'react';
import styled from 'styled-components';
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from 'react-sortable-hoc';
import { Icon } from 'merchant-portal-components';
import { withStyles } from '@material-ui/core/styles';

const FieldWrappedDiv = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 50px 1fr;
  background-color: white;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
  padding: 12px 0;
`;

const ComboCardDiv = styled.div`
  display: grid;
  align-items: center;
  cursor: pointer;
  padding: 10px;
`;

const HeaderDiv = styled.div`
  display: grid;
  background-color: white;
  grid-template-columns: 50px auto 60px;
  padding: 0 0 25px;
  font-weight: bold;
`;

const StyledSortableContainer = styled.div`
  height: 100%;
  overflow-y: scroll; // need to be in container for auto scroll when sorting
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
  border-radius: 12px;
  padding: 24px;
`;

const styles = {
  switchBase: {
    transition: 'none',
  },
  switchBaseDefault: {
    transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
};

const SortableItem = SortableElement(({ comboitem }) => {
  return (
    <FieldWrappedDiv>
      <Icon
        className="far fa-equals"
        style={{ cursor: 'move', textAlign: 'center' }}
      />
      <ComboCardDiv>
        <div>{comboitem.label}</div>
      </ComboCardDiv>
    </FieldWrappedDiv>
  );
});

const SortableList = SortableContainer(({ comboItems, arrayHelpers }) => {
  return (
    <StyledSortableContainer>
      <HeaderDiv>
        <div>Name</div>
      </HeaderDiv>
      {comboItems.map((comboitem, index) => (
        <SortableItem
          key={`comboitem-${index}`}
          index={index}
          itemIndex={index}
          comboitem={comboitem}
          arrayHelpers={arrayHelpers}
        />
      ))}
    </StyledSortableContainer>
  );
});

const RenderSortableComboItemsList = ({ arrayHelpers }) => {
  const comboItems = arrayHelpers.form.values.comboItems;
  const setFieldValue = arrayHelpers.form.setFieldValue;

  return (
    <Fragment>
      <SortableList
        helperClass="sortableHelper"
        comboItems={comboItems}
        arrayHelpers={arrayHelpers}
        onSortEnd={({ oldIndex, newIndex }) => {
          if (oldIndex !== newIndex) {
            const newList = arrayMove(comboItems, oldIndex, newIndex);
            setFieldValue(`comboItems`, newList);
          }
        }}
        distance={1}
      />
    </Fragment>
  );
};

export default withStyles(styles)(RenderSortableComboItemsList);
