export const GET_VARIANTS_REQUEST = 'GET_VARIANTS_REQUEST';

export const GET_VARIANTS_SUCCESS = 'GET_VARIANTS_SUCCESS';
export const GET_VARIANTS_FAILURE = 'GET_VARIANTS_FAILURE';
export const CHANGE_COMPONENT_TYPE = 'CHANGE_COMPONENT_TYPE';
export const GET_ACTIVE_REQUEST = 'GET_ACTIVE_REQUEST';
export const GET_ACTIVE_SUCCESS = 'GET_ACTIVE_SUCCESS';
export const GET_ACTIVE_FAILURE = 'GET_ACTIVE_FAILURE';
export const RESET_TO_INITIAL_VALUES = 'RESET_TO_INITIAL_VALUES';
export const SET_EDIT_VARIANT = 'SET_EDIT_VARIANT';
export const EDIT_VARIANT_REQUEST = 'EDIT_VARIANT_REQUEST';
export const EDIT_VARIANT_SUCCESS = 'EDIT_VARIANT_SUCCESS';
export const EDIT_VARIANT_FAILURE = 'EDIT_VARIANT_FAILURE';
export const CREATE_VARIANT_REQUEST = 'CREATE_VARIANT_REQUEST';
export const CREATE_VARIANT_SUCCESS = 'CREATE_VARIANT_SUCCESS';
export const CREATE_VARIANT_FAILURE = 'CREATE_VARIANT_FAILURE';
export const SET_LOCAL_VARIANT_ISACTIVE = 'SET_LOCAL_VARIANT_ISACTIVE';
export const UPDATE_VARIANTS_REQUEST_PARAMS = 'UPDATE_VARIANTS_REQUEST_PARAMS';
export const UPDATE_SORTED_VARIANTS_LIST = 'UPDATE_SORTED_VARIANTS_LIST';
