import React from 'react';
import { Field } from 'redux-form';
import styled from 'styled-components';
import { Label } from 'merchant-portal-components';

const Wrapper = styled.div`
  display: grid;
  align-items: center;
  ${(props) =>
    props.vertical
      ? `grid-auto-flow: row;
  grid-gap: 4px;`
      : `
  grid-auto-flow: column;
  justify-content: flex-start;
  grid-gap: 16px;
  `}
`;

const Switch = styled.label`
  position: relative;
  display: block;
  ${'' /* margin-left: auto; */}
  margin: 0;
  width: 40px;
  height: 14px;
  input {
    display: none;
    &:checked + span {
      ${'' /* background-color: #2196F3; */}
      background-color: #919fd6;
    }
    &:checked + span:before {
      transform: translateX(21px);
      background-color: #2440ae;
    }
    &:focus + span {
      ${'' /* box-shadow: 0 0 1px #2196F3; */}
      box-shadow: 0 0 1px ${(props) =>
        props.disabled ? '#ccc' : props.theme.blue};
    }
  }
`;

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 20px;
  &:before {
    position: absolute;
    content: '';
    height: 20px;
    width: 20px;
    left: 0px;
    bottom: -2px;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.24), 0 0 1px 0 rgba(0, 0, 0, 0.12);
    background-color: #f1f1f1;
    transition: 0.4s;
    border-radius: 50%;
  }
  &::after {
    right: 20px;
  }
`;

export const MiniToggleSwitch = ({
  onChange,
  checked,
  label,
  disabled,
  onClick,
  vertical,
}) => {
  return (
    <Wrapper vertical={vertical} onClick={onClick}>
      <Switch>
        <input
          type="checkbox"
          checked={checked}
          onChange={onChange}
          disabled={disabled}
        />
        <Slider />
      </Switch>
      {label && <Label vertical={vertical}>{label}</Label>}
    </Wrapper>
  );
};

const ToggleSwitch = ({ onChange, name, label, vertical }) => (
  <Wrapper vertical={vertical}>
    <Switch>
      <Field
        name={name}
        component="input"
        onChange={onChange}
        type="checkbox"
        parse={Boolean}
      />
      <Slider />
    </Switch>
    {label && (
      <Label vertical={vertical} style={{ fontWeight: '600' }}>
        {label}
      </Label>
    )}
  </Wrapper>
);

export default MiniToggleSwitch;
