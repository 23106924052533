import React, { Fragment } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    grid-column: 1 / span 2;
    grid-row: 3;
    display: grid;
    grid-template-columns: 140px 140px 140px 140px;
    grid-gap: 20px;
    margin: 20px 10px 20px 20px;
    overflow: scroll;

    @media (max-width: 1313px) {
        grid-template-columns: 140px 140px 140px;
    }

`;

const ImageWrapper = styled.div``;

const Images = styled.img`
    width: 140px;
    height: 140px;
    object-fit: cover;
    border-radius: 8%;
    border: ${props => !props.isSelected ? '1px solid #979797' : '3px solid #2440ae'};
    cursor: pointer;
    background-color: #d3d3d3;

    &:hover {
        border-radius: 8%;
        border: 3px solid #2440ae;
      }
`;

const ImageName = styled.div`
   font-size: 12px;
   text-align: center;
`;




const ImageGallery = (props) => {
    const {images} = props.mediaReducer;
   
    return (
        <Wrapper>
            {props.sliceImages(images).length > 0 ? 
             (props.sliceImages(images).map(image => {
                return (
                <ImageWrapper key={image.media_id}
                    onClick={() => props.setSelectedImage({
                        media_id: image.media_id,
                        name: image.name,
                        url: image.url,
                        alt_text: image.alt_text
                        }
                    )}
                > 
                    <Images src={image.url} isSelected={props.selectedImage.media_id == image.media_id}/>
                    <ImageName>
                        {image.name.length <=15 ? image.name : `${image.name.slice(0,15)}...`}
                    </ImageName>
                </ImageWrapper>
            )}))
            : <div>No matching image</div>
            }
    </Wrapper>
            
    );
}

export default ImageGallery;
