import React from 'react';
import { Widgets, Operators } from 'react-awesome-query-builder';
const {
  TextWidget,
  NumberWidget,
  SelectWidget,
  MultiSelectWidget,
  DateWidget,
  BooleanWidget,
  TimeWidget,
  DateTimeWidget,
  ValueFieldWidget
} = Widgets;
const { ProximityOperator } = Operators;
import moment from 'moment';
import en_US from 'antd/lib/locale-provider/en_US';

const relativeBetweenDateValues = ({ includeFutureDates, isStrict }) => {
  let values = {
    '-1': '1 day ago',
    '-7': '1 week ago',
    '-30': '1 month ago',
    '-90': '3 months ago',
    '-180': '6 months ago',
    '-365': '1 year ago'
  };

  if (includeFutureDates && isStrict) {
    values = {
      '0': 'today',
      '+1': '1 day from now',
      '+7': '1 week from now',
      '+30': '1 month from now',
      '+90': '3 months from now',
      '+180': '6 months from now',
      '+365': '1 year from now'
    };
  } else if (includeFutureDates) {
    values = {
      ...values,
      '+1': '1 day from now',
      '+7': '1 week from now',
      '+30': '1 month from now',
      '+90': '3 months from now',
      '+180': '6 months from now',
      '+365': '1 year from now'
    };
  }

  return values;
};

const relativeDateValues = ({ includeFutureDates, isStrict }) => {
  let values = {
    '0': 'today',
    '-1': '1 day ago',
    '-7': '1 week ago',
    '-30': '1 month ago',
    '-90': '3 months ago',
    '-180': '6 months ago',
    '-365': '1 year ago'
  };
  if (includeFutureDates && isStrict) {
    values = {
      '+1': '1 day from now',
      '+7': '1 week from now',
      '+30': '1 month from now',
      '+90': '3 months from now',
      '+180': '6 months from now',
      '+365': '1 year from now'
    };
  } else if (includeFutureDates) {
    values = {
      ...values,
      '+1': '1 day from now',
      '+7': '1 week from now',
      '+30': '1 month from now',
      '+90': '3 months from now',
      '+180': '6 months from now',
      '+365': '1 year from now'
    };
  }

  return values;
};

export const relativeDates = relativeDateValues({ includeFutureDates: true });
export const relativeBetweenDates = {
  '0': 'today',
  '-1': '1 day ago',
  '-7': '1 week ago',
  '-30': '1 month ago',
  '-90': '3 months ago',
  '-180': '6 months ago',
  '-365': '1 year ago',
  '+1': '1 day from now',
  '+7': '1 week from now',
  '+30': '1 month from now',
  '+90': '3 months from now',
  '+180': '6 months from now',
  '+365': '1 year from now'
};

export default {
  conjunctions: {
    AND: {
      label: 'And',
      formatConj: (children, conj, isForDisplay) => {
        return children.size > 1
          ? '(' + children.join(' ' + (isForDisplay ? 'AND' : '&&') + ' ') + ')'
          : children.first();
      }
    },
    OR: {
      label: 'Or',
      formatConj: (children, conj, isForDisplay) => {
        return children.size > 1
          ? '(' + children.join(' ' + (isForDisplay ? 'OR' : '||') + ' ') + ')'
          : children.first();
      }
    }
  },
  fields: {
    // first_name: {
    //     label: 'First Name',
    //     type: 'text',
    //     operators: ['equal', 'not_equal', 'begins_with', 'does_not_begin_with', 'contains', 'does_not_contain', 'ends_with', 'not_ends_with'],
    //     defaultOperator: 'equal',
    //     mainWidgetProps: {
    //         formatValue: (val, fieldDef, wgtDef, isForDisplay) => (" "+ val)
    //     },
    // },
    // last_name: {
    //     label: 'Last Name',
    //     type: 'text',
    //     operators: ['equal', 'not_equal', 'begins_with', 'does_not_begin_with', 'contains', 'does_not_contain', 'ends_with', 'not_ends_with'],
    //     defaultOperator: 'equal',
    //     mainWidgetProps: {
    //         formatValue: (val, fieldDef, wgtDef, isForDisplay) => (" "+ val)
    //     },
    // },
    // address: {
    //     label: 'Address',
    //     type: 'text',
    //     operators: ['equal', 'not_equal', 'begins_with', 'does_not_begin_with', 'contains', 'does_not_contain', 'ends_with', 'not_ends_with'],
    //     defaultOperator: 'equal',
    //     mainWidgetProps: {
    //         formatValue: (val, fieldDef, wgtDef, isForDisplay) => (" "+ val)
    //     },
    // },
    // postal_code: {
    //     label: 'Postal Code',
    //     type: 'text',
    //     operators: ['equal', 'not_equal', 'begins_with', 'does_not_begin_with', 'contains', 'does_not_contain', 'ends_with', 'not_ends_with'],
    //     defaultOperator: 'equal',
    //     mainWidgetProps: {
    //         formatValue: (val, fieldDef, wgtDef, isForDisplay) => (" "+ val)
    //     },
    // },
    // total_dollars_spent: {
    //     label: 'Total dollars spent',
    //     type: 'number',
    //     operators: ['equal', 'not_equal', 'less', 'less_or_equal', 'greater', 'greater_or_equal', 'between'],
    //     fieldSettings: {
    //         min: 0
    //     },
    //     mainWidgetProps: {
    //         formatValue: (val, fieldDef, wgtDef, isForDisplay) => (" "+ val)
    //     },
    // },
    // average_transactions_per_month: {
    //     label: 'Average transactions per month',
    //     type: 'number',
    //     operators: ['equal', 'not_equal', 'less', 'less_or_equal', 'greater', 'greater_or_equal', 'between'],
    //     fieldSettings: {
    //         min: 0
    //     },
    //     mainWidgetProps: {
    //         formatValue: (val, fieldDef, wgtDef, isForDisplay) => (" "+ val)
    //     },
    // },
    email: {
      label: 'Email',
      type: 'text',
      operators: [
        'equal',
        'not_equal',
        'BeginsWith',
        'EndsWith',
        // 'begins_with',
        // 'does_not_begin_with',
        // 'contains',
        // 'not_contains',
        // 'ends_with',
        // 'not_ends_with'
      ],
      defaultOperator: 'equal',
      mainWidgetProps: {
        formatValue: (val, fieldDef, wgtDef, isForDisplay) => ' ' + val
      }
    },
    tags: {
      label: 'Tag',
      type: 'select',
      operators: ['select_equals'],
      // fieldSettings: {
      //   min: 0
      // },
      // mainWidgetProps: {
      //   formatValue: (val, fieldDef, wgtDef, isForDisplay) => ' ' + val
      // },
      listValues: {}
    },
    products_purchased: {
      label: 'Product Purchased',
      type: 'select',
      operators: ['select_equals'],
      // fieldSettings: {
      //   min: 0
      // },
      // mainWidgetProps: {
      //   formatValue: (val, fieldDef, wgtDef, isForDisplay) => ' ' + val
      // },
      listValues: {}
    },
    total_transactions: {
      label: 'Total transactions',
      type: 'number',
      operators: [
        'equal',
        'not_equal',
        'less',
        'less_or_equal',
        'greater',
        'greater_or_equal',
        'between'
      ],
      fieldSettings: {
        min: 0
      },
      mainWidgetProps: {
        formatValue: (val, fieldDef, wgtDef, isForDisplay) => ' ' + val
      }
    },
    total_spend: {
      label: 'Total spent',
      type: 'number',
      operators: [
        'equal',
        'not_equal',
        'less',
        'less_or_equal',
        'greater',
        'greater_or_equal',
        'between'
      ],
      fieldSettings: {
        min: 0
      },
      mainWidgetProps: {
        formatValue: (val, fieldDef, wgtDef, isForDisplay) => ' ' + val
      }
    },
    average_spend: {
      label: 'Average spent',
      type: 'number',
      operators: [
        'equal',
        'not_equal',
        'less',
        'less_or_equal',
        'greater',
        'greater_or_equal',
        'between'
      ],
      fieldSettings: {
        min: 0
      },
      mainWidgetProps: {
        formatValue: (val, fieldDef, wgtDef, isForDisplay) => ' ' + val
      }
    },
    average_transactions: {
      label: 'Average transactions',
      type: 'number',
      operators: [
        'equal',
        'not_equal',
        'less',
        'less_or_equal',
        'greater',
        'greater_or_equal',
        'between'
      ],
      fieldSettings: {
        min: 0
      },
      mainWidgetProps: {
        formatValue: (val, fieldDef, wgtDef, isForDisplay) => ' ' + val
      }
    },
    first_transaction_date: {
      label: 'First Transaction Date',
      type: 'date',
      operators: [
        'is/was',
        'equal',
        'not_equal',
        'less',
        'less_or_equal',
        'greater',
        'greater_or_equal',
        'between',
        'relatively_before',
        'relatively_after',
        'relatively_between'
      ],
      defaultOperator: 'is/was',
      mainWidgetProps: {
        formatValue: (val, fieldDef, wgtDef, isForDisplay) => ' ' + val
      },
      listValues: null
    },
    last_transaction_date: {
      label: 'Last Transaction Date',
      type: 'date',
      operators: [
        'is/was',
        'equal',
        'not_equal',
        'less',
        'less_or_equal',
        'greater',
        'greater_or_equal',
        'between',
        'relatively_before',
        'relatively_after',
        'relatively_between'
      ],
      defaultOperator: 'is/was',
      mainWidgetProps: {
        formatValue: (val, fieldDef, wgtDef, isForDisplay) => ' ' + val
      },
      listValues: null
    },
    loyalty_credit_earned: {
      label: 'Loyalty credits earned',
      type: 'number',
      operators: [
        'equal',
        'not_equal',
        'less',
        'less_or_equal',
        'greater',
        'greater_or_equal',
        'between'
      ],
      fieldSettings: {
        min: 0
      },
      mainWidgetProps: {
        formatValue: (val, fieldDef, wgtDef, isForDisplay) => ' ' + val
      }
    },
    loyalty_credit_redeemed: {
      label: 'Loyalty credits redeemed',
      type: 'number',
      operators: [
        'equal',
        'not_equal',
        'less',
        'less_or_equal',
        'greater',
        'greater_or_equal',
        'between'
      ],
      fieldSettings: {
        min: 0
      },
      mainWidgetProps: {
        formatValue: (val, fieldDef, wgtDef, isForDisplay) => ' ' + val
      }
    },
    coupon_credit_earned: {
      label: 'Coupon credits earned',
      type: 'number',
      operators: [
        'equal',
        'not_equal',
        'less',
        'less_or_equal',
        'greater',
        'greater_or_equal',
        'between'
      ],
      fieldSettings: {
        min: 0
      },
      mainWidgetProps: {
        formatValue: (val, fieldDef, wgtDef, isForDisplay) => ' ' + val
      }
    },
    coupon_credit_redeemed: {
      label: 'Coupon credits redeemed',
      type: 'number',
      operators: [
        'equal',
        'not_equal',
        'less',
        'less_or_equal',
        'greater',
        'greater_or_equal',
        'between'
      ],
      fieldSettings: {
        min: 0
      },
      mainWidgetProps: {
        formatValue: (val, fieldDef, wgtDef, isForDisplay) => ' ' + val
      }
    },
    global_credit_earned: {
      label: 'Global credits earned',
      type: 'number',
      operators: [
        'equal',
        'not_equal',
        'less',
        'less_or_equal',
        'greater',
        'greater_or_equal',
        'between'
      ],
      fieldSettings: {
        min: 0
      },
      mainWidgetProps: {
        formatValue: (val, fieldDef, wgtDef, isForDisplay) => ' ' + val
      }
    },
    global_credit_redeemed: {
      label: 'Global credits redeemed',
      type: 'number',
      operators: [
        'equal',
        'not_equal',
        'less',
        'less_or_equal',
        'greater',
        'greater_or_equal',
        'between'
      ],
      fieldSettings: {
        min: 0
      },
      mainWidgetProps: {
        formatValue: (val, fieldDef, wgtDef, isForDisplay) => ' ' + val
      }
    },
    offer_credit_earned: {
      label: 'Offer credits earned',
      type: 'number',
      operators: [
        'equal',
        'not_equal',
        'less',
        'less_or_equal',
        'greater',
        'greater_or_equal',
        'between'
      ],
      fieldSettings: {
        min: 0
      },
      mainWidgetProps: {
        formatValue: (val, fieldDef, wgtDef, isForDisplay) => ' ' + val
      }
    },
    offer_credit_redeemed: {
      label: 'Offer credits redeemed',
      type: 'number',
      operators: [
        'equal',
        'not_equal',
        'less',
        'less_or_equal',
        'greater',
        'greater_or_equal',
        'between'
      ],
      fieldSettings: {
        min: 0
      },
      mainWidgetProps: {
        formatValue: (val, fieldDef, wgtDef, isForDisplay) => ' ' + val
      }
    },
    points_earned: {
      label: 'Points earned',
      type: 'number',
      operators: [
        'equal',
        'not_equal',
        'less',
        'less_or_equal',
        'greater',
        'greater_or_equal',
        'between'
      ],
      fieldSettings: {
        min: 0
      },
      mainWidgetProps: {
        formatValue: (val, fieldDef, wgtDef, isForDisplay) => ' ' + val
      }
    },
    points_redeemed: {
      label: 'Points redeemed',
      type: 'number',
      operators: [
        'equal',
        'not_equal',
        'less',
        'less_or_equal',
        'greater',
        'greater_or_equal',
        'between'
      ],
      fieldSettings: {
        min: 0
      },
      mainWidgetProps: {
        formatValue: (val, fieldDef, wgtDef, isForDisplay) => ' ' + val
      }
    },
    milestones_reached: {
      label: 'Milestones reached',
      type: 'number',
      operators: [
        'equal',
        'not_equal',
        'less',
        'less_or_equal',
        'greater',
        'greater_or_equal',
        'between'
      ],
      fieldSettings: {
        min: 0
      },
      mainWidgetProps: {
        formatValue: (val, fieldDef, wgtDef, isForDisplay) => ' ' + val
      }
    },
    birthdate: {
      label: 'Birthdate',
      type: 'date',
      operators: [
        'equal',
        'not_equal',
        'less',
        'less_or_equal',
        'greater',
        'greater_or_equal',
        'between'
      ],
      defaultOperator: 'equal',
      mainWidgetProps: {
        formatValue: (val, fieldDef, wgtDef, isForDisplay) => ' ' + val
      },
      listValues: null
    },
    next_birthday: {
      label: 'Next Birthday',
      type: 'date',
      operators: [
        'is/was',
        'relatively_before',
        'relatively_after',
        'relatively_between'
      ],
      defaultOperator: 'is/was',
      mainWidgetProps: {
        formatValue: (val, fieldDef, wgtDef, isForDisplay) => ' ' + val
      },
      listValues: null
    },
    last_birthday: {
      label: 'Last Birthday',
      type: 'date',
      operators: [
        'is/was',
        'relatively_before',
        'relatively_after',
        'relatively_between'
      ],
      defaultOperator: 'equal',
      mainWidgetProps: {
        formatValue: (val, fieldDef, wgtDef, isForDisplay) => ' ' + val
      },
      listValues: null
    },

    created_at: {
      label: 'Registration Date',
      type: 'date',
      operators: [
        'is/was',
        'equal',
        'not_equal',
        'less',
        'less_or_equal',
        'greater',
        'greater_or_equal',
        'between',
        'relatively_before',
        'relatively_after',
        'relatively_between'
      ],
      defaultOperator: 'is/was',
      mainWidgetProps: {
        formatValue: (val, fieldDef, wgtDef, isForDisplay) => ' ' + val
      },
      listValues: null
    },
    total_dollars_saved: {
      label: 'Total Dollars Saved',
      type: 'number',
      operators: [
        'equal',
        'not_equal',
        'less',
        'less_or_equal',
        'greater',
        'greater_or_equal',
        'between'
      ],
      fieldSettings: {
        min: 0
      },
      mainWidgetProps: {
        formatValue: (val, fieldDef, wgtDef, isForDisplay) => ' ' + val
      }
    }
  },
  types: {
    text: {
      valueSources: ['value'],
      widgets: {
        text: {
          operators: [
            'equal',
            'not_equal',
            'BeginsWith',
            'EndsWith',
            'begins_with',
            'does_not_begin_with',
            'contains',
            'not_contains',
            'ends_with',
            'not_ends_with'
          ],
          defaultOperator: 'equal',
          widgetProps: {
            valueLabel: 'Enter Text',
            valuePlaceholder: 'Enter Text'
          }
        }
      }
    },
    number: {
      valueSources: ['value'],
      widgets: {
        number: {
          operators: [
            'equal',
            'not_equal',
            'less',
            'less_or_equal',
            'greater',
            'greater_or_equal',
            'between',
            'not_between',
            'is_empty',
            'is_not_empty'
          ],
          defaultOperator: 'less',
          widgetProps: {
            valueLabel: 'Number2',
            valuePlaceholder: 'Enter number2'
          }
        }
      }
    },
    date: {
      widgets: {
        date: {
          operators: [
            'is/was',
            'equal',
            'not_equal',
            'less',
            'less_or_equal',
            'greater',
            'greater_or_equal',
            'between',
            'not_between',
            'is_empty',
            'is_not_empty',
            'relatively_before',
            'relatively_after',
            'relatively_between'
          ]
        }
      }
    },
    time: {
      widgets: {
        time: {
          operators: [
            'equal',
            'not_equal',
            'less',
            'less_or_equal',
            'greater',
            'greater_or_equal',
            'between',
            'not_between',
            'is_empty',
            'is_not_empty'
          ]
        }
      }
    },
    datetime: {
      widgets: {
        datetime: {
          operators: [
            'equal',
            'not_equal',
            'less',
            'less_or_equal',
            'greater',
            'greater_or_equal',
            'between',
            'not_between',
            'is_empty',
            'is_not_empty'
          ],
          opProps: {
            between: {
              valueLabels: [
                { label: 'Date from', placeholder: 'Enter datetime from' },
                { label: 'Date to', placeholder: 'Enter datetime to' }
              ]
            }
          },
          widgetProps: {
            timeFormat: 'HH:mm',
            dateFormat: 'YYYY-MM-DD',
            valueFormat: 'YYYY-MM-DD HH:mm'
          }
        }
      }
    },
    select: {
      mainWidget: 'select',
      widgets: {
        select: {
          operators: ['select_equals', 'select_not_equals'],
          widgetProps: {}
        },
        multiselect: {
          operators: ['select_any_in', 'select_not_any_in'],
          widgetProps: {}
        }
      }
    },
    multiselect: {
      widgets: {
        multiselect: {
          operators: ['multiselect_equals', 'multiselect_not_equals']
        }
      }
    },
    boolean: {
      widgets: {
        boolean: {
          operators: ['equal'],
          widgetProps: {
            //you can enable this if you don't use fields as value sources
            //hideOperator: true,
            //operatorInlineLabel: "is",
          }
        },
        field: {
          operators: ['equal', 'not_equal']
        }
      }
    }
  },
  operators: {
    'is/was': {
      label: 'is/was',
      labelForFormat: 'is/was'
    },
    equal: {
      label: 'equals',
      labelForFormat: 'equals',
      reversedOp: 'not_equal'
    },
    not_equal: {
      label: 'is not equal to',
      labelForFormat: 'is not equal to',
      reversedOp: 'equal'
    },
    BeginsWith:{
      label: 'Begins with',
      labelForFormat: 'Begins with',
      reversedOp: 'does_not_begins_with'
    },
    EndsWith:{
      label: 'Ends with',
      labelForFormat: 'Ends with',
      reversedOp: 'does_not_begins_with'
    },
    begins_with: {
      label: 'begins with',
      labelForFormat: 'begins with',
      reversedOp: 'does_not_begins_with'
    },
    does_not_begins_with: {
      label: 'does not begin with',
      labelForFormat: 'does not begin with',
      reversedOp: 'not_ends_with'
    },
    contains: {
      label: 'contains',
      labelForFormat: 'contains',
      reversedOp: 'not_contains'
    },
    not_contains: {
      label: 'does not contain',
      labelForFormat: 'does not contain',
      reversedOp: 'contains'
    },
    ends_with: {
      label: 'ends with',
      labelForFormat: 'ends with',
      reversedOp: 'not_ends_with'
    },
    not_ends_with: {
      label: 'does not end with',
      labelForFormat: 'does not end with',
      reversedOp: 'ends_with'
    },
    less: {
      label: 'is less than',
      labelForFormat: 'is less than',
      reversedOp: 'greater_or_equal'
    },
    less_or_equal: {
      label: 'is less than or equal to',
      labelForFormat: 'is less than or equal to',
      reversedOp: 'greater'
    },
    greater: {
      label: 'is greater than',
      labelForFormat: 'is greater than',
      reversedOp: 'less_or_equal'
    },
    greater_or_equal: {
      label: 'is greater than or equal to',
      labelForFormat: 'is greater than or equal to',
      reversedOp: 'less'
    },
    relatively_before: {
      label: 'is relatively before',
      labelForFormat: 'is relatively before'
    },
    relatively_after: {
      label: 'is relatively after',
      labelForFormat: 'is after'
    },
    relatively_between: {
      label: 'is relatively between',
      labelForFormat: 'is within',
      cardinality: 2,
      formatOp: (
        field,
        op,
        values,
        valueSrcs,
        valueTypes,
        opDef,
        operatorOptions,
        isForDisplay
      ) => {
        let valFrom = values.first();
        let valTo = values.get(1);
        if (isForDisplay)
          return `${field} >= ${valFrom} AND ${field} <= ${valTo}`;
        else return `${field} >= ${valFrom} && ${field} <= ${valTo}`;
      },
      valueLabels: ['Value from', 'Value to'],
      textSeparators: [null, 'and'],
      reversedOp: 'not_between'
    },

    between: {
      label: 'is between',
      labelForFormat: 'is between',
      cardinality: 2,
      formatOp: (
        field,
        op,
        values,
        valueSrcs,
        valueTypes,
        opDef,
        operatorOptions,
        isForDisplay
      ) => {
        let valFrom = values.first();
        let valTo = values.get(1);
        if (isForDisplay)
          return `${field} >= ${valFrom} AND ${field} <= ${valTo}`;
        else return `${field} >= ${valFrom} && ${field} <= ${valTo}`;
      },
      valueLabels: ['Value from', 'Value to'],
      textSeparators: [null, 'and'],
      reversedOp: 'not_between'
    },
    not_between: {
      label: 'Not between',
      labelForFormat: 'NOT BETWEEN',
      cardinality: 2,
      reversedOp: 'between',
      valueLabels: ['Value from', 'Value to'],
      textSeparators: [null, 'and'],
      reversedOp: 'between'
    },

    is_empty: {
      isUnary: true,
      label: 'Is Blank',
      labelForFormat: 'IS EMPTY',
      cardinality: 0,
      reversedOp: 'is_not_empty',
      formatOp: (
        field,
        op,
        value,
        valueSrc,
        valueType,
        opDef,
        operatorOptions,
        isForDisplay
      ) => {
        return isForDisplay ? `${field} IS EMPTY` : `!${field}`;
      }
    },
    is_not_empty: {
      isUnary: true,
      label: 'Is not blank',
      labelForFormat: 'IS NOT EMPTY',
      cardinality: 0,
      reversedOp: 'is_empty',
      formatOp: (
        field,
        op,
        value,
        valueSrc,
        valueType,
        opDef,
        operatorOptions,
        isForDisplay
      ) => {
        return isForDisplay ? `${field} IS NOT EMPTY` : `!!${field}`;
      }
    },
    select_equals: {
      label: '==',
      labelForFormat: '==',
      formatOp: (
        field,
        op,
        value,
        valueSrc,
        valueType,
        opDef,
        operatorOptions,
        isForDisplay
      ) => {
        return `${field} == ${value}`;
      },
      reversedOp: 'select_not_equals'
    },
    select_not_equals: {
      label: '!=',
      labelForFormat: '!=',
      formatOp: (
        field,
        op,
        value,
        valueSrc,
        valueType,
        opDef,
        operatorOptions,
        isForDisplay
      ) => {
        return `${field} != ${value}`;
      },
      reversedOp: 'select_equals'
    },
    select_any_in: {
      label: 'Any in',
      labelForFormat: 'IN',
      formatOp: (
        field,
        op,
        values,
        valueSrc,
        valueType,
        opDef,
        operatorOptions,
        isForDisplay
      ) => {
        if (valueSrc == 'value') return `${field} IN (${values.join(', ')})`;
        else return `${field} IN (${values})`;
      },
      reversedOp: 'select_not_any_in'
    },
    select_not_any_in: {
      label: 'Not in',
      labelForFormat: 'NOT IN',
      formatOp: (
        field,
        op,
        values,
        valueSrc,
        valueType,
        opDef,
        operatorOptions,
        isForDisplay
      ) => {
        if (valueSrc == 'value')
          return `${field} NOT IN (${values.join(', ')})`;
        else return `${field} NOT IN (${values})`;
      },
      reversedOp: 'select_any_in'
    },
    multiselect_equals: {
      label: 'Equals',
      labelForFormat: '==',
      formatOp: (
        field,
        op,
        values,
        valueSrc,
        valueType,
        opDef,
        operatorOptions,
        isForDisplay
      ) => {
        if (valueSrc == 'value') return `${field} == [${values.join(', ')}]`;
        else return `${field} == ${values}`;
      },
      reversedOp: 'multiselect_not_equals'
    },
    multiselect_not_equals: {
      label: 'Not equals',
      labelForFormat: '!=',
      formatOp: (
        field,
        op,
        values,
        valueSrc,
        valueType,
        opDef,
        operatorOptions,
        isForDisplay
      ) => {
        if (valueSrc == 'value') return `${field} != [${values.join(', ')}]`;
        else return `${field} != ${values}`;
      },
      reversedOp: 'multiselect_equals'
    },

    proximity: {
      label: 'Proximity search',
      cardinality: 2,
      valueLabels: [
        { label: 'Word 1', placeholder: 'Enter first word' },
        'Word 2'
      ],
      textSeparators: [
        //'Word 1',
        //'Word 2'
      ],
      formatOp: (
        field,
        op,
        values,
        valueSrc,
        valueType,
        opDef,
        operatorOptions,
        isForDisplay
      ) => {
        let val1 = values.first();
        let val2 = values.get(1);
        return `${field} ${val1} NEAR/${operatorOptions.get(
          'proximity'
        )} ${val2}`;
      },
      options: {
        optionLabel: 'Near',
        optionTextBefore: 'Near',
        optionPlaceholder: 'Select words between',
        factory: props => <ProximityOperator {...props} />,
        defaults: {
          proximity: 2
        }
      }
    }
  },
  widgets: {
    text: {
      type: 'text',
      valueSrc: 'value',
      factory: props => <TextWidget {...props} />,
      formatValue: (val, fieldDef, wgtDef, isForDisplay) => {
        return isForDisplay ? '"' + val + '"' : JSON.stringify(val);
      }
    },
    number: {
      type: 'number',
      valueSrc: 'value',
      factory: props => <NumberWidget {...props} />,
      valueLabel: 'Number',
      valuePlaceholder: 'Enter number',
      formatValue: (val, fieldDef, wgtDef, isForDisplay) => {
        return isForDisplay ? val : JSON.stringify(val);
      }
    },
    select: {
      type: 'select',
      valueSrc: 'value',
      factory: props => <SelectWidget {...props} />,
      formatValue: (val, fieldDef, wgtDef, isForDisplay) => {
        let valLabel = fieldDef.listValues[val];
        return isForDisplay ? '"' + valLabel + '"' : JSON.stringify(val);
      }
    },
    multiselect: {
      type: 'multiselect',
      valueSrc: 'value',
      factory: props => <MultiSelectWidget {...props} />,
      formatValue: (vals, fieldDef, wgtDef, isForDisplay) => {
        let valsLabels = vals.map(v => fieldDef.listValues[v]);
        return isForDisplay
          ? valsLabels.map(v => '"' + v + '"')
          : vals.map(v => JSON.stringify(v));
      }
    },
    date: {
      type: 'date',
      valueSrc: 'value',
      factory: props => {
        if (
          props.operator === 'is/was' ||
          props.operator === 'relatively_before' ||
          props.operator === 'relatively_after' ||
          props.operator === 'relatively_between'
        ) {
          let { fields } = props.config;
          let fieldsWithoutListValues = {};

          if (
            props.operator === 'relatively_between' &&
            (props.field !== 'next_birthday' || props.field !== 'last_birthday')
          ) {
            if (props.label == 'Value from') {
              let listValues = relativeBetweenDateValues({
                includeFutureDates: false
              });

              Object.keys(fields).forEach(
                key =>
                  (fieldsWithoutListValues[key] = {
                    ...fields[key],
                    listValues
                  })
              );
            } else {
              let listValues = relativeBetweenDateValues({
                includeFutureDates: true,
                isStrict: true
              });

              Object.keys(fields).forEach(
                key =>
                  (fieldsWithoutListValues[key] = {
                    ...fields[key],
                    listValues
                  })
              );
            }
          } else if (props.field === 'next_birthday') {
            let listValues = relativeBetweenDateValues({
              includeFutureDates: true,
              isStrict: true
            });

            Object.keys(fields).forEach(
              key =>
                (fieldsWithoutListValues[key] = {
                  ...fields[key],
                  listValues
                })
            );
          } else if (props.field === 'last_birthday') {
            let listValues = relativeBetweenDateValues({
              includeFutureDates: false
            });

            Object.keys(fields).forEach(
              key =>
                (fieldsWithoutListValues[key] = {
                  ...fields[key],
                  listValues
                })
            );
          } else {
            Object.keys(fields).forEach(
              key =>
                (fieldsWithoutListValues[key] = {
                  ...fields[key],
                  listValues: relativeDateValues({
                    includeFutureDates: props.operator === 'relatively_after',
                    isStrict: props.operator === 'relatively_after'
                  })
                })
            );
          }
          const propsToPass = {
            ...props,
            config: { ...props.config, fields: fieldsWithoutListValues }
          };
          return <SelectWidget {...propsToPass} />;
        }
        return (
          <DateWidget
            {...props}
            dateFormat={
              props.field === 'next_birthday' || props.field === 'last_birthday'
                ? 'MMM DD'
                : 'MMM DD, YYYY'
            }
            valueFormat={
              props.field === 'next_birthday' || props.field === 'last_birthday'
                ? 'MMM DD'
                : 'YYYY-MM-DD'
            }
          />
        );
      },
      dateFormat: 'DD.MM.YYYY',
      valueFormat: 'YYYY-MM-DD',
      formatValue: (val, fieldDef, wgtDef, isForDisplay, operator) => {
        if (operator === 'is/was') {
          let valLabel = fieldDef.listValues[val];
          return isForDisplay ? '"' + valLabel + '"' : JSON.stringify(val);
        }
        let dateVal = moment(val, wgtDef.valueFormat);
        return isForDisplay
          ? '"' + dateVal.format(wgtDef.dateFormat) + '"'
          : JSON.stringify(val);
      }
    },
    time: {
      type: 'time',
      valueSrc: 'value',
      factory: props => <TimeWidget {...props} />,
      timeFormat: 'HH:mm',
      valueFormat: 'HH:mm:ss',
      formatValue: (val, fieldDef, wgtDef, isForDisplay) => {
        let dateVal = moment(val, wgtDef.valueFormat);
        return isForDisplay
          ? '"' + dateVal.format(wgtDef.timeFormat) + '"'
          : JSON.stringify(val);
      }
    },
    datetime: {
      type: 'datetime',
      valueSrc: 'value',
      factory: props => <DateTimeWidget {...props} />,
      timeFormat: 'HH:mm',
      dateFormat: 'DD.MM.YYYY',
      valueFormat: 'YYYY-MM-DD HH:mm:ss',
      formatValue: (val, fieldDef, wgtDef, isForDisplay) => {
        let dateVal = moment(val, wgtDef.valueFormat);
        return isForDisplay
          ? '"' +
              dateVal.format(wgtDef.dateFormat + ' ' + wgtDef.timeFormat) +
              '"'
          : JSON.stringify(val);
      }
    },
    boolean: {
      type: 'boolean',
      valueSrc: 'value',
      factory: props => <BooleanWidget {...props} />,
      labelYes: 'Yes',
      labelNo: 'No ',
      formatValue: (val, fieldDef, wgtDef, isForDisplay) => {
        return isForDisplay ? (val ? 'Yes' : 'No') : JSON.stringify(!!val);
      },
      defaultValue: false
    },
    field: {
      valueSrc: 'field',
      factory: props => <ValueFieldWidget {...props} />,
      formatValue: (val, fieldDef, wgtDef, isForDisplay, valFieldDef) => {
        return isForDisplay ? valFieldDef.label || val : val;
      },
      valueLabel: 'Field to compare',
      valuePlaceholder: 'Select field to compare'
    }
  },
  settings: {
    locale: {
      short: 'en',
      full: 'en-US',
      antd: en_US
    },
    maxLabelsLength: 50,
    hideConjForOne: true,
    renderSize: 'small',
    renderConjsAsRadios: false,
    renderTextFieldAndOpAsDropdown: false,
    setOpOnChangeField: ['keep', 'default'], // 'default' (default if present), 'keep' (keep prev from last field), 'first', 'none'
    clearValueOnChangeField: false, //false - if prev & next fields have same type (widget), keep
    clearValueOnChangeOp: false,
    setDefaultFieldAndOp: false,
    maxNesting: 10,
    fieldSeparator: '.',
    fieldSeparatorDisplay: '->',
    showLabels: false,
    valueLabel: 'Value',
    valuePlaceholder: 'Value',
    fieldLabel: 'Field',
    operatorLabel: 'Operator',
    fieldPlaceholder: 'Select field',
    operatorPlaceholder: 'Select operator',
    deleteLabel: null,
    addGroupLabel: 'Add group',
    addRuleLabel: 'Add rule',
    delGroupLabel: null,
    canLeaveEmptyGroup: true, //after deletion
    formatReverse: (
      q,
      operator,
      reversedOp,
      operatorDefinition,
      revOperatorDefinition,
      isForDisplay
    ) => {
      if (isForDisplay) return 'NOT(' + q + ')';
      else return '!(' + q + ')';
    },
    formatField: (
      field,
      parts,
      label2,
      fieldDefinition,
      config,
      isForDisplay
    ) => {
      if (isForDisplay) return label2;
      else return field;
    },
    valueSourcesInfo: {
      value: {
        label: 'Value'
      }
      // field: {
      //   label: 'Field',
      //   widget: 'field'
      // }
    },
    valueSourcesPopupTitle: 'Select value source',
    canReorder: false,
    canCompareFieldWithField: (
      leftField,
      leftFieldConfig,
      rightField,
      rightFieldConfig
    ) => {
      //for type == 'select'/'multiselect' you can check listValues
      return true;
    }
  }
};
