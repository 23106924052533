import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { LoadingBlueLarge } from 'merchant-portal-components';
import PaginationControl from '../Pagination/PaginationControl';
import { Button } from 'merchant-portal-components';
import { Checkbox, TextField, InputAdornment } from '@material-ui/core';
import { Dialog } from '@material-ui/core';
import {
  StyledCard,
  HeaderWrapper,
  SearchWrapper,
  SectionTitle,
  LocationsCounter,
  RowWrapper,
  TH,
  TD,
  PaginationWrapper,
  ButtonWrapper,
  Left,
  Right,
  CancelButton,
  CloseIcon,
} from './filterModalStyles';
import * as actions from '../../modules/MenuManagement/Variants/variantsActions';
import { paginate } from './paginate';

const initiallRequestParams = {
  page_number: 1,
  page_size: '10',
  sorting_option: 'title-asc',
};
const FilterVariantModal = ({
  allVariants,
  requestParams,
  filteredVariants,
  setFilteredVariants,
  isOpen,
  setIsOpen,
  placeholder,
  searchLabel,
  formField,
  isEmptyAccepted,
  getVariants,
  updateVariantsRequestParams,
}) => {
  delete requestParams.has_next_page;
  //
  const [selectedVariants, setSelectedVariants] = useState(
    filteredVariants || []
  );
  const [searchedVariants, setSearchedVariants] = useState([]);
  useEffect(() => {
    if (!allVariants?.length > 0) {
      getVariants({
        page_number: 1,
        page_size: '4000',
        search_string: '',
      });
    }
    updateVariantsRequestParams(initiallRequestParams);
    return () => {
      updateVariantsRequestParams(initiallRequestParams);
    };
  }, []);
  //
  const updateInitials = useCallback(() => {
    if (allVariants && filteredVariants.length === 0 && isEmptyAccepted) {
      const newAllVariants = allVariants.map((variant) => variant.id);
      setSelectedVariants(newAllVariants.sort());
      setSearchedVariants(newAllVariants);
    }
  }, [allVariants]);
  useEffect(() => {
    updateInitials();
  }, [updateInitials]);

  const [currentPageVariants, setCurrentPageVariants] = useState([]);
  const [variantListLength, setVariantListLength] = useState([]);
  const [searchString, setSearchString] = useState('');
  const filterVariantList = useCallback(() => {
    let filteredList = allVariants;
    if (searchString) {
      filteredList = filteredList?.filter((item) =>
        item.name?.toLowerCase().includes(searchString?.toLowerCase())
      );
    }
    setSearchedVariants(filteredList);
    let paginatedList = paginate(
      filteredList,
      requestParams.page_number,
      requestParams.page_size
    );
    setCurrentPageVariants(paginatedList);
    setVariantListLength(filteredList.length);
  }, [
    requestParams.page_number,
    requestParams.page_size,
    selectedVariants,
    JSON.stringify(allVariants),
  ]);
  useEffect(() => {
    filterVariantList();
  }, [filterVariantList]);
  useEffect(() => {
    if (allVariants?.length > 0) {
      const filteredList = allVariants?.filter((item) =>
        item.name?.toLowerCase().includes(searchString?.toLowerCase())
      );
      setSearchedVariants(filteredList);
      const paginatedList = paginate(filteredList, 1, requestParams.page_size);
      updateVariantsRequestParams({ ...requestParams, page_number: 1 });
      setCurrentPageVariants(paginatedList);
      setVariantListLength(filteredList.length);
    }
  }, [searchString, allVariants]);
  ///
  useEffect(() => {}, []);
  const handleAssignVariants = (e, id) => {
    let newSelectedVariants = [...selectedVariants];
    let isSelected = selectedVariants.includes(id);
    if (isSelected) {
      newSelectedVariants = selectedVariants.filter(
        (currentId) => currentId !== id
      );
    } else {
      newSelectedVariants.push(id);
    }
    setSelectedVariants(newSelectedVariants);
  };
  const handleAssignAllVariants = (e) => {
    let isSelected = e.target.checked;
    let newSelectedVariants = [];
    if (isSelected) {
      searchedVariants.map((variant) => {
        newSelectedVariants.push(variant.id);
      });
    }
    setSelectedVariants(newSelectedVariants);
  };
  const handleAddVariants = () => {
    const isAllVariantSelected = allVariants.length === selectedVariants.length;
    let newSelectedVariants =
      isAllVariantSelected && isEmptyAccepted ? [] : selectedVariants;
    if (formField) {
      setFilteredVariants(formField, newSelectedVariants);
    } else {
      setFilteredVariants(newSelectedVariants);
    }
    setIsOpen(false);
  };
  const handleReset = () => {
    if (filteredVariants.length === 0 && !isEmptyAccepted) {
      const newAllVariants = allVariants.map((variant) => variant.id);
      setSelectedVariants(newAllVariants.sort());
    } else {
      setSelectedVariants(filteredVariants.sort());
    }
  };
  const isSaveDisabled = () => {
    let isDisabled = true;
    let isEqual = _.isEqual(filteredVariants.sort(), selectedVariants.sort());

    if (allVariants) {
      if (isEmptyAccepted && !isEqual) {
        isDisabled = false;
      } else if (
        !isEmptyAccepted &&
        selectedVariants.length !== 0 &&
        !isEqual
      ) {
        isDisabled = false;
      }
    }
    return isDisabled;
  };
  return (
    <Dialog open={isOpen} fullWidth maxWidth='md'>
      <StyledCard>
        <HeaderWrapper padding='0'>
          <div>
            <SectionTitle>Variants</SectionTitle>
            <LocationsCounter>
              {`${selectedVariants.length} variants selected`}
            </LocationsCounter>
          </div>
          <div style={{ textAlign: 'right' }}>
            <CloseIcon
              className='fa fa-times'
              onClick={() => setIsOpen(false)}
            ></CloseIcon>
          </div>
        </HeaderWrapper>
        <SearchWrapper>
          <TextField
            id='outlined-basic'
            label={searchLabel}
            placeholder={placeholder}
            variant='outlined'
            style={{ width: '100%' }}
            value={searchString}
            onChange={(e) => setSearchString(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <i className='fa fa-search' style={{ color: '#b5b5b5' }}></i>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position='end'>
                  {`${variantListLength} results`}
                </InputAdornment>
              ),
            }}
          />
        </SearchWrapper>
        <RowWrapper>
          <TH>
            <Checkbox
              checked={searchedVariants.length === selectedVariants.length}
              onChange={handleAssignAllVariants}
              color='primary'
              style={{
                transform: 'scale(1.3)',
              }}
            />
          </TH>
          <TH>Select All Variants</TH>
        </RowWrapper>
        {currentPageVariants.map((variant, index) => (
          <RowWrapper
            key={index}
            hoverBGColor='#f1f1fa'
            onClick={(e) => handleAssignVariants(e, variant.id)}
          >
            <TD bgColor='yellow'>
              <Checkbox
                checked={selectedVariants.includes(variant.id)}
                onChange={(e) => handleAssignVariants(e, variant.id)}
                color='primary'
                style={{
                  transform: 'scale(1.3)',
                }}
              />
            </TD>
            <TD isAssigned={selectedVariants.includes(variant.id)}>
              {variant.name}
            </TD>
          </RowWrapper>
        ))}
        <PaginationWrapper>
          <PaginationControl
            response={{
              ...currentPageVariants,
              page: requestParams.page_number,
              page_size: requestParams.page_size,
              count: allVariants.length,
            }}
            requestParams={requestParams}
            // updateAction={getVariants}
            type='variants'
          />
        </PaginationWrapper>
        <ButtonWrapper>
          <Left>
            <CancelButton onClick={handleReset}>Cancel</CancelButton>
          </Left>
          <Right>
            <Button onClick={handleAddVariants} disabled={isSaveDisabled()}>
              Save
            </Button>
          </Right>
        </ButtonWrapper>
      </StyledCard>
    </Dialog>
  );
};
function mapStateToProps(state) {
  return {
    allVariants: state.variantsReducer.allVariants,
    requestParams: state.variantsReducer.requestParams,
  };
}
export default connect(mapStateToProps, actions)(FilterVariantModal);
