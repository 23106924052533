import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import Card from './components/Card';
import { getAutomatedMessagesList } from './AutomatedMessages/automatedMessagesActions';
import BreadCrumb from '../../../components/BreadCrumb/BreadCrumb';
import { Button, ButtonRow } from 'merchant-portal-components';
import apnIcon from '../../../../public/img/MobileMessages/apn-icon.png';
import apnIconDisabled from '../../../../public/img/MobileMessages/apn-icon-disabled.png';
import oneTimeMsgIcon from '../../../../public/img/MobileMessages/one-time-msg-icon.png';
import illustrationCopy from '../../../../public/img/MobileMessages/illustration-copy.png';
import { Dialog } from '@material-ui/core';
const ModalWrapper = styled.div`
  grid-row: 1;
  display: grid;
  grid-template-columns: 2fr 1fr;
  padding: 42px 35px 34px 35px;
  position: relative;
`;
const ContentWrapper = styled.div`
  margin: 0;
`;
const CloseIcone = styled.i`
  margin: 0;
  top: 15px;
  right: 18px;
  position: absolute;
  color: black;
  cursor: pointer;
`;
const IconWrapper = styled.div`
  padding: '15px';
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;
const TitleWrapper = styled.div`
  /* font-family: Inter; */
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.03px;
  color: #191818;
  margin-bottom: 20px;
`;
const DescriptionWrapper = styled.div`
  /* font-family: Inter; */
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  color: #191818;
  margin-bottom: 26px;
`;

const MobileMessagesContainer = (props) => {
  const { getAutomatedMessagesList, automatedMessagesList } = props;
  const [isAutomatedMsgDisabled, setIsAutomatedMsgDisabled] = useState(false);
  const [isShowContactUs, setIsShowContactUs] = useState(false);
  useEffect(() => {
    getAutomatedMessagesList();
  }, []);
  const updateList = useCallback(() => {
    if (automatedMessagesList && automatedMessagesList.length > 0) {
      setIsAutomatedMsgDisabled(false);
    } else {
      setIsAutomatedMsgDisabled(true);
    }
  }, [automatedMessagesList]);

  useEffect(() => {
    updateList();
  }, [updateList]);
  const handleDisabledClick = () => {
    setIsShowContactUs(true);
  };
  const handleHideContactUs = () => {
    setIsShowContactUs(false);
  };
  const handleOpenEmail = () => {
    window.open('mailto:support@smooth.tech');
  };
  return (
    <div>
      <ButtonRow justifyContent="space-between" style={{ margin: 'auto' }}>
        <BreadCrumb pageName="Mobile Messages" />
      </ButtonRow>
      <Card
        logo={isAutomatedMsgDisabled ? apnIconDisabled : apnIcon}
        title="Automated Messages"
        description="Automatically inform customers about activities such as order status, offers, loyalty and more."
        onArrowClick={() =>
          props.history.push('mobile-messages/automated-messages')
        }
        disabled={isAutomatedMsgDisabled}
        onDisabledClick={handleDisabledClick}
      />

      <Card
        logo={oneTimeMsgIcon}
        title="One-Time Messages"
        description="Create single-use messages to inform all customers (or a specific group of customers) when you have a special event or promotion."
        onArrowClick={() =>
          props.history.push('mobile-messages/one-time-messages')
        }
        disabled={false}
      />
      {isShowContactUs && (
        <Dialog
          fullWidth={true}
          maxWidth={'sm'}
          open={isShowContactUs}
          onClose={handleHideContactUs}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <ModalWrapper>
            <ContentWrapper>
              <TitleWrapper>{'Contact us to enable this feature'}</TitleWrapper>
              <DescriptionWrapper>
                {
                  'Automated Messages allows you to send automated push notifications to your customers on activities such as order status updates, loyalty, referrals and more.'
                }
              </DescriptionWrapper>
              <ButtonRow
                justifyContent="space-between"
                style={{ margin: 'auto' }}
              >
                <Button onClick={handleOpenEmail} autoFocus>
                  Contact Us
                </Button>
              </ButtonRow>
            </ContentWrapper>
            <IconWrapper>
              <img
                src={illustrationCopy}
                width="124"
                height="109"
                style={{ margin: '0 auto', alignSelf: 'center' }}
              />
            </IconWrapper>
            <CloseIcone
              className="far fa-times fa-lg"
              onClick={handleHideContactUs}
            />
          </ModalWrapper>
        </Dialog>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  automatedMessagesList: state.automatedMessagesReducer.automatedMessagesList,
});

export default connect(mapStateToProps, {
  getAutomatedMessagesList,
})(MobileMessagesContainer);
