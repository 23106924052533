import firebase, { app } from 'firebase';

const firebaseConfig = {
  apiKey: 'AIzaSyAFtL8eBBB6Hw8haKRbdQqGUyw1u_C61VY',
  authDomain: 'smooth-portal.firebaseapp.com',
  databaseURL: 'https://smooth-portal.firebaseio.com',
  projectId: 'smooth-portal',
  storageBucket: 'smooth-portal.appspot.com',
  messagingSenderId: '660917012520',
  appId: '1:660917012520:web:b8798fc283766404200d1d',
};

const environment = process.env.NODE_ENV;

class Firebase {
  constructor() {
    var app = firebase.initializeApp(firebaseConfig);
    const url =
      environment === 'production' ? '/production-version' : '/staging-version';

    this.appVersion = null;

    const dataRef = app.database().ref(url);

    this.onAppVersionChange = (func) => {
      dataRef.on('value', (snapshot) => {
        if (!this.appVersion) {
          this.appVersion = snapshot.val();
        } else if (this.appVersion !== snapshot.val()) {
          this.appVersion = snapshot.val();
          func && func();
        }
      });
    };
  }
}

export default Firebase;
