import {
  GET_MOBILE_MESSAGES_REQUEST,
  GET_MOBILE_MESSAGES_SUCCESS,
  GET_MOBILE_MESSAGES_FAILURE,
  INITIATE_CREATE_MOBILE_MESSAGE,
  CREATE_MOBILE_MESSAGE_REQUEST,
  CREATE_MOBILE_MESSAGE_SUCCESS,
  CREATE_MOBILE_MESSAGE_FAILURE,
  INITIATE_EDIT_MOBILE_MESSAGE,
  EDIT_MOBILE_MESSAGE_REQUEST,
  EDIT_MOBILE_MESSAGE_SUCCESS,
  EDIT_MOBILE_MESSAGE_FAILURE,
  CANCEL_MOBILE_MESSAGE,
  CREATE_TEST_MESSAGE_REQUEST,
  CREATE_TEST_MESSAGE_FAILURE,
  CREATE_TEST_MESSAGE_SUCCESS,
  RESET_TEST_MESSAGES,
  READ_ONLY_MOBILE_MESSAGE,
  UPDATE_SORTED_MESSAGES_LIST
} from './mobileMessagesTypes';
import { UPDATE_MOBILE_MESSAGES_REQUEST_PARAMS } from '../../../Pagination/types';
const initialFormValues = {
  send_now: true,
  type: 'push',
  segments: []
};

export default function(
  state = {
    requestParams: {
      page_number: 1,
      page_size: 25,
      sorting_option: ''
    },
    isFetching: false,
    isCreating: false,
    response: null,
    isDeleting: [],
    initialFormValues,
    isTesting: false,
    testSuccess: null,
    isReadOnlyMobileMessage: false,
    isEditOnlyMobileMessage: false
  },
  action
) {
  switch (action.type) {
    case RESET_TEST_MESSAGES:
      return {
        ...state,
        isTesting: false,
        testSuccess: null
      };
    case CREATE_TEST_MESSAGE_REQUEST:
      return {
        ...state,
        isTesting: true
      };
    case CREATE_TEST_MESSAGE_SUCCESS:
      return {
        ...state,
        isTesting: false,
        testSuccess: true
      };
    case GET_MOBILE_MESSAGES_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case GET_MOBILE_MESSAGES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isCreating: false,
        isEditOnlyMobileMessage: false,
        isCreateOnlyMobileMessage: false,
        response: action.mobileMessages
      };
    case GET_MOBILE_MESSAGES_FAILURE:
      return {
        ...state,
        isFetching: false,
        isCreating: false,
        isEditOnlyMobileMessage: false,
        isCreateOnlyMobileMessage: false,
        error: action.error
      };
    case CREATE_MOBILE_MESSAGE_REQUEST:
      return {
        ...state,
        isCreating: true,
        isMessage: true,
        initialFormValues
      };
    case CREATE_MOBILE_MESSAGE_SUCCESS:
      return {
        ...state,
        isCreating: false
      };
    case CREATE_TEST_MESSAGE_FAILURE:
      return {
        ...state,
        isTesting: false,
        testSuccess: false,
        error: action.error
      };
    case CREATE_MOBILE_MESSAGE_FAILURE:
      return {
        ...state,
        isFetching: false,
        isCreating: false,
        isEditOnlyMobileMessage: false,
        isCreateOnlyMobileMessage: true,
        error: action.error
      };

    case INITIATE_CREATE_MOBILE_MESSAGE:
      return {
        ...state,
        //isCreating: false,
        isCreateOnlyMobileMessage: true,
        initialFormValues
      };
    case EDIT_MOBILE_MESSAGE_REQUEST:
      return {
        ...state,
        isCreating: true
      };
    case EDIT_MOBILE_MESSAGE_SUCCESS:
      return {
        ...state,
        isCreating: false,
        initialFormValues
      };
    case EDIT_MOBILE_MESSAGE_FAILURE:
      return {
        ...state,
        isFetching: false,
        isCreating: false,
        isEditOnlyMobileMessage: true,
        isCreateOnlyMobileMessage: false,
        error: action.error
      };
    case CANCEL_MOBILE_MESSAGE:
      return {
        ...state,
        isCreateOnlyMobileMessage: false,
        isEditOnlyMobileMessage: false,
        isReadOnlyMobileMessage: false,
        isCreating: false,
        isMessage: false
      };
    case INITIATE_EDIT_MOBILE_MESSAGE:
      return {
        ...state,
        isEditOnlyMobileMessage: true,
        initialFormValues: action.payload.mobileMessage
      };
    case READ_ONLY_MOBILE_MESSAGE:
      return {
        ...state,
        isReadOnlyMobileMessage: true,
        initialFormValues: action.payload.mobileMessage
      };
    case UPDATE_MOBILE_MESSAGES_REQUEST_PARAMS:
      return {
        ...state,
        requestParams: {
          ...state.requestParams,
          ...action.payload
        }
      };
    case UPDATE_SORTED_MESSAGES_LIST:
      return {
        ...state,
        response: {
          ...state.response,
          data: action.messagesList
        },
        requestParams: {
          ...state.requestParams,
          sorting_option: action.sortOrder
        }
      };
  }
  return state;
}
