import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
// const useStyles = makeStyles((theme) => ({
//   formControl: {
//     margin: 0,
//     height: 28,
//   },
// }));
const FilterSelect = ({
  selectedFilterType,
  filterIndex,
  updateFilterType,
  availableFilterTypes,
}) => {
  //   const classes = useStyles();
  const handleChange = (event) => {
    const selectedKey = event.target.value;
    updateFilterType(selectedKey, filterIndex);
  };
  return (
    <FormControl variant='outlined'>
      <Select value={selectedFilterType || 'none'} onChange={handleChange}>
        <MenuItem value='none' disabled>
          Select a filter
        </MenuItem>
        <MenuItem
          value='customer_id'
          disabled={!availableFilterTypes?.includes('customer_id')}
        >
          Customer Name or Email
        </MenuItem>
        <MenuItem
          value='order_id'
          disabled={!availableFilterTypes?.includes('order_id')}
        >
          Order #{' '}
        </MenuItem>
        <MenuItem
          value='business_id'
          disabled={!availableFilterTypes?.includes('business_id')}
        >
          Location
        </MenuItem>
        <MenuItem
          value='order_date'
          disabled={!availableFilterTypes?.includes('order_date')}
        >
          Date Placed
        </MenuItem>
        <MenuItem
          value='order_type'
          disabled={!availableFilterTypes?.includes('order_type')}
        >
          Order Type
        </MenuItem>
        <MenuItem
          value='status'
          disabled={!availableFilterTypes?.includes('status')}
        >
          Payment Status
        </MenuItem>
        <MenuItem
          value='pos_order_id'
          disabled={!availableFilterTypes?.includes('pos_order_id')}
        >
          POS #
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default FilterSelect;
