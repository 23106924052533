import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../productsActions';
import ProductBusinessSelectionFormComponent from '../components/ProductBusinessSelectionFormComponent';

const ProductBusinessSelectionFormContainer = (props) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [confirmCancelModalIsVisible, setConfirmCancelModalIsVisible] =
    useState(false);

  const [allBusinesses, setAllBusinesses] = useState([]);
  const [isAvailableAllLocations, setIsAvailableAllLocations] = useState('');

  useEffect(() => {
    props.allSubBusinesses.length > 0
      ? setAllBusinesses(props.allSubBusinesses)
      : setAllBusinesses(props.allBusinesses);
  }, []);

  useEffect(() => {
    setIsAvailableAllLocations(
      allBusinesses.length <=
        props.productSelectedBusinessesInitialValues.length
    );
  }, [allBusinesses, props.productSelectedBusinessesInitialValues]);

  const formatAllBusinesses = () => {
    return allBusinesses.map((business) => {
      const foundBusiness = props.productSelectedBusinessesInitialValues.find(
        (productBusiness) => productBusiness.business_id === business.id
      );
      if (foundBusiness) {
        return {
          ...foundBusiness,
          display_name: business.display_name,
          business_name: business.business_name,
          is_active: true,
        };
      } else {
        return {
          display_name: business.display_name,
          business_name: business.business_name,
          business_id: business.id,
          is_active: true,
          is_available: true,
          product_id: props.productId,
        };
      }
    });
  };

  return (
    <ProductBusinessSelectionFormComponent
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}
      disabled={!props.productId}
      initialValues={{
        productSelectedBusinesses:
          props.productSelectedBusinessesInitialValues.map(
            (business) => business.business_id
          ),
      }}
      confirmCancelModalIsVisible={confirmCancelModalIsVisible}
      setConfirmCancelModalIsVisible={setConfirmCancelModalIsVisible}
      allBusinesses={formatAllBusinesses()}
      putProductBusinesses={props.putProductBusinesses}
      businessesAssigned={props.productSelectedBusinesses}
      isAvailableAllLocations={isAvailableAllLocations}
      setIsAvailableAllLocations={setIsAvailableAllLocations}
      allInitialBusinesses={props.allBusinesses}
      productId={props.productId}
      getProductAssignedBusinesses={props.getProductAssignedBusinesses}
    />
  );
};

const mapStateToProps = (state) => ({
  productId: state.productsReducer.productId,
  productSelectedBusinessesInitialValues:
    state.productsReducer.productSelectedBusinessesInitialValues,
  allBusinesses: state.businessLocationsList.businessLocationsOfMerchant,
  allSubBusinesses: state.businessLocationsList.subBusinessLocationsOfMerchant,
});

export default connect(
  mapStateToProps,
  actions
)(ProductBusinessSelectionFormContainer);
