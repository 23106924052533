import api from '../../api';
import {
  CLEAR_ALL_ORDER_SUMMARIES,
  GET_ALL_ORDER_SUMMARY_SUCCSESSFUL,
  HIDE_TEMPEROARY_SUMMARY_REPORT_BANNER,
} from './summaryReportTypes';

export function isTemperoarySummaryReportBannerDisplayed(statue) {
  return {
    type: HIDE_TEMPEROARY_SUMMARY_REPORT_BANNER,
    temperoaryShowSummaryReportBanner: statue,
  };
}
export function updateRequestParams(requestParams) {
  return {
    type: 'UPDATE_SUMMARY_REPORT_REQUEST_PARAMS',
    payload: requestParams,
  };
}
export function clearAllOrderSummaries() {
  return {
    type: CLEAR_ALL_ORDER_SUMMARIES,
    allOrderSummaries: null,
  };
}
export function getOrderSummaries(
  startdate,
  endDate,
  businessIDs,
  sources,
  order_types,
  payment_types
) {
  return function (dispatch) {
    return api
      .post(`/report/transaction-summary`, {
        business_ids: businessIDs,
        start_date: startdate,
        end_date: endDate,
        sources: sources,
        order_types: order_types,
        payment_types: payment_types,
      })
      .then((response) => {
        dispatch({
          type: GET_ALL_ORDER_SUMMARY_SUCCSESSFUL,
          allOrderSummaries: response.data.data,
        });
      })
      .catch((error) => {
        // console.log(error);
      });
  };
}
