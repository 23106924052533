import React, { Component } from 'react';
import * as actions from '../segmentationActions';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import styled from 'styled-components';
import * as mapFunctions from './mapFunctions';
import WizardProgressBar from './WizardProgressBar/WizardProgressBar';
import BuildYourAudience from './BuildYourAudience/BuildYourAudience';
import FilterCriteria from './FilterCriteria/FilterCriteria';
import ReviewYourAudience from './ReviewYourAudience/ReviewYourAudience';
import SyncWithEmail from './SyncWithEmail/SyncWithEmail';
import BreadCrumb from '../../../components/BreadCrumb/BreadCrumb';
import LeaveFormModalPrompt from '../../../components/Modal/LeaveFormModalPrompt';
import BackButton from '../../../components/BackButton';
import ReactGA from 'react-ga';
const BreadCrumbWrapper = styled.div`
  color: ${(props) => props.theme.darkBlue};
  margin: 30px;
  margin-left: 0;
  @media print {
    display: none;
  }
`;
class CreateSegmentWizard extends Component {
  state = {
    pageNumber: 1,
    pageNames: [
      'Build Your Audience',
      'Filter Criteria',
      'Refine Your Audience',
      'Sync With Email',
    ],
    overlay: null,
    overlayType: null,
    overlayIsDragging: false,
    fitBounds: false,
    drawingMode: null,
    drawingManager: null,
    criteria: null,
    map: null,
    createRequestSent: false,
    isBlocking: false, // setting up on form's data change
    redirect: false,
    modal: false,
    overlayWithMap: null,
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  componentDidMount() {
    // if (!this.props.segments.allSegments) {
    //   this.props.getSegments(); //in case of user directly loading create-segment...need access to mailChimpLists
    // }
    this.props.change('audience.shape_json', null);

    if (this.props.segments.editSegment) {
      track.page('engagement-segmentation-edit');
    } else {
      track.page('engagement-segmentation-create');
    }
    ReactGA.pageview(window.location.pathname, undefined, 'Segment Detail');
  }

  componentDidUpdate(prevProps, prevState) {
    let editingSegment =
      this.props.edit && !prevProps.initialized && this.props.initialized;
    let cloningSegment =
      this.props.clone && !prevProps.initialized && this.props.initialized;
    let zoneFieldIsEmpty =
      this.props.segmentForm &&
      this.props.segmentForm.values.audience &&
      !this.props.segmentForm.values.audience.zone;
    let changedFromNoSyncForMailchimp =
      prevProps.initialized &&
      prevProps.segmentForm.values.mailchimp &&
      prevProps.segmentForm.values.mailchimp.external_segment_id === 'nosync' &&
      this.props.segmentForm.values.mailchimp &&
      this.props.segmentForm.values.mailchimp.external_segment_id !== 'nosync';
    let changedToNewListForMailchimp =
      prevProps.initialized &&
      prevProps.segmentForm.values.mailchimp &&
      prevProps.segmentForm.values.mailchimp.external_segment_id !== 'new' &&
      this.props.segmentForm.values.mailchimp &&
      this.props.segmentForm.values.mailchimp.external_segment_id === 'new';

    if (editingSegment || cloningSegment) {
      this.populateFieldsIfEditing();
    }
    // if (zoneFieldIsEmpty) {
    //   this.props.change('audience.zone', 'all_users'); //if editing segment with no audience, populate zone with default
    // }
    if (changedFromNoSyncForMailchimp) {
      this.props.change('mailchimp.sync_frequency', 'hourly'); //if user goes from nosync to new or existing list, set default sync_frequency
    }
    if (changedToNewListForMailchimp) {
      this.props.change('mailchimp.details.default_email_type', true); //if user goes from nosync to new or existing list, set default sync_frequency
    }
    // if (errorSubmittingForm) {
    //   this.setState({createRequestSent: false}); //resets submit button
    // }
    if (this.state.pageNumber === 3 && !prevProps.token && this.props.token) {
      // console.log(this.props.segmentForm.values.audience);
      this.props.fetchTargetAudienceData(
        this.props.segmentForm.values.audience,
        this.state.criteria
      );
    }
  }

  populateFieldsIfEditing = () => {
    let segment = this.props.segments.initialFormValues;
    segment = {
      ...segment,
      is_overwrite: segment.is_overwrite ? true : false,
    };
    if (segment.audience.zone && segment.audience.zone !== 'all_users') {
      segment.audience.shape_json.isEditable = true;
      this.setState({ overlay: segment.audience.shape_json });
    }
    if (segment.filters.filter_json) {
      this.setState({
        criteria: {
          filter_json: segment.filters.filter_json,
          filter_rules: segment.filters.filter_rules,
        },
      });
    }
  };

  nextPage = () => {
    if (
      this.state.pageNumber == 1 &&
      this.props.segmentForm.values.audience.zone !== 'all_users'
    ) {
      // let drawnOverlayZone = this.props.drawnOverlayZones.length
      //   ? this.props.drawnOverlayZones[0].editId != undefined ||
      //     !Object.keys(this.props.editOverlayObj).length
      //     ? this.props.drawnOverlayZones[0]
      //     : this.props.editOverlayObj
      //   : this.props.editOverlayObj;
      let drawnOverlayZone =
        'id' in this.props.editOverlayObj
          ? this.props.editOverlayObj
          : this.props.drawnOverlayZones[0];
      // const drawnOverlayZone = this.props.drawnOverlayZones[0];
      let shape = {
        type: drawnOverlayZone.type,
        // mapObj: drawnOverlayZone.mapObj
      };
      if (shape.type == 'polygon') {
        shape.vertices = drawnOverlayZone.vertices;
      } else {
        shape.position = drawnOverlayZone.position;
      }
      this.props.change('audience.shape_json', shape);
      shape.isVisible = true;
      shape.isEditable = false;
      shape.colour = '#00A5DB';
      this.setState({
        overlay: shape,
        overlayWithMap: { ...shape, mapObj: drawnOverlayZone.mapObj },
      });
    }
    if (
      this.state.overlay &&
      this.state.pageNumber == 1 &&
      this.props.segmentForm.values.audience.zone === 'all_users'
    ) {
      this.deleteOverlay();
    }
    this.setState({ pageNumber: this.state.pageNumber + 1 });
  };

  previousPage = () => {
    if (this.state.pageNumber == 2 && this.state.overlay) {
      this.setState({ overlay: { ...this.state.overlay, isEditable: true } });
    }
    this.setState({ pageNumber: this.state.pageNumber - 1 });
  };

  createSegment = () => {
    const formValues = this.props.segmentForm.values;
    const { editSegment, createSegment, cloneSegment } = this.props;
    const editingSegment = this.props.segments.editSegment;
    this.setState({ createRequestSent: true });
    editingSegment
      ? editSegment(formValues)
      : this.props.clone
      ? cloneSegment(formValues)
      : createSegment(formValues);
  };

  deleteOverlay = () => {
    this.props.change('audience.shape_json', null);
    this.setState({ overlay: null });
  };

  updateCriteria = (criteria) => {
    this.setState({ criteria });
    this.props.change('filters.filter_json', criteria.filter_json);
    this.props.change(
      'filters.filter_rules',
      criteria.filter_rules ? criteria.filter_rules : null
    );
  };

  cancelEditSegment = () => {
    if (this.props.pristine) {
      this.props.cancelEditSegment();
    }
  };

  render() {
    const { onSubmit, segments, pristine } = this.props;
    const { pageNumber } = this.state;
    const pageName = segments.editSegment ? 'Edit Segment' : 'Create Segment';

    return (
      <div className="createSegmentWizard">
        <BreadCrumbWrapper>
          <BackButton
            pageName="Segmentation List"
            onClick={() => this.props.cancelEditSegment()}
          />
          <BreadCrumb style={{ margin: ' 0px' }} pageName={pageName} />
        </BreadCrumbWrapper>

        <WizardProgressBar
          pageNumber={this.state.pageNumber}
          pageNames={this.state.pageNames}
        />
        <LeaveFormModalPrompt
          isBlocking={!pristine}
          afterConfirm={this.props.cancelEditSegment}
        />

        {pageNumber === 1 && (
          <BuildYourAudience
            clone={this.props.clone}
            nameToClone={
              this.props.clone ? this.props.initialValues.name : null
            }
            onSubmit={this.nextPage}
            updateSelectedLocations={this.props.updateSelectedLocations}
            allLocations={this.props.allBusinesses}
            selectedLocations={this.props.segments.selectedLocations}
            setOverlay={this.setOverlay}
            overlayIsDragging={this.state.overlayIsDragging}
            overlay={this.state.overlay}
            overlayType={this.state.overlayType}
            initOverlayListeners={this.initOverlayListeners}
            drawingManager={this.state.drawingManager}
            drawingMode={this.state.drawingMode}
            drawingComplete={this.drawingComplete}
            setMap={this.setMap}
            changeDrawingMode={this.changeDrawingMode}
            deleteOverlay={this.deleteOverlay}
            cancelEditSegment={this.cancelEditSegment}
            segmentForm={this.props.segmentForm}
          />
        )}

        {pageNumber === 2 && (
          <FilterCriteria
            previousPage={this.previousPage}
            onSubmit={this.nextPage}
            selectedLocations={this.props.segments.selectedLocations}
            updateCriteria={this.updateCriteria}
            criteria={this.state.criteria}
            cancelEditSegment={this.cancelEditSegment}
            fetchTargetAudienceData={this.props.fetchTargetAudienceData}
            audience={this.props.segmentForm.values.audience}
            filters={this.props.segmentForm.values.filters}
            allTags={this.props.allTags}
            overlayWithMap={this.state.overlayWithMap}
            allProducts={this.props.allProducts}
            change={this.props.change}
            businesses={this.props.segmentForm.values.businesses}
          />
        )}

        {pageNumber === 3 && (
          <ReviewYourAudience
            zone={this.props.segmentForm.values.audience.zone}
            previousPage={this.previousPage}
            onSubmit={this.nextPage}
            selectedLocations={this.props.segments.selectedLocations}
            overlay={this.state.overlay}
            overlayType={this.state.overlayType}
            criteria={this.state.criteria}
            cancelEditSegment={this.cancelEditSegment}
            targetAudienceData={this.props.segments.targetAudience}
            allTags={this.props.allTags}
            overlayWithMap={this.state.overlayWithMap}
            allProducts={this.props.allProducts}
            businesses={this.props.segmentForm.values.businesses}
          />
        )}

        {pageNumber === 4 && (
          <SyncWithEmail
            previousPage={this.previousPage}
            onSubmit={this.createSegment}
            segments={this.props.segments}
            edit={this.props.edit}
            createRequestSent={this.state.createRequestSent}
            cancelEditSegment={this.cancelEditSegment}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    segmentForm: state.form.segmentForm,
    segments: state.segments,
    token: state.accountReducer.token,
    allTags: state.tagsReducer.allTags,
    allProducts: state.productsReducer.allProducts,
    drawnOverlayZones: state.googleMapsWithOverlayZones.drawnOverlayZones,
    editOverlayObj: state.googleMapsWithOverlayZones.editOverlayObj,
  };
}

CreateSegmentWizard = reduxForm({
  form: 'segmentForm',
  destroyOnUnmount: true,
})(CreateSegmentWizard);

CreateSegmentWizard = connect((state) => ({
  initialValues: state.segments.initialFormValues, // pull initial values from segment reducer
}))(CreateSegmentWizard);

CreateSegmentWizard = connect(mapStateToProps, actions)(CreateSegmentWizard);

export default CreateSegmentWizard;
