import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import * as actions from '../actions';
import {
  initiateEditLocation,
  resetBusinessLocationDetailsReducerState,
} from '../../../actions';
import { submit } from 'redux-form';
import DeliveryZonesCreate from './components/DeliveryZoneCreate';
import BreadCrumb from '../../../../../../components/BreadCrumb/BreadCrumb';
import { LoadingBlueLarge } from 'merchant-portal-components';

class DeliveryZonesCreateContainer extends Component {
  componentDidMount() {
    if (
      !Object.keys(this.props.businessLocationDeliveryZones.locationCoords)
        .length
    ) {
      this.props.resetBusinessLocationDetailsReducerState();
      this.props.resetDeliveryZoneReducerState();
      this.props.history.push(`/locations`);
    } else {
      this.props.getDeliveryZoneObjects(
        this.props.match.params.businessLocationId
      );
    }

    // setTimeout(() => this.props.removeDeliveryZoneObject(1), 10000);
    // setTimeout(() => {
    //   let d = this.props.deliveryZones.deliveryZoneObjects[0];
    //   console.log(d);
    //   d.visible = false;
    //   this.props.updateDeliveryZoneObject(d);
    // }, 5000);
    // setTimeout(() => {
    //   let d = this.props.deliveryZones.deliveryZoneObjects[0];
    //   console.log(d);
    //   d.visible = true;
    //   this.props.updateDeliveryZoneObject(d);
    // }, 7000);
  }

  handleDeliveryZoneVisibility = (deliveryZoneObject, event) => {
    deliveryZoneObject.isVisible = event.target.checked;
    this.props.updateDeliveryZoneObject(deliveryZoneObject);
  };

  handleCreateDeliveryZoneFormSubmit = (deliveryZoneFormValues) => {
    const { drawnOverlayZones } = this.props;
    this.validatValues(deliveryZoneFormValues, drawnOverlayZones)
      .then((response) => {
        this.props.postDeliveryZoneObjectByLocationId(
          this.props.match.params.businessLocationId,
          response
        );
      })
      .catch((error) => {
        this.props.alertError(error.message);
      });
  };

  handleSaveChangesButtonClick = () => {
    this.handleCreateDeliveryZoneFormSubmit(
      this.props.form.createDeliveryZoneForm.values
    );
    // this.props.dispatch(submit('createDeliveryZoneForm'));
  };

  handleCancelButtonClick = () => {
    this.props.initiateEditLocation(this.props.match.params.businessLocationId);
    this.props.history.push(`/locations`);
  };

  validatValues = (deliveryZoneFormValues, drawnOverlayZones) => {
    return new Promise((resolve, reject) => {
      if (deliveryZoneFormValues.zoneName == '') {
        reject({ message: "Delivery Zone Name can't be empty" });
      } else if (deliveryZoneFormValues.zoneFee == '') {
        reject({ message: "Delivery Zone Fee can't be empty" });
      } else if (!drawnOverlayZones.length) {
        reject({ message: 'Must draw a Delivery Zone to save' });
      } else resolve({ deliveryZoneFormValues, drawnOverlayZones });
    });
  };

  render() {
    const {
      shouldComponentBeLoading,
      deliveryZoneObjects,
      editingColour,
      locationCoords,
    } = this.props.businessLocationDeliveryZones;

    const pageName = 'Create New Delivery Zone';
    if (shouldComponentBeLoading || !deliveryZoneObjects) {
      return <LoadingBlueLarge />;
    }
    return (
      <Fragment>
        <BreadCrumb pageName={pageName} />
        <DeliveryZonesCreate
          deliveryZoneObjects={deliveryZoneObjects}
          editingColour={editingColour}
          handleDeliveryZoneVisibility={this.handleDeliveryZoneVisibility}
          handleSaveChangesButtonClick={this.handleSaveChangesButtonClick}
          handleCancelButtonClick={this.handleCancelButtonClick}
          handleCreateDeliveryZoneFormSubmit={
            this.handleCreateDeliveryZoneFormSubmit
          }
          locationCoords={locationCoords}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    businessLocationDeliveryZones: state.businessLocationDeliveryZones,
    drawnOverlayZones: state.googleMapsWithOverlayZones.drawnOverlayZones,
    form: state.form,
  };
};

export default connect(mapStateToProps, {
  ...actions,
  initiateEditLocation,
  resetBusinessLocationDetailsReducerState,
})(DeliveryZonesCreateContainer);
