import React, { Fragment } from 'react';
import styled from 'styled-components';
import {
  Table,
  Tr,
  TrHead,
  Th,
  TBody,
  Td,
  SearchInputBar,
} from 'merchant-portal-components';
import { Link } from 'react-router-dom';
import { LoadingBlueLarge } from 'merchant-portal-components';
import Tooltip from '@material-ui/core/Tooltip';

const TableWrapper = styled.div`
  overflow: hidden;
  border-radius: 12px;
`;

const ViewDetails = styled.div`
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    color: #909cae;
  }
`;

const NoOrdersFound = styled.div`
  text-align: center;
  position: relative;
  transform: translateY(50%);
  height: 250px;
  font-size: 38px;
  color: #d8d8d8;
`;

const renderHeaders = () => {
  return (
    <TrHead data-test="component-TrHead">
      <Th align="left">Location</Th>
      <Th align="left">Customer</Th>
      <Th align="left">Rating</Th>
      <Th align="left">Comment</Th>
      <Th align="left">Order</Th>
    </TrHead>
  );
};

const renderStars = (rating) => {
  const array1To5 = [1, 2, 3, 4, 5];
  return (
    <Tooltip placement="right" title={rating}>
      <div>
        {array1To5.map((number, index) => {
          const className = number > rating ? 'far fa-star' : 'fa fa-star';
          return <i key={index} className={className} />;
        })}
      </div>
    </Tooltip>
  );
};

const renderRows = (
  handleActionButtonClick,
  reviews,
  allBusinesses,
  handleRedirectToCustomerDetail
) => {
  const findBusiness = (id) => {
    let bizName = allBusinesses.find((location) => location.id == id);
    if (bizName) {
      return bizName.display_name;
    }
  };
  if (reviews) {
    return reviews.map((review, index) => {
      return (
        <Tr key={index} data-test="component-Tr">
          <Td align="left">
            {allBusinesses
              ? findBusiness(review.business_id)
              : review.business_id}
          </Td>
          <Td align="left">
            <ViewDetails
              onClick={() => handleRedirectToCustomerDetail(review.customer_id)}
            >
              {review.order_id ? 'View Details' : null}
            </ViewDetails>
          </Td>
          <Td align="left" style={{ width: '200px' }}>
            {renderStars(review.rating)}
          </Td>
          <Td align="left">{review.comment}</Td>
          <Td align="left">
            <ViewDetails
              onClick={() => handleActionButtonClick(review.order_id)}
            >
              {review.order_id ? 'View Details' : null}
            </ViewDetails>
          </Td>
        </Tr>
      );
    });
  }
};

export const FeedbackHistoryTable = (props) => {
  const {
    getCustomerReviewsIsRequesting,
    handleActionButtonClick,
    handleRedirectToCustomerDetail,
    reviews,
    allBusinesses,
  } = props;
  window.scrollTo(0, 0);
  return (
    <Fragment>
      <TableWrapper>
        <Table data-test="component-Table" noBorder>
          <TBody striped>
            {renderHeaders()}
            {reviews &&
              props.allBusinesses &&
              !getCustomerReviewsIsRequesting &&
              renderRows(
                handleActionButtonClick,
                reviews,
                allBusinesses,
                handleRedirectToCustomerDetail
              )}
          </TBody>
        </Table>
      </TableWrapper>

      {getCustomerReviewsIsRequesting ? (
        <LoadingBlueLarge
          height="100vh"
          data-test="component-LoadingBlueLarge"
        />
      ) : (
        reviews &&
        !reviews.length && (
          <NoOrdersFound data-test="component-NoFeedbackFound">
            No Feedback Found
          </NoOrdersFound>
        )
      )}
    </Fragment>
  );
};

export default FeedbackHistoryTable;
