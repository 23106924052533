import {
  GET_ORDER_DETAILS_BY_ORDER_ID_REQUEST,
  GET_ORDER_DETAILS_BY_ORDER_ID_SUCCESS,
  CANCEL_ORDER_DETAILS_BY_ORDER_ID_REQUEST,
  CANCEL_ORDER_DETAILS_BY_ORDER_ID_SUCCESS,
  TOGGLE_CANCEL_ORDER_MODAL_VISIBILITY,
  GET_ORDER_DETAILS_BY_ORDER_ID_FAILURE,
  TOGGLE_SEND_LOYALTY_MODAL_VISIBILITY,
  CANCEL_ORDER_DETAILS_BY_ORDER_ID_FAILURE,
  SEND_LOYALTY_POINTS_BY_CUSTOMER_ID_REQUEST,
  SEND_LOYALTY_POINTS_BY_CUSTOMER_ID_SUCCESS,
  SEND_LOYALTY_POINTS_BY_CUSTOMER_ID_FAILURE,
  RESET_ORDER_DETAILS_REDUCER_STATE,
} from './types';

const defaultState = {
  orderDetails: null,
  requestingOrderDetails: true,
  requestingCancelOrderDetails: false,
  cancelOrderHistoryDetailsResponse: null,
  isCancelOrderModalVisible: false,
  isSendLoyaltyModalVisible: false,
  requestingSendLoyaltyPointsByCustomerId: false,
  redirectPage: '',
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'REDIRECT_USER_TO_RELATED_PAGE_FROM_ORDER_HISTORY_DETAIL':
      return { ...state, redirectPage: action.redirectPage };
    case GET_ORDER_DETAILS_BY_ORDER_ID_REQUEST:
      return {
        ...state,
        orderDetails: null,
        requestingOrderDetails: true,
      };
    case GET_ORDER_DETAILS_BY_ORDER_ID_SUCCESS:
      return {
        ...state,
        orderDetails: action.orderDetails,
        requestingOrderDetails: false,
      };
    case GET_ORDER_DETAILS_BY_ORDER_ID_FAILURE:
      return {
        ...state,
        requestingOrderDetails: false,
        orderDetails: null,
      };
    case CANCEL_ORDER_DETAILS_BY_ORDER_ID_REQUEST:
      return {
        ...state,
        requestingCancelOrderDetails: true,
      };
    case CANCEL_ORDER_DETAILS_BY_ORDER_ID_SUCCESS:
      return {
        ...state,
        requestingCancelOrderDetails: false,
        isCancelOrderModalVisible: false,
        cancelOrderHistoryDetailsResponse: action.payload.data,
      };
    case TOGGLE_CANCEL_ORDER_MODAL_VISIBILITY:
      return { ...state, isCancelOrderModalVisible: action.payload };
    case TOGGLE_SEND_LOYALTY_MODAL_VISIBILITY:
      return { ...state, isSendLoyaltyModalVisible: action.payload };
    case CANCEL_ORDER_DETAILS_BY_ORDER_ID_FAILURE:
      return {
        ...state,
        isCancelOrderModalVisible: false,
        requestingCancelOrderDetails: false,
      };
    case SEND_LOYALTY_POINTS_BY_CUSTOMER_ID_SUCCESS:
      return {
        ...state,
        isSendLoyaltyModalVisible: false,
        requestingSendLoyaltyPointsByCustomerId: false,
      };
    case SEND_LOYALTY_POINTS_BY_CUSTOMER_ID_REQUEST:
      return {
        ...state,
        requestingSendLoyaltyPointsByCustomerId: true,
      };
    case SEND_LOYALTY_POINTS_BY_CUSTOMER_ID_FAILURE:
      return {
        ...state,
        isSendLoyaltyModalVisible: false,
        requestingSendLoyaltyPointsByCustomerId: false,
      };
    case RESET_ORDER_DETAILS_REDUCER_STATE:
      return defaultState;
    default:
      return state;
  }
};
