export const GET_COUPONS_REQUEST = 'GET_COUPONS_REQUEST';
export const GET_COUPONS_SUCCESS = 'GET_COUPONS_SUCCESS';
export const GET_COUPONS_FAILURE = 'GET_COUPONS_FAILURE';

export const INITIATE_CREATE_COUPON = 'INITIATE_CREATE_COUPON';

export const CREATE_COUPON_REQUEST = 'CREATE_COUPON_REQUEST';
export const CREATE_COUPON_SUCCESS = 'CREATE_COUPON_SUCCESS';
export const CREATE_COUPON_FAILURE = 'CREATE_COUPON_FAILURE';

export const INITIATE_EDIT_COUPON = 'INITIATE_EDIT_COUPON';
export const EDIT_COUPON_REQUEST = 'EDIT_COUPON_REQUEST';
export const EDIT_COUPON_SUCCESS = 'EDIT_COUPON_SUCCESS';
export const EDIT_COUPON_FAILURE = 'EDIT_COUPON_FAILURE';

export const TOGGLE_IS_ACTIVE_COUPON = 'TOGGLE_IS_ACTIVE_COUPON';
export const TOGGLE_IS_ACTIVE_COUPON_SUCCESS =
  'TOGGLE_IS_ACTIVE_COUPON_SUCCESS';
export const TOGGLE_IS_ACTIVE_COUPON_FAILURE =
  'TOGGLE_IS_ACTIVE_COUPON_FAILURE';

export const CANCEL_COUPON = 'CANCEL_COUPON';

export const SEARCH_CUSTOMER_REQUEST = 'SEARCH_CUSTOMER_REQUEST';
export const SEARCH_CUSTOMER_SUCCESS = 'SEARCH_CUSTOMER_SUCCESS';
export const SEARCH_CUSTOMER_FAILURE = 'SEARCH_CUSTOMER_FAILURE';

export const CLEAR_CUSTOMER_SEARCH = 'CLEAR_CUSTOMER_SEARCH';
export const GET_CUSTOMER_DETAIL_REQUEST = 'GET_CUSTOMER_DETAIL_REQUEST';
export const GET_CUSTOMER_DETAIL_SUCCESS = 'GET_CUSTOMER_DETAIL_SUCCESS';
export const GET_CUSTOMER_DETAIL_FAILURE = 'GET_CUSTOMER_DETAIL_FAILURE';
export const UPDATE_COUPONS_REQUEST_PARAMS = 'UPDATE_COUPONS_REQUEST_PARAMS';
export const UPDATE_SORTED_COUPONS_LIST = 'UPDATE_SORTED_COUPONS_LIST';
export const RESET_STORE_VALUE = 'RESET_STORE_VALUE';
