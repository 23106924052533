import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Table, Tr, TrHead, Th, TBody, Td } from 'merchant-portal-components';
import PaginationControl from '../../../../../components/Pagination/PaginationControl';
import { TooltipIcon } from '../../../../../components/Icon';
import { Tooltip, Switch } from '@material-ui/core';
import { NoItemsFound } from '../../../NoItemsFound';
import NoDataMessage from '../../../../../components/NoDataMessage/NoDataMessage';
const PaginationWrapper = styled.div`
  padding: 15px 45px;
  border-top: 1px solid lightgray;
`;
const TableWrapper = styled.div`
  overflow-x: auto;
  border-radius: 12px;
`;

const PaginationWrappr = styled.div`
  padding: 4px 23px;
  z-index: 1;
`;

const MessageWrapper = styled.div`
  display: flex;
  margin-left: 120%;
  height: 300px;
  justify-content: center;
  align-items: center;
  font-weight: 600;
`;

const StyledDiv = styled.div`
position:relative;
bottom:85px;
right: 33px;
height: 0px;
z-index:1;

@media (min-width: 700px) and (max-width: 1100px){
  position:relative;
  bottom: 10px;
  top:10px;
  padding:10px;
  right: 65px;
  margin-bottom:50px;
 }
@media (min-width: 1100px) and (max-width: 1270px){
  position:relative;
  bottom: 10px;
  padding:10px;
  right: 65px;
  margin-bottom:20px;
 }

`;

const renderList = (
  allVariants,
  toggleIsActive,
  variantIsSaving,
  setEditVariant,
  requestParams,
  searchString,
  showActiveData
) => {
  return (
    <>
      <TableWrapper>
        <Table style={{ border: 'none' }}>
          <TBody>
            {renderTableHeader(requestParams)}
            {allVariants.length > 0 ? (
              requestParams &&
              renderTableRows(
                allVariants,
                toggleIsActive,
                variantIsSaving,
                setEditVariant,
                requestParams,
                searchString
              )
            ) : (
              <div></div>
            )}
          </TBody>
        </Table>
        <NoDataMessage
          len={allVariants?.length}
          type='Variants'
          isActive={showActiveData}
        />
      </TableWrapper>
    </>
  );
};

const renderTableHeader = (requestParams) => {
  return (
    <TrHead>
      <Th
        align='left'
        value='name'
        sortingOption={requestParams.sorting_option}
        style={{ paddingLeft: '37px' }}
      >
        Name
      </Th>
      <Th align='left'>
        Label
        <Tooltip
          style={{ marginRight: 'auto' }}
          title='This is an internal label to help you identify the type of variant.This will not appear to your customers'
        >
          <span>
            <TooltipIcon marginLeft />
          </span>
        </Tooltip>
      </Th>
      <Th
        align='right'
        value='is_active'
        sortingOption={requestParams.sorting_option}
        style={{ paddingRight: '45px' }}
      >
        Active
      </Th>
    </TrHead>
  );
};
const renderTableRows = (
  allVariants,
  toggleIsActive,
  variantIsSaving,
  setEditVariant
) => {
  return allVariants.map((variant, index) => (
    <Tr
      key={index}
      style={{ height: '78px', color: '#191818', cursor: 'pointer' }}
      onClick={() => !variantIsSaving && setEditVariant(variant)}
    >
      <Td padding={'0px 0px 0px 37px'}>{variant.name}</Td>
      <Td>{variant.label}</Td>
      <Td padding={'0px 37px 0px 0px'} style={{ textAlign: 'right' }}>
        <Switch
          checked={variant.is_active}
          onClick={(e) => e.stopPropagation()}
          onChange={() => toggleIsActive(variant)}
          disabled={variantIsSaving}
          value='checkedB'
          color='primary'
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
      </Td>
    </Tr>
  ));
};

const VariantList = ({
  showOnlyWhenNotSearched,
  allVariants,
  requestParams,
  isGetVariantRequest,
  toggleIsActive,
  setEditVariant,
  variantIsSaving,
  searchString,
  getVariants,
  showActiveData,
}) => {
  return (
    <Fragment>
      {showOnlyWhenNotSearched && (
        <StyledDiv>
          <PaginationWrappr>
            <PaginationControl
              response={{
                ...allVariants,
                page: requestParams.page_number,
                page_size: requestParams.page_size,
                count: allVariants,
              }}
              requestParams={requestParams}
              updateAction={getVariants}
              type='variants'
            />
          </PaginationWrappr>
        </StyledDiv>
      )}
      {renderList(
        allVariants,
        toggleIsActive,
        variantIsSaving,
        setEditVariant,
        requestParams,
        searchString,
        showActiveData
      )}

      <PaginationWrapper>
        <PaginationControl
          response={{
            ...allVariants,
            page: requestParams.page_number,
            page_size: requestParams.page_size,
            count: allVariants,
          }}
          requestParams={requestParams}
          updateAction={getVariants}
          type='variants'
        />
      </PaginationWrapper>
    </Fragment>
  );
};

export default VariantList;
