export const GET_APP_FEED_REQUEST = "GET_APP_FEED_REQUEST";
export const GET_APP_FEED_SUCCESS = "GET_APP_FEED_SUCCESS";
export const CREATE_APP_FEED_REQUEST = "CREATE_APP_FEED_REQUEST";
export const CREATE_APP_FEED_SUCCESS = "CREATE_APP_FEED_SUCCESS";
export const CREATE_APP_FEED_FAILURE = "CREATE_APP_FEED_FAILURE";
export const OPEN_CREATE_FEED_ROW_FORM = "OPEN_CREATE_FEED_ROW_FORM";
export const CANCEL_CREATE_FEED_ROW_FORM = "CANCEL_CREATE_FEED_ROW_FORM";
export const CREATE_FEED_ROW = "CREATE_FEED_ROW";
export const SET_SORT_APP_FEED = "SET_SORT_APP_FEED";
export const UNDO_APP_FEED_CHANGES = "UNDO_APP_FEED_CHANGES";
export const OPEN_EDIT_FEED_ROW_FORM = "OPEN_EDIT_FEED_ROW_FORM";
export const CANCEL_EDIT_FEED_ROW_FORM = "CANCEL_EDIT_FEED_ROW_FORM";
export const UPDATE_FEED_ROW = "UPDATE_FEED_ROW";
export const DELETE_FEED_ROW = "DELETE_FEED_ROW";
export const OPEN_CREATE_CARD_FORM = "OPEN_CREATE_CARD_FORM";
export const CLOSE_CREATE_CARD_FORM = "CLOSE_CREATE_CARD_FORM";
export const CREATE_CARD = "CREATE_CARD";
export const SET_SORT_CARDS = "SET_SORT_CARDS";
export const OPEN_EDIT_CARD_FORM = "OPEN_EDIT_CARD_FORM";
export const CLOSE_EDIT_CARD_FORM = "CLOSE_EDIT_CARD_FORM";
export const LIVE_EDIT_CARD = "LIVE_EDIT_CARD";
export const EDIT_CARD = "EDIT_CARD";
export const DELETE_CARD = "DELETE_CARD";
export const HIDE_FEED_ROW_FORM = "HIDE_FEED_ROW_FORM";
export const RESET_APP_FEED_REDUCER_STATE = "RESET_APP_FEED_REDUCER_STATE";
