import React from "react";
import styled from "styled-components";
import { Button } from "../../../../../../../components/Button/Button";
import Dialog from "@material-ui/core/Dialog";
export const ModalBody = styled.div`
  background: white;
  display: grid;
  border-radius: 2px;
  padding: 0 0 30px 0;
`;

export const ContainerDiv = styled.div`
  text-align: center;
  padding: ${(props) => props.padding || "0"};
`;

export const StyledP = styled.p`
  font-size: ${(props) => props.size || "inherit"};
  color: ${(props) => (props.lightBlue ? "#296bde" : props.theme.darkBlue)};
  font-weight: ${(props) => (props.bold ? "bold" : "inherit")};
`;

export const HorizontalLine = styled.hr`
  width: 100%;
  margin: 0 auto;
  border: none;
  border-top: 1px solid #c9cbcf;
`;

export const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  margin: 15px 15% 15px 15%;
`;

const DeleteSpecialHoursModal = ({
  isOpen,
  setIsOpen,
  deleteSelectedSpecialHours,
  businessId,
  specialHoursIDs,
}) => {
  const handleDelete = () => {
    deleteSelectedSpecialHours(businessId, specialHoursIDs);
    setIsOpen(false);
  };
  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth="sm"
    >
      <ModalBody>
        <ContainerDiv padding="15px">
          <StyledP bold size="36px">
            Confirm delete
          </StyledP>
        </ContainerDiv>
        <ContainerDiv>
          <HorizontalLine />
        </ContainerDiv>
        <ContainerDiv padding="30px 15px 10px 15px">
          <StyledP size="18px">
            You are about to delete upcoming Special Hours
          </StyledP>
        </ContainerDiv>
        <ContainerDiv padding="10px 15px 30px 15px">
          <StyledP size="16px" lightBlue>
            This action cannot be undone
          </StyledP>
        </ContainerDiv>
        <ContainerDiv padding="10px 15px 30px 15px">
          <StyledP size="18px">Are you sure you want to Delete?</StyledP>
        </ContainerDiv>
        <GridWrapper>
          <Button secondary onClick={() => setIsOpen(false)}>
            CANCEL
          </Button>
          <Button primary onClick={handleDelete}>
            {"YES, DELETE"}
          </Button>
        </GridWrapper>
      </ModalBody>
    </Dialog>
  );
};

export default DeleteSpecialHoursModal;
