import React, {Component} from 'react';
import {Query, Builder, Utils} from 'react-awesome-query-builder';
const {queryBuilderFormat, queryString} = Utils;
import Immutable from 'immutable';
import config from './config';
import './QueryBuilder.scss';

class QueryBuilder extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    if (this.props.criteria && this.props.criteria.filter_json) {
      let tree = Immutable.OrderedMap(
        Immutable.fromJS(this.props.criteria.filter_json)
      );
      this.onChange(tree);
    }
  }
  getChildren(props) {
    return (
      <div className="query-builder">
        <Builder {...props} />
      </div>
    );
  }

  onChange(tree) {
    this.props.updateCriteria({
      filter_json: tree.toJS(),
      filter_rules: queryBuilderFormat(tree, config)
    });
  }

  toOrderedMap() {
    if (this.props.criteria) {
      return Immutable.OrderedMap(
        Immutable.fromJS(this.props.criteria.filter_json)
      );
    }
    return null;
  }

  configWithTags = () => {
    // tags
    let tagNames = {};
    const renderValueString = value => (value !== 'true' ? `: ${value}` : '');
    this.props.allTags &&
      this.props.allTags.forEach(
        tag =>
          (tagNames = {
            ...tagNames,
            [tag.tag_id]: `${tag.name}${renderValueString(tag.value)}`
          })
      );

    // products
    let productNames = {};

    this.props.allProducts &&
      this.props.allProducts.forEach(
        product =>
          (productNames = {
            ...productNames,
            [product.id]: `${product.name}`
          })
      );

    return {
      ...config,
      fields: {
        ...config.fields,
        tags: {...config.fields.tags, listValues: tagNames},
        products_purchased: {
          ...config.fields.products_purchased,
          listValues: productNames
        }
      }
    };
  };

  render() {
    return (
      <Query
        {...this.configWithTags()}
        // {...this.configWithProducts()}
        value={this.toOrderedMap()}
        get_children={this.getChildren}
        onChange={this.onChange}
      />
    );
  }
}

export default QueryBuilder;
