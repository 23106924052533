import api from '../../../api';
import { createAlert } from '../../Alert/actions';
import {
  GET_ORDER_DETAILS_BY_ORDER_ID_REQUEST,
  GET_ORDER_DETAILS_BY_ORDER_ID_SUCCESS,
  CANCEL_ORDER_DETAILS_BY_ORDER_ID_SUCCESS,
  CANCEL_ORDER_DETAILS_BY_ORDER_ID_REQUEST,
  TOGGLE_CANCEL_ORDER_MODAL_VISIBILITY,
  GET_ORDER_DETAILS_BY_ORDER_ID_FAILURE,
  TOGGLE_SEND_LOYALTY_MODAL_VISIBILITY,
  CANCEL_ORDER_DETAILS_BY_ORDER_ID_FAILURE,
  SEND_LOYALTY_POINTS_BY_CUSTOMER_ID_REQUEST,
  SEND_LOYALTY_POINTS_BY_CUSTOMER_ID_SUCCESS,
  SEND_LOYALTY_POINTS_BY_CUSTOMER_ID_FAILURE,
  RESET_ORDER_DETAILS_REDUCER_STATE,
} from './types';

export function handleRedirectFromOrderHistoryDetail(redirectPage) {
  return function (dispatch) {
    dispatch({
      type: 'REDIRECT_USER_TO_RELATED_PAGE_FROM_ORDER_HISTORY_DETAIL',
      redirectPage: redirectPage,
    });
  };
}
function getOrderDetailsByOrderIdRequest() {
  return {
    type: GET_ORDER_DETAILS_BY_ORDER_ID_REQUEST,
  };
}

function getOrderDetailsByOrderIdFailure() {
  return {
    type: GET_ORDER_DETAILS_BY_ORDER_ID_FAILURE,
  };
}

export function getOrderDetailsByOrderId(params) {
  return function (dispatch) {
    dispatch(getOrderDetailsByOrderIdRequest());
    return api
      .get(`/orders/${params}`)
      .then((response) => {
        dispatch({
          type: GET_ORDER_DETAILS_BY_ORDER_ID_SUCCESS,
          orderDetails: response.data.data,
        });
      })
      .catch((error) => {
        dispatch(getOrderDetailsByOrderIdFailure());
      });
  };
}

function cancelOrderByOrderIdRequest() {
  return {
    type: CANCEL_ORDER_DETAILS_BY_ORDER_ID_REQUEST,
  };
}

export function cancelOrderModalVisibility(isVisible) {
  return function (dispatch) {
    dispatch({
      type: TOGGLE_CANCEL_ORDER_MODAL_VISIBILITY,
      payload: isVisible,
    });
  };
}

export function sendLoyaltyModalVisibility(isVisible) {
  return function (dispatch) {
    dispatch({
      type: TOGGLE_SEND_LOYALTY_MODAL_VISIBILITY,
      payload: isVisible,
    });
  };
}

export function cancelOrderByOrderId(orderId) {
  return function (dispatch) {
    dispatch(cancelOrderByOrderIdRequest());
    return api
      .post(`/orders/${orderId}/refund`)
      .then((response) => {
        dispatch({
          type: CANCEL_ORDER_DETAILS_BY_ORDER_ID_SUCCESS,
          payload: response.data,
        });
        dispatch(
          createAlert({
            type: 'success',
            message: 'Order successfully refunded',
          })
        );
        dispatch(getOrderDetailsByOrderId(orderId));
      })
      .catch((error) => {
        let status = error.response.status;
        let message =
          status == 403
            ? 'You are not allowed to cancel this order'
            : status == 500
            ? 'Server is down, try again later'
            : 'Unexpected error, contact admin';
        dispatch({
          type: CANCEL_ORDER_DETAILS_BY_ORDER_ID_FAILURE,
        });
        dispatch(
          createAlert({
            type: 'error',
            message: message,
          })
        );
      });
  };
}

function sendLoyaltyPointsByCustomerIdRequest() {
  return {
    type: SEND_LOYALTY_POINTS_BY_CUSTOMER_ID_REQUEST,
  };
}

export function sendLoyaltyPointsByCustomerId(customerId, type, body) {
  return function (dispatch) {
    dispatch(sendLoyaltyPointsByCustomerIdRequest());
    return api
      .post(`customers/${customerId}/${type}`, body)
      .then((response) => {
        dispatch({
          type: SEND_LOYALTY_POINTS_BY_CUSTOMER_ID_SUCCESS,
          payload: response.data,
        });
        dispatch(
          createAlert({
            type: 'success',
            message: `Customer Successfully Received ${
              type == 'points'
                ? `${body.amount} Point Credits`
                : `${body.amount} Dollar Credits`
            }`,
          })
        );
      })
      .catch((error) => {
        dispatch({
          type: SEND_LOYALTY_POINTS_BY_CUSTOMER_ID_FAILURE,
        });
        dispatch(
          createAlert({
            type: 'error',
            message: error?.response?.data?.errors?.message || 'Cannot send loyalty points right now',
          })
        );
      });
  };
}

export function errorAlert(message) {
  return function (dispatch) {
    dispatch(
      createAlert({
        type: 'error',
        message,
      })
    );
  };
}

export function resetOrderDetailsReducerState() {
  return {
    type: RESET_ORDER_DETAILS_REDUCER_STATE,
  };
}

export function resendReceiptEmail(orderId) {
  return function (dispatch) {
    // dispatch(sendLoyaltyPointsByCustomerIdRequest());
    return api
      .post(`/orders/${orderId}/resend-receipt`)
      .then((response) => {
        dispatch({
          type: SEND_LOYALTY_POINTS_BY_CUSTOMER_ID_SUCCESS,
          payload: response.data,
        });
        dispatch(
          createAlert({
            type: 'success',
            message: 'Receipt sent successfully',
          })
        );
      })
      .catch((err) => {
        createAlert({
          type: 'error',
          message: err?.response?.data?.errors?.message || 'Something went wrong!',
        });
      });
  };
}
