import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import RolesTable from './RolesTable';
import { Wrapper } from 'merchant-portal-components';
import CreateEditUserModalContainer from './CreateEditUser/CreateEditUserModalContainer';

const TitleWrapper = styled(Wrapper)`
  display: grid;
  grid-template-columns: 5fr 1fr;
  padding: 0;
  margin: 0;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 18px;
`;

const TitleDiv = styled.div`
  padding: 30px 30px 20px 30px;
`;

const NewUserDiv = styled.div`
  font-family: 'Montserrat';
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
  margin: auto;
`;

const RolesIndex = (props) => {
  const {
    accounts,
    roles,
    businesses,
    postResendInvitation,
    updateUser,
    patchUpdateRole,
    authUser,
    isModal,
    toggleCreateUserModal,
  } = props;

  return (
    <div>
      <TitleWrapper>
        <TitleDiv>
          <Title>Users</Title>
          <div>View the different roles registered for your portal</div>
        </TitleDiv>
        <NewUserDiv>
          <div onClick={() => toggleCreateUserModal(null)}>
            <i className="far fa-user-plus" style={{ marginRight: '8px' }} />
            Add a New User
          </div>
        </NewUserDiv>
      </TitleWrapper>
      <RolesTable
        accounts={accounts}
        roles={roles}
        postResendInvitation={postResendInvitation}
        updateUser={updateUser}
        patchUpdateRole={patchUpdateRole}
        authUser={authUser}
        businesses={businesses}
        isModal={isModal}
        toggleCreateUserModal={toggleCreateUserModal}
      />
    </div>
  );
};
export default RolesIndex;
