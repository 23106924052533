import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: ${props => '#fff'};
`;

const CheckIcon = styled.i`
  color: ${props => 'black'};
  font-size: 13px;
  font-weight: 600;
`;

const CircleCheck = () => (
  <Wrapper>
    <CheckIcon className="fal fa-check" />
  </Wrapper>
);

export default CircleCheck;
