import React, { useEffect, Suspense, lazy, useState, useCallback } from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import * as actions from '../ReportTableActions.js';
import ReportSearch from '../components/ReportSearch';
import { Dialog } from '@material-ui/core';
import FilterModal from '../../../components/FilterModal/FilterModal';
import { formatLocations } from '../../../utils/childLocations/formatLocations';
import { getBusinessLocationsOfMerchant } from '../../BusinessLocationDetails/LocationListManager/actions.js';
import LocationFilterModal from '../../../components/FilterModal/LocationFilterModal.js';
const GenericTable = lazy(() =>
  import('../../../components/Table/GenericTable')
);

function LocationReportContainer({
  allLocationOrders,
  allBusinesses,
  requestParams,
  getLocationReport,
  setExportingData,
  setExportingHeaders,
  setExportingFileName,
  clearLocationReport,
  updateReportsRequestParams,
  getBusinessLocationsOfMerchant,
  setMessage,
  resetAllReports,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  selectedBusinessesIDs,
  setSelectedBusinessesIDs,
  filteredLocations,
  setFilteredLocations,
}) {
  const [isLocationFilterOpen, setIsLocationFilterOpen] = useState(false);
  const columnHeaders = [
    'Location Name',
    'Number of orders #',
    'Pickup Orders Paid in App #',
    'Pickup Orders Paid on Arrival #',
    'Delivery Orders Paid in App #',
    'Delivery Orders Paid on Arrival #',
    'Scan to Pay Orders #',
    'Loyalty Only Orders #',
    'Scan and Go Orders #',
    'Drop-off Orders #',
    'Products Sold #',
    'Combos Sold #',
    'Paypal Orders #',
    'Gift Card Orders #',
    'CC Orders #',
    'AVG Transaction Basket Size',
    'Gross Sales',
    'Discounts',
    'Net Sales',
    'Customer Paid $',
  ];
  const columnNames = [
    'business_name',
    'num_of_orders',
    'num_orders_pickup_paid_in_app',
    'num_orders_pickup_paid_on_arrival',
    'num_orders_delivery_paid_in_app',
    'num_orders_delivery_paid_on_arrival',
    'num_orders_in_store_paid_in_app',
    'num_orders_in_store_paid_in_person',
    'num_orders_scan_and_go',
    'num_orders_drop_off',
    'num_products_sold',
    'num_combos_sold',
    'num_order_paypal',
    'num_orders_gift_card',
    'num_orders_credit_card',
    'avg_basket_size',
    'gross_sales',
    'discounts',
    'net_sales',
    'customer_paid',
  ];
  const columnTypes = [
    'string',
    'string',
    'string',
    'string',
    'string',
    'string',
    'string',
    'string',
    'string',
    'string',
    'string',
    'string',
    'string',
    'string',
    'string',
    'money',
    'money',
    'money',
    'money',
    'money',
  ];
  const columnWidth = [
    '200px',
    '200px',
    '200px',
    '200px',
    '200px',
    '200px',
    '200px',
    '200px',
    '200px',
    '200px',
    '200px',
    '200px',
    '200px',
    '200px',
    '200px',
    '200px',
    '200px',
    '200px',
    '200px',
    '200px',
  ];

  const columnHeaderIsSortableList = [
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
  ];
  const updateSelectedBusiness = useCallback(() => {
    if (filteredLocations) {
      setSelectedBusinessesIDs(filteredLocations);
    }
  }, [filteredLocations]);
  useEffect(() => {
    updateSelectedBusiness();
  }, [updateSelectedBusiness]);
  const getExportHeaders = (headers, fields) => {
    const exportedHeaders = [];
    headers.forEach((header, index) =>
      exportedHeaders.push({ label: header, key: fields[index] })
    );
    return exportedHeaders;
  };
  useEffect(() => {
    getBusinessLocationsOfMerchant();
    setExportingFileName('location_report');
    setExportingHeaders(getExportHeaders(columnHeaders, columnNames));
    setMessage('Select a date range and location to generate a report');
    ReactGA.pageview(window.location.pathname, undefined, 'Location Report');
    return () => {
      clearLocationReport();
      setExportingData([]);
      updateReportsRequestParams({
        page_number: 1,
        page_size: 25,
      });
      setMessage('Select a date range and location to generate a report');
      resetAllReports();
      setFilteredLocations([]);
      setSelectedBusinessesIDs([]);
    };
  }, []);
  useEffect(() => {
    if (allLocationOrders?.length === 0) {
      setExportingData([]);
      setMessage('No Data Found');
    }
  }, [allLocationOrders]);
  return (
    <>
      <ReportSearch
        allBusinesses={allBusinesses}
        generateReport={getLocationReport}
        haslocationFiltering
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        selectedBusinessesIDs={selectedBusinessesIDs}
        setSelectedBusinessesIDs={setSelectedBusinessesIDs}
        setIsLocationFilterOpen={setIsLocationFilterOpen}
      />
      {allLocationOrders?.length > 0 && (
        <div style={{ marginTop: '30px' }}>
          <Suspense fallback={<div>Loading...</div>}>
            <GenericTable
              dataList={allLocationOrders}
              columnHeaders={columnHeaders}
              columnHeaderIsSortableList={columnHeaderIsSortableList}
              columnNames={columnNames}
              columnWidth={columnWidth}
              columnTypes={columnTypes}
              placeholder=''
              requestParams={requestParams}
              requestParamsType={'locationReport'}
              handleClickRow={null}
              handleCheckboxSelect={null}
              checkboxList={[]}
              setCheckboxList={null}
              setExportingData={() => setExportingData(allLocationOrders)}
            />
          </Suspense>
        </div>
      )}
      {isLocationFilterOpen && (
        <LocationFilterModal
          placeholder='Enter Internal Location Name'
          searchLabel='Search by Internal Location Name'
          filteredLocations={filteredLocations}
          setFilteredLocations={setFilteredLocations}
          isOpen={isLocationFilterOpen}
          setIsOpen={setIsLocationFilterOpen}
          emptyIsEqualToAll={true}
          isEmptyAccepted={false}
        />
      )}
    </>
  );
}

function mapStateToProps(state) {
  return {
    allLocationOrders: state.ReportTableReducer.allLocationOrders,
    requestParams: state.ReportTableReducer.requestParams,
    allBusinesses: state.businessLocationsList.businessLocationsOfMerchant,
  };
}

export default connect(mapStateToProps, {
  ...actions,
  getBusinessLocationsOfMerchant,
})(LocationReportContainer);
