import api from '../../api';
import renderQueryString from '../../utils/renderQueryString';
import moment from 'moment';
import { get } from 'lodash';
import ReactGA from 'react-ga';
// new
export function resetAllReports() {
  return function (dispatch) {
    dispatch({
      type: 'RESET_ALL_REPORTS',
    });
  };
}
export function updateReportsRequestParams(params) {
  return function (dispatch) {
    dispatch({
      type: 'UPDATE_REPORTS_REQUEST_PARAMS',
      params: params,
    });
  };
}
export function getTransactionReport(params) {
  return function (dispatch) {
    const startTime = moment();
    return api
      .get(`/transactions${renderQueryString(params)}`, {
        ignoreInterceptors: true,
      })
      .then((response) => {
        const endTime = moment();
        const responseTime = endTime.diff(startTime, 'millisecond');
        ReactGA.timing({
          category: 'Response Time',
          variable: 'Fetching',
          value: responseTime,
          label: 'Getting Transactions Response Time',
        });
        ReactGA.event({
          category: 'Successful Response',
          action: 'Getting Transactions',
          label: 'Successfully Getting Transactions',
        });
        dispatch({
          type: 'GET_ALL_TRANSACTION_REPORT_SUCCSESSFUL',
          allTransactions: response.data.data,
        });
      })
      .catch((error) => {
        ReactGA.event({
          category: 'Unsuccessful Response',
          action: 'Getting Transactions',
          label: 'Unsuccessfully Getting Transactions',
        });
        // console.log(error);
      });
  };
}
export function clearTransactionReport() {
  return function (dispatch) {
    dispatch({
      type: 'CLEAR_TRANSACTION_REPORT',
    });
  };
}
export function getOfferReport(params, setMessage) {
  const modifiedParams = {
    ...params,
    start_date: moment(params.start_datetime).format('YYYY-MM-DD'),
    end_date: moment(params.end_datetime).format('YYYY-MM-DD'),
  };
  delete modifiedParams.start_datetime;
  delete modifiedParams.end_datetime;
  if ('business_ids' in params) {
    Object.assign(modifiedParams, {
      business_ids: params.business_ids,
    });
  }
  return function (dispatch) {
    return api
      .get(`report/offer${renderQueryString(modifiedParams)}`, {
        ignoreInterceptors: true,
      })
      .then((response) => {
        if (response.data?.data?.length === 0 && setMessage) {
          setMessage('No Data Found');
        }
        dispatch({
          type: 'GET_ALL_OFFER_REPORT_SUCCSESSFUL',
          allOffers: response.data.data,
        });
      })
      .catch((error) => {
        // console.log(error);
      });
  };
}
export function clearOfferReport() {
  return function (dispatch) {
    dispatch({
      type: 'CLEAR_OFFER_REPORT',
    });
  };
}
export function getCouponReport(params, setMessage) {
  const modifiedParams = {
    ...params,
    start_date: moment(params.start_datetime).format('YYYY-MM-DD'),
    end_date: moment(params.end_datetime).format('YYYY-MM-DD'),
  };
  delete modifiedParams.start_datetime;
  delete modifiedParams.end_datetime;
  if ('business_ids' in params) {
    Object.assign(modifiedParams, {
      business_ids: params.business_ids,
    });
  }
  return function (dispatch) {
    return api
      .get(`/report/coupon${renderQueryString(modifiedParams)}`, {
        ignoreInterceptors: true,
      })
      .then((response) => {
        if (response.data?.data?.length === 0) {
          setMessage('No Data Found');
        }
        dispatch({
          type: 'GET_ALL_COUPON_REPORT_SUCCSESSFUL',
          allCoupons: response.data.data,
        });
      })
      .catch((error) => {
        // console.log(error);
      });
  };
}
export function clearCouponReport() {
  return function (dispatch) {
    dispatch({
      type: 'CLEAR_COUPON_REPORT',
    });
  };
}
export function getLoyaltyReport(params) {
  const modifiedParams = {
    page_number: params.page_number,
    page_size: params.page_size,
    start_date: moment(params.start_datetime).format('YYYY-MM-DD'),
    end_date: moment(params.end_datetime).format('YYYY-MM-DD'),
  };
  return function (dispatch) {
    return api
      .get(
        `/report/loyalty?page_number=${modifiedParams.page_number}&page_size=${modifiedParams.page_size}&start_date=${modifiedParams.start_date}&end_date=${modifiedParams.end_date}&sort_by=business_name&sort_direction=asc`,
        {
          ignoreInterceptors: true,
        }
      )
      .then((response) => {
        dispatch({
          type: 'GET_ALL_LOYALTY_REPORT_SUCCSESSFUL',
          allLoyalties: response.data.data,
        });
      })
      .catch((error) => {
        // console.log(error);
      });
  };
}
export function clearLoyaltyReport() {
  return function (dispatch) {
    dispatch({
      type: 'CLEAR_LOYALTY_REPORT',
    });
  };
}

export function getProductReport(params) {
  const modifiedParams = {
    page_number: params.page_number,
    page_size: params.page_size,
    start_date: moment(params.start_datetime).format('YYYY-MM-DD'),
    end_date: moment(params.end_datetime).format('YYYY-MM-DD'),
    sort_by: 'product_name',
    sort_direction: 'asc',
  };
  if ('business_ids' in params) {
    Object.assign(modifiedParams, {
      business_ids: params.business_ids,
    });
  }
  return function (dispatch) {
    return api
      .get(`/report/product${renderQueryString(modifiedParams)}`, {
        ignoreInterceptors: true,
      })
      .then((response) => {
        dispatch({
          type: 'GET_ALL_PRODUCT_REPORT_SUCCSESSFUL',
          allProducts: response.data.data,
        });
      })
      .catch((error) => {
        // console.log(error);
      });
  };
}
export function clearProductReport() {
  return function (dispatch) {
    dispatch({
      type: 'CLEAR_PRODUCT_REPORT',
    });
  };
}

export function getComboReport(params) {
  const modifiedParams = {
    page_number: params.page_number,
    page_size: params.page_size,
    start_date: moment(params.start_datetime).format('YYYY-MM-DD'),
    end_date: moment(params.end_datetime).format('YYYY-MM-DD'),
    // sort_by: 'product_name',
    // sort_direction: 'asc',
  };
  if ('business_ids' in params) {
    Object.assign(modifiedParams, {
      business_ids: params.business_ids,
    });
  }
  return function (dispatch) {
    return api
      .get(`/report/combo${renderQueryString(modifiedParams)}`, {
        ignoreInterceptors: true,
      })
      .then((response) => {
        dispatch({
          type: 'GET_ALL_COMBO_REPORT_SUCCSESSFUL',
          allCombos: response.data.data,
        });
      })
      .catch((error) => {
        // console.log(error);
      });
  };
}
export function clearComboReport() {
  return function (dispatch) {
    dispatch({
      type: 'CLEAR_COMBO_REPORT',
    });
  };
}
export function getLocationReport(params) {
  const modifiedParams = {
    page_number: params.page_number,
    page_size: params.page_size,
    start_date: moment(params.start_datetime).format('YYYY-MM-DD'),
    end_date: moment(params.end_datetime).format('YYYY-MM-DD'),
    sort_by: 'business_name',
    sort_direction: 'asc',
  };
  if ('business_ids' in params) {
    Object.assign(modifiedParams, {
      business_ids: params.business_ids,
    });
  }
  return function (dispatch) {
    return api
      .get(
        `/report/business/order-summary${renderQueryString(modifiedParams)}`,
        {
          ignoreInterceptors: true,
        }
      )
      .then((response) => {
        dispatch({
          type: 'GET_ALL_LOCATION_REPORT_SUCCSESSFUL',
          allLocationOrders: response.data.data,
        });
      })
      .catch((error) => {
        // console.log(error);
      });
  };
}
export function clearLocationReport() {
  return function (dispatch) {
    dispatch({
      type: 'CLEAR_LOCATION_REPORT',
    });
  };
}
export function getCustomerReport(
  params = {
    page_number: 1,
    page_size: 2000,
    // start_date: moment(params.start_datetime).format('YYYY-MM-DD'),
    // end_date: moment(params.end_datetime).format('YYYY-MM-DD'),
    sort_direction: 'asc',
  }
) {
  // const modifiedParams = {
  //   page_number: params.page_number,
  //   page_size: params.page_size,
  //   start_date: moment(params.start_datetime).format('YYYY-MM-DD'),
  //   end_date: moment(params.end_datetime).format('YYYY-MM-DD'),
  //   sort_by: 'business_name',
  //   sort_direction: 'asc',
  // };
  return function (dispatch, getState) {
    const curentCustomersList = getState().ReportTableReducer.allCustomers;
    return api
      .get(`/report/customer${renderQueryString(params)}`, {
        ignoreInterceptors: true,
      })
      .then((response) => {
        dispatch({
          type: 'GET_ALL_CUSTOMER_REPORT_SUCCSESSFUL',
          allCustomers: curentCustomersList.concat(response.data.data),
        });
      })
      .catch((error) => {
        // console.log(error);
      });
  };
}
export function clearCustomerReport() {
  return function (dispatch) {
    dispatch({
      type: 'CLEAR_CUSTOMER_REPORT',
    });
  };
}
////// old
