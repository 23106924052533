import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import * as actions from '../productsActions';
import { connect } from 'react-redux';
import ProductWizardIndex from './components/ProductWizardIndex';
import ReactGA from 'react-ga';
const Wrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const ProductWizardContainer = (props) => {
  const [productPreviewIsVisible, setProductPreviewIsVisible] = useState(false);

  useEffect(() => {
    if (props.isEditing) {
      track.page('menu-management-product-edit');
    } else {
      track.page('menu-management-product-create');
    }
    return () => props.resetProductReducerState();
  }, []);
  useEffect(() => {
    if (productPreviewIsVisible) {
      props.setProductPreviewFlatProduct();
    } else {
      props.setProductPreviewFlatProduct(null);
    }
    ReactGA.pageview(window.location.pathname, undefined, 'Product Detail');
  }, [productPreviewIsVisible]);

  return (
    <Wrapper>
      <ProductWizardIndex
        resetProductReducerState={props.resetProductReducerState}
        getProductCategoriesIsRequesting={
          props.getProductCategoriesIsRequesting
        }
        getProductVariantsIsRequesting={props.getProductVariantsIsRequesting}
        getProductOptionsIsRequesting={props.getProductOptionsIsRequesting}
        getProductBusinessesIsRequesting={
          props.getProductBusinessesIsRequesting
        }
        productPreviewIsVisible={productPreviewIsVisible}
        setProductPreviewIsVisible={setProductPreviewIsVisible}
        productCustomizerReducer={props.productCustomizerReducer}
        appConfig={props.appConfig}
        isRequestingProductPosMappings={props.isRequestingProductPosMappings}
        orderType={props.orderType}
        selectedProduct={props.selectedProduct}
        optionWithDefault={props.optionWithDefault}
      />
    </Wrapper>
  );
};

const mapStateToProps = (state) => ({
  optionWithDefault: state.productsReducer.optionWithDefault,
  getProductCategoriesIsRequesting:
    state.productsReducer.getProductCategoriesIsRequesting,
  getProductVariantsIsRequesting:
    state.productsReducer.getProductVariantsIsRequesting,
  getProductOptionsIsRequesting:
    state.productsReducer.getProductOptionsIsRequesting,
  getProductBusinessesIsRequesting:
    state.productsReducer.getProductBusinessesIsRequesting,
  productCustomizerReducer: state.productsReducer.productCustomizerReducer,
  appConfig: state.accountReducer.appMetaData.configuration,
  isRequestingProductPosMappings:
    state.posReducer.isRequestingProductPosMappings,
  isEditing: state.productsReducer.isEditing,
  orderType: state.productsReducer.productInformationInitialValues.orderType,
});
export default connect(mapStateToProps, actions)(ProductWizardContainer);
