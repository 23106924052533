import React, { Fragment } from 'react';
import styled from 'styled-components';
import Tooltip from '@material-ui/core/Tooltip';
import { TooltipIcon } from '../../../components/Icon';
import {altTextDescription} from '../../Media/components/ImageForm';
import TextField from '@material-ui/core/TextField';
import EditImageContainer from '../../Media/containers/EditImageContainer';
import ImagePreviewPopUp from './ImagePreviewPopUp';

const Wrapper = styled.div`
    grid-column: 3;
    grid-row: 2 / span 4;
    // width: 250px;
    border-left: 1px solid #ebebeb;
    padding: 15px;
`;

const PreviewImage = styled.img`
    width: 250px;
    height: 250px;
    object-fit: cover;
    border-radius: 8%;
    margin-bottom: 10px;
    background-color: #d3d3d3;
`;

const Label = styled.div`
  font-size: 12px;
  color: #6f6f76;
  margin-top: 10px;
`;

const ToolsDivWrapper = styled.div`
    // margin: 20px 0;
    align-items: center;
    justify-content: flex-end;
    display: flex;
`;

const ToolsDiv = styled.span`
    border: 1px solid #2440ae;
    border-radius: 50%;
    padding: 5px;
    margin: 5px;
    color: #2440ae;
    cursor: pointer;
`;


const ImagePreview = ({selectedImage, 
    showEditImageForm, 
    editImageFormIsVisible, 
    deleteImageRequest,
    setSelectedImage,
    displayConfirmModal
}) => {

    const {url, name, alt_text} = selectedImage;
    return (
        <Wrapper>
            <PreviewImage src={url} />
            <ToolsDivWrapper>
                {/* <ToolsDiv onClick={() => showEditImageForm(selectedImage)}>
                    <i className="fas fa-crop" style={{ margin: '0 5px' }}></i>
                </ToolsDiv> */}
                <ImagePreviewPopUp 
                    selectedImage={selectedImage} 
                    deleteImageRequest={deleteImageRequest}
                    setSelectedImage={setSelectedImage}
                    showEditImageForm={showEditImageForm}
                    displayConfirmModal={displayConfirmModal}
                />
            </ToolsDivWrapper>
            {editImageFormIsVisible && <EditImageContainer />}
        </Wrapper>
            
    );
}

export default ImagePreview;
