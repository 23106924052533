import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../POSMapping/posAction';
import {
  getProductVariants,
  getProductInformation,
} from '../../../productsActions';
import ProductPosMappingForm from '../components/ProductPosMappingForm';
import {
  selectFormattedVariants,
  selectProductUpcs,
} from '../../../productsSelectors';

const ProductPosMappingFormContainer = (props) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const [
    confirmCancelModalIsVisible,
    setConfirmCancelModalIsVisible,
  ] = useState(false);

  return (
    <ProductPosMappingForm
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}
      disabled={!props.productId}
      initialValues={{ productTags: props.productTagsInitialValues }}
      confirmCancelModalIsVisible={confirmCancelModalIsVisible}
      setConfirmCancelModalIsVisible={setConfirmCancelModalIsVisible}
      productId={props.productId}
      productVariantsInitialValues={props.productVariantsInitialValues}
      globalVariants={props.globalVariants}
      productsMappingFields={props.productsMappingFields}
      currentProductMappingValues={props.currentProductMappingValues}
      submitProductPosMappingValues={props.submitProductPosMappingValues}
      getProductVariants={props.getProductVariants}
      upcs={props.upcs}
      getProductInformation={props.getProductInformation}
    />
  );
};

const mapStateToProps = (state) => ({
  productId: state.productsReducer.productId,
  productVariantsInitialValues:
    state.productsReducer.productVariantsInitialValues,
  productsMappingFields: state.posReducer.productsMappingFields,
  currentProductMappingValues: state.posReducer.currentProductMappingValues,
  upcs: selectProductUpcs(
    state.productsReducer.allProducts,
    state.productsReducer.productId
  ),
  globalVariants: selectFormattedVariants(state),
});

export default connect(mapStateToProps, {
  ...actions,
  getProductVariants,
  getProductInformation,
})(ProductPosMappingFormContainer);
