export const GET_SEGMENTS_REQUEST = 'GET_SEGMENTS_REQUEST';
export const GET_SEGMENTS_SUCCESS = 'GET_SEGMENTS_SUCCESS';
export const GET_SEGMENTS_FAILURE = 'GET_SEGMENTS_FAILURE';
export const GET_MAILCHIMP_LISTS_REQUEST = 'GET_MAILCHIMP_LISTS_REQUEST';
export const GET_MAILCHIMP_LISTS_SUCCESS = 'GET_MAILCHIMP_LISTS_SUCCESS';
export const NO_MAILCHIMP_LIST = 'NO_MAILCHIMP_LIST';
export const INITIATE_CREATE_SEGMENT = 'INITIATE_CREATE_SEGMENT';
export const CREATE_SEGMENT_REQUEST = 'CREATE_SEGMENT_REQUEST';
export const CREATE_SEGMENT_SUCCESS = 'CREATE_SEGMENT_SUCCESS';
export const CREATE_SEGMENT_FAILURE = 'CREATE_SEGMENT_FAILURE';
export const INITIATE_EDIT_SEGMENT = 'INITIATE_EDIT_SEGMENT';
export const CANCEL_EDIT_SEGMENT = 'CANCEL_EDIT_SEGMENT';
export const EDIT_SEGMENT_REQUEST = 'EDIT_SEGMENT_REQUEST';
export const EDIT_SEGMENT_SUCCESS = 'EDIT_SEGMENT_SUCCESS';
export const EDIT_SEGMENT_FAILURE = 'EDIT_SEGMENT_FAILURE';
export const DELETE_SEGMENT_REQUEST = 'DELETE_SEGMENT_REQUEST';
export const DELETE_SEGMENT_SUCCESS = 'DELETE_SEGMENT_SUCCESS';
export const DELETE_SEGMENT_FAILURE = 'DELETE_SEGMENT_FAILURE';
export const UPDATE_SEGMENTS_REQUEST_PARAMS = 'UPDATE_SEGMENTS_REQUEST_PARAMS';
export const UPDATE_SELECTED_LOCATIONS = 'UPDATE_SELECTED_LOCATIONS';
export const RESET_LOCATIONS = 'RESET_LOCATIONS';
export const TARGET_AUDIENCE_PREVIEW_REQUEST =
  'TARGET_AUDIENCE_PREVIEW_REQUEST';
export const TARGET_AUDIENCE_PREVIEW_SUCCESS =
  'TARGET_AUDIENCE_PREVIEW_SUCCESS';
export const TARGET_AUDIENCE_PREVIEW_FAILURE =
  'TARGET_AUDIENCE_PREVIEW_FAILURE';
export const SET_LOCATIONS = 'SET_LOCATIONS';
export const INITIATE_CLONE_SEGMENT = 'INITIATE_CLONE_SEGMENT';
export const UPDATE_SORTED_SEGMENTS_LIST = 'UPDATE_SORTED_SEGMENTS_LIST';
