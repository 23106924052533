import React, { Fragment, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Formik, Field } from 'formik';
import moment from 'moment';
import {
  RenderTimePicker,
  RenderSelect,
} from '../../../components/FormElements/FormikElements';
import { Button } from 'merchant-portal-components';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import TabPromo from './TabPromo';

const ModalTitle = styled.div`
  display: grid;
  grid-template-columns: 10fr 1fr;
`;

const LineDivider = styled.hr`
  border: solid 1px #e1e1e1;
`;

const ButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 10px 20px;
  width: 100%;

  > :last-child {
    justify-self: right;
  }

  > :first-child {
    justify-self: left;
  }
`;

const HoursSelectionGrid = styled.div`
  display: grid;
  grid-column-gap: 25px;
  align-items: center;
  margin-top: 25px;
  grid-template-columns: 160px 250px auto;
  grid-gap: 25px;
  min-height: 75px;
  padding-bottom: 15px;

  .error-entry {
    border: 1px solid red;
  }
  .disabled {
    pointer-events: none;
    opacity: 0.5;
  }
`;

const TimeSelectionGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
`;

const DayName = styled.p`
  font-weight: 600;
  align-self: center;
  text-transform: capitalize;
  font-size: 14px !important;
`;

const HoursField = styled.div`
  text-align: left;

  .rw-popup-container.rw-dropup {
    z-index: 99999 !important;
    position: absolute;
    top: 100%;
    left: 0;
  }
  span.labelHour {
    display: block;
    margin-top: -30px;
  }
  input {
    height: 56px;
  }
`;
const PromoModal = ({
  //comboAvailabilities,
  setFieldValue,
  toggleAvailableHoursModal,
  setAvailabilityModalState,
  handleReset,
  values,
  selectedPromoCode,
  setSelectedPromoCode,
}) => {
  return (
    <Dialog open fullWidth maxWidth='sm'>
      <DialogTitle>
        <ModalTitle>
          <div>{selectedPromoCode ? 'Edit' : 'Add'} Promo Codes</div>
          <div
            onClick={() => toggleAvailableHoursModal()}
            style={{ cursor: 'pointer' }}
          >
            x
          </div>
        </ModalTitle>
      </DialogTitle>
      <DialogContent>
        <TabPromo
          values={values}
          setFieldValue={setFieldValue}
          setAvailabilityModalState={setAvailabilityModalState}
          selectedPromoCode={selectedPromoCode}
          setSelectedPromoCode={setSelectedPromoCode}
        ></TabPromo>
      </DialogContent>
    </Dialog>
  );
};

export default PromoModal;
