import React from 'react';
import styled from 'styled-components';

const POSListWrapper = styled.div`
  display: grid;
  grid-gap: 5px;
`;

const ProductPosMappingSummary = ({
  variantsToRender,
  currentProductMappingValues,
  productsMappingFields,
}) => {
  if (currentProductMappingValues['mappings'] === undefined) return null;
  return (
    <POSListWrapper>
      {variantsToRender.map((variant, index) => {
        const raw_values =
          currentProductMappingValues['mappings'][variant.variantId] || [];
        const values = Object.keys(productsMappingFields).reduce((acc, key) => {
          if (raw_values[key] && raw_values[key] != '') {
            acc.push(raw_values[key]);
          }
          return acc;
        }, []);
        return (
          <div key={index}>
            {variant.variantName}, {values.join(', ')}
          </div>
        );
      })}
    </POSListWrapper>
  );
};

export default ProductPosMappingSummary;
