import React, {Component} from 'react';
import {Doughnut} from 'react-chartjs-2';
import {
  NoData,
  ChartCard,
  TitleWrapper,
  Title,
  TotalWrapper,
  TitleSpan
} from '../../components/DashboardElements';
import styled from 'styled-components';
import 'chart.piecelabel.js';
import Tooltip from '@material-ui/core/Tooltip';
import {TooltipIcon} from '../../../../components/Icon';

export default class ReferralStats extends Component {
  render() {
    const {referralStatsData} = this.props;
    const {total_referrals, total_converted} = referralStatsData;

    var converted = (total_converted / total_referrals) * 100;
    var convertedPercentage = Math.round(converted); //parseFloat(parseFloat(converted).toFixed(2));
    var nonConverted = 100 - convertedPercentage;
    if (Number.isNaN(convertedPercentage)) convertedPercentage = 0;
    if (Number.isNaN(nonConverted)) nonConverted = 0;

    const data = {
      labels: ['Converted Referrals', 'Unconverted Referrals'],
      datasets: [
        {
          data: [convertedPercentage, nonConverted],
          backgroundColor: ['#296bde', '#00a5db'],
          hoverBackgroundColor: ['#296bde', '#00a5db']
        }
      ]
    };

    return (
     
      <ChartCard>
        <TitleWrapper>
          <Title>
            <TitleSpan>
              Referral Stats {' '}
              <Tooltip title="The number of users acquired through referral sites" placement="top" arrow>
              <span><TooltipIcon/></span>
              </Tooltip>
            </TitleSpan> <br />
          </Title>
        </TitleWrapper>
        <ChartWrapper>
          <ChartLegend>
            <div>
              <div>
                <svg height="25" width="37.7">
                  <line
                    x1="0"
                    y1="25"
                    x2="37.7"
                    y2="25"
                    style={{stroke: '#296bde', strokeWidth: '27'}}
                  />
                </svg>{' '}
                <span> Converted Referrals </span>
              </div>
              <div>
                <svg height="25" width="37.7">
                  <line
                    x1="0"
                    y1="25"
                    x2="37.7"
                    y2="25"
                    style={{stroke: '#00a5db', strokeWidth: '27'}}
                  />
                </svg>{' '}
                <span> Unconverted Referrals </span>
              </div>
            </div>
            <div>
              <div> Total Referral </div>
              <TitleSpan> {total_referrals.toLocaleString()} </TitleSpan>
              <div> Converted Referral </div>
              <TitleSpan> {total_converted.toLocaleString()} </TitleSpan>
            </div>
          </ChartLegend>
          <div style={{minWidth: '100%', alignSelf: 'center'}}>
            {total_referrals == 0 && total_converted == 0 ? (
              <Doughnut
                data={{
                  datasets: [
                    {
                      data: [0, 100],
                      backgroundColor: ['#296bde', '#979eaa'],
                      hoverBackgroundColor: ['#296bde', '#979eaa']
                    }
                  ]
                }}
                options={{
                  legend: {
                    display: false
                  },
                  pieceLabel: {
                    //showZero: true,
                    render: 'percentage',
                    fontColor: '#000',
                    position: 'outside',
                    render: function(args) {
                      return '0%';
                    }
                  },
                  tooltips: {
                    enabled: false
                  }
                }}
              />
            ) : (
              <Doughnut
                data={data}
                options={{
                  legend: {
                    display: false
                  },
                  pieceLabel: {
                    render: 'percentage',
                    fontColor: '#000',
                    position: 'outside'
                  },
                  tooltips: {
                    display: false,
                    custom: tooltipModel => {
                      tooltipModel.opacity = 0;
                      return;
                    }
                    // callbacks: {
                    // 	title: function(tooltipItem, data) {
                    // 	 return data['labels'][tooltipItem[0]['index']];
                    //  },
                    // 	label: function(tooltipItem, data) {
                    //     return data['datasets'][0]['data'][tooltipItem['index']]+'%';
                    //   },
                    // afterLabel: function(tooltipItem, data){
                    // 	return '%';
                    // }
                    //}
                  }
                }}
              />
            )}
          </div>
        </ChartWrapper>
      </ChartCard>
    );
  }
}

const ChartWrapper = styled.div`
  display: grid;
  grid-template-columns: 3fr 10fr;
  height: 90%;
`;
const ChartLegend = styled.div`
  color: ${props => props.theme.darkBlue};
  font-size: 12px;
  align-items: center;
  grid-template-rows: 3fr 5fr;
  display: grid;
`;

const LegendWrapper = styled.div`
  align-items: center;
  grid-template-rows: 3fr 5fr;
  display: grid;
`;
