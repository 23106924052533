export const eventFormat = {
  loyalty_global_credit_awarded: 'loyalty',
  loyalty_points_awarded: 'loyalty',
  referral_points_awarded: 'referrals',
  referral_points_received: 'referrals',
  points_transfer_created: 'pointsTransfer',
  points_transfer_claimed: 'pointsTransfer',
  points_transfer_cancelled: 'pointsTransfer',
  points_transfer_expired: 'pointsTransfer',
  order_status_submitted: 'orderStatus',
  order_status_accepted: 'orderStatus',
  order_status_completed: 'orderStatus',
  order_status_on_delivery: 'orderStatus',
  order_status_delivered: 'orderStatus',
  order_status_refunded: 'orderStatus',
  order_status_rejected: 'orderStatus',
};

export const transformAutomatedMessageList = (automatedMessagesList) => {
  const finalDataStructure = {
    loyalty: [],
    referrals: [],
    pointsTransfer: [],
    orderStatus: [],
  };
  automatedMessagesList.map((automatedMessage) => {
    const eventType = automatedMessage.notification_type.event_type;
    finalDataStructure[eventFormat[eventType]].push(automatedMessage);
  });
  const automatedMessagesObj = formatAutomatedMessageList(finalDataStructure);

  const existingAutomatedMessages = {};
  if (automatedMessagesObj.referrals.data.length) {
    existingAutomatedMessages.referrals = automatedMessagesObj.referrals;
  }
  if (automatedMessagesObj.loyalty.data.length) {
    existingAutomatedMessages.loyalty = automatedMessagesObj.loyalty;
  }
  if (automatedMessagesObj.pointsTransfer.data.length) {
    existingAutomatedMessages.pointsTransfer =
      automatedMessagesObj.pointsTransfer;
  }
  if (automatedMessagesObj.orderStatus.data.length) {
    existingAutomatedMessages.orderStatus = automatedMessagesObj.orderStatus;
  }
  return { ...existingAutomatedMessages };
};

export const formatAutomatedMessageList = (dataStructure) => {
  Object.keys(dataStructure).map((obj) => {
    if (obj === 'loyalty')
      dataStructure[obj] = {
        data: dataStructure[obj],
        title: 'Loyalty',
        description: 'Update customers on their loyalty progress',
      };
    else if (obj === 'referrals')
      dataStructure[obj] = {
        data: dataStructure[obj],
        title: 'Referrals',
        description: 'Update customers on referral bonuses',
      };
    else if (obj === 'orderStatus')
      dataStructure[obj] = {
        data: dataStructure[obj],
        title: 'Order Status Updates',
        description: 'Send customers updates on the progress of their orders',
      };
    else if (obj === 'pointsTransfer')
      dataStructure[obj] = {
        data: dataStructure[obj],
        title: 'Point Transfers',
        description: 'Send customers updates about point transfer activity',
      };
    else return dataStructure;
  });
  return dataStructure;
};
