//modify data structure to adapt with api and design
export const renderData = (templateList, businessList) => {
  return businessList.map(business => ({
    ...business,
    selectedTemplate: templateList.find(template => {
      let businesses = {
        ...template,
        businesses: template.businesses.map(business => business.business_id)
      };
      return businesses.businesses.includes(business.id);
    })
      ? templateList.find(template => {
          let businesses = {
            ...template,
            businesses: template.businesses.map(
              business => business.business_id
            )
          };
          return businesses.businesses.includes(business.id);
        })
      : null
  }));
};
