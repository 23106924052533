import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Table, Tr, TrHead, Th, TBody, Td } from 'merchant-portal-components';
import PaginationControl from '../../../components/Pagination/PaginationControl';
import Checkbox from '@material-ui/core/Checkbox';
import { TooltipIcon } from '../../../components/Icon';
import { Tooltip, Switch } from '@material-ui/core';

const PaginationWrapper = styled.div`
  padding: 15px 45px;
  border-top: 1px solid lightgray;
`;
const TableWrapper = styled.div`
  overflow-x: auto;
  border-radius: 12px;
`;

const renderList = (
  offerPromoCode,
  requestParams,
  setFieldValue,
  setSelectedPromoCode,
  setAvailabilityModalState
) => {
  return (
    <TableWrapper>
      <Table style={{ border: 'none' }}>
        <TBody>
          {renderTableHeader(requestParams, setAvailabilityModalState)}
          {offerPromoCode.length &&
            requestParams &&
            renderTableRows(
              offerPromoCode,
              setFieldValue,
              setSelectedPromoCode,
              setAvailabilityModalState
            )}
        </TBody>
      </Table>
    </TableWrapper>
  );
};

const renderTableHeader = (requestParams, setAvailabilityModalState) => {
  return (
    <TrHead>
      <Th
        align='left'
        value='code'
        sortingOption={requestParams.sorting_option}
        style={{ paddingLeft: '37px' }}
      >
        #
      </Th>
      <Th align='left'>Code</Th>
      <Th align='left'>Used</Th>
      <Th align='left'>Shareable</Th>
      <Th
        align='right'
        // sortingOption={requestParams.sorting_option}
        style={{ paddingRight: '45px' }}
      ></Th>
    </TrHead>
  );
};
const renderTableRows = (
  offerPromoCode,
  setFieldValue,
  setSelectedPromoCode,
  setAvailabilityModalState
) => {
  return offerPromoCode.map((promo, index) => (
    <Tr
      key={index}
      style={{ height: '78px', color: '#191818', cursor: 'pointer' }}
      onClick={() => {
        // setSelectedPromoCode({ promo: promo, index: index });
        // setAvailabilityModalState(true);
      }}
    >
      <Td padding={'0px 0px 0px 37px'}>{index + 1}</Td>
      <Td>{promo.code}</Td>
      <Td>
        <Checkbox
          checked={promo.is_used}
          color='primary'
          disabled
          // onChange={(e) => handleChangeIsShareable(e, i)}
        />
      </Td>
      <Td>
        <Checkbox
          checked={promo.is_shareable}
          color='primary'
          disabled
          // onChange={(e) => handleChangeIsShareable(e, i)}
        />
      </Td>
      <Td padding={'0px 37px 0px 0px'} style={{ textAlign: 'right' }}>
        <i
          class='fal fa-trash-alt'
          onClick={() => {
            const newOfferPromoCode = [...offerPromoCode];
            newOfferPromoCode.splice(index, 1);
            setFieldValue('offerPromoCode', newOfferPromoCode);
          }}
        ></i>
      </Td>
    </Tr>
  ));
};

const PromoList = ({
  offerPromoCode,
  requestParamsPromo,
  getPromos,
  setFieldValue,
  setSelectedPromoCode,
  setAvailabilityModalState,
}) => {
  return (
    <Fragment>
      {renderList(
        offerPromoCode,
        requestParamsPromo,
        setFieldValue,
        setSelectedPromoCode,
        setAvailabilityModalState
      )}
      <PaginationWrapper>
        <PaginationControl
          response={{
            ...offerPromoCode,
            page: requestParamsPromo.page_number,
            page_size: requestParamsPromo.page_size,
            count: offerPromoCode.length,
          }}
          requestParams={requestParamsPromo}
          updateAction={getPromos}
          type='promo'
        />
      </PaginationWrapper>
    </Fragment>
  );
};

export default PromoList;
