import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
const BannerWrapper = styled.div`
  display: grid;
  position: relative;
  grid-template-columns: 30px 1fr;
  grid-gap: 10px;
  background-color: ${(props) => (props.isSummary ? '#e3f3eb' : '#f9ecdb')};
  margin: 15px 0;
  padding: 15px;
  border-radius: 4px;
`;
const BannerIcon = styled.i`
  color: ${(props) => (props.isSummary ? '#00a57b' : '#e89623')};
  text-align: center;
  margin-top: 3px;
`;
const CloseIcon = styled.i`
  position: absolute;
  color: #191818;
  top: 15px;
  right: 15px;
  cursor: pointer;
`;
const BannerContent = styled.div`
  padding-right: 36px;
`;
const TitleWrapper = styled.div`
  font-family: Montserrat;
  line-height: 22px;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #191818;
  vertical-align: middle;
  margin-bottom: 15px;
`;
const DecriptionWrapper = styled.p`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.01px;
  color: #191818;
  text-align: justify;
  margin-bottom: 15px;
`;
const ProcedureWrapper = styled.p`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.01px;
  color: #191818;
  text-align: justify;
`;

const CustomLink = styled(Link)`
  height: 18px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.01px;
  color: #191818;
  text-decoration: underline;
  &:hover {
    text-decoration: underline;
    color: #191818;
    font-style: italic;
  }
`;

const SettlmentBanner = ({ isSummary, removeBanner }) => {
  return (
    <BannerWrapper isSummary={isSummary}>
      <BannerIcon
        isSummary={isSummary}
        className={
          isSummary ? 'fas fa-info-circle' : 'fas fa-exclamation-triangle'
        }
      ></BannerIcon>
      {isSummary ? (
        <BannerContent>
          <TitleWrapper>
            Introducing the new Transaction Summary Report!
          </TitleWrapper>
          <DecriptionWrapper>
            We have introduced the Transaction Summary Report that will generate
            reports in the duration you want and runs reports in the store's
            time zone. With this change we will be removing the Settlement
            Report from the Smooth Portal. Going forward please use the new
            Transaction Summary Report and let us know your feedback.
          </DecriptionWrapper>
          <ProcedureWrapper>
            If you haven't tried it yet generate your first report now!
          </ProcedureWrapper>
        </BannerContent>
      ) : (
        <BannerContent>
          <TitleWrapper>
            Settlements is being replaced with the new Transaction Summary
            Report! We will sunset the Settlements report on August 8th, 2022.
          </TitleWrapper>
          <DecriptionWrapper>
            We have introduced the Transaction Summary Report that will generate
            reports in the duration you want and runs reports in the store's
            time zone. With this change we will be removing the Settlement
            Report from the Smooth Portal. Going forward please use the new
            Transaction Summary Report and let us know your feedback.
          </DecriptionWrapper>
          <ProcedureWrapper>
            If you haven't tried it yet generate your first report now!{' '}
            <CustomLink to="summary-report">Try now</CustomLink>
          </ProcedureWrapper>
        </BannerContent>
      )}
      <CloseIcon className="fal fa-times" onClick={removeBanner}></CloseIcon>
    </BannerWrapper>
  );
};

export default SettlmentBanner;
