import React from 'react';
import { Modal } from 'merchant-portal-components';
import { Button } from 'merchant-portal-components';
import styled from 'styled-components';
import { Title, ModalBody } from 'merchant-portal-components';

const HorizontalLine = styled.hr`
  width: 100%;
  border: 0;
  border-top: 2px solid #d8d8d8;
  opacity: 0.4;
`;

const Content = styled.div`
  padding: 0 150px;
`;

const Notes = styled.div``;

const StyledSpan = styled.span`
  font-weight: 600;
`;

const CheckboxWrapper = styled.div`
  margin: 30px 60px;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 30px;
`;

const Label = styled.div`
  align-self: center;
`;

const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  padding: 20px 0 0 0;
`;

const AccountActivationConfirmModal = (props) => {
  const {
    accountIsActive,
    customerName,
    handleAccountActivationCheckboxToggle,
    accountActivationCheckboxIsChecked,
    accountActivationConfirmModalToggleVisibility,
    handleAccountActivationSubmit,
    patchAcountIsActiveIsRequesting,
  } = props;

  return (
    <Modal data-test="component-Modal">
      <div className="modal">
        <ModalBody padding="30px 0 40px 0">
          <Title>... are you sure?</Title>
          <HorizontalLine />
          <Content>
            <Notes>
              You are about to{' '}
              <StyledSpan>
                {accountIsActive ? 'Deactivate' : 'Activate'} {customerName}{' '}
              </StyledSpan>{' '}
              Account. {customerName} will{' '}
              {accountIsActive ? 'no longer be' : null} able to order from you.
            </Notes>
            <CheckboxWrapper>
              <input
                style={{ alignSelf: 'center' }}
                type="checkbox"
                checked={accountActivationCheckboxIsChecked}
                onChange={() => handleAccountActivationCheckboxToggle()}
              />
              <Label>Yes, I understand</Label>
            </CheckboxWrapper>
            <ButtonWrapper>
              <Button
                secondary
                onClick={accountActivationConfirmModalToggleVisibility}
              >
                Cancel
              </Button>
              <Button
                disabled={
                  !accountActivationCheckboxIsChecked ||
                  patchAcountIsActiveIsRequesting
                }
                onClick={handleAccountActivationSubmit}
              >
                {patchAcountIsActiveIsRequesting && accountIsActive
                  ? 'Deactivating...'
                  : patchAcountIsActiveIsRequesting
                  ? 'Activating...'
                  : accountIsActive
                  ? 'Deactivate'
                  : 'Activate'}
              </Button>
            </ButtonWrapper>
          </Content>
        </ModalBody>
      </div>
    </Modal>
  );
};

export default AccountActivationConfirmModal;
