import {SELECT_BUSINESS} from './types';

export default function(
  state = {
    selectedBusiness: false
  },
  action
) {
  switch (action.type) {
    case SELECT_BUSINESS:
      return {
        ...state,
        selectedBusiness: action.business
      };
    default:
      return state;
  }
}
