import React, { Fragment } from 'react';
import styled from 'styled-components';
import logo from '../../../../../public/img/smooth-commerce-logo.png';
import Moment from 'moment';
import { TransactionsTitle } from 'merchant-portal-components';

// font-size: 1.25rem was it's original size with bootstrap

const Logo = styled.img`
  height: 50px;
  margin-bottom: 8px;
`;

const StyledP = styled.p`
  margin: 0;
  word-break: break-word;
  @media print {
    font-size: 14px;
    padding: ${(props) => props.printPadding || 'inherit'};
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : 'space-between'};
`;

const toPriceFormat = (intPrice) => {
  return new Intl.NumberFormat('en-EN', {
    style: 'currency',
    currency: 'USD',
  }).format(Math.abs(intPrice));
};

const BatchReportHeader = (props) => {
  const {
    batchDate,
    batchReportBusinessName,
    batchSettlementId,
    batchReportBusinessAddress,
    batchAmount,
    batchBusinessesIncluded,
    businessPluralOrSingular,
    businessString,
    batchTransactionPeriodBegin,
    batchTransactionPeriodEnd,
    title,
    detailsAreHidden,
  } = props;
  return (
    <div>
      <Row>
        <Logo src={logo} alt="Smooth Commerce" />
        {Moment(batchDate).format('MM/DD/YYYY')}
      </Row>
      {!detailsAreHidden && (
        <Fragment>
          <Row>
            <StyledP>{batchReportBusinessName}</StyledP>
            {batchSettlementId.length < 94 ? (
              <StyledP>Batch ID: {batchSettlementId}</StyledP>
            ) : null}
          </Row>
          <Row>
            <StyledP>{batchReportBusinessAddress}</StyledP>
            <StyledP>Batch Amount: {toPriceFormat(batchAmount)}</StyledP>
          </Row>
          <Row>
            <StyledP>
              {batchBusinessesIncluded.length} {businessPluralOrSingular}{' '}
              included in report <br /> ({businessString})
            </StyledP>
            <StyledP>
              Transaction Period:&nbsp;
              {Moment(batchTransactionPeriodBegin).format('MM/DD/YYYY')}
              &nbsp;-&nbsp;
              {Moment(batchTransactionPeriodEnd).format('MM/DD/YYYY')}
            </StyledP>
          </Row>
        </Fragment>
      )}
      <TransactionsTitle>{title}</TransactionsTitle>
    </div>
  );
};

export default BatchReportHeader;
