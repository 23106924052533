import React from 'react';
import styled from 'styled-components';
import {CardTitle, CardSubtitle, CardDescription} from './FeedPreviewElements';
import ListTitleAndAction from './ListTitleAndAction';
import CardButton from './CardButton';

const ListCard = ({row, compact}) => {
  const ListCardWrapper = styled.div`
    border-radius: ${props => props.theme.feedStyles.corner_radius}px;
    border-width: ${props => props.theme.feedStyles.border_width}px;
    border-style: solid;
    border-color: ${props =>
      props.theme.feedStyles.border_color
        ? props.theme.feedStyles.border_color
        : 'transparent'};
    overflow: hidden;
    padding: 10px 10px 22px 10px;
    background: ${props => props.theme.white};
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    margin: 12px;
  `;

  const TopRow = styled.div`
    display: grid;
    grid-template-columns: 2fr 1fr;
    align-items: flex-start;
    line-height: 18px;
    margin-bottom: 16px;
  `;

  const ItemWrapper = styled.div`
    display: flex;
    height: 50px;
    align-items: center;
    margin-bottom: 2px;
  `;

  const ThumbnailWrapper = styled.div`
    width: 38px;
    height: 38px;
    border-radius: 50%;
    background-image: url(${props => props.image});
    background-size: cover;
  `;

  const ItemWordsWrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-bottom: 1px solid lightgray;
    flex-grow: 1;
    padding-left: 12px;
    p {
      font-size: 12px;
      margin: 0;
      line-height: 14px;
      font-weight: 500;
    }
  `;

  const ListTitle = styled.p`
    font-size: 16px;
    font-weight: 800;
    margin: 0;
  `;

  const ListActionLabel = styled.p`
    color: #fcb65a;
    font-size: 13px;
    text-align: right;
    margin: 0;
  `;

  const renderListItems = () => {
    return row.items.map(item => {
      if (item.is_visible) {
        return (
          <ItemWrapper key={item.id}>
            <ThumbnailWrapper image={item.thumbnail_image_url} />
            <ItemWordsWrapper>
              <p>{item.title}</p>
              <p>{item.subtitle}</p>
            </ItemWordsWrapper>
          </ItemWrapper>
        );
      }
    });
  };

  if (row.is_visible) {
    return (
      <ListCardWrapper>
        <TopRow>
          <ListTitle>{row.title}</ListTitle>
          <ListActionLabel>{row.list_action_label}</ListActionLabel>
        </TopRow>
        {renderListItems()}
      </ListCardWrapper>
    );
  } else {
    return null;
  }
};

export default ListCard;
