import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Table, Tr, TrHead, Th, TBody, Td } from 'merchant-portal-components';
import PaginationControl from '../../../../components/Pagination/PaginationControl';
import { TooltipIcon } from '../../../../components/Icon';
import { Tooltip, Switch } from '@material-ui/core';

const PaginationWrapper = styled.div`
  padding: 15px 45px;
  border-top: 1px solid lightgray;
`;
const TableWrapper = styled.div`
  overflow-x: auto;
  border-radius: 12px;
`;
const TitleWrapper = styled.div`
  font-weight: 600;
  margin: 0 45px 45px 35px;
  font-size: 20px;
`;
const NotFaundWrapper = styled.div`
  text-align: center;
  font-weight: 500;
`;
const HorizontalLine = styled.hr`
  border: 0;
  border-top: 2px solid #d8d8d8;
  opacity: 0.4;
  margin: 50px 37px;
`;
const renderList = (
  allOrders,
  requestParams,
  navigateToOrderHistoryDetailPage
) => {
  return (
    <TableWrapper>
      <Table style={{ border: 'none' }}>
        <TBody>
          {renderTableHeader(requestParams)}
          {allOrders.length &&
            requestParams &&
            renderTableRows(allOrders, navigateToOrderHistoryDetailPage)}
        </TBody>
      </Table>
    </TableWrapper>
  );
};

const renderTableHeader = (requestParams) => {
  return (
    <TrHead>
      <Th
        align='left'
        value='name'
        sortingOption={requestParams.sorting_option}
        style={{ paddingLeft: '45px', fontSize: '16px' }}
      >
        Order Number
      </Th>
      <Th align='left' style={{ paddingLeft: '45px', fontSize: '16px' }}>
        Order Date
      </Th>
    </TrHead>
  );
};
const renderTableRows = (allOrders, navigateToOrderHistoryDetailPage) => {
  return allOrders.map((order, index) => (
    <Tr
      key={index}
      style={{ height: '45px', color: '#191818', cursor: 'pointer' }}
      onClick={() => navigateToOrderHistoryDetailPage(order.order_id)}
    >
      <Td padding={'0px 0px 0px 45px'}>{order.order_id}</Td>
      <Td>{order.order_date}</Td>
    </Tr>
  ));
};

const CustomerOrderHistory = ({
  allOrders,
  requestParams,
  getCustomerOrders,
  history,
  updateOrderId,
  updateCurrentPage,
}) => {
  const navigateToOrderHistoryDetailPage = (orderId) => {
    updateOrderId(orderId);
    updateCurrentPage('detail');
    history.push('/order-history');
  };
  return (
    <Fragment>
      <TitleWrapper>Customer Order History</TitleWrapper>
      {allOrders?.length ? (
        <>
          {renderList(
            allOrders,
            requestParams,
            navigateToOrderHistoryDetailPage
          )}
          <PaginationWrapper>
            <PaginationControl
              response={{
                ...allOrders,
                page: requestParams.page_number,
                page_size: requestParams.page_size,
                count: allOrders,
              }}
              requestParams={requestParams}
              updateAction={getCustomerOrders}
              type='variants'
            />
          </PaginationWrapper>
        </>
      ) : (
        <NotFaundWrapper>No Record Found</NotFaundWrapper>
      )}
      <HorizontalLine />
    </Fragment>
  );
};

export default CustomerOrderHistory;
