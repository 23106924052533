import React, { Component, Fragment } from 'react';
import { Field, FieldArray } from 'redux-form';
import {
  renderTextField,
  renderSelect,
  required,
  maxLength,
  urlValidation,
} from '../../../components/FormElements/ReduxForm';
import { Label, ModalBody } from 'merchant-portal-components';
import { ButtonRow, Button } from 'merchant-portal-components';
import CardsList from '../CardsList';
import ToggleSwitch from '../../../components/ToggleSwitch/ToggleSwitch';
import CardForm from './CardForm';
import { Modal } from 'merchant-portal-components';
import styled from 'styled-components';
import SingleItemCard from './FeedPreview/SingleItemCard';
import HorizontalList from './FeedPreview/HorizontalList';
import { multipleAppFeedItemClients } from '../../../utils/clientCustomizations';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
// import { useState } from 'react';

const DatePickerWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  width: 100%;
`;
const Icon = styled.i`
  cursor: pointer;
  color: #5c5959;
`;
const ItemsTitleWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 120px;
  align-items: center;
  /* border-bottom: 2px solid #d9d9d9; */
  padding-bottom: 7px;
  outline: none;
`;
const AppFeedItemTitle = styled.h4`
  font-weight: 600;
  margin-left: 5px;
`;
const ItemTitle = styled.h5`
  font-weight: 500;
  margin-left: 5px;
`;
const ItemRow = styled.div`
  padding: 7px 5px;
  display: grid;
  grid-template-columns: 1fr 20px;
  align-items: center;
  background-color: ${(props) =>
    props.index % 2 === 0 ? '#d9d9d9' : '#e3e1e1'};
`;
const CustomAddButton = styled.span`
  max-width: 120px;
  font-size: 12px;
  padding: 5px 10px;
  background-color: #2440ae;
  color: white;
  font-weight: 500px;
  border-radius: 25px;
  text-align: center;
  cursor: pointer;
  &:hover {
    background-color: #3050d4;
  }
`;
const AllOffersCard = styled.div`
  background: white;
  border-radius: 2px;
  // border: 1px solid lightgray;
  display: inline-block;
  height: 46px;
  line-height: 46px;
  margin: 5px;
  margin-left: 0;
  position: relative;
  width: 100%;
  padding: 0px 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  // cursor: ${(props) => (props.sortDisabled ? 'not-allowed' : 'move')};
`;

const MultiSelectWrapper = styled.div`
  width: 400px;
`;

const ALL_OFFERS = 'all_offers';

const maxLength32 = maxLength(32);
const maxLength50 = maxLength(50);
const maxLength512 = maxLength(512);
class FeedRowModal extends React.Component {
  state = {
    fieldTouched: false,
    isInternalLabelNotValid: true,
    isSectionLabelNotValid: true,
    isExpanded: [],
    startDateValue: null,
    endDateValue: null
  };
  componentDidMount() {
    if (this.props.formValues.items.length > 0) {
      let isExpanded = this.props.formValues?.items.map(() => false);
      this.setState({ isExpanded });
    }
  }
  componentDidUpdate(prevProps, prevState) {}
  validateInternalLabel = (value) => {
    let error = '';
    if (!value) {
      this.setState({ isInternalLabelNotValid: false });
    } else {
      if (value.toString().length >= 51) {
        this.setState({ isInternalLabelNotValid: true });
        error = 'No more than 50 Characters';
      } else {
        this.setState({ isInternalLabelNotValid: false });
        error = '';
      }
    }
    this.forceUpdate();
    return error;
  };
  validateSectionLabel = (value) => {
    let error = '';
    if (!value) {
      this.setState({ isSectionLabelNotValid: false });
    } else {
      if (value.toString().length >= 51) {
        this.setState({ isSectionLabelNotValid: true });
        error = 'No more than 50 Characters';
      } else {
        this.setState({ isSectionLabelNotValid: false });
        error = '';
      }
    }
    this.forceUpdate();
    return error;
  };
  addNewItem = () => {
    let newFormValueItems = [...this.props.formValues.items];
    const emptyItem = {
      id: null,
      position: 0,
      name: '',
      title: null,
      subtitle: null,
      description: null,
      background_image_url: '',
      thumbnail_image_url: null,
      button_label: null,
      button_action: null,
      card_action: '',
      is_visible: true,
    };
    newFormValueItems.push(emptyItem);
    this.props.change('items', newFormValueItems);
  };
  showOrHideListFields = () => {
    // const { feed_style_type } = this.props.formValues;
    // if (
    //   feed_style_type !== "single_card" &&
    //   feed_style_type !== "single_compact_card"
    // )

    return (
      <Fragment>
        <Field
          label='Section Title'
          name='title'
          component={renderTextField}
          type='text'
          marginTop='15px'
          validate={this.validateSectionLabel}
        />

        {/* <Field
          label="List Action Label"
          name="list_action_label"
          component={renderTextField}
          type="text"

          // validate={[required, maxLength32]}
        />
        <Field
          label="List Action URL"
          name="list_action"
          component={renderTextField}
          type="text"
          // validate={[required, maxLength512, urlValidation]}
        /> */}
      </Fragment>
    );
  };
  updateIsExpanded = (index) => {
    const newIsExpanded = this.state.isExpanded.reduce(
      (acc, curr, currIndex) => {
        if (currIndex === index) {
          acc.push(!curr);
        } else {
          acc.push(false);
        }
        return acc;
      },
      []
    );
    this.setState({ isExpanded: newIsExpanded });
  };
  renderTextFieldArrayOrCardForm = () => {
    const { formValues } = this.props;
    const { isExpanded } = this.state;
    const { feed_type } = formValues;
    if (feed_type === ALL_OFFERS) {
      return <AllOffersCard>Your Offers</AllOffersCard>;
    }
    return (
      <FieldArray
        light
        name='items'
        component={CardsList}
        changed={(value) => this.setState({ fieldTouched: value })}
        {...this.props}
      />
    );
    // return formValues?.items.map((item, index) => (
    //   <>
    //     <ItemRow key={`app-feed-item-${index}`} index={index}>
    //       <ItemTitle>{item.title || 'No Title'}</ItemTitle>
    //       <Icon
    //         className='fa fa-pencil'
    //         onClick={() => this.updateIsExpanded(index)}
    //       />
    //     </ItemRow>
    //     <Collapse in={isExpanded[index]}>
    //       <FieldArray
    //         light
    //         name='items'
    //         component={CardsList}
    //         changed={(value) => {
    //           this.setState({ fieldTouched: value });
    //         }}
    //       />
    //     </Collapse>
    //   </>
    // ));
  };

  renderCardPreview = () => {
    const { formValues } = this.props;
    const { feed_style_type } = formValues;
    if (feed_style_type === 'single_card') {
      return (
        <div style={{ height: '266px', width: '246px' }}>
          <SingleItemCard preview row={this.props.formValues} />
        </div>
      );
    } else if (feed_style_type === 'single_compact_card') {
      return (
        <div style={{ height: '94px', width: '246px' }}>
          <SingleItemCard preview row={this.props.formValues} compact />
        </div>
      );
    } else if (feed_style_type === 'horizontal_cards') {
      return (
        <div style={{ height: '198px', width: '246px' }}>
          <HorizontalList row={this.props.formValues} />
        </div>
      );
    }
  };

  render() {
    const {
      requiredFeedRow,
      cancel,
      allSegments,
      allTags,
      formValues,
      handleSubmit,
      appIdentifier,
      isSuperAdmin,
      feedRowIsEditing,
    } = this.props;
    const { feed_style_type } = formValues;
    let feedStyleTypeOptions = multipleAppFeedItemClients.includes(
      appIdentifier
    )
      ? [
        {
          value: 'horizontal_cards',
          label: 'Horizontal List of Cards',
        },
        { value: 'horizontal_items', label: 'Horizontal Items' },
        { value: 'horizontal_compact_cards', label: 'Horizontal Compact Cards' },
        { value: 'single_card', label: 'Single Card' },
        {
          value: 'single_compact_card',
          label: 'Compact Card',
        },
      ]
      : [{ value: 'single_card', label: 'Single Card' }];

    if (
      appIdentifier === 'cf' ||
      appIdentifier === 'cpl' ||
      appIdentifier === 'ticats' ||
      appIdentifier === 'bombers'
    ) {
      feedStyleTypeOptions = [
        ...feedStyleTypeOptions,
        {
          value: 'single_compact_card',
          label: 'Compact Card',
        },
      ];
    }


    const renderStartDatePicker = ({ input, label, meta: { touched, error }, ...custom }) => {
      return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            {...input}
            {...custom}
            label={label}
            autoOk
            clearable
            format="MM/dd/yyyy"
            KeyboardButtonProps={{
              'aria-label': 'change start date',
            }}
            error={touched && !!error}
            helperText={touched && error}
            size='small'
            inputVariant='outlined'
            variant='inline'
            inputFormat='YYYY-MM-DD HH:mm:ss'
            placeholder='DD/MM/YYYY'
            InputAdornmentProps={{ position: 'end' }}
            
          />
        </MuiPickersUtilsProvider>
      )
    }

    const renderEndDatePicker = ({ input, label, meta: { touched, error }, ...custom }) => {
      return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            {...input}
            {...custom}
            label={label}
            autoOk
            clearable
            format="MM/dd/yyyy"
            KeyboardButtonProps={{
              'aria-label': 'change end date',
            }}
            error={touched && !!error}
            helperText={touched && error}
            size='small'
            inputVariant='outlined'
            variant='inline'
            inputFormat='YYYY-MM-DD HH:mm:ss'
            placeholder='DD/MM/YYYY'
            InputAdornmentProps={{ position: 'end' }}

          />
        </MuiPickersUtilsProvider>
      )
    }


    return (
      <Modal>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 280px',
            backgroundColor: 'white',
            margin: 'auto',
            padding: '20px',
          }}
        >
          <ModalBody small gridGap='5px' padding='10px 0px 10px 0px'>
            <form
              name='feedRowForm'
              onSubmit={handleSubmit}
              onChange={() => this.setState({ fieldTouched: true })}
            >
              <div style={{ display: 'grid', gridGap: '1px 1px' }}>
                {!requiredFeedRow && (
                  <div
                    style={{
                      marginBottom: '10px',
                      display: 'grid',
                      gridTemplateColumns: '1fr 1fr',
                      gridGap: '5px',
                    }}
                  >
                    <ToggleSwitch name='is_visible' label='Visible' />
                    {isSuperAdmin && (
                      <ToggleSwitch name='is_pinned' label='Is Pinned' />
                    )}
                  </div>
                )}
                {!requiredFeedRow && (
                  <Field
                    label='Internal Label'
                    name='internal_label'
                    component={renderTextField}
                    type='text'
                    validate={this.validateInternalLabel}
                  />
                )}

                {/* <div>
                      <Label>Feed Type</Label>
                      <div
                        style={{
                          fontWeight: '800',
                          paddingLeft: '6px',
                          textTransform: 'capitalize'
                        }}
                      >
                        {feedType.replace(/_/g, ' ')}
                      </div>
                    </div> */}
                {requiredFeedRow && (
                  <div style={{ marginTop: '25px' }}>
                    <Label>Section Row Style Type</Label>
                    <div
                      style={{
                        fontWeight: '800',
                        paddingLeft: '6px',
                        textTransform: 'capitalize',
                      }}
                    >
                      {feed_style_type.replace(/_/g, ' ')}
                    </div>
                  </div>
                )}
                {!requiredFeedRow && (
                  <Fragment>
                    {/*<SelectWrapper label="Feed Row Type">
                            <Field name="feed_type" component="select">
                              <option value="custom">Custom</option>
                            </Field>
                      </SelectWrapper>*/}
                    {formValues.feed_type !== ALL_OFFERS && (
                      <MultiSelectWrapper>
                        <Field
                          inLine
                          zIndex={9}
                          name='feed_style_type'
                          label='Section Row Style Type'
                          component={renderSelect}
                          options={feedStyleTypeOptions}
                          onChange={() => this.setState({ fieldTouched: true })}
                          simpleValue
                          topMod='-12px'
                          light
                          whiteBackground
                        />
                      </MultiSelectWrapper>
                    )}
                    {formValues.feed_type !== ALL_OFFERS && (
                      <MultiSelectWrapper>
                        <Field
                          zIndex={8}
                          name='segments'
                          topMod='-12px'
                          label='Segments (leave blank for all users)'
                          component={renderSelect}
                          isMulti
                          whiteBackground
                          light
                          inLine
                          onChange={() => this.setState({ fieldTouched: true })}
                          options={allSegments}
                          getOptionValue={(segment) => segment.segment_id}
                          getOptionLabel={(segment) => segment.name}
                        />
                      </MultiSelectWrapper>
                    )}
                    <MultiSelectWrapper>
                      <Field
                        zIndex={7}
                        name='tags'
                        label='Tags'
                        whiteBackground
                        component={renderSelect}
                        topMod='-12px'
                        isMulti
                        onChange={() => this.setState({ fieldTouched: true })}
                        inLine
                        light
                        options={allTags}
                        getOptionValue={(tag) => tag.tag_id}
                        getOptionLabel={(tag) =>
                          tag ? `${tag.name}: ${tag.value}` : null
                        }
                      />
                    </MultiSelectWrapper>
                    <DatePickerWrapper>
                      <Field
                        onChange={() => {
                          this.setState({ fieldTouched: true })
                         }}
                        name="start_date"
                        component={renderStartDatePicker}
                        label="Start Date"
                      />
                      <Field
                        onChange={() => {
                          this.setState({ fieldTouched: true })
                         }}
                        name="end_date"
                        component={renderEndDatePicker}
                        label="End Date"
                      />
                    </DatePickerWrapper>
                  </Fragment>
                )}
                {!requiredFeedRow ? (
                  <Fragment>
                    {this.showOrHideListFields()}
                    <ItemsTitleWrapper>
                      <AppFeedItemTitle>App Feed Items</AppFeedItemTitle>
                      {multipleAppFeedItemClients.includes(appIdentifier) && (
                        <CustomAddButton onClick={() => this.addNewItem()}>
                          Add New Item
                        </CustomAddButton>
                      )}
                    </ItemsTitleWrapper>
                    {this.renderTextFieldArrayOrCardForm()}
                  </Fragment>
                ) : (
                  <Field
                    label={
                      feed_style_type === 'horizontal_cards'
                        ? 'List Title'
                        : 'Title'
                    }
                    name='title'
                    component={renderTextField}
                    type='text'

                  // validate={maxLength50}
                  />
                )}
                <ButtonRow
                  style={{ marginTop: '10px' }}
                  justifyContent='space-between'
                >
                  <Button type='button' secondary onClick={cancel}>
                    Cancel
                  </Button>
                  <Button
                    type='submit'
                    disabled={
                      (feedRowIsEditing ? !this.state.fieldTouched : false) ||
                      this.state.isInternalLabelNotValid ||
                      this.state.isSectionLabelNotValid
                    }
                  >
                    Done
                  </Button>
                </ButtonRow>
              </div>
            </form>
          </ModalBody>
          <div style={{ marginLeft: '30px', width: '95%' }}>
            <div>Card Preview</div>
            {this.renderCardPreview()}
          </div>
        </div>
      </Modal>
    );
  }
}

export default FeedRowModal;
