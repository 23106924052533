export const setOverlayToMap = (overlayData, drawingComplete) => {
  switch (overlayData.type) {
    case 'polygon':
      const polygon = new google.maps.Polygon({
        paths: overlayData.geometry.map((array, index) => ({
          lat: overlayData.geometry[index][0],
          lng: overlayData.geometry[index][1]
        })),
        fillColor: '#00A5DB',
        fillOpacity: 0.3,
        strokeWeight: 0,
        clickable: true,
        editable: true,
        draggable: true,
        zIndex: 1
      });
      drawingComplete({overlay: polygon, type: 'polygon'});
      break;
    case 'circle':
      const circle = new google.maps.Circle({
        center: {lat: overlayData.geometry[0], lng: overlayData.geometry[1]},
        radius: overlayData.radius,
        fillColor: '#00A5DB',
        fillOpacity: 0.3,
        strokeWeight: 0,
        clickable: true,
        editable: true,
        draggable: true,
        zIndex: 1
      });
      drawingComplete({overlay: circle, type: 'circle'});
      break;
    case 'rectangle':
      const rectangle = new google.maps.Rectangle({
        bounds: {
          south: overlayData.geometry[0][0],
          west: overlayData.geometry[0][1],
          north: overlayData.geometry[1][0],
          east: overlayData.geometry[1][1]
        },
        fillColor: '#00A5DB',
        fillOpacity: 0.3,
        strokeWeight: 0,
        clickable: true,
        editable: true,
        draggable: true,
        zIndex: 1
      });
      drawingComplete({overlay: rectangle, type: 'rectangle'});
      break;
  }
};

export const parseOverlayForAPI = (overlay, type) => {
  let payload = {
    type: type,
    geometry: []
  };

  switch (type) {
    case 'polygon':
      let coordinatesArray = overlay.getPath().getArray();
      coordinatesArray.forEach(coordinates => {
        payload = {
          ...payload,
          geometry: [
            ...payload.geometry,
            [coordinates.lat(), coordinates.lng()]
          ]
        };
      });
      break;
    case 'circle':
      payload = {
        ...payload,
        geometry: [overlay.getCenter().lat(), overlay.getCenter().lng()],
        radius: overlay.getRadius()
      };
      break;
    case 'rectangle':
      payload = {
        ...payload,
        geometry: [
          [
            overlay
              .getBounds()
              .getSouthWest()
              .lat(),
            overlay
              .getBounds()
              .getSouthWest()
              .lng()
          ],
          [
            overlay
              .getBounds()
              .getNorthEast()
              .lat(),
            overlay
              .getBounds()
              .getNorthEast()
              .lng()
          ]
        ]
      };
      break;
  }
  return payload;
};

export const locationsWithinOverlay = (overlay, type, locations) => {
  return locations.filter(location => {
    if (locationIsWithinOverlay(latLngLiteral(location), overlay, type)) {
      return location;
    }
  });
};

const locationIsWithinOverlay = (latLng, overlay, type) => {
  switch (type) {
    case 'polygon':
      return google.maps.geometry.poly.containsLocation(latLng, overlay);
      break;
    case 'circle':
      return (
        google.maps.geometry.spherical.computeDistanceBetween(
          latLng,
          overlay.getCenter()
        ) <= overlay.getRadius()
      );
      break;
    case 'rectangle':
      return overlay.getBounds().contains(latLng);
      break;
  }
};

const latLngLiteral = location => {
  return new google.maps.LatLng(location.lat, location.lng);
};
