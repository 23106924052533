import styled from 'styled-components';

export const ButtonsWrapper = styled.div`
  margin: 30px 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;

export const FormWrapper = styled.div`
  display: grid;
  grid-gap: 32px;
`;

export const BusinessSelect = styled.div`
  margin-top: 17px;

  .react-select__value-container--is-multi {
    height: 70px;
    overflow-y: scroll;
  }
`;

export const ModalTitle = styled.div`
  font-family: 'Montserrat';
  font-size: 20px;
  color: #191818;
  text-align: center;
  padding: 0 0 20px;
  display: grid;
  grid-template-columns: 7fr 1fr;
  text-align: left;
`;
