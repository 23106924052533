import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { LoadingBlueLarge } from 'merchant-portal-components';
import { Icon } from 'merchant-portal-components';
import { Button } from 'merchant-portal-components';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';

const ModalTitle = styled.div`
  display: grid;
  grid-template-columns: 10fr 1fr;
  padding: 0 0 12px;
`;

const LineDivider = styled.hr`
  border: solid 1px #e1e1e1;
`;

const ButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 120px;
  padding: 12px;
`;

const BusinessesList = styled.div``;

const BusinessRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 7fr;
  align-items: center;
`;

const BusinessWrapper = styled.div``;

const TextSearch = styled.div`
  padding: 10px 0px;
`;

const BrowseLocationsModal = ({
  toggleLocationsModal,
  businesses,
  initValues,
  comboBusinesses,
  setFieldValue,
  setAvailableAllLocations,
}) => {
  const [selectedBusinesses, setSelectedBusinesses] = useState(comboBusinesses);
  const [isSelectAll, setIsSelectAll] = useState(false);

  const [filterState, setFilterState] = useState({
    search: '',
    businessesList: businesses,
  });

  useEffect(() => {
    setFilterState({
      search: filterState.search,
      businessesList: businesses,
    });
  }, []);

  const handleSearch = (e) => {
    setFilterState({
      search: e.target.value,
      businessesList: businesses.filter((business) =>
        business.display_name
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
      ),
    });
  };

  const onChangeSelect = (id) => {
    if (selectedBusinesses.includes(id)) {
      setSelectedBusinesses(
        selectedBusinesses.filter((selectedId) => selectedId !== id)
      );
    } else {
      setSelectedBusinesses([...selectedBusinesses, id]);
    }
  };

  const onToggleSelectAll = () => {
    const newValue = !isSelectAll;
    setIsSelectAll(newValue);

    if (newValue) {
      setSelectedBusinesses(businesses.map((business) => business.id));
    } else {
      setSelectedBusinesses([]);
    }
  };

  return (
    <Dialog open>
      <DialogTitle>
        <ModalTitle>
          <div>Browse Locations</div>
          <div
            onClick={() => toggleLocationsModal()}
            style={{ cursor: 'pointer' }}
          >
            x
          </div>
        </ModalTitle>
        <TextSearch>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon className="fal fa-search" />
                </InputAdornment>
              ),
            }}
            style={{ width: '100%' }}
            variant="outlined"
            type="text"
            onChange={(e) => handleSearch(e)}
            placeholder="Search locations"
            value={filterState.search}
          />
        </TextSearch>
        <FormControlLabel
          control={
            <Checkbox
              onChange={() => onToggleSelectAll(setFieldValue)}
              color="primary"
            />
          }
          checked={isSelectAll}
          label="Select All"
          style={{ paddingLeft: '16px' }}
        />
      </DialogTitle>
      <DialogContent>
        <BusinessesList>
          <LineDivider />
          {!businesses ? (
            <LoadingBlueLarge />
          ) : (
            <BusinessWrapper>
              {filterState.businessesList.map((business) => (
                <div key={business.id}>
                  <BusinessRow>
                    <Checkbox
                      checked={selectedBusinesses.includes(business.id)}
                      onChange={() =>
                        onChangeSelect(business.id, setFieldValue)
                      }
                      color="primary"
                    />
                    {business.display_name}
                  </BusinessRow>
                  <LineDivider />
                </div>
              ))}
            </BusinessWrapper>
          )}
        </BusinessesList>
      </DialogContent>
      <DialogActions>
        <ButtonsWrapper>
          <Button secondary onClick={() => toggleLocationsModal()}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              setFieldValue('comboLocations', selectedBusinesses);
              setAvailableAllLocations(
                selectedBusinesses.length === businesses.length
              );
              toggleLocationsModal();
            }}
          >
            Save
          </Button>
        </ButtonsWrapper>
      </DialogActions>
    </Dialog>
  );
};

export default BrowseLocationsModal;
