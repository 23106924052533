import React, { Component } from 'react';
import { render } from 'react-dom';
import styled from 'styled-components';
const ImageWrapperMain = styled.div`
  display: flex;
`;
const ButtonWrapper = styled.div`
  display: block;
  text-align: center;
  button {
    border: 2px dashed #2440ae;
    color: #2440ae;
    padding: 3px 7px;
    border-radius: 100px;
    margin-top: 5px;
  }
`;
const ImageWrapper = styled.div`
  margin: 32px 101px 0px 102px;
  padding: 38px 12px 21px 13px;
  border-radius: 12px;
  border: 2px dashed #2440ae;
  position: relative;
  height: 246px;
  width: 100%;
  .uploadDoc {
    font-size: 14px;
    color: #191818;
    text-align: center;
    position: relative;
    z-index: 0;
  }
  .uploadDoc span {
    display: block;
  }
  .uploadDoc .browseButton {
    background: #e6ebff;
    font-size: 15px;
    font-weight: 600;
    padding: 10px 20px;
    color: #2440ae;
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 5px;
    margin-bottom: 15px;
    margin-top: 15px;
  }
  input[type='file'] {
    position: absolute;
    top: 0px;
    bottom: 0px;
    max-width: 100%;
    margin-left: -14px;
    height: 100%;
    opacity: 0;
    z-index: 1;
  }
  div#fileName {
    position: absolute;
    right: 0px;
    left: 0px;
    text-align: center;
    top: calc(50% - 12px);
  }
`;
class Fileuploader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datafile: null,
    };
  }

  deleteFile = () => {
    var fileData = document.getElementById('fileUploader');
    fileData.value = '';
    let preview = document.getElementById('show-text');
    let fileName = document.getElementById('fileName');
    var uploadText = document.getElementById('uploadeText');
    preview.innerHTML = '';
    fileName.innerHTML = '';
    uploadText.innerHTML =
      '<span>Drag & drop file</span><span>or</span><span class="browseButton">Browse Computer</span><span>Maximum File Size: 5MB</span><span>Format: .txt</span>';
  };
  showFile = () => {
    var savefiledata = this.props.values;
    if (window.File && window.FileReader && window.FileList && window.Blob) {
      var preview = document.getElementById('show-text');
      var fileName = document.getElementById('fileName');
      var uploadText = document.getElementById('uploadeText');
      var file = document.querySelector('input[type=file]').files[0];
      var reader = new FileReader();
      var textFile = /text.*/;
      fileName.innerHTML = file.name;
      if (file.type.match(textFile)) {
        reader.onload = function (event) {
          preview.innerHTML = event.target.result;
          uploadText.innerHTML = '';
          let tabtwoData = event.target.result.split('\n');
          this.props.setPromoCodeList(tabtwoData);
        };
        this.setState({
          datafile: preview,
        });
      } else {
        preview.innerHTML =
          "<span class='error'>It doesn't seem to be a text file!</span>";
      }
      reader.readAsText(file);
    } else {
      alert('Your browser is too old to support HTML5 File API');
    }
  };

  render() {
    return (
      <>
        <ImageWrapperMain>
          <ImageWrapper>
            <input id='fileUploader' type='file' onChange={this.showFile} />
            <div id='fileName'></div>
            <div id='uploadeText' className='uploadDoc'>
              <span>Drag & drop file</span>
              <span>or</span>
              <span className='browseButton'>Browse Computer</span>
              <span>Maximum File Size: 5MB</span>
              <span>Format: .txt</span>
            </div>
          </ImageWrapper>
        </ImageWrapperMain>
        <ButtonWrapper>
          <button onClick={this.deleteFile}>
            <i class='fa fa-trash'></i>
          </button>
          {/* <button onClick={this.handleuploadCode}>Save Codes</button> */}
        </ButtonWrapper>
        {/* <div id='show-text'></div> */}
      </>
    );
  }
}
export default Fileuploader;
