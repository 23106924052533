import React, {Component} from 'react';
import {connect} from 'react-redux';
import {reduxForm, formValueSelector} from 'redux-form';
import SyncWithEmailForm from './SyncWithEmailForm';

class SyncWithEmail extends Component {
  render() {
    return <SyncWithEmailForm {...this.props} />;
  }
}

const validate = values => {
  const errors = {};
  const requiredFields = [
    'mailchimp.details.segment_name',
    'mailchimp.details.company',
    'mailchimp.details.address1',
    'mailchimp.details.city',
    'mailchimp.details.state',
    'mailchimp.details.country',
    'mailchimp.details.zip',
    'mailchimp.details.default_reminder_text',
    'mailchimp.details.default_from_name',
    'mailchimp.details.default_from_email',
    'mailchimp.details.default_subject',
    'mailchimp.details.default_language',
    'mailchimp.details.default_email_type'
  ];
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Required';
    }
  });
  return errors;
};

SyncWithEmail = reduxForm({
  form: 'segmentForm',
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate
})(SyncWithEmail);

const selector = formValueSelector('segmentForm');

SyncWithEmail = connect(state => {
  const external_segment = selector(state, 'mailchimp');
  const integration = selector(state, 'integration');
  return {
    external_segment,
    integration
  };
})(SyncWithEmail);

export default SyncWithEmail;
