import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Field } from 'formik';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import { RenderSelect } from '../../../../../../components/FormElements/FormikElements';
import { SortableCardRow } from '../../../../components/MenuManagementElements';
import { Icon } from 'merchant-portal-components';
import { StyledText } from '../../../../../../components/StyledText/StyledText';

const SortableCardRowWrapper = styled.div`
  display: grid;
  grid-template-columns: 16px 500px;
  grid-gap: 24px;
  align-items: center;

  .react-select__control {
    background-color: white;
    border-radius: 6px;
  }

  .react-select__single-value {
    font-weight: 600;
    font-size: 14px;
  }

  .react-select__multi-value {
    background-color: #e6e6e6;
    border-radius: 15px;
    padding: 1px 3px;
    font-weight: 400;
    font-size: 14px;
  }
`;

const AddNewOption = styled.div`
  font-weight: 600;
  color: ${(props) => props.disabled && '#6f6f76'};
  margin-right: auto;
  margin-top: 24px;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  cursor: pointer;
`;

const SortableItem = SortableElement(
  ({ productOption, allOptions, optionIndex, arrayHelpers, productOptionsAreEditable }) => {
    const getSelectedOption = () => {
      let foundOption = allOptions.find(
        (globalOption) => globalOption.id === productOption.product_option_id
      );
      return foundOption ? (
        <>
          {`${foundOption.name} - `}
          <StyledText fontWeight="300" fontStyle="italic">
            {foundOption.id}
          </StyledText>
        </>
      ) : (
        'Option Not Found'
      );
    };

    return (
      <SortableCardRowWrapper onClick={(e) => e.stopPropagation()}>
        {productOptionsAreEditable ? (
          <Icon className="fas fa-minus-circle" onClick={() => arrayHelpers.remove(optionIndex)} />
        ) : (
          <Icon className="far fa-equals" style={{ cursor: 'move' }} />
        )}
        <SortableCardRow
          style={{
            display: 'block',
            cursor: productOptionsAreEditable ? 'default' : 'move',
          }}
        >
          {productOptionsAreEditable ? (
            <Field
              onClick={(e) => e.stopPropagation()}
              name={`productOptions[${optionIndex}].product_option_id`}
              component={RenderSelect}
              options={allOptions.filter(
                (globalOption) =>
                  !arrayHelpers.form.values.productOptions
                    .map((productOption) => productOption.product_option_id)
                    .includes(globalOption.id) ||
                  arrayHelpers.form.values.productOptions[optionIndex].product_option_id ===
                    globalOption.id
              )}
              setFieldValue={arrayHelpers.form.setFieldValue}
              getOptionLabel={(option) => `${option.name} - ${option.id}`}
              getOptionValue={(option) => option.id}
              valueKey="id"
              placeholder="Select Option*"
              required
            />
          ) : (
            getSelectedOption()
          )}
        </SortableCardRow>
      </SortableCardRowWrapper>
    );
  }
);

const SortableList = SortableContainer(
  ({ allOptions, productOptions, arrayHelpers, productOptionsAreEditable }) => {
    return (
      <div style={{ display: 'grid', gridGap: '24px' }} onClick={(e) => e.stopPropagation()}>
        {productOptions.map((productOption, index) => (
          <SortableItem
            key={`productOption-${index}`}
            index={index}
            productOption={productOption}
            optionIndex={index}
            disabled={productOptionsAreEditable}
            allOptions={allOptions}
            arrayHelpers={arrayHelpers}
            productOptionsAreEditable={productOptionsAreEditable}
          />
        ))}
      </div>
    );
  }
);

const RenderProductOptionsFieldArray = ({
  arrayHelpers,
  allOptions,
  productOptionsAreEditable,
}) => {
  return (
    <Fragment>
      <SortableList
        allOptions={allOptions}
        productOptions={arrayHelpers.form.values.productOptions}
        arrayHelpers={arrayHelpers}
        productOptionsAreEditable={productOptionsAreEditable}
        onSortEnd={({ oldIndex, newIndex }) => {
          // arrayHelpers.move(oldIndex, newIndex);
          arrayHelpers.form.setValues({
            productOptions: arrayMove(
              arrayHelpers.form.values.productOptions,
              oldIndex,
              newIndex
            ).map((productOption, index) => ({
              ...productOption,
              position: index + 1,
            })),
          });
        }}
      />
      <AddNewOption
        disabled={!productOptionsAreEditable}
        onClick={(e) => {
          e.stopPropagation();
          arrayHelpers.push({
            position: arrayHelpers.form.values.productOptions.length + 1,
            product_option_id: null,
          });
        }}
      >
        + Add New Option
      </AddNewOption>
    </Fragment>
  );
};

export default RenderProductOptionsFieldArray;
