export const getCurrentCreditsBalance = customerGlobalCredits => {
  let currentCreditsBalance = 0;

  customerGlobalCredits.forEach(credit => {
    currentCreditsBalance += credit.current_amount;
  });

  return currentCreditsBalance;
};

export default getCurrentCreditsBalance;
