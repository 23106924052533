import React, { useState, useEffect, Fragment, useRef } from 'react';
import { Formik, Field, FieldArray } from 'formik';
import TextFieldPOS from '../../components/TextFieldPOS';
import ToogleFieldPOS from '../../components/ToogleFieldPOS';
import Pagination from '../../../../../components/Pagination/Pagination';
import CreatableSelect from '../../../../../components/Select/CreatableSelect';
import StickyButtons from '../../../../../components/StickyButtons';
import ConfirmUnsavedChangesModal from '../../components/ConfirmUnsavedChangesModal';
import { discountOptionsModified } from '../../utils/utils';
import {
  MainDiv,
  PaginationWrapper,
  Body,
  DiscountDiv,
  DiscountNameCell,
  POSDiv,
  DiscountHeader,
  POSHeader,
  POSFieldWrapper,
  POSValueCell,
  LineDivider,
  ToogleFieldPOSWrapper,
  SelectDiv,
} from '../../styles/styles';
import { newOfferEngineClients } from '../../../../../utils/clientCustomizations';
const OffersMappingTable = ({
  meta,
  requestParams,
  updateRequestParams,
  dirty,
  handleReset,
  values,
  initialValues,
  offers,
  getOffers,
  type,
  submitPOSDiscountValuesOffers,
  initialRequestParams,
  selectedValuePOS,
  setSelectedValuePOS,
  allOffers,
  posDiscountsMappingFields,
  posDiscountsFieldsNum,
}) => {
  const searchRef = useRef();
  let appIdentifier = localStorage.getItem('appIdentifier');
  const newOfferClients = newOfferEngineClients;
  const hasNewOfferFormat = newOfferClients.includes(appIdentifier);
  const [isConfirmModal, setIsConfirmModal] = useState(false);

  const discountOptions = discountOptionsModified(allOffers, type);

  // flags that store params for action to be exe after modal confirmation for save
  const [newValue, setNewValue] = useState();
  const [newSearch, setNewSearch] = useState(null);
  const [newPage, setNewPage] = useState(null);

  const handleChange = (newValue) => {
    searchRef.current = newValue;
    if (!newValue) {
      setNewValue(null);
      getOffers(initialRequestParams);
    } else if (newValue) {
      if (dirty) {
        setNewValue(newValue);
        setIsConfirmModal(true);
      } else {
        getOffers(
          {
            searchString: hasNewOfferFormat
              ? newValue.title
              : newValue.offer_details.label,
            page_number: 1,
            page_size: 25,
          },
          true
        );
      }
    } else {
      if (dirty) {
        setNewSearch({
          searchString: newValue.inputValue,
          page_number: meta.page_number,
          page_size: meta.page_size,
        });
        setIsConfirmModal(true);
      } else {
        getOffers(
          {
            searchString: newValue.inputValue,
            page_number: meta.page_number,
            page_size: meta.page_size,
          },
          true
        );
      }
    }
  };
  const availablePageSized = [
    { value: 10, label: '10' },
    { value: 25, label: '25' },
    { value: 50, label: '50' },
  ];
  return (
    <MainDiv>
      <SelectDiv>
        <CreatableSelect
          onChange={handleChange}
          options={discountOptions || []}
          value={newValue}
          placeholder='Search for offers'
          isClearable={!dirty}
        />
      </SelectDiv>
      <Body>
        <DiscountDiv>
          <DiscountHeader>
            <div>{type.charAt(0).toUpperCase() + type.slice(1)} Name</div>
          </DiscountHeader>
          <LineDivider />
          {offers?.map((offer, index) => {
            return (
              <div key={offer.offer_id}>
                <DiscountNameCell>{offers[index].label}</DiscountNameCell>
              </div>
            );
          })}
        </DiscountDiv>

        <POSDiv>
          <POSFieldWrapper fieldsNumber={posDiscountsFieldsNum}>
            {Object.keys(posDiscountsMappingFields)?.map((item, index) => {
              return <POSHeader key={index}>{item}*</POSHeader>;
            })}
            <LineDivider fieldsNumber={posDiscountsFieldsNum} />

            <FieldArray
              name='offers'
              render={(arrayHelpers) => (
                <Fragment>
                  {offers?.map((offer, index) => {
                    return (
                      <Fragment key={`o_${offer.offer_id}`}>
                        {Object.keys(posDiscountsMappingFields)?.map((key) => {
                          return (
                            <POSValueCell key={`p_${offer.offer_id}_${key}`}>
                              {posDiscountsMappingFields[key].type ===
                              'string' ? (
                                <TextFieldPOS
                                  name={`offers[${offer.index}].pos_mapping['${key}']`}
                                  value={
                                    offer?.pos_mapping
                                      ? offer?.pos_mapping[key]
                                      : ''
                                  }
                                  setFieldValue={
                                    arrayHelpers.form.setFieldValue
                                  }
                                />
                              ) : (
                                <ToogleFieldPOSWrapper>
                                  <ToogleFieldPOS
                                    name={`offers[${offer.index}].pos_mapping['${key}']`}
                                    onChange={arrayHelpers.form.setFieldValue}
                                  />
                                </ToogleFieldPOSWrapper>
                              )}
                            </POSValueCell>
                          );
                        })}
                      </Fragment>
                    );
                  })}
                  <StickyButtons
                    isVisible={dirty}
                    handleDiscard={() => handleReset()}
                    handleSave={() => {
                      submitPOSDiscountValuesOffers(
                        values,
                        type,
                        initialValues,
                        selectedValuePOS,
                        null,
                        null,
                        meta.page_size,
                        meta.page_number,
                        posDiscountsMappingFields
                      );
                      setNewValue('');
                    }}
                    title='Offers POS Mapping'
                  />
                </Fragment>
              )}
            />
          </POSFieldWrapper>
        </POSDiv>
      </Body>

      <PaginationWrapper>
        <Pagination
          response={{
            page_number: meta.page_number,
            page_size: meta.page_size,
            count:
              hasNewOfferFormat && !searchRef.current
                ? allOffers.length
                : meta.count,
          }}
          pageIntervalOptions={availablePageSized}
          requestParams={requestParams}
          updateRequestParams={updateRequestParams}
          handlePreviousPageClick={() => {
            if (dirty) {
              setNewPage(meta.page_number - 1);
              setIsConfirmModal(true);
            } else {
              getOffers({
                searchString: '',
                page_number: meta.page_number - 1,
                page_size: meta.page_size,
              });
            }
          }}
          handleNextPageClick={() => {
            if (dirty) {
              setNewPage(meta.page_number + 1);
              setIsConfirmModal(true);
            } else {
              getOffers({
                searchString: '',
                page_number: meta.page_number + 1,
                page_size: meta.page_size,
              });
            }
          }}
          isResultsPerPageDisabled={false}
        />
      </PaginationWrapper>

      {isConfirmModal && (
        <ConfirmUnsavedChangesModal
          toggleConfirmModal={() => setIsConfirmModal(false)}
          handleSave={() => {
            setIsConfirmModal(false);
            submitPOSDiscountValuesOffers(
              values,
              type,
              initialValues,
              selectedValuePOS,
              newValue,
              newSearch,
              meta.page_size,
              newPage ? newPage : meta.page_number,
              posDiscountsMappingFields
            );
            setNewSearch(null);
            setNewPage(null);
          }}
        />
      )}
    </MainDiv>
  );
};

export default OffersMappingTable;
