const isFieldValid = (fieldValue) => {
  const isValid =
    fieldValue !== '' && fieldValue !== null && fieldValue !== undefined;
  return isValid;
};
export const getPurePayload = (payload) => {
  let neatPayload = payload;
  if (typeof payload === 'object') {
    neatPayload = Object.keys(payload)
      .filter(function (k) {
        return isFieldValid(payload[k]);
      })
      .reduce(function (acc, k) {
        let itemValue = payload[k];
        if (Array.isArray(payload[k])) {
          itemValue = payload[k].map((subItem) =>
            typeof subItem === 'object' ? getPurePayload(subItem) : subItem
          );
        }
        if (typeof payload === 'object' && !Array.isArray(payload[k])) {
          itemValue = getPurePayload(payload[k]);
        }
        acc[k] = itemValue;
        return acc;
      }, {});
  }
  return neatPayload;
};
export const getSearchUrl = (baseUrl, searchString, minLength) => {
  let neatUrl = baseUrl;
  if (searchString.length >= minLength) {
    neatUrl = neatUrl + `&search_string=${searchString}`;
  }
  return neatUrl;
};
