import React, { useEffect, Suspense, lazy, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import * as actions from '../ReportTableActions.js';
import ReportSearch from '../components/ReportSearch';
import { getBusinessLocationsOfMerchant } from '../../BusinessLocationDetails/LocationListManager/actions.js';
import LocationFilterModal from '../../../components/FilterModal/LocationFilterModal';
const GenericTable = lazy(() =>
  import('../../../components/Table/GenericTable')
);

function ProductReportContainer({
  allProducts,
  allBusinesses,
  requestParams,
  getProductReport,
  setExportingData,
  setExportingHeaders,
  setExportingFileName,
  clearProductReport,
  updateReportsRequestParams,
  getBusinessLocationsOfMerchant,
  setMessage,
  resetAllReports,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  selectedBusinessesIDs,
  setSelectedBusinessesIDs,
  filteredLocations,
  setFilteredLocations,
}) {
  const [isLocationFilterOpen, setIsLocationFilterOpen] = useState(false);
  const columnHeaders = [
    'Product',
    'Variant',
    'No. of Times Ordered',
    'Product Sales',
    'No. of Times Ordered Outside Combo',
    'AVG Price',

  ];
  const columnNames = [
    'product_name',
    'product_variant_name',
    'num_times_ordered',
    'product_price',
    'num_times_ordered_outside_combo',
    'avg_price',
  ];
  const columnTypes = ['string', 'string', 'string', 'money', 'string','money'];
  const columnWidth = ['120px', '150px', '110px', '250px', '150px'];
  const columnHeaderIsSortableList = [true, true, true, true, true];
  const updateSelectedBusiness = useCallback(() => {
    if (filteredLocations) {
      setSelectedBusinessesIDs(filteredLocations);
    }
  }, [filteredLocations]);
  useEffect(() => {
    updateSelectedBusiness();
  }, [updateSelectedBusiness]);
  const getExportHeaders = (headers, fields) => {
    const exportedHeaders = [];
    headers.forEach((header, index) =>
      exportedHeaders.push({ label: header, key: fields[index] })
    );
    return exportedHeaders;
  };
  useEffect(() => {
    getBusinessLocationsOfMerchant();
    setExportingFileName('product_report');
    setExportingHeaders(getExportHeaders(columnHeaders, columnNames));
    setMessage('Select a date range and location to generate a report');
    return () => {
      clearProductReport();
      setExportingData([]);
      updateReportsRequestParams({
        page_number: 1,
        page_size: 25,
      });
      setMessage('Select a date range and location to generate a report');
      resetAllReports();
      setFilteredLocations([]);
      setSelectedBusinessesIDs([]);
    };
  }, []);
  useEffect(() => {
    if (allProducts?.length === 0) {
      setExportingData([]);
      setMessage('No Data Found');
    }
  }, [allProducts]);
  return (
    <>
      <ReportSearch
        allBusinesses={allBusinesses}
        generateReport={getProductReport}
        haslocationFiltering
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        selectedBusinessesIDs={selectedBusinessesIDs}
        setSelectedBusinessesIDs={setSelectedBusinessesIDs}
        setIsLocationFilterOpen={setIsLocationFilterOpen}
      />
      {allProducts?.length > 0 && (
        <div style={{ marginTop: '30px' }}>
          <Suspense fallback={<div>Loading...</div>}>
            <GenericTable
              dataList={allProducts}
              columnHeaders={columnHeaders}
              columnHeaderIsSortableList={columnHeaderIsSortableList}
              columnNames={columnNames}
              columnWidth={columnWidth}
              columnTypes={columnTypes}
              placeholder=''
              requestParams={requestParams}
              requestParamsType={'productReport'}
              handleClickRow={null}
              handleCheckboxSelect={null}
              checkboxList={[]}
              setCheckboxList={null}
              setExportingData={() => setExportingData(allProducts)}
            />
          </Suspense>
        </div>
      )}
      {isLocationFilterOpen && (
        <LocationFilterModal
          placeholder='Enter Internal Location Name'
          searchLabel='Search by Internal Location Name'
          filteredLocations={filteredLocations}
          setFilteredLocations={setFilteredLocations}
          isOpen={isLocationFilterOpen}
          setIsOpen={setIsLocationFilterOpen}
          emptyIsEqualToAll={true}
          isEmptyAccepted={false}
        />
      )}
    </>
  );
}
function mapStateToProps(state) {
  return {
    allProducts: state.ReportTableReducer.allProducts,
    requestParams: state.ReportTableReducer.requestParams,
    allBusinesses: state.businessLocationsList.businessLocationsOfMerchant,
  };
}
export default connect(mapStateToProps, {
  ...actions,
  getBusinessLocationsOfMerchant,
})(ProductReportContainer);
