import {
  GET_SEGMENTS_REQUEST,
  GET_SEGMENTS_SUCCESS,
  GET_MAILCHIMP_LISTS_REQUEST,
  GET_MAILCHIMP_LISTS_SUCCESS,
  NO_MAILCHIMP_LIST,
  INITIATE_CREATE_SEGMENT,
  CREATE_SEGMENT_REQUEST,
  CREATE_SEGMENT_SUCCESS,
  CREATE_SEGMENT_FAILURE,
  INITIATE_EDIT_SEGMENT,
  CANCEL_EDIT_SEGMENT,
  EDIT_SEGMENT_FAILURE,
  DELETE_SEGMENT_REQUEST,
  DELETE_SEGMENT_SUCCESS,
  DELETE_SEGMENT_FAILURE,
  UPDATE_SELECTED_LOCATIONS,
  RESET_LOCATIONS,
  SET_LOCATIONS,
  TARGET_AUDIENCE_PREVIEW_REQUEST,
  TARGET_AUDIENCE_PREVIEW_SUCCESS,
  TARGET_AUDIENCE_PREVIEW_FAILURE,
  INITIATE_CLONE_SEGMENT,
  UPDATE_SORTED_SEGMENTS_LIST
} from './segmentationTypes';
import { UPDATE_SEGMENTS_REQUEST_PARAMS } from '../Pagination/types';
import moment from 'moment';

export const initialFormValues = {
  name: '',
  is_active: true,
  is_overwrite: false,
  audience: {
    shape_json: null,
    zone: 'all_users'
  },
  filters: {
    filter_json: null,
    filter_rules: null
  },
  integration: 'mailchimp',
  mailchimp: {
    external_segment_id: 'nosync',
    sync_frequency: 'daily',
    details: {
      country: 'CA',
      state: 'ON',
      default_language: 'EN',
      default_email_type: true
    }
  },
  businesses: []
};

export default function(
  state = {
    allLocations: false,
    selectedLocations: false,
    requestingGetSegments: false,
    requestingCreateSegment: false,
    isDeleting: [],
    allSegments: false,
    requestParams: {
      page_number: 1,
      page_size: '25',
      sorting_option: ''
    },
    initialFormValues,
    editSegment: false
  },
  action
) {
  switch (action.type) {
    case SET_LOCATIONS:
      return {
        ...state,
        allLocations: action.allLocations,
        selectedLocations: action.allLocations
      };
    case GET_SEGMENTS_REQUEST:
      return {
        ...state,
        requestingGetSegments: true
      };
    case GET_SEGMENTS_SUCCESS:
      return {
        ...state,
        requestingGetSegments: false,
        requestingCreateSegment: false,
        editSegment: false,
        createSegment: false,
        initialFormValues,
        allSegments: action.response.data.map(segment => {
          const minutesLeft =
            10 -
            moment
              .utc()
              .diff(
                moment.utc(segment.updated_at, 'YYYY-MM-DD HH:mm:ss'),
                'minutes'
              );

          const isAvailable = minutesLeft < 0;
          return { ...segment, isAvailable, minutesLeft };
        }),
        availableSegments: action.response.data
          .map(segment => {
            const minutesLeft =
              10 -
              moment
                .utc()
                .diff(
                  moment.utc(segment.updated_at, 'YYYY-MM-DD HH:mm:ss'),
                  'minutes'
                );

            const isAvailable = minutesLeft < 0;
            return { ...segment, isAvailable, minutesLeft };
          })
          .filter(segment => segment.isAvailable),
        targetAudience: null,
        selectedLocations: false
      };
    case GET_MAILCHIMP_LISTS_SUCCESS:
      return {
        ...state,
        mailchimpLists: action.mailchimpLists.data
      };
    case NO_MAILCHIMP_LIST:
      return {
        ...state,
        noMailchimpIntegration: true
      };
    case INITIATE_CREATE_SEGMENT:
      return {
        ...state,
        createSegment: true
      };
    case CREATE_SEGMENT_REQUEST:
      return {
        ...state,
        requestingCreateSegment: true
      };
    case INITIATE_CLONE_SEGMENT:
      return {
        ...state,
        // requestingCreateSegment: true,
        cloneSegment: true,
        createSegment: false,
        initialFormValues: action.payload
      };
    case CREATE_SEGMENT_SUCCESS:
      return {
        ...state,
        requestingCreateSegment: false,
        createSegment: false,
        editSegment: false,
        cloneSegment: false
      };
    case CREATE_SEGMENT_FAILURE:
      return {
        ...state,
        requestingCreateSegment: false,
        selectedLocations: false
      };
    case INITIATE_EDIT_SEGMENT:
      return {
        ...state,
        editSegment: true,
        initialFormValues: {
          ...action.payload,
          mailchimp:
            action.payload.mailchimp &&
            action.payload.mailchimp.external_segment_id
              ? action.payload.mailchimp
              : initialFormValues.mailchimp
        }
      };
    case CANCEL_EDIT_SEGMENT:
      return {
        ...state,
        editSegment: false,
        createSegment: false,
        initialFormValues,
        selectedLocations: false,
        cloneSegment: false
      };
    case DELETE_SEGMENT_REQUEST:
      return {
        ...state,
        isDeleting: [...state.isDeleting, action.payload]
      };
    case DELETE_SEGMENT_SUCCESS:
      return {
        ...state,
        isDeleting: state.isDeleting.filter(id => id !== action.payload),
        allSegments: state.allSegments.filter(
          segment => segment.segment_id !== action.payload
        )
      };
    case UPDATE_SEGMENTS_REQUEST_PARAMS:
      return { ...state, 
                requestParams: {
                  ...state.requestParams,
                  ...action.payload
                }};
    case UPDATE_SELECTED_LOCATIONS:
      return { ...state, selectedLocations: action.selectedLocations };
    case RESET_LOCATIONS:
      return { ...state, selectedLocations: action.allLocations };
    case TARGET_AUDIENCE_PREVIEW_REQUEST:
      return {
        ...state,
        targetAudience: false,
        requestingAudiencePreview: true
      };
    case TARGET_AUDIENCE_PREVIEW_SUCCESS:
      return {
        ...state,
        targetAudience: action.payload.data,
        requestingAudiencePreview: false
      };
    case TARGET_AUDIENCE_PREVIEW_FAILURE:
      return {
        ...state,
        error: action.error,
        requestingAudiencePreview: false
      };
    case UPDATE_SORTED_SEGMENTS_LIST:
      return {
        ...state,
        allSegments: action.segmentsList,
        requestParams: {
          ... state.requestParams,
          sorting_option: action.sortOrder
        }
      };
    default:
      return state;
  }
}
