import {
  GET_ORDER_HISTORY_OF_MERCHANT_REQUEST,
  GET_ORDER_HISTORY_OF_MERCHANT_SUCCESS,
  GET_ORDER_HISTORY_OF_MERCHANT_FAILURE,
} from './types';

export default function (
  state = {
    ordersOfMerchantIsRequesting: false,
    orders: [],
    selectedOrderId: '',
    currentPage: 'list',
    meta: [],
    requestedParams: {
      page_number: 1,
      page_size: '25',
      sorting_option: 'title-asc',
      search_string: '',
      has_next_page: false,
    },
  },
  action
) {
  switch (action.type) {
    case 'UPDATE_ORDER_HISTORY_OF_MERCHANT_ORDERID':
      return {
        ...state,
        selectedOrderId: action.selectedOrderId,
      };
    case 'UPDATE_ORDER_HISTORY_OF_MERCHANT_CURRENT_PAGE':
      return {
        ...state,
        currentPage: action.currentPage,
      };
    case 'UPDATE_ORDER_HISTORY_OF_MERCHANT_REQUESTED_PARAMS':
      return {
        ...state,
        requestedParams: action.requestedParams,
      };
    case GET_ORDER_HISTORY_OF_MERCHANT_REQUEST:
      return {
        ...state,
        ordersOfMerchantIsRequesting: true,
      };
    case GET_ORDER_HISTORY_OF_MERCHANT_SUCCESS:
      return {
        ...state,
        ordersOfMerchantIsRequesting: false,
        orders: action.orders,
        meta: action.meta,
        requestedParams: {
          ...state.requestedParams,
          has_next_page: action.meta.has_next_page,
        },
      };
    case GET_ORDER_HISTORY_OF_MERCHANT_FAILURE:
      return {
        ...state,
        ordersOfMerchantIsRequesting: false,
      };
    case 'UPDATE_ORDER_HISTORY_REQUEST_PARAMS':
      return {
        ...state,
        requestedParams: {
          ...state.requestedParams,
          ...action.payload,
        },
      };
    default:
      return state;
  }
}
