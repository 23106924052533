import styled from 'styled-components';

export const NoData = styled.div`
  position: relative;
  font-size: 34px;
  width: 100%;
  height: 100%;
  opacity: 0.2;
  text-align: center;
  font-weight: bold;
  top: 40%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  margin: auto;
`;

export const ChartCard = styled.div`
  padding: 10px;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.25);
  margin-top: 15px;
  background: ${props => props.theme.white};
  border-radius: 12px;
  ${'' /* display: grid;
  grid-template-rows: 56px auto; */};
`;

export const TitleWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-bottom: 10px;
`;

export const Title = styled.div`
  font-size: 12px;
  font-weight: normal;
`;

export const TotalWrapper = styled.div`
  text-align: right;
  padding-right: 18px;
  font-size: 16px;
  align-self: center;
`;

export const TitleSpan = styled.span`
  font-weight: 700;
  font-size: 16px;
`;

export const Charts = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  grid-gap: 16px;
`;

export const TimePeriod = styled.div`
  display: grid;
  font-size: 16px;
  justify-content: flex-start;
`;
