import {
  GET_PRODUCTS,
  GET_PRODUCTS_MAPPING_FIELDS,
  GET_PRODUCTS_CATEGORIES,
  GET_PRODUCTS_FILTERED_CATEGORY,
  SET_SELECTED_VALUE_POS,
  GET_CURRENT_PRODUCT_POS_MAPPING_VALUES,
  SET_IS_REQUESTING_PRODUCT_POS_MAPPING_VALUES,
  GET_POS_COMBOS,
  GET_POS_OFFERS,
  GET_POS_COUPONS,
  GET_ALL_COUPONS_POS,
  GET_DISCOUNT_MAPPING_FIELDS,
  RESET_SEARCH_VALUE,
  SET_SEARCH_VALUE,
  POS_DISCOUNT_MAPPING_FIELDS_NOT_AVAILABLE,
  GET_DISCOUNT_MAPPING_FIELDS_REQUEST,
  GET_ITEMS_POS_VALUES_REQUEST,
  GET_ITEMS_POS_VALUES_SUCCESS,
  GET_ITEMS_MAPPING_FIELDS_SUCCESS,
  GET_ITEMS_MAPPING_FIELDS_REQUEST,
  GET_POS_COMBO_ITEM_GROUP,
  GET_POS_COMBO_ITEM_GROUP_TYPE,
  GET_POS_COMBO_ITEM_GROUP_POS_MAPPING,
  UPDATE_POS_COMBO_ITEM_GROUP,
  GET_SPECIFIC_POS_COMBOS,
} from './posTypes';
import { api } from '../../../api';
import { createAlert } from '../../Alert/actions';
import { renderItemsModified } from './ItemsMapping/helper/helper';
import axios from 'axios';
import _ from 'lodash';
import { getMissingCombosPOSMapping } from '../../Full/actions';
import { newOfferEngineClients } from '../../../utils/clientCustomizations';
import { getSearchUrl } from '../../../utils/purePayload';
const CancelToken = axios.CancelToken;
let source;

export function getProducts(
  requestParams = {
    page_number: 1,
    page_size: '25',
    sorting_option: 'title-asc',
    search_string: '',
    has_next_page: true,
  }
) {
  return function (dispatch) {
    if (source) {
      source.cancel('Operation canceled by the user.');
    }

    source = CancelToken.source();

    let urlwithOutSearchString = `/menu/products?include=prices&page_number=${1}&page_size=${500}`;
    const url = getSearchUrl(urlwithOutSearchString, requestParams.search_string, 3);
    
    return api
      .get(url, {
        cancelToken: source.token,
      })
      .then((response) => {
        const allProducts = response.data.data.map((product) => {
          const productVariants = product.prices.map((variant) => ({
            variantId: variant.product_variant_id,
            variantName: variant.name,
          }));

          return {
            productId: product.id,
            productName: product.name,
            productImageUrl: product.image_url,
            productIsActive: product.is_active,
            productVariants,
          };
        });

        dispatch({
          type: GET_PRODUCTS,
          payload: allProducts,
          meta: response.data.meta,
        });
      })
      .catch();
  };
}

export const setIsRequestingProductPosMappings = () => {
  return (dispatch) => {
    dispatch({
      type: SET_IS_REQUESTING_PRODUCT_POS_MAPPING_VALUES,
    });
  };
};

export function getProductsMappingFields(productId) {
  return function (dispatch) {
    return api
      .get(`/pos-mapping-fields/product?page_number=1&page_size=4000`)
      .then((response) => {
        const fields = response.data.data.reduce((acc, cur, i) => {
          acc[cur.key] = cur;
          return acc;
        }, {});
        dispatch({
          type: GET_PRODUCTS_MAPPING_FIELDS,
          payload: fields,
        });
        if (productId) {
          dispatch(getProductPosMappingValues(productId, fields));
        }
      })
      .catch((err) => {
        dispatch(
          createAlert({
            type: 'error',
            message: err?.response?.data?.errors?.message || 'An error occurred trying to get POS fields',
          })
        );
      });
  };
}

export function getProductPosMappingValues(productId, productsMappingFields) {
  return function (dispatch) {
    return api
      .get(`/menu/products/${productId}?include=prices,pos_mappings`)
      .then((response) => {
        const default_values = {};
        for (let [key, field] of Object.entries(productsMappingFields)) {
          default_values[key] = field.type === 'boolean' ? false : '';
        }

        let mofifiedMappings = { mappings: {} };
        const returnedMappings = response.data.data.pos_mappings.reduce(
          (acc, item) => {
            acc[item['product_variant_id']] = item;
            return acc;
          },
          {}
        );

        response.data.data.prices.map((variant) => {
          if (returnedMappings[variant.product_variant_id]) {
            mofifiedMappings['mappings'][variant.product_variant_id] =
              returnedMappings[variant.product_variant_id];
          } else {
            mofifiedMappings['mappings'][variant.product_variant_id] =
              default_values;
          }
        });
        dispatch({
          type: GET_CURRENT_PRODUCT_POS_MAPPING_VALUES,
          mappings: mofifiedMappings,
        });
      })
      .catch((err) => {
        dispatch(
          createAlert({
            type: 'error',
            message: err?.response?.data?.errors?.message || 'An error occurred trying to get POS values',
          })
        );
      });
  };
}

export function submitProductPosMappingValues(
  product,
  fields,
  mappings,
  callback
) {
  return function (dispatch) {
    const productId = product.productId;

    let data = [];
    const reqFields = [];
    const whiteFields = [];
    mappingsLoop: for (let [key, values] of Object.entries(
      mappings['mappings']
    )) {
      if (values === undefined) continue;

      Object.keys(fields).map((key) => {
        const value = values[key];

        if (
          typeof value !== 'boolean' &&
          value == '' &&
          !fields[key].optional &&
          !reqFields.includes(fields[key].label)
        ) {
          reqFields.push(fields[key].label);
        }
        if (typeof value !== 'boolean' && value && value.trim() == '')
          whiteFields.push('White Space');
      });

      let item = {
        product_variant_id: parseInt(key),
      };
      for (let [field, field_data] of Object.entries(fields)) {
        if (
          field_data.type !== 'boolean' &&
          (values[field] == undefined || values[field] == '')
        ) {
          delete item[field];
        } else item[field] = values[field];
      }

      data.push(item);
      continue mappingsLoop;
    }

    if (reqFields.length) {
      const message =
        reqFields.length == 1 ? ' is required!' : ' are required!';
      dispatch(
        createAlert({
          type: 'error',
          message: reqFields.toString() + message,
        })
      );
      return;
    }

    if (whiteFields.length) {
      dispatch(
        createAlert({
          type: 'error',
          message: 'Empty spaces are not allowed!',
        })
      );
      return;
    }

    return api
      .put(`/menu/products/${productId}/pos-mapping`, { mappings: data })
      .then((response) => {
        dispatch(
          createAlert({
            type: 'success',
            message: 'POS values were saved successfully.',
          })
        );

        const selectedValue = callback();
        if (selectedValue) {
          dispatch(getProductPosMappingValues(selectedValue, fields));
        }
      })
      .catch((err) => {
        dispatch(
          createAlert({
            type: 'error',
            message: err?.response?.data?.errors?.message || 'An error occurred trying to saving POS values.',
          })
        );
      });
  };
}

export function getProductsCategories() {
  return function (dispatch) {
    return api
      .get('/menu/categories?page_number=1&page_size=2000')
      .then((response) => {
        dispatch({
          type: GET_PRODUCTS_CATEGORIES,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        dispatch(
          createAlert({
            type: 'error',
            message: err?.response?.data?.errors?.message || 'An error occurred trying to get categories',
          })
        );
      });
  };
}

export function getProductsFilteredCategory(category) {
  return function (dispatch) {
    return api
      .get(
        `/menu/categories/${category.id}?include=products.prices,sub_categories.products.prices`
      )
      .then((response) => {
        const finalProductsList = response.data.data.products.map(
          (product, index) => {
            const productVariants = product.prices.map((variant) => ({
              variantId: variant.product_variant_id,
              variantName: variant.name,
              variantIsActive: variant.is_active,
            }));

            return {
              productId: product.id,
              productName: product.name,
              productImageUrl: product.image_url,
              productIsActive: product.is_active,
              productVariants,
            };
          }
        );
        dispatch({
          type: GET_PRODUCTS_FILTERED_CATEGORY,
          payload: finalProductsList,
        });
      })
      .catch((error) => {
        dispatch(
          createAlert({
            type: 'error',
            message: error?.response?.data?.errors?.message || 'An error occurred getting product variants',
          })
        );
      });
  };
}

export function getAllProducts(
  requestParams = {
    page_number: 1,
    page_size: '4000',
    sorting_option: 'title-asc',
    search_string: '',
    has_next_page: true,
  }
) {
  return function (dispatch) {
    const urlwithOutSearchString=`/menu/products?include=prices&page_number=${requestParams.page_number}&page_size=${requestParams.page_size}`;
    const url = getSearchUrl(urlwithOutSearchString, requestParams.search_string, 3);
    return api
      .get(url)
      .then((response) => {
        dispatch({
          type: 'GET_ALL_PRODUCTS',
          payload: response.data.data,
        });
      });
  };
}

// POS fields for discounts
export function getPOSDiscountsMappingFields() {
  return function (dispatch) {
    dispatch({
      type: GET_DISCOUNT_MAPPING_FIELDS_REQUEST,
    });
    return api
      .get('/pos-mapping-fields/discount?page_number=1&page_size=10')
      .then((response) => {
        const fields = response.data.data.reduce((acc, cur, i) => {
          acc[cur.key] = cur;
          return acc;
        }, {});
        dispatch({
          type: GET_DISCOUNT_MAPPING_FIELDS,
          payload: fields,
        });
      })
      .catch((err) => {
        if (err.response.data.errors.http_code === 400) {
          dispatch({
            type: POS_DISCOUNT_MAPPING_FIELDS_NOT_AVAILABLE,
          });
        } else {
          dispatch(
            createAlert({
              type: 'error',
              message: err?.response?.data?.errors?.message || 
                'An error occurred trying to get POS Mapping Discounts fields',
            })
          );
        }
      });
  };
}

export function validatePOSFields(
  valuesModified,
  posDiscountsMappingFields,
  type
) {
  // let valuesModified = '';
  // if (type === 'combo') {
  //   valuesModified = values.combos;
  // }
  // if (type === 'offer') {
  //   valuesModified = values;
  // }
  // if (type === 'coupon') {
  //   valuesModified = values.coupons;
  // }
  let notAllFilled = true;
  for (var i = 0; i < valuesModified.length; i++) {
    let data = [];
    Object.keys(posDiscountsMappingFields).map((val) => {
      if (
        !valuesModified[i].pos_mapping[val] ||
        valuesModified[i].pos_mapping[val] == ''
      ) {
        data.push('Empty');
      }
    });

    if (
      data.length > 0 &&
      data.length < Object.keys(posDiscountsMappingFields).length
    ) {
      notAllFilled = false;
      break;
    }
  }
  return notAllFilled;
}

export function validateWhiteSpaces(valuesModified, posDiscountsMappingFields) {
  let whiteSpaces = false;
  for (var i = 0; i < valuesModified.length; i++) {
    let data = [];
    Object.keys(posDiscountsMappingFields).map((val) => {
      if (
        !valuesModified[i].pos_mapping[val] == '' &&
        /\s/.test(valuesModified[i].pos_mapping[val]) &&
        val != 'pos_discount_name'
      ) {
        data.push('Empty');
      }
    });

    if (data.length) {
      whiteSpaces = true;
      break;
    }
  }
  return whiteSpaces;
}

//Actions for combos

export function getCombos(
  { searchString, page_number, page_size },
  search = false,
  newValue
) {
  const searchStringWrapped = encodeURIComponent(searchString);
  return function (dispatch) {
    const urlwithOutSearchString=`/combos?page_number=${page_number}&include=businesses&page_size=${2000}`;
    const url = getSearchUrl(urlwithOutSearchString, searchStringWrapped, 3);

    return api
      .get(url)
      .then((response) => {
        if (newValue) {
          if (response.data.data.length > 1) {
            dispatch({
              type: GET_POS_COMBOS,
              payload: response.data.data.filter(
                (response) => response.name === newValue.label
              ),
              meta: response.data.meta,
            });
          } else
            dispatch({
              type: GET_POS_COMBOS,
              payload: response.data.data,
              specificPOSCombos: null,
              meta: response.data.meta,
            });
        } else
          dispatch({
            type: GET_POS_COMBOS,
            payload: response.data.data,
            meta: response.data.meta,
            specificPOSCombos: null,
          });
      });
  };
}
export function getSpecificCombos(newValue) {
  // const searchStringWrapped = encodeURIComponent(searchString);
  return function (dispatch) {
    const urlwithOutSearchString=`/combos?page_number=${1}&include=businesses&page_size=${2000}`;
    const url = getSearchUrl(urlwithOutSearchString, '', 3);
    return api
      .get(url)
      .then((response) => {
        if (response?.data?.data?.length > 0 && newValue)
          dispatch({
            type: GET_SPECIFIC_POS_COMBOS,
            specificPOSCombos: response.data.data.filter(
              (res) => res?.name === newValue?.name
            ),
            meta: response.data.meta,
          });
      });
  };
}

export function getComboItemGroupType(
  requestParams = { page_number: 1, page_size: 2000 }
) {
  // const searchStringWrapped = encodeURIComponent(searchString);
  return function (dispatch) {
    return api
      .get(
        `/pos-mapping-fields/combo-item-group?page_number=${requestParams.page_number}&page_size=${requestParams.page_size}`
      )
      .then((response) => {
        dispatch({
          type: GET_POS_COMBO_ITEM_GROUP_TYPE,
          comboItemsGroupType: response.data.data,
        });
      });
  };
}
export function getComboItemGroup(comboId) {
  // const searchStringWrapped = encodeURIComponent(searchString);
  return function (dispatch) {
    return api
      .get(`/combos/${comboId}/item-groups?include=pos_mappings`)
      .then((response) => {
        dispatch({
          type: GET_POS_COMBO_ITEM_GROUP,
          comboItemsGroup: response.data.data,
        });
      });
  };
}
export function getComboItemGroupPOSMapping(comboId, itemGroupId) {
  // const searchStringWrapped = encodeURIComponent(searchString);
  return function (dispatch) {
    return api
      .get(`/combos/${comboId}/item-groups/${itemGroupId}?include=pos_mappings`)
      .then((response) => {
        dispatch({
          type: GET_POS_COMBO_ITEM_GROUP_POS_MAPPING,
          comboItemsGroupPOSMapping: response.data.data.pos_mappings,
          selecteItemGroup: response.data.data,
        });
      });
  };
}
export function updateComboItemGroupPOSMapping(comboId, mappings) {
  return function (dispatch) {
    return api
      .put(`/combos/${comboId}/item-groups/pos-mapping`, mappings)
      .then((response) => {
        dispatch({
          type: UPDATE_POS_COMBO_ITEM_GROUP,
        });
        dispatch(
          createAlert({
            type: 'success',
            message: 'The POS values are updated successfully',
          })
        );
        dispatch(getComboItemGroup(comboId));
      });
  };
}

export function submitPOSDiscountValuesCombos(
  values,
  type,
  initialValues,
  selectedValue,
  newValue,
  newSearch,
  page_size,
  page_number,
  posDiscountsMappingFields
) {
  return function (dispatch, getState) {
    const appId = getState().accountReducer.appMetaData.app_id;

    values = values.combos;

    const valuesModified = formValuesModified(
      posDiscountsMappingFields,
      values
    );

    const initialValuesModified = formValuesModified(
      posDiscountsMappingFields,
      initialValues.combos
    );

    if (!validatePOSFields(valuesModified, posDiscountsMappingFields, type)) {
      dispatch(
        createAlert({
          type: 'error',
          message:
            'Please fill in all fields of a variant or leave them empty.',
        })
      );
      return;
    }

    if (validateWhiteSpaces(valuesModified, posDiscountsMappingFields)) {
      dispatch(
        createAlert({
          type: 'error',
          message: 'Empty spaces are not allowed!',
        })
      );
      return;
    }

    return Promise.all(
      promiseArrays(
        initialValuesModified,
        valuesModified,
        type,
        posDiscountsMappingFields,
        appId
      )
    )
      .then((response) => {
        dispatch(
          createAlert({
            type: 'success',
            message: 'The POS values are updated successfully',
          })
        );
        dispatch(getMissingCombosPOSMapping());
        if (newValue)
          dispatch(
            getCombos(
              {
                searchString: newValue.label,
                page_number: 1,
                page_size: page_size,
              },
              true
            )
          );
        else if (newSearch) dispatch(getCombos(newSearch, true));
        else {
          dispatch(
            getCombos({
              searchString: newSearch ? newSearch.label : '',
              page_number,
              page_size,
            })
          );
        }
      })
      .catch((err) => {
        dispatch(
          createAlert({
            type: 'error',
            message: err?.response?.data?.errors?.message || 'An error occurred updating POS values',
          })
        );
      });
  };
}

export function promiseArrays(
  initialValuesModified,
  valuesModified,
  type,
  posDiscountsMappingFields,
  appId
) {
  let initialValueId = '';
  let valueId = '';

  const idPaths = {
    combo: 'id',
    coupon: 'coupon_details.coupon_id',
    offer: 'offer_details.offer_id',
  };
  const idPath = idPaths[type];

  // new
  const oldArray = initialValuesModified;
  const newArray = valuesModified;
  const attributes = Object.keys(posDiscountsMappingFields);
  const finalValues = [];

  for (let i = 0; i < oldArray.length; i++) {
    const oldObj = oldArray[i];
    initialValueId = _.get(oldObj, idPath);
    const newObj = newArray.find((el) => _.get(el, idPath) === initialValueId);
    if (!newObj) continue;
    valueId = _.get(newObj, idPath);

    const oldValues = Object.keys(oldObj.pos_mapping).reduce((acc, key) => {
      if (attributes.includes(key) /*&& oldObj.pos_mapping[key]*/)
        acc.push(oldObj.pos_mapping[key]);

      return acc;
    }, []);
    const newValues = Object.keys(newObj.pos_mapping).reduce((acc, key) => {
      if (attributes.includes(key) && newObj.pos_mapping[key])
        acc.push(newObj.pos_mapping[key]);

      return acc;
    }, []);

    let reqType;

    if (!oldValues.length && newValues.length) reqType = 'post';
    else if (oldValues.length && !newValues.length) reqType = 'delete';
    else if (!oldValues.length && !newValues.length) reqType = 'do not send';
    else reqType = 'patch';

    if (reqType === 'post') {
      const discountData = {
        ...newObj.pos_mapping,
        type: type,
        discount_id: valueId,
        app_id: appId,
      };
      finalValues.push(api.post(`/discount-mapping`, discountData));
    } else if (reqType === 'patch') {
      const discountData = newObj.pos_mapping;
      delete discountData.app_id;
      delete discountData.type;
      finalValues.push(
        api.patch(`/discount-mapping/${valueId}/${type}`, discountData)
      );
    } else if (reqType === 'delete') {
      finalValues.push(api.delete(`/discount-mapping/${valueId}/${type}`));
    }
  }
  return finalValues;
}

//Actions for coupons
export function getCoupons(
  { searchString, page_number, page_size },
  search = false
) {
  const searchStringWrapped = encodeURIComponent(searchString);
  return function (dispatch) {
    const urlwithOutSearchString=`/coupons?page_number=${page_number}&page_size=${2000}`;
    const url = getSearchUrl(urlwithOutSearchString, searchStringWrapped, 3);
    return api
      .get(url)
      .then((response) => {
        dispatch({
          type: GET_POS_COUPONS,
          payload: response.data.data,
          meta: response.data.meta,
        });
        if (!search)
          dispatch({
            type: RESET_SEARCH_VALUE,
          });
        if (search)
          dispatch(
            setSelectedValuePOS(
              response.data.data.map((coupon, index) => ({ ...coupon, index }))
            )
          );
      });
  };
}

export function formValuesModified(posDiscountsMappingFields, values) {
  const finalFields = [
    ...Object.keys(posDiscountsMappingFields),
    'type',
    'app_id',
    'discount_id',
  ];

  let valuesModified = values.map((el, index) => ({
    ...el,
    pos_mapping: Object.keys(el.pos_mapping).reduce((acc, key) => {
      if (finalFields.includes(key)) {
        acc[key] = el.pos_mapping[key];
      }
      return acc;
    }, {}),
  }));

  return valuesModified;
}

export function submitPOSDiscountValuesCoupons(
  values,
  type,
  initialValues,
  selectedValue,
  newValue,
  newSearch,
  page_size,
  page_number,
  posDiscountsMappingFields
) {
  return function (dispatch, getState) {
    const appId = getState().accountReducer.appMetaData.app_id;

    values = values.coupons;

    const valuesModified = formValuesModified(
      posDiscountsMappingFields,
      values
    );

    const initialValuesModified = formValuesModified(
      posDiscountsMappingFields,
      initialValues.coupons
    );
    if (!validatePOSFields(valuesModified, posDiscountsMappingFields, type)) {
      dispatch(
        createAlert({
          type: 'error',
          message:
            'Please fill in all fields of a variant or leave them empty.',
        })
      );
      return;
    }

    if (validateWhiteSpaces(valuesModified, posDiscountsMappingFields)) {
      dispatch(
        createAlert({
          type: 'error',
          message: 'Empty spaces are not allowed!',
        })
      );
      return;
    }

    return Promise.all(
      promiseArrays(
        initialValuesModified,
        valuesModified,
        type,
        posDiscountsMappingFields,
        appId
      )
    )
      .then((response) => {
        dispatch(
          createAlert({
            type: 'success',
            message: 'The POS values are updated successfully',
          })
        );
        if (newValue)
          dispatch(
            getCoupons(
              {
                searchString: newValue.coupon_details.label,
                page_number: 1,
                page_size: page_size,
              },
              true
            )
          );
        else if (newSearch) dispatch(getCoupons(newSearch, true));
        else {
          dispatch(
            getCoupons({
              searchString: newSearch ? newSearch.label : '',
              page_number,
              page_size,
            })
          );
        }
      })
      .catch((err) => {
        dispatch(
          createAlert({
            type: 'error',
            message: err?.response?.data?.errors?.message || 'An error occurred updating POS values',
          })
        );
      });
  };
}

//Actions for offers
export function getOffers(
  { searchString, page_number, page_size },
  search = false
) {
  let searchStringWrapped = '';
  if (searchString === 'ALL OFFERS') searchStringWrapped = '';
  searchStringWrapped = encodeURIComponent(searchString);
  return function (dispatch) {
    const newOfferClients = newOfferEngineClients;
    let appIdentifier = localStorage.getItem('appIdentifier');
    const urlwithOutSearchStringIncAppIden=`v2/offers?page_number=${page_number}&page_size=${page_size}&include=pos_mappings`;
    const urlIncAppIden = getSearchUrl(urlwithOutSearchStringIncAppIden, searchStringWrapped, 3);

    const urlwithOutSearchString=`/offers?page_number=${page_number}&page_size=${page_size}`;
    const url = getSearchUrl(urlwithOutSearchString, searchStringWrapped, 3);

    const endPoint = newOfferClients.includes(appIdentifier)
      ? urlIncAppIden
      : url;
    return api.get(endPoint).then((response) => {
      dispatch({
        type: GET_POS_OFFERS,
        payload: response.data.data,
        meta: response.data.meta,
      });
    });
  };
}
const offerUpdateList = (
  posDiscountsMappingFields,
  oldValues,
  newValues,
  type,
  appId
) => {
  const apiList = [];
  let discountPatchList = [];
  let offerPatchList = [];
  let deleteList = [];
  let postList = [];
  const posKeys = Object.keys(posDiscountsMappingFields);
  for (var i = 0; i < oldValues.length; i++) {
    const discountData = {
      ...newValues[i].pos_mapping,
      type: type,
      discount_id: oldValues[i].offer_id,
      app_id: appId,
    };
    // check having new pos
    const hasNewValue =
      (!oldValues[i].pos_mapping ||
        Object.keys(oldValues[i].pos_mapping).length === 0) &&
      Object.keys(newValues[i].pos_mapping).length > 0;

    // check pos has updated

    const bothHaveValues =
      oldValues[i].pos_mapping &&
      Object.keys(oldValues[i].pos_mapping).length > 0 &&
      Object.keys(newValues[i].pos_mapping).length > 0;
    let hasUpdatedValues = false;
    let hasDeletedValue = [];
    posKeys.forEach((key) => {
      if (
        oldValues[i].pos_mapping[key] !== newValues[i].pos_mapping[key] &&
        newValues[i].pos_mapping[key] !== ''
      ) {
        hasUpdatedValues = true;
      }
      if (
        oldValues[i].pos_mapping[key] !== newValues[i].pos_mapping[key] &&
        newValues[i].pos_mapping[key] === ''
      ) {
        hasDeletedValue.push(true);
      }
    });
    if (hasNewValue) {
      apiList.push(api.post(`/discount-mapping`, discountData));
    } else if (bothHaveValues && hasUpdatedValues) {
      delete discountData.app_id;
      delete discountData.type;
      apiList.push(
        api.patch(
          `/discount-mapping/${discountData.discount_id}/${type}`,
          discountData
        )
      );
    } else if (hasDeletedValue.length === posKeys.length) {
      apiList.push(
        api.delete(`/discount-mapping/${discountData.discount_id}/${type}`)
      );
    }
  }
  return apiList;
};
export function submitPOSDiscountValuesOffers(
  values,
  type,
  initialValues,
  selectedValue,
  newValue,
  newSearch,
  page_size,
  page_number,
  posDiscountsMappingFields
) {
  return function (dispatch, getState) {
    const appId = getState().accountReducer.appMetaData.app_id;
    values = values.offers;
    const initialValuesModified = formValuesModified(
      posDiscountsMappingFields,
      initialValues.offers
    );
    const valuesModified = formValuesModified(
      posDiscountsMappingFields,
      values
    );
    if (!validatePOSFields(valuesModified, posDiscountsMappingFields, type)) {
      dispatch(
        createAlert({
          type: 'error',
          message:
            'Please fill in all fields of a variant or leave them empty.',
        })
      );
      return;
    }

    if (validateWhiteSpaces(valuesModified, posDiscountsMappingFields)) {
      dispatch(
        createAlert({
          type: 'error',
          message: 'Empty spaces are not allowed!',
        })
      );
      return;
    }
    Promise.all(
      offerUpdateList(
        posDiscountsMappingFields,
        initialValuesModified,
        valuesModified,
        type,
        appId
      )
    )
      .then((response) => {
        dispatch(
          createAlert({
            type: 'success',
            message: 'The POS values are updated successfully',
          })
        );
        const reqParams = {
          searchString: newValue?.label || newSearch || '',
          page_number: 1,
          page_size: page_size,
          // page_number: meta.page_number,
          // page_size: meta.page_size
        };
        dispatch(getOffers(reqParams, true));
      })
      .catch((error) => {
        dispatch(
          createAlert({
            type: 'error',
            message: error?.response?.data?.errors?.message || 'An error occurred updating POS values',
          })
        );
      });
  };
}

export function getAllCoupons() {
  return function (dispatch) {
    const urlwithOutSearchString=`/coupons?page_number=1&page_size=2000`;
    const url = getSearchUrl(urlwithOutSearchString, '', 3);
    return api
      .get(url)
      .then((response) => {
        dispatch({
          type: GET_ALL_COUPONS_POS,
          payload: response.data.data,
        });
      });
  };
}

export function setSelectedValuePOS(value) {
  return {
    type: SET_SELECTED_VALUE_POS,
    payload: value,
  };
}

export function setSearchValue(value) {
  return {
    type: SET_SEARCH_VALUE,
    payload: value,
  };
}

export function getItemsMappingFields() {
  return function (dispatch) {
    dispatch({
      type: GET_ITEMS_MAPPING_FIELDS_REQUEST,
    });
    return api
      .get('/pos-mapping-fields/item?page_number=1&page_size=1000')
      .then((response) => {
        const fields = response.data.data.reduce((acc, cur, i) => {
          acc[cur.key] = cur;
          return acc;
        }, {});
        dispatch({
          type: GET_ITEMS_MAPPING_FIELDS_SUCCESS,
          payload: fields,
        });
      })
      .catch((err) => {
        dispatch(
          createAlert({
            type: 'error',
            message: err?.response?.data?.errors?.message || 
              'An error occurred trying to get POS Mapping Discounts fields',
          })
        );
        //}
      });
  };
}

export function getItemsPOSValues({ selectedOption, variants, optionPrices }) {
  return function (dispatch) {
    if (selectedOption) {
      dispatch({
        type: GET_ITEMS_POS_VALUES_REQUEST,
      });
      const urlwithOutSearchString=`/menu/options/${selectedOption.id}/items?include=pos_mappings,sub_items.pos_mappings&page_size=1000&page_number=1`;
      const url = getSearchUrl(urlwithOutSearchString, '', 3);
      api
        .get(url)
        .then((response) => {
          dispatch({
            type: GET_ITEMS_POS_VALUES_SUCCESS,
            payload: renderItemsModified(
              response.data.data,
              variants,
              optionPrices
            ),
            meta: response.data.meta,
          });
        });
    }
  };
}

export function submitPOSValuesItems(
  values,
  itemsMappingFields,
  selectedOption,
  variants,
  optionPrices
) {
  return function (dispatch) {
    let finalArr = [];
    let reqFields = [];
    let whiteFields = [];

    values.items.map((item, index) => {
      const variantIds = item.prices.map((variant) => variant.variant_id);

      Object.keys(item.pos_mappings).map((key) => {
        if (!variantIds.includes(parseInt(key))) {
          delete item.pos_mappings[key];
        }
      });

      let data = [];
      mappingsLoop: for (let [key, values] of Object.entries(
        item.pos_mappings
      )) {
        if (values === undefined) continue;

        Object.keys(itemsMappingFields).map((key) => {
          const value = values[key];

          if (
            typeof value !== 'boolean' &&
            (value == '' || value == null) &&
            !itemsMappingFields[key].optional &&
            !reqFields.includes(itemsMappingFields[key].label)
          ) {
            reqFields.push(itemsMappingFields[key].label);
          }
          if (typeof value !== 'boolean' && value && value.trim() == '')
            whiteFields.push('White Space');
        });

        let item = {
          product_variant_id: parseInt(key),
        };
        for (let [field, field_data] of Object.entries(itemsMappingFields)) {
          if (
            field_data.type !== 'boolean' &&
            (values[field] == undefined || values[field] == '')
          ) {
            delete item[field];
          } else item[field] = values[field];
        }

        data.push(item);
        continue mappingsLoop;
      }

      data = data.filter((d) => variantIds.includes(d.product_variant_id));

      if (reqFields.length) {
        const message =
          reqFields.length == 1 ? ' is required!' : ' are required!';
        dispatch(
          createAlert({
            type: 'error',
            message: reqFields.toString() + message,
          })
        );
        return;
      }

      if (whiteFields.length) {
        dispatch(
          createAlert({
            type: 'error',
            message: 'Empty spaces are not allowed!',
          })
        );
        return;
      }

      finalArr.push(
        api.put(
          `/menu/options/${item.product_option_id}/items/${item.id}/pos-mapping`,
          { mappings: data }
        )
      );
    });

    if (reqFields.length) return;
    if (whiteFields.length) return;

    Promise.all(finalArr)
      .then((responses) => {
        dispatch(
          getItemsPOSValues({
            selectedOption,
            variants,
            optionPrices,
          })
        );
        dispatch(
          createAlert({
            type: 'success',
            message: 'POS values were saved successfully.',
          })
        );
      })
      .catch((err) => {
        dispatch(
          createAlert({
            type: 'error',
            message: err?.response?.data?.errors?.message || 'An error occurred trying to update POS values.',
          })
        );
      });
  };
}

export function updateItemsRequestParams(requestParams) {
  return {
    type: 'UPDATE_ITEMS_REQUEST_PARAMS',
    payload: requestParams,
  };
}
