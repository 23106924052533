import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../productsActions';
import ProductOptionsForm from '../components/ProductOptionsForm';
import OptionsSortModal from '../components/OptionsSortModal';

const ProductOptionsFormContainer = (props) => {
  const [isSortModalOpen, setIsSortModalOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [productOptionsAreEditable, setProductOptionsAreEditable] =
    useState(false);
  useEffect(() => {
    if (!props.productOptionsInitialValues.length) {
      setProductOptionsAreEditable(true);
    }
  }, [props.productOptionsInitialValues]);
  const [confirmCancelModalIsVisible, setConfirmCancelModalIsVisible] =
    useState(false);
  return (
    <>
      <ProductOptionsForm
      setIsSortModalOpen={setIsSortModalOpen}
      isExpanded={isExpanded}
      disabled={!props.productId}
      setIsExpanded={setIsExpanded}
      initialValues={{ productOptions: props.productOptionsInitialValues }}
      allOptions={props.allOptions}
      productOptionsAreEditable={productOptionsAreEditable}
      setProductOptionsAreEditable={setProductOptionsAreEditable}
      setConfirmCancelModalIsVisible={setConfirmCancelModalIsVisible}
      confirmCancelModalIsVisible={confirmCancelModalIsVisible}
      putProductOptions={props.putProductOptions}
      optionWithDefault={props.optionWithDefault}
      putProductOptionsDefaultCustomization={
        props.putProductOptionsDefaultCustomization
      }
    />
    {isSortModalOpen && (
          <OptionsSortModal
            productId={props.productId}
            sortAssignedOptions={props.sortAssignedOptions}
            options={props.optionWithDefault}
            setIsSortModalOpen={setIsSortModalOpen}
            isSortingRequested={props.isSortingRequested}
            formTypeName="options"
            formHeadName="option"
          />
        )}
    </>
    
  );
};

const mapStateToProps = (state) => ({
  productOptionsInitialValues:
    state.productsReducer.productOptionsInitialValues,
  allOptions: state.productsReducer.allOptions,
  productId: state.productsReducer.productId,
  isSortingRequested: state.productsReducer.isSortingRequested,
});

export default connect(mapStateToProps, actions)(ProductOptionsFormContainer);
