import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Field, FastField } from 'formik';
import TextField from '@material-ui/core/TextField';
import {
  RenderTextField,
  required,
} from '../../../../components/FormElements/FormikElements';

const CustomField = styled.div``;

const styles = (theme) => ({
  cssLabel: {
    color: '#2949cb',
  },

  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: `${theme.palette.primary.main} !important`,
    },
  },

  cssFocused: {},

  notchedOutline: {
    borderWidth: '1px',
    borderColor: '#2949cb !important',
  },
});

const TextFieldPOS = ({
  value,
  name,
  classes,
  styles,
  maxLength,
  setFieldValue,
}) => {
  const [error, setError] = useState(null);
  const [posValue, setPosValue] = useState(value);
  useEffect(() => {
    setPosValue(value);
  }, [value]);
  const validate = (value, maxLength) => {
    if (
      maxLength !== undefined &&
      value.trim() !== '' &&
      value.length > maxLength
    ) {
      setError(`Max ${maxLength} chars`);
      // return `Max ${maxLength} chars`;
    }
    setError();
  };
  const handleChange = (e) => {
    setPosValue(e.target.value);
    setFieldValue(name, e.target.value);
    validate(e.target.value, maxLength);
  };
  return (
    <CustomField>
      <TextField
        error={error}
        id="outlined-error-helper-text"
        name={name}
        value={posValue}
        onChange={handleChange}
        label="POS Value"
        helperText={error}
        variant="outlined"
      />
    </CustomField>
  );
};

TextFieldPOS.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TextFieldPOS);
