import React, { Fragment } from 'react';
import GoogleMapsWithOverlayZones from '../../../../../GoogleMapsWithOverlayZones/GoogleMapsWithOverlayZones';
import styled from 'styled-components';
import Checkbox from '@material-ui/core/Checkbox';
import { Button } from 'merchant-portal-components';
import DeliveryZonesViewTable from './DeliveryZoneViewTable';
import {
  ComponentCard,
  ControlShowShapesContainer,
  ShapeNameGrid,
  ShapeName,
  StyledP,
} from '../common/styledComponents';

const DeliveryZoneDetailsContainer = styled.div`
  padding: 32px;
`;

const LocationNameContainer = styled.div`
  padding: 15px 0;
`;

const PreviewContainer = styled.div`
  padding: 45px 0;
`;

const PreviewGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
`;

const CreateDeliveryZoneButtonContainer = styled.div`
  margin-bottom: 15px;
  text-align: right;
`;

const DeliveryZonesView = (props) => {
  const {
    deliveryZoneObjects,
    editingColour,
    handleDeliveryZoneVisibility,
    handleCreateNewButtonClick,
    handleEditIconClick,
    handleDeleteIconClick,
    displayName,
    locationCoords,
    isBusinessAdminRole,
  } = props;
  console.log('isBusinessAdminRole[4]=>', isBusinessAdminRole);
  return (
    <Fragment>
      <CreateDeliveryZoneButtonContainer>
        <Button
          onClick={() => handleCreateNewButtonClick()}
          disabled={isBusinessAdminRole}
        >
          Create New
        </Button>
      </CreateDeliveryZoneButtonContainer>
      <div>
        <DeliveryZoneDetailsContainer>
          <StyledP semiBold size='18px'>
            View or Edit delivery zone(s) and set fees for this Location
          </StyledP>
          <PreviewContainer>
            <StyledP semiBold size='18px'>
              Preview of Delivery Zones
            </StyledP>
            <PreviewGrid>
              <ControlShowShapesContainer>
                <StyledP padding='10px 0 0 0' semiBold>
                  Show:
                </StyledP>

                {deliveryZoneObjects.map((deliveryZone, index) => (
                  <ShapeNameGrid key={index}>
                    <Checkbox
                      color='primary'
                      checked={deliveryZone.isVisible}
                      onChange={(event) =>
                        handleDeliveryZoneVisibility(deliveryZone, event)
                      }
                      disabled={isBusinessAdminRole}
                    />
                    <ShapeName>
                      <StyledP semiBold isVisible={deliveryZone.isVisible}>
                        {deliveryZone.zoneName}
                      </StyledP>
                    </ShapeName>
                  </ShapeNameGrid>
                ))}
              </ControlShowShapesContainer>
              {deliveryZoneObjects ? (
                <GoogleMapsWithOverlayZones
                  overlayObjects={deliveryZoneObjects}
                  maxDrawnOverlaysAllowed={1}
                  editingColour={editingColour}
                  center={locationCoords}
                  selectedLocations={[locationCoords]}
                  zoom={15}
                />
              ) : null}
            </PreviewGrid>
          </PreviewContainer>
        </DeliveryZoneDetailsContainer>
        <DeliveryZonesViewTable
          deliveryZoneObjects={deliveryZoneObjects}
          handleEditIconClick={handleEditIconClick}
          handleDeleteIconClick={handleDeleteIconClick}
          isBusinessAdminRole={isBusinessAdminRole}
        />
      </div>
    </Fragment>
  );
};

export default DeliveryZonesView;
