import {
  GET_USERS_SUMMARY,
  GET_ACTIVE_USERS,
  GET_NEW_USER_SIGN_UPS,
  GET_REFERRAL_STATS,
  GET_ACTIVE_USER_AGE,
  GET_CUMULATIVE_USERS,
  LOADING,
} from './types';

import api from '../../../api';
import moment from 'moment';
import {
  userSummaryData,
  activeUsersData,
  userSignUpsData,
  referralStatsData,
  userAgeData,
  cumulativeUsersData
} from '../../../../mocks/usersDashboard';

moment.locale('en');

export function getSummary(timePeriod) {
  return function(dispatch) {
    dispatch({type: LOADING});
    api
      .get(
        `/dashboard/users/summary?interval_type=${
          timePeriod.interval_type
        }&interval_value=${timePeriod.interval_value}`
      )
      .then(response => {
        dispatch({type: GET_USERS_SUMMARY, payload: response.data});
      })
      .catch(error => {
        // console.log('error occured', error);
      });
  };
}

export function getActiveUsers(timePeriod) {
  return function(dispatch) {
    api
      .get(
        `/dashboard/users/active?interval_type=${
          timePeriod.interval_type
        }&interval_value=${timePeriod.interval_value}`
      )
      .then(response => {
        dispatch({type: GET_ACTIVE_USERS, payload: response.data});
      })
      .catch(error => {
        // console.log('error occured', error);
      });
  };
}

export function getNewUsers(timePeriod) {
  return function(dispatch) {
    api
      .get(
        `/dashboard/users/signups?interval_type=${
          timePeriod.interval_type
        }&interval_value=${timePeriod.interval_value}`
      )
      .then(response => {
        dispatch({type: GET_NEW_USER_SIGN_UPS, payload: response.data});
      })
      .catch(error => {
        // console.log('error occured', error);
      });
  };
}

export function getReferralStats(timePeriod) {
  return function(dispatch) {
    api
      .get(
        `/dashboard/users/referrals?interval_type=${
          timePeriod.interval_type
        }&interval_value=${timePeriod.interval_value}`
      )
      .then(response => {
        dispatch({type: GET_REFERRAL_STATS, payload: response.data});
      })
      .catch(error => {
        // console.log('error occured', error);
      });
  };
}

export function getUserAge(timePeriod) {
  return function(dispatch) {
    api
      .get(
        `/dashboard/users/age?interval_type=${
          timePeriod.interval_type
        }&interval_value=${timePeriod.interval_value}`
      )
      .then(response => {
        dispatch({type: GET_ACTIVE_USER_AGE, payload: response.data});
      })
      .catch(error => {
        // console.log('error occured', error);
      });
  };
}

export function getCumulativeUsers() {
  return function(dispatch) {
    api
      .get(`/dashboard/users/cumulative?interval_type=day&interval_value=7`)
      .then(response => {
        dispatch({type: GET_CUMULATIVE_USERS, payload: response.data});
      })
      .catch(error => {
        // console.log('error occured', error);
      });
  };
}
