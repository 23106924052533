import React, { Fragment, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { ChevronLeftIcon } from 'merchant-portal-components';
import BreadCrumb from '../../../../components/BreadCrumb/BreadCrumb';
import * as actions from '../variantsActions';
import VariantDetails from './components/VariantDetails';
import ReactGA from 'react-ga';
const BackButton = styled.div`
  width: 176px;
  cursor: pointer;
  display: grid;
  grid-template-columns: 4px 300px;
  grid-gap: 10px;
  align-items: center;
  margin-top: 20px;

  &:hover {
    color: #3051d4;
  }
`;

const VariantDetailsContainer = ({
  changeComponentType,
  variantToEdit,
  editVariant,
  createVariant,
  variantIsSaving,
}) => {
  useEffect(() => {
    if (variantToEdit) {
      track.page('menu-management-variant-edit');
    } else {
      track.page('menu-management-variant-create');
    }
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname, undefined, 'Variant Detail');
  }, []);
  return (
    <Fragment>
      <BackButton
        onClick={() => !variantIsSaving && changeComponentType('list')}
      >
        <ChevronLeftIcon />
        <p>Back To Variant Manager</p>
      </BackButton>
      <BreadCrumb
        style={{ margin: ' 0px' }}
        pageName={variantToEdit ? 'Edit Variant' : 'Create Variant'}
      />

      <VariantDetails
        variantToEdit={variantToEdit}
        editVariant={editVariant}
        createVariant={createVariant}
        variantIsSaving={variantIsSaving}
      />
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  variantIsSaving: state.variantsReducer.variantIsSaving,
});
export default connect(mapStateToProps, actions)(VariantDetailsContainer);
