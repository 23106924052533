export const selectAllProducts = (state) => state.productsReducer.allProducts;

export const selectRequestingGetProducts = (state) =>
  state.productsReducer.requestingGetProducts;

export const selectRequestingToggleProductIds = (state) =>
  state.productsReducer.requestingToggleProductIds;

export const selectProductTags = (state) =>
  state.tagsReducer.allTags.filter((tag) => tag.type === 'product');

export const selectFormattedVariants = (state) =>
  state.variantsReducer.allVariants.map((variant) => ({
    ...variant,
    name: variant.name
      .toLowerCase()
      .split(' ')
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' '),
    label: (variant.label || '')
      .toLowerCase()
      .split(' ')
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' '),
  }));

export const selectProductTogglesIsDisabled = (state) => {
  const {
    productId,
    categoriesInitialValues,
    productVariantsInitialValues,
    productInformation,
  } = state.productsReducer;

  return (
    !productId ||
    (categoriesInitialValues.length && !categoriesInitialValues[0].id) ||
    !productVariantsInitialValues.length ||
    (!productInformation.is_pos_mapped &&
      productInformation.is_pos_mapping_required)
  );
};

export const selectProductUpcs = (allProducts, productId) => {
  const foundProduct = allProducts?.find(
    (product) => product.product_id === productId
  );

  if (foundProduct) {
    return foundProduct.upc || [];
  } else {
    return [];
  }
};
