import React, { Component } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { connect } from 'react-redux';
import * as actions from './appFeedActions';
import { DashedRow, Icon } from './components/AppFeedElements';
import SortableCard from './SortableCard';
import { EditIcon } from '../../components/Icon';

const SortableItem = SortableElement((props) => {
  return (
    <div>
      <SortableCard {...props} />
    </div>
  );
});

const Cards = SortableContainer((props) => {
  return (
    <ul style={{ listStyle: 'none', padding: 0 }}>
      {props.fields.map((card, index) => {
        const { id } = props.fields.get(index);
        const disabled = props.editingCard;
        // props.fields.length == 1 ||
        // props.editingCard ||
        // props.showCreateCardForm;
        return (
          <SortableItem
            key={`card-${id}-${index}--${card}`}
            card={card}
            index={index}
            disabled={disabled}
            {...props}
          />
        );
      })}
    </ul>
  );
});

class CardsList extends Component {
  onSortEnd = ({ oldIndex, newIndex }) => {
    const { fields, updateFeedRow, formValues } = this.props;

    this.props.changed(true);
    fields.move(oldIndex, newIndex);
    // if (oldIndex !== newIndex) {
    //   fields.move(oldIndex, newIndex);
    //   updateFeedRow({
    //     ...formValues,
    //     items: this.props.fields.getAll()
    //   });
  };

  renderOrHideEditCardIcon(card) {
    const { editingCard } = this.props;
    if (!editingCard) {
      return <EditIcon onClick={() => this.openEditCardForm(card)} />;
    }
  }

  initiateCreateCard() {
    const { openCreateCardForm, fields } = this.props;

    openCreateCardForm();
    fields.push({
      id: Math.random().toString(10),
      is_visible: true,

      card_action: '',
    });
  }

  render() {
    const { formValues } = this.props;
    const feedRowStyleType = formValues.feed_style_type;
    const cardOrItem = feedRowStyleType === 'list_card' ? 'Item' : 'Card';
    return (
      <ul style={{ listStyle: 'none', padding: 0 }}>
        <Cards
          {...this.props}
          lockAxis='y'
          onSortEnd={this.onSortEnd}
          formValues={formValues}
        />
        {formValues.items.length === 0 && (
          <li>
            <DashedRow
              onClick={() => this.initiateCreateCard()}
              style={{ marginBottom: '10px' }}
            >
              + Add {cardOrItem}
            </DashedRow>
          </li>
        )}
        {/* {!this.props.showCreateCardForm && (
          <li>
            <DashedRow
              onClick={() => this.initiateCreateCard()}
              style={{ marginBottom: "10px" }}
            >
              + Add {cardOrItem}
            </DashedRow>
          </li>
        )} */}
      </ul>
    );
  }
}

function mapStateToProps(state) {
  return {
    formValues: state.form.feedRowForm.values,
    showCreateCardForm: state.appFeed.showCreateCardForm,
    feedRowFormIsVisible: state.appFeed.feedRowFormIsVisible,
    editingCard: state.appFeed.editingCard,
  };
}

export default CardsList = connect(mapStateToProps, actions)(CardsList);
