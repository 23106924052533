import React, { useState, useEffect, useCallback } from "react";
import { lastDayOfWeek } from "date-fns";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import moment from "moment";
import {
  Wrapper,
  Title,
  HoursTableWrraper,
  HoursTable,
  HoursTableBody,
  HoursTableRow,
  HoursTableHeader,
  HoursTableColumn,
  EditButton,
} from "../RegularHours/styles/regularHoursListStyles";
const getDayOfWeek = (id) => {
  let day;
  switch (id) {
    case 1:
      day = "Mon";
      break;
    case 2:
      day = "Tue";
      break;
    case 3:
      day = "Wed";
      break;
    case 4:
      day = "Thu";
      break;
    case 5:
      day = "Fri";
      break;
    case 6:
      day = "Sat";
      break;
    case 7:
      day = "Sun";
      break;
  }
  return day;
};
const SpecialHoursSubList = ({ title, hoursTable, handleOpenModal }) => {
  const [isSplited, setIsSplited] = useState(false);
  const [editEnabledList, setEditEnabledList] = useState([]);
  useEffect(() => {
    let isSplited = false;
    if (hoursTable?.length) {
      hoursTable.forEach((item) => {
        if (
          item.status !== "closed" &&
          (item.status.open2 || item.status.close2)
        ) {
          isSplited = true;
        }
      });
    }
    setIsSplited(isSplited);
    let newEditEnableList = [];
    hoursTable.forEach((item) => {
      if (title.toLowerCase() === "location") {
        newEditEnableList.push(true);
      } else {
        if (item.isStoreClosed) {
          newEditEnableList.push(false);
        } else {
          newEditEnableList.push(true);
        }
      }
    });
    setEditEnabledList(newEditEnableList);
  }, [hoursTable]);
  return (
    <Wrapper>
      <Title>{title} Hours</Title>
      <HoursTableWrraper>
        <HoursTable>
          <HoursTableRow isHeader={true}>
            <HoursTableHeader width="60px">Day</HoursTableHeader>
            <HoursTableHeader width="110px" align="center">
              Open Time
            </HoursTableHeader>
            <HoursTableHeader width="110px" align="center">
              Close Time
            </HoursTableHeader>
            <HoursTableHeader width="110px" align="center">
              {isSplited ? "Open Time" : ""}
            </HoursTableHeader>
            <HoursTableHeader width="110px" align="center">
              {isSplited ? "Close Time" : ""}
            </HoursTableHeader>
            <HoursTableHeader width="70px" align="center">
              Next Day
            </HoursTableHeader>
            <HoursTableHeader width="50px" align="center">
              Edit
            </HoursTableHeader>
          </HoursTableRow>
          {hoursTable?.map((item, index) =>
            item.status === "closed" ? (
              <HoursTableRow key={index + 1}>
                <HoursTableColumn>{getDayOfWeek(item.id)}</HoursTableColumn>
                <HoursTableColumn width="110px" align="center" isClosed={true}>
                  Closed
                </HoursTableColumn>
                <HoursTableColumn
                  width="110px"
                  align="center"
                ></HoursTableColumn>
                <HoursTableColumn
                  width="110px"
                  align="center"
                ></HoursTableColumn>
                <HoursTableColumn
                  width="110px"
                  align="center"
                ></HoursTableColumn>
                <HoursTableColumn
                  width="70px"
                  align="center"
                ></HoursTableColumn>
                <HoursTableColumn align="center">
                  {editEnabledList[index] && (
                    <EditButton
                      className="fas fa-pencil"
                      onClick={() =>
                        handleOpenModal(item.id, title.toLowerCase())
                      }
                    />
                  )}
                </HoursTableColumn>
              </HoursTableRow>
            ) : (
              <HoursTableRow>
                <HoursTableColumn width="60px">
                  {getDayOfWeek(item.id)}
                </HoursTableColumn>

                <HoursTableColumn width="110px" align="center">
                  {item.status.open1
                    ? moment(item.status.open1, "HH:mm:ss").format("HH:mm")
                    : ""}
                </HoursTableColumn>
                <HoursTableColumn width="110px" align="center">
                  {item.status.close1
                    ? moment(item.status.close1, "HH:mm:ss").format("HH:mm")
                    : ""}
                </HoursTableColumn>
                <HoursTableColumn width="110px" align="center">
                  {item.status.open2
                    ? moment(item.status.open2, "HH:mm:ss").format("HH:mm")
                    : ""}
                </HoursTableColumn>
                <HoursTableColumn width="110px" align="center">
                  {item.status.close2
                    ? moment(item.status.close2, "HH:mm:ss").format("HH:mm")
                    : ""}
                </HoursTableColumn>
                <HoursTableColumn width="70px" align="center">
                  <Checkbox
                    checked={item.status.hasNextDay}
                    name="checkedB"
                    color="primary"
                    disabled
                  />
                </HoursTableColumn>
                <HoursTableColumn width="50px" align="center">
                  {editEnabledList[index] && (
                    <EditButton
                      className="fas fa-pencil"
                      onClick={() =>
                        handleOpenModal(item.id, title.toLowerCase())
                      }
                    />
                  )}
                </HoursTableColumn>
              </HoursTableRow>
            )
          )}
        </HoursTable>
      </HoursTableWrraper>
    </Wrapper>
  );
};

export default SpecialHoursSubList;
