export const GET_MOBILE_MESSAGES_REQUEST = "GET_MOBILE_MESSAGES_REQUEST";
export const GET_MOBILE_MESSAGES_SUCCESS = "GET_MOBILE_MESSAGES_SUCCESS";
export const GET_MOBILE_MESSAGES_FAILURE = "GET_MOBILE_MESSAGES_FAILURE";
export const INITIATE_CREATE_MOBILE_MESSAGE = "INITIATE_CREATE_MOBILE_MESSAGE";
export const CREATE_MOBILE_MESSAGE_REQUEST = "CREATE_MOBILE_MESSAGE_REQUEST";
export const CREATE_MOBILE_MESSAGE_SUCCESS = "CREATE_MOBILE_MESSAGE_SUCCESS";
export const CREATE_MOBILE_MESSAGE_FAILURE = "CREATE_MOBILE_MESSAGE_FAILURE";
export const INITIATE_EDIT_MOBILE_MESSAGE = "INITIATE_EDIT_MOBILE_MESSAGE";
export const READ_ONLY_MOBILE_MESSAGE = "READ_ONLY_MOBILE_MESSAGE";
export const EDIT_MOBILE_MESSAGE_REQUEST = "EDIT_MOBILE_MESSAGE_REQUEST";
export const EDIT_MOBILE_MESSAGE_SUCCESS = "EDIT_MOBILE_MESSAGE_SUCCESS";
export const EDIT_MOBILE_MESSAGE_FAILURE = "EDIT_MOBILE_MESSAGE_FAILURE";
export const CANCEL_MOBILE_MESSAGE = "CANCEL_MOBILE_MESSAGE";
export const CREATE_TEST_MESSAGE_REQUEST = "CREATE_TEST_MESSAGE_REQUEST";
export const RESET_TEST_MESSAGES = "RESET_TEST_MESSAGES";
export const CREATE_TEST_MESSAGE_FAILURE = "CREATE_TEST_MESSAGE_FAILURE";
export const CREATE_TEST_MESSAGE_SUCCESS = "CREATE_TEST_MESSAGE_SUCCESS";
export const UPDATE_MOBILE_MESSAGES_REQUEST_PARAMS = 'UPDATE_MOBILE_MESSAGES_REQUEST_PARAMS';
export const UPDATE_SORTED_MESSAGES_LIST = 'UPDATE_SORTED_MESSAGES_LIST';
