import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import { Formik, Field, FieldArray } from 'formik';
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from 'react-sortable-hoc';
import { Tooltip } from '@material-ui/core';
import { SortableCardRow } from '../../../components/MenuManagementElements';
import {
  RenderTextField,
  RenderToggleSwitch,
} from '../../../../../components/FormElements/FormikElements';

import ItemForm from './ItemForm';
import ItemsSort from './ItemsSort';
import { TooltipIcon } from '../../../../../components/Icon';

const Card = styled.div`
  background-color: white;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
  //   padding: 48px;
  border-radius: 6px;
  display: grid;
  grid-gap: 32px;
`;

const NonTableElements = styled.div`
  display: grid;
  grid-gap: 24px;
  padding: 48px;
`;

const Title = styled.div`
  font-size: 21px;
  font-weight: 600;
`;

const OptionNameWrapper = styled.div`
  width: 200px;
`;

const Subtitle = styled.div`
  font-weight: 600;
  cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  color: ${(props) => (props.disabled ? props.theme.blueGray : 'initial')};
  text-align: ${(props) => props.textAlign || 'left'};
`;

const OptionDetailsWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 48px;
  align-items: center;
  width: 822px;

  @media (max-width: 1313px) {
    grid-template-columns: 1fr;
    grid-auto-flow: row;
    width: 100%;
  }
`;

const ButtonWrapper = styled.div`
  display: grid;
  grid-gap: 12px;
  width: 170px;
`;

// Hided on column for release 2.8.0
const ItemsHeaderWrapper = styled.div`
  display: grid;
  grid-gap: 25px;
  padding: 20px;
  grid-template-columns: 50px 3fr 2fr 2fr 1fr 2fr 1fr;
  align-items: center;
`;

const SortableCardRowWrapper = styled.div`
  display: grid;
  grid-template-columns: 16px 500px;
  grid-gap: 24px;
  align-items: center;

  .react-select__control {
    background-color: white;
    border-radius: 6px;
  }

  .react-select__single-value {
    font-weight: 600;
    font-size: 14px;
  }

  .react-select__multi-value {
    background-color: #e6e6e6;
    border-radius: 15px;
    padding: 1px 3px;
    font-weight: 400;
    font-size: 14px;
  }
`;

const TooltipRow = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 5px;
  align-items: center;
`;

const SortableItem = SortableElement(
  ({ option, optionIndex, options, arrayHelpers }) => {
    return (
      <SortableCardRowWrapper>
        <SortableCardRow>{option.name}</SortableCardRow>
      </SortableCardRowWrapper>
    );
  }
);

const SortableList = SortableContainer(({ options, arrayHelpers }) => {
  return (
    <div style={{ display: 'grid', gridGap: '24px' }}>
      {options.map((option, index) => (
        <SortableItem
          key={`option-${index}`}
          index={index}
          option={option}
          optionIndex={index}
          options={options}
          arrayHelpers={arrayHelpers}
        />
      ))}
    </div>
  );
});

const OptionForm = ({
  option,
  optionIndex,
  setFieldValue,
  arrayHelpers,
  getNextOptionId,
}) => {
  const [subOptionsAreSortable, setSubOptionsAreSortable] = useState(false);
  const [itemsAreSortable, setItemsAreSortable] = useState(false);
  const [subItemsToSort, setSubitemsToSort] = useState([]);
  return (
    <Card>
      <NonTableElements>
        {option.parent_id && <Title>Sub-Option</Title>}
        <OptionNameWrapper>
          <Field
            name={`options[${optionIndex}].name`}
            component={RenderTextField}
            label="Option Name*"
            error={
              arrayHelpers.form.touched.options &&
              arrayHelpers.form.touched.options[optionIndex] &&
              arrayHelpers.form.touched.options[optionIndex].name &&
              arrayHelpers.form.errors.options &&
              arrayHelpers.form.errors.options[optionIndex] &&
              arrayHelpers.form.errors.options[optionIndex].name
            }
          />
        </OptionNameWrapper>

        <OptionDetailsWrapper>
          <Fragment>
            <div>
              <TooltipRow>
                <Subtitle>Min*</Subtitle>
                <Tooltip
                  // style={{ marginRight: 'auto' }}
                  title="Minimum amount of item quantity that's required"
                >
                  <span>
                    <TooltipIcon />
                  </span>
                </Tooltip>
              </TooltipRow>
              <Field
                name={`options[${optionIndex}].minimum_pick`}
                component={RenderTextField}
                error={
                  arrayHelpers.form.touched.options &&
                  arrayHelpers.form.touched.options[optionIndex] &&
                  arrayHelpers.form.touched.options[optionIndex].minimum_pick &&
                  arrayHelpers.form.errors.options &&
                  arrayHelpers.form.errors.options[optionIndex] &&
                  arrayHelpers.form.errors.options[optionIndex].minimum_pick
                }
              />
            </div>

            <div>
              <TooltipRow>
                <Subtitle>Max*</Subtitle>
                <Tooltip
                  style={{ marginRight: 'auto' }}
                  title="Maximum amount of item quantity. Cannot go over this amount"
                >
                  <span>
                    <TooltipIcon />
                  </span>
                </Tooltip>
              </TooltipRow>
              <Field
                name={`options[${optionIndex}].maximum_pick`}
                component={RenderTextField}
                error={
                  arrayHelpers.form.touched.options &&
                  arrayHelpers.form.touched.options[optionIndex] &&
                  arrayHelpers.form.touched.options[optionIndex] &&
                  arrayHelpers.form.touched.options[optionIndex].maximum_pick &&
                  arrayHelpers.form.errors.options &&
                  arrayHelpers.form.errors.options[optionIndex] &&
                  arrayHelpers.form.errors.options[optionIndex].maximum_pick
                }
              />
            </div>

            <div>
              <TooltipRow>
                <Subtitle>Included*</Subtitle>
                <Tooltip
                  style={{ marginRight: 'auto' }}
                  title="Amount of items that are free"
                >
                  <span>
                    <TooltipIcon />
                  </span>
                </Tooltip>
              </TooltipRow>
              <Field
                name={`options[${optionIndex}].included_pick`}
                component={RenderTextField}
                error={
                  arrayHelpers.form.touched.options &&
                  arrayHelpers.form.touched.options[optionIndex] &&
                  arrayHelpers.form.touched.options[optionIndex]
                    .included_pick &&
                  arrayHelpers.form.errors.options &&
                  arrayHelpers.form.errors.options[optionIndex] &&
                  arrayHelpers.form.errors.options[optionIndex].included_pick
                }
              />
            </div>
          </Fragment>

          <div style={{ alignSelf: 'flex-start' }}>
            <Subtitle>Active</Subtitle>
            <Field
              name={`options[${optionIndex}].is_active`}
              component={RenderToggleSwitch}
              onChange={(e) =>
                setFieldValue(
                  `options[${optionIndex}].is_active`,
                  e.target.checked
                )
              }
              style={{ marginTop: '12px' }}
            />
          </div>
        </OptionDetailsWrapper>

        <ButtonWrapper>
          <Subtitle
            disabled={
              arrayHelpers.form.values.options.length > 1 && optionIndex === 0
            }
            onClick={() =>
              option.items
                ? setFieldValue(`options[${optionIndex}].items`, [
                    ...option.items,
                    {
                      parent_id: null,
                      name: '',
                      user_description: null,
                      maximum_pick: null,
                      image_url: null,
                      count_as: 1,
                      position: null,
                      is_active: false,
                      is_default: false,
                      is_available: true,
                    },
                  ])
                : setFieldValue(`options[${optionIndex}].items`, [
                    {
                      parent_id: null,
                      name: '',
                      user_description: null,
                      maximum_pick: null,
                      image_url: null,
                      count_as: 1,
                      position: null,
                      is_active: false,
                      is_default: false,
                      is_available: true,
                    },
                  ])
            }
          >
            + Add an Item
          </Subtitle>

          <Subtitle
            disabled={
              arrayHelpers.form.values.options.length > 1 || option.items
            }
            style={{ textAlign: 'center' }}
          >
            or
          </Subtitle>

          <Subtitle
            disabled={option.parent_id || option.items}
            onClick={() => {
              const newOption = {
                name: '',
                parent_id: option.id,

                minimum_pick: null,
                maximum_pick: null,
                included: null,
                is_active: false,
              };
              arrayHelpers.push(newOption);
            }}
          >
            + Add a Sub-Option
          </Subtitle>
          {arrayHelpers.form.values.options.length > 1 && optionIndex === 0 && (
            <Subtitle
              style={{ marginTop: '20px' }}
              onClick={() => setSubOptionsAreSortable(!subOptionsAreSortable)}
            >
              <i className="far fa-sort-amount-down" /> Sort Sub-Options
            </Subtitle>
          )}
        </ButtonWrapper>

        {subOptionsAreSortable && (
          <SortableList
            options={arrayHelpers.form.values.options.filter(
              (option) => option.parent_id
            )}
            arrayHelpers={arrayHelpers}
            onSortEnd={({ oldIndex, newIndex }) => {
              // +1 because we don't want to sort parent option
              arrayHelpers.form.setValues({
                options: arrayMove(
                  arrayHelpers.form.values.options,
                  oldIndex + 1,
                  newIndex + 1
                ),
              });
            }}
          />
        )}
      </NonTableElements>
      {option.items && (
        <FieldArray
          name={`option[${optionIndex}].items`}
          render={(itemsArrayHelpers) => (
            <div>
              <div
                style={{
                  margin: '0 0 0 48px',
                  width: '100px',
                  cursor: 'pointer',
                }}
                onClick={() => setItemsAreSortable(!itemsAreSortable)}
              >
                <Tooltip title="Click to order the items">
                  <Title>
                    Items <i className="far fa-sort-amount-down" />
                  </Title>
                </Tooltip>
              </div>
              <ItemsHeaderWrapper>
                <Subtitle />
                <Subtitle>Name*</Subtitle>
                <Subtitle>Quantity Max*</Subtitle>
                <Subtitle>Count As*</Subtitle>
                {/* <Subtitle textAlign="center">Default</Subtitle> // hided for release 2.8.0  */}
                <Subtitle textAlign="center">Active</Subtitle>
                <Subtitle textAlign="center">Add Sub-Item</Subtitle>
                <Subtitle textAlign="center">Other</Subtitle>
              </ItemsHeaderWrapper>
              {!itemsAreSortable ? (
                option.items.map((item, itemIndex) => (
                  <Fragment key={itemIndex}>
                    <ItemForm
                      option={option}
                      item={item}
                      optionIndex={optionIndex}
                      itemIndex={itemIndex}
                      subItemIsSortable={() =>
                        subItemsToSort.includes(itemIndex)
                          ? setSubitemsToSort(
                              subItemsToSort.filter(
                                (index) => index !== itemIndex
                              )
                            )
                          : setSubitemsToSort([...subItemsToSort, itemIndex])
                      }
                      arrayHelpers={itemsArrayHelpers}
                      disableChildItems={() =>
                        item.sub_items.forEach((sub_item, subItemIndex) => {
                          setFieldValue(
                            `options[${optionIndex}].items[${itemIndex}].sub_items[${subItemIndex}].is_active`,
                            false
                          );
                        })
                      }
                    />
                    {item.sub_items && (
                      <Fragment>
                        {!subItemsToSort.includes(itemIndex) ? (
                          item.sub_items.map((sub_item, subItemIndex) => (
                            <ItemForm
                              key={subItemIndex}
                              isSubItem={true}
                              item={item}
                              optionIndex={optionIndex}
                              itemIndex={itemIndex}
                              subItemIndex={subItemIndex}
                              arrayHelpers={itemsArrayHelpers}
                              option={option}
                            />
                          ))
                        ) : (
                          <ItemsSort
                            isSubItem={true}
                            items={option.items[itemIndex].sub_items}
                            optionIndex={optionIndex}
                            itemIndex={itemIndex}
                            arrayHelpers={itemsArrayHelpers}
                          />
                        )}
                      </Fragment>
                    )}
                  </Fragment>
                ))
              ) : (
                <ItemsSort
                  items={option.items}
                  optionIndex={optionIndex}
                  arrayHelpers={itemsArrayHelpers}
                />
              )}
            </div>
          )}
        />
      )}
    </Card>
  );
};

export default OptionForm;
