import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Modal } from 'merchant-portal-components';
import { Link } from 'react-router-dom';
// import { useHistory } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

const ModalTitle = styled.div`
  display: grid;
  grid-template-columns: 10fr 50px;
  padding: 24px 24px 15px 15px;
`;

const LineDivider = styled.hr`
  border: solid 1px #e1e1e1;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: 0.03px;
  color: #191818;
`;

const ModalBody = styled.div`
  padding: 48px 30px;
  overflow-y: auto;
  height: 60vh;
  width: 100%;
  margin: 0;
`;
const CustomLink = styled.span`
  display: block;
  cursor: pointer;
  margin-bottom: 12px;
`;
const InfoMessageContainer = styled.div`
  min-width: 100%;
  display: flex;
  align-items: center;
  min-height: 100%;
  flex-wrap: wrap;
  margin: 0;
`;
const InfoMessageWrapper = styled.div`
  min-width: 100%;
  text-align: center;
  margin: 0;
`;
const InfoMessage = styled.p`
  margin-bottom: 12px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.01px;
  color: #191818;
`;
const InfoMessageLink = styled(Link)`
  cursor: pointer;
  margin-bottom: 12px;
  margin-left: 3px;
  margin-right: 3px;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.01px;
  color: #2440ae;
`;
const RouterCustomLink = withRouter(
  ({
    history,
    children,
    selectedProduct,
    onClose,
    initiateEditProductWizard,
  }) => (
    <CustomLink
      onClick={() => {
        initiateEditProductWizard(selectedProduct);
        onClose();
        history.push('/products');
      }}
    >
      {children}
    </CustomLink>
  )
);
const AssociatedProductsModal = ({
  onClose,
  optionTitle,
  accociatedProductList,
  initiateEditProductWizard,
}) => {
  return (
    <Modal>
      <div
        style={{
          width: '550px',
        }}
      >
        <ModalTitle>
          <Title>{`${optionTitle} - Associated Products`}</Title>
          <i
            className="fal fa-times fa-lg"
            style={{ cursor: 'pointer', textAlign: 'center' }}
            onClick={onClose}
          ></i>
        </ModalTitle>
        <LineDivider />
        <ModalBody>
          {accociatedProductList?.length > 0 ? (
            accociatedProductList.map((product) => (
              <RouterCustomLink
                key={product.id}
                selectedProduct={product}
                onClose={onClose}
                initiateEditProductWizard={initiateEditProductWizard}
              >
                {product.name}
              </RouterCustomLink>
            ))
          ) : (
            <InfoMessageContainer>
              <InfoMessageWrapper>
                <InfoMessage>
                  {'This Option is not associated with any Products.'}
                </InfoMessage>
                <InfoMessage>
                  To associate with a Product, go to the
                  <InfoMessageLink to="/products">Products</InfoMessageLink>
                  section.
                </InfoMessage>
              </InfoMessageWrapper>
            </InfoMessageContainer>
          )}
        </ModalBody>
      </div>
    </Modal>
  );
};

export default AssociatedProductsModal;
