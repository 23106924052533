import api from '../../api';
import {
  GET_CUSTOMERS_BY_QUERY_REQUEST,
  GET_CUSTOMERS_BY_QUERY_SUCCESS,
  GET_CUSTOMERS_BY_QUERY_FAILURE,
  SELECT_CUSTOMER,
  SELECT_CUSTOMER_BY_DEFAULT_VALUE,
  RESET_STATE,
} from './types';
import { getSearchUrl } from '../../utils/purePayload';

export function getCustomersByQuery(queryString) {
  if (queryString?.length > 2) {
    return function (dispatch) {
      dispatch({ type: GET_CUSTOMERS_BY_QUERY_REQUEST });
      const urlwithOutSearchString=`/customers?page_number=1&page_size=1000`
      const url = getSearchUrl(urlwithOutSearchString, encodeURIComponent(queryString), 3);
      api
        .get(
          url
        )
        .then(
          (response) => {
            dispatch({
              type: GET_CUSTOMERS_BY_QUERY_SUCCESS,
              response: response.data,
            });
          },
          (error) => {
            dispatch({
              type: GET_CUSTOMERS_BY_QUERY_FAILURE,
              error,
            });
          }
        );
    };
  } else {
    return function (dispatch) {
      dispatch({
        type: GET_CUSTOMERS_BY_QUERY_SUCCESS,
        response: { data: [] },
      });
    };
  }
}

export function selectCustomerByDefaultValue(customerId) {
  return function (dispatch, getState) {
    const { selectedCustomer } = getState().customerSearchManager;
    if (selectedCustomer) {
      dispatch({
        type: SELECT_CUSTOMER_BY_DEFAULT_VALUE,
        customer: selectedCustomer,
      });
    } else {
      return api.get(`/customers/${customerId}`).then((response) => {
        dispatch({
          type: SELECT_CUSTOMER_BY_DEFAULT_VALUE,
          customer: response.data.data,
        });
      });
    }
  };
}

export function resetState() {
  return {
    type: RESET_STATE,
  };
}

export function selectCustomer(customer) {
  return {
    type: SELECT_CUSTOMER,
    customerId: customer.customer_id,
  };
}
