import React, { Component } from "react";
import styled from "styled-components";
import { PhonePreviewWrapper, AppFeedPreview } from "./FeedPreviewElements";
import HorizontalList from "./HorizontalList";
import ListCard from "./ListCard";
import SingleItemCard from "./SingleItemCard";
import iphoneImg from "./iphone@2x.png";
import qrPreview from "./qr_preview.png";

const PreviewWrapper = styled.div`
  height: 100%;
  padding-left: 70px;
`;

class FeedPreview extends Component {
  state = {
    id: "",
    newRows: [],
    modifiedFeedRows: []
  };

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.feedRowFormIsVisible && this.props.feedRowFormIsVisible) {
      this.scrollToBottom();
    }
    if (prevProps.feedRows !== this.props.feedRows) {
      this.setRowHeightToState(this.props.feedRows);
      this.setState({
        modifiedFeedRows: this.props.feedRows.map(feedRow => ({
          ...feedRow,
          segments: feedRow.segments.map(segmentId =>
            typeof segmentId === "string"
              ? parseInt(segmentId)
              : segmentId.segment_id
          )
        }))
      });
    }
    if (prevProps.cardById !== this.props.cardById) {
      this.scrollToId(this.props.cardById);
    }
    if (
      prevProps.previewSegments !== this.props.previewSegments ||
      prevState.modifiedFeedRows !== this.state.modifiedFeedRows
    ) {
      const segment_ids = this.props.previewSegments.map(
        item => item.segment_id
      );

      this.setState({
        newRows: this.state.modifiedFeedRows.filter(row =>
          row.segments.some(segment_id => segment_ids.includes(segment_id))
        )
      });
    }
  }

  scrollToBottom = () => {
    //list.scrollHeight is the total height, which would bring it to the bottom
    //small timeout needed in order to wait for it to be created, then measured, before scrolling
    setTimeout(
      () =>
        list.scrollTo({
          top: list.scrollHeight,
          behavior: "smooth"
        }),
      5
    );
  };

  scrollToId = id => {
    let stateCopy = { ...this.state };
    let selectedRow;
    let selectedRow2;

    Object.entries(stateCopy).forEach(([key, value]) => {
      if (key == id) {
        selectedRow = value;
      }
    });

    Object.entries(stateCopy).forEach(([key, value]) => {
      if (Object.keys(value) == id) {
        selectedRow2 = key;
      }
    });

    let heightTotal = 0;
    let heightTotal2 = 0;

    if (selectedRow) {
      Object.entries(stateCopy).forEach(([key, value]) => {
        if (key + 1 <= parseInt(Object.keys(selectedRow))) {
          heightTotal = heightTotal + parseInt(Object.values(value));
        }
      });
    }

    if (selectedRow2) {
      Object.entries(stateCopy).forEach(([key, value]) => {
        if (parseInt(key) + 1 <= parseInt(Object.keys(selectedRow))) {
          heightTotal2 = heightTotal2 + parseInt(Object.values(value));
        }
      });
    }
    list.scrollTo({
      top: heightTotal2,
      behavior: "smooth" // Optional, adds animation
    });
  };

  setRowHeightToState = feedRows => {
    let i;
    let prodIdArray = [];
    for (i = 0; i < feedRows.length; i++) {
      if (document.getElementById(feedRows[i].id)) {
        prodIdArray.push(feedRows[i].id);
      }
    }

    this.measureHeight(prodIdArray, feedRows);
  };

  measureHeight = (prodIdArray, feedRows) => {
    let fullProdArray = [];
    let j;

    if (!this.state[prodIdArray[0]]) {
      for (j = 0; j < prodIdArray.length; j++) {
        let arry = feedRows.filter(row => row.id == prodIdArray[j]);
        if (arry) {
          fullProdArray.push(arry[0]);
        }
      }
    }
    this.assignHeights(fullProdArray);
    //as the component is rerendered on mount, it loses its items
  };

  assignHeights = array => {
    let k;
    for (k = 0; k < array.length; k++) {
      let size = document.getElementById(array[k].id);
      let cardHeight = size.scrollHeight;
      this.setState({ [array[k].id]: { [array[k].position]: cardHeight } });
      // It would be better if the array was set to position instead of ID,
      // Without a timeout, the position is not fully set, and reseults in an
      // infinite re-render. Once changed, the scrollTo methods will need to take
      // new structure into account
      setTimeout(
        this.setState({ [array[k].position]: { [array[k].id]: cardHeight } }),
        1000
      );
    }
  };

  renderRows = () => {
    const feedRows =
      this.props.previewSegments.length > 0
        ? this.state.newRows
        : this.props.feedRows;
    this.setRowHeightToState(feedRows);
    // const showRowBasedOnSegments = row => {
    //   row.segments.some(segmentId => {
    //     previewSegments.some(
    //       segmentObject => segmentObject.segment_id == segmentId
    //     );
    //   }) ||
    //     row.segments.some(segment => {
    //       previewSegments.some(
    //         segmentObject => segmentObject.segment_id == segment.segment_id
    //       );
    //     }) ||
    //     !row.segments.length;
    // };

    return feedRows.map(row => {
      if (row.is_visible) {
        if (row.feed_type === "custom") {
          //showRowBasedOnSegments(row)
          switch (row.feed_style_type) {
            case "horizontal_cards":
              return (
                <div style={{ height: "46%" }} id={row.id} key={row.id}>
                  <HorizontalList row={row} />
                </div>
              );
            case "list_card":
              return <ListCard key={row.id} row={row} />;
            case "single_card":
            case "single_compact_card":
              return (
                <div
                  style={
                    row.feed_style_type === "single_card"
                      ? { height: "62%" }
                      : { height: "unset" }
                  }
                  id={row.id}
                  key={row.id}
                >
                  <SingleItemCard
                    row={row}
                    compact={row.feed_style_type === "single_compact_card"}
                  />
                </div>
              );
          }
        } else if (row.feed_type === "pay_in_store") {
          const item = {
            is_visible: true,
            title: "PAY NOW",
            subtitle: "Quick Access",
            background_image_url: qrPreview
          };
          return (
            <div style={{ height: "66%" }} id={row.id} key={row.id}>
              <SingleItemCard
                style={{ height: "22%" }}
                row={{ ...row, items: [item] }}
                compact
              />
            </div>
          );
        }
      }
    });
  };

  renderOfferRows = () => {
    const { previewSegments } = this.props;
    const { allOffers } = this.props;

    const showRowBasedOnSegments = row =>
      row.segments.some(segmentId =>
        previewSegments.some(
          segmentObject => segmentObject.segment_id == segmentId
        )
      ) ||
      row.segments.some(segment =>
        previewSegments.some(
          segmentObject => segmentObject.segment_id == segment.segment_id
        )
      ) ||
      !row.segments.length;
    // not mapping here, as mapping would return several rows of single
    //line horizontal cards, map inside component for single line of horz cards
    return (
      <div style={{ height: "46%" }}>
        <HorizontalList
          rowTitle="Your Rewards"
          actionLabel="See All"
          allOffers={allOffers}
        />
      </div>
    );
  };

  render() {
    return (
      <PreviewWrapper>
        <PhonePreviewWrapper>
          <img src={iphoneImg} style={{ width: "340px" }} />
          <AppFeedPreview id="list" ref={ref => (this.myRef = ref)}>
            {this.props.allOffers && this.renderOfferRows()}
            {this.props.feedRows && this.renderRows()}
          </AppFeedPreview>
        </PhonePreviewWrapper>
      </PreviewWrapper>
    );
  }
}

export default FeedPreview;
