import React, { Fragment } from 'react';
import {
  RenderTextField,
  required,
  RenderSelect,
  requiredSelect,
} from '../../../../../../components/FormElements/FormikElements';
import { Field } from 'formik';
import { FieldWrapper, Image } from './styles';
import timezones from '../../../../../../utils/constants/timezones';
import ChooseImage from '../../../../../MediaModal/components/ChooseImage';

const DetailsForm = ({
  selectedDetails,
  setIsEditing,
  values,
  setDetails,
  setMediaSelectionModalIsVisible,
  errors,
  isEditing,
  languagesConfig,
  touched,
}) => {
  return (
    <Fragment>
      <FieldWrapper grid='2fr 1fr'>
        <Field
          required
          name='details.timezone'
          attachedLabel='Select Timezone *'
          component={RenderSelect}
          options={timezones.map((timezone) => ({
            label: timezone,
            value: timezone,
          }))}
          getOptionValue={(timezone) => timezone.value}
          getOptionLabel={(timezone) => timezone.label}
          valueKey='label'
          // placeholder='Select Timezone'
          // validate={requiredSelect}
          // onChange={() => {
          //   setIsEditing(true);
          // }}
        />
      </FieldWrapper>
      <FieldWrapper grid='2fr 1fr'>
        <Field
          name='details.language_code'
          attachedLabel='Select Language *'
          component={RenderSelect}
          options={languagesConfig.map((language) => ({
            label: language.display_name,
            value: language.language_code,
          }))}
          getOptionLabel={(language) => language.label}
          getOptionValue={(language) => language.value}
          valueKey='value'
          // validate={requiredSelect}
          // placeholder='Select Language *'
          // onChange={() => {
          //   setIsEditing(true);
          // }}
        />
      </FieldWrapper>
      <FieldWrapper grid='2fr 1fr'>
        <Field
          name='details.tax_number'
          label='HST Number'
          component={RenderTextField}
          // onChange={(e) => {
          //   setDetails({
          //     details: {
          //       ...selectedDetails.details,
          //       tax_number: e.target.value,
          //     },
          //   });
          //   setIsEditing(true);
          // }}
        />
      </FieldWrapper>
      <FieldWrapper grid='2fr 1fr'>
        <Field
          label='Website URL'
          name='details.website_url'
          component={RenderTextField}
          // onChange={(e) => {
          //   setDetails({
          //     details: {
          //       ...selectedDetails.details,
          //       website_url: e.target.value,
          //     },
          //   });
          //   setIsEditing(true);
          // }}
          // label='Website URL'
        />
      </FieldWrapper>
      <ChooseImage
        imageUrl={values.details.image_url}
        setIsImageGallaryVisible={setMediaSelectionModalIsVisible}
        isNotRequired={true}
      />
    </Fragment>
  );
};

export default DetailsForm;
