import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actions from './actions';
import BusinessSearchSelect from './components/BusinessSearchSelect';

class BusinessSearchManager extends Component {
  componentDidMount() {
    if (this.props.defaultValue) {
      this.props.selectBusinessById(this.props.defaultValue);
    }
  }
  componentWillMount() {
    this.props.selectBusiness(false);
  }
  render() {
    return <BusinessSearchSelect {...this.props} />;
  }
}

function mapStateToProps(state) {
  return {
    businessSearchManager: state.businessSearchManager,
    businesses: state.businessLocationsList.businessLocationsOfMerchant
  };
}

export default connect(
  mapStateToProps,
  actions
)(BusinessSearchManager);
