import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import { getCustomersByQuery } from '../../../CustomerSearchManager/actions';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import CustomerSearchSelect from '../../../CustomerSearchManager/components/CustomerSearchSelect';
import FilterButton from '../../../../components/FilterModal/FilterButton';
import LocationFilterModalSingleSelect from '../../../../components/FilterModal/LocationFilterModalSingleSelect';
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: 0,
    height: 28,
  },
  //   selectEmpty: {
  //     marginTop: theme.spacing(2),
  //   },
}));
const statusOptions = [
  {
    label: 'Completed',
    value: 'completed',
  },
  {
    label: 'Partial',
    value: 'partial',
  },
  {
    label: 'Failed',
    value: 'failed',
  },
  {
    label: 'Refunded',
    value: 'refunded',
  },
];
const FilterValue = ({
  selectedFilterType,
  selectedFilterValue,
  filterIndex,
  updateFilterValue,
  customerSearchManager,
  getCustomersByQuery,
  orderTypesList,
}) => {
  const [queryString, setQueryString] = useState('');
  const [startUpdating, setStartUpdating] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedBusinessName, setSelectedBusinessName] = useState('');
  useEffect(() => {
    const debounce = setTimeout(() => {
      setStartUpdating(true);
    }, 1500);
    return () => {
      setStartUpdating(false);
      clearTimeout(debounce);
    };
  }, [queryString]);
  const handleChange = (e, specificKey) => {
    let selectedKey;
    let selectedValue;
    if (moment.isMoment(e)) {
      selectedKey = 'order_date';
      selectedValue = moment(e).format('YYYY-MM-DD');
    } else if (specificKey === 'customer_id') {
      selectedKey = specificKey;
      selectedValue = e?.customer_id;
    } else if (specificKey === 'business_id') {
      selectedKey = specificKey;
      selectedValue = e?.id;
    } else {
      selectedKey = e?.target?.name;
      selectedValue = e?.target?.value;
    }
    // console.log('selectedKey', selectedKey);
    // console.log('selectedValue', selectedValue);
    updateFilterValue(selectedKey, selectedValue, filterIndex);
  };
  return (
    <>
      {selectedFilterType === 'customer_id' ? (
        <CustomerSearchSelect
          value={null}
          selectCustomer={(customer) => handleChange(customer, 'customer_id')}
          queryString={queryString}
          setQueryString={setQueryString}
          startUpdating={startUpdating}
          setStartUpdating={setStartUpdating}
          customerSearchManager={customerSearchManager}
          getCustomersByQuery={getCustomersByQuery}
        />
      ) : selectedFilterType === 'order_id' ? (
        <>
          <TextField
            id='order_id'
            name='order_id'
            value={selectedFilterValue}
            onChange={handleChange}
            variant='outlined'
            placeholder='Enter Order ID'
          />
        </>
      ) : selectedFilterType === 'business_id' ? (
        <>
          <FilterButton
            message={
              selectedBusinessName
                ? `Selected Business (${selectedBusinessName})`
                : 'Select business'
            }
            height='55px'
            setIsOpen={setIsOpen}
            colorStyle={false}
          />
        </>
      ) : selectedFilterType === 'order_date' ? (
        <>
          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            <KeyboardDatePicker
              name='order_date'
              clearable
              autoOk
              disableFuture
              variant='inline'
              inputVariant='outlined'
              format='YYYY-MM-DD'
              mask='____-__-__'
              placeholder='YYYY-MM-DD'
              value={
                selectedFilterValue
                  ? moment(selectedFilterValue, 'YYYY-MM-DD')
                  : null
              }
              InputAdornmentProps={{ position: 'end' }}
              onChange={(date) => handleChange(date)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              style={{ width: '100%' }}
              // size='small'
            />
          </MuiPickersUtilsProvider>{' '}
        </>
      ) : selectedFilterType === 'order_type' ? (
        <>
          <FormControl variant='outlined'>
            <Select
              name='order_type'
              value={selectedFilterValue || 'none'}
              onChange={handleChange}
              style={{ color: !selectedFilterValue && '#A2A2A2' }}
            >
              <MenuItem value='none' disabled>
                Select an Order Type
              </MenuItem>
              {orderTypesList.map((option) => (
                <MenuItem
                  value={
                    option.type.toLowerCase().includes('drop')
                      ? 'drop-off'
                      : option.type.toLowerCase()
                  }
                >
                  {option.type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </>
      ) : selectedFilterType === 'status' ? (
        <>
          <FormControl variant='outlined' fullWidth>
            <Select
              name='status'
              labelId='demo-simple-select-outlined-label'
              id='demo-simple-select-outlined'
              value={selectedFilterValue || 'none'}
              onChange={handleChange}
              style={{ color: !selectedFilterValue && '#A2A2A2' }}
            >
              <MenuItem value='none' disabled>
                Select a Status
              </MenuItem>
              {statusOptions.map((option) => (
                <MenuItem value={option.value}>{option.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </>
      ) : selectedFilterType === 'pos_order_id' ? (
        <>
          <TextField
            id='pos_order_id'
            name='pos_order_id'
            value={selectedFilterValue}
            onChange={handleChange}
            variant='outlined'
            placeholder='Enter Order POS ID'
          />
        </>
      ) : (
        <></>
      )}
      {isOpen && (
        <LocationFilterModalSingleSelect
          approveButtonName='Select Business'
          placeholder='Enter Internal Location Name'
          searchLabel='Search by Internal Location Name'
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          initialBusiness={selectedFilterValue}
          handleSave={(business) => {
            setSelectedBusinessName(business.display_name);
            handleChange(business, 'business_id');
          }}
        />
      )}
    </>
  );
};

function mapStateToProps(state) {
  return {
    customerSearchManager: state.customerSearchManager,
    orderTypesList: state.fullReducer.orderTypesList,
  };
}

export default connect(mapStateToProps, { getCustomersByQuery })(FilterValue);
