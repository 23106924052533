import React, { Fragment, useState, useEffect, useRef } from 'react';
import { PatternFormat } from 'react-number-format';
import {
  RenderTextField,
  RenderToggleSwitch,
} from '../../../../../../components/FormElements/FormikElements';
import TextField from '@material-ui/core';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Collapse } from '@material-ui/core';
import ChevronUpIcon from '../../../../../../components/Icon/ChevronUpIcon';
import ChevronDownIcon from '../../../../../../components/Icon/ChevronDownIcon';
import { Button, ButtonRow } from 'merchant-portal-components';
import ConfirmCancelModal from '../../../../../MenuManagement/Products/ProductWizard/components/ConfirmCancelModal';
import MediaModalContainer from '../../../../../MediaModal/MediaModalContainer';
import {
  Title,
  TitleAndCollapseButton,
  Card,
  FormWrapper,
  FieldWrapper,
  Headline,
  PhoneNumberWrapper,
  PhoneNumberLabel,
} from './styles';
import { searchAddress } from './helper';
import {
  getCountries,
  getStates,
} from '../../../../../../utils/constants/constantOptions';
import AddressForm from './AddressForm';
import DetailsForm from './DetailsForm';
import * as Yup from 'yup';
const validationSchema = Yup.object().shape({
  address: Yup.object().shape({
    line1: Yup.string().required('Required'),
    city: Yup.string().required('Required'),
    country: Yup.string().required('Required'),
    state: Yup.string().required('Required'),
    zip: Yup.string().required('Required'),
    latitude: Yup.string().required('Required'),
    longitude: Yup.string().required('Required'),
  }),
  details: Yup.object().shape({
    display_name: Yup.string().required('Required'),
    phone: Yup.string().required('Required').min(10, 'Must be 10 digits'),
    language_code: Yup.string().required('Required'),
    timezone: Yup.string().required('Required'),
  }),
});
const AddressAndDetailsForm = ({
  businessId,
  initialValues,
  handleSubmit,
  languagesConfig,
  deactivateSection,
  isBusinessAdminRole,
}) => {
  const [mediaSelectionModalIsVisible, setMediaSelectionModalIsVisible] =
    useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [confirmCancelModalIsVisible, setConfirmCancelModalIsVisible] =
    useState(false);
  const states = getStates();
  const countries = getCountries();
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [selectedDetails, setDetails] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const form = useRef();

  useEffect(() => {
    // let input = document.getElementById('address-input');
    // let searchBox = new window.google.maps.places.Autocomplete(input, {
    //   componentRestrictions: { country: ['ca', 'usa'] },
    // });
    // setSearchBox(searchBox);
    // setLocalForm(initialValues);
    // if (initialValues?.address) {
    //   input.value = `${initialValues?.address.line1}`;
    // setSelectedAddress({
    //   address: { ...initialValues.address },
    // });
    // setDetails({
    //   details: { ...initialValues.details },
    // });
    // }
    // let searchBoxListener = searchBox.addListener('place_changed', () => {
    //   let place = searchBox.getPlace();
    //   if (!place) {
    //     setSelectedAddress(null);
    //     return;
    //   }
    //   const searchedAddress = searchAddress(place, states, countries);
    //   //   input.value = searchedAddress ? searchedAddress.line1
    //   if (searchedAddress) {
    //     setSelectedAddress({ address: searchedAddress });
    //     searchedAddress && setIsEditing(true);
    //     form.current.getFormikActions().validateForm();
    //   }
    // });
    // return () => {
    //   // window.google.maps.event.removeListener(searchBoxListener);
    // };
  }, []);
  useEffect(() => {
    if (form.current) {
      form.current.getFormikActions().validateForm();
    }
  }, []);

  // const formValues = selectedAddress
  //   ? {
  //       details: { ...selectedDetails.details },
  //       address: { ...selectedAddress.address },
  //     }
  //   : initialValues;
  return (
    <Card disabled={businessId && !deactivateSection ? false : 'true'}>
      <Formik
        initialValues={initialValues}
        ref={form}
        enableReinitialize={true}
        validationSchema={validationSchema}
      >
        {({
          values,
          touched,
          handleReset,
          dirty,
          setFieldValue,
          errors,
          setFieldError,
          setFieldTouched,
        }) => (
          <>
            {values.details && values.address && (
              <Fragment>
                <TitleAndCollapseButton>
                  <Title
                    style={{ textTransform: 'capitalize' }}
                    disabled={businessId && !deactivateSection ? false : 'true'}
                  >
                    Address & Details
                  </Title>
                  {!isExpanded ? (
                    <ChevronDownIcon
                      disabled={businessId && !deactivateSection ? false : true}
                      style={{
                        opacity:
                          businessId && !deactivateSection ? 'none' : '0.5',
                      }}
                      onClick={() => {
                        setIsExpanded(true);
                      }}
                    />
                  ) : (
                    <ChevronUpIcon
                      disabled={dirty}
                      onClick={() => {
                        setIsExpanded(false);
                      }}
                    />
                  )}
                </TitleAndCollapseButton>
                <Collapse in={isExpanded}>
                  <Form autoComplete='off'>
                    <FormWrapper>
                      <FieldWrapper grid='2fr 1fr'>
                        <Field
                          required
                          label='Display Name'
                          name='details.display_name'
                          component={RenderTextField}
                          // onChange={(e) => {
                          //   setDetails({
                          //     details: {
                          //       ...selectedDetails.details,
                          //       display_name: e.target.value,
                          //     },
                          //   });
                          //   // setFieldValue('details.display_name', e.target.value)
                          //   // setIsEditing(true);
                          // }}
                          error={
                            touched?.details?.display_name &&
                            errors?.details?.display_name
                          }
                          // validate={required}
                        />
                      </FieldWrapper>
                      <FieldWrapper grid='1fr 100px'>
                        <Field
                          label='Description (ex. Food Truck, Kiosk)'
                          name='details.description'
                          component={RenderTextField}
                          // onChange={(e) => {
                          //   setDetails({
                          //     details: {
                          //       ...selectedDetails.details,
                          //       description: e.target.value,
                          //     },
                          //   });
                          //   setIsEditing(true);
                          // }}
                        />
                      </FieldWrapper>
                      <Headline>Address</Headline>
                      <AddressForm
                        setSelectedAddress={setSelectedAddress}
                        setIsEditing={setIsEditing}
                        isEditing={isEditing}
                        AddressForm
                        countries={countries}
                        states={states}
                        values={values}
                        setFieldValue={setFieldValue}
                        selectedAddress={selectedAddress}
                        errors={errors}
                        touched={touched}
                        form={form}
                      />
                      <FieldWrapper grid='1fr 1fr 1fr 100px'>
                        <PhoneNumberWrapper>
                          <PhoneNumberLabel>
                            Business Phone Number *
                          </PhoneNumberLabel>
                          <PatternFormat
                            required
                            label='Phone Number'
                            value={values.details.phone}
                            onValueChange={(phone) => {
                              setFieldValue('details.phone', phone.value);
                            }}
                            valueIsNumericString={true}
                            customInput={TextField}
                            format='+1 (###) ###-####'
                            mask='_'
                            allowEmptyFormatting
                            style={{
                              padding: '15px',
                              border: '1px solid lightgray',
                              borderRadius: '4px',
                              width: '100%',
                            }}
                          />
                        </PhoneNumberWrapper>
                        <Field
                          name='details.is_phone_visible'
                          component={RenderToggleSwitch}
                          label='Visible'
                          onChange={(e) => {
                            setFieldValue(
                              'details.is_phone_visible',
                              e.target.checked
                            );
                            setDetails({
                              details: {
                                ...selectedDetails.details,
                                is_phone_visible: e.target.checked,
                              },
                            });
                            setIsEditing(true);
                          }}
                          style={{ marginTop: '15px' }}
                        />
                      </FieldWrapper>
                      <ErrorMessage name='details.phone'>
                        {(msg) => (
                          <div style={{ color: 'red', fontSize: '10px;' }}>
                            {msg}
                          </div>
                        )}
                      </ErrorMessage>
                      <Headline>Location Details</Headline>
                      <DetailsForm
                        selectedDetails={selectedDetails}
                        setIsEditing={setIsEditing}
                        isEditing={isEditing}
                        values={values}
                        setDetails={setDetails}
                        setMediaSelectionModalIsVisible={
                          setMediaSelectionModalIsVisible
                        }
                        errors={errors}
                        touched={touched}
                        languagesConfig={languagesConfig}
                      />
                      <ButtonRow
                        justifyContent='space-between'
                        style={{
                          margin: '24px 40px 24px 40px',
                          width: 'inherit',
                        }}
                      >
                        <Button
                          secondary
                          onClick={() => {
                            dirty
                              ? setConfirmCancelModalIsVisible(true)
                              : setIsExpanded(false);
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          disabled={
                            Object.keys(errors).length || isBusinessAdminRole
                          }
                          onClick={() => {
                            setIsEditing(false);
                            setIsExpanded(false);
                            handleSubmit(values, businessId);
                          }}
                        >
                          Save
                        </Button>
                      </ButtonRow>
                      {mediaSelectionModalIsVisible && (
                        <MediaModalContainer
                          closeModal={() =>
                            setMediaSelectionModalIsVisible(false)
                          }
                          selectProduct={(id, url) => {
                            setFieldValue('details.image_url', url);
                            setDetails({
                              details: {
                                ...selectedDetails.details,
                                image_url: url,
                              },
                            });
                            setIsEditing(true);
                          }}
                        />
                      )}
                      {confirmCancelModalIsVisible && (
                        <ConfirmCancelModal
                          onCancel={() => setConfirmCancelModalIsVisible(false)}
                          onConfirm={() => {
                            handleReset();
                            setConfirmCancelModalIsVisible(false);
                            setIsExpanded(false);
                          }}
                        />
                      )}
                    </FormWrapper>
                  </Form>
                </Collapse>
              </Fragment>
            )}
          </>
        )}
      </Formik>
    </Card>
  );
};

export default AddressAndDetailsForm;
