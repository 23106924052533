import {
  GETTING_COMBOS,
  GET_COMBOS,
  LOAD_EDIT_COMBO_PAGE,
  LOAD_COMBOS_LIST_PAGE,
  PATCH_ACTIVE_COMBO,
  GET_COMBO_BUSINESSES,
  GET_COMBO_ITEMS_PRODUCTS,
  GET_COMBO_AVAILABILITIES,
  GET_PRODUCTS_CATEGORIES,
  GET_PRODUCTS_VARIANTS,
  HANDLE_BASIC_INFORMATION_SAVE_REQUEST,
  GET_COMBOS_OPTIONS_OVERRIDES,
  PATCH_COMBO_BASIC_INFORMATION,
  INITIATE_CREATE_COMBO,
  HANDLE_COMBO_ITEMS_REQUEST,
  PATCH_COMBO_ITEMS,
  HANDLE_AVAILABILITIES_SAVE_REQUEST,
  PATCH_COMBO_AVAILABILITIES,
  GET_ALL_PRODUCTS,
  GET_ALL_COMBOS_SHARED_OPTIONS,
  RESET_COMBOS_REDUCER,
  PATCH_COMBO_BASIC_INFORMATION_ERROR,
  PATCH_SUCCESS_COMBO_ITEMS,
  GET_COMBOS_OPTIONS_OVERRIDES_REQUESTING,
  PATCH_COMBO_ITEMS_SUCCESS,
  RESET_COMBO_ITEM_SUBMIT,
  SAVING_COMBO_POSITIONS,
  SAVING_COMBO_ITEMS_POSITIONS,
  UPDATE_SORTED_COMBOS_LIST,
  GET_COMBO_OVERRIDES,
  SUBMIT_COMBO_OVERRIDES_REQUEST,
  SUBMIT_COMBO_OVERRIDES,
  GET_COMBOS_POSITIONS,
} from './combosTypes';
import api from '../../../api';
import { createAlert } from '../../Alert/actions';
import {
  isComboIncludedOptionValidation,
  isExtraChargePriceValidation,
} from '../../../components/FormElements/FormikElements';
import moment from 'moment';
import { ALL_ORDER_TYPE_ID } from '../Products/productsTypes';
import _ from 'lodash';
import { getSearchUrl } from '../../../utils/purePayload';

export function getCombos(
  requestParams = {
    page_number: 1,
    page_size: '25',
    sorting_option: 'title-asc',
    search_string: '',
    has_next_page: true,
  }
) {
  return function (dispatch) {
    const urlwithOutSearchString=`/combos?include=businesses&page_number=${requestParams.page_number}&page_size=${requestParams.page_size}` 
    const url = getSearchUrl(urlwithOutSearchString, requestParams.search_string, 3);

    dispatch({
      type: GETTING_COMBOS,
    });
    return api
      .get(
        url
        )
      .then((response) => {
        const hasNextPage = response.data.meta.has_next_page;
        dispatch({
          type: GET_COMBOS,
          combos: response.data.data,
          meta: response.data.meta,
          hasNextPage: hasNextPage,
        });
      });
  };
}
export function getCombosPosition() {
  return function (dispatch) {
    return api.get(`/combo/positions`).then((response) => {
      const newAllCombosPositions = response.data.data?.map((item) => {
        return {
          combo_id: item.combo_id,
          name: item.name,
          position: parseInt(item.position),
        };
      });
      let sortedPositions = _.sortBy(
        newAllCombosPositions,
        ['position'],
        'asc'
      );
      dispatch({
        type: GET_COMBOS_POSITIONS,
        allCombosPositions: sortedPositions,
      });
    });
  };
}
export function updateCombosPosition(comboPositions) {
  return function (dispatch) {
    return api
      .patch(`/combo/positions`, { combos: comboPositions })
      .then(() => {
        dispatch(getCombosPosition());
        dispatch(getCombos());
      });
  };
}
export function updateComboRequestParams(requestParams) {
  return function (dispatch) {
    dispatch({
      type: 'UPDATE_COMBO_REORDERING_REQUEST_PARAMS',
      payload: requestParams,
    });
  };
}

export function initiateEditCombo(combo) {
  let modifiedCombo = { ...combo, fixed_price: parseFloat(combo.fixed_price) };
  return function (dispatch, getState) {
    return api
      .get(`/combos/${modifiedCombo.id}`)
      .then((response) => {
        delete response.data.data.pos_mappings;
        return api
          .get(`/combos/${modifiedCombo.id}/option-overrides`)
          .then((overrideResponse) => {
            dispatch({
              type: LOAD_EDIT_COMBO_PAGE,
              combo: {
                ...response.data.data,
                order_types: response.data.data.order_types,
              },
              overrides: overrideResponse.data.data,
            });
            if (
              getState().accountReducer.appMetaData.configuration
                .smoothpay_integration_enabled
            ) {
              api
                .get(`/combos/${modifiedCombo.id}/taxes`)
                .then((taxResponse) => {
                  dispatch({
                    type: LOAD_EDIT_COMBO_PAGE,
                    combo: {
                      ...response.data.data,
                      tax_category_id: taxResponse.data.data.tax_category_id,
                      order_types: response.data.data.order_types,
                    },
                    overrides: overrideResponse.data.data,
                  });
                });
            }
          });
      })
      .catch((error) => {
        dispatch(
          createAlert({
            type: 'error',
            message: error?.response?.data?.errors?.message ||  'An error occurred getting product variants',
          })
        );
      });
  };
}

export function loadCombosListPage() {
  return function (dispatch) {
    dispatch(getCombos());
    dispatch({ type: LOAD_COMBOS_LIST_PAGE });
  };
}

export function toggleCombo(combo, isActive) {
  return function (dispatch) {
    return api
      .patch(`/combos/${combo.id}`, { is_active: isActive })
      .then((response) => {
        dispatch({
          type: PATCH_ACTIVE_COMBO,
          payload: { id: combo.id, isActive },
        });
        dispatch(
          createAlert({
            type: 'success',
            message: 'Combo is updated successfully',
          })
        );
      })
      .catch((error) => {
        dispatch(
          createAlert({
            type: 'error',
            message: error?.response?.data?.errors?.message ||  'An error occurred getting product variants',
          })
        );
      });
  };
}

export function getComboBusinesses(combo) {
  return function (dispatch) {
    return api.get(`/combos/${combo.id}/businesses`).then((response) => {
      dispatch({
        type: GET_COMBO_BUSINESSES,
        payload: response.data.data,
      });
    });
  };
}

export function getComboItems(products, variants, combo) {
  return function (dispatch) {
    return api
      .get(`/combos/${combo.id}/item-groups`)
      .then((itemGroupsResponse) => {
        const itemGroups = [];

        return Promise.all(
          itemGroupsResponse.data.data
            .filter((itemGroup) => itemGroup.is_active)
            .map((singleItemGroup, index) => {
              const itemId = singleItemGroup.id;

              itemGroups[index] = {
                id: itemId,
                label: singleItemGroup.label,
                is_active: singleItemGroup.is_active,
                is_required: singleItemGroup.is_required,
                quantity: singleItemGroup.quantity,
                position: singleItemGroup.position,
                productVariants: [],
              };
              return api
                .get(
                  `/combos/${combo.id}/item-groups/${itemId}/items?include=products.prices`
                )
                .then((singleItemResponse) => {
                  singleItemResponse.data.data.map((singleItem) => {
                    if (singleItem.products.length) {
                      singleItem.products.map((product) => {
                        let productName = product.name;
                        let productId = product.id;
                        let productExtraPrice = product.extra_price;
                        let productIsActive = product.is_active;
                        if (product.prices.length) {
                          product.prices.map((variant) => {
                            let variantId = variant.variant_id;
                            let variantName = variant.variant;
                            let variantPrice = variant.price;
                            itemGroups[index].productVariants.push({
                              productId,
                              productName,
                              productIsActive,
                              productExtraPrice,
                              variantId,
                              variantName,
                              variantPrice,
                            });
                          });
                        }
                      });
                    }
                  });
                });
            })
        ).then(() => {
          dispatch({
            type: GET_COMBO_ITEMS_PRODUCTS,
            payload: itemGroups,
          });
        });
      });
  };
}

export function getComboAvailabilities(combo) {
  return function (dispatch) {
    return api.get(`/combos/${combo.id}/availabilities`).then((response) => {
      dispatch({
        type: GET_COMBO_AVAILABILITIES,
        payload: response.data.data,
      });
    });
  };
}

export function getProductsCategories() {
  return function (dispatch) {
    return api
      .get('/menu/categories?page_number=1&page_size=2000')
      .then((response) => {
        dispatch({
          type: GET_PRODUCTS_CATEGORIES,
          payload: response.data.data,
        });
      })
      .catch((error) => {});
  };
}

export function getProductsVariants(category) {
  return function (dispatch) {
    return api
      .get(
        `/menu/categories/${category.id}?include=products.prices,sub_categories.products.prices`
      )
      .then((response) => {
        const finalProductsList = response.data.data.products.map(
          (product, index) => {
            const productVariants = product.prices.map((variant) => ({
              variantId: variant.product_variant_id,
              variantName: variant.name,
              variantIsActive: variant.is_active,
            }));

            return {
              productId: product.id,
              productName: product.name,
              productImageUrl: product.image_url,
              productIsActive: product.is_active,
              productVariants,
            };
          }
        );
        dispatch({
          type: GET_PRODUCTS_VARIANTS,
          payload: finalProductsList,
        });
      })
      .catch((error) => {
        dispatch(
          createAlert({
            type: 'error',
            message: error?.response?.data?.errors?.message || 'An error occurred getting product variants',
          })
        );
      });
  };
}

export const handleSortingComboSubmit = (comboList, callback) => {
  return (dispatch) => {
    dispatch({
      type: SAVING_COMBO_POSITIONS,
      payload: true,
    });

    const sendRequests = (startIndex = 0) => {
      let calls = [];
      let i = startIndex;
      for (; i < Math.min(startIndex + 5, comboList.length); i++) {
        const combo = comboList[i];
        const body = {
          position: i + 1,
          is_active: combo.is_active,
        };
        calls.push(api.patch(`/combos/${combo.id}`, body));
      }

      Promise.all(calls)
        .then((res) => {
          if (i + 1 < comboList.length) {
            sendRequests(i + 1);
          } else {
            dispatch(
              createAlert({
                type: 'success',
                message: 'Combos have been updated successfully',
              })
            );
            dispatch(getCombos());
            dispatch({
              type: SAVING_COMBO_POSITIONS,
              payload: false,
            });
            callback();
          }
        })
        .catch((err) => {
          dispatch(
            createAlert({
              type: 'success',
              message: 'There are errors while saving combos',
            })
          );
          dispatch({
            type: SAVING_COMBO_POSITIONS,
            payload: false,
          });
        });
    };

    sendRequests();
  };
};

export const handleSortingComboItemsSubmit = (combo, comboItemsList) => {
  return (dispatch) => {
    dispatch({
      type: SAVING_COMBO_ITEMS_POSITIONS,
      isSavingComboItemsPositions: true,
    });

    const sendRequests = (startIndex = 0) => {
      let calls = [];
      let i = startIndex;
      for (; i < Math.min(startIndex + 5, comboItemsList.length); i++) {
        const comboItem = comboItemsList[i];
        const body = {
          position: i + 1,
        };
        calls.push(
          api.patch(`/combos/${combo.id}/item-groups/${comboItem.id}`, body)
        );
      }

      Promise.all(calls)
        .then((res) => {
          if (i + 1 < comboItemsList.length) {
            sendRequests(i + 1);
          } else {
            dispatch(
              createAlert({
                type: 'success',
                message: 'Combo groups have been updated successfully',
              })
            );
            dispatch(getComboItems(null, null, combo));
            dispatch({
              type: SAVING_COMBO_ITEMS_POSITIONS,
              isSavingComboItemsPositions: false,
            });
          }
        })
        .catch((err) => {
          dispatch(
            createAlert({
              type: 'success',
              message: 'There are errors while saving combo groups',
            })
          );
          dispatch({
            type: SAVING_COMBO_ITEMS_POSITIONS,
            isSavingComboItemsPositions: false,
          });
        });
    };

    sendRequests();
  };
};

export function handleComboInformationSubmit(editingcombo, values, combosList) {
  return function (dispatch, getState) {
    dispatch({
      type: HANDLE_BASIC_INFORMATION_SAVE_REQUEST,
    });
    let apiCall = null;

    if (!editingcombo.id) {
      const body = {
        ...values,
        position: combosList.length + 1,
        start_date: moment(new Date().setHours(0, 0, 0, 0)).format(
          'YYYY-MM-DD HH:mm:ss'
        ),
        is_active: true,
        order_types: [1, 2],
      };
      delete body.tax_category_id;
      apiCall = api.post('/combos', body);
    } else {
      const body = {
        ...values,
      };
      delete body.id;
      delete body.tax_category_id;
      apiCall = api.patch(`/combos/${editingcombo.id}`, body);
    }
    return apiCall
      .then((response) => {
        dispatch(initiateEditCombo(response.data.data));
        if (
          getState().accountReducer.appMetaData.configuration
            .smoothpay_integration_enabled
        ) {
          api
            .put(`/combos/${response.data.data.id}/taxes`, {
              tax_category_id: values.tax_category_id,
            })
            .then((taxResponse) => {
              dispatch({
                type: PATCH_COMBO_BASIC_INFORMATION,
                payload: {
                  ...response.data.data,
                  tax_category_id: values.tax_category_id,
                },
              });
              dispatch(getCombos());
              dispatch(
                createAlert({
                  type: 'success',
                  message: 'Combo is updated successfully',
                })
              );
            });
        } else {
          dispatch(
            createAlert({
              type: 'success',
              message: 'Combo is updated successfully',
            })
          );
          dispatch(getCombos());
        }
      })
      .catch((err) => {
        dispatch(
          createAlert({
            type: 'error',
            message: err?.response?.data?.errors?.message || 
              'An error occurred trying to update the combo or you put a wrong format of price.',
          })
        );
        dispatch({
          type: PATCH_COMBO_BASIC_INFORMATION_ERROR,
        });
      });
  };
}

const getEndDayOfWeek = (combo) => {
  const startTime = moment(combo.start_time);
  const endTime = moment(combo.end_time);
  let endDayOfWeek = combo.start_day_of_week;
  if (startTime.isAfter(endTime)) {
    endDayOfWeek = endDayOfWeek + 1 > 7 ? 1 : endDayOfWeek + 1;
  }
  return endDayOfWeek;
};

export function handleComboAvailabilitiesSubmit(editingcombo, values) {
  return function (dispatch) {
    dispatch({
      type: HANDLE_AVAILABILITIES_SAVE_REQUEST,
    });
    const availableDaysOnly = values.comboAvailability.reduce((acc, curr) => {
      if (curr.end_time !== null) {
        acc.push(curr);
      }
      return acc;
    }, []);
    const comboAvailabilityBody = availableDaysOnly.map((combo) => ({
      start_day_of_week: combo.start_day_of_week,
      end_day_of_week: getEndDayOfWeek(combo),
      start_time: combo.start_time
        ? moment(combo.start_time).format('HH:mm:ss')
        : '00:00:00',
      end_time: combo.end_time
        ? moment(combo.end_time).format('HH:mm:ss')
        : '23:59:59',
    }));
    const startDate = moment(values.comboStartDate, 'YYYY-MM-DD HH:mm:ss')
      .set({ hour: 0, minute: 0, second: 0 })
      .format('YYYY-MM-DD HH:mm:ss');
    const expieryDate = values.comboNeverExpired
      ? null
      : moment(values.comboExpiryDate, 'YYYY-MM-DD HH:mm:ss')
          .set({ hour: 23, minute: 59, second: 59 })
          .format('YYYY-MM-DD HH:mm:ss');
    let body = {
      ...editingcombo,
      is_active: values.comboIsActive,
      expiry_date: expieryDate,
      start_date: startDate,
      order_types: values.comboOrderTypes.includes(ALL_ORDER_TYPE_ID)
        ? []
        : values.comboOrderTypes,
      discount_type: 'dollar',
    };
    delete body.id;
    delete body.pos_mapping;
    delete body.pos_mappings;
    delete body.tax_category_id;
    return Promise.all(
      [
        api.put(`/combos/${editingcombo.id}/businesses`, {
          businesses: values.comboLocations,
        }),
        api.put(`/combos/${editingcombo.id}/availabilities`, {
          availabilities: comboAvailabilityBody,
        }),
        api.patch(`/combos/${editingcombo.id}`, body),
      ].map((p) => p.catch((e) => e))
    ).then((results) => {
      results.map((result, index) => {
        if (result instanceof Error) {
          //if to check req number and dispatch error
          if (index === 0) {
            dispatch({
              type: PATCH_COMBO_AVAILABILITIES,
            });
            dispatch(
              createAlert({
                type: 'error',
                message:
                  'An error occurred trying to update combo businesses. ' +
                  result.response.data.error,
              })
            );
          } else if (index === 1) {
            dispatch({
              type: PATCH_COMBO_AVAILABILITIES,
            });
            dispatch(
              createAlert({
                type: 'error',
                message:
                  'An error occurred trying to update combo available hours.',
              })
            );
          } else {
            dispatch({
              type: PATCH_COMBO_AVAILABILITIES,
            });
            dispatch(
              createAlert({
                type: 'error',
                message:
                  'An error occurred trying to update combo. ' +
                  result.response.data.error,
              })
            );
          }
        } else {
          dispatch({
            type: PATCH_COMBO_AVAILABILITIES,
          });
          dispatch(
            createAlert({
              type: 'success',
              message: 'Combo availabilities are updated successfully',
            })
          );
          api.get(`/combos/${editingcombo.id}`).then((response) => {
            const combo = {
              ...response.data.data,
              order_types: response.data.data.order_types,
            };
            dispatch({
              type: LOAD_EDIT_COMBO_PAGE,
              combo: {
                ...response.data.data,
                order_types: response.data.data.order_types,
              },
            });
            dispatch(getComboBusinesses(combo));
            dispatch(getComboAvailabilities(combo));
          });
        }
      });
    });
  };
}

export function initiateCreateCombo() {
  return function (dispatch) {
    dispatch({
      type: INITIATE_CREATE_COMBO,
    });
  };
}

export function validateComboItemCard(values) {
  let errors = [];
  values.comboItems.map((comboItem) => {
    comboItem.productVariants.map((productVariant) => {
      if (isExtraChargePriceValidation(productVariant.productExtraPrice)) {
        errors.push('Extra charge price must be a valid format');
      }
    });
    if (comboItem.combosOptionsOverride) {
      comboItem.combosOptionsOverride.map((comboOptionOverride) => {
        if (isComboIncludedOptionValidation(comboOptionOverride.included_pick))
          errors.push('Included amount must be a number');
      });
    }
  });
  if (values.sharedOptionsCombos.length) {
    values.sharedOptionsCombos.map((sharedOptionCombo) => {
      if (
        isComboIncludedOptionValidation(
          sharedOptionCombo.combined_included_pick
        )
      )
        errors.push('Included amount must be a number');
    });
  }
  return errors;
}

export function handleComboItemsSubmit(editingCombo, values) {
  return function (dispatch, getState) {
    let errors = validateComboItemCard(values);

    if (errors.length) {
      dispatch(
        createAlert({
          type: 'error',
          message: errors[0],
        })
      );
      return;
    }

    dispatch({
      type: HANDLE_COMBO_ITEMS_REQUEST,
    });
    let comboItemGroupIds = [];
    let priceOverridesList = [];
    let optionOverridesList = [];

    Promise.all(
      values.comboItems.map((comboItem, index) => {
        let apiCall = null;
        let body = null;
        if (!comboItem.id) {
          body = {
            label: comboItem.label,
            quantity: 1,
            position: index + 1,
            is_active: true,
            is_required: comboItem.is_required,
          };
          apiCall = api.post(`/combos/${editingCombo.id}/item-groups`, body);
        } else {
          body = {
            ...comboItem,
            position: index + 1,
          };
          delete body.id;
          delete body.productVariants;
          body.combosOptionsOverride && delete body.combosOptionsOverride;
          apiCall = api.patch(
            `/combos/${editingCombo.id}/item-groups/${comboItem.id}`,
            body
          );
        }

        return apiCall.then((comboItemGroupResponse) => {
          const combo_item_group_id = comboItemGroupResponse.data.data.id;
          let productVariants = [];
          let priceOverrides = [];

          comboItem.productVariants.map((productVariant) => {
            productVariants.push({
              product_id: productVariant.productId,
              product_variant_id: productVariant.variantId,
            });
            if (productVariant.productExtraPrice) {
              priceOverrides.push({
                product_id: productVariant.productId,
                product_variant_id: productVariant.variantId,
                extra_price: productVariant.productExtraPrice,
              });
            }
          });

          let optionOverrides = [];
          comboItem.combosOptionsOverride &&
            comboItem.combosOptionsOverride.forEach((comboOptionOverride) => {
              if (!comboOptionOverride.included_pick) return;

              optionOverrides.push({
                product_option_id: parseInt(comboOptionOverride.id),
                included_pick: parseInt(comboOptionOverride.included_pick),
                combined_included_pick: null,
                combo_item_group_id: combo_item_group_id,
              });
            });

          comboItemGroupIds.push(combo_item_group_id);
          priceOverridesList.push(priceOverrides);
          optionOverridesList.push(...optionOverrides);
          return api.put(
            `/combos/${editingCombo.id}/item-groups/${combo_item_group_id}/items`,
            { items: productVariants }
          );
        });
      })
    ).then((promiseResponse) => {
      // combined included pick
      const sharedOptionsCombos = [];
      values.sharedOptionsCombos.forEach((option) => {
        if (!option.combined_included_pick) return;

        sharedOptionsCombos.push({
          product_option_id: parseInt(option.id),
          included_pick: null,
          combined_included_pick: parseInt(option.combined_included_pick),
          combo_item_group_id: null,
        });
      });

      const finalOptionsCombos = [
        ...optionOverridesList,
        ...sharedOptionsCombos,
      ];

      let oldOverrides = getState().combosReducer.editingOverrides;

      finalOptionsCombos.forEach((newOption) => {
        oldOverrides = oldOverrides.filter((oldOverride) => {
          if (
            oldOverride.product_option_id == newOption.product_option_id &&
            oldOverride.combo_item_group_id == newOption.combo_item_group_id
          )
            return false;

          return true;
        });
      });

      oldOverrides = oldOverrides.map((item) => {
        delete item.combo_id;
        return item;
      });

      const apiBodyOptionsOverride = [...finalOptionsCombos];

      let validResponses = promiseResponse.filter((p) => p);

      const newPromisesArray = [
        ...validResponses.map((response, index) => {
          return api.put(
            `/combos/${editingCombo.id}/item-groups/${comboItemGroupIds[index]}/price-overrides`,
            {
              price_overrides: priceOverridesList[index],
            }
          );
        }),
      ];

      newPromisesArray.push(
        api.put(`/combos/${editingCombo.id}/option-overrides`, {
          option_overrides: apiBodyOptionsOverride,
        })
      );

      Promise.all(newPromisesArray)
        .then((responses) => {
          dispatch(getComboItems(null, null, editingCombo));
          dispatch({
            type: PATCH_COMBO_ITEMS_SUCCESS,
          });
          dispatch({
            type: PATCH_COMBO_ITEMS,
          });
          dispatch(
            createAlert({
              type: 'success',
              message: 'Combo Items have been successfully updated',
            })
          );
        })
        .catch((e) => {
          dispatch({
            type: PATCH_COMBO_ITEMS,
          });
          dispatch(
            createAlert({
              type: 'error',
              message: !e.response.data.error
                ? 'An error occurred trying to save combo items'
                : e.response.data.error,
            })
          );
        });
    });
  };
}

export function getAllProducts(
  requestParams = {
    page_number: 1,
    page_size: '25',
    sorting_option: 'title-asc',
    search_string: '',
    has_next_page: true,
  }
) {
  return function (dispatch, getState) {

    const urlwithOutSearchString=`/menu/products?page_number=${requestParams.page_number}&page_size=${requestParams.page_size}&include=prices`;
    const url = getSearchUrl(urlwithOutSearchString, requestParams.search_string, 3);
    
    return api
      .get(url)
      .then((response) => {
        const allProducts = response.data.data.map((product, index) => {
          const productVariants = product.prices.map((variant) => ({
            //variantId: variant.variant_id,
            variantId: variant.product_variant_id,
            variantName: variant.name,
          }));

          return {
            productId: product.id,
            productName: product.name,
            productImageUrl: product.image_url,
            productIsActive: product.is_active,
            productVariants,
          };
        });

        const hasNextPage = response.data.meta.has_next_page;

        dispatch({
          type: GET_ALL_PRODUCTS,
          payload: allProducts,
          hasNextPage: hasNextPage,
        });
      });
  };
}

export function resetCombosReducer() {
  return {
    type: RESET_COMBOS_REDUCER,
  };
}

export function getSharedOptionsComboItems(comboItems, options, editingCombo) {
  return function (dispatch) {
    let overrides = [];
    let allOptions = [];
    let productCount = 0;
    // getting all option for combos' products
    const promises = [];
    comboItems.map((comboItem) => {
      productCount = productCount + comboItem.productVariants.length;
      const innerPromises = comboItem.productVariants.map((product) => {
        return api
          .get(`menu/products/${product.productId}/options`)
          .then((productOptionsResponse) => {
            const innerOptions = productOptionsResponse.data.data.map(
              (productOption) => {
                return productOption;
              }
            );
            allOptions.push(...innerOptions);
          });
      });

      promises.push(...innerPromises);
    });

    return Promise.all(promises).then((promiseResponses) => {
      let allOptionIDs = allOptions.map((option) => option.product_option_id);
      let uniqueAllOptionIDs = [...new Set(allOptionIDs)]; // remove duplicate items from option id  list

      const finalCommonOptions = [];

      uniqueAllOptionIDs.forEach((optionId) => {
        options.forEach((option) => {
          if (option.id === optionId) {
            finalCommonOptions.push({
              id: optionId,

              name: option.name,
            });
          }

          option.sub_options.forEach((subOption) => {
            if (subOption.id === optionId) {
              finalCommonOptions.push({
                id: optionId,

                name: option.name + ' - ' + subOption.name,
              });
            }
          });
        });
      });

      return api
        .get(`/combos/${editingCombo.id}/option-overrides`)
        .then((response) => {
          overrides = response.data.data;
          // logic to find combined included pick from the api response
          const superFinalCommonOptions = finalCommonOptions.map((option) => {
            overrides.forEach((override) => {
              if (parseInt(override.product_option_id) === parseInt(option.id))
                option.combined_included_pick = override.combined_included_pick;
            });

            if (!option.combined_included_pick)
              option.combined_included_pick = null;

            return option;
          });
          dispatch({
            type: GET_ALL_COMBOS_SHARED_OPTIONS,

            list: superFinalCommonOptions,
          });
        });
    });
  };
}

export function getCombosOptionsOverrides(
  comboItem,
  options,
  editingCombo,
  setFieldValue,
  comboItemFormik,
  comboItemIndex
) {
  return function (dispatch) {
    dispatch({
      type: GET_COMBOS_OPTIONS_OVERRIDES_REQUESTING,
    });

    let finalOptionsList = [];
    let overrides = [];

    let allOptions = [];
    let productCount = 0;

    const promises = [];
    productCount = productCount + comboItem.productVariants.length;
    const innerPromises = comboItem.productVariants.map((product) => {
      return api
        .get(`menu/products/${product.productId}/options`)
        .then((productOptionsResponse) => {
          const innerOptions = productOptionsResponse.data.data.map(
            (productOption) => productOption.product_option_id
          );
          allOptions.push(...innerOptions);
        });
    });

    promises.push(...innerPromises);

    return Promise.all(promises).then((promiseResponses) => {
      const duplicateOptionsCount = {};
      allOptions.forEach((x) => {
        duplicateOptionsCount[x] = (duplicateOptionsCount[x] || 0) + 1;
      });
      const commonOptions = [];
      Object.keys(duplicateOptionsCount).map((optionId) => {
        if (duplicateOptionsCount[optionId] === productCount)
          commonOptions.push(optionId);
      });
      const finalCommonOptions = [];

      commonOptions.forEach((cOptionId) => {
        const cOptionIdInt = parseInt(cOptionId);

        options.forEach((option) => {
          if (option.id === cOptionIdInt) {
            finalCommonOptions.push({
              id: cOptionId,
              name: option.name,
            });
          }

          option.sub_options.forEach((subOption) => {
            if (subOption.id === cOptionIdInt) {
              finalCommonOptions.push({
                id: cOptionId,
                name: option.name + ' - ' + subOption.name,
              });
            }
          });
        });
      });

      return api
        .get(`/combos/${editingCombo.id}/option-overrides`)
        .then((response) => {
          overrides = response.data.data;

          // logic
          let superFinal = finalCommonOptions.map((option) => {
            overrides.forEach((override) => {
              if (parseInt(override.product_option_id) == parseInt(option.id))
                option.included_pick = override.included_pick;
            });
            if (!option.included_pick) option.included_pick = null;
            return option;
          });

          if (comboItemFormik.combosOptionsOverride) {
            superFinal = superFinal.map((option) => {
              let includedPick = null;
              comboItemFormik.combosOptionsOverride.forEach((formikOption) => {
                if (parseInt(formikOption.id) == parseInt(option.id))
                  includedPick = formikOption.included_pick;
              });
              if (includedPick) option.included_pick = includedPick;
              return option;
            });
          }

          dispatch({
            type: GET_COMBOS_OPTIONS_OVERRIDES,
            list: superFinal, //finalOptionsList
            //comboItemId: comboItem.id
          });
          setFieldValue(`comboItems[${comboItemIndex}]`, {
            ...comboItemFormik,
            combosOptionsOverride: superFinal,
          });
        });
    });
  };
}

export function updateIsComboItemSubmit() {
  return function (dispatch) {
    return dispatch({
      type: RESET_COMBO_ITEM_SUBMIT,
    });
  };
}

export function updateCombosRequestParams(requestParams) {
  return {
    type: 'UPDATE_COMBOS_REQUEST_PARAMS',
    payload: requestParams,
  };
}

export function updateSortedCombosList(combosList, sortOrder) {
  return {
    type: UPDATE_SORTED_COMBOS_LIST,
    combosList: combosList,
    sortOrder: sortOrder,
  };
}

export function getComboOverrides(comboId) {
  return function (dispatch) {
    api
      .get(`/combos/${comboId}/business-overrides`)
      .then((response) => {
        dispatch({
          type: GET_COMBO_OVERRIDES,
          payload: response.data.data,
        });
      })
      .catch((err) => {
        createAlert({
          type: 'error',
          message: err?.response?.data?.errors?.message || 'An error occured trying to get overrides!',
        });
      });
  };
}

export function handleSubmitComboOverrides(
  comboId,
  summaryData,
  values,
  isCreate,
  initialBusinesses,
  index,
  initialValues,
  touched,
  cb
) {
  return function (dispatch) {
    if (isCreate && !touched.price) {
      dispatch(
        createAlert({
          type: 'error',
          message: 'Please modify the prices to save the overrides!',
        })
      );
      return;
    } else if (values.price <= 0) {
      dispatch(
        createAlert({
          type: 'error',
          message: `The override price must be greater than 0`,
        })
      );
      return;
    }

    cb();

    dispatch({
      type: SUBMIT_COMBO_OVERRIDES_REQUEST,
    });
    const initialBusinessesIds =
      initialBusinesses && initialBusinesses.map((val) => val.id);
    const deletedBusinessesIds = _.difference(
      initialBusinessesIds,
      values.assignedBusinesses
    );

    let businessesObj = {};
    summaryData.forEach((obj, i) => {
      obj.businesses.forEach((business) => {
        if (i == index && deletedBusinessesIds.includes(business.id)) {
          return;
        } else if (!businessesObj[business.id]) {
          businessesObj[business.id] = {
            business_id: business.id,
            fixed_price: obj.fixed_price,
          };
        } else {
          businessesObj[business.id].fixed_price = {
            ...businessesObj[business.id].price,
            ...values.price,
          };
        }
      });
    });

    values.assignedBusinesses.forEach((businessId) => {
      if (!businessesObj[businessId]) {
        businessesObj[businessId] = {
          business_id: businessId,
          fixed_price: values.price,
        };
      } else {
        businessesObj[businessId].fixed_price = values.price;
      }
    });

    api
      .put(`/combos/${comboId}/business-overrides`, {
        businesses: businessesObj,
      })
      .then((response) => {
        dispatch({
          type: SUBMIT_COMBO_OVERRIDES,
        });
        dispatch(
          createAlert({
            type: 'success',
            message: 'The product overrides have been successfully saved!',
          })
        );
        dispatch(getComboOverrides(comboId));
      })
      .catch((err) => {
        dispatch({
          type: SUBMIT_COMBO_OVERRIDES,
        });
        dispatch(
          createAlert({
            type: 'error',
            message: err?.response?.data?.errors?.message ||  'An error occurred trying to update the overrides!',
          })
        );
      });
  };
}
export function reassociateCombosOverrides(
  comboId,
  businesses,
  isDeleted = false,
  showMessage = false
) {
  return function (dispatch) {
    api
      .put(`/combos/${comboId}/business-overrides`, {
        businesses: businesses,
      })
      .then(() => {
        if (!showMessage) {
          dispatch(
            createAlert({
              type: 'success',
              message: `The combos overrides have been successfully ${
                isDeleted ? 'deleted' : 'updated'
              }!`,
            })
          );
        }
        dispatch(getComboOverrides(comboId));
      })
      .catch((err) => {
        if (!showMessage) {
          dispatch(
            createAlert({
              type: 'error',
              message: err?.response?.data?.errors?.message || `An error occurred trying to ${
                isDeleted ? 'delete' : 'update'
              } the overrides!`,
            })
          );
        }
      });
  };
}
