// Beutify Role Names
export const getRoles = roles => {
  return roles
    .filter(role => role != '' && role != 'ROLE_BUSINESS_SMOOTHPAY')
    .map(role => ({
      label: role
        .toLowerCase()
        .replace(/_/g, ' ')
        .replace(/role/g, '')
        .split(' ')
        .map(item => item.charAt(0).toUpperCase() + item.slice(1))
        .join(' '),
      value: role
    }));
};

//Get businesses
export const getBusinesses = businesses => {
  return businesses.map(business => ({
    label: business.display_name,
    value: business.id
  }));
};
