import React, { Fragment } from 'react';
import styled from 'styled-components';
import PermissionsIndex from './PermissionsIndex';
import RolesIndex from './RolesIndex';
import { getPagesWithPermissions } from '../configs/pagesConfig';

const ComponentCardWrapper = styled.div`
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
  border-radius: 12px;
  background-color: white;
  padding: 0;
  padding-bottom: 5px;
  margin-bottom: 20px;
`;

const Roles = (props) => {
  const {
    accounts,
    businesses,
    postResendInvitation,
    updateUser,
    patchUpdateRole,
    authUser,
    isModal,
    toggleCreateUserModal,
  } = props;

  const roles = getPagesWithPermissions();
  return (
    <Fragment>
      <ComponentCardWrapper>
        <PermissionsIndex accounts={accounts} />
      </ComponentCardWrapper>
      <ComponentCardWrapper>
        <RolesIndex
          accounts={accounts}
          roles={roles}
          businesses={businesses}
          postResendInvitation={postResendInvitation}
          updateUser={updateUser}
          patchUpdateRole={patchUpdateRole}
          authUser={authUser}
          isModal={isModal}
          toggleCreateUserModal={toggleCreateUserModal}
        />
      </ComponentCardWrapper>
    </Fragment>
  );
};
export default Roles;
