import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Field } from 'formik';
import { StyledText } from '../../../../../../components/StyledText/StyledText';
import {
  RenderTextField,
  isComboIncludedOptionValidation,
} from '../../../../../../components/FormElements/FormikElements';
import {
  Modal,
  Button,
  LoadingBlueLarge,
  Table,
  Tr,
  TrHead,
  Th,
  TBody,
  Td,
} from 'merchant-portal-components';

const ButtonsWrapper = styled.div`
  // display: grid;
  // grid-template-columns: 1fr 1fr;
  // grid-gap: 120px;
  text-align: right;
  padding: 20px;
  box-shadow: 0 -2px 9px 0 rgba(0, 0, 0, 0.12);
`;

const ComboOverridesWrapper = styled.div`
  overflow-y: scroll;
  height: 60vh;
`;

const ModalTitle = styled.div`
  display: grid;
  grid-template-columns: 1fr 30px 20px;
`;

const ModalWrapper = styled.div`
  padding: 24px;
  color: #191818;
`;

const LineDivider = styled.hr`
  border: solid 1px #ebebeb;
  margin-bottom: 23px;
  margin-top: 15px;
`;

const Text = styled.div`
  margin-top: 23px;
  margin-bottom: 16px;
`;

const PriceField = styled.div`
  width: 200px;
  margin-bottom: 30px;
`;

const OptionsTable = styled(Table)`
  border: none;
`;

const ThModal = styled(Th)`
  color: #191818;
  font-size: 15px;
`;

const FieldWrapper = styled(Field)`
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

const SharedIncludedOptionsModal = ({
  comboItems,
  options,
  getSharedOptionsComboItems,
  sharedOptionsCombos,
  setIsSharedIncludedOptionsModal,
  initValues,
  isRequestingGetSharedOptionsCombos,
  arrayHelpers,
}) => {
  useEffect(() => {
    getSharedOptionsComboItems(comboItems, options, initValues);
  }, []);

  return (
    <Modal>
      <div style={{ width: '550px' }}>
        <div>
          <ModalWrapper>
            <ModalTitle>
              <div>Shared Included Options</div>
              <div />
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => setIsSharedIncludedOptionsModal(false)}
              >
                x
              </div>
            </ModalTitle>
            <LineDivider />
            <ComboOverridesWrapper>
              {!isRequestingGetSharedOptionsCombos ? (
                !sharedOptionsCombos.length ? (
                  <span style={{ fontStyle: 'italic' }}>
                    There are no common options between products of combo items
                  </span>
                ) : (
                  <OptionsTable>
                    <TBody>
                      <TrHead>
                        <ThModal align="left">Option</ThModal>
                        <ThModal align="left">Included Amount</ThModal>
                      </TrHead>

                      {sharedOptionsCombos.map((option, index) => {
                        return (
                          <Tr key={index}>
                            <Td>
                              <>
                                {`${option.parentName ? option.parentName + ' - ' : ''}${
                                  option.name
                                } - `}
                                <StyledText fontWeight="300" fontStyle="italic">
                                  {option.id}
                                </StyledText>
                              </>
                            </Td>
                            <Td style={{ width: '170px' }}>
                              <FieldWrapper
                                name={`sharedOptionsCombos[${index}].combined_included_pick`}
                                component={RenderTextField}
                                validate={isComboIncludedOptionValidation}
                                error={
                                  arrayHelpers.form.touched.sharedOptionsCombos &&
                                  arrayHelpers.form.touched.sharedOptionsCombos[index] &&
                                  arrayHelpers.form.touched.sharedOptionsCombos[index]
                                    .combined_included_pick
                                    ? isComboIncludedOptionValidation(
                                        arrayHelpers.form.values.sharedOptionsCombos[index]
                                          .combined_included_pick
                                      )
                                    : null
                                }
                                isPrice
                                onKeyDown={(evt) =>
                                  (evt.key === 'e' ||
                                    evt.keyCode === 188 ||
                                    evt.keyCode === 190 ||
                                    evt.keyCode === 110 ||
                                    evt.key === '+' ||
                                    evt.key === '-') &&
                                  evt.preventDefault()
                                }
                              />
                            </Td>

                            {option.subOptions &&
                              option.subOptions.map((subOption) => {
                                return (
                                  <div>
                                    <Td>{subOption.name}</Td>
                                    <Td style={{ width: '170px' }}>
                                      <FieldWrapper
                                        name={`sharedOptionsCombos[${index}].combined_included_pick`}
                                        component={RenderTextField}
                                        validate={isComboIncludedOptionValidation}
                                        error={
                                          arrayHelpers.form.touched.sharedOptionsCombos &&
                                          arrayHelpers.form.touched.sharedOptionsCombos[index] &&
                                          arrayHelpers.form.touched.sharedOptionsCombos[index]
                                            .combined_included_pick
                                            ? isComboIncludedOptionValidation(
                                                arrayHelpers.form.values.sharedOptionsCombos[index]
                                                  .combined_included_pick
                                              )
                                            : null
                                        }
                                        isPrice
                                        onKeyDown={(evt) =>
                                          (evt.key === 'e' ||
                                            evt.keyCode === 188 ||
                                            evt.keyCode === 190 ||
                                            evt.keyCode === 110 ||
                                            evt.key === '+' ||
                                            evt.key === '-') &&
                                          evt.preventDefault()
                                        }
                                      />
                                    </Td>
                                  </div>
                                );
                              })}
                          </Tr>
                        );
                      })}
                    </TBody>
                  </OptionsTable>
                )
              ) : (
                <LoadingBlueLarge />
              )}
            </ComboOverridesWrapper>
          </ModalWrapper>

          <ButtonsWrapper>
            <Button
              onClick={() => setIsSharedIncludedOptionsModal(false)}
              //   {
              //   handleReset();
              //   toggleCombosOverridesModal();
              // }}
            >
              Close
            </Button>
          </ButtonsWrapper>
        </div>
      </div>
    </Modal>
  );
};

export default SharedIncludedOptionsModal;

//combo item group id null
// also included pick null
// combined_included_pick
