import {
  GET_UPSELL_MENU_REQUEST,
  GET_UPSELL_MENU_SUCCESS,
  GET_UPSELL_MENU_FAILURE,
  ADD_PRODUCTS_TO_UPSELL_MENU_DRAFT,
  REMOVE_PRODUCT_FROM_UPSELL_MENU_DRAFT,
  SORT_UPSELL_MENU_DRAFT,
  EDIT_UPSELL_MENU_REQUEST,
  EDIT_UPSELL_MENU_SUCCESS,
  EDIT_UPSELL_MENU_FAILURE,
  UNDO_UNSAVED_UPSELL_MENU_CHANGES,
  GET_CATEGORIES_UPSELL_MODAL,
  GET_CATEGORIES_UPSELL_MODAL_REQUEST,
  GET_CATEGORIES_UPSELL_MODAL_FAILURE,
  GET_PRODUCTS_UPSELL_MODAL_REQUEST,
  GET_PRODUCTS_UPSELL_MODAL,
  GET_PRODUCTS_UPSELL_MODAL_FAILURE,
  RESET_UPSELL_MODAL,
} from './upsellTypes';
import api from '../../../api';
import { createAlert } from '../../Alert/actions';

export function getUpsellMenu() {
  return function (dispatch) {
    dispatch({ type: GET_UPSELL_MENU_REQUEST });
    return api
      .get('/menu/upsell/products')
      .then((response) => {
        dispatch({
          type: GET_UPSELL_MENU_SUCCESS,
          upsellMenu: response.data.data,
        });
      })
      .catch((error) => {
        dispatch({ type: GET_UPSELL_MENU_FAILURE, error });
      });
  };
}

export function addProductsToUpsellMenuDraft(products) {
  return {
    type: ADD_PRODUCTS_TO_UPSELL_MENU_DRAFT,
    products,
  };
}

export function removeProductFromUpsellMenuDraft(productId) {
  return {
    type: REMOVE_PRODUCT_FROM_UPSELL_MENU_DRAFT,
    productId,
  };
}

export function sortUpsellMenuDraft(sortedUpsellMenu) {
  return {
    type: SORT_UPSELL_MENU_DRAFT,
    sortedUpsellMenu,
  };
}

export function editUpsellMenu(upsellMenu) {
  return function (dispatch) {
    dispatch({ type: EDIT_UPSELL_MENU_REQUEST });
    return api
      .put('/menu/upsell/products', {
        upsell_menu: upsellMenu.map(({ id }, position) => ({
          product_id: id,
          position,
        })),
      })
      .then((response) => {
        dispatch({
          type: EDIT_UPSELL_MENU_SUCCESS,
        });
        dispatch(
          createAlert({
            type: 'success',
            message: 'Upsell Menu was successfully updated',
          })
        );
        dispatch(getUpsellMenu());
      })
      .catch((error) => {
        dispatch({ type: EDIT_UPSELL_MENU_FAILURE, error });
        dispatch(
          createAlert({
            type: 'error',
            message: 'An error occurred trying to update Upsell Menu',
          })
        );
      });
  };
}

export function undoUnsavedUpsellMenuChanges() {
  return {
    type: UNDO_UNSAVED_UPSELL_MENU_CHANGES,
  };
}

export function getCategoriesUpsellModal() {
  return function (dispatch) {
    dispatch({ type: GET_CATEGORIES_UPSELL_MODAL_REQUEST });
    return api
      .get('/menu/categories?page_number=1&page_size=2000')
      .then((response) => {
        dispatch({
          type: GET_CATEGORIES_UPSELL_MODAL,
          upsellCategoriesModal: response.data.data,
        });
      })
      .catch((error) => {
        dispatch({ type: GET_CATEGORIES_UPSELL_MODAL_FAILURE, error });
      });
  };
}

export function getProductsUpsellModal(categoryId) {
  return function (dispatch) {
    dispatch({ type: GET_PRODUCTS_UPSELL_MODAL_REQUEST });
    return api
      .get(`/menu/categories/${categoryId}?include=products`)
      .then((response) => {
        dispatch({
          type: GET_PRODUCTS_UPSELL_MODAL,
          upsellProductsModal: response.data.data.products,
        });
      })
      .catch((error) => {
        dispatch({ type: GET_PRODUCTS_UPSELL_MODAL_FAILURE, error });
      });
  };
}

export function saveUpsellModalSelectedProducts(
  existingUpsellProduct,
  upsellProductsModalMenu
) {
  return function (dispatch) {
    // if (existingUpsellProduct.length === upsellProductsModalMenu.length) {
    //   if (objectsAreSame(existingUpsellProduct, upsellProductsModalMenu)) {
    //     dispatch(
    //       createAlert({
    //         type: 'error',
    //         message: 'There are no changes made to be saved'
    //       })
    //     );
    //     return;
    //   }
    // }
    return api
      .put('/menu/upsell/products', {
        upsell_menu: upsellProductsModalMenu.map((product, position) => ({
          product_id: product.id,
          position: position,
        })),
      })
      .then((response) => {
        dispatch(getUpsellMenu());
        dispatch({
          type: RESET_UPSELL_MODAL,
        });
        dispatch(
          createAlert({
            type: 'success',
            message: 'Upsell Menu was successfully updated',
          })
        );
      });
  };
}
