import {
  GET_AUTOMATED_MESSAGES_LIST,
  GET_AUTOMATED_MESSAGES_LIST_REQUEST,
  SAVE_MESSAGE_REQUEST,
  SAVE_MESSAGE_SUCCESS,
  SAVE_MESSAGE_FAILURE,
} from './automatedMessagesTypes';

export default function (
  state = {
    automatedMessagesList: null,
    isRequestingGetMessagesList: true,
    savingIds: [],
  },
  action
) {
  switch (action.type) {
    case GET_AUTOMATED_MESSAGES_LIST_REQUEST:
      return {
        ...state,
        isRequestingGetMessagesList: true,
      };
    case GET_AUTOMATED_MESSAGES_LIST:
      return {
        ...state,
        automatedMessagesList: action.payload,
        isRequestingGetMessagesList: false,
      };

    case SAVE_MESSAGE_REQUEST:
      return {
        ...state,
        savingIds: [...state.savingIds, action.id],
      };

    case SAVE_MESSAGE_SUCCESS:
      return {
        ...state,
        savingIds: state.savingIds.filter((id) => id !== action.id),
      };
    case SAVE_MESSAGE_FAILURE:
      return {
        ...state,
        savingIds: state.savingIds.filter((id) => id !== action.id),
      };
  }
  return state;
}
