import React, { Fragment, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Formik, Field } from 'formik';
import moment from 'moment';
import { setAvailabilityOption } from './utils/helper.js';
import { dayToString } from './utils/helper.js';
import { renderAvailabilityOptions } from './utils/helper.js';
import {
  RenderTimePicker,
  RenderSelect,
} from '../../components/FormElements/FormikElements';
import { Button } from 'merchant-portal-components';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';

const ModalTitle = styled.div`
  display: grid;
  grid-template-columns: 10fr 1fr;
`;

const LineDivider = styled.hr`
  border: solid 1px #e1e1e1;
`;

const ButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 10px 20px;
  width: 100%;

  > :last-child {
    justify-self: right;
  }

  > :first-child {
    justify-self: left;
  }
`;

const HoursSelectionGrid = styled.div`
  display: grid;
  grid-column-gap: 25px;
  align-items: center;
  margin-top: 25px;
  grid-template-columns: 160px 250px auto;
  grid-gap: 25px;
  min-height: 75px;
  padding-bottom: 15px;

  .error-entry {
    border: 1px solid red;
  }
  .disabled {
    pointer-events: none;
    opacity: 0.5;
  }
`;

const TimeSelectionGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
`;

const DayName = styled.p`
  font-weight: 600;
  align-self: center;
  text-transform: capitalize;
  font-size: 14px !important;
`;

const HoursField = styled.div`
  text-align: left;

  .rw-popup-container.rw-dropup {
    z-index: 99999 !important;
    position: absolute;
    top: 100%;
    left: 0;
  }
  span.labelHour {
    display: block;
    margin-top: -30px;
  }
  input {
    height: 56px;
  }
`;
const AvailableHoursCommonModal = ({
  //comboAvailabilities,
  setFieldValue,
  toggleAvailableHoursModal,
  productAvailabilities,
  handleReset,
  setIsProductAvailableAllTime,
  checkErrorEndTime,
}) => {
  const [newProductAvailabilities, setNewProductAvailabilities] = useState();
  const updatedForm = (productAvailableHours) => {
    return {
      mySelectKey: [
        !productAvailableHours.isAvailableAllDay &&
        !productAvailableHours.start_time
          ? {
              id: 2,
              name: 'Not Available All Day',
            }
          : productAvailableHours.isAvailableAllDay
          ? {
              id: 1,
              name: 'Available All Day',
            }
          : {
              id: 3,
              name: 'Select Available Hours',
            },
      ],
    };
  };
  const [myForm, setMyForm] = useState();
  const [isErrorHour, setisErrorHour] = useState();
  let countError;
  const checkError = (data) => {
    data.forEach((products, index) => {
      const startTime = moment(products.start_time).format('HH:mm:ss');
      const endTime = moment(products.end_time).format('HH:mm:ss');
      if (startTime > endTime && startTime != 'Invalid date') {
        setisErrorHour(true);
        countError = 1;
      }
      if (countError == 0 || countError == undefined) {
        setisErrorHour(false);
      }
    });
  };
  const onChangeTime = (field, value, index, setFieldValue) => {
    const newArray = productAvailabilities.map((productAvailableHours, i) => {
      if (i === index) {
        return {
          ...productAvailableHours,
          [field]: value,
        };
      }

      return productAvailableHours;
    });
    setFieldValue('productAvailabilities', newArray);
    setNewProductAvailabilities(newArray);
    checkError(newArray);
  };
  return (
    <Dialog open fullWidth maxWidth='md'>
      <DialogTitle>
        <ModalTitle>
          <div>Set Product Available Hours</div>
          <div
            onClick={() =>
              toggleAvailableHoursModal(
                newProductAvailabilities || productAvailabilities,
                myForm?.mySelectKey
              )
            }
            style={{ cursor: 'pointer' }}
          >
            x
          </div>
        </ModalTitle>
      </DialogTitle>
      <DialogContent>
        {productAvailabilities?.map((productAvailableHours, index) => {
          return (
            <div key={index}>
              <HoursSelectionGrid key={index}>
                <DayName>
                  {dayToString(productAvailableHours.start_day_of_week)}
                </DayName>
                <Field
                  // name={`combosAvailableHours[${index}]`}
                  component={RenderSelect}
                  options={renderAvailabilityOptions()}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option}
                  onChange={(option) => {
                    setMyForm({
                      ...updatedForm(productAvailableHours),
                      mySelectKey: option,
                    });
                    setAvailabilityOption(
                      productAvailabilities,
                      option,
                      index,
                      setFieldValue
                    );
                  }}
                  defaultValue={myForm?.mySelectKey}
                  placeholder={
                    !productAvailableHours?.isAvailableAllDay &&
                    !productAvailableHours?.start_time
                      ? 'Not Available All Day'
                      : productAvailableHours.isAvailableAllDay
                      ? 'Available All Day'
                      : 'Select Available Hours'
                  }
                  required
                  isSearchable={false}
                />
                {!productAvailableHours.isAvailableAllDay &&
                !productAvailableHours.start_time ? (
                  <div>Product is not available all day</div>
                ) : productAvailableHours.isAvailableAllDay ? (
                  'Product is Available all day'
                ) : (
                  <TimeSelectionGrid>
                    <HoursField>
                      <span className='labelHour'>Start</span>
                      <Field
                        name={`productAvailabilities[${index}].start_time`}
                        component={RenderTimePicker}
                        onChange={(value) => {
                          onChangeTime(
                            'start_time',
                            value,
                            index,
                            setFieldValue
                          );
                          setFieldValue(
                            `productAvailabilities[${index}].start_time`,
                            value
                          );
                        }}
                        hideDate
                        max={new Date(moment('January 1, 3999 23:30:00'))}
                      />
                    </HoursField>
                    <HoursField>
                      <span className='labelHour'>End</span>
                      <Field
                        name={`productAvailabilities[${index}].end_time`}
                        component={RenderTimePicker}
                        className={
                          productAvailabilities[index].start_time >
                          productAvailabilities[index].end_time
                            ? 'error-entry'
                            : null
                        }
                        onChange={(value) => {
                          onChangeTime('end_time', value, index, setFieldValue);
                          setFieldValue(
                            `productAvailabilities[${index}].end_time`,
                            value
                          );
                        }}
                        min={
                          new Date(
                            moment(productAvailabilities[index].start_time).add(
                              '30',
                              'm'
                            )
                          )
                        }
                        hideDate
                      />
                    </HoursField>
                  </TimeSelectionGrid>
                )}
              </HoursSelectionGrid>
              <LineDivider />
            </div>
          );
        })}
      </DialogContent>
      <DialogActions>
        <ButtonsWrapper>
          <Button
            secondary
            onClick={() => {
              handleReset();
              toggleAvailableHoursModal(
                newProductAvailabilities || productAvailabilities,
                myForm?.mySelectKey
              );
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={isErrorHour}
            onClick={() => {
              setTimeout(() => {
                toggleAvailableHoursModal(
                  newProductAvailabilities || productAvailabilities,
                  myForm?.mySelectKey
                );
              }, 1000);
            }}
          >
            Save
          </Button>
        </ButtonsWrapper>
      </DialogActions>
    </Dialog>
  );
};

export default AvailableHoursCommonModal;
