import React from "react";
import styled from "styled-components";
import { Button } from "merchant-portal-components";
import { Icon } from "merchant-portal-components";
import { SELECT_LOCATION_SCREEN } from "../priceTypes";

const ButtonWrapper = styled(Button)`
  margin-top: 23px;
  margin-bottom: 30px;
`;

const OverridesRow = styled.div`
  margin-bottom: 30px;
  display: grid;
  grid-template-columns: 1fr 60px;
  grid-gap: 50px;
`;

const IconWrapper = styled(Icon)`
  margin-left: 6px;
  font-size: 14px;
`;

const VariantName = styled.div`
  color: #191818;
  margin-top: 20px;
  font-size: 14px;
`;

const BusinessName = styled.span`
  color: #191818;
  font-weight: 600;
`;

const LocationSummaryScreen = ({
  setPage,
  singleItemOverrides,
  formattedBusinesses,
  summaryData,
  setSummaryData,
  isPageEdit,
  setIsPageEdit,
  editingVariant,
  setEditingVariant,
  priceKey,
}) => {
  let summaryDataModified;
  summaryDataModified = summaryData.map((data) => ({
    ...data,
    item: {
      prices: data.item.prices.filter(
        (variant) => variant.name && variant.isSelected
      ),
    },
  }));

  summaryDataModified = summaryDataModified.filter(
    (data) => data.item.prices.length > 0
  );

  return (
    <div>
      <ButtonWrapper
        onClick={() => {
          setPage(SELECT_LOCATION_SCREEN);
          setIsPageEdit(false);
          setEditingVariant({ assignedBusinesses: [], item: null });
        }}
      >
        Add Override
      </ButtonWrapper>
      {summaryDataModified.map((data, index) => {
        return (
          <OverridesRow key={index}>
            <div>
              {data.businesses.map((business, businessIndex) => {
                return (
                  <BusinessName key={businessIndex}>
                    {business.name},{" "}
                  </BusinessName>
                );
              })}

              {data.item.prices.map((variant, variantIndex) => {
                return (
                  <VariantName key={variantIndex}>
                    {variant.name} - {variant.is_active ? "Active" : "Inactive"}{" "}
                    - {variant[priceKey]}
                  </VariantName>
                );
              })}
            </div>
            <div>
              Edit
              <IconWrapper
                className="fas fa-pencil"
                onClick={() => {
                  setEditingVariant({
                    assignedBusinesses: summaryDataModified[index].businesses,
                    item: {
                      ...summaryDataModified[index].item,
                      prices: summaryDataModified[index].item.prices.filter(
                        (variant) => variant.name
                      ),
                    },
                    index: index,
                  });
                  setPage(SELECT_LOCATION_SCREEN);
                  setIsPageEdit(true);
                  setSummaryData(summaryDataModified);
                }}
              />
            </div>
          </OverridesRow>
        );
      })}
    </div>
  );
};

export default LocationSummaryScreen;
