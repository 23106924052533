export const GET_PRODUCTS_REQUEST = 'GET_PRODUCTS_REQUEST';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_FAILURE = 'GET_PRODUCTS_FAILURE';
export const SHOW_PRODUCT_INDEX = 'SHOW_PRODUCT_INDEX';
export const TOGGLE_PRODUCT_REQUEST = 'TOGGLE_PRODUCT_REQUEST';
export const TOGGLE_PRODUCT_SUCCESS = 'TOGGLE_PRODUCT_SUCCESS';
export const TOGGLE_PRODUCT_FAILURE = 'TOGGLE_PRODUCT_FAILURE';

export const GET_PRODUCT_AVAILABILITIES = 'GET_PRODUCT_AVAILABILITIES';
export const PATCH_PRODUCT_AVAILABILITIES = 'PATCH_PRODUCT_AVAILABILITIES';

export const SET_PRODUCT_WIZARD_IS_VISIBLE = 'SET_PRODUCT_WIZARD_IS_VISIBLE';
export const RESET_PRODUCT_REDUCER_STATE = 'RESET_PRODUCT_REDUCER_STATE';
export const PATCH_PRODUCT_REQUEST = 'PATCH_PRODUCT_REQUEST';
export const PATCH_PRODUCT_SUCCESS = 'PATCH_PRODUCT_SUCCESS';
export const PATCH_PRODUCT_FAILURE = 'PATCH_PRODUCT_FAILURE';
export const CREATE_PRODUCT_REQUEST = 'CREATE_PRODUCT_REQUEST';
export const CREATE_PRODUCT_SUCCESS = 'CREATE_PRODUCT_SUCCESS';
export const CREATE_PRODUCT_FAILURE = 'CREATE_PRODUCT_FAILURE';
export const INITIATE_CREATE_PRODUCT = 'INITIATE_CREATE_PRODUCT';
export const GET_PRODUCT_INFORMATION = 'GET_PRODUCT_INFORMATION';
export const LINK_PRODUCT_WITH_CATEGORIES_SUCCESS =
  'LINK_PRODUCT_WITH_CATEGORIES_SUCCESS';
export const GET_PRODUCT_CATEGORIES_SUCCESS = 'GET_PRODUCT_CATEGORIES_SUCCESS';
export const GET_PRODUCT_VARIANTS_SUCCESS = 'GET_PRODUCT_VARIANTS_SUCCESS';
export const UPDATE_PRODUCT_VARIANT_INITIAL_VALUES =
  'UPDATE_PRODUCT_VARIANT_INITIAL_VALUES';
export const RESET_PRODUCTION_VARIANT_INITIAL_VALUES =
  'RESET_PRODUCTION_VARIANT_INITIAL_VALUES';
export const PUT_PRODUCT_VARIANTS_SUCCESS = 'PUT_PRODUCT_VARIANTS_SUCCESS';
export const GET_PRODUCT_OPTIONS_SUCCESS = 'GET_PRODUCT_OPTIONS_SUCCESS';
export const PUT_PRODUCT_OPTIONS_SUCCESS = 'PUT_PRODUCT_OPTIONS_SUCCESS';
export const UPDATE_PRODUCT_TOGGLES = 'UPDATE_PRODUCT_TOGGLES';
export const GET_PRODUCT_BUSINESS_SUCCESS = 'GET_PRODUCT_BUSINESS_SUCCESS';
export const UPDATE_PRODUCT_BUSINESSES = 'UPDATE_PRODUCT_BUSINESSES';
export const GET_PRODUCT_TAGS_SUCCESS = 'GET_PRODUCT_TAGS_SUCCESS';
export const PUT_PRODUCT_TAGS_SUCCESS = 'PUT_PRODUCT_TAGS_SUCCESS';
export const SET_PRODUCT_PREVIEW_FLAT_PRODUCT =
  'SET_PRODUCT_PREVIEW_FLAT_PRODUCT';
export const CHANGE_VARIANT = 'CHANGE_VARIANT';
export const INCREMENT_ITEM = 'INCREMENT_ITEM';
export const DECREMENT_ITEM = 'DECREMENT_ITEM';
export const INCREMENT_SUB_ITEM = 'INCREMENT_SUB_ITEM';
export const DECREMENT_SUB_ITEM = 'DECREMENT_SUB_ITEM';
export const VALIDATE_OPTION = 'VALIDATE_OPTION';

export const GET_SELECTED_BUSINESS_REQUESTING =
  'GET_SELECTED_BUSINESS_REQUESTING';
export const GET_SELECTED_BUSINESS_SUCCESS = 'GET_SELECTED_BUSINESS_SUCCESS';
export const GET_SELECTED_BUSINESS_FAILURE = 'GET_SELECTED_BUSINESS_FAILURE';
export const PUT_SELECTED_BUSINESS_SUCCESS = 'PUT_SELECTED_BUSINESS_SUCCESS';
export const UPDATE_SORTED_PRODUCTS_LIST = 'UPDATE_SORTED_PRODUCTS_LIST';

export const GET_PRODUCT_OVERRIDES = 'GET_PRODUCT_OVERRIDES';
export const GET_PRODUCT_ASSIGNED_BUSINESSES =
  'GET_PRODUCT_ASSIGNED_BUSINESSES';
export const SUBMIT_PRODUCT_OVERRIDES_REQUEST =
  'SUBMIT_PRODUCT_OVERRIDES_REQUEST';
export const SUBMIT_PRODUCT_OVERRIDES = 'SUBMIT_PRODUCT_OVERRIDES';
export const GET_PRODUCT_VARIANTS_REQUEST = 'GET_PRODUCT_VARIANTS_REQUEST';
export const CHANGE_ORDER_TYPE = 'CHANGE_ORDER_TYPE';
export const GET_PRODUCTS_VARIANTS_SUCCESS = 'GET_PRODUCTS_VARIANTS_SUCCESS';

export const ALL_ORDER_TYPE_ID = -1;
export const SCAN_AND_GO = 4;
