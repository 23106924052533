const STREET_NUMBER = 'street_number';
const ROUTE = 'route';
const LOCALITY = 'locality';
const ADMINISTRATIVE_ADREA_LEVEL_1 = 'administrative_area_level_1';
const COUNTRY = 'country';
const POSTAL_CODE = 'postal_code';
const POSTAL_TOWN = 'postal_town';

export const searchAddress = (rawAddress, states, countries) => {
    const addressComponents = rawAddress.address_components;
      let streetNumber;
      let route;
      let locality;
      let administrativeAreaLevel1;
      let country;
      let postalCode;
      let latitude;
      let longitude;
        try{
            streetNumber = addressComponents.find(component =>
                component.types.includes(STREET_NUMBER)
              ).long_name;
              route = addressComponents.find(component =>
                component.types.includes(ROUTE)
              ).long_name;
              locality = (
                addressComponents.find(component =>
                  component.types.includes(LOCALITY)
                ) ||
                addressComponents.find(component =>
                  component.types.includes(POSTAL_TOWN)
                )
              ).long_name;
              administrativeAreaLevel1 = states.find(
                  s =>
                    s.name ===
                    addressComponents.find(component =>
                      component.types.includes(ADMINISTRATIVE_ADREA_LEVEL_1)
                    ).long_name
                ).abbreviation;
      
              country = countries.find(
                c =>
                  c.name ===
                  addressComponents.find(component =>
                    component.types.includes(COUNTRY)
                  ).long_name
              ).abbreviation;
              postalCode = addressComponents.find(component =>
                component.types.includes(POSTAL_CODE)
              ).long_name;
              latitude = rawAddress.geometry.location.lat();
              longitude = rawAddress.geometry.location.lng();

              return {
                line1: `${streetNumber} ${route}`,
                city: locality,
                state: administrativeAreaLevel1,
                zip: postalCode,
                country: country,
                latitude: latitude,
                longitude: longitude
              };
        }catch(e){
            return null;
        }

};