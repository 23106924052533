import React from 'react';

import {
  DefaultFilterButton,
  DefaultFilterColorButton,
} from './filterModalStyles';
const FilterButton = ({ setIsOpen, message, width, height, colorStyle }) => {
  return (
    <>
      {!colorStyle ? (
        <DefaultFilterButton
          onClick={() => setIsOpen(true)}
          height={height}
          width={width}
        >
          <span>{message}</span>
        </DefaultFilterButton>
      ) : (
        <DefaultFilterColorButton
          onClick={() => setIsOpen(true)}
          height={height}
          width={width}
        >
          <span>{message}</span>
        </DefaultFilterColorButton>
      )}
    </>
  );
};

export default FilterButton;
