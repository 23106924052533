import React from 'react';
import {
  AvailableToggleSwitchWrapper,
  AvailabilityButton,
} from '../styles/style.js';
import AvailableHoursModal from './AvailableHoursModal';
import { SmallToggleSwitch } from '../../../../../../components/ToggleSwitch/ToggleSwitch';

const AvailableDaysRow = ({
  values,
  setFieldValue,
  isComboAvailableAllTime,
  handleisAvailableAllTime,
  setIsComboAvailableAllTime,
  availabilityModalState,
  toggleAvailableHoursModal,
  initValues,
  handleReset,
}) => {
  return (
    <div>
      <AvailableToggleSwitchWrapper>
        <SmallToggleSwitch
          checked={isComboAvailableAllTime}
          onChange={({ target: { checked } }) =>
            handleisAvailableAllTime(checked, setFieldValue)
          }
          value='checkedB'
          color='primary'
          inputProps={{ 'aria-label': 'primary checkbox' }}
          label='Available All Days (Customize time & day-based availability here, In-stock availability can be managed at the bottom)'
        />
        {!isComboAvailableAllTime && (
          <AvailabilityButton onClick={() => toggleAvailableHoursModal(true)}>
            Modify Combo Availability
          </AvailabilityButton>
        )}
      </AvailableToggleSwitchWrapper>
      {availabilityModalState && (
        <AvailableHoursModal
          comboAvailability={values.comboAvailability}
          setFieldValue={setFieldValue}
          toggleAvailableHoursModal={toggleAvailableHoursModal}
          initValues={initValues}
          handleReset={handleReset}
          setIsComboAvailableAllTime={setIsComboAvailableAllTime}
        ></AvailableHoursModal>
      )}
    </div>
  );
};

export default AvailableDaysRow;
