import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Field } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { RenderSelect } from '../../../../../components/FormElements/FormikElements';
import FilterButton from '../../../../../components/FilterModal/FilterButton';
import FilterProductModal from '../../../../../components/FilterModal/FilterProductModal';
import FilterVariantModal from '../../../../../components/FilterModal/FilterVariantModal';
import { values } from 'lodash';
const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      minWidth: '100%',
    },
  },
}));
const ProductWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 100px;
`;

const FieldWrapper = styled.div`
  display: grid;
  grid-gap: 40px;
  grid-template-rows: 120px auto;
`;

const Subtitle = styled.div`
  font-weight: 600;
`;

const ConditionWrapper = styled.div`
  display: grid;
  grid-gap: 15px;
`;

const Conditions = ({
  allProducts,
  allVariants,
  conditions,
  templateName,
  setFieldValue,
}) => {
  let prefix = '';
  if (templateName === 'free-item') {
    prefix = 'eligible';
  } else {
    prefix = 'discounted';
  }
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [formField, setFormField] = useState('');
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [filteredVariants, setFilteredVariants] = useState([]);
  const [requiredProductsPOSValue, setRequiredProductsPOSValue] = useState([]);
  const [eligibleProductsPOSValue, setEligibleProductsPOSValue] = useState([]);
  const openModal = (field, products) => {
    setFormField(field);
    setFilteredProducts(products);
    setIsOpen(true);
  };
  const openModal2 = (field, products) => {
    setFormField(field);
    setFilteredVariants(products);
    setIsOpen2(true);
  };
  useEffect(() => {
    let newRequiredPOSs = [];
    let newEligiblePOSs = [];
    conditions.forEach((condition, index) => {
      let requiredPOSIDs = { source: condition.source };
      let eligiblePOSIDs = { source: condition.source };
      if (condition.source !== 'web' && condition.source !== 'mobile') {
        const requiredProducts = JSON.stringify(
          condition.item_conditions.required_products
        )
          ?.replace('[', '')
          ?.replace(']', '')
          ?.replaceAll('"', '');

        const eligibleProducts = JSON.stringify(
          prefix === 'eligible'
            ? condition.item_conditions.eligible_products
            : condition.item_conditions.discounted_products
        )
          ?.replace('[', '')
          ?.replace(']', '')
          ?.replaceAll('"', '');
        requiredPOSIDs = { ...requiredPOSIDs, products: requiredProducts };
        eligiblePOSIDs = { ...eligiblePOSIDs, products: eligibleProducts };
        newRequiredPOSs.push(requiredPOSIDs);
        newEligiblePOSs.push(eligiblePOSIDs);
      }
    });
    setRequiredProductsPOSValue(newRequiredPOSs);
    setEligibleProductsPOSValue(newEligiblePOSs);
  }, [conditions.length]);
  const handleChangePOSIDs = (e, setFieldValue, type, source) => {
    const filed = e.target.name;
    const value = e.target.value;
    let formatedFiledValue = [];
    let filedValue = value.split(',');
    filedValue.forEach((item) => {
      formatedFiledValue.push(item);
      // if (!isNaN(item) && !isNaN(parseFloat(item))) {
      // }
    });
    setFieldValue(filed, formatedFiledValue);
    if (type === 'required') {
      let newRequiredProduct = [...requiredProductsPOSValue];
      const index = requiredProductsPOSValue.findIndex(
        (item) => item.source === source
      );
      newRequiredProduct[index].products = value;
      setRequiredProductsPOSValue(newRequiredProduct);
    } else {
      let newEligibleProduct = [...eligibleProductsPOSValue];
      const index = eligibleProductsPOSValue.findIndex(
        (item) => item.source === source
      );
      newEligibleProduct[index].products = value;
      setEligibleProductsPOSValue(newEligibleProduct);
    }
  };
  const getRequiredPOSValue = (source) => {
    const selectedRequired = requiredProductsPOSValue.find(
      (item) => item.source === source
    );
    return selectedRequired ? selectedRequired.products : '';
  };
  const getEligiblePOSValue = (source) => {
    const selectedEligible = eligibleProductsPOSValue.find(
      (item) => item.source === source
    );
    return selectedEligible ? selectedEligible.products : '';
  };
  return conditions.map((condition, index) => {
    return (
      <React.Fragment key={index}>
        <Subtitle style={{ margin: '10px 0', textTransform: 'capitalize' }}>
          {condition.source}
        </Subtitle>
        <ProductWrapper>
          <FieldWrapper>
            <div>
              <Subtitle>Pre-Required Products</Subtitle>
              <div>
                These are Products that the customer would have to have in their
                cart for this offer to be applicable. If you want to offer a
                discount without Pre-Requisite Products, continue to Discounted
                Products
              </div>
            </div>
            <div>
              {condition.source === 'web' || condition.source === 'mobile' ? (
                <FilterButton
                  message={
                    conditions[index]?.item_conditions?.required_products
                      ?.length === 0
                      ? 'No Product Selected'
                      : conditions[index].item_conditions.required_products
                          ?.length === allProducts?.length
                      ? 'All Product Selected'
                      : `Selected Products (${conditions[index].item_conditions.required_products?.length})`
                  }
                  height='55px'
                  setIsOpen={() =>
                    openModal(
                      `conditions[${index}].item_conditions.required_products`,
                      conditions[index].item_conditions.required_products
                    )
                  }
                />
              ) : (
                <TextField
                  multiline
                  className={classes.root}
                  id='outlined-basic'
                  placeholder='Type POS IDs'
                  name={`conditions[${index}].item_conditions.required_products`}
                  value={getRequiredPOSValue(condition.source)}
                  onChange={(e) =>
                    handleChangePOSIDs(
                      e,
                      setFieldValue,
                      'required',
                      condition.source
                    )
                  }
                  variant='outlined'
                  style={{ width: '100%' }}
                />
              )}
              {isOpen && (
                <FilterProductModal
                  placeholder={`Enter product name (${index})`}
                  searchLabel='Search by product name'
                  filteredProducts={filteredProducts}
                  setFilteredProducts={setFieldValue}
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  formField={formField}
                  isEmptyAccepted={false}
                />
              )}
            </div>
          </FieldWrapper>
          <FieldWrapper>
            <div>
              <Subtitle>*Eligible Products</Subtitle>
              <div>
                These are Products that can be free. If there are no
                Pre-Requisite Products, the free products will be available to
                all customers that can view it on their app.
              </div>
            </div>
            <div>
              {condition.source === 'web' || condition.source === 'mobile' ? (
                <FilterButton
                  message={
                    conditions[index].item_conditions[`${prefix}_products`]
                      ?.length === 0
                      ? 'No Product Selected'
                      : conditions[index].item_conditions[`${prefix}_products`]
                          ?.length === allProducts?.length
                      ? 'All Product Selected'
                      : `Selected Products (${
                          conditions[index].item_conditions[
                            `${prefix}_products`
                          ]?.length
                        })`
                  }
                  height='55px'
                  setIsOpen={() =>
                    openModal(
                      `conditions[${index}].item_conditions.${prefix}_products`,
                      conditions[index].item_conditions[`${prefix}_products`]
                    )
                  }
                />
              ) : (
                <TextField
                  multiline
                  className={classes.root}
                  id='outlined-basic'
                  placeholder='Type POS IDs'
                  name={`conditions[${index}].item_conditions.${prefix}_products`}
                  value={getEligiblePOSValue(condition.source)}
                  onChange={(e) =>
                    handleChangePOSIDs(
                      e,
                      setFieldValue,
                      'eligible',
                      condition.source
                    )
                  }
                  variant='outlined'
                  style={{ width: '100%' }}
                />
              )}

              {isOpen && (
                <FilterProductModal
                  placeholder='Enter product name'
                  searchLabel='Search by product name'
                  filteredProducts={filteredProducts}
                  setFilteredProducts={setFieldValue}
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  formField={formField}
                  isEmptyAccepted={false}
                />
              )}
            </div>
          </FieldWrapper>
        </ProductWrapper>
        {(condition.source === 'web' || condition.source === 'mobile') && (
          <ProductWrapper>
            <FieldWrapper>
              <div>
                <Subtitle>Pre-Required Variants</Subtitle>
                <div>
                  These are Variants that the customer would have to have in
                  their cart for this offer to be applicable. If you want to
                  offer a discount without Pre-Requisite Variants, continue to
                  Discounted Variants
                </div>
              </div>
              <div>
                <FilterButton
                  message={
                    conditions[index].item_conditions.required_variants
                      ?.length === 0
                      ? 'No Variants Selected'
                      : conditions[index].item_conditions.required_variants
                          ?.length === allVariants.length
                      ? 'All Variants Selected'
                      : `Selected Variants (${conditions[index].item_conditions.required_variants?.length})`
                  }
                  height='55px'
                  setIsOpen={() =>
                    openModal2(
                      `conditions[${index}].item_conditions.required_variants`,
                      conditions[index].item_conditions.required_variants
                    )
                  }
                />
                {isOpen2 && (
                  <FilterVariantModal
                    placeholder='Enter variant name'
                    searchLabel='Search by variant name'
                    filteredVariants={filteredVariants}
                    setFilteredVariants={setFieldValue}
                    isOpen={isOpen2}
                    setIsOpen={setIsOpen2}
                    formField={formField}
                    isEmptyAccepted={false}
                  />
                )}
              </div>
            </FieldWrapper>
            <FieldWrapper>
              <div>
                <Subtitle>*Eligible Variants</Subtitle>
                <div>
                  These are Variants that can be free. If there are no
                  Pre-Requisite Variants, the free Variants will be available to
                  all customers that can view it on their app.
                </div>
              </div>
              <div>
                <FilterButton
                  message={
                    conditions[index].item_conditions[`${prefix}_variants`]
                      ?.length === 0
                      ? 'No Variants Selected'
                      : conditions[index].item_conditions[`${prefix}_variants`]
                          ?.length === allVariants.length
                      ? 'All Variants Selected'
                      : `Selected Variants (${
                          conditions[index].item_conditions[
                            `${prefix}_variants`
                          ]?.length
                        })`
                  }
                  height='55px'
                  setIsOpen={() =>
                    openModal2(
                      `conditions[${index}].item_conditions.${prefix}_variants`,
                      conditions[index].item_conditions[`${prefix}_variants`]
                    )
                  }
                />
                {isOpen2 && (
                  <FilterVariantModal
                    placeholder='Enter variant name'
                    searchLabel='Search by variant name'
                    filteredVariants={filteredVariants}
                    setFilteredVariants={setFieldValue}
                    isOpen={isOpen2}
                    setIsOpen={setIsOpen2}
                    formField={formField}
                    isEmptyAccepted={false}
                  />
                )}
              </div>
            </FieldWrapper>
          </ProductWrapper>
        )}
        {templateName === 'discounted-item' ||
        templateName === 'birthday-discounted-item' ? (
          <ConditionWrapper>
            <div>
              Do all required products need to be in cart for this offer to be
              active?*
            </div>
            <Field
              name={`conditions[${index}].item_conditions.required_product_condition`}
              component={RenderSelect}
              options={[
                { value: 'AND', label: 'Yes' },
                { value: 'OR', label: 'No' },
              ]}
            />
          </ConditionWrapper>
        ) : null}
        <hr />
      </React.Fragment>
    );
  });
};

export default Conditions;
