import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { LoadingBlueLarge } from 'merchant-portal-components';
import PaginationControl from '../Pagination/PaginationControl';
import { Button } from 'merchant-portal-components';
import {
  Checkbox,
  TextField,
  InputAdornment,
  Switch,
  FormControlLabel,
} from '@material-ui/core';
import { Dialog } from '@material-ui/core';
import {
  StyledCard,
  HeaderWrapper,
  SearchWrapper,
  SectionTitle,
  LocationsCounter,
  RowContainer,
  Row,
  TH,
  TD,
  PaginationWrapper,
  ButtonWrapper,
  Left,
  Right,
  CancelButton,
  CloseIcon,
  ActiveSwitchWrapper,
} from './filterModalStyles';
import * as actions from '../../modules/MenuManagement/Products/productsActions';
import { paginate } from './paginate';

const initiallRequestParams = {
  page_number: 1,
  page_size: '25',
  sorting_option: 'title-asc',
};

const activeProductVariants = (allProductVariants) => {
  let activeList = [];
  for (var i = 0; i < allProductVariants.length; i++) {
    if (
      allProductVariants[i].is_active &&
      allProductVariants[i].prices.length > 0
    ) {
      let newProductVariant = { ...allProductVariants[i], prices: [] };
      allProductVariants[i].prices.forEach((varianat) => {
        if (varianat.is_active) {
          newProductVariant.prices.push(varianat);
        }
      });
      if (newProductVariant.prices.length > 0) {
        activeList.push(newProductVariant);
      }
    } else {
      if (allProductVariants[i].is_active) {
        activeList.push(allProductVariants[i]);
      }
    }
  }
  return activeList;
};

const FilterProductVariantsModal = ({
  allProductVariants,
  requestParams,
  filteredProducts,
  setFilteredProducts,
  isOpen,
  setIsOpen,
  placeholder,
  searchLabel,
  formField,
  isEmptyAccepted,
  emptyIsEqualToAll,
  getProductsVariants,
  updateProductsRequestParams,
  clearProductsList,
  isAllProductsSelected,
}) => {
  delete requestParams.has_next_page;
  const [allActiveProductVariants, setAllActiveProductVariants] = useState([]);
  const [displayActiveProducts, setDisplayActiveProducts] = useState(true);
  const [currentPageProducts, setCurrentPageProducts] = useState([]);
  const [productListLength, setProductListLength] = useState([]);
  const [searchString, setSearchString] = useState('');
  const [selectedProducts, setSelectedProducts] = useState();
  const [selectedProductsInitial, setSelectedProductsInitial] = useState();
  const [allSearchedProducts, setAllSearchedProducts] = useState([]);
  const [allSearchedSelectedProducts, setAllSearchedSelectedProducts] =
    useState([]);
  const [isAllProductVariantsSelected, setIsAllProductVariantsSelected] =
    useState(isAllProductsSelected);
  useEffect(() => {
    clearProductsList();
    if (allProductVariants.length === 0) {
      getProductsVariants({
        page_number: 1,
        page_size: '4000',
        search_string: '',
      });
    }
    updateProductsRequestParams(initiallRequestParams);
    return () => {
      updateProductsRequestParams(initiallRequestParams);
    };
  }, []);
  useEffect(() => {
    if (displayActiveProducts) {
      setAllActiveProductVariants(activeProductVariants(allProductVariants));
    } else {
      setAllActiveProductVariants(allProductVariants);
    }
  }, [displayActiveProducts, allProductVariants]);
  //
  const updateInitials = useCallback(() => {
    let initialProductVariants = [];
    allActiveProductVariants?.forEach((product) => {
      const productId = product.id;
      let newProductVariant;
      newProductVariant = {
        id: productId,
        checked: '',
        variants: null,
      };
      if (product.prices.length > 0) {
        let newVariants = [];
        product.prices?.forEach((variant) => {
          const newVariant = {
            id: variant.product_variant_id,
            checked: false,
          };
          newVariants.push(newVariant);
        });
        newProductVariant = { ...newProductVariant, variants: newVariants };
      }
      initialProductVariants.push(newProductVariant);
    });
    if (emptyIsEqualToAll && filteredProducts?.length === 0) {
      initialProductVariants = [];
      allActiveProductVariants?.forEach((product) => {
        const productId = product.id;
        let newProductVariant;
        newProductVariant = {
          id: productId,
          checked: 'full',
          variants: null,
        };
        if (product.prices.length > 0) {
          let newVariants = [];
          product.prices?.forEach((variant) => {
            const newVariant = {
              id: variant.product_variant_id,
              checked: true,
            };
            newVariants.push(newVariant);
          });
          newProductVariant = { ...newProductVariant, variants: newVariants };
        }
        initialProductVariants.push(newProductVariant);
      });
    } else if (filteredProducts?.length > 0) {
      initialProductVariants.forEach((prodVar, prodIndex) => {
        if (prodVar.variants) {
          let count = 0;
          prodVar.variants.forEach((variant, varIndex) => {
            const newVariant = filteredProducts.find(
              (item) =>
                item.variant_id === variant.id && item.product_id === prodVar.id
            );
            if (newVariant) {
              count++;
              initialProductVariants[prodIndex].variants[
                varIndex
              ].checked = true;
            }
          });
          if (count === prodVar.variants.length) {
            // if all variants exist in the map, check product checkbox
            initialProductVariants[prodIndex].checked = 'full';
          } else if (count > 0 && count < prodVar.variants.length) {
            initialProductVariants[prodIndex].checked = 'semi';
          } else {
            initialProductVariants[prodIndex].checked = '';
          }
        } else {
          const newProduct = filteredProducts.find(
            (item) => item.product_id === prodVar.id
          );
          if (newProduct) {
            initialProductVariants[prodIndex].checked = 'full';
          }
        }
      });
    }
    setSelectedProducts(initialProductVariants);
    setSelectedProductsInitial(
      JSON.parse(JSON.stringify(initialProductVariants))
    );
    setAllSearchedProducts(initialProductVariants);
  }, [allActiveProductVariants, filteredProducts]);
  useEffect(() => {
    updateInitials();
  }, [updateInitials]);

  const filterProductList = useCallback(() => {
    let filteredList = allActiveProductVariants;
    if (searchString) {
      filteredList = allActiveProductVariants?.filter((item) => {
        if (item.prices?.length > 0) {
          if (item.name?.toLowerCase().includes(searchString?.toLowerCase())) {
            return true;
          } else {
            let hasCharacter = false;
            item.prices?.forEach((price) => {
              if (
                price.name?.toLowerCase().includes(searchString?.toLowerCase())
              ) {
                hasCharacter = true;
              }
            });
            return hasCharacter;
          }
        } else {
          if (item.name?.toLowerCase().includes(searchString?.toLowerCase())) {
            return true;
          }
        }
      });
    }
    setAllSearchedProducts(filteredList);
    let paginatedList = paginate(
      filteredList,
      requestParams.page_number,
      requestParams.page_size
    );
    setCurrentPageProducts(paginatedList);
    setProductListLength(filteredList.length);

    // all is all Product Selected
    let isAllSelected = true;
    selectedProducts?.forEach((product) => {
      if (product.checked !== 'full') {
        isAllSelected = false;
      }
    });
    setIsAllProductVariantsSelected(isAllSelected);
  }, [
    requestParams.page_number,
    requestParams.page_size,
    selectedProducts,
    JSON.stringify(allActiveProductVariants),
  ]);
  useEffect(() => {
    filterProductList();
  }, [filterProductList]);
  useEffect(() => {
    if (allActiveProductVariants?.length > 0) {
      const filteredList = allActiveProductVariants?.filter((item) => {
        if (item.prices?.length > 0) {
          if (item.name?.toLowerCase().includes(searchString?.toLowerCase())) {
            return true;
          } else {
            let hasCharacter = false;
            item.prices?.forEach((price) => {
              if (
                price.name?.toLowerCase().includes(searchString?.toLowerCase())
              ) {
                hasCharacter = true;
              }
            });
            return hasCharacter;
          }
        } else {
          if (item.name?.toLowerCase().includes(searchString?.toLowerCase())) {
            return true;
          }
        }
      });
      setAllSearchedProducts(filteredList);
      const paginatedList = paginate(filteredList, 1, requestParams.page_size);
      updateProductsRequestParams({ ...requestParams, page_number: 1 });
      setCurrentPageProducts(paginatedList);
      setProductListLength(filteredList.length);
    }
  }, [searchString, allActiveProductVariants]);
  /// product variant selection

  const handleAssignProducts = (e, productId, varIndex) => {
    let newSelectedProducts = [...selectedProducts];
    const productIndex = selectedProducts.findIndex(
      (product) => product.id === productId
    );
    if (varIndex >= 0) {
      newSelectedProducts[productIndex].variants[varIndex].checked =
        e.target.checked;
      const len = newSelectedProducts[productIndex].variants.length;
      const checkedLen = newSelectedProducts[productIndex].variants.filter(
        (variant) => variant.checked
      ).length;
      if (len === checkedLen) {
        newSelectedProducts[productIndex].checked = 'full';
      } else if (len > checkedLen && checkedLen > 0) {
        newSelectedProducts[productIndex].checked = 'semi';
      } else {
        newSelectedProducts[productIndex].checked = '';
      }
    } else {
      if (e.target.checked) {
        newSelectedProducts[productIndex].checked = 'full';
        if (selectedProducts[productIndex].variants?.length > 0) {
          selectedProducts[productIndex].variants.forEach((variant, index) => {
            newSelectedProducts[productIndex].variants[index].checked = true;
          });
        }
      } else {
        newSelectedProducts[productIndex].checked = '';
        if (selectedProducts[productIndex].variants?.length > 0) {
          selectedProducts[productIndex].variants.forEach((variant, index) => {
            newSelectedProducts[productIndex].variants[index].checked = false;
          });
        }
      }
    }
    setSelectedProducts(newSelectedProducts);
  };
  const updateNewAllSelectedProducts = (newAllProductVariants) => {
    const modifiedAllSelectedProducts = selectedProducts.reduce((acc, curr) => {
      const foundNewSelecedProduct = newAllProductVariants.find(
        (productVar) => productVar.id === curr.id
      );
      if (foundNewSelecedProduct) {
        acc.push(foundNewSelecedProduct);
      } else {
        acc.push(curr);
      }
      return acc;
    }, []);
    setSelectedProducts(modifiedAllSelectedProducts);
  };
  const handleAssignAllProducts = (e) => {
    const isChecked = e.target.checked;
    let newAllProductVariants = [];
    allSearchedProducts?.forEach((product) => {
      const productId = product.id;
      let productVariant = {
        id: productId,
        checked: isChecked ? 'full' : '',
        variants: null,
      };
      if (product.prices.length > 0) {
        let newVariants = [];
        product.prices?.forEach((variant) => {
          const newVariant = {
            id: variant.product_variant_id,
            checked: isChecked,
          };
          newVariants.push(newVariant);
        });
        productVariant = { ...productVariant, variants: newVariants };
      }
      newAllProductVariants.push(productVariant);
    });
    updateNewAllSelectedProducts(newAllProductVariants);
    setAllSearchedSelectedProducts(isChecked ? newAllProductVariants : []);
    setIsAllProductVariantsSelected(isChecked);
  };
  const handleAddProducts = () => {
    let formatedSelectedProductvariants = [];
    selectedProducts.forEach((product) => {
      if (product.checked) {
        let newProductVariant;
        if (product.variants?.length > 0) {
          product.variants.forEach((variant) => {
            if (variant.checked) {
              newProductVariant = {
                product_id: product.id,
                variant_id: variant.id,
              };
              formatedSelectedProductvariants.push(newProductVariant);
            }
          });
        } else {
          newProductVariant = { product_id: product.id };
          formatedSelectedProductvariants.push(newProductVariant);
        }
      }
    });
    let newSelectedProductVariants =
      isAllProductVariantsSelected && isEmptyAccepted
        ? []
        : formatedSelectedProductvariants;
    if (formField) {
      setFilteredProducts(formField, newSelectedProductVariants);
    } else {
      setFilteredProducts(newSelectedProductVariants);
    }
    setIsOpen(false);
  };
  const handleReset = () => {
    setIsAllProductVariantsSelected(isAllProductsSelected);
    setSelectedProducts(JSON.parse(JSON.stringify(selectedProductsInitial)));
  };
  const isSaveDisabled = () => {
    let isDisabled = true;
    let isEqual = _.isEqual(
      selectedProductsInitial?.sort(),
      selectedProducts?.sort()
    );
    // check if is there any product or variant selected
    let isAnyProductVariantSelected = false;
    selectedProducts?.forEach((proVar) => {
      if (proVar.checked !== '') {
        isAnyProductVariantSelected = true;
      }
    });
    //
    if (allActiveProductVariants) {
      if (isEmptyAccepted && !isEqual) {
        isDisabled = false;
      } else if (!isEmptyAccepted && isAnyProductVariantSelected && !isEqual) {
        isDisabled = false;
      }
    }
    return isDisabled;
  };
  const checkedProductStatus = (productId) => {
    const product = selectedProducts.find(
      (product) => product.id === productId
    );
    if (product?.checked === 'full') {
      return [true, false];
    } else if (product?.checked === 'semi') {
      return [false, true];
    } else {
      return [false, false];
    }
  };
  const checkedVariantStatus = (productId, variantIndex) => {
    const product = selectedProducts.find(
      (product) => product.id === productId
    );
    return product?.variants[variantIndex]?.checked || false;
  };
  const numberOfSelectedProductVariants = () => {
    let count = 0;
    selectedProducts?.forEach((product) => {
      if (product.variants?.length > 0) {
        product.variants.forEach((variant) => {
          if (variant.checked) count++;
        });
      } else {
        if (product.checked === 'full') count++;
      }
    });
    return count;
  };
  const numberOfSearchedProductVariants = () => {
    let count = 0;
    allSearchedProducts?.forEach((product) => {
      if (product.prices?.length > 0) {
        product.prices.forEach(() => {
          count++;
        });
      } else {
        count++;
      }
    });
    return count;
  };
  const isAllSelected = () => {
    if (searchString) {
      return (
        allSearchedSelectedProducts.length !== 0 &&
        allSearchedSelectedProducts.length === allSearchedProducts.length
      );
    } else {
      return (
        numberOfSearchedProductVariants() === numberOfSelectedProductVariants()
      );
    }
  };
  return (
    <Dialog open={isOpen} fullWidth maxWidth='md'>
      <StyledCard>
        <HeaderWrapper padding='0'>
          <div>
            <SectionTitle>Product Variants</SectionTitle>
            <LocationsCounter>
              {`${numberOfSelectedProductVariants()} product variants selected`}
            </LocationsCounter>
          </div>
          <div style={{ textAlign: 'right' }}>
            <CloseIcon
              className='fa fa-times'
              onClick={() => setIsOpen(false)}
            ></CloseIcon>
          </div>
        </HeaderWrapper>

        <SearchWrapper>
          <TextField
            id='outlined-basic'
            label={searchLabel}
            placeholder={placeholder}
            variant='outlined'
            style={{ width: '100%' }}
            value={searchString}
            onChange={(e) => setSearchString(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <i className='fa fa-search' style={{ color: '#b5b5b5' }}></i>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position='end'>
                  {`${numberOfSearchedProductVariants()} results`}
                </InputAdornment>
              ),
            }}
          />
        </SearchWrapper>
        <ActiveSwitchWrapper>
          <FormControlLabel
            control={
              <Switch
                checked={displayActiveProducts}
                onChange={(e) => setDisplayActiveProducts(e.target.checked)}
                color='primary'
              />
            }
            label='Show active product-variant only'
          />
        </ActiveSwitchWrapper>
        <Row>
          <TH>
            <Checkbox
              checked={isAllSelected()}
              onChange={handleAssignAllProducts}
              color='primary'
              style={{
                transform: 'scale(1.3)',
              }}
            />
          </TH>
          <TH>Select All Products</TH>
        </Row>
        {currentPageProducts.map((product, index) => (
          <RowContainer key={index}>
            <Row
              hoverBGColor='#f1f1fa'
              onClick={(e) => handleAssignProducts(e, product.id)}
              padding='7px 0'
            >
              <TD>
                <Checkbox
                  checked={checkedProductStatus(product.id)[0]}
                  indeterminate={checkedProductStatus(product.id)[1]}
                  onChange={(e) => handleAssignProducts(e, product.id)}
                  color='primary'
                  style={{
                    transform: 'scale(1.3)',
                  }}
                />
              </TD>
              <TD isAssigned={true}>{product.name}</TD>
            </Row>
            {product.prices.length > 0 &&
              product.prices.map((variant, varIndex) => (
                <Row margin='0 0 0 35px'>
                  <TD>
                    <Checkbox
                      checked={checkedVariantStatus(product.id, varIndex)}
                      onChange={(e) =>
                        handleAssignProducts(e, product.id, varIndex)
                      }
                      color='primary'
                      style={{
                        transform: 'scale(1.3)',
                      }}
                    />
                  </TD>
                  <TD>{variant.name}</TD>
                </Row>
              ))}
          </RowContainer>
        ))}
        <PaginationWrapper>
          <PaginationControl
            response={{
              ...currentPageProducts,
              page: requestParams.page_number,
              page_size: requestParams.page_size,
              count: productListLength,
            }}
            requestParams={requestParams}
            // updateAction={getProductsVariants}
            type='products'
          />
        </PaginationWrapper>
        <ButtonWrapper>
          <Left>
            <CancelButton onClick={handleReset}>Cancel</CancelButton>
          </Left>
          <Right>
            <Button onClick={handleAddProducts} disabled={isSaveDisabled()}>
              Save
            </Button>
          </Right>
        </ButtonWrapper>
      </StyledCard>
    </Dialog>
  );
};
function mapStateToProps(state) {
  return {
    allProductVariants: state.productsReducer.allProductVariants,
    requestParams: state.productsReducer.requestParams,
  };
}
export default connect(mapStateToProps, actions)(FilterProductVariantsModal);
