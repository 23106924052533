import {
  GET_TAGS_REQUEST,
  GET_TAGS_SUCCESS,
  GET_TAGS_FAILURE,
  CREATE_TAG_REQUEST,
  CREATE_TAG_SUCCESS,
  CREATE_TAG_FAILURE,
  EDIT_TAG_REQUEST,
  EDIT_TAG_SUCCESS,
  EDIT_TAG_FAILURE,
  SHOW_TAG_FORM,
  HIDE_TAG_FORM,
  SHOW_DELETE_TAG_MODAL,
  HIDE_DELETE_TAG_MODAL,
  DELETE_TAG_REQUEST,
  DELETE_TAG_SUCCESS,
  DELETE_TAG_FAILURE,
  RESET_TAGS_REDUCER_STATE,
} from './tagsTypes';
import api from '../../api';
import { createAlert } from '../Alert/actions';
import { getPurePayload } from '../../utils/purePayload';

export function getTags() {
  return function (dispatch) {
    dispatch({ type: GET_TAGS_REQUEST });
    return api
      .get('/tags')
      .then((response) => {
        dispatch({ type: GET_TAGS_SUCCESS, tags: response.data.data });
      })
      .catch((error) => {
        dispatch({ type: GET_TAGS_FAILURE, error });
      });
  };
}

export function createTag(formValues) {
  return function (dispatch) {
    dispatch({ type: CREATE_TAG_REQUEST });
    return api
      .post('/tags', {
        ...formValues,
        detail: transformDetails(formValues.detail),
      })
      .then((response) => {
        dispatch({ type: CREATE_TAG_SUCCESS });
        dispatch(
          createAlert({
            type: 'success',
            message: 'Tag successfully created',
          })
        );
        dispatch(hideTagForm());
        dispatch(getTags());
      })
      .catch((error) => {
        dispatch({ type: CREATE_TAG_FAILURE });
        dispatch(
          createAlert({
            type: 'error',
            message:
              error?.response?.data?.errors?.message ||
              'An error occurred trying to create tag',
          })
        );
      });
  };
}

export function editTag(formValues, tagId) {
  const tagWithoutType = { ...formValues };
  delete tagWithoutType.type;
  return function (dispatch) {
    const tagPayload = {
      ...tagWithoutType,
      detail: transformDetails(formValues.detail),
    };
    const tagPurePayload = getPurePayload(tagPayload);
    dispatch({ type: EDIT_TAG_REQUEST });
    return api
      .patch(`/tags/${tagId}`, tagPurePayload)
      .then(() => {
        dispatch({ type: EDIT_TAG_SUCCESS });
        dispatch(
          createAlert({ type: 'success', message: 'Tag successfully updated' })
        );
        dispatch(hideTagForm());
        dispatch(getTags());
      })
      .catch((error) => {
        dispatch({ type: EDIT_TAG_FAILURE });
        dispatch(
          createAlert({
            type: 'error',
            message:
              error?.response?.data?.errors?.message ||
              'An error occurred trying to update tag',
          })
        );
      });
  };
}

function transformDetails(details) {
  let detailsObject = {};
  if (details.length) {
    details.forEach((detail) => (detailsObject[detail.key] = detail.value));
  }
  return detailsObject;
}

export function showTagForm(tagToEdit) {
  return {
    type: SHOW_TAG_FORM,
    tagToEdit,
  };
}

export function hideTagForm() {
  return {
    type: HIDE_TAG_FORM,
  };
}

export function showDeleteTagModal(tagIdToDelete) {
  return {
    type: SHOW_DELETE_TAG_MODAL,
    tagIdToDelete,
  };
}

export function hideDeleteTagModal() {
  return {
    type: HIDE_DELETE_TAG_MODAL,
  };
}

export function deleteTag(tagIdToDelete) {
  return function (dispatch) {
    dispatch({ type: DELETE_TAG_REQUEST });
    return api
      .delete(`/tags/${tagIdToDelete}`)
      .then((response) => {
        dispatch({ type: DELETE_TAG_SUCCESS });
        dispatch(
          createAlert({ type: 'success', message: 'Tag successfully deleted' })
        );
        dispatch(hideDeleteTagModal());
        dispatch(getTags());
      })
      .catch((error) => {
        dispatch({ type: DELETE_TAG_FAILURE });
        dispatch(
          createAlert({
            type: 'error',
            message:
              error?.response?.data?.errors?.message ||
              'An error occurred trying to delete tag',
          })
        );
      });
  };
}

export function resetTagsReducerState() {
  return {
    type: RESET_TAGS_REDUCER_STATE,
  };
}
