import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';
import { DialogContent, Dialog } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import _ from 'lodash';
import MissingPOSMappingContainer from './MissingPOSMappingContainer';
import { Table, Tr, TrHead, Th, TBody, Td } from 'merchant-portal-components';
import TextField from '@material-ui/core/TextField';

import { withStyles } from '@material-ui/core/styles';

import RenderMissingTable from './missingTable';
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const Wrapper = styled.div`
  width: 100%;
  @media (max-width: 1313px) {
    width: 100%;
  }
`;
const HeaderWrapper = styled.div`
  padding: 25px;
  display: grid;
  grid-template-columns: 1fr 30px;
  @media (max-width: 768px) {
    width: 80%;
  }
`;
const Divider = styled.hr`
  border: 1px solid rgba(0, 0, 0, 0.14);
  margin-bottom: 0;
`;
const SectionTitle = styled.span`
  width: 100%;
  margin: 0px 0 12px 0;
  font-family: Montserrat;
  font-size: 21px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.03px;
  color: #191818;
  text-align: ${(props) => props.textAlign || 'left'};
`;
const SectionSubtitle = styled.div`
  margin-top: 10px;
  width: 100%;
  font-family: Montserrat;
  font-size: 17px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.03px;
  color: #191818;
`;
const PortalProblemIcon = styled.i`
  color: #ffa500;
  font-size: 25px;
  margin-right: 10px;
  &:hover {
    animation: none;
    cursor: pointer;
  }
`;
const CloseIcon = styled.i`
  font-size: 20px;
  cursor: pointer;
`;
const Notes = styled.div`
  display: grid;
  margin-bottom: 15px;
  font-size: 15px;
  font-weight: normal;
`;

const PortalProblemsModal = ({
  isOpen,
  handleCloseModal,
  missingPOSMappings,
  getMissingCombosPOSMapping,
}) => {
  const {
    missingComboItemPOSMappings,
    missingComboPOSMappings,
    missingItemPOSMappings,
    missingProductPOSMappings,
  } = missingPOSMappings;
  const [value, setValue] = React.useState(0);
  const [availableMissingMapping, setAvailableMissingMapping] = useState([]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    getMissingCombosPOSMapping();
  }, []);
  useEffect(() => {
    let newAvailableMissingMapping = [];
    if (missingProductPOSMappings?.length > 0) {
      const groupedMissingProduct = _.groupBy(
        missingProductPOSMappings,
        'product_id'
      );
      let newMissingProductPOSMapping = [];
      for (var [key, value] of Object.entries(groupedMissingProduct)) {
        let newProduct;
        if (value.length === 1) {
          newProduct = {
            product_id: parseInt(key),
            product_name: value[0].name,
            is_active: value[0].product_is_active,
            variants: [
              {
                variant_id: value[0].variant_id,
                variant_name: value[0].variant_name,
                variant_is_active: value[0].variant_is_active,
              },
            ],
          };
          newMissingProductPOSMapping.push(newProduct);
        } else {
          let newVariants = [];
          value.forEach((variant) => {
            newVariants.push({
              variant_id: variant.variant_id,
              variant_name: variant.variant_name,
              variant_is_active: variant.variant_is_active,
            });
          });
          newProduct = {
            product_id: parseInt(key),
            product_name: value[0].name,
            is_active: value[0].product_is_active,
            variants: newVariants,
          };
          newMissingProductPOSMapping.push(newProduct);
        }
      }
      const mapping = {
        value: 0,
        tabTitle: 'Missing Products Mapping',
        tabSubtilte:
          'How to fix it:\nClick on the "Link to Issue" to be redirected to the pos mapping page.\nPOS mapping catrgory and the product that is missing pos mapping has already been pre-selected for you.\nYou neen to enter missing values of pos mapping fields\nAfter you entered all missing field click save button to save your change.\nTo select other missing POS mapping click on the yellow ringing bell at the top-right corner again',
        mappingData: newMissingProductPOSMapping,
        type: 'product',
      };
      newAvailableMissingMapping.push(mapping);
    }
    if (missingItemPOSMappings?.length > 0) {
      const newGroupedMissingItems = _.groupBy(
        missingItemPOSMappings,
        'option_id'
      );
      let newMissingOptionPOSMapping = [];
      for (var [optionKey, optionValue] of Object.entries(
        newGroupedMissingItems
      )) {
        const groupedSuboptions = _.groupBy(optionValue, 'sub_option_id');
        for (var [subOptionKey, subOptionValue] of Object.entries(
          groupedSuboptions
        )) {
          let newOption = {
            option_id: optionKey,
            option_name: optionValue[0].option_name,
            is_active: optionValue[0].option_is_active,
            suboption_id: '',
            suboption_name: '',
          };
          if (subOptionKey === 'null') {
          } else {
            newOption = {
              option_id: optionKey,
              option_name: optionValue[0].option_name,
              is_active: optionValue[0].option_is_active,
              suboption_id: subOptionKey,
              suboption_name: subOptionValue[0].sub_option_name,
            };
          }
          newMissingOptionPOSMapping.push(newOption);
        }
      }
      const mapping = {
        value: 1,
        tabTitle: 'Missing Items Mapping',
        tabSubtilte: 'POS mappings for below items are missing',
        mappingData: newMissingOptionPOSMapping,
        type: 'item',
      };
      newAvailableMissingMapping.push(mapping);
    }
    if (missingComboPOSMappings?.length > 0) {
      const mapping = {
        value: 2,
        tabTitle: 'Missing Combos Mapping',
        tabSubtilte: 'POS mappings for below combos are missing',
        mappingData: missingComboPOSMappings,
        type: 'combo',
      };
      newAvailableMissingMapping.push(mapping);
    }
    if (missingComboItemPOSMappings?.length > 0) {
      const mapping = {
        value: 3,
        tabTitle: 'Missing Combo-Items Mapping',
        tabSubtilte: 'POS mappings for below combo items are missing',
        mappingData: missingComboItemPOSMappings,
        type: 'combo-item',
      };
      newAvailableMissingMapping.push(mapping);
    }
    setAvailableMissingMapping(newAvailableMissingMapping);
  }, [
    missingComboItemPOSMappings,
    missingComboPOSMappings,
    missingItemPOSMappings,
    missingProductPOSMappings,
  ]);
  return (
    <Dialog
      open={isOpen}
      fullWidth
      maxWidth='md'
      style={{ margin: 'unset', padding: 'unset' }}
      scroll='body'
    >
      <Wrapper>
        <HeaderWrapper padding='0'>
          <div>
            <div>
              <PortalProblemIcon className='fas fa-cogs'></PortalProblemIcon>
              <SectionTitle>Configuration Issues</SectionTitle>
            </div>
            <SectionSubtitle>
              We have uncovered areas in your configuration that need
              correcting. See below for more.
            </SectionSubtitle>
          </div>
          <div style={{ textAlign: 'right' }}>
            <CloseIcon
              className='fa fa-times'
              onClick={handleCloseModal}
            ></CloseIcon>
          </div>
        </HeaderWrapper>
        <Divider />
        <Box sx={{ width: '100%' }}>
          <AppBar position='static' style={{ backgroundColor: '#243167' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label='basic tabs example'
              // inkBarStyle={{ background: '#fff' }}
              TabIndicatorProps={{
                style: {
                  backgroundColor: '#ffffff',
                },
              }}
            >
              <Tab
                style={{ textTransform: 'capitalize', fontWeight: 600 }}
                label={'Missing POS Mappings'}
                {...a11yProps(0)}
              />
              {/* <Tab
                style={{ textTransform: 'capitalize', fontWeight: 600 }}
                label={'Missing Option Prices'}
                {...a11yProps(1)}
              /> */}
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <MissingPOSMappingContainer
              isOpen={isOpen}
              handleCloseModal={handleCloseModal}
              missingPOSMappings={missingPOSMappings}
              getMissingCombosPOSMapping={getMissingCombosPOSMapping}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <div style={{ height: '400px' }}>Coming Soon</div>
          </TabPanel>
        </Box>
      </Wrapper>
    </Dialog>
  );
};

function mapStateToProps(state) {
  return {
    mediaReducer: state.mediaReducer,
  };
}

export default connect(mapStateToProps, null)(PortalProblemsModal);
