import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../productsActions';
import styled from 'styled-components';
import SelectionRow from './SelectionRow';
import { Subhead } from './Typography';
import ItemRow from './ItemRow';
import OptionDrawerString from './OptionDrawerString';
import Collapse from '@material-ui/core/Collapse';

const Wrapper = styled.div`
  @media (min-width: 768px) {
    display: ${props => (props.hasSubOptions ? 'none' : 'inherit')};
    grid-column: span 2;
    align-self: flex-start;
  }
`;

const OptionLabel = styled(Subhead)`
  color: ${props => props.theme.darkGray};
  text-transform: 'uppercase';
`;

const ItemDropdown = styled.div`
  display: grid;
  grid-gap: 12px;
  margin-left: 20px;
  @media (min-width: 768px) {
    margin-left: 100px;
  }
`;

const ItemsCol = styled.div`
  display: grid;
  grid-gap: 12px;
  align-self: flex-start;
`;

const OptionDrawer = ({
  flatProduct,
  option,
  validateOption,
  incrementItem,
  decrementItem,
  id
}) => {
  const [isExpanded, setIsExpanded] = useState(null);
  useEffect(() => {
    if (isExpanded === false) {
      validateOption(option, flatProduct);
    }
  }, [isExpanded]);

  const toggleDrawer = () => setIsExpanded(!isExpanded);

  const selectItem = itemToSelect => {
    option.items.forEach(itemId => {
      const item = flatProduct.itemsById[itemId];
      if (item.quantity) {
        decrementItem(item);
      }
      if (!itemToSelect.quantity && itemToSelect.item_id === itemId) {
        incrementItem(item);
      }
    });
    setIsExpanded(false);
  };

  return (
    <Wrapper id={id}>
      <OptionLabel>{option.name}</OptionLabel>
      <SelectionRow
        onClick={toggleDrawer}
        opened={isExpanded}
        error={option.error}
      >
        <OptionDrawerString flatProduct={flatProduct} option={option} />
        <i className={`fal fa-chevron-${isExpanded ? 'up' : 'down'}`} />
      </SelectionRow>
      <Collapse in={isExpanded}>
        <ItemDropdown>
          {option.items.map(itemId => {
            const item = flatProduct.itemsById[itemId];
            return (
              <ItemRow
                key={item.item_id}
                selectItem={selectItem}
                flatProduct={flatProduct}
                option={option}
                item={item}
              />
            );
          })}
        </ItemDropdown>
      </Collapse>
    </Wrapper>
  );
};

export default connect(
  null,
  actions
)(OptionDrawer);
