import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Formik, Field, FieldArray } from 'formik';
import {
  getCoupons,
  submitPOSDiscountValuesCoupons,
  getAllCoupons,
} from '../posAction';
import { LoadingBlueLarge } from 'merchant-portal-components';
import fetchOnParamChange from '../../../HOCs/fetchOnParamChange';
import CouponsMappingTable from './components/CouponsMappingTable';

const Wrapper = styled.div`
  width: 1000px;
  background: white;
  padding: 24px;
  border-radius: 10px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
  margin-top: 24px;

  @media (max-width: 1313px) {
    width: 100%;
  }
`;

let CouponsMappingContainer = ({
  meta,
  updateRequestParams,
  requestParams,
  coupons,
  isRequestingGetCoupons,
  getCoupons,
  type,
  submitPOSDiscountValuesCoupons,
  selectedValuePOS,
  setSelectedValuePOS,
  allCoupons,
  isRequestingGetAllCoupons,
  getAllCoupons,
  posDiscountsMappingFields,
  posDiscountsFieldsNum,
}) => {
  useEffect(() => {
    getCoupons(initialRequestParams);
    getAllCoupons();
  }, []);

  const initialValues = {
    coupons: coupons.map((coupon, index) => ({
      ...coupon,
      pos_mapping: coupon.pos_mapping
        ? Object.keys(coupon.pos_mapping).reduce((acc, key) => {
            acc[key] = coupon.pos_mapping[key] || '';
            return acc;
          }, {})
        : '',
      index,
    })),
  };

  let couponsModified = coupons.map((coupon, index) => ({
    ...coupon,
    index,
  }));

  return (
    <Formik initialValues={initialValues} enableReinitialize={true}>
      {({ values, handleReset, dirty, errors, setFieldValue, handleSave }) => (
        <Fragment>
          <Wrapper>
            {!isRequestingGetCoupons && !isRequestingGetAllCoupons ? (
              <CouponsMappingTable
                meta={meta}
                coupons={couponsModified}
                requestParams={requestParams}
                updateRequestParams={updateRequestParams}
                initialRequestParams={initialRequestParams}
                dirty={dirty}
                handleReset={handleReset}
                values={values}
                initialValues={initialValues}
                type={type}
                getCoupons={getCoupons}
                submitPOSDiscountValuesCoupons={submitPOSDiscountValuesCoupons}
                selectedValuePOS={selectedValuePOS}
                setSelectedValuePOS={setSelectedValuePOS}
                allCoupons={allCoupons}
                posDiscountsMappingFields={posDiscountsMappingFields}
                posDiscountsFieldsNum={posDiscountsFieldsNum}
              />
            ) : (
              <LoadingBlueLarge />
            )}
          </Wrapper>
        </Fragment>
      )}
    </Formik>
  );
};

const initialRequestParams = {
  page_number: 1,
  page_size: 10,
  searchString: '',
};

CouponsMappingContainer = fetchOnParamChange(
  CouponsMappingContainer,
  getCoupons,
  initialRequestParams
);

const mapStateToProps = (state) => ({
  meta: state.posReducer.meta,
  isRequestingGetCoupons: state.posReducer.isRequestingGetCoupons,
  isRequestingGetAllCoupons: state.posReducer.isRequestingGetAllCoupons,
  coupons: state.posReducer.coupons,
  allCoupons: state.posReducer.allCoupons,
  selectedValuePOS: state.posReducer.selectedValuePOS,
});

export default connect(mapStateToProps, {
  getCoupons,
  getAllCoupons,
  submitPOSDiscountValuesCoupons,
})(CouponsMappingContainer);
