import {
  INITIATE_CREATE_LAYOUT,
  CREATE_APP_FEED_LAYOUT_REQUEST,
  CREATE_APP_FEED_LAYOUT_SUCCESS,
  GET_LIST_APP_FEED_LAYOUT_SUCCESS,
  DELETE_APP_FEED_LAYOUT,
  APP_FEED_LAYOUT_SEARCH_RESULT,
  SET_DEFAULT_APP_FEED,
  UPDATE_DEFAULT_LAYOUT,
  DUPLICATE_APP_FEED_LAYOUT,
  SET_PAGE_TYPE,
  EDIT_FEED_LAYOUT,
  UPDATE_APP_FEED_LAYOUT_SUCCESS,
  RESET_APP_FEED_LAYOUT_REDUCER
} from './appFeedLayoutTypes';

const initialState = {
  appFeedLayoutPage: 'list',
  formInitialData: null,
  id: null,
  feedLayoutId: null,
  createIsRequesting: false,
  listAppFeedLayoutIsRequesting: true,
  listAppFeed: [],
  search: {
    text: '',
    list: [],
    filterAttribute: '',
    filterOrder: ''
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case INITIATE_CREATE_LAYOUT:
      return {
        ...state,
        appFeedLayoutPage: 'create'
      };
    case RESET_APP_FEED_LAYOUT_REDUCER:
      return initialState;

    case EDIT_FEED_LAYOUT:
      return {
        ...state,
        appFeedLayoutPage: 'edit',
        formInitialData: action.formInitialData,
        id: action.id
      };
    case CREATE_APP_FEED_LAYOUT_REQUEST:
      return {
        ...state,
        createIsRequesting: true
      };
    case CREATE_APP_FEED_LAYOUT_SUCCESS:
      return initialState;

    case GET_LIST_APP_FEED_LAYOUT_SUCCESS:
      return {
        ...state,
        listAppFeedLayoutIsRequesting: false,
        listAppFeed: action.list
      };
    case UPDATE_APP_FEED_LAYOUT_SUCCESS:
      return initialState;
    case DELETE_APP_FEED_LAYOUT:
      return {
        ...state,
        listAppFeed: state.listAppFeed.filter(
          appFeed => appFeed.id != action.appFeed
        )
      };
    case APP_FEED_LAYOUT_SEARCH_RESULT:
      return {
        ...state,
        search: {
          ...state.search,
          ...action.payload
        }
      };
    case SET_DEFAULT_APP_FEED:
      return {
        ...state,
        ...action.payload
      };
    case UPDATE_DEFAULT_LAYOUT:
      return {
        ...state,
        listAppFeed: state.listAppFeed.map(list => ({
          ...list,
          is_default: list.id === action.id
        }))
      };
    case DUPLICATE_APP_FEED_LAYOUT:
      return {
        ...state,
        listAppFeed: [
          ...state.listAppFeed,
          { ...action.payload, businesses: [] }
        ]
      };
    case SET_PAGE_TYPE:
      return {
        ...state,
        appFeedLayoutPage: 'list'
      };
    default:
      return state;
  }
}
