import React, { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import BusinessSearchManagerContainer from '../../../BusinessSearchManager/BusinessSearchManagerContainer';
import { TooltipIcon } from '../../../../components/Icon';
import { LoadingBlueLarge } from 'merchant-portal-components';
import PaginationControl from '../../../../components/Pagination/PaginationControl';
import { paginate } from './utils/paginate';
import { Button } from 'merchant-portal-components';
import { Checkbox, Switch, Tooltip, Popover } from '@material-ui/core';
import {
  StyledCard,
  HeaderWrapper,
  SearchWrapper,
  SectionTitle,
  SectionSubTitle,
  LocationsCounter,
  SortWrapper,
  SortDiv,
  RowWrapper,
  TH,
  TD,
  PaginationWrapper,
  ButtonWrapper,
  Left,
  Right,
  CancelButton,
} from './Styles/ServiceFeeDetailStyles';

const initiallRequestParams = {
  page_number: 1,
  page_size: '10',
  sorting_option: 'title-asc',
};
const ServiceFeeLocationList = (props) => {
  const {
    selectedBusinessLocationsOfMerchant,
    requestingGetBusinessLocationsOfMerchant,
    assignBusinessLocations,
    editingFee,
    requestParams,
    updateRequestParams,
  } = props;
  const initialActiveBusinesses = editingFee.assigned_businesses.active;
  const initialInactiveBusinesses = editingFee.assigned_businesses.inactive;
  const [activeBusinesses, setActiveBusinesses] = useState(
    initialActiveBusinesses
  );
  const [inactiveBusinesses, setInactiveBusinesses] = useState(
    initialInactiveBusinesses
  );
  const [businessList, setBusinessList] = useState([]);
  const [businessListLength, setBusinessListLength] = useState([]);
  const [showActives, setShowActive] = useState(false);
  const [showInactives, setShowInactive] = useState(false);
  const [showAssigned, setShowAssigned] = useState(false);
  const [showUnassigned, setShowUnassigned] = useState(false);
  const filterBusinessList = useCallback(() => {
    let filteredList = selectedBusinessLocationsOfMerchant;
    if (showActives) {
      filteredList = filteredList
        .filter((item) => activeBusinesses.includes(item.id))
        .slice();
      setShowInactive(false);
    }
    if (showInactives) {
      filteredList = filteredList
        .filter((item) => inactiveBusinesses.includes(item.id))
        .slice();
      setShowActive(false);
    }
    if (showAssigned) {
      filteredList = filteredList
        .filter(
          (item) =>
            activeBusinesses.includes(item.id) ||
            inactiveBusinesses.includes(item.id)
        )
        .slice();
      setShowUnassigned(false);
    }
    if (showUnassigned) {
      filteredList = filteredList
        .filter(
          (item) =>
            !(
              activeBusinesses.includes(item.id) ||
              inactiveBusinesses.includes(item.id)
            )
        )
        .slice();
      setShowAssigned(false);
    }
    const paginatedList = paginate(
      filteredList,
      requestParams.page_number,
      requestParams.page_size
    );
    setBusinessList(paginatedList);
    setBusinessListLength(filteredList.length);
  }, [
    requestParams.page_number,
    requestParams.page_size,
    activeBusinesses,
    inactiveBusinesses,
    showActives,
    showInactives,
    showAssigned,
    showUnassigned,
    JSON.stringify(selectedBusinessLocationsOfMerchant),
  ]);
  useEffect(() => {
    updateRequestParams(initiallRequestParams);
  }, []);
  useEffect(() => {
    filterBusinessList();
  }, [filterBusinessList]);
  const handleAssignBusinesses = (e, id) => {
    let isActive = e.target.checked;
    let newActiveBusinesses = [...activeBusinesses];
    let newInactiveBusinesses = [...inactiveBusinesses];
    if (isActive) {
      // assign
      if (newInactiveBusinesses.includes(id)) {
        const index = newInactiveBusinesses.indexOf(id);
        newInactiveBusinesses.splice(index, 1);
        newActiveBusinesses.push(id);
      } else {
        newActiveBusinesses.push(id);
      }
    } else {
      //unassign
      if (newInactiveBusinesses.includes(id)) {
        const index = newInactiveBusinesses.indexOf(id);
        newInactiveBusinesses.splice(index, 1);
      }
      if (newActiveBusinesses.includes(id)) {
        const index = newActiveBusinesses.indexOf(id);
        newActiveBusinesses.splice(index, 1);
      }
    }
    setActiveBusinesses(newActiveBusinesses);
    setInactiveBusinesses(newInactiveBusinesses);
  };
  const handleAssignAllBusinesses = (e) => {
    let isActive = e.target.checked;
    let newActiveBusinesses = [];
    let newInactiveBusinesses = [];
    if (isActive) {
      selectedBusinessLocationsOfMerchant.map((business) => {
        newActiveBusinesses.push(business.id);
      });
    }
    setActiveBusinesses(newActiveBusinesses);
    setInactiveBusinesses(newInactiveBusinesses);
  };
  const handleActiveBusinesses = (e, id) => {
    let isActive = e.target.checked;
    let newActiveBusinesses = [...activeBusinesses];
    let newInactiveBusinesses = [...inactiveBusinesses];
    if (isActive) {
      // active
      const index = newInactiveBusinesses.indexOf(id);
      newInactiveBusinesses.splice(index, 1); //remove from inactive
      newActiveBusinesses.push(id); //add to active
    } else {
      const index = newActiveBusinesses.indexOf(id);
      newActiveBusinesses.splice(index, 1); //remove from active
      newInactiveBusinesses.push(id); // add to inactive
    }
    setActiveBusinesses(newActiveBusinesses.sort());
    setInactiveBusinesses(newInactiveBusinesses.sort());
  };
  const handleAddBusinesses = () => {
    let assignedBusinesses = {
      active: activeBusinesses,
      inactive: inactiveBusinesses,
    };
    assignBusinessLocations(editingFee.id, assignedBusinesses);
  };
  const handleReset = () => {
    setActiveBusinesses(initialActiveBusinesses.sort());
    setInactiveBusinesses(initialInactiveBusinesses.sort());
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openFilter = Boolean(anchorEl);
  return (
    <StyledCard>
      <HeaderWrapper padding="0">
        <SectionTitle>Locations</SectionTitle>
        <SectionSubTitle>
          Select which locations will use this Service Fee.
        </SectionSubTitle>
        <LocationsCounter>
          {activeBusinesses.length + inactiveBusinesses.length} locations
          selected
        </LocationsCounter>
      </HeaderWrapper>
      <SearchWrapper>
        <BusinessSearchManagerContainer isClearable={true} />
        {/* onClick={() => setIsSortModalOpen(true)} */}
        <SortWrapper>
          <SortDiv onClick={(e) => setAnchorEl(e.currentTarget)}>
            <i className="far fa-sort-amount-down" /> Filter
          </SortDiv>
          <Popover
            id="basic-menu"
            anchorEl={anchorEl}
            open={openFilter}
            onClose={() => setAnchorEl(null)}
            // MenuListProps={{
            //   'aria-labelledby': 'basic-button',
            // }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <div style={{ fontSize: '12px', padding: '3px 3px 3px 0px' }}>
              <Checkbox
                checked={showActives}
                onChange={(e) => {
                  e.stopPropagation();
                  setShowActive((prevState) => !prevState);
                }}
                color="primary"
                style={{
                  transform: 'scale(0.9)',
                }}
              />
              Enabled Locations
            </div>
            <div style={{ fontSize: '12px', padding: '3px 3px 3px 0px' }}>
              <Checkbox
                checked={showInactives}
                onChange={(e) => {
                  e.preventDefault();
                  setShowInactive((prevState) => !prevState);
                }}
                color="primary"
                style={{
                  transform: 'scale(0.9)',
                }}
              />
              Disabled Locations
            </div>
            <div style={{ fontSize: '12px', padding: '3px 3px 3px 0px' }}>
              <Checkbox
                checked={showAssigned}
                onChange={(e) => {
                  e.stopPropagation();
                  setShowAssigned((prevState) => !prevState);
                }}
                color="primary"
                style={{
                  transform: 'scale(0.9)',
                }}
              />
              Assigned Locations
            </div>
            <div style={{ fontSize: '12px', padding: '3px 6px 3px 0px' }}>
              <Checkbox
                checked={showUnassigned}
                onChange={(e) => {
                  e.stopPropagation();
                  setShowUnassigned((prevState) => !prevState);
                }}
                color="primary"
                style={{
                  transform: 'scale(0.9)',
                }}
              />
              Unassigned Locations
            </div>
          </Popover>
        </SortWrapper>
      </SearchWrapper>
      <RowWrapper>
        <TH>
          <Checkbox
            checked={
              selectedBusinessLocationsOfMerchant.length ===
              activeBusinesses.length + inactiveBusinesses.length
            }
            onChange={handleAssignAllBusinesses}
            color="primary"
            style={{
              transform: 'scale(1.3)',
            }}
          />
        </TH>
        <TH>Select All Locations</TH>
        <TH textAlign="right">
          Status
          <Tooltip
            style={{ marginRight: 'auto' }}
            title="Active or inactive selected location for this service fee"
          >
            <span>
              <TooltipIcon marginLeft />
            </span>
          </Tooltip>
        </TH>
      </RowWrapper>
      {businessList.map((businessLocation, index) => (
        <RowWrapper key={index} hoverBGColor="#f1f1fa">
          <TD bgColor="yellow">
            <Checkbox
              checked={
                activeBusinesses.includes(businessLocation.id) ||
                inactiveBusinesses.includes(businessLocation.id)
              }
              onChange={(e) => handleAssignBusinesses(e, businessLocation.id)}
              color="primary"
              style={{
                transform: 'scale(1.3)',
              }}
            />
          </TD>
          <TD
            isAssigned={
              activeBusinesses.includes(businessLocation.id) ||
              inactiveBusinesses.includes(businessLocation.id)
            }
          >
            {businessLocation.display_name}
          </TD>
          <TD textAlign="right">
            <Switch
              color="primary"
              disabled={
                !activeBusinesses.includes(businessLocation.id) &&
                !inactiveBusinesses.includes(businessLocation.id)
              }
              checked={activeBusinesses.includes(businessLocation.id)}
              onChange={(e) => handleActiveBusinesses(e, businessLocation.id)}
            />
          </TD>
        </RowWrapper>
      ))}
      <PaginationWrapper>
        <PaginationControl
          response={{
            ...businessList,
            page: requestParams.page_number,
            page_size: requestParams.page_size,
            count: businessListLength,
          }}
          requestParams={requestParams}
          type="fees"
        />
      </PaginationWrapper>
      <ButtonWrapper>
        <Left>
          <CancelButton onClick={handleReset}>Cancel</CancelButton>
        </Left>
        <Right>
          <Button
            onClick={handleAddBusinesses}
            disabled={
              _.isEqual(initialActiveBusinesses, activeBusinesses) &&
              _.isEqual(initialInactiveBusinesses, inactiveBusinesses)
            }
          >
            Save
          </Button>
        </Right>
      </ButtonWrapper>
      {requestingGetBusinessLocationsOfMerchant ? <LoadingBlueLarge /> : null}
    </StyledCard>
  );
};

export default ServiceFeeLocationList;
