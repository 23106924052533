import React, { useEffect, Suspense, lazy, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { Dialog } from '@material-ui/core';
import FilterModal from '../../../components/FilterModal/FilterModal';
import { formatLocations } from '../../../utils/childLocations/formatLocations';
import * as actions from '../ReportTableActions.js';
import ReportSearch from '../components/ReportSearch';
import { getBusinessLocationsOfMerchant } from '../../BusinessLocationDetails/LocationListManager/actions.js';
import LocationFilterModal from '../../../components/FilterModal/LocationFilterModal';
const GenericTable = lazy(() =>
  import('../../../components/Table/GenericTable')
);

function OfferReportContainer({
  allOffers,
  allBusinesses,
  requestParams,
  getOfferReport,
  setExportingData,
  setExportingHeaders,
  setExportingFileName,
  clearOfferReport,
  updateReportsRequestParams,
  getBusinessLocationsOfMerchant,
  setMessage,
  resetAllReports,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  selectedBusinessesIDs,
  setSelectedBusinessesIDs,
  filteredLocations,
  setFilteredLocations,
}) {
  const [isLocationFilterOpen, setIsLocationFilterOpen] = useState(false);
  const columnHeaders = [
    'Title',
    'Label',
    'Status',
    'Number of Customer Redemption',
    'Amount Redeemed',
    'Points Earned',
    'Total Redemption',
  ];
  const columnHeadersInfoIconMessages = [
    '',
    '',
    '',
    'Customer Redemptions: The number of unique customers that have redeemed the offer.',
    '',
    '',
    'Total Redemptions: The total number of times the offer was redeemed.',
  ];
  const columnNames = [
    'title',
    'label',
    'status',
    'no_of_customer_redemption',
    'amount_redeemed',
    'points_earned',
    'total_redemptions',
  ];
  const columnTypes = [
    'string',
    'string',
    'string',
    'string',
    'money',
    'string',
    'string',
  ];
  const columnWidth = [
    '270px',
    '150px',
    '100px',
    '220px',
    '150px',
    '150px',
    '200px',
  ];
  const columnHeaderIsSortableList = [true, true, true, true, true];
  const updateSelectedBusiness = useCallback(() => {
    if (filteredLocations) {
      setSelectedBusinessesIDs(filteredLocations);
    }
  }, [filteredLocations]);
  useEffect(() => {
    updateSelectedBusiness();
  }, [updateSelectedBusiness]);
  const getExportHeaders = (headers, fields) => {
    const exportedHeaders = [];
    headers.forEach((header, index) =>
      exportedHeaders.push({ label: header, key: fields[index] })
    );
    return exportedHeaders;
  };
  useEffect(() => {
    getBusinessLocationsOfMerchant();
    setExportingFileName('offer_report');
    setExportingHeaders(getExportHeaders(columnHeaders, columnNames));
    setMessage('Select a date range and location to generate a report');
    return () => {
      clearOfferReport();
      setExportingData([]);
      updateReportsRequestParams({
        page_number: 1,
        page_size: 25,
      });
      setFilteredLocations([]);
      setSelectedBusinessesIDs([]);
      setMessage('Select a date range and location to generate a report');
      resetAllReports();
    };
  }, []);
  useEffect(() => {
    if (allOffers?.length === 0) {
      setExportingData([]);
      setMessage('Select a date range to generate a report');
    }
  }, [allOffers]);
  return (
    <>
      <ReportSearch
        allBusinesses={allBusinesses}
        generateReport={getOfferReport}
        haslocationFiltering
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        selectedBusinessesIDs={selectedBusinessesIDs}
        setSelectedBusinessesIDs={setSelectedBusinessesIDs}
        setIsLocationFilterOpen={setIsLocationFilterOpen}
        setMessage={setMessage}
      />
      {allOffers?.length > 0 && (
        <div style={{ marginTop: '30px' }}>
          <Suspense fallback={<div>Loading...</div>}>
            <GenericTable
              dataList={allOffers}
              columnHeaders={columnHeaders}
              columnHeaderIsSortableList={columnHeaderIsSortableList}
              columnNames={columnNames}
              columnWidth={columnWidth}
              columnTypes={columnTypes}
              placeholder=''
              requestParams={requestParams}
              requestParamsType={'offerReport'}
              handleClickRow={null}
              handleCheckboxSelect={null}
              checkboxList={[]}
              setCheckboxList={null}
              setExportingData={() => setExportingData(allOffers)}
              columnHeadersInfoIconMessages={columnHeadersInfoIconMessages}
            />
          </Suspense>
        </div>
      )}
      {isLocationFilterOpen && (
        <LocationFilterModal
          placeholder='Enter Internal Location Name'
          searchLabel='Search by Internal Location Name'
          filteredLocations={filteredLocations}
          setFilteredLocations={setFilteredLocations}
          isOpen={isLocationFilterOpen}
          setIsOpen={setIsLocationFilterOpen}
          emptyIsEqualToAll={true}
          isEmptyAccepted={false}
        />
      )}
    </>
  );
}
function mapStateToProps(state) {
  return {
    allOffers: state.ReportTableReducer.allOffers,
    requestParams: state.ReportTableReducer.requestParams,
    allBusinesses: state.businessLocationsList.businessLocationsOfMerchant,
  };
}
export default connect(mapStateToProps, {
  ...actions,
  getBusinessLocationsOfMerchant,
})(OfferReportContainer);
