import {
  GET_OFFERS_ENGINE_REQUEST,
  GET_OFFERS_ENGINE_SUCCESS,
  GET_OFFERS_ENGINE_FAILURE,
  TOGGLE_OFFER_ENGINE_AVAILABILITY_SUCCESS,
  INITIATE_CREATE_OFFER_ENGINE,
  INITIATE_CREATE_EDIT_OFFER_ENGINE,
  CREATE_OFFER_ENGINE_REQUEST,
  CREATE_OFFER_ENGINE_SUCCESS,
  CREATE_OFFER_ENGINE_FAILURE,
  INITIATE_EDIT_OFFER_ENGINE,
  EDIT_OFFER_ENGINE_REQUEST,
  EDIT_OFFER_ENGINE_SUCCESS,
  EDIT_OFFER_ENGINE_FAILURE,
  RESET_OFFER_ENGINE_STATE,
  UPDATE_SORTED_OFFERS_ENGINE_LIST,
  GET_PROMO_REQUEST,
  GET_PROMO_SUCCESS,
  GET_PROMO_FAILURE,
  SORT_OFFER_LIST,
  UPDATE_PROMO_REQUEST_PARAMS,
  GET_OFFERS_POSITIONS,
} from './OfferEngineTypes';

const initialState = {
  allOffersEngine: [],
  requestingGetOffers: true,
  isCreatingOffer: false,
  isEditingEngineOffer: false,
  isCopyingOfferEngine: false,
  requestingCreateOffer: false,
  requestingEditOffer: false,
  offerAvailability: [],
  customerList: [],
  segmentList: [],
  businessList: [],
  offerEngineSource: [],
  offerEngineProducts: [],
  copyingOfferEngine: null,
  editingOfferEngine: null,
  editingOfferEngineId: null,
  meta: { page_number: 1, page_size: 25 },
  options: null,
  requestParams: {
    page_number: 1,
    page_size: '25',
    sorting_option: 'title-asc',
    search_string: '',
    has_next_page: true,
  },
  requestParamsPromo: {
    page_number: 1,
    page_size: '10',
    sorting_option: 'title-asc',
    search_string: '',
    has_next_page: true,
  },
  currentAssignedCustomers: [],
  // offer re-positioning
  offerReorderRequestParams: {
    page_number: 1,
    page_size: '10',
    sorting_option: 'title-asc',
    search_string: '',
  },
  isSavingOfferPositions: false,
  isSavingOfferItemsPositions: false,
  offerOverrides: null,
  isSubmitting: false,
  allOffersPositions: [],
  loyaltyTiers: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'GET_OFFER_OPTIONS_SUCCESS':
      return {
        ...state,
        options: action.options.sources.map((item, index) => ({
          label: item,
          value: item,
        })),
      };
    case 'GET_LOYALTY_TIERS_SUCCESS':
      return { ...state, loyaltyTiers: action.loyaltyTiers };
    case GET_OFFERS_ENGINE_REQUEST:
      return { ...state, requestingGetOffers: true };

    case GET_OFFERS_ENGINE_SUCCESS:
      return {
        ...state,
        requestingGetOffers: false,
        allOffersEngine: action.allOffersEngine,
        requestParams: {
          ...state.requestParams,
          has_next_page: action.has_next_page,
        },
      };
    case GET_OFFERS_ENGINE_FAILURE:
      return {
        ...state,
        requestingGetOffers: false,
        error: action.error,
        allOffersEngine: initialState.allOffersEngine,
      };
    case GET_OFFERS_POSITIONS:
      return {
        ...state,
        allOffersPositions: action.allOffersPositions,
      };
    case 'UPDATE_PROMO_REQUEST_PARAMS':
      return {
        ...state,
        requestParamsPromo: {
          ...state.requestParamsPromo,
          ...action.payload,
        },
      };
    case TOGGLE_OFFER_ENGINE_AVAILABILITY_SUCCESS:
      return {
        ...state,
        allOffersEngine: state.allOffersEngine.map((offer) =>
          offer.offer_id === action.offer.offer_id
            ? {
                ...offer,
                is_active: !offer.is_active,
              }
            : offer
        ),
      };
    case INITIATE_CREATE_OFFER_ENGINE:
      return {
        ...state,
        customerList: action.customerList,
        segmentList: action.segmentList,
        businessList: action.businessList,
        offerEngineSource: action.offerEngineSource,
        offerEngineProducts: action.offerEngineProducts,
        isCreatingOffer: true,
        isEditingEngineOffer: false,
        requestingCreateOffer: false,
        editingOfferEngine: null,
        editingOfferEngineId: null,
        isSortingRequested: false,
      };
    case INITIATE_CREATE_EDIT_OFFER_ENGINE:
      return {
        ...state,
        customerList: action.customerList,
        segmentList: action.segmentList,
        businessList: action.businessList,
        offerEngineSource: action.offerEngineSource,
      };
    case CREATE_OFFER_ENGINE_REQUEST:
      return {
        ...state,
        requestingCreateOffer: true,
      };
    case CREATE_OFFER_ENGINE_SUCCESS:
      return {
        ...state,
        requestingCreateOffer: false,
        isCreatingOffer: false,
        isEditingEngineOffer: false,
        editingOfferEngine: null,
        editingOfferEngineId: null,
      };
    case CREATE_OFFER_ENGINE_FAILURE:
      return {
        ...state,
        requestingCreateOffer: false,
      };
    case INITIATE_EDIT_OFFER_ENGINE:
      return {
        ...state,
        isEditingEngineOffer: true,
        editingOfferEngine: action.editingOfferEngine,
        offerAvailability: action.offerAvailability,
        editingOfferEngineId: action.editingOfferEngineId,
        customerList: action.customerList,
        segmentList: action.segmentList,
        businessList: action.businessList,
        offerEngineSource: action.offerEngineSource,
        offerEngineProducts: action.offerEngineProducts,
        offerPromoCode: action.offerPromoCode,
        customerData: action.customerData,
        currentAssignedCustomers: action.currentAssignedCustomers,
        isCopyingOfferEngine: action.isCopyingOfferEngine,
      };
    case EDIT_OFFER_ENGINE_REQUEST:
      return {
        ...state,
        requestingEditOffer: true,
      };
    case EDIT_OFFER_ENGINE_SUCCESS:
      return {
        ...state,
        requestingCreateOffer: false,
        requestingEditOffer: false,
        isCreatingOffer: false,
        isEditingEngineOffer: false,
        editingOfferEngine: null,
        editingOfferEngineId: null,
      };
    case EDIT_OFFER_ENGINE_FAILURE:
      return {
        ...state,
        requestingEditOffer: false,
      };
    case 'UPDATE_OFFERS_REQUEST_PARAMS':
      return {
        ...state,
        requestParams: {
          ...state.requestParams,
          ...action.payload,
        },
      };
    case 'UPDATE_OFFER_REORDERING_REQUEST_PARAMS':
      return {
        ...state,
        offerReorderRequestParams: {
          ...state.offerReorderRequestParams,
          ...action.payload,
        },
      };
    case RESET_OFFER_ENGINE_STATE:
      return initialState;
    case UPDATE_SORTED_OFFERS_ENGINE_LIST:
      return {
        ...state,
        allOffersEngine: action.offersList,
        requestParams: {
          ...state.requestParams,
          sorting_option: action.sortOrder,
        },
      };
    case GET_PROMO_REQUEST:
      return {
        ...state,
      };
    case GET_PROMO_SUCCESS:
      return {
        ...state,
        offerPromoCode: action.offerPromoCode,
        requestParams: {
          ...state.requestParams,
          has_next_page: action.hasNextPage,
        },
        requestParamsPromo: {
          ...state.requestParamsPromo,
          ...action.requestParamsPromo,
        },
      };

    case GET_PROMO_FAILURE:
      return {
        ...state,
      };
    default:
      return state;
  }
}
