import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { LoadingBlueLarge } from 'merchant-portal-components';
import PaginationControl from '../Pagination/PaginationControl';
import { paginate } from './paginate';
import { Button } from 'merchant-portal-components';
import { Checkbox, TextField, InputAdornment, Radio } from '@material-ui/core';
import { formatLocations } from '../../utils/childLocations/formatLocations';
import * as actions from '../../modules/BusinessLocationDetails/LocationListManager/actions';
import { updateBusinessInformation } from '../../modules/BusinessLocationDetails/LocationDetailsManager/actions';
import { Dialog } from '@material-ui/core';
import {
  StyledCard,
  HeaderWrapper,
  SearchWrapper,
  SectionTitle,
  LocationsCounter,
  RowWrapper,
  TH,
  TD,
  PaginationWrapper,
  ButtonWrapper,
  Left,
  Right,
  CancelButton,
  CloseIcon,
} from './filterModalStyles';

const initiallRequestParams = {
  page_number: 1,
  page_size: '10',
  sorting_option: 'title-asc',
};
const LocationFilterModalSingleSelect = ({
  locationList,
  requestParams,
  isOpen,
  setIsOpen,
  placeholder,
  searchLabel,
  requestingGetBusinessLocationsOfMerchant,
  updateLocationRequestParams,
  approveButtonName,
  handleSave,
  initialBusiness,
}) => {
  const [originalLocationList, setOriginalLocationList] = useState([]);
  const [businessList, setBusinessList] = useState([]);
  const [businessListLength, setBusinessListLength] = useState([]);
  const [searchString, setSearchString] = useState('');
  const [selectedBusiness, setSelectedBusiness] = useState(initialBusiness);
  // update original location list
  const updateOriginalLocationList = useCallback(() => {
    setOriginalLocationList(formatLocations(locationList));
  }, [locationList]);
  useEffect(() => {
    updateOriginalLocationList();
  }, [updateOriginalLocationList]);
  // filtering Selected List
  const filterBusinessList = useCallback(() => {
    let filteredList = originalLocationList;
    if (searchString) {
      filteredList = filteredList?.filter((item) =>
        item.business_name?.toLowerCase().includes(searchString?.toLowerCase())
      );
    }
    let paginatedList = paginate(
      filteredList,
      requestParams.page_number,
      requestParams.page_size
    );
    setBusinessList(paginatedList);
    setBusinessListLength(filteredList.length);
  }, [
    requestParams.page_number,
    requestParams.page_size,
    JSON.stringify(originalLocationList),
  ]);
  useEffect(() => {
    filterBusinessList();
  }, [filterBusinessList]);
  // search location
  useEffect(() => {
    const filteredList = originalLocationList.filter((item) =>
      item.business_name?.toLowerCase().includes(searchString?.toLowerCase())
    );
    const paginatedList = paginate(filteredList, 1, requestParams.page_size);
    updateLocationRequestParams({ ...requestParams, page_number: 1 });
    setBusinessList(paginatedList);
    setBusinessListLength(filteredList.length);
  }, [searchString]);
  ///
  useEffect(() => {
    updateLocationRequestParams(initiallRequestParams);
    return () => {
      updateLocationRequestParams(initiallRequestParams);
    };
  }, []);
  const handleAssignBusinesses = (id) => {
    const filteredLocation = locationList.find(
      (business) => business.id === id
    );
    setSelectedBusiness(filteredLocation);
  };
  const handleAddBusinesses = () => {
    if (Object.keys(selectedBusiness).length > 0) {
      handleSave(selectedBusiness);
      setIsOpen(false);
    }
  };
  const handleReset = () => {
    setSelectedBusiness(null);
  };
  return (
    <Dialog open={isOpen} fullWidth maxWidth='md'>
      <StyledCard>
        <HeaderWrapper padding='0'>
          <div>
            <SectionTitle>Locations</SectionTitle>

            <LocationsCounter>
              {`Selected Location : ${
                selectedBusiness?.display_name || 'No Selection'
              }`}
            </LocationsCounter>
          </div>
          <div style={{ textAlign: 'right' }}>
            <CloseIcon
              className='fa fa-times'
              onClick={() => setIsOpen(false)}
            ></CloseIcon>
          </div>
        </HeaderWrapper>

        <SearchWrapper>
          <TextField
            id='outlined-basic'
            label={searchLabel}
            placeholder={placeholder}
            variant='outlined'
            style={{ width: '100%' }}
            value={searchString}
            onChange={(e) => setSearchString(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <i className='fa fa-search' style={{ color: '#b5b5b5' }}></i>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position='end'>
                  {`${businessListLength} results`}
                </InputAdornment>
              ),
            }}
          />
        </SearchWrapper>
        <RowWrapper padding='0 0 7px 0'>
          <TH></TH>
          <TH>Locations</TH>
        </RowWrapper>
        {businessList.map((businessLocation, index) => (
          <RowWrapper
            key={index}
            hoverBGColor='#f1f1fa'
            onClick={() => handleAssignBusinesses(businessLocation.id)}
          >
            <TD bgColor='yellow'>
              <Radio
                checked={selectedBusiness?.id === businessLocation.id}
                onChange={() => handleAssignBusinesses(businessLocation.id)}
                color='primary'
                value={businessLocation.id}
                style={{
                  transform: 'scale(1.3)',
                }}
              />
            </TD>
            <TD isAssigned={selectedBusiness?.id === businessLocation.id}>
              {businessLocation.business_name}
            </TD>
          </RowWrapper>
        ))}
        <PaginationWrapper>
          <PaginationControl
            response={{
              ...businessList,
              page: requestParams.page_number,
              page_size: requestParams.page_size,
              count: businessListLength,
            }}
            requestParams={requestParams}
            type='locationFiltering'
          />
        </PaginationWrapper>
        <ButtonWrapper>
          <Left>
            <CancelButton onClick={handleReset}>Cancel</CancelButton>
          </Left>
          <Right>
            <Button
              onClick={handleAddBusinesses}
              disabled={
                !selectedBusiness || !Object.keys(selectedBusiness).length
              }
            >
              {approveButtonName}
            </Button>
          </Right>
        </ButtonWrapper>
        {requestingGetBusinessLocationsOfMerchant ? <LoadingBlueLarge /> : null}
      </StyledCard>
    </Dialog>
  );
};
function mapStateToProps(state) {
  return {
    requestingGetBusinessLocationsOfMerchant:
      state.businessLocationsList.requestingGetBusinessLocationsOfMerchant,
    locationList: state.businessLocationsList.businessLocationsOfMerchant,
    requestParams: state.businessLocationsList.requestParams,
  };
}
export default connect(mapStateToProps, {
  ...actions,
  updateBusinessInformation,
})(LocationFilterModalSingleSelect);
