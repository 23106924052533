export const GET_DELIVERY_ZONE_OBJECTS_REQUEST =
  'GET_DELIVERY_ZONE_OBJECTS_REQUEST';
export const GET_DELIVERY_ZONE_OBJECTS_SUCCESS =
  'GET_DELIVERY_ZONE_OBJECTS_SUCCESS';
export const DELETE_DELIVERY_ZONE_OBJECT_BY_ID_REQUEST =
  'DELETE_DELIVERY_ZONE_OBJECT_BY_ID_REQUEST';
export const DELETE_DELIVERY_ZONE_OBJECT_BY_ID_SUCCESS =
  'DELETE_DELIVERY_ZONE_OBJECT_BY_ID_SUCCESS';
export const DELETE_DELIVERY_ZONE_OBJECT_BY_ID_FAILURE =
  'DELETE_DELIVERY_ZONE_OBJECT_BY_ID_FAILURE';
export const UPDATE_DELIVERY_ZONE_OBJECT = 'UPDATE_DELIVERY_ZONE_OBJECT';
export const UPDATE_EDITABLE_DELIVERY_ZONE = 'UPDATE_EDITABLE_DELIVERY_ZONE';
export const POST_DELIVERY_ZONE_OBJECT_BY_LOCATION_ID_REQUEST =
  'POST_DELIVERY_ZONE_OBJECT_BY_LOCATION_ID_REQUEST';
export const POST_DELIVERY_ZONE_OBJECT_BY_LOCATION_ID_SUCCESS =
  'POST_DELIVERY_ZONE_OBJECT_BY_LOCATION_ID_SUCCESS';
export const POST_DELIVERY_ZONE_OBJECT_BY_LOCATION_ID_FAILURE =
  'POST_DELIVERY_ZONE_OBJECT_BY_LOCATION_ID_FAILURE';
export const PATCH_DELIVERY_ZONE_OBJECT_BY_LOCATION_ID_REQUEST =
  'PATCH_DELIVERY_ZONE_OBJECT_BY_LOCATION_ID_REQUEST';
export const PATCH_DELIVERY_ZONE_OBJECT_BY_LOCATION_ID_SUCCESS =
  'PATCH_DELIVERY_ZONE_OBJECT_BY_LOCATION_ID_SUCCESS';
export const PATCH_DELIVERY_ZONE_OBJECT_BY_LOCATION_ID_FAILURE =
  'PATCH_DELIVERY_ZONE_OBJECT_BY_LOCATION_ID_FAILURE';
export const MAKE_DELIVERY_ZONE_NOT_EDITABLE_BY_ID =
  'MAKE_DELIVERY_ZONE_NOT_EDITABLE_BY_ID';
export const RESET_DELIVERYZONE_REDUCER_STATE = 'RESET_DELIVERYZONE_REDUCER_STATE';
