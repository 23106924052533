const transformToFormValues = ({
  initialFormValues,
  actionArray,
  actionValue,
  allProducts,
  allOfferDetails
}) => ({
  ...initialFormValues,
  messageActions: actionValue
    ? 'webpage'
    : actionArray
    ? actionArray[0]
    : 'open_app',
  messageSubActions: actionArray
    ? actionArray[0] == 'products'
      ? allProducts.find(product => product.id == actionArray[1]).name
      : actionArray[0] == 'offers'
      ? allOfferDetails.find(offer => offer.offer_id == actionArray[1]).label
      : null
    : actionValue
    ? actionValue
    : null
});

export default transformToFormValues;
