import React from 'react';
import { SingleDatePicker } from 'react-dates';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  DateTimePicker,
} from '@material-ui/pickers';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { ExpireWrapper, SingleDatePickerWrapper } from '../styles/style.js';
import { SmallToggleSwitch } from '../../../../../components/ToggleSwitch/ToggleSwitch';
import { ICON_AFTER_POSITION } from 'react-dates/esm/constants';
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 250,
  },
}));
const DatesRow = ({
  values,
  setFieldValue,
  startDateFocusedState,
  setstartDateFocusedState,
  expiryFocusedState,
  setexpiryFocusedState,
  handleChangeExpiry,
  expire,
}) => {
  const classes = useStyles();
  return (
    <div>
      <div>
        <SingleDatePickerWrapper>
          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            <KeyboardDateTimePicker
              label='Start Datetime'
              placeholder='Start Datetime'
              inputVariant='outlined'
              name='productInformation.start_datetime'
              value={
                values.productInformation.start_datetime
                  ? moment(values.productInformation.start_datetime).format(
                      'YYYY-MM-DD HH:mm'
                    )
                  : moment(new Date())
                      .set({
                        hour: 0,
                        minute: 0,
                        second: 0,
                      })
                      .format('YYYY-MM-DD HH:mm')
              }
              onChange={(date) => {
                setFieldValue(
                  'productInformation.start_datetime',
                  moment(date)
                );
              }}
              // disablePast
              format='yyyy/MM/DD HH:mm'
            />
          </MuiPickersUtilsProvider>
        </SingleDatePickerWrapper>
      </div>
      <ExpireWrapper>
        <div>
          <SmallToggleSwitch
            checked={!expire}
            onChange={({ target: { checked } }) =>
              handleChangeExpiry(checked, setFieldValue)
            }
            value='checkedB'
            color='primary'
            inputProps={{ 'aria-label': 'primary checkbox' }}
            label='Never Expires'
          />
        </div>
        {expire && (
          <SingleDatePickerWrapper>
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
              <KeyboardDateTimePicker
                label='End Datetime'
                placeholder='Start Datetime'
                inputVariant='outlined'
                name='productInformation.end_datetime'
                value={
                  values.productInformation.end_datetime
                    ? moment(values.productInformation.end_datetime).format(
                        'YYYY-MM-DD HH:mm'
                      )
                    : moment(new Date()).format('YYYY-MM-DD HH:mm')
                }
                onChange={(date) => {
                  setFieldValue(
                    'productInformation.end_datetime',
                    moment(date)
                  );
                }}
                format='yyyy/MM/DD HH:mm'
              />
            </MuiPickersUtilsProvider>
          </SingleDatePickerWrapper>
        )}
      </ExpireWrapper>
    </div>
  );
};

export default DatesRow;
