import './WizardProgressBar.scss';
import React from 'react';

const WizardProgressBar = ({pageNumber, pageNames}) => {
  const renderSteps = () => {
    return pageNames.map((pageName, index) => {
      let stepClass = 'wizardProgressBar__step';
      let circleClass = 'wizardProgressBar__circle';
      let nameClass = 'wizardProgressBar__name';
      let selectedClass = 'wizardProgressBar__circle--selected';

      if (index === pageNames.length - 1) {
        stepClass = `${stepClass} ${stepClass}--last`;
        nameClass = `${nameClass} ${nameClass}--last`;
      }

      if (index + 1 === pageNumber) {
        return (
          <div className={stepClass} key={index}>
            <div className={`wizardProgressBar__circle--selected`}>
              <span className={`wizardProgressBar__text--selected`}>
                {index + 1}
              </span>
            </div>
            <div className="wizardProgressBar__line" />
            <p className={nameClass}>{pageName}</p>
          </div>
        );
      } else {
        return (
          <div className={stepClass} key={index}>
            <div className={`wizardProgressBar__circle`}>
              <span className={`wizardProgressBar__text`}>{index + 1}</span>
            </div>
            <div className="wizardProgressBar__line" />
            <p className={nameClass}>{pageName}</p>
          </div>
        );
      }

      return (
        <div className={stepClass} key={index}>
          <div className={circleClass} />
          <div className="wizardProgressBar__line" />
        </div>
      );
    });
  };

  return <div className="wizardProgressBar">{renderSteps()}</div>;
};

export default WizardProgressBar;
