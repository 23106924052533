export function makeCircleObject(circle) {
  const type = 'circle';
  const radius = circle.getRadius();
  const center = {
    lat: circle.getCenter().lat(),
    lng: circle.getCenter().lng(),
  };
  const overlay = {
    type,
    position: {
      radius,
      center,
    },
    mapObj: circle,
  };
  return overlay;
}

export function makeRectangleObject(rectangle) {
  const type = 'rectangle';

  const northEast = {
    lat: rectangle.getBounds().getNorthEast().lat(),
    lng: rectangle.getBounds().getNorthEast().lng(),
  };

  const southWest = {
    lat: rectangle.getBounds().getSouthWest().lat(),
    lng: rectangle.getBounds().getSouthWest().lng(),
  };

  const position = {
    northEast: northEast,
    northWest: {
      lat: southWest.lat,
      lng: northEast.lng,
    },
    southWest: southWest,
    southEast: {
      lat: northEast.lat,
      lng: southWest.lng,
    },
  };
  const overlay = {
    type,
    position,
    mapObj: rectangle,
  };
  return overlay;
}

export function makePolygonObject(polygon) {
  const type = 'polygon';
  let MVCArray = polygon.getPath().getArray();

  let vertices = [];

  for (let i = 0; i < MVCArray.length; i++) {
    vertices.push({ lat: MVCArray[i].lat(), lng: MVCArray[i].lng() });
  }

  const overlay = {
    type,
    vertices,
    mapObj: polygon,
  };

  return overlay;
}

export function addOverlaysToGoogleMaps(overlays, map) {
  if (!overlays) {
    return [];
  }
  let storeOverlays = [];
  overlays.forEach((overlay) => {
    if (overlay.type == 'circle') {
      storeOverlays.push({
        ...overlay,
        mapObj: addCircleToGoogleMaps(
          overlay.position,
          map,
          overlay.colour,
          overlay.isVisible,
          overlay.isEditable
        ),
      });
    } else if (overlay.type == 'rectangle') {
      storeOverlays.push({
        ...overlay,
        mapObj: addRectangleToGoogleMaps(
          overlay.position,
          map,
          overlay.colour,
          overlay.isVisible,
          overlay.isEditable
        ),
      });
    } else if (overlay.type == 'polygon') {
      storeOverlays.push({
        ...overlay,
        mapObj: addPolygonToGoogleMaps(
          overlay.vertices,
          map,
          overlay.colour,
          overlay.isVisible,
          overlay.isEditable
        ),
      });
    }
  });

  return storeOverlays;
}

function addCircleToGoogleMaps(position, map, colour, isVisible, isEditable) {
  return new google.maps.Circle({
    fillColor: colour,
    strokeWeight: 0,
    fillOpacity: 0.3,
    visible: isVisible,
    editable: isEditable,
    draggable: isEditable,
    zIndex: 1,
    radius: position.radius,
    center: position.center,
    map: map,
  });
}

function addRectangleToGoogleMaps(
  position,
  map,
  colour,
  isVisible,
  isEditable
) {
  return new google.maps.Rectangle({
    fillColor: colour,
    strokeWeight: 0,
    fillOpacity: 0.3,
    visible: isVisible,
    editable: isEditable,
    draggable: isEditable,
    zIndex: 1,
    bounds: {
      north: position.northEast.lat,
      east: position.northEast.lng,
      south: position.southWest.lat,
      west: position.southWest.lng,
    },
    map: map,
  });
}

function addPolygonToGoogleMaps(vertices, map, colour, isVisible, isEditable) {
  return new google.maps.Polygon({
    fillColor: colour,
    strokeWeight: 0,
    fillOpacity: 0.3,
    visible: isVisible,
    editable: isEditable,
    draggable: isEditable,
    zIndex: 1,
    paths: vertices,
    map: map,
  });
}

export function removeOverlaysFromGoogleMaps(overlayObjects) {
  overlayObjects.forEach((overlayObject) => {
    if (overlayObject.mapObj) {
      overlayObject.mapObj.setMap(null);
    }
  });
}
