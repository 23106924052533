import React, { useState } from 'react';
import styled from 'styled-components';
import { Field } from 'formik';
import {
  RenderTextField,
  required,
} from '../../../../../components/FormElements/FormikElements';
import MediaModalContainer from '../../../../MediaModal/MediaModalContainer';
import ChooseImage from '../../../../MediaModal/components/ChooseImage';

const ImageWrapper = styled.div``;

const PButton = styled.p`
  color: #2440ae;
  font-weight: 600;

  margin: 20px auto;
  cursor: pointer;
`;

const OfferImageForm = ({ imageUrl, setFieldValue }) => {
  const [isImageGallaryVisible, setIsImageGallaryVisible] = useState(false);
  return (
    <ImageWrapper>
      <ChooseImage
        imageUrl={imageUrl}
        setIsImageGallaryVisible={setIsImageGallaryVisible}
      />
      {isImageGallaryVisible && (
        <MediaModalContainer
          closeModal={() => setIsImageGallaryVisible(false)}
          selectProduct={(id, url) => {
            setFieldValue('offer_details.image_url', url);
          }}
        />
      )}
    </ImageWrapper>
  );
};

export default OfferImageForm;
