import React from 'react';
import { Field } from 'formik';
import { RenderSelect } from '../../../../../../components/FormElements/FormikElements';
import {
  LocationsAvailableWrapper,
  BrowseLocationsButton,
  AvailableLocationsDiv,
} from '../styles/style.js';
import BrowseLocationsModal from './BrowseLocationsModal';
import { SmallToggleSwitch } from '../../../../../../components/ToggleSwitch/ToggleSwitch';

const ComboLocationsRow = ({
  values,
  setFieldValue,
  availableAllLocations,
  setAvailableAllLocations,
  handleIsAvailableAllLocations,
  getExistingBusinesses,
  toggleLocationsModal,
  locationModalState,
  businesses,
  initValues,
}) => {
  return (
    <div>
      <LocationsAvailableWrapper>
        <SmallToggleSwitch
          checked={availableAllLocations}
          onChange={({ target: { checked } }) =>
            handleIsAvailableAllLocations(checked, setFieldValue)
          }
          value="checkedB"
          color="primary"
          inputProps={{ 'aria-label': 'primary checkbox' }}
          label="Available at All Locations"
        />

        {!availableAllLocations && (
          <AvailableLocationsDiv>
            <Field
              options={getExistingBusinesses(businesses, values.comboLocations)}
              name="comboLocations"
              component={RenderSelect}
              placeholder="Combo Locations"
              isMulti
              // getOptionValue={comboBusinesses => comboBusinesses.id}
              // getOptionLabel={comboBusinesses => comboBusinesses.name}
              // optionsSelectionDisabled
            />
            <BrowseLocationsButton onClick={() => toggleLocationsModal()}>
              Browse Locations
            </BrowseLocationsButton>
          </AvailableLocationsDiv>
        )}

        {locationModalState && (
          <BrowseLocationsModal
            toggleLocationsModal={toggleLocationsModal}
            businesses={businesses}
            initValues={initValues}
            comboBusinesses={values.comboLocations}
            setFieldValue={setFieldValue}
            setAvailableAllLocations={setAvailableAllLocations}
          />
        )}
      </LocationsAvailableWrapper>
    </div>
  );
};

export default ComboLocationsRow;
