import React, { useState, useEffect, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {
  loadServiceFeeListPage,
  createFee,
  editFee,
  assignBusinessLocations,
  updateRequestParams,
  createFeeOverrides,
  getAllOverrides,
  getSpecificOverride,
  EmptyOverride,
  deleteSpecificOverride,
} from '../../feesActions';
import BreadCrumb from '../../../../components/BreadCrumb/BreadCrumb';
import ServiceFeeDetail from './ServiceFeeDetail';
import ServiceFeeLocationList from './ServiceFeeLocationList';
import ServiceFeeLocationOverrides from './ServiceFeeLocationOverrides';
import {
  getBusinessLocationsOfMerchant,
  filterBusinessLocationsOfMerchantWithQuery,
} from '../../../BusinessLocationDetails/LocationListManager/actions';
import { formatLocations } from '../../../../utils/childLocations/formatLocations';
import {
  Wrapper,
  CardWrapper,
  BackText,
  StyledCard,
  HeaderWrapper,
  SectionTitle,
  SectionSubTitle,
} from './Styles/ServiceFeeDetailStyles';

const ServiceFeeDetailContainer = ({
  loadServiceFeeListPage,
  createFee,
  editFee,
  feesReducer,
  orderTypesList,
  // Assigning locations
  assignBusinessLocations,
  businessLocationsList,
  businessSearchManager,
  getBusinessLocationsOfMerchant,
  filterBusinessLocationsOfMerchantWithQuery,
  requestParams,
  updateRequestParams,
  // creating and modifying overrides
  EmptyOverride,
  createFeeOverrides,
  getAllOverrides,
  getSpecificOverride,
  deleteSpecificOverride,
}) => {
  const { isEditing, editingFee, allFeeOverrides, selectedFeeOverride } =
    feesReducer;
  const {
    requestingGetBusinessLocationsOfMerchant,
    selectedBusinessLocationsOfMerchant,
  } = businessLocationsList;
  const { selectedBusiness } = businessSearchManager;
  useEffect(() => {
    if (editingFee?.id) {
      getAllOverrides(editingFee?.id);
    }
  }, []);
  const allFeeOverridesCallback = useCallback(() => {
    if (editingFee?.id) {
      getAllOverrides(editingFee?.id);
    }
  }, [allFeeOverrides.length]);
  useEffect(() => {
    allFeeOverridesCallback();
  }, [allFeeOverridesCallback]);
  useEffect(() => {
    if (!selectedBusiness) {
      getBusinessLocationsOfMerchant();
    } else {
      filterBusinessLocationsOfMerchantWithQuery(selectedBusiness);
    }
  }, [selectedBusiness]);
  return (
    <Wrapper>
      <BackText onClick={loadServiceFeeListPage}>
        <i className="fa fa-chevron-left" />
        <div>Back To Service Fees</div>
      </BackText>
      <BreadCrumb
        style={{ margin: ' 0px' }}
        pageName={
          isEditing ? `Edit ${editingFee.display_name}` : 'Create New Fee'
        }
      />
      <CardWrapper>
        <ServiceFeeDetail
          loadServiceFeeListPage={loadServiceFeeListPage}
          feeReducer={feesReducer}
          orderTypesList={orderTypesList}
          createFee={createFee}
          editFee={editFee}
        />
      </CardWrapper>
      <CardWrapper>
        {!editingFee ? (
          <StyledCard backgroundColor="#EBEBEB">
            <HeaderWrapper padding="0">
              <SectionTitle>Locations</SectionTitle>
              <SectionSubTitle>
                Select which locations will use this Service Fee.
              </SectionSubTitle>
            </HeaderWrapper>
          </StyledCard>
        ) : (
          <ServiceFeeLocationList
            requestingGetBusinessLocationsOfMerchant={
              requestingGetBusinessLocationsOfMerchant
            }
            selectedBusinessLocationsOfMerchant={formatLocations(
              selectedBusinessLocationsOfMerchant
            )}
            isEditing={isEditing}
            editingFee={editingFee}
            assignBusinessLocations={assignBusinessLocations}
            requestParams={requestParams}
            updateRequestParams={updateRequestParams}
          />
        )}
      </CardWrapper>
      <CardWrapper>
        {!editingFee ? (
          <StyledCard backgroundColor="#EBEBEB">
            <HeaderWrapper padding="0" margin="0 0 15px 0">
              <div style={{ marginBottom: '12px' }}>
                <SectionTitle display="inline">Location Overrides</SectionTitle>
                <SectionSubTitle display="inline" margin="0 0 0 5px">
                  (Optional)
                </SectionSubTitle>
              </div>
              <SectionSubTitle>
                Override this Service Fee for specific locations
              </SectionSubTitle>
            </HeaderWrapper>
          </StyledCard>
        ) : (
          <ServiceFeeLocationOverrides
            editingFee={editingFee}
            selectedBusinessLocationsOfMerchant={formatLocations(
              selectedBusinessLocationsOfMerchant
            )}
            orderTypesList={orderTypesList}
            createFeeOverrides={createFeeOverrides}
            allFeeOverrides={allFeeOverrides}
            selectedFeeOverride={selectedFeeOverride}
            getAllOverrides={getAllOverrides}
            getSpecificOverride={getSpecificOverride}
            EmptyOverride={EmptyOverride}
            deleteSpecificOverride={deleteSpecificOverride}
          />
        )}
      </CardWrapper>
    </Wrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    feesReducer: state.feesReducer,
    orderTypesList: state.fullReducer.orderTypesList,
    businessLocationsList: state.businessLocationsList,
    businessSearchManager: state.businessSearchManager,
    requestParams: state.feesReducer.requestParams,
  };
};

export default connect(mapStateToProps, {
  loadServiceFeeListPage,
  createFee,
  editFee,
  assignBusinessLocations,
  getBusinessLocationsOfMerchant,
  filterBusinessLocationsOfMerchantWithQuery,
  updateRequestParams,
  createFeeOverrides,
  getAllOverrides,
  getSpecificOverride,
  EmptyOverride,
  deleteSpecificOverride,
})(ServiceFeeDetailContainer);
