import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import BusinessSearchManagerContainer from '../../../BusinessSearchManager/BusinessSearchManagerContainer';
import {
  Table,
  Tr,
  TrHead,
  Th,
  TBody,
  Td,
  TdFlex,
} from 'merchant-portal-components';
import { StyledP, ComponentCard } from 'merchant-portal-components';
import { LoadingBlueLarge } from 'merchant-portal-components';
import { Button } from 'merchant-portal-components';
import _ from 'lodash';
import { Checkbox } from '@material-ui/core';

const ContainerDiv = styled.div`
  padding: ${(props) => props.padding || '0 0 0 0'};
  margin: ${(props) => props.margin || '0 0 0 0'};
  width: ${(props) => props.width || 'inherit'};

  @media (max-width: 768px) {
    width: 80%;
  }
`;

const SearchAndButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 4fr 1fr;
  grid-gap: 20px;
  justify-content: space-between;
  margin: 0px 30px 30px 30px;
`;
const StyledTd = styled(Td)`
  padding: 10px 30px 10px 20px;
  text-align: ${(props) => props.align || 'left'};
  /* width: ${(props) => props.width || '100%'}; */
`;

const BusinessLocationsList = (props) => {
  const {
    businessLocationsOfMerchant,
    handleBusinessLocationAction,
    requestingGetBusinessLocationsOfMerchant,
    isBusinessAdminRole,
  } = props;
  const [sortedBusinesses, setSortedBusinesses] = useState();
  const [sorted, setSorted] = useState(['', '']);
  const [sortIndex, setSortIndex] = useState(-1);
  const [sortColumn, setSortColumn] = useState('');
  const { requestParams, updateRequestParams } = props;
  const updateBusinessList = useCallback(() => {
    let soretedBusinesses;
    if (sorted[0] || sorted[1]) {
      soretedBusinesses = [...businessLocationsOfMerchant];
      soretedBusinesses = _.orderBy(
        soretedBusinesses,
        [sortColumn],
        sorted[sortIndex]
      );
    } else {
      soretedBusinesses = [...businessLocationsOfMerchant];
    }
    setSortedBusinesses(soretedBusinesses);
  }, [businessLocationsOfMerchant, sorted]);
  useEffect(() => {
    updateBusinessList();
  }, [updateBusinessList]);
  const handleSorting = (sortColumn) => {
    setSortColumn(sortColumn);
    const newSortIndex = sortColumn === 'is_active' ? 0 : 1;
    setSortIndex(newSortIndex);
    let newSort = ['', ''];
    if (!sorted[0] && !sorted[1]) {
      newSort[0] = newSortIndex === 0 ? 'asc' : '';
      newSort[1] = newSortIndex === 1 ? 'asc' : '';
    } else if (newSortIndex === 0) {
      newSort[0] =
        sorted[newSortIndex] === ''
          ? 'asc'
          : sorted[newSortIndex] === 'asc'
          ? 'desc'
          : 'asc';
      newSort[1] = '';
    } else if (newSortIndex === 1) {
      newSort[0] = '';
      newSort[1] =
        sorted[newSortIndex] === ''
          ? 'asc'
          : sorted[newSortIndex] === 'asc'
          ? 'desc'
          : 'asc';
    }
    setSorted(newSort);
  };
  return (
    <ComponentCard
      style={{
        borderRadius: '12px',
        boxShadow: '0 2px 4px 0 rgba(0,0,0,0.09)',
      }}
    >
      <ContainerDiv padding='32px 32px 0 32px'>
        <StyledP semiBold>Find, Manage, and Edit Locations</StyledP>
        <StyledP>
          Search by Location Name or browse the list to find a Location. Click
          the Edit icon to go to that Location’s Detail Page, where you can edit
          Location info, set Regular and Special Hours, and manage Delivery
          Zones.
        </StyledP>
      </ContainerDiv>
      <ContainerDiv padding='24px 32px 5px  32px'>
        <StyledP semiBold>Search by Internal Location Name</StyledP>
      </ContainerDiv>
      <SearchAndButtonWrapper>
        <BusinessSearchManagerContainer isClearable={true} />
        <Button
          style={{ height: '60px' }}
          onClick={() => props.initiateCreateLocation()}
          disabled={isBusinessAdminRole}
        >
          Add Location
        </Button>
      </SearchAndButtonWrapper>
      <ContainerDiv>
        <Table noBorder>
          <TBody striped>
            {renderHeaders(handleSorting, sorted)}
            {requestingGetBusinessLocationsOfMerchant
              ? null
              : renderRows(sortedBusinesses, handleBusinessLocationAction)}
          </TBody>
        </Table>
        {requestingGetBusinessLocationsOfMerchant ? <LoadingBlueLarge /> : null}
      </ContainerDiv>
    </ComponentCard>
  );
};

const renderHeaders = (handleSorting, sorted) => {
  return (
    <TrHead
      style={{
        position: 'sticky',
        position: '-webkit-sticky',
        top: 0,
        backgroundColor: 'white',
        zIndex: 10,
      }}
    >
      <Th align='left'>Internal Location Name</Th>
      <Th align='left'>Location Address</Th>
      <Th align='left'>
        <div style={{ display: 'flex', gap: '5px' }}>
          <div>Status</div>
          <div style={{ cursor: 'pointer' }}>
            {!sorted[0] && (
              <i
                className='fa fa-sort'
                onClick={() => handleSorting('is_active')}
              ></i>
            )}
            {sorted[0] === 'asc' && (
              <i
                className='fa fa-sort-up'
                onClick={() => handleSorting('is_active')}
              ></i>
            )}
            {sorted[0] === 'desc' && (
              <i
                className='fa fa-sort-down'
                onClick={() => handleSorting('is_active')}
              ></i>
            )}
          </div>
        </div>
      </Th>
      <Th align='left' width='270px'>
        <div style={{ display: 'flex', gap: '5px' }}>
          <div>Order Ahead Enabled</div>
          <div style={{ cursor: 'pointer' }}>
            {!sorted[1] && (
              <i
                className='fa fa-sort'
                onClick={() => handleSorting('is_order_ahead_enabled')}
              ></i>
            )}
            {sorted[1] === 'asc' && (
              <i
                className='fa fa-sort-up'
                onClick={() => handleSorting('is_order_ahead_enabled')}
              ></i>
            )}
            {sorted[1] === 'desc' && (
              <i
                className='fa fa-sort-down'
                onClick={() => handleSorting('is_order_ahead_enabled')}
              ></i>
            )}
          </div>
        </div>
      </Th>
    </TrHead>
  );
};

const renderRows = (sortedBusinesses, handleBusinessLocationAction) => {
  return sortedBusinesses?.map((businessLocation, index) => {
    return (
      <Tr
        onClick={() => handleBusinessLocationAction(businessLocation.id)}
        style={{ cursor: 'pointer' }}
        key={index}
        //key={businessLocation.id}
      >
        <StyledTd align='left'>
          <StyledP>{businessLocation.business_name}</StyledP>
        </StyledTd>
        <StyledTd align='left'>
          <StyledP>{businessLocation.address}</StyledP>
        </StyledTd>
        <StyledTd align='center'>
          <StyledP>
            {businessLocation.is_active ? 'ACTIVE' : 'INACTIVE'}
          </StyledP>
        </StyledTd>
        <StyledTd align='center' width='200px'>
          <Checkbox
            checked={businessLocation.is_order_ahead_enabled}
            disabled
          />
          {/* <StyledP>{`${businessLocation.is_order_ahead_enabled}`}</StyledP> */}
          {/* <StyledP>{`${
            businessLocation.is_order_ahead_enabled ? 'True' : 'False'
          }`}</StyledP> */}
        </StyledTd>
      </Tr>
    );
  });
};

export default BusinessLocationsList;
