import React from 'react';
import styled from 'styled-components';
import renderSelectedItemsString from '../utils/renderSelectedItemsString';
import renderOptionDrawerPlaceholderText from '../utils/renderOptionDrawerPlaceholderText';
import {Body} from './Typography';

const Selections = styled(Body)`
  color: ${props => props.theme.optionSelectionsFontColor};
`;

const Placeholder = styled(Body)`
  color: ${props => props.theme.gray};
`;

const OptionDrawerString = ({flatProduct, option}) => {
  const selectedItemsString = renderSelectedItemsString(flatProduct, option);

  if (selectedItemsString) {
    return <Selections>{selectedItemsString}</Selections>;
  } else {
    return (
      <Placeholder>{renderOptionDrawerPlaceholderText(option)}</Placeholder>
    );
  }
};

export default OptionDrawerString;
