export default option => {
  if (!option.minimum_pick) {
    if (option.included_pick) {
      return `${option.included_pick} Included (optional)`;
    }
    if (!option.included_pick) {
      return '(Optional)';
    }
  }
  if (option.minimum_pick) {
    if (
      option.maximum_pick > option.included_pick &&
      option.included_pick === option.minimum_pick
    ) {
      return `*Select at least ${option.minimum_pick}`;
    }
    if (!option.included_pick && option.maximum_pick > option.minimum_pick) {
      return `*Select ${option.minimum_pick} or more`;
    }
    if (
      !option.included_pick ||
      (option.minimum_pick === option.maximum_pick &&
        option.minimum_pick === option.included_pick)
    ) {
      return `*Select ${option.minimum_pick}`;
    }
    if (option.included_pick > option.minimum_pick) {
      return `*${option.included_pick} Included`;
    }
  }
};
