import React, { useState, useEffect } from 'react';
import OffersListContainer from './OffersList/OffersListContainer';
import OfferDetailsContainer from './OfferDetails/OfferDetailsContainer';
import { connect } from 'react-redux';
import { getProducts } from '../../MenuManagement/Products/productsActions';

const OffersContainer = ({ isCreatingOffer, isEditingOffer, getProducts }) => {
  useEffect(() => {
    // getProducts({
    //   page_number: 1,
    //   page_size: '4000',
    //   sorting_option: 'title-asc',
    //   search_string: '',
    //   has_next_page: false,
    // });
  }, []);
  const [searchString, setSearchString] = useState('');
  if (isCreatingOffer || isEditingOffer) {
    return <OfferDetailsContainer />;
  } else {
    return (
      <OffersListContainer
        searchString={searchString}
        setSearchString={setSearchString}
      />
    );
  }
};

const mapStateToProps = (state) => ({
  isCreatingOffer: state.offersReducer.isCreatingOffer,
  isEditingOffer: state.offersReducer.isEditingOffer,
});

export default connect(mapStateToProps, { getProducts })(OffersContainer);
