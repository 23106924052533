import React from 'react';
import * as actions from './actions';
import { Formik, Field } from 'formik';
import {
  RenderTextField,
  required,
} from '../../components/FormElements/FormikElements';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Icon } from 'merchant-portal-components';
import Paper from '@material-ui/core/Paper';
import { Button, ButtonRow } from 'merchant-portal-components';
import styled from 'styled-components';
import Alert from '../Alert/Alert';
import { Link } from 'react-router-dom';

import {
  PageWrapper,
  StyledForm,
  SectionWrapper,
  Section,
  ImageWrapper,
} from './styles';

import { connect } from 'react-redux';

const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 18px;
  margin-bottom: 5%;
  font-weight: 510;
`;

const SubMessageWrapper = styled.p`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-size: 15px;
  margin-top: 5%;
  margin-bottom: 3%;
  font-weight: 300;
`;

const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 3;
  margin-top: 5%;
`;

const CancelButton = styled.a`
  justify-content: space-around;
  margin-top: 10px;
  color: black;
  font-weight: 500;
`;

function ResetPasswordRequest(props) {
  return (
    <Formik
      initialValues={{
        username: '',
      }}
      onSubmit={(value) => props.requestUserPasswordReset(value)}
      enableReinitialize={true}
    >
      {({ handleSubmit }) => (
        <PageWrapper>
          <Alert />
          <SectionWrapper>
            <Paper component={Section}>
              <ImageWrapper>
                <img
                  src="https://s3.amazonaws.com/assets.smoothpay.com/web/portal-assets/smooth-commerce-new-logo.png"
                  width="40%"
                />
              </ImageWrapper>
              <MessageWrapper id="ResetPasswordRequest-Title">
                Reset password
                <SubMessageWrapper id="ResetPasswordRequest-Message">
                  Enter your username and we'll send you a link to reset your
                  password
                </SubMessageWrapper>
              </MessageWrapper>
              <StyledForm onSubmit={handleSubmit}>
                <Field
                  id="ResetPasswordRequest-RequestUsernameField"
                  name="username"
                  placeholder="Username"
                  size="small"
                  type="text"
                  component={RenderTextField}
                  validate={required}
                />
                <ButtonRow>
                  <OptionsWrapper>
                    <CancelButton>
                      <Link to="/" style={{ color: 'black', fontWeight: 550 }}>
                        Cancel
                      </Link>
                    </CancelButton>
                    <Button
                      id="ResetPasswordRequest-submitButton"
                      type="submit"
                      style={{ alignSelf: 'flex-end' }}
                    >
                      Send
                    </Button>
                  </OptionsWrapper>
                </ButtonRow>
              </StyledForm>
            </Paper>
          </SectionWrapper>
        </PageWrapper>
      )}
    </Formik>
  );
}

const mapsStateToProps = (state) => ({
  accountReducer: state.accountReducer,
});

export default connect(mapsStateToProps, { ...actions })(ResetPasswordRequest);
