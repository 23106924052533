import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Field } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import {
  RenderTextField,
  required,
  RenderSelect,
} from '../../../../../components/FormElements/FormikElements';
import FilterButton from '../../../../../components/FilterModal/FilterButton';
import FilterProductModal from '../../../../../components/FilterModal/FilterProductModal';
const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      minWidth: '100%',
    },
  },
}));
const Wrapper = styled.div`
  display: grid;
  grid-gap: 40px;
`;

const ProductWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 100px;
`;

const FieldWrapper = styled.div`
  display: grid;
  grid-gap: 40px;
  grid-template-rows: 120px auto;
`;

const Subtitle = styled.div`
  font-weight: 600;
`;

const OptionsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 100px;
`;

const OptionGridWrapper = styled.div`
  display: grid;
  grid-gap: 20px;
`;

const CheckboxWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 50px;
  align-items: center;
`;

const BirthdayFreeItemOffer = ({
  allProducts,
  allVariants,
  setFieldValue,
  auto_redeem,
  reusable,
  apply_multiple,
  conditions,
  touched,
  errors,
}) => {
  const classes = useStyles();
  const validateDiscountAmount = (value) => {
    let error;
    if (!value && value !== 0) {
      error = 'Required';
    } else if (parseFloat(value) > 100000000) {
      error = 'Entered amount is not acceptable.';
    } else if (parseFloat(value) < 0) {
      error = 'No less than Zero';
    } else if (parseFloat(value) === 0) {
      error = 'More than zero is required';
    }
    return error;
  };
  const [isOpen, setIsOpen] = useState(false);
  const [formField, setFormField] = useState('');
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [eligibleProductsPOSValue, setEligibleProductsPOSValue] = useState([]);
  const openModal = (field, products) => {
    setFormField(field);
    setFilteredProducts(products);
    setIsOpen(true);
  };
  useEffect(() => {
    let newEligiblePOSs = [];
    conditions.forEach((condition, index) => {
      let eligiblePOSIDs = { source: condition.source };
      if (condition.source !== 'web' && condition.source !== 'mobile') {
        const eligibleProducts = JSON.stringify(
          condition.item_conditions.eligible_products
        )
          ?.replace('[', '')
          ?.replace(']', '')
          ?.replaceAll('"', '');
        eligiblePOSIDs = { ...eligiblePOSIDs, products: eligibleProducts };
        newEligiblePOSs.push(eligiblePOSIDs);
      }
    });
    setEligibleProductsPOSValue(newEligiblePOSs);
  }, []);
  const handleChangePOSIDs = (e, setFieldValue, type, source) => {
    const filed = e.target.name;
    const value = e.target.value;
    let formatedFiledValue = [];
    let filedValue = value.split(',');
    filedValue.forEach((item) => {
      formatedFiledValue.push(item);
      // if (!isNaN(item) && !isNaN(parseFloat(item))) {
      // }
    });
    setFieldValue(filed, formatedFiledValue);
    let newEligibleProduct = [...eligibleProductsPOSValue];
    const index = eligibleProductsPOSValue.findIndex(
      (item) => item.source === source
    );
    newEligibleProduct[index].products = value;
    setEligibleProductsPOSValue(newEligibleProduct);
  };
  const getEligiblePOSValue = (source) => {
    const selectedEligible = eligibleProductsPOSValue.find(
      (item) => item.source === source
    );
    return selectedEligible ? selectedEligible.products : '';
  };
  return (
    <Wrapper>
      {conditions.map((condition, index) => {
        return (
          <React.Fragment key={index}>
            <Subtitle style={{ margin: '10px 0', textTransform: 'capitalize' }}>
              {condition.source}
            </Subtitle>
            <ProductWrapper>
              <FieldWrapper>
                <div>
                  <Subtitle>*Eligible Products</Subtitle>
                  <div>
                    These are Products that can be free. If there are no
                    Pre-Requisite Products, the free products will be available
                    to all customers that can view it on their app.
                  </div>
                </div>
                <div>
                  {condition.source === 'web' ||
                  condition.source === 'mobile' ? (
                    <FilterButton
                      message={
                        conditions[index].item_conditions.eligible_products
                          ?.length === 0
                          ? 'No Products Selected'
                          : conditions[index].item_conditions.eligible_products
                              ?.length === allProducts?.length
                          ? 'All Product Selected'
                          : `Selected Products (${conditions[index].item_conditions.eligible_products?.length})`
                      }
                      height='55px'
                      setIsOpen={() =>
                        openModal(
                          `conditions[${index}].item_conditions.eligible_products`,
                          conditions[index].item_conditions.eligible_products
                        )
                      }
                    />
                  ) : (
                    <TextField
                      multiline
                      className={classes.root}
                      id='outlined-basic'
                      placeholder='Type POS IDs'
                      name={`conditions[${index}].item_conditions.eligible_products`}
                      value={getEligiblePOSValue(condition.source)}
                      onChange={(e) =>
                        handleChangePOSIDs(
                          e,
                          setFieldValue,
                          'eligible',
                          condition.source
                        )
                      }
                      variant='outlined'
                      style={{ width: '100%' }}
                    />
                  )}
                  {isOpen && (
                    <FilterProductModal
                      placeholder={`Enter product name (${index})`}
                      searchLabel='Search by product name'
                      filteredProducts={filteredProducts}
                      setFilteredProducts={setFieldValue}
                      isOpen={isOpen}
                      setIsOpen={setIsOpen}
                      formField={formField}
                      isEmptyAccepted={false}
                    />
                  )}
                </div>
              </FieldWrapper>

              <div></div>
            </ProductWrapper>
            <hr />
          </React.Fragment>
        );
      })}
      <Subtitle>Free Item Options</Subtitle>
      <OptionsWrapper>
        <OptionGridWrapper>
          <Field
            name='offer_details.template_values.max_discount'
            component={RenderTextField}
            type='number'
            label='*Max Discount'
            validate={validateDiscountAmount}
            onKeyDown={(evt) =>
              (evt.key === 'e' || evt.key === '-' || evt.key === '+') &&
              evt.preventDefault()
            }
          />
          {!errors &&
            !touched &&
            errors.offer_details.template_values.max_discount &&
            touched.offer_details.template_values.max_discount && (
              <div>{errors.offer_details.template_values.max_discount}</div>
            )}

          <Field
            name='offer_details.template_values.days_available'
            component={RenderTextField}
            type='number'
            label='*days available'
            validate={required}
            onKeyDown={(evt) =>
              (evt.key === 'e' || evt.key === '-' || evt.key === '+') &&
              evt.preventDefault()
            }
          />
        </OptionGridWrapper>
        <OptionGridWrapper>
          <CheckboxWrapper>
            <Checkbox
              onChange={(e) =>
                setFieldValue('offer_details.auto_redeem', e.target.checked)
              }
              checked={auto_redeem}
              inputProps={{
                'aria-label': 'secondary checkbox',
              }}
            />
            <div>Auto Redeem</div>
          </CheckboxWrapper>
          <CheckboxWrapper>
            <Checkbox
              onChange={(e) =>
                setFieldValue('offer_details.reusable', e.target.checked)
              }
              checked={reusable}
              inputProps={{
                'aria-label': 'secondary checkbox',
              }}
            />
            <div>Reusable</div>
          </CheckboxWrapper>
        </OptionGridWrapper>
      </OptionsWrapper>
    </Wrapper>
  );
};

const mapStateToProps = (state) => ({
  allProducts: state.productsReducer.allProducts,
  allVariants: state.variantsReducer.allVariants,
});

export default connect(mapStateToProps)(BirthdayFreeItemOffer);
