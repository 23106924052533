export const UPDATE_OFFERS_REQUEST_PARAMS = 'UPDATE_OFFERS_REQUEST_PARAMS';
export const UPDATE_SEGMENTS_REQUEST_PARAMS = 'UPDATE_SEGMENTS_REQUEST_PARAMS';
export const UPDATE_MOBILE_MESSAGES_REQUEST_PARAMS =
  'UPDATE_MOBILE_MESSAGES_REQUEST_PARAMS';
export const UPDATE_COUPONS_REQUEST_PARAMS = 'UPDATE_COUPONS_REQUEST_PARAMS';
export const UPDATE_MEDIA_REQUEST_PARAMS = 'UPDATE_MEDIA_REQUEST_PARAMS';
export const UPDATE_VARIANTS_REQUEST_PARAMS = 'UPDATE_VARIANTS_REQUEST_PARAMS';
export const UPDATE_PROMO_REQUEST_PARAMS = 'UPDATE_PROMO_REQUEST_PARAMS';
export const UPDATE_OPTIONS_REQUEST_PARAMS = 'UPDATE_OPTIONS_REQUEST_PARAMS';
export const UPDATE_COMBOS_REQUEST_PARAMS = 'UPDATE_COMBOS_REQUEST_PARAMS';
export const UPDATE_PRODUCTS_REQUEST_PARAMS = 'UPDATE_PRODUCTS_REQUEST_PARAMS';
export const UPDATE_CATEGORIES_REQUEST_PARAMS =
  'UPDATE_CATEGORIES_REQUEST_PARAMS';
export const UPDATE_ITEMS_REQUEST_PARAMS = 'UPDATE_ITEMS_REQUEST_PARAMS';
