import {
    EIGHT_CHARACTERS_MIN_MATCH,
    UPPER_LOWER_CHARACTER_MATCH,
    DIGIT_SYMBOL_MATCH,
    PASSWORD_MATCH_RETYPED,
} from './types';

const defaultState = {
    eight: false,
    upperLower: false,
    digitSymbol: false,
    typedPasswordsMatch: false,
}

export default (state = defaultState, action) => {
    switch (action.type) {
        case EIGHT_CHARACTERS_MIN_MATCH:
            return {
                ...state,
                eight: action.hasEightDigits ? action.hasEightDigits : false
            }
        case UPPER_LOWER_CHARACTER_MATCH:
            return {
                ...state,
                upperLower: action.hasUpperAndLower ? action.hasUpperAndLower : false
            }
        case DIGIT_SYMBOL_MATCH:
            return {
                ...state,
                digitSymbol: action.hasDigitOrSymbol ? action.hasDigitOrSymbol : false
            }
        case PASSWORD_MATCH_RETYPED:
            return {
                ...state,
                typedPasswordsMatch: action.typedPasswordsMatch ? action.typedPasswordsMatch : false
            }
        default:
            return state;
    }
}