import React from 'react';
import styled from 'styled-components';

export const PhonePreviewWrapper = styled.div`
  margin-left: 40px;
  position: sticky;
  top: 70px;
`;

export const AppFeedPreview = styled.div`
  position: absolute;
  top: 55px;
  left: 166px;
  transform: translateX(-49%);
  background: #fdf8f2;
  width: 246px;
  height: 520px;
  border-radius: 3px;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const CardTitle = styled.p`
  font-size: ${props => props.fontSize};
  font-weight: bolder;
  color: ${props => props.theme.white};
  margin: 0;
  line-height: 12px;
`;

export const CardSubtitle = styled.p`
  font-size: ${props => props.fontSize};
  color: ${props => props.theme.white};
  margin: 0;
`;

export const CardDescription = styled.p`
  font-size: 7px;
  color: ${props => props.theme.white};
  margin: 0;
`;
