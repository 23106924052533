import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { Field } from 'redux-form';
import SegmentCard, { ButtonRow } from '../SegmentCard/SegmentCard';
import { Button, LoadingButton } from 'merchant-portal-components';
import {
  renderTextField,
  renderSelect,
  renderRadioGroup,
  renderCheckbox,
} from '../../../../components/FormElements/ReduxForm';
import { LoadingWhite } from 'merchant-portal-components';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import ConfirmModal from '../../../../components/Modal/ConfirmModal';

import {
  getCountries,
  getStates,
  getLanguages,
} from '../../../../utils/constants/constantOptions';

const FormWrapper = styled.div`
  padding: 10px 0 10px 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 25px 30px;
  grid-column: 1;
`;

const GridCol = styled.div`
  grid-column: span ${(props) => props.lg};
  @media (max-width: 992px) {
    grid-column: span ${(props) => props.md};
  }
`;

const Label = styled.label`
  padding-top: 20px;
  color: ${(props) => props.theme.darkBlue};
  font-weight: bold;
  width: 60%;
  text-align: left;
`;

const StyledLink = styled.a`
  text-align: right;
  margin-right: 80px;
  cursor: pointer;
  text-decoration: underline;
`;

class SyncWithEmailForm extends Component {
  state = {
    states: getStates(),
    countries: getCountries(),
    languages: getLanguages(),
    isConfirmSendModalOpen: false,
  };
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.external_segment &&
      this.props.external_segment.external_segment_id != 'nosync'
    ) {
      this.props.change('integration', 'mailchimp');
      if (
        this.props.external_segment.sync_frequency !==
        prevProps.external_segment.sync_frequency
      ) {
        // this.props.change('mailchimp.strategy', 'merge_with_existing_list');
      }
    } else {
      this.props.change('integration', 'none');
    }
  }

  renderMailchimpLists() {
    if (this.props.segments.mailchimpLists) {
      return this.props.segments.mailchimpLists.map((list) => {
        return {
          value: list.id,
          label: list.name,
        };
      });
    } else return [];
  }

  formSubmit() {
    this.setState({ isConfirmSendModalOpen: false });
    this.props.handleSubmit();
  }

  renderFinishButton() {
    if (this.props.segments.requestingCreateSegment) {
      let buttonText = this.props.edit ? 'Updating' : 'Creating';
      return (
        <LoadingButton whiteSpinner disabled>
          {buttonText}
        </LoadingButton>
      );
    }
    return (
      <>
        <Button
          type="button"
          onClick={() => this.setState({ isConfirmSendModalOpen: true })}
        >
          Finish
        </Button>
        {this.state.isConfirmSendModalOpen && (
          <ConfirmModal
            actionLabel="Ok"
            titleIsHidden={true}
            confirmMessage="Your segmentation data summary report will be generated in 5-10 minutes."
            onClick={() => this.formSubmit()}
            onClose={() => this.setState({ isConfirmSendModalOpen: false })}
          />
        )}
      </>
    );
  }

  render() {
    const title = 'sync with email';
    const instructions = this.props.segments.noMailchimpIntegration
      ? 'You are not integrated with MailChimp. Please finish creating segment.'
      : 'Would you like to synchronize this audience with a list?';

    const { external_segment, integration } = this.props;
    const { states, countries, languages } = this.state;
    return (
      <div>
        <form onSubmit={this.props.handleSubmit}>
          <SegmentCard
            title={title}
            instructions={instructions}
            singleColumn
            cancelEditSegment={this.props.cancelEditSegment}
          >
            {!this.props.segments.noMailchimpIntegration && (
              <FormWrapper>
                <GridCol lg={1} md={4}>
                  <Field
                    inLine
                    label="Choose an action"
                    name="mailchimp.external_segment_id"
                    component={renderSelect}
                    options={[
                      { value: 'nosync', label: 'Do not sync' },
                      { value: 'new', label: 'New list...' },
                      ...this.renderMailchimpLists(),
                    ]}
                    simpleValue
                  />
                </GridCol>
                {integration != 'none' &&
                  external_segment.external_segment_id === 'new' && (
                    <Fragment>
                      <GridCol lg={2} md={4}>
                        <Field
                          name="mailchimp.details.segment_name"
                          label="Mail Chimp List Name"
                          component={renderTextField}
                          validate={required}
                          type="text"
                        />
                      </GridCol>

                      <GridCol lg={2} md={4}>
                        <Field
                          name="mailchimp.details.company"
                          label="Company Name"
                          component={renderTextField}
                          validate={required}
                          type="text"
                        />
                      </GridCol>

                      <GridCol lg={2} md={4}>
                        <Field
                          name="mailchimp.details.address1"
                          label="Address Line 1"
                          component={renderTextField}
                          validate={required}
                          type="text"
                        />
                      </GridCol>

                      <GridCol lg={2} md={4}>
                        <Field
                          name="mailchimp.details.address2"
                          label="Address Line 2"
                          component={renderTextField}
                          type="text"
                        />
                      </GridCol>

                      <GridCol lg={2} md={2}>
                        <Field
                          name="mailchimp.details.city"
                          label="City"
                          component={renderTextField}
                          validate={required}
                          type="text"
                        />
                      </GridCol>

                      <GridCol lg={1} md={2}>
                        <Field
                          inLine
                          name="mailchimp.details.state"
                          label="Province"
                          component={renderSelect}
                          options={states}
                          getOptionValue={(state) => state}
                          getOptionLabel={(state) => state.name}
                          simpleValue
                          valueKey="abbreviation"
                          validate={required}
                        />
                      </GridCol>

                      <GridCol lg={1} md={2}>
                        <Field
                          name="mailchimp.details.zip"
                          label="Postal / Zip Code"
                          component={renderTextField}
                          validate={required}
                          type="text"
                        />
                      </GridCol>

                      <GridCol lg={1} md={2}>
                        <Field
                          inLine
                          name="mailchimp.details.country"
                          label="Country"
                          component={renderSelect}
                          options={countries}
                          getOptionValue={(country) => country}
                          getOptionLabel={(country) => country.name}
                          simpleValue
                          valueKey="abbreviation"
                          validate={required}
                        />
                      </GridCol>

                      <GridCol lg={4} md={4}>
                        <Field
                          name="mailchimp.details.default_reminder_text"
                          label="Subscription Reminder"
                          component={renderTextField}
                          rows={2}
                          validate={required}
                          type="textarea"
                          placeholder="Enter a message to remind your mail recipients how get onto this list."
                        />
                      </GridCol>

                      <GridCol lg={1} md={2}>
                        <Field
                          name="mailchimp.details.default_from_name"
                          label="Default From Name"
                          component={renderTextField}
                          validate={required}
                          type="text"
                        />
                      </GridCol>

                      <GridCol lg={1} md={2}>
                        <Field
                          name="mailchimp.details.default_from_email"
                          label="Default From Email"
                          component={renderTextField}
                          validate={[email, required]}
                          type="email"
                        />
                      </GridCol>

                      <GridCol lg={1} md={2}>
                        <Field
                          name="mailchimp.details.default_subject"
                          label="Default Subject"
                          component={renderTextField}
                          validate={required}
                          type="text"
                        />
                      </GridCol>

                      <GridCol style={{ gridColumn: '1' }}>
                        <Field
                          inLine
                          name="mailchimp.details.default_language"
                          label="Language"
                          component={renderSelect}
                          options={languages}
                          getOptionValue={(language) => language}
                          getOptionLabel={(language) => language.name}
                          simpleValue
                          valueKey="abbreviation"
                          validate={required}
                        />
                      </GridCol>

                      <GridCol lg={1} md={2}>
                        <Field
                          name="mailchimp.sync_frequency"
                          label="How often do you want to sync"
                          validate={required}
                          component={renderSelect}
                          options={[
                            { value: 'hourly', label: 'Hourly' },
                            { value: 'daily', label: 'Daily' },
                            { value: 'weekly', label: 'Weekly' },
                            { value: 'monthly', label: 'Monthly' },
                          ]}
                          simpleValue
                        />
                      </GridCol>

                      <GridCol style={{ gridColumn: '1' }}>
                        Overwrite existing list{' '}
                        <Field
                          name="is_overwrite"
                          component={renderCheckbox}
                          type="checkbox"
                        />
                      </GridCol>
                      <GridCol lg={1} md={2}>
                        Email Type Option{' '}
                        <Field
                          name="mailchimp.details.default_email_type"
                          component={renderCheckbox}
                          type="checkbox"
                        />
                      </GridCol>
                    </Fragment>
                  )}

                {integration != 'none' &&
                  external_segment.length !== 0 &&
                  external_segment.external_segment_id !== 'new' &&
                  external_segment.external_segment_id !== 'nosync' && (
                    <React.Fragment>
                      <GridCol lg={1} md={4}>
                        <Field
                          name="mailchimp.sync_frequency"
                          label="How often do you want to sync"
                          validate={required}
                          component={renderSelect}
                          options={[
                            { value: 'hourly', label: 'Hourly' },
                            { value: 'daily', label: 'Daily' },
                            { value: 'weekly', label: 'Weekly' },
                            { value: 'monthly', label: 'Monthly' },
                          ]}
                          simpleValue
                        />
                      </GridCol>

                      <GridCol lg={1} md={2}>
                        Overwrite existing list{' '}
                        <Field
                          name="is_overwrite"
                          component={renderCheckbox}
                          type="checkbox"
                        />
                      </GridCol>
                    </React.Fragment>
                  )}
              </FormWrapper>
            )}
            {/* <StyledLink href=" ">What does this mean?</StyledLink> */}
            <GridCol lg={1} md={4}>
              <div>
                {' '}
                Segments will only include contact information from Users who
                have opted in.
              </div>
              {/* <a style={{textDecoration: 'underline'}} href="">
                Learn More
              </a> */}
            </GridCol>
            <ButtonRow>
              <Button
                type="button"
                onClick={() => this.props.previousPage()}
                secondary
              >
                Back
              </Button>
              {this.renderFinishButton()}
            </ButtonRow>
          </SegmentCard>
        </form>
      </div>
    );
  }
}

const required = (value) => (value ? undefined : 'Required');
const length2 = (value) =>
  value && value.length != 2 ? `Must be 2 character code.` : undefined;
const number = (value) =>
  value && isNaN(Number(value)) ? 'Must be a number' : undefined;
const email = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Invalid email address'
    : undefined;

export default SyncWithEmailForm;
