import React from 'react';
import styled from 'styled-components';
import { EditIcon, DeleteIcon } from '../../../components/Icon';
import Card from '../../../components/Card/Card';

const TagRow = styled.div`
  width: 324px;
  height: 48px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  background-color: ${props => props.theme.white};
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 16px;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  margin: 0px 0px 16px 0px;
  border-radius: 4px;
`;

const TagText = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: ${props => props.theme.black};
`;

const ActionsWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 20px;
  color: ${props => props.theme.black};
`;

const StyleCard = styled(Card)`
  border-radius: 12px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
`;

const TagIndex = ({ allTags, showTagForm, showDeleteTagModal }) => {
  return (
    <StyleCard>
      <div style={{ marginBottom: '20px' }}>
        Tags are used for labelling purpose and for search optimization on the
        app
      </div>
      {allTags.map(tag => {
        return (
          <TagRow key={tag.tag_id}>
            <TagText>
              {/* {tag.name}: {tag.value} */}
              {tag.name}
            </TagText>
            <ActionsWrapper>
              <DeleteIcon onClick={() => showDeleteTagModal(tag.tag_id)} />
              <EditIcon onClick={() => showTagForm(tag)} />
            </ActionsWrapper>
          </TagRow>
        );
      })}
    </StyleCard>
  );
};

export default TagIndex;
