import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from './optionActions';
import OptionsListContainer from './OptionsList/OptionsListContainer';
import OptionsDetailContainer from './OptionsDetail/OptionsDetailContainer';

const OptionsContainer = ({
  currentPage,
  loadCreatePage,
  getOptions,
  requestParams,
  updateOptionRequestParams,
}) => {
  const [searchString, setSearchString] = useState('');
  useEffect(() => {
    return () => {
      updateOptionRequestParams({
        ...requestParams,
        page_number: 1,
      });
      setSearchString('');
    };
  }, []);
  useEffect(() => {
    const debounce = setTimeout(() => {
      const newRequestParams = {
        ...requestParams,
        page_number: 1,
        search_string: searchString,
      };
      if (searchString.length > 2 || searchString === '') {
        getOptions(newRequestParams);
      }
      updateOptionRequestParams(newRequestParams);
    }, 1500);
    return () => {
      clearTimeout(debounce);
    };
  }, [searchString, currentPage]);
  if (currentPage === 'list') {
    return (
      <OptionsListContainer
        handleCreateButtonClick={() => loadCreatePage()}
        searchString={searchString}
        setSearchString={setSearchString}
      />
    );
  } else if (currentPage === 'create') {
    return <OptionsDetailContainer />;
  } else if (currentPage === 'edit') {
    return <OptionsDetailContainer isEdit={true} />;
  }
};

const mapStateToProps = (state) => ({
  currentPage: state.optionReducer.currentPage,
  requestParams: state.optionReducer.requestParams,
});
export default connect(mapStateToProps, actions)(OptionsContainer);
