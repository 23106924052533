import React from 'react';
import styled from 'styled-components';
import { Field } from 'formik';
import {
  RenderTextField,
  required,
  RenderSelect
} from '../../../../../components/FormElements/FormikElements';
import { Tooltip } from '@material-ui/core';

const InfoWrapper = styled.div`
  display: grid;
  grid-gap: 30px;
`;

const allOfferTypes = [
  { value: 'first-time', label: 'First-Time', requiresPoints: false },
  { value: 'discounted-item', label: 'Discount', requiresPoints: false },
  { value: 'free-item', label: 'Free Item', requiresPoints: true },
  {
    value: 'birthday-free-item',
    label: 'Birthday - Free Item',
    requiresPoints: false
  },
  {
    value: 'birthday-discounted-item',
    label: 'Birthday - Discount',
    requiresPoints: false
  },
  { value: 'carryover-loyalty', label: 'Carry Over', requiresPoints: true }
];

const OfferBasicForm = ({
  setFieldValue,
  source,
  templateName,
  editingOffer,
  isPointsEnabled
}) => {
  const templateNameOptions = isPointsEnabled
    ? allOfferTypes
    : allOfferTypes.filter(type => !type.requiresPoints);

  return (
    <div>
      <InfoWrapper>
        <Tooltip
          title="You cannot edit offer template name"
          //style={{ display: editingOffer ? 'block' : 'none' }}
          disableHoverListener={!editingOffer}
          disableFocusListener={!editingOffer}
          disableTouchListener={!editingOffer}
        >
          <div>
            <Field
              name="offer_details.template_name"
              component={RenderSelect}
              options={templateNameOptions}
              placeholder="*Offer Type"
              validate={required}
              onChange={name => {
                setFieldValue(
                  'offer_details.template_values',
                  name.value == 'free-item'
                    ? { apply_multiple: false }
                    : name.value === 'discounted-item'
                    ? { apply_multiple: false, discount_type: 'percent' }
                    : name.value === 'birthday-discounted-item'
                    ? { discount_type: 'percent' }
                    : {}
                );
                if (name.value == 'first-time') {
                  setFieldValue('offer_details.auto_redeem', true);
                } else {
                  setFieldValue('offer_details.auto_redeem', false);
                }
                if (name.value == 'carryover-loyalty') {
                  setFieldValue('offer_details.reusable', true);
                } else {
                  setFieldValue('offer_details.reusable', false);
                }
                const item_conditions =
                  name.value === 'discounted-item'
                    ? {
                        discounted_products: [],
                        required_products: [],
                        discounted_variants: [],
                        required_variants: [],
                        required_product_condition: 'AND',
                        sort_price: 'ASC'
                      }
                    : name.value === 'birthday-free-item'
                    ? { eligible_products: [] }
                    : name.value === 'birthday-discounted-item'
                    ? {
                        discounted_products: [],
                        required_products: [],
                        discounted_variants: [],
                        required_variants: [],
                        required_product_condition: 'AND'
                      }
                    : {
                        eligible_products: [],
                        required_products: [],
                        eligible_variants: [],
                        required_variants: []
                      };
                setFieldValue('conditions', []);
              }}
              isDisabled={editingOffer}
            />
          </div>
        </Tooltip>
        <Field
          name="offer_details.title"
          component={RenderTextField}
          type="text"
          label="*Title"
          validate={required}
        />
        <Field
          name="offer_details.subtitle"
          component={RenderTextField}
          type="text"
          label="*Subtitle"
          validate={required}
        />
        <Field
          name="offer_details.description"
          component={RenderTextField}
          type="text"
          label="Description"
        />
        <Field
          name="offer_details.label"
          component={RenderTextField}
          type="text"
          label="*Label"
          validate={required}
        />
      </InfoWrapper>
    </div>
  );
};

export default OfferBasicForm;
