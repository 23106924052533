import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popper from '@material-ui/core/Popper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import EditOrderStatusModal from './EditOrderStatusModal';
const useStyles = makeStyles((theme) => ({
  root: {},
  typography: {
    padding: '8px 12px',
    fontSize: '15px',
    cursor: 'pointer',
  },
  paper: {
    minWidth: 250,
    boxShadow: '0 1px 8px 0 rgba(0, 0, 0, 0.16)',
  },
}));
const ActionPopper = ({ anchorEl, open, selectedOrder, setAnchorEl }) => {
  const classes = useStyles();
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [modalType, setModalType] = useState();
  const handleOpenStatusModal = (modalType) => {
    setModalType(modalType);
    setOpenStatusModal(true);
  };
  const handleCloseStatusModal = () => {
    setModalType('');
    setOpenStatusModal(false);
  };
  const handleClickAway = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Popper open={open} anchorEl={anchorEl} placement="bottom-end" transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={150}>
            <ClickAwayListener onClickAway={handleClickAway}>
              <Paper className={classes.paper}>
                {selectedOrder.status === 0 && !selectedOrder.is_future_order && (
                  <>
                    <Typography
                      className={classes.typography}
                      onClick={() => handleOpenStatusModal('accept')}
                    >
                      Accept Order
                    </Typography>
                    {/* <Typography
                      className={classes.typography}
                      onClick={() => handleOpenStatusModal('accept-delay')}
                    >
                      Accept Order With Delay
                    </Typography> */}
                  </>
                )}
                {selectedOrder.status === 1 && (
                  <>
                    <Typography
                      className={classes.typography}
                      onClick={() => handleOpenStatusModal('ready')}
                    >
                      Change to Ready for Pickup
                    </Typography>
                    <Typography
                      className={classes.typography}
                      onClick={() => handleOpenStatusModal('cancel')}
                    >
                      {'Cancel & Refund'}
                    </Typography>
                  </>
                )}
                {selectedOrder.status === 0 && (
                  <>
                    <Typography
                      className={classes.typography}
                      onClick={() => handleOpenStatusModal('reject')}
                    >
                      Reject Order
                    </Typography>
                  </>
                )}
              </Paper>
            </ClickAwayListener>
          </Fade>
        )}
      </Popper>
      {openStatusModal && (
        <EditOrderStatusModal
          selectedOrder={selectedOrder}
          open={openStatusModal}
          handleCloseStatusModal={handleCloseStatusModal}
          modalType={modalType}
        />
      )}
    </>
  );
};

export default ActionPopper;
