import styled from 'styled-components';

export const PageTitle = styled.p`
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 0.04px;
  color: #191818;
`;

export const BackText = styled.div`
  display: grid;
  grid-gap: 5px;
  grid-template-columns: 7px 1fr;
  cursor: pointer;
  align-items: center;
  margin-top: 20px;

  &:hover {
    color: #3051d4;
  }
`;

export const Card = styled.div`
  max-width: 100%;
  margin: 40.5px 0px 113px 0;
  padding: 33.5px 105px 25.5px 61px;
  border-radius: 20px;
  background-color: #fff;
`;

export const MainWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 20px;
  margin-top: 20px;
`;

export const TitleDiv = styled.div``;

export const Title = styled.p`
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0.03px;
  color: #000;
`;

export const Description = styled.p`
  font-size: 15px;
  color: #191818;
  max-width: 500px;
`;

export const MessageDiv = styled.div`
  margin: 30px 0px 50px 0px;
  display: grid;
`;

export const ImageDiv = styled.div`
  display: grid;
  grid-gap: 20px;
`;

export const MessageAction = styled.div`
  margin-top: 30px;
  display: grid;
  grid-gap: 20px;
`;

export const MessageTiming = styled.div`
  margin: 40px 0;
  display: grid;
  grid-gap: 20px;
`;

export const PreviewDiv = styled.div`
  border-left: 1.5px solid #e1e1e1;
  padding-left: 30px;
`;

export const SectionTitle = styled.p`
  font-size: 17px;
  font-weight: 600;
  letter-spacing: 0.02px;
`;

export const TimeWrapper = styled.div`
  display: grid;
  grid-template-columns: 86px 100px;
  grid-gap: 32px;
  align-items: center;
  font-weight: 600;
  .rw-widget-input {
    border: 0.5px solid rgba(0, 0, 0, 0.24);
  }
  .rw-input {
    background-color: #fff;
  }
  .rw-select-bordered {
    background-color: #fff;
    border: 0.5px solid rgba(0, 0, 0, 0.24);
    border-left: none;
  }
`;

export const PushDiv = styled.div`
  padding: 8.2px 13.4px 13.8px 8.8px;
  border-radius: 10.9px;
  -webkit-backdrop-filter: blur(16.8px);
  backdrop-filter: blur(16.8px);
  background-color: rgba(245, 245, 245, 0.6);
`;

export const PushTitle = styled.span`
  color: #000;
  font-size: 13px;
  font-weight: 600;
  line-height: 1.33;
  margin-top: 10px;
`;

export const PushDivHeader = styled.div`
  display: flex;
  justify-content: space-between;

  span {
    color: rgba(0, 0, 0, 0.6);
    font-size: 11px;
  }
`;

export const SmsDiv = styled.div`
  position: relative;
  margin-bottom: 10px;
  margin-left: calc(100% - 240px);
  padding: 10px;
  background-color: #e5e5ea;
  text-align: left;
  border: 1px solid #e5e5ea;
  border-radius: 17px;
  font-size: 17px;
  letter-spacing: -0.41px;

  :after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-bottom: 15px solid #e5e5ea;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    bottom: 0;
    right: -15px;
  }

  :before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-bottom: 17px solid #e5e5ea;
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
    bottom: -1px;
    right: -17px;
  }
`;

export const MessageTokensWrapper = styled.div`
  position: absolute;
  bottom: ${(props) => props.bottom};
  right: 18px;
  cursor: pointer;
`;
export const MessageTokenDiv = styled.div`
  text-align: center;
  padding: 5px;
  border-radius: 2px;
  background-color: ${(props) => (props.disabled ? '#f5f0f0' : '#e6ebff')};
  display: flex;
  align-items: center;
`;

export const MessageTokenIcon = styled.i`
  color: ${(props) => (props.disabled ? 'gray' : '#2440ae')};
  font-size: 11px;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
`;
