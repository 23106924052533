import React, { useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../productsActions';
import styled from 'styled-components';
import SubItemRow from './SubItemRow';
import { PlusIcon, MinusIcon } from './Icons';
import { Body } from './Typography';
import CircleCheck from './CircleCheck';
import CalorieString from './CalorieString';
import Collapse from '@material-ui/core/Collapse';
import getSelectedSubItemQuantity from '../utils/getSelectedSubItemQuantity';

const ItemLabel = styled(Body)`
  // color: ${props => props.theme.bodyColor};
  text-transform: uppercase;
`;

const ItemRowWrapper = styled.div`
  /* min-height: 90px; */
  background: white;
  border-bottom: 1px solid ${props => 'gray'};
`;

const ItemRow = styled.div`
  cursor: pointer;
  display: grid;
  grid-template: ${props => (props.image ? '90px / 90px 1fr' : '36px / 1fr')};
`;

const ImageWrapper = styled.div`
  overflow: hidden;
`;

const ItemImage = styled.img`
  height: 100%;
  object-fit: cover;
  background-color: #d3d3d3;
`;

const ItemInfo = styled.div`
  display: grid;
  align-items: stretch;
  /* padding: 16px 8px; */
`;

const ItemNameAndQuantity = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
`;

// const Cals = styled.div`
//   align-self: flex-end;
//   font-size: 12px;
// `;

const QuantitySectionWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 8px 4px 8px;
`;

const QuantitySection = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-gap: 20px;
`;

export const QuantityCircle = styled.div`
  width: 24px;
  height: 24px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  background: ${props => '#2440ae'};
  border-radius: 50%;
  margin-top: -5px;
`;

const ItemRowComponent = ({
  flatProduct,
  option,
  item,
  selectItem,
  incrementItem,
  decrementItem,
  incrementSubItem,
  decrementSubItem
}) => {
  const { subItemsById } = flatProduct;

  const [opened, setOpened] = useState(false);

  const toggleSelf = () => setOpened(!opened);

  const handleItemClick = () => {
    if (!item.sub_items) {
      if (option.maximum_pick === 1) {
        selectItem(item);
      } else if (!item.quantity) {
        incrementItem(item);
        if (item.maximum_pick !== 1) {
          toggleSelf();
        }
      } else if (item.quantity && item.maximum_pick === 1) {
        decrementItem(item);
      } else {
        toggleSelf();
      }
    } else {
      toggleSelf();
    }
  };

  return (
    <ItemRowWrapper>
      <ItemRow onClick={handleItemClick} image={item.image_url}>
        {item.image_url && (
          <ImageWrapper>
            <ItemImage src={item.image_url} />
          </ImageWrapper>
        )}
        <ItemInfo>
          <ItemNameAndQuantity>
            <ItemLabel>{item.name}</ItemLabel>
            {item.sub_items &&
              item.sub_items.some(subItemId => {
                const subItem = subItemsById[subItemId];
                return subItem.quantity;
              }) && (
                <QuantityCircle>
                  {getSelectedSubItemQuantity(subItemsById, item)}
                </QuantityCircle>
              )}
            {item.quantity === 1 && item.maximum_pick === 1 && <CircleCheck />}
            {item.quantity > 0 && item.maximum_pick !== 1 && (
              <QuantityCircle>{item.quantity}</QuantityCircle>
            )}
          </ItemNameAndQuantity>
          {/*<CalorieString
              calories={item.calories.find(
                calorie => calorie.variant_id === selectedVariantId
              )}
              />*/}
        </ItemInfo>
      </ItemRow>
      <Collapse in={opened}>
        {item.sub_items ? (
          item.sub_items.map(subItemId => {
            const subItem = subItemsById[subItemId];
            return (
              <SubItemRow
                key={subItem.item_id}
                option={option}
                item={item}
                subItem={subItem}
                subItemsById={subItemsById}
                incrementSubItem={incrementSubItem}
                decrementSubItem={decrementSubItem}
              />
            );
          })
        ) : (
          <QuantitySectionWrapper>
            <QuantitySection>
              <div
                onClick={() => {
                  if (item.quantity) {
                    if (item.quantity === 1) {
                      toggleSelf();
                    }
                    decrementItem(item);
                  }
                }}
              >
                <MinusIcon />
              </div>
              {item.quantity}
              <div
                onClick={() =>
                  item.quantity < item.maximum_pick && incrementItem(item)
                }
              >
                <PlusIcon />
              </div>
            </QuantitySection>
          </QuantitySectionWrapper>
        )}
      </Collapse>
    </ItemRowWrapper>
  );
};

const mapStateToProps = state => ({
  selectedVariantId:
    state.productsReducer.productCustomizerReducer.selectedVariantId
});

export default connect(
  mapStateToProps,
  actions
)(ItemRowComponent);
