import * as React from 'react';
import { Button } from 'merchant-portal-components';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import {
  SectionTitle,
  SectionSubTitle,
  ButtonWrapper,
  Left,
  Right,
  CancelButton,
} from './Styles/ServiceFeeDetailStyles';

export default function DeleteOverrideConfirmationModal(props) {
  const { businessId, open, onCancel, onConfirm } = props;
  const handleClose = () => {
    onCancel();
  };
  const handleConfirm = () => {
    onConfirm(businessId);
  };
  return (
    <div>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: { borderRadius: 10 },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          <SectionTitle display="inline" fontWeight="normal" fontSize="21px">
            Delete Override
          </SectionTitle>
          <i
            className="fa fa-times"
            style={{
              fontWeight: '200',
              float: 'right',
              margin: '0',
              cursor: 'pointer',
            }}
            onClick={handleClose}
          ></i>
        </DialogTitle>
        <DialogContent>
          <SectionSubTitle fontSize="16px">
            Are you sure you want to delete this Service Fee override?
          </SectionSubTitle>
        </DialogContent>
        <ButtonWrapper padding="20px 30px" margin="0">
          <Left>
            <CancelButton onClick={handleClose}>Cancel</CancelButton>
          </Left>
          <Right>
            <Button onClick={handleConfirm}>Yes, Delete</Button>
          </Right>
        </ButtonWrapper>
      </Dialog>
    </div>
  );
}
