import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  deleteAppFeedLayout,
  deepDuplicateFeed
} from '../../appFeedLayoutActions';
import ConfirmDeleteModal from '../../../../components/Modal/ConfirmDeleteModal';
import moment from 'moment';

const SingleAppFeedDiv = styled.div``;

const timeRange = 3;
const LayoutName = styled.p`
  font-size: 17px;
  font-weight: 600;
  color: #191818;
  margin-bottom: 16px;
`;

const LayoutNotes = styled.div`
  font-size: 12px;
  color: #191818;
`;

const IconsRow = styled.div`
  display: grid;
  grid-template-columns: auto auto auto 1fr auto;
  font-size: 12px;
  color: #191818;
  margin: 50px 0 0 0;
  text-align: center;
  grid-gap: 50px;

  & i {
    font-size: 16px;

    color: #3f3c3c;
  }
`;

const StarIcon = styled.i`
  color: #f3c500;
  font-size: 16px;
  margin-right: 10px;
`;

const BusinessesAssigned = styled.div`
  font-size: 12px;
  color: #191818;
  margin-top: 16px;
`;

const DateDiv = styled.div`
  font-size: 12px;
  color: #6f6f76;
  text-align: left;
`;

const SingleFeedComponent = ({
  singleAppFeed,
  deleteAppFeedLayout,
  deepDuplicateFeed,
  appIdentifier,
  history
}) => {
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDeleteAppLayout = singleAppFeed => {
    deleteAppFeedLayout(singleAppFeed);
  };

  const onClickDuplicate = singleAppFeed => {
    deepDuplicateFeed(singleAppFeed);
  };

  return (
    <SingleAppFeedDiv>
      <LayoutName>
        {singleAppFeed.is_default && (
          <StarIcon className="fas fa-star"></StarIcon>
        )}
        {singleAppFeed.template_name}
      </LayoutName>
      <LayoutNotes>
        <p>Notes:</p>
        {singleAppFeed.notes}
      </LayoutNotes>
      <BusinessesAssigned>
        <p>Assigned to:</p>
        {singleAppFeed.businesses.length === 0 ? (
          <span style={{ fontStyle: 'italic' }}>No businesses assigned</span>
        ) : (
          singleAppFeed.businesses
            .map(business => business.display_name)
            .join(', ')
        )}
      </BusinessesAssigned>
      <IconsRow>
        <div
          onClick={e => {
            onClickDuplicate(singleAppFeed);

            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <i className="fal fa-clone" />
          <p style={{ cursor: 'pointer' }}>Duplicate</p>
        </div>
        <div
          onClick={e => {
            setDeleteModal(true);

            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <i className="fal fa-trash-alt" />
          <p
            //onClick={() => onClickDeleteAppLayout(singleAppFeed)}

            style={{ cursor: 'pointer' }}
          >
            Delete
          </p>
        </div>
        <div
          onClick={e => {
            history.push('/layout-assignment');
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <i className="fal fa-share-all" />
          <p>
            {appIdentifier === 'sodexo'
              ? 'Assign to a business'
              : 'Assign a default layout'}
          </p>
        </div>
        <div />

        <DateDiv>
          <p>
            Date{' '}
            {moment(singleAppFeed.updated_at).isBetween(
              moment(singleAppFeed.created_at).subtract(timeRange, 'seconds')
                ._d,
              moment(singleAppFeed.created_at).add(timeRange, 'seconds')._d
            )
              ? 'Created'
              : 'Modified'}
          </p>
          {moment(singleAppFeed.updated_at).format('MMM D, YYYY')}
        </DateDiv>
      </IconsRow>

      {deleteModal && (
        <div onClick={e => e.stopPropagation()}>
          <ConfirmDeleteModal
            disable={singleAppFeed.is_default}
            cancel={() => setDeleteModal(false)}
            deleteAction={() => onClickDeleteAppLayout(singleAppFeed)}
          />
        </div>
      )}
    </SingleAppFeedDiv>
  );
};

export default SingleFeedComponent;
