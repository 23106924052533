import {
  REQUEST_EMPLOYEE_DATA,
  SET_EMPLOYEE_DATA,
} from './OrderTabletUsersTypes';

const defaultState = {
  data: [],
  meta: [],
  isRequestingData: false,
  selectedEmployee: null,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case REQUEST_EMPLOYEE_DATA:
      return {
        ...state,
        isRequestingData: true,
      };
    case SET_EMPLOYEE_DATA:
      return {
        ...state,
        isRequestingData: false,
        data: action.payload ? action.payload.data : [],
        meta: action.payload ? action.payload.meta : [],
      };
    default:
      return state;
  }
};
