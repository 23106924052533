import React, { useState } from 'react';
import styled from 'styled-components';
import Icon from '../../../../components/Icon/Icon';
import { StyledP } from '../../../../components/FormElements/FormElements';
import { Tr, Td, TBody } from '../../../../components/Table/TableElements';
import EditUser from '../EditUser';
import { getEmployeeInformation } from '../../OrderTabletUsersActions';

const StyledTd = styled(Td)`
  padding: 10px 30px 10px 20px;
  text-align: ${(props) => props.align || 'left'};
`;

function TableBody(props) {
  const {
    rows,
    fields,
    businessesLocations,
    employeeLocations,
    patchEmployee,
    passwordVerifier,
  } = props;

  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  return (
    <TBody>
      <EditUser
        open={infoModalOpen}
        close={() => setInfoModalOpen(false)}
        employeeLocations={employeeLocations}
        businessesLocations={businessesLocations}
        selectedEmployee={selectedEmployee}
        patchEmployee={patchEmployee}
        passwordVerifier={passwordVerifier}
      />
      {rows
        ? rows.map((data) => (
            <Tr>
              {fields.map((field) => (
                <StyledTd
                  align="left"
                  onClick={() => {
                    getEmployeeInformation(data.employee_id).then(
                      (employee) => {
                        setSelectedEmployee(employee);
                        if (selectedEmployee) setInfoModalOpen(true);
                      }
                    );
                    //
                  }}
                >
                  {field.includes('.')
                    ? data[field.split('.')[0]][field.split('.')[1]]
                    : data[field]}
                </StyledTd>
              ))}
              {/* <StyledTd align="left">
                  <StyledP>
                    <Icon className="far fa-trash-alt" />
                  </StyledP>
                </StyledTd> */}
            </Tr>
          ))
        : null}
    </TBody>
  );
}

export default TableBody;
