import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
const CustomerSearchSelect = ({
  customerSearchManager,
  onChange,
  setQueryString,
  queryString,
  value,
  startUpdating,
  getCustomersByQuery,
  selectCustomer,
}) => {
  const { customersByQuery } = customerSearchManager;
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  useEffect(() => {
    setFilteredCustomers(customersByQuery);
  }, [customersByQuery]);
  useEffect(() => {
    if (value === null) {
      setFilteredCustomers([]);
    }
  }, [value]);
  useEffect(() => {
    if (startUpdating && typeof queryString === 'string') {
      getCustomersByQuery(queryString?.trim());
    }
  }, [queryString, startUpdating]);
  const customerLabel = (customer) =>
    `${customer.first_name} ${customer.last_name} (${customer.email})`;

  const handleSearchChange = (queryString) => {
    setQueryString(queryString);
  };

  const renderNoOptionsMessage = () => {
    if (queryString?.length < 3) {
      return 'Type at least 3 characters to search';
    } else {
      if (customerSearchManager.requestingGetCustomersByQuery) {
        return 'Loading...';
      } else {
        return 'No results found';
      }
    }
  };
  return (
    <>
      <Autocomplete
        id='combo-box-demo'
        options={filteredCustomers}
        filterOptions={(customer) => customer}
        getOptionLabel={(customer) => customerLabel(customer)}
        getOptionValue={(customer) => customer.customer_id}
        onInputChange={(e) => {
          handleSearchChange(e?.target?.value);
          if (e === undefined) {
            onChange(null);
          }
        }}
        onChange={(e, selectedCustomer) => {
          selectCustomer(selectedCustomer);
          if (onChange) onChange(selectedCustomer);
        }}
        renderInput={(params) => (
          <TextField {...params} label='Search Customer' variant='outlined' />
        )}
      />
    </>
  );
};
export default CustomerSearchSelect;
