import React from 'react';
import {
  ComboItemRow,
  LineDivider,
  ProductName,
  ComboProductsDiv,
  ComboItemProductImage,
} from '../styles/style';
import ChargeExtraModal from './ChargeExtraModal';
import { Icon } from 'merchant-portal-components';
import Tooltip from '@material-ui/core/Tooltip';

const SingleComboProductsDiv = ({
  productVariant,
  productVariantIndex,
  comboItem,
  index,
  arrayHelpers,
  beautifyProductVariantNames,
  toggleChargeExtraModal,
  removeProductVariant,
  isChargeExtraModal,
  chargeExtraModalState,
  setChargeExtraModalState,
  initValues,
  setFieldValue,
  handleReset,
  values,
}) => {
  return (
    <div>
      <ComboProductsDiv key={productVariantIndex}>
        <ComboItemRow>
          {productVariant.productImage ? (
            <ComboItemProductImage src={productVariant.productImage} />
          ) : (
            <div />
          )}
          <ProductName isActive={productVariant.productIsActive}>
            {beautifyProductVariantNames(productVariant.productName)} -&nbsp;
            <span
              style={{
                fontWeight: '600',
              }}
            >
              {beautifyProductVariantNames(productVariant.variantName)}
            </span>
            {!productVariant.productIsActive ? (
              <span>
                <Tooltip title="The product is inactive">
                  <span>
                    <Icon
                      className="fal fa-info-circle"
                      style={{
                        cursor: 'inherit',
                        fontSize: '16px',
                        marginLeft: '10px',
                      }}
                    />
                  </span>
                </Tooltip>
              </span>
            ) : null}
            {productVariant.productExtraPrice && (
              <span
                style={{
                  marginLeft: '10px',
                  color: '#191818',
                  letterSpacing: '0.03px',
                }}
              >
                - ${productVariant.productExtraPrice} extra
              </span>
            )}
          </ProductName>
          <div />
          <div>
            <span
              onClick={() => {
                setChargeExtraModalState({
                  productId: productVariant.productId,
                  variantId: productVariant.variantId,
                  comboItemId: comboItem.id,
                });
                toggleChargeExtraModal();
              }}
              style={{
                cursor: 'pointer',
              }}
            >
              <i className="fas fa-dollar-sign"></i>
            </span>
          </div>
          <div>
            <span
              onClick={() =>
                removeProductVariant(
                  productVariant.productId,
                  productVariant.variantId,
                  comboItem.id,
                  setFieldValue,
                  index,
                  values
                )
              }
              style={{
                cursor: 'pointer',
              }}
            >
              <i className="far fa-trash-alt"></i>
            </span>
          </div>
        </ComboItemRow>
        <LineDivider />

        {isChargeExtraModal &&
          chargeExtraModalState.productId === productVariant.productId &&
          chargeExtraModalState.variantId === productVariant.variantId &&
          chargeExtraModalState.comboItemId === comboItem.id && (
            <ChargeExtraModal
              toggleChargeExtraModal={toggleChargeExtraModal}
              productVariant={productVariant}
              comboItemId={comboItem.id}
              initValues={initValues.id}
              setFieldValue={setFieldValue}
              comboItemIndex={index}
              arrayHelpers={arrayHelpers}
              productVariantIndex={productVariantIndex}
              handleReset={handleReset}
            ></ChargeExtraModal>
          )}
      </ComboProductsDiv>
    </div>
  );
};

export default SingleComboProductsDiv;
