export const GET_OPTION_PRICES_SUCCESS = 'GET_OPTION_PRICES_SUCCESS';
export const FILTER_OPTIONS_BY_VARIANTS = 'FILTER_OPTIONS_BY_VARIANTS';
export const PUT_OPTION_PRICES_REQUEST = 'PUT_OPTION_PRICES_REQUEST';
export const PUT_OPTION_PRICES_SUCCESS = 'PUT_OPTION_PRICES_SUCCESS';
export const RESET_PRICE_REDUX_STATE = 'RESET_PRICE_REDUX_STATE';
export const GET_ITEM_OVERRIDES_REQUEST = 'GET_ITEM_OVERRIDES_REQUEST';
export const GET_ITEMS_OVERRIDES = 'GET_ITEMS_OVERRIDES';
export const GET_FORMATTED_BUSINESSES = 'GET_FORMATTED_BUSINESSES';
export const SELECT_LOCATION_SCREEN = 'SELECT_LOCATION_SCREEN';
export const SUMMARY_SCREEN = 'SUMMARY_SCREEN';
export const GET_OPTION_PRICES_REQUEST = 'GET_OPTION_PRICES_REQUEST';
export const SET_IS_OPTION_OVERRIDES_MODAL = 'SET_IS_OPTION_OVERRIDES_MODAL';
export const SET_SELECTED_LOCATIONS = 'SET_SELECTED_LOCATIONS';
