import {
  GET_LOCATION_TRANSACTIONS,
  GET_LOCATION_SALES,
  GET_LOCATION_REFUND,
  GET_LOCATION_NEW_USER_TRANSACTIONS,
  LOADING
} from './types';
import api from '../../../api';
import moment from 'moment';
//import { transactionsData, salesData, refundData, newUserTransactionsData } from '../../../../mocks/topLocationsDashboard';

moment.locale('en');

export function getTopLocationTransactions(timePeriod) {
  return function(dispatch) {
    //dispatch({ type : GET_LOCATION_TRANSACTIONS, payload : transactionsData.data});
    dispatch({type: LOADING});
    api
      .get(
        `/dashboard/locations/transactions?interval_type=${
          timePeriod.interval_type
        }&interval_value=${timePeriod.interval_value}`
      )
      .then(response => {
        dispatch({type: GET_LOCATION_TRANSACTIONS, payload: response.data});
      })
      .catch(error => {
        // console.log('error occured', error);
      });
  };
}

export function getTopLocationSales(timePeriod) {
  return function(dispatch) {
    api
      .get(
        `/dashboard/locations/sales?interval_type=${
          timePeriod.interval_type
        }&interval_value=${timePeriod.interval_value}`
      )
      .then(response => {
        dispatch({type: GET_LOCATION_SALES, payload: response.data});
      })
      .catch(error => {
        // console.log('error occured', error);
      });
  };
}

export function getTopLocationRefund(timePeriod) {
  return function(dispatch) {
    //dispatch({ type : GET_LOCATION_REFUND, payload : refundData.data});
    api
      .get(
        `/dashboard/locations/purchases?interval_type=${
          timePeriod.interval_type
        }&interval_value=${timePeriod.interval_value}`
      )
      .then(response => {
        dispatch({type: GET_LOCATION_REFUND, payload: response.data});
      })
      .catch(error => {
        // console.log('error occured', error);
      });
  };
}

export function getTopLocationNewUserTransactions(timePeriod) {
  return function(dispatch) {
    //dispatch({ type : GET_LOCATION_NEW_USER_TRANSACTIONS, payload : newUserTransactionsData.data});
    api
      .get(
        `/dashboard/locations/users?interval_type=${
          timePeriod.interval_type
        }&interval_value=${timePeriod.interval_value}`
      )
      .then(response => {
        dispatch({
          type: GET_LOCATION_NEW_USER_TRANSACTIONS,
          payload: response.data
        });
      })
      .catch(error => {
        // console.log('error occured', error);
      });
  };
}
