import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import { Formik, Field } from 'formik';
import {
  RenderTextField,
  required,
  isPriceValidation,
  bulkValidator,
  maxLengthValidationForNameField,
} from '../../../../../../components/FormElements/FormikElements';
import { Button, ButtonRow } from 'merchant-portal-components';
import MediaModalContainer from '../../../../../MediaModal/MediaModalContainer';
import Tooltip from '@material-ui/core/Tooltip';
import MenuItem from '@material-ui/core/MenuItem';
import ChooseImage from '../../../../../MediaModal/components/ChooseImage';

const Wrapper = styled.div`
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
  margin-bottom: 24px;
`;

const FormWrapper = styled.div`
  padding: 48px;
  display: grid;
  grid-gap: 32px;
`;

const NameAndTaxWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  grid-gap: 20px;
`;

const ImageWrapper = styled.div`
  display: grid;
  grid-template: 100px / 150px auto 1fr;
  grid-gap: 48px;
  align-items: center;
  //height: 100px;
  margin-top: 40px;
  height: 150px;
`;

const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 12px;
`;

const FieldName = styled.div`
  // width: 200px;
`;

const FieldPrice = styled(FieldName)``;

const TaxCategoryWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 20px;
  align-items: center;
  grid-gap: 10px;
  justify-content: center;
`;

const ComboInformationForm = ({
  initValues,
  handleComboInformationSubmit,
  submitBasicInformationIsRequesting,
  combosList,
  taxCategoryList,
  showTax,
}) => {
  const [mediaSelectionModalIsVisible, setMediaSelectionModalIsVisible] =
    useState(false);

  const initialValues = {
    name: initValues.name,
    label: initValues.label,
    tax_category_id: initValues.tax_category_id,
    user_description: initValues.user_description,
    fixed_price: initValues.fixed_price,
    image_url: initValues.image_url,
    discount_type: 'dollar',
    discount_amount: null,
    is_autodetect: false,
    priority: 0,
    is_visible: true,
  };

  return (
    <Wrapper>
      <Formik initialValues={initialValues} enableReinitialize={true}>
        {({
          values,
          isValid,
          handleReset,
          dirty,
          errors,
          setFieldValue,
          touched,
        }) => (
          <Fragment>
            <FormWrapper>
              <NameAndTaxWrapper>
                <FieldName>
                  <Field
                    name='name'
                    component={RenderTextField}
                    label='Combo Name *'
                    validate={bulkValidator(
                      required,
                      maxLengthValidationForNameField
                    )}
                  />
                </FieldName>
                <FieldName>
                  <Field
                    name='label'
                    component={RenderTextField}
                    label='Combo Label'
                    // validate={bulkValidator(
                    //   required,
                    //   maxLengthValidationForNameField
                    // )}
                  />
                </FieldName>
                {showTax && (
                  <TaxCategoryWrapper>
                    <Field
                      component={RenderTextField}
                      style={{ position: 'relative', top: '-3px' }}
                      type='text'
                      name='tax_category_id'
                      label='Tax Rate Product Type *'
                      select
                      variant='outlined'
                      margin='normal'
                      validate={required}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      {taxCategoryList.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Field>
                    <Tooltip
                      interactive
                      title={
                        <Fragment>
                          For more information regarding the types of taxable
                          supplies &nbsp;
                          <a
                            rel='shortcut icon'
                            target='_blank'
                            style={{
                              color: 'white',
                              textDecoration: 'underline',
                            }}
                            href='https://www.canada.ca/en/revenue-agency/services/tax/businesses/topics/gst-hst-businesses/charge-collect-which-rate.html'
                          >
                            go to the CRA website
                          </a>
                        </Fragment>
                      }
                    >
                      <div style={{ cursor: 'pointer' }}>
                        <i
                          className='fal fa-info-circle'
                          style={{ fontSize: '16px' }}
                        ></i>
                      </div>
                    </Tooltip>
                  </TaxCategoryWrapper>
                )}
              </NameAndTaxWrapper>
              <Field
                name='user_description'
                component={RenderTextField}
                rows={4}
                label='Description *'
                validate={required}
              />
              <ChooseImage
                imageUrl={values.image_url}
                setIsImageGallaryVisible={setMediaSelectionModalIsVisible}
              />
              <FieldPrice>
                <Field
                  name='fixed_price'
                  component={RenderTextField}
                  label='Price *'
                  validate={isPriceValidation}
                />
              </FieldPrice>
              <ButtonRow
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  padding: '10px 20px 48px 48px',
                }}
              >
                <Button
                  onClick={() =>
                    handleComboInformationSubmit(initValues, values, combosList)
                  }
                  disabled={
                    Object.keys(errors).length ||
                    submitBasicInformationIsRequesting ||
                    !values.image_url ||
                    (showTax && !values?.tax_category_id)
                  }
                >
                  Save
                </Button>
              </ButtonRow>
              {mediaSelectionModalIsVisible && (
                <MediaModalContainer
                  closeModal={() => setMediaSelectionModalIsVisible(false)}
                  selectProduct={(id, url) => {
                    setFieldValue('image_url', url);
                  }}
                />
              )}
            </FormWrapper>
          </Fragment>
        )}
      </Formik>
    </Wrapper>
  );
};

export default ComboInformationForm;
