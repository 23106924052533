import React, { Component } from 'react';
import { HorizontalBar } from 'react-chartjs-2';
import styled from 'styled-components';
import {
  NoData,
  ChartCard,
  TitleWrapper,
  Title,
  TotalWrapper,
  TitleSpan,
} from '../../components/DashboardElements';
import Tooltip from '@material-ui/core/Tooltip';
import { TooltipIcon } from '../../../../components/Icon';
const MessageWrapper = styled.div`
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Message = styled.span`
  font-size: 34px;
  opacity: 0.2;
  text-align: center;
  font-weight: bold;
`;
class Sales extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { locations, order_ahead, scan_to_pay } = this.props.data;
    const label = locations;
    const orderAhead = order_ahead;
    const scanToPay = scan_to_pay;
    const barThickness = label.length < 7 ? 35 : 15;

    const data = {
      labels: label,
      datasets: [
        {
          label: 'Order Ahead',
          backgroundColor: '#296bde',
          borderColor: '#296bde',
          borderWidth: 1,
          //hoverBackgroundColor: 'rgba(75,192,192,0.4)',
          hoverBorderColor: '#296bde',
          data: orderAhead,
        },
        {
          label: 'Scan To Pay',
          backgroundColor: '#00a5db',
          borderColor: '#00a5db',
          borderWidth: 1,
          //hoverBackgroundColor: 'rgba(75,192,192,0.4)',
          hoverBorderColor: '#00a5db',
          data: scanToPay,
        },
      ],
    };

    return (
      <ChartCard>
        <TitleWrapper>
          <Title>
            <TitleSpan>
              Top Locations - Sales{' '}
              <Tooltip
                title="The total revenue generated in top locations"
                placement="top"
                arrow
              >
                <span>
                  {' '}
                  <TooltipIcon />{' '}
                </span>
              </Tooltip>
            </TitleSpan>{' '}
            <br />
            <svg height="10" width="17.7">
              <line
                x1="0"
                y1="7"
                x2="17.7"
                y2="7"
                style={{ stroke: '#296bde', strokeWidth: '8' }}
              />
            </svg>{' '}
            <span style={{ color: '#296bde' }}> order ahead </span>
            &nbsp;
            <svg height="10" width="17.7">
              <line
                x1="0"
                y1="7"
                x2="17.7"
                y2="7"
                style={{ stroke: '#00a5db', strokeWidth: '8' }}
              />
            </svg>{' '}
            <span style={{ color: '#00a5db' }}> scan to pay </span>
          </Title>
        </TitleWrapper>
        {/* {(!label || !label.length) && <NoData>No Data available</NoData>} */}
        {!label || label.length == 0 ? (
          <MessageWrapper>
            <Message>No Data available</Message>
          </MessageWrapper>
        ) : (
          <HorizontalBar
            data={data}
            options={{
              maintainAspectRatio: true,
              responsive: true,
              legend: {
                display: false,
              },
              scales: {
                xAxes: [
                  {
                    display: true,
                    ticks: {
                      callback: function (value, index, values) {
                        return `$${value.toLocaleString()}`;
                      },
                      suggestedMin: 60000,
                      beginAtZero: false,
                      //suggestedMax: 80000
                      //stepSize: 1
                    },
                    scaleLabel: {
                      display: false,
                    },
                    stacked: true,
                  },
                ],
                yAxes: [
                  {
                    display: true,
                    scaleLabel: {
                      display: false,
                      //labelString: 'Date',
                    },
                    barThickness: barThickness,
                    stacked: true,
                  },
                ],
              },
            }}
          />
        )}
      </ChartCard>
    );
  }
}

export default Sales;
