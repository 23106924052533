import React, { Component } from 'react';
import DeliveryZonesEdit from './components/DeliveryZonesEdit';
import { connect } from 'react-redux';
import { submit } from 'redux-form';
import * as actions from '../actions';
import BreadCrumb from '../../../../../../components/BreadCrumb/BreadCrumb';
import { LoadingBlueLarge } from 'merchant-portal-components';
import { resetBusinessLocationDetailsReducerState } from '../../../actions';

class DeliveryZonesEditContainer extends Component {
  componentDidMount() {
    if (
      !Object.keys(this.props.businessLocationDeliveryZones.locationCoords)
        .length
    ) {
      this.props.resetBusinessLocationDetailsReducerState();
      this.props.resetDeliveryZoneReducerState();
      this.props.history.push(`/locations`);
    } else {
      this.props.getDeliveryZoneObjects(
        this.props.match.params.businessLocationId,
        this.props.match.params.deliveryZoneId
      );
    }
  }

  handleDeliveryZoneVisibility = (deliveryZoneObject, event) => {
    deliveryZoneObject.isVisible = event.target.checked;
    this.props.updateDeliveryZoneObject(deliveryZoneObject);
  };

  handleEditDeliveryZoneFormSubmit = (deliveryZoneFormValues) => {
    const { editOverlayObj } = this.props;
    this.validatValues(deliveryZoneFormValues, editOverlayObj)
      .then((deliveryZoneInfoObject) => {
        this.props.patchDeliveryZoneByBusinessLocationId(
          this.props.match.params.businessLocationId,
          deliveryZoneInfoObject
        );
      })
      .catch((error) => {
        this.props.alertError(error.message);
      });
  };

  validatValues = (deliveryZoneFormValues, editDeliveryZone) => {
    return new Promise((resolve, reject) => {
      if (deliveryZoneFormValues.zoneName == '') {
        reject({ message: "Delivery Zone Name can't be empty" });
      } else if (deliveryZoneFormValues.zoneFee == '') {
        reject({ message: "Delivery Zone Fee can't be empty" });
      } else if (!Object.keys(editDeliveryZone).length) {
        reject({
          message: 'Must draw a Delivery Zone to save edit',
        });
      } else
        resolve({
          deliveryZoneFormValues,
          editDeliveryZone,
        });
    });
  };

  handleSaveChangesButtonClick = () => {
    this.handleEditDeliveryZoneFormSubmit(
      this.props.form.editDeliveryZoneForm.values
    );
    // this.props.dispatch(submit('editDeliveryZoneForm'));
  };

  handleCancelButtonClick = () => {
    this.props.makeDeliveryZoneNotEditableById(
      this.props.match.params.businessLocationId
    );
    this.props.history.push(`/locations/`);
  };

  render() {
    const {
      shouldComponentBeLoading,
      deliveryZoneObjects,
      editingColour,
      locationCoords,
    } = this.props.businessLocationDeliveryZones;
    if (shouldComponentBeLoading || !deliveryZoneObjects) {
      return <LoadingBlueLarge />;
    }
    const pageName = 'Edit Delivery Zone';
    return (
      <div>
        <BreadCrumb pageName={pageName} />
        <DeliveryZonesEdit
          deliveryZoneObjects={deliveryZoneObjects}
          editingColour={editingColour}
          handleDeliveryZoneVisibility={this.handleDeliveryZoneVisibility}
          handleSaveChangesButtonClick={this.handleSaveChangesButtonClick}
          handleEditDeliveryZoneFormSubmit={
            this.handleEditDeliveryZoneFormSubmit
          }
          handleCancelButtonClick={this.handleCancelButtonClick}
          locationCoords={locationCoords}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    businessLocationDeliveryZones: state.businessLocationDeliveryZones,
    editOverlayObj: state.googleMapsWithOverlayZones.editOverlayObj,
    form: state.form,
  };
};

export default connect(mapStateToProps, {
  ...actions,
  resetBusinessLocationDetailsReducerState,
})(DeliveryZonesEditContainer);
