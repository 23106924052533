import React, {Fragment} from 'react';
import styled from 'styled-components';

const Title = styled.p`
  font-weight: 600;
`;
const TitleContainer = styled.div`
  padding-bottom: 10px;
`;
const Content = styled.p``;
const NotesContainer = styled.div``;

/**
 * Return static notes
 * @returns {JSX.Element} - Rendered component
 */
const CustomerNotes = () => {
  return (
    <NotesContainer>
      <TitleContainer>
        <Title>Find, Manage, and Edit Customers</Title>
      </TitleContainer>
      <Content>
        Search by Customer Name, Email, Phone-number, Company Name or browse the list to find
        a Customer. Click the Edit icon to go to that Customer's Detail Page,
        where you can edit Customer info, send rewards and/or credits.
      </Content>
    </NotesContainer>
  );
};

export default CustomerNotes;
