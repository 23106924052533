import React from 'react';
import styled from 'styled-components';

const InfoIcon = styled.i`
  font-size: ${props => props.size || '13px'};
  cursor: default;
  color: ${props => (props.red ? props.theme.red : '#191818')};
  margin-left: ${props => (props.marginLeft ? '5px' : 'inherit')};
`;

const TooltipIcon = props => {
  return <InfoIcon {...props} className="fal fa-info-circle" />;
};

export default TooltipIcon;
