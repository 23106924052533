import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from '../../../../../components/Button/Button';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const Wrapper = styled.div`
  display: grid;
  grid-gap: 10px;
`;

const Title = styled.div`
  font-size: 22.5px;
  font-weight: 500;
  letter-spacing: 0.02px;
  line-height: 1.45;
`;

const Description = styled.div`
  font-size: 16.5px;
  line-height: 1.45;
  letter-spacing: 0.02px;
`;
const Disclaimer = styled.div`
  font-size: 13.5px;
  margin-top: -10px;
  line-height: 1.44;
  letter-spacing: 0.02px;
`;

const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const PersonalizationModal = ({ insertPersonalization, tokens, setIsOpen }) => {
  const classes = useStyles();
  const [personalization, setPersonalization] = useState(
    Object.keys(tokens)[0]
  );
  const handleChange = (event) => {
    setPersonalization(event.target.value);
  };
  const insertPersonalizationHandler = (e) => {
    setIsOpen(false);
    insertPersonalization(personalization);
    e.stopPropagation();
  };

  return (
    <Wrapper>
      <Title>Insert Personalization</Title>
      <Description>
        Adding personalization allows you to customize your message for each
        customer.
      </Description>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel htmlFor="outlined-age-native-simple">
          Select Personalization
        </InputLabel>
        <Select
          value={personalization}
          onChange={handleChange}
          label="Select Personalization"
          inputProps={{
            name: 'personalization',
            id: 'outlined-age-native-simple',
          }}
        >
          tokens
          {Object.keys(tokens).map((key, i) => (
            <MenuItem key={i} value={key}>
              {tokens[key]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Disclaimer>
        If the customer does not have a value for this property it will be left
        blank.
      </Disclaimer>
      <ButtonWrapper>
        <Button
          secondary
          style={{ fontSize: '16px', minWidth: '0' }}
          onClick={(e) => {
            setIsOpen(false);
            e.stopPropagation();
          }}
        >
          Cancel
        </Button>
        <Button
          style={{ fontSize: '16px', minWidth: '0' }}
          onClick={insertPersonalizationHandler}
        >
          Insert
        </Button>
      </ButtonWrapper>
    </Wrapper>
  );
};

export default PersonalizationModal;
