import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Formik, Field } from 'formik';
import { Modal } from 'merchant-portal-components';
import { Button } from 'merchant-portal-components';
import { Title, ModalBody } from 'merchant-portal-components';
import TextField from '@material-ui/core/TextField';
import SingleDatePicker from '../../../../../components/FormElements/SingleDatePicker';
import { RenderTextField } from '../../../../../components/FormElements/FormikElements';
import PhoneNumber from '../../../../../components/PhoneNumber';

const FormGrid = styled.div`
  display: grid;
  grid-gap: 15px;
`;

const CustomerFieldRow = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  padding: 10px 0 20px 0;
`;
const PhoneNumberText = styled.div`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  input[type='number'] {
    -moz-appearance: textfield;
    height: 45px;
  }
`;
const StyledP = styled.p``;

const CustomerFieldWrapper = styled.div`
  min-width: 80px;
`;

const HorizontalLine = styled.hr`
  width: 100%;
  border: 0;
  border-top: 2px solid #d8d8d8;
  opacity: 0.4;
`;

const ContentWrapper = styled.div`
  padding: 0 150px;
`;

const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  padding: 20px 0 0 0;
`;

/**
 * Return the 'Required' error message if value is empty
 * @param {String} value - form value to validate
 * @returns {String} - Error message (or nothing if no errors)
 */

let AccountInformationModal = (props) => {
  const {
    formValues,
    handleAccountInformationSubmit,
    accountInformationEditModalToggleVisibility,
    patchAccountInformationIsRequesting,
  } = props;

  const prepareDataToPatch = (values, initialValues) => {
    let data = {};

    if (values.companyName !== initialValues.companyName) {
      data = {
        ...data,
        detail: { company_name: values.companyName },
      };
    }

    if (values.firstName !== initialValues.firstName) {
      data = {
        ...data,
        first_name: values.firstName,
      };
    }

    if (values.lastName !== initialValues.lastName) {
      data = {
        ...data,
        last_name: values.lastName,
      };
    }

    if (values.email !== initialValues.email) {
      data = {
        ...data,
        email: values.email,
      };
    }

    if (values.mobilePhone !== initialValues.mobilePhone) {
      data = {
        ...data,
        detail: {
          ...data.detail,
          mobile_phone: values.mobilePhone,
        },
      };
    }

    if (values.birthdate !== initialValues.birthdate) {
      data = {
        ...data,
        detail: {
          ...data.detail,
          birthdate: moment(values.birthdate).format('YYYY-MM-DD'),
        },
      };
    }

    return data;
  };

  const validateBirthdate = (birthdate) => {
    if (!moment(birthdate).isValid()) {
      return 'Not a valid date';
    }
    // else if (!moment(birthdate).isBefore(moment().subtract(13, 'years'))) {
    //   return 'The minimal required age is 13';
    // }
    return null;
  };

  return (
    <Modal data-test="component-Modal">
      <div className="modal">
        <ModalBody padding="30px 0 40px 0">
          <Title>Edit Customer Account Information</Title>
          <HorizontalLine />
          <ContentWrapper>
            <Formik
              initialValues={{
                companyName: props.customerDetails.detail.company_name,
                firstName: props.customerDetails.first_name,
                lastName: props.customerDetails.last_name,
                mobilePhone: props.customerDetails.detail.mobile_phone,
                email: props.customerDetails.email,
                birthdate: props.customerDetails.detail.birthdate
                  ? moment(props.customerDetails.detail.birthdate).format(
                      'MM/DD/YYYY'
                    )
                  : null,
              }}
              validate={(values) => {
                let errors = {};

                if (values.firstName.length > 20) {
                  errors.firstName = 'Must be 20 characters or less';
                }
                if (values.lastName.length > 20) {
                  errors.lastName = 'Must be 20 characters or less';
                }

                if (values.mobilePhone.length < 10) {
                  errors.mobilePhone = 'Must be a 10 digit phone number';
                }

                if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                    values.email
                  )
                ) {
                  errors.email = 'Must be a valid email';
                }

                // if (!moment(values.birthdate).isValid()) {
                //   errors.birthdate = 'Must digit a valid birthdate';
                // }

                return errors;
              }}
            >
              {({ initialValues, values, errors, setFieldValue }) => (
                <FormGrid>
                  <CustomerFieldRow>
                    <CustomerFieldWrapper>
                      <StyledP>Company Name</StyledP>
                      <Field
                        name="companyName"
                        variant="outlined"
                        component={RenderTextField}
                      />
                    </CustomerFieldWrapper>
                  </CustomerFieldRow>
                  <CustomerFieldRow>
                    <CustomerFieldWrapper>
                      <StyledP>*Customer First Name</StyledP>
                      <Field
                        name="firstName"
                        variant="outlined"
                        component={RenderTextField}
                      />
                    </CustomerFieldWrapper>
                    <CustomerFieldWrapper>
                      <StyledP>*Customer Last Name</StyledP>
                      <Field
                        name="lastName"
                        variant="outlined"
                        component={RenderTextField}
                      />
                    </CustomerFieldWrapper>
                  </CustomerFieldRow>
                  <CustomerFieldRow>
                    <CustomerFieldWrapper>
                      <StyledP>*Customer Phone Number</StyledP>
                      <PhoneNumberText>
                        <PhoneNumber
                          name="mobilePhone"
                          id="phoneOnCustomer"
                          value={values.mobilePhone}
                          variant="outlined"
                          defaultPhoneValue={values.mobilePhone}
                          handlePhone={(value) =>
                            setFieldValue('mobilePhone', value)
                          }
                        />
                      </PhoneNumberText>
                    </CustomerFieldWrapper>
                    <CustomerFieldWrapper>
                      <StyledP>*Customer Email</StyledP>
                      <Field
                        name="email"
                        variant="outlined"
                        component={RenderTextField}
                      />
                    </CustomerFieldWrapper>
                  </CustomerFieldRow>
                  <CustomerFieldRow>
                    <CustomerFieldWrapper>
                      <StyledP>Customer Birthdate</StyledP>
                      <Field
                        name="birthdate"
                        value={initialValues.birthdate}
                        component={SingleDatePicker}
                        error={
                          initialValues.birthdate
                            ? validateBirthdate(values.birthdate)
                            : null
                        }
                      />
                    </CustomerFieldWrapper>
                  </CustomerFieldRow>
                  <ButtonWrapper>
                    <Button
                      secondary
                      onClick={accountInformationEditModalToggleVisibility}
                    >
                      Cancel
                    </Button>
                    <Button
                      disabled={
                        patchAccountInformationIsRequesting ||
                        (values &&
                          (!values.firstName ||
                            !values.lastName ||
                            values.mobilePhone.length !== 10)) ||
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                          values.email
                        ) ||
                        ((values.birthdate ||
                          initialValues.birthdate !== null) &&
                          validateBirthdate(values.birthdate))
                      }
                      onClick={() => {
                        let data = prepareDataToPatch(values, initialValues);
                        handleAccountInformationSubmit(data);
                      }}
                    >
                      {patchAccountInformationIsRequesting
                        ? 'Saving...'
                        : 'Save'}
                    </Button>
                  </ButtonWrapper>
                </FormGrid>
              )}
            </Formik>
          </ContentWrapper>
        </ModalBody>
      </div>
    </Modal>
  );
};

export default AccountInformationModal;
