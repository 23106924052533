import React, { useEffect, Suspense, lazy, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { Dialog } from '@material-ui/core';
import FilterModal from '../../../components/FilterModal/FilterModal';
import { formatLocations } from '../../../utils/childLocations/formatLocations';
import * as actions from '../ReportTableActions.js';
import ReportSearch from '../components/ReportSearch';
import { getBusinessLocationsOfMerchant } from '../../BusinessLocationDetails/LocationListManager/actions.js';
import LocationFilterModal from '../../../components/FilterModal/LocationFilterModal';
const GenericTable = lazy(() =>
  import('../../../components/Table/GenericTable')
);

function CouponReportContainer({
  allCoupons,
  allBusinesses,
  requestParams,
  getCouponReport,
  setExportingData,
  setExportingHeaders,
  setExportingFileName,
  clearCouponReport,
  updateReportsRequestParams,
  getBusinessLocationsOfMerchant,
  setMessage,
  resetAllReports,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  selectedBusinessesIDs,
  setSelectedBusinessesIDs,
  filteredLocations,
  setFilteredLocations,
}) {
  const [isLocationFilterOpen, setIsLocationFilterOpen] = useState(false);
  const columnHeaders = [
    'Coupon Name',
    'Coupon Code',
    'Coupon Point Value',
    'Coupon Dollar Value',
    'Coupon Status',
    'No. of Customers Redeemed',
    'No of Redemptions',
  ];
  const columnNames = [
    'coupon_name',
    'coupon_code',
    'coupon_point_value',
    'coupon_dollar_value',
    'coupon_status',
    'num_customers_redeemed',
    'num_redemptions',
  ];
  const columnTypes = [
    'string',
    'string',
    'string',
    'money',
    'string',
    'string',
    'string',
  ];
  const columnWidth = [
    '170px',
    '170px',
    '210px',
    '220px',
    '170px',
    '280px',
    '250px',
  ];
  const columnHeaderIsSortableList = [true, true, true, true, true, true, true];
  const updateSelectedBusiness = useCallback(() => {
    if (filteredLocations) {
      setSelectedBusinessesIDs(filteredLocations);
    }
  }, [filteredLocations]);
  useEffect(() => {
    updateSelectedBusiness();
  }, [updateSelectedBusiness]);
  const getExportHeaders = (headers, fields) => {
    const exportedHeaders = [];
    headers.forEach((header, index) =>
      exportedHeaders.push({ label: header, key: fields[index] })
    );
    return exportedHeaders;
  };
  useEffect(() => {
    getBusinessLocationsOfMerchant();
    setExportingFileName('coupon_report');
    setExportingHeaders(getExportHeaders(columnHeaders, columnNames));
    setMessage('Select a date range and location to generate a report');
    return () => {
      clearCouponReport();
      setExportingData([]);
      updateReportsRequestParams({
        page_number: 1,
        page_size: 25,
      });
      setMessage('Select a date range and location to generate a report');
      resetAllReports();
      setFilteredLocations([]);
      setSelectedBusinessesIDs([]);
    };
  }, []);
  useEffect(() => {
    if (allCoupons?.length === 0) {
      setExportingData([]);
    }
  }, [allCoupons]);
  return (
    <>
      <ReportSearch
        allBusinesses={allBusinesses}
        generateReport={getCouponReport}
        haslocationFiltering
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        selectedBusinessesIDs={selectedBusinessesIDs}
        setSelectedBusinessesIDs={setSelectedBusinessesIDs}
        setIsLocationFilterOpen={setIsLocationFilterOpen}
        setMessage={setMessage}
      />
      {allCoupons?.length > 0 && (
        <div style={{ marginTop: '30px' }}>
          <Suspense fallback={<div>Loading...</div>}>
            <GenericTable
              dataList={allCoupons}
              columnHeaders={columnHeaders}
              columnHeaderIsSortableList={columnHeaderIsSortableList}
              columnNames={columnNames}
              columnWidth={columnWidth}
              columnTypes={columnTypes}
              placeholder=''
              requestParams={requestParams}
              requestParamsType={'couponReport'}
              handleClickRow={null}
              handleCheckboxSelect={null}
              checkboxList={[]}
              setCheckboxList={null}
              setExportingData={() => setExportingData(allCoupons)}
            />
          </Suspense>
        </div>
      )}
      {isLocationFilterOpen && (
        <LocationFilterModal
          placeholder='Enter Internal Location Name'
          searchLabel='Search by Internal Location Name'
          filteredLocations={filteredLocations}
          setFilteredLocations={setFilteredLocations}
          isOpen={isLocationFilterOpen}
          setIsOpen={setIsLocationFilterOpen}
          emptyIsEqualToAll={true}
          isEmptyAccepted={false}
        />
      )}
    </>
  );
}
function mapStateToProps(state) {
  return {
    allCoupons: state.ReportTableReducer.allCoupons,
    requestParams: state.ReportTableReducer.requestParams,
    allBusinesses: state.businessLocationsList.businessLocationsOfMerchant,
  };
}
export default connect(mapStateToProps, {
  ...actions,
  getBusinessLocationsOfMerchant,
})(CouponReportContainer);
