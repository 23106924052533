import {
    EIGHT_CHARACTERS_MIN_MATCH,
    UPPER_LOWER_CHARACTER_MATCH,
    DIGIT_SYMBOL_MATCH,
    PASSWORD_MATCH_RETYPED
} from './types';

export function escapeRegExp(password) {
    return password.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

export function passwordMatch(password, confirmPassword) {
    return function (dispatch) {
        const typedPasswordsMatch = password ? password.length > 0 && confirmPassword === password : false;
        dispatch({ type: PASSWORD_MATCH_RETYPED, typedPasswordsMatch });
    }
};

export function passwordHasEightDigits(password) {
    return function (dispatch) {
        const hasEightDigits = password ? password.length >= 8 : false;
        dispatch({ type: EIGHT_CHARACTERS_MIN_MATCH, hasEightDigits });
    }
};

export function passwordHasUpperAndLowerCharacter(password) {
    return function (dispatch) {
        const hasUpperAndLower = /^(?=.*[A-Z])^(?=.*[a-z])/.test(password)
        dispatch({ type: UPPER_LOWER_CHARACTER_MATCH, hasUpperAndLower });
    }
};

export function passwordHasDigitOrSymbol(password) {
    return function (dispatch) {
        const hasDigitOrSymbol = /[0-9*@!#%&()^~{}]+/.test(password)
        dispatch({ type: DIGIT_SYMBOL_MATCH, hasDigitOrSymbol })

    }
};