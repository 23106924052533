import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import * as actions from '../../../productsActions';
import ProductTagsForm from '../components/ProductTagsForm';
import {selectProductTags} from '../../../productsSelectors';

const ProductTagsFormContainer = props => {
  const [isExpanded, setIsExpanded] = useState(false);

  const [
    confirmCancelModalIsVisible,
    setConfirmCancelModalIsVisible
  ] = useState(false);

  return (
    <ProductTagsForm
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}
      disabled={!props.productId}
      initialValues={{productTags: props.productTagsInitialValues}}
      confirmCancelModalIsVisible={confirmCancelModalIsVisible}
      setConfirmCancelModalIsVisible={setConfirmCancelModalIsVisible}
      allProductTags={props.allProductTags}
      putProductTags={props.putProductTags}
    />
  );
};

const mapStateToProps = state => ({
  productId: state.productsReducer.productId,
  productTagsInitialValues: state.productsReducer.productTagsInitialValues,
  allProductTags: selectProductTags(state)
});

export default connect(
  mapStateToProps,
  actions
)(ProductTagsFormContainer);
