import React, { Fragment } from 'react';
import styled from 'styled-components';

const BalanceWrapper = styled.div`
  display: grid;
  grid-template-columns: 4fr 1fr;
  grid-gap: 10px;
  padding: 5px;
`;

const BalanceName = styled.div`
  font-size: 14px;
`;

const BalanceValue = styled.div`
  font-weight: 600;
  font-size: 14px;
  min-width: 120px;
`;

const CustomerLoyaltyRewardsTier = (props) => {
  const { loyaltyTier } = props;
  return (
    <Fragment>
      <BalanceWrapper>
        <BalanceName data-test='label-currentLoyaltyTier'>
          Current Loyalty Tier
        </BalanceName>
        <BalanceValue data-test='value-currentLoyaltyTier'>
          {loyaltyTier.name}
        </BalanceValue>
      </BalanceWrapper>
      <BalanceWrapper>
        <BalanceName data-test='label-pointsToNextTier'>
          Points to Next Tier
        </BalanceName>
        <BalanceValue data-test='value-pointsToNextTier'>
          {loyaltyTier.points_to_next_tier} pts
        </BalanceValue>
      </BalanceWrapper>
      <BalanceWrapper>
        <BalanceName data-test='label-pointsToNextTier'>
          Progress to Next Tier
        </BalanceName>
        <BalanceValue data-test='value-pointsToNextTier'>
          {loyaltyTier.progress_to_next_tier}%
        </BalanceValue>
      </BalanceWrapper>
      {/* <BalanceWrapper>
        <BalanceName data-test='label-pointsToNextTier'>
          Quantity to Next Tier
        </BalanceName>
        <BalanceValue data-test='value-pointsToNextTier'>
          {loyaltyTier.quantity_to_next_tier}
        </BalanceValue>
      </BalanceWrapper> */}
      {/* <BalanceWrapper>
        <BalanceName data-test='label-pointsToNextTier'>
          Progress/Quantity to Next Tier
        </BalanceName>
        <BalanceValue data-test='value-pointsToNextTier'>
          {loyaltyTier.progress_quantity_to_next_tier}
        </BalanceValue>
      </BalanceWrapper> */}
    </Fragment>
  );
};

export default CustomerLoyaltyRewardsTier;
