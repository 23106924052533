import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { ALL_ORDER_TYPE_ID } from '../../../productsTypes';
import * as actions from '../../../productsActions';
import ProductInformationForm from '../components/ProductInformationForm';
import moment from 'moment';
import { productAvailableAllTime } from '../../../../utils/helper.js';
import { isProductAvailableAllTimeFunction } from '../../../../utils/helper.js';
import { hoursFormatter } from '../../../../utils/helper.js';

const TIME_FORMAT = 'HH:mm:ss';

const ProductInformationFormContainer = ({
  productsReducer,
  createProduct,
  patchProduct,
  resetProductReducerState,
  productPreviewIsVisible,
  setProductPreviewIsVisible,
  programType,
  getProductInformation,
  getTaxCategoryList,
  orderTypesList,
  taxCategoryList,
  accountReducer,
  getProductAvailabilities,
  productAvailabilities,
}) => {
  const {
    productInformationInitialValues,
    productId,
    createProductIsRequesting,
    patchProductIsRequesting,
    productBusinessesInitialValues,
    productInformation,
  } = productsReducer;
  const productInformationEdit = productInformation;
  const [mediaSelectionModalIsVisible, setMediaSelectionModalIsVisible] =
    useState(false);

  const [isAvailableAllDay, setIsAvailableAllDay] = useState(true);

  useEffect(() => {
    if (productId) {
      getProductInformation(productId);
      getProductAvailabilities(productId);
      setIsAvailableAllDay(
        productInformationInitialValues.start_time == '00:00:00' &&
          productInformationInitialValues.end_time == '23:59:59'
      );
    }
  }, []); //empty array means the callback will only be fired once, similar to componentDidMount
  const buildOrderTypePayload = (productInformation) => {
    return productInformation.orderTypes;
  };

  const handleSubmit = ({
    productInformation,
    productBusinesses,
    productAvailabilities,
  }) => {
    const orderTypePayload = buildOrderTypePayload(productInformation);
    delete productInformation.orderTypes;

    const productInformationWithFormattedTimes = {
      ...productInformation,
      start_time: isAvailableAllDay
        ? '00:00:00'
        : moment(productInformation.start_time).format(TIME_FORMAT),
      end_time: isAvailableAllDay
        ? '23:59:59'
        : moment(productInformation.end_time).format(TIME_FORMAT),
      is_active:
        productInformationEdit.is_pos_mapping_required &&
        !productInformationEdit.is_pos_mapped
          ? false
          : productInformationEdit.is_active,
    };
    if (productId) {
      patchProduct(
        {
          productInformationWithFormattedTimes,
          productBusinesses,
          productAvailabilities,
        },
        productId
      );
    } else {
      createProduct(
        productInformationWithFormattedTimes,
        productAvailabilities
      );
    }
  };
  const showTaxCategory =
    accountReducer.appMetaData.configuration.smoothpay_integration_enabled;
  const initialValues = {
    productInformation: {
      ...productInformationInitialValues,
      start_time: moment(
        productInformationInitialValues.start_time,
        TIME_FORMAT
      ),
      end_time: moment(productInformationInitialValues.end_time, TIME_FORMAT),
      start_datetime: !productInformationInitialValues.start_datetime
        ? moment(new Date())
            .set({
              hour: 0,
              minute: 0,
              second: 0,
            })
            .format('YYYY-MM-DD HH:mm')
        : moment(productInformationInitialValues.start_datetime, TIME_FORMAT),

      end_datetime: !productInformationInitialValues.end_datetime
        ? null
        : moment(productInformationInitialValues.end_datetime, TIME_FORMAT),

      id: productInformation.id,
      is_points_enabled: productInformationInitialValues.is_points_enabled,
    },
    productIsProductAvailableAllTime: isProductAvailableAllTimeFunction(
      productAvailabilities
    ),
    productAvailabilities: hoursFormatter(
      productAvailabilities,
      productInformation
    ),
  };
  return (
    <ProductInformationForm
      initialValues={{
        productInformation: {
          ...productInformationInitialValues,
          start_time: moment(
            productInformationInitialValues.start_time,
            TIME_FORMAT
          ),
          end_time: moment(
            productInformationInitialValues.end_time,
            TIME_FORMAT
          ),
          start_datetime: !productInformationInitialValues.start_datetime
            ? moment(new Date())
                .set({
                  hour: 0,
                  minute: 0,
                  second: 0,
                })
                .format('YYYY-MM-DD HH:mm:ss')
            : moment(
                productInformationInitialValues.start_datetime,
                'YYYY-MM-DD HH:mm:ss'
              ).format('YYYY-MM-DD HH:mm:ss'),
          end_datetime: productInformationInitialValues.end_datetime
            ? moment(
                productInformationInitialValues.end_datetime,
                'YYYY-MM-DD HH:mm:ss'
              ).format('YYYY-MM-DD HH:mm:ss')
            : null,

          id: productInformation.id,
          is_points_enabled: productInformationInitialValues.is_points_enabled,
        },
        productIsProductAvailableAllTime: isProductAvailableAllTimeFunction(
          productAvailabilities
        ),
        productAvailabilities: hoursFormatter(
          productAvailabilities,
          productInformation
        ),
      }}
      productAvailabilities={productAvailabilities}
      showTaxCategory={showTaxCategory}
      isAvailableAllDay={isAvailableAllDay}
      setIsAvailableAllDay={(boolean) => setIsAvailableAllDay(boolean)}
      mediaSelectionModalIsVisible={mediaSelectionModalIsVisible}
      setMediaSelectionModalIsVisible={setMediaSelectionModalIsVisible}
      isRequesting={patchProductIsRequesting || createProductIsRequesting}
      handleCancelClick={resetProductReducerState}
      handleSubmit={handleSubmit}
      productPreviewIsVisible={productPreviewIsVisible}
      setProductPreviewIsVisible={setProductPreviewIsVisible}
      productPreviewIsDisabled={
        !productsReducer.productVariantsInitialValues.length
      }
      programType={programType}
      orderTypesList={[
        { value: ALL_ORDER_TYPE_ID, label: 'All Order Types' },
        ...orderTypesList.map((orderType) => ({
          value: orderType.id,
          label: orderType.type,
        })),
      ]}
      taxCategoryList={taxCategoryList.map((taxCategory) => ({
        value: taxCategory.tax_category_id,
        label: taxCategory.description,
      }))}
      productId={productId}
    />
  );
};

const mapStateToProps = (state) => ({
  productsReducer: state.productsReducer,
  programType: state.accountReducer.appMetaData.configuration.program_type,
  taxCategoryList: state.fullReducer.taxCategoryList,
  orderTypesList: state.fullReducer.orderTypesList,
  accountReducer: state.accountReducer,
  productAvailabilities: state.productsReducer.productAvailabilities,
});

export default connect(
  mapStateToProps,
  actions
)(ProductInformationFormContainer);
