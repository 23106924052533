import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from './actions';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Icon } from 'merchant-portal-components';

const styles = (theme) => ({
  success: {
    backgroundColor: '#3B953F',
  },
  error: {
    backgroundColor: '#CD2929',
    maxWidth: '1200px',
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Montserrat, sans-serif',
    whiteSpace: 'pre',
  },
});

function MySnackbarContent(props) {
  const { classes, className, message, onClose, variant, ...other } = props;
  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          {message}
        </span>
      }
      action={[
        <Icon
          key="alert"
          aria-label="Close"
          className="fal fa-times"
          onClick={onClose}
        />,
      ]}
      {...other}
    />
  );
}

const MySnackbarContentWrapper = withStyles(styles)(MySnackbarContent);

class Alert extends Component {
  state = {
    open: false,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.alert !== this.props.alert && this.props.alert) {
      this.handleClick();
    }
  }

  handleClick = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={this.state.open}
        autoHideDuration={this.props.alert.alertDuration || 5000}
        onClose={this.handleClose}
      >
        <MySnackbarContentWrapper
          onClose={this.handleClose}
          variant={this.props.alert.type}
          message={this.props.alert.message}
        />
      </Snackbar>
    );
  }
}

function mapStateToProps(state) {
  return {
    alert: state.alert,
  };
}

export default connect(mapStateToProps, actions)(Alert);
