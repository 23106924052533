export const INITIATE_CREATE_LAYOUT = 'INITIATE_CREATE_LAYOUT';
export const CREATE_APP_FEED_LAYOUT_REQUEST = 'CREATE_APP_FEED_LAYOUT_REQUEST';
export const CREATE_APP_FEED_LAYOUT_SUCCESS = 'CREATE_APP_FEED_LAYOUT_SUCCESS';
export const GET_LIST_APP_FEED_LAYOUT_SUCCESS =
  'GET_LIST_APP_FEED_LAYOUT_SUCCESS';
export const DELETE_APP_FEED_LAYOUT = 'DELETE_APP_FEED_LAYOUT';
export const APP_FEED_LAYOUT_SEARCH_RESULT = 'APP_FEED_LAYOUT_SEARCH_RESULT';
export const SET_DEFAULT_APP_FEED = 'SET_DEFAULT_APP_FEED';
export const UPDATE_DEFAULT_LAYOUT = 'UPDATE_DEFAULT_LAYOUT';
export const DUPLICATE_APP_FEED_LAYOUT = 'DUPLICATE_APP_FEED_LAYOUT';
export const SET_PAGE_TYPE = 'SET_PAGE_TYPE';
export const EDIT_FEED_LAYOUT = 'EDIT_FEED_LAYOUT';
export const UPDATE_APP_FEED_LAYOUT_SUCCESS = 'UPDATE_APP_FEED_LAYOUT_SUCCESS';
export const RESET_APP_FEED_LAYOUT_REDUCER = 'RESET_APP_FEED_LAYOUT_REDUCER';
