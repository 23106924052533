import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { LoadingBlue } from 'merchant-portal-components';
import {
  getCustomerViewLazyLoader,
  noCondition,
  getMaxPageNoLimit,
} from './CustomerListViewHelper';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 50px;

  @media (max-width: 1229px) {
    display: block;
  }
`;

const ListWrapper = styled.div`
  border: 1px solid #dbdbdb;
`;

const List = styled.div`
  height: 250px;
  overflow-x: scroll;
`;

const CustomerLine = styled.div`
  display: grid;
  grid-template-columns: 8fr 1fr;
  grid-gap: 5px;
  border-bottom: 1px solid #dbdbdb;
  line-height: 32px;
  padding: 2px 0 2px 15px;
  cursor: pointer;
  &:hover {
    background-color: ${(props) => props.theme.lightGray};
  }
`;

const CustomerName = styled.div`
  font-weight: 400;
  font-size: 13px;
  text-overflow: ellipsis;
  overflow: hidden;
  align-self: center;
  color: ${(props) => props.theme.black};
`;

const Input = styled.input`
  width: 100%;
  background-color: ${(props) => props.theme.lightGray};
  border: none;
  border-right: #e6e6e6 1px solid;
  border-bottom: #e6e6e6 1px solid;
  padding: 5px 15px;
  color: ${(props) => props.theme.darkBlue};
  font-weight: 600;
`;

const Title = styled.div`
  width: 100%;
  padding: 5px 15px;
  font-weight: 600;
  color: ${(props) => props.theme.white};
  background-color: ${(props) => props.theme.darkBlue};
`;

const InputAndButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
`;

const SelectionButton = styled.div`
  text-align: center;
  background-color: ${(props) => props.theme.darkBlue};
  padding-top: 6px;
  font-size: 14px;
  color: ${(props) => props.theme.white};
  font-weight: 600;
  cursor: pointer;
`;

const Sign = styled.div`
  align-self: center;
  font-weight: bold;
  color: ${(props) => props.theme.black};
`;

const TitleAndButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
`;

const CustomerSelect = (props) => {
  const {
    handleCustomerSearchChange,
    customersFromSelectedSegments,
    selectedRecipientsDetails,
    selectedCustomers,
    handleRemoveRecipient,
    handleAddAllRecipient,
    handleAddRecipient,
    handleRemoveAllRecipient,
    loading,
    requestingGetCustomerDetail,
    setIsEditingCustomers,
    queryString,
  } = props;

  return (
    <Wrapper>
      <ListWrapper>
        <InputAndButtonWrapper>
          <Input
            placeholder="Customer Search..."
            // value={queryString}
            onChange={(e) => {
              handleCustomerSearchChange(e.target.value);
              setIsEditingCustomers(true);
            }}
          />
          <SelectionButton
            onClick={() => handleAddAllRecipient(customersFromSelectedSegments)}
          >
            Add All
          </SelectionButton>
        </InputAndButtonWrapper>

        {loading ? (
          <div style={{ textAlign: 'center' }}>
            <LoadingBlue large />
          </div>
        ) : (
          <List>
            {customersFromSelectedSegments.map((customer, index) => (
              <CustomerLine
                key={index}
                onClick={() => handleAddRecipient(customer)}
              >
                <CustomerName>
                  {customer.first_name +
                    customer.last_name +
                    `(${customer.email})`}
                </CustomerName>
                <Sign>+</Sign>
              </CustomerLine>
            ))}
          </List>
        )}
      </ListWrapper>
      <ListWrapper>
        <TitleAndButtonWrapper>
          <Title>
            Recipient ({selectedRecipientsDetails.length} Recipient Selected)
          </Title>
          <SelectionButton onClick={() => handleRemoveAllRecipient()}>
            Remove All
          </SelectionButton>
        </TitleAndButtonWrapper>
        {requestingGetCustomerDetail ? (
          <div style={{ textAlign: 'center' }}>
            <LoadingBlue large />
          </div>
        ) : (
          <List>
            {selectedRecipientsDetails.map((customer, index) => (
              <CustomerLine
                key={index}
                onClick={() => handleRemoveRecipient(customer)}
              >
                {requestingGetCustomerDetail ? (
                  <LoadingBlue style={{ margin: '0', alignSelf: 'center' }} />
                ) : (
                  <CustomerName>
                    {customer.first_name +
                      customer.last_name +
                      `(${customer.email})`}
                  </CustomerName>
                )}
                <Sign>-</Sign>
              </CustomerLine>
            ))}
          </List>
        )}
      </ListWrapper>
    </Wrapper>
  );
};

export default CustomerSelect;
