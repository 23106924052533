import {
  ADD_OVERLAY_ZONE_OBJECTS,
  REMOVE_OVERLAY_ZONE_OBJECT,
  ADD_OVERLAY_TO_STORE,
  UPDATE_DRAWN_OVERLAY_OBJECTS,
  UPDATE_EDIT_OVERLAY_OBJECT,
  RESET_REDUX_STATES
} from './types';

export default function(
  state = {
    overlayZones: [],
    drawnOverlayZones: [],
    editOverlayObj: {}
  },
  action
) {
  switch (action.type) {
    case ADD_OVERLAY_ZONE_OBJECTS:
      return {...state, overlayZones: action.payload};
    case REMOVE_OVERLAY_ZONE_OBJECT:
      return {
        ...state,
        overlayZones: state.overlayZones.filter(
          overlay => overlay.id != action.payload
        )
      };
    case ADD_OVERLAY_TO_STORE:
      return {...state, drawnOverlayZones: action.payload};
    case UPDATE_DRAWN_OVERLAY_OBJECTS:
      return {...state, drawnOverlayZones: action.payload.slice(0)};
    case UPDATE_EDIT_OVERLAY_OBJECT:
      return {...state, editOverlayObj: action.payload};
    case RESET_REDUX_STATES:
      return {
        ...state,
        overlayZones: [],
        drawnOverlayZones: [],
        editOverlayObj: {}
      };
    default:
      return state;
  }
}
