import {
  REQUEST_REPORT_DATA,
  SET_REPORT_DATA,
  UPDATE_REPORT_INITIAL_PARAMS,
  CACHE_REPORT_DATA,
  SET_CACHE_DATA,
  RESET_REPORT_TABLE_REDUCER,
} from "./ReportTableTypes";
import Moment from "moment";

const defaultState = {
  //new
  allTransactions: null,
  allLoyalties: null,
  allProducts: null,
  allCombos: null,
  allLocationOrders: null,
  allCustomers: [],
  allOffers: [],
  allCoupons: [],
  requestParams: {
    page_number: 1,
    page_size: 25,
  },
  //
  isRequestingData: true,
  filters: null,
  rows: [],
  columns: [],
  columnNames: [],
  meta: null,
  initialParams: {
    page_number: 1,
    page_size: 10,
    export: false,
    start_datetime: `${Moment()
      .subtract(1, "days")
      .format("YYYY-MM-DD")} 00:00:00`,
    end_datetime: `${Moment().format("YYYY-MM-DD")} 23:59:59`,
  },
  cache: {},
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case "RESET_ALL_REPORTS":
      return {
        ...state,
        allTransactions: null,
        allLoyalties: null,
        allProducts: null,
        allCombos: null,
        allLocationOrders: null,
        allCustomers: [],
      };
    case "UPDATE_REPORTS_REQUEST_PARAMS":
      return {
        ...state,
        requestParams: action.params,
      };
    case "GET_ALL_TRANSACTION_REPORT_SUCCSESSFUL":
      return {
        ...state,
        allTransactions: action.allTransactions,
      };
    case "CLEAR_TRANSACTION_REPORT":
      return {
        ...state,
        allTransactions: [],
      };
    case "GET_ALL_OFFER_REPORT_SUCCSESSFUL":
      return {
        ...state,
        allOffers: action.allOffers,
      };
    case "CLEAR_OFFER_REPORT":
      return {
        ...state,
        allOffers: [],
      };
    case "GET_ALL_COUPON_REPORT_SUCCSESSFUL":
      return {
        ...state,
        allCoupons: action.allCoupons,
      };
    case "CLEAR_COUPON_REPORT":
      return {
        ...state,
        allCoupons: [],
      };
    case "GET_ALL_LOYALTY_REPORT_SUCCSESSFUL":
      return {
        ...state,
        allLoyalties: action.allLoyalties,
      };
    case "CLEAR_LOYALTY_REPORT":
      return {
        ...state,
        allLoyalties: [],
      };
    case "GET_ALL_PRODUCT_REPORT_SUCCSESSFUL":
      return {
        ...state,
        allProducts: action.allProducts,
      };
    case "CLEAR_PRODUCT_REPORT":
      return {
        ...state,
        allProducts: [],
      };
    case "GET_ALL_COMBO_REPORT_SUCCSESSFUL":
      return {
        ...state,
        allCombos: action.allCombos,
      };
    case "CLEAR_COMBO_REPORT":
      return {
        ...state,
        allCombos: [],
      };
    case "GET_ALL_LOCATION_REPORT_SUCCSESSFUL":
      return {
        ...state,
        allLocationOrders: action.allLocationOrders,
      };
    case "CLEAR_LOCATION_REPORT":
      return {
        ...state,
        allLocationOrders: [],
      };
    case "GET_ALL_CUSTOMER_REPORT_SUCCSESSFUL":
      return {
        ...state,
        allCustomers: action.allCustomers,
      };
    case "CLEAR_CUSTOMER_REPORT":
      return {
        ...state,
        allCustomers: [],
      };
    case REQUEST_REPORT_DATA:
      return {
        ...state,
        isRequestingData: true,
      };
    case SET_REPORT_DATA:
      return {
        ...state,
        isRequestingData: false,
        rows: action.payload ? action.payload.rows : [],
        columns: action.payload ? action.payload.columns : [],
        columnNames: action.payload ? action.payload.columnNames : [],
        meta: action.payload ? action.payload.meta : null,
        initialParams: action.payload.initialParams
          ? action.payload.initialParams
          : state.initialParams,
      };
    case UPDATE_REPORT_INITIAL_PARAMS:
      return {
        ...state,
        initialParams: {
          ...state.initialParams,
          ...action.params,
        },
      };
    case CACHE_REPORT_DATA:
      return {
        ...state,
        cache: {
          ...state.cache,
          [action.key]: action.payload,
        },
      };
    case SET_CACHE_DATA:
      return {
        ...state,
        cache: action.cache ? action.cache : state.cache,
      };
    case RESET_REPORT_TABLE_REDUCER:
      return defaultState;
    case "UPDATE_TRANSACTION_REPORT_REQUEST_PARAMS":
      return {
        ...state,
        requestParams: {
          ...state.requestParams,
          ...action.payload,
        },
      };
    case "UPDATE_LOYALTY_REPORT_REQUEST_PARAMS":
      return {
        ...state,
        requestParams: {
          ...state.requestParams,
          ...action.payload,
        },
      };
    case "UPDATE_PRODUCT_REPORT_REQUEST_PARAMS":
      return {
        ...state,
        requestParams: {
          ...state.requestParams,
          ...action.payload,
        },
      };
    case "UPDATE_COMBO_REPORT_REQUEST_PARAMS":
      return {
        ...state,
        requestParams: {
          ...state.requestParams,
          ...action.payload,
        },
      };
    case "UPDATE_LOCATION_REPORT_REQUEST_PARAMS":
      return {
        ...state,
        requestParams: {
          ...state.requestParams,
          ...action.payload,
        },
      };
    case "UPDATE_CUSTOMER_REPORT_REQUEST_PARAMS":
      return {
        ...state,
        requestParams: {
          ...state.requestParams,
          ...action.payload,
        },
      };
    case "UPDATE_OFFER_REPORT_REQUEST_PARAMS":
      return {
        ...state,
        requestParams: {
          ...state.requestParams,
          ...action.payload,
        },
      };
    case "UPDATE_COUPON_REPORT_REQUEST_PARAMS":
      return {
        ...state,
        requestParams: {
          ...state.requestParams,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};
