import { maxLengthValidationForNameField } from '../../../../components/FormElements/FormikElements';

const validateOptions = (options) => {
  let optionsErrors = [];
  // const parentOption = options.find(option => !option.parent_id);

  optionsErrors = [
    ...options.map((option) => {
      let validatedOption = {
        ...validateOptionFields(option, true),
      };
      if (option.items) {
        validatedOption = {
          ...validatedOption,
          items: option.items.map((item) => {
            if (!item.sub_items) {
              let validatedItem = {
                ...validateItemFields(item),
              };
              return validatedItem;
            } else {
              let validatedItem = {
                name: null,
                maximum_pick: null,
                count_as: null,
                sub_items: item.sub_items.map((subItem) => {
                  let validatedSubItem = { ...validateSubItemFields(subItem) };
                  return validatedSubItem;
                }),
              };
              return validatedItem;
            }
          }),
        };
      }
      return validatedOption;
    }),
  ];

  return optionsErrors;
};

const validateOptionFields = (option, fullValidation) => {
  let optionErrors = {
    name:
      maxLengthValidationForNameField(option.name) || option.name.length < 2
        ? 'Must be at least 2 characters long'
        : null,
  };

  if (fullValidation) {
    optionErrors = {
      ...optionErrors,
      maximum_pick: isIntegerNumberError(option.maximum_pick),
      minimum_pick: isIntegerNumberError(option.minimum_pick),
      included_pick:
        isNaturalNumberError(option.included_pick) ||
        (parseInt(option.included_pick) > 127 ? 'Must be less than 128' : null),
    };
    if (!optionErrors.minimum_pick && !optionErrors.maximum_pick) {
      if (parseInt(option.minimum_pick) > parseInt(option.maximum_pick)) {
        optionErrors.maximum_pick =
          'Must be greater than or equal to minimum pick';
      }
    }
  }

  return optionErrors;
};

const validateItemFields = (item) => {
  let itemErrors = {
    name:
      maxLengthValidationForNameField(item.name) || item.name.length < 2
        ? 'Must be at least 2 characters long'
        : null,
    maximum_pick: isGreaterThanZero(item.maximum_pick),
    count_as: item.sub_items
      ? isIntegerNumberError(item.count_as)
      : isGreaterThanZero(item.count_as),
  };

  return itemErrors;
};

const validateSubItemFields = (subItem) => {
  let subItemErrors = {
    name:
      maxLengthValidationForNameField(subItem.name) || subItem.name.length < 2
        ? 'Must be at least 2 characters long'
        : null,
    maximum_pick: isGreaterThanZero(subItem.maximum_pick),
    count_as: isGreaterThanZero(subItem.count_as),
  };

  return subItemErrors;
};

const isGreaterThanZero = (number) =>
  !isNaN(number) && parseFloat(number) > 0
    ? null
    : number && !isNaN(number) && parseFloat(number) <= 0
    ? 'Must greater than 0'
    : number
    ? 'Must be a number'
    : 'Required';

const isIntegerNumberError = (number) =>
  !isNaN(number) && parseFloat(number) >= 0
    ? null
    : number && !isNaN(number) && parseFloat(number) < 0
    ? 'Must be 0 or greater'
    : number
    ? 'Must be a number'
    : 'Required';

const isNaturalNumberError = (number) => {
  return !isNaN(number) &&
    parseFloat(number) >= 0 &&
    number % 1 === 0 &&
    new RegExp(`^[0-9][0-9]*$`).test(number)
    ? null
    : number && !isNaN(number) && parseFloat(number) < 0
    ? 'must be 0 or greater'
    : number
    ? 'Must be a whole number. Eg. 1, 2, 3...'
    : 'Required';
};
export default validateOptions;
