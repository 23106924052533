import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import * as action from "./productsActions";
import ProductsListContainer from "./ProductList/ProductsListContainer";
import ProductWizardContainer from "./ProductWizard/ProductWizardContainer";
import ReactGA from "react-ga";
const ProductsContainer = ({
  productWizardIsVisible,
  allProducts,
  getProducts,
  updateProductsRequestParams,
  requestParams,
}) => {
  const [selectedProduct, setSelectedProduct] = useState();
  const [searchString, setSearchString] = useState("");
  useEffect(() => {
    return () => {
      updateProductsRequestParams({
        ...requestParams,
        page_number: 1,
      });
      setSearchString("");
    };
  }, []);
  useEffect(() => {
    let debounce = setTimeout(() => {
      const newRequestParams = {
        ...requestParams,
        page_number: 1,
        search_string: searchString,
      };
      if (searchString.length > 2 || searchString === "") {
        getProducts(newRequestParams);
      }
      updateProductsRequestParams(newRequestParams);
      ReactGA.event({
        category: "Search",
        action: "Searching product in product list",
        label: "Search Product",
      });
    }, 1500);
    return () => {
      clearTimeout(debounce);
    };
  }, [searchString, productWizardIsVisible]);
  return !productWizardIsVisible ? (
    <ProductsListContainer
      searchString={searchString}
      setSearchString={setSearchString}
      allProducts={allProducts}
      setSelectedProduct={setSelectedProduct}
    />
  ) : (
    <ProductWizardContainer selectedProduct={selectedProduct} />
  );
};

const mapStateToProps = (state) => ({
  productWizardIsVisible: state.productsReducer.productWizardIsVisible,
  allProducts: state.productsReducer.allProducts,
  requestParams: state.productsReducer.requestParams,
});

export default connect(mapStateToProps, action)(ProductsContainer);
