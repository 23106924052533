import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Bar} from 'react-chartjs-2';
import {
  NoData,
  ChartCard,
  TitleWrapper,
  Title,
  TotalWrapper,
  TitleSpan
} from '../../components/DashboardElements';
import {findMaxValue, findStepSize} from '../../utils/chart';
import Tooltip from '@material-ui/core/Tooltip';
import {TooltipIcon} from '../../../../components/Icon';
class ActiveUserAge extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {usersAgeData} = this.props;
    const ages = usersAgeData.ages;
    const users = usersAgeData.counts;

    var suggestedMax = findMaxValue(users);
    //find stepSize
    var stepSize = findStepSize(users);

    if (stepSize <= 1 && suggestedMax <= 7) {
      stepSize = 1;
      suggestedMax = 7;
    } else {
      stepSize = '';
    }

    var data = {
      labels: ages,
      datasets: [
        {
          //label: 'TRANSACTIONS',
          backgroundColor: '#00a5db',
          borderColor: '#00a5db',
          borderWidth: 1,
          hoverBackgroundColor: '#00a5db',
          hoverBorderColor: '#00a5db',
          data: users
        }
      ]
    };
    return (
      <ChartCard>
        <TitleWrapper>
          <Title>
            <TitleSpan>
              Active User Age {' '}
              <Tooltip title="The age of users who have accessed the app " placement="top" arrow>
                 <span><TooltipIcon/></span>
              </Tooltip>
            </TitleSpan>
          </Title>
        </TitleWrapper>
        {(!users || users.length == 0) && <NoData>No Data Available</NoData>}
        <Bar
          data={data}
          options={{
            legend: {
              display: false
            },
            maintainAspectRatio: true,
            responsive: true,
            scales: {
              yAxes: [
                {
                  display: true,
                  //type: 'linear',
                  ticks: {
                    suggestedMin: 0,
                    beginAtZero: true,
                    suggestedMax: suggestedMax,
                    stepSize: stepSize
                    // min: 0,
                    // callback: function(value, index, values) {
                    //     if (Math.floor(value) === value) {
                    //         return value;
                    //     }
                    // }
                  },
                  scaleLabel: {
                    display: false
                  }
                }
              ],
              xAxes: [
                {
                  display: true,
                  padding: 50,
                  scaleLabel: {
                    display: false
                  }
                }
              ]
            }
          }}
        />
      </ChartCard>
    );
  }
}

export default ActiveUserAge;
