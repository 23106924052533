import React, { useState, useEffect, useCallback } from "react";
import Collapse from "@material-ui/core/Collapse";
import {
  Wrapper,
  TitleWrapper,
  Title,
  AddSpecialHoursButton,
  HoursTableWrraper,
  HoursTableRow,
  HoursTableHeader,
  HoursTableColumn,
  EditButton,
  DeleteButton,
  DaysWrapper,
  Day,
  TableWrapper,
  DetailWrapper,
  TableTitleWrapper,
} from "./styles/specialHoursListStyles";
import SpecialHoursSubList from "./SpecialHoursSubList";
import SpecialDayTimeSelectionModal from "./SpecialDayTimeSelectionModal";
import DeleteSpecialHoursModal from "./DeleteSpecialHoursModal";

const SpecialHoursList = ({
  businessId,
  formatedSpecialHours,
  specialHoursForm,
  setSoecialHoursForm,
  postSpecialHour,
  patchSpecialHour,
  deleteSelectedSpecialHours,
  isCreateSpecialHoursModalOpen,
  setIsCreateSpecialHoursModalOpen,
}) => {
  const [specialHoursTable, setSpecialHoursTable] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState();
  const [selectedDay, setSelectedDay] = useState();
  const [selectedHoursType, setSelectedHoursType] = useState();
  const [openingHours, setOpeningHours] = useState([]);
  const [orderingHours, setOrderingHours] = useState([]);
  const [selectedSpecialHours, setSelectedSpecialHours] = useState([]);
  // delete special hours
  const [specialHoursIDs, setSpecialHoursIDs] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  // create special hours

  const updateFormatedSpecialHours = useCallback(() => {
    let newFormatedSpecialHours = [];
    formatedSpecialHours.forEach((item) => {
      let days = [];
      item.hours.forEach((day) => days.push(day.open_day_of_week));

      const newItem = {
        startEndDate: item.startEndDate,
        days: [...new Set(days)],
      };
      newFormatedSpecialHours.push(newItem);
    });
    setSpecialHoursTable(newFormatedSpecialHours);
  }, [formatedSpecialHours]);
  useEffect(() => {
    updateFormatedSpecialHours();
  }, [updateFormatedSpecialHours]);

  const handleOpenModal = (id, type) => {
    setSelectedDay(id);
    setSelectedHoursType(type);
    setIsOpen(true);
  };
  const handleCollapse = (index) => {
    let newSpecialHours = [...formatedSpecialHours[index]?.hours];
    const days = newSpecialHours.map((item) => item?.open_day_of_week);
    const selectedSpecialDays = [...new Set(days)];
    let newOpeningHoursTable = [];
    let newOrderigHoursTable = [];
    let openingStatus;
    let orderingStatus;
    selectedSpecialDays.forEach((i) => {
      const filteredDay = newSpecialHours.filter(
        (day) => day.open_day_of_week === i
      );
      if (filteredDay.length === 1) {
        const isLocationClosed = filteredDay[0]?.is_closed;
        const isOrderingClosed =
          !filteredDay[0]?.order_start_time || !filteredDay[0]?.order_end_time;
        const hasNextday =
          filteredDay[0].open_day_of_week !== filteredDay[0].close_day_of_week;
        openingStatus = {
          id: i,
          status: isLocationClosed
            ? "closed"
            : {
                hasNextDay: hasNextday,
                open1: filteredDay[0].open_time,
                close1: filteredDay[0].close_time,
                open2: "",
                close2: "",
              },
        };
        orderingStatus = {
          id: i,
          status: isOrderingClosed
            ? "closed"
            : {
                hasNextDay: hasNextday,
                open1: filteredDay[0]?.order_start_time,
                close1: filteredDay[0]?.order_end_time,
                open2: "",
                close2: "",
              },
          isStoreClosed: isLocationClosed,
        };
      } else {
        const isLocationClosed = filteredDay[0]?.is_closed;
        const isOrderingClosed1 =
          !filteredDay[0]?.order_start_time || !filteredDay[0]?.order_end_time;
        const isOrderingClosed2 =
          !filteredDay[1]?.order_start_time || !filteredDay[1]?.order_end_time;
        const isOrderingClosed = isOrderingClosed1 && isOrderingClosed2;
        const hasNextday =
          filteredDay[0].open_day_of_week !== filteredDay[0].close_day_of_week;
        openingStatus = {
          id: i,
          status: isLocationClosed
            ? "closed"
            : {
                hasNextDay: hasNextday,
                open1: filteredDay[0].open_time,
                close1: filteredDay[0].close_time,
                open2: filteredDay[1].open_time,
                close2: filteredDay[1].close_time,
              },
        };
        orderingStatus = {
          id: i,
          status: isOrderingClosed
            ? "closed"
            : {
                hasNextDay: hasNextday,
                open1: filteredDay[0]?.order_start_time,
                close1: filteredDay[0]?.order_end_time,
                open2: filteredDay[1]?.order_start_time,
                close2: filteredDay[1]?.order_end_time,
              },
          isStoreClosed: isLocationClosed,
        };
      }
      newOpeningHoursTable.push(openingStatus);
      newOrderigHoursTable.push(orderingStatus);
    });
    setOpeningHours(newOpeningHoursTable);
    setOrderingHours(newOrderigHoursTable);
    setSelectedSpecialHours(newSpecialHours);
    setSelectedIndex(selectedIndex ? null : index + 1);
  };
  const handleDeleteSpecialHours = (index) => {
    const hours = formatedSpecialHours[index]?.hours;
    const selectedSpecialHoursIDs = hours.map((item) => item.id);
    setSpecialHoursIDs(selectedSpecialHoursIDs);
    setIsDeleteModalOpen(true);
  };
  const handleCreateSpecialHoursModalOpen = (index) => {
    setIsCreateSpecialHoursModalOpen(true);
  };
  return (
    <Wrapper>
      <TitleWrapper>
        <Title>Special Hours</Title>
        <AddSpecialHoursButton onClick={handleCreateSpecialHoursModalOpen}>
          <i class="fal fa-plus"></i> Create Special Hours
        </AddSpecialHoursButton>
      </TitleWrapper>
      <HoursTableWrraper>
        <HoursTableRow isHeader={true}>
          <HoursTableHeader>Start Date</HoursTableHeader>
          <HoursTableHeader>End Date</HoursTableHeader>
          <HoursTableHeader align="center">Days of Week</HoursTableHeader>
          <HoursTableHeader align="center">Edit</HoursTableHeader>
          <HoursTableHeader align="center">Delete</HoursTableHeader>
        </HoursTableRow>
        {specialHoursTable?.map((item, index) => (
          <>
            <HoursTableRow>
              <HoursTableColumn>{item.startEndDate.startDate}</HoursTableColumn>
              <HoursTableColumn>{item.startEndDate.endDate}</HoursTableColumn>
              <HoursTableColumn align="center">
                <DaysWrapper>
                  <Day isSelected={item.days.includes(7)}>S</Day>
                  <Day isSelected={item.days.includes(1)}>M</Day>
                  <Day isSelected={item.days.includes(2)}>T</Day>
                  <Day isSelected={item.days.includes(3)}>W</Day>
                  <Day isSelected={item.days.includes(4)}>T</Day>
                  <Day isSelected={item.days.includes(5)}>F</Day>
                  <Day isSelected={item.days.includes(6)}>S</Day>
                </DaysWrapper>
              </HoursTableColumn>
              <HoursTableColumn align="center">
                <EditButton
                  className="fa fa-pencil"
                  onClick={() => handleCollapse(index)}
                />
              </HoursTableColumn>
              <HoursTableColumn align="center">
                <DeleteButton
                  className="fa fa-trash"
                  onClick={() => handleDeleteSpecialHours(index)}
                />
              </HoursTableColumn>
            </HoursTableRow>
            <Collapse in={index + 1 === selectedIndex}>
              <TableWrapper>
                <TableTitleWrapper>
                  <div>{item.startEndDate.startDate}</div>
                  <div>/</div>
                  <div>{item.startEndDate.endDate}</div>
                </TableTitleWrapper>
                <DetailWrapper>
                  <SpecialHoursSubList
                    title="Location"
                    hoursTable={openingHours}
                    handleOpenModal={handleOpenModal}
                  />
                  <SpecialHoursSubList
                    title="Ordering"
                    hoursTable={orderingHours}
                    handleOpenModal={handleOpenModal}
                  />
                </DetailWrapper>
              </TableWrapper>
            </Collapse>
          </>
        ))}
      </HoursTableWrraper>
      {isOpen && (
        <SpecialDayTimeSelectionModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          selectedDay={selectedDay}
          selectedHoursType={selectedHoursType}
          specialHoursForm={specialHoursForm}
          selectedSpecialHours={selectedSpecialHours}
          setSpecialHoursForm={setSoecialHoursForm}
          postSpecialHour={postSpecialHour}
          patchSpecialHour={patchSpecialHour}
          deleteSelectedSpecialHours={deleteSelectedSpecialHours}
          setSelectedIndex={setSelectedIndex}
          businessId={businessId}
        />
      )}
      <DeleteSpecialHoursModal
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        deleteSelectedSpecialHours={deleteSelectedSpecialHours}
        businessId={businessId}
        specialHoursIDs={specialHoursIDs}
      />
    </Wrapper>
  );
};

export default SpecialHoursList;
