import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

const Banner = styled.div`
  height: 78px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 10px;
  margin: 24px 0;
  padding: 12px;
  border-radius: 8px;
  background-color: #fdf5eb;
`;

const BannerContent = styled.div`
  height: 54px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0;
`;

const BannerInnerContent = styled.div`
  align-self: stretch;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  padding: 0 16px;
`;

const BannerHeading = styled.div`
  width: 450px;
  height: 21px;
  flex-grow: 0;
  font-family: Montserrat;
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.25px;
  text-align: left;
  color: #313131;
`;

const BannerBody = styled.div`
  height: 17px;
  align-self: stretch;
  flex-grow: 0;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.25px;
  text-align: left;
  color: #313131;
`;

function LocationDeactivatedBanner({ deactivationTime }) {
  return (
    <Banner>
      <BannerContent>
        <i
          className="fa fa-exclamation-triangle"
          style={{ color: '#ee9a32', marginTop: '1.5px' }}
        />
        <BannerInnerContent>
          <BannerHeading>Location Deactivated</BannerHeading>
          {deactivationTime && (
            <BannerBody>
              {`This location was deactivated. If you wish to reactivate this location please contact Smooth support.`}
            </BannerBody>
          )}
        </BannerInnerContent>
      </BannerContent>
    </Banner>
  );
}

export default LocationDeactivatedBanner;
