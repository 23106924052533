import React from 'react';
import styled from 'styled-components';
import ProgressCircle from './ProgressCircle/ProgressCircle';
import { LoadingBlue } from 'merchant-portal-components';

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 100px;
`;

const Label = styled.div`
  margin-bottom: 16px;
  font-weight: bold;
  color: ${(props) => props.theme.darkBlue};
  text-transform: uppercase;
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Col = styled.div`
  flex-basis: 50%;
`;

const Legend = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-left: 32px;
`;

const BoxNumberWrapper = Row.extend`
  align-items: center;
`;

const GrayLabel = Label.extend`
  color: #858c9e;
  margin-bottom: 0;
`;

const GrayBox = styled.div`
  margin: 4px 8px;
  background: #babdc4;
  width: 24px;
  height: 24px;
`;

const Number = styled.div`
  color: #858c9e;
  font-size: 18px;
`;

const BlueBox = GrayBox.extend`
  background: linear-gradient(to bottom, #4869d3, #0e85d6);
`;

const YourTargetAudience = (props) => {
  if (!props.audienceData) {
    return (
      <div>
        <Label>Your Target Audience</Label>
        <LoadingWrapper>
          <LoadingBlue large />
        </LoadingWrapper>
      </div>
    );
  } else {
    const totalUsers = props.audienceData.total_customers; //tempData
    const targetAudience = props.audienceData.targeted_customers; //tempData
    const percentage = (targetAudience / totalUsers) * 100;
    const percentageCircle = percentage.toFixed(2);
    return (
      <div>
        <Label>Your Target Audience</Label>
        <Row>
          <Col>
            <ProgressCircle percentage={percentageCircle} />
          </Col>
          <Col>
            <Legend>
              <div>
                <GrayLabel>Total Users</GrayLabel>
                <BoxNumberWrapper>
                  <GrayBox />
                  <Number>{totalUsers}</Number>
                </BoxNumberWrapper>
              </div>
              <div>
                <GrayLabel>Target Audience</GrayLabel>
                <BoxNumberWrapper>
                  <BlueBox />
                  <Number>{targetAudience}</Number>
                </BoxNumberWrapper>
              </div>
            </Legend>
          </Col>
        </Row>
      </div>
    );
  }
};

export default YourTargetAudience;
