import React, { Fragment, useEffect, useState } from 'react';
import Card from '../../../components/Card/Card';
import AppDetailForm from './AppDetailForm';

const AppDetail = ({
  appDetailSchema,
  appDetails,
  paymentOptions,
  handleSubmit
}) => {
  const appDetail = Object.keys(appDetails).map((key, index) => {
    return (
      <Card
        key={index}
        style={{
          borderBottomLeftRadius: '20px',
          borderBottomRightRadius: '20px',
          padding: '0px'
        }}
      >
        <AppDetailForm
          appDetailSchema={appDetailSchema}
          appDetail={appDetails[key]}
          paymentOptions={paymentOptions}
          handleSubmit={handleSubmit}
        />
      </Card>
    );
  });
  return <Fragment>{appDetail}</Fragment>;
};
export default AppDetail;
