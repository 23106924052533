import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Formik, Field, FieldArray } from 'formik';
import StickyButtons from '../../../../../components/StickyButtons';
import LinkProduct from './LinkProduct';
import OptionForm from './OptionForm';
import validateOptions from '../../utils/validateOptions';

const Wrapper = styled.div`
  display: grid;
  grid-gap: 24px;
`;

const containsNoErrors = (obj, res = true) =>
  Object.entries(obj).reduce((result, [key, value]) => {
    if (value && typeof value === 'object') {
      return res && containsNoErrors(value, result);
    } else {
      return res && result && value === null;
    }
  }, res);

const OptionsDetail = ({ getNextOptionId, saveClick, initialValues }) => {
  return (
    <Formik
      initialValues={initialValues}
      // validate={(values) => {

      //   let errors = { options: validateOptions(values.options) };

      //   return errors;
      // }}
      enableReinitialize={true}
    >
      {({ values, handleReset, dirty, errors }) => (
        <Fragment>
          <StickyButtons
            isVisible={dirty}
            handleDiscard={() => handleReset()}
            // saveIsDisabled={!containsNoErrors(errors)}
            handleSave={() => saveClick(values.options)}
            title="Option Management"
          />

          <Wrapper>
            <FieldArray
              name="options"
              render={(arrayHelpers) =>
                arrayHelpers.form.values.options.map((option, index) => (
                  <OptionForm
                    key={index}
                    option={option}
                    optionIndex={index}
                    setFieldValue={arrayHelpers.form.setFieldValue}
                    arrayHelpers={arrayHelpers}
                    getNextOptionId={getNextOptionId}
                  />
                ))
              }
            />
            {/* <LinkProduct /> */}
          </Wrapper>
        </Fragment>
      )}
    </Formik>
  );
};

export default OptionsDetail;
