import React, { useEffect, Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import * as actions from '../ReportTableActions.js';
import ReportSearch from '../components/ReportSearch';
const GenericTable = lazy(() =>
  import('../../../components/Table/GenericTable')
);

function TransactionReportContainer({
  allTransactions,
  requestParams,
  getTransactionReport,
  setExportingData,
  setExportingHeaders,
  setExportingFileName,
  clearTransactionReport,
  updateReportsRequestParams,
  setMessage,
  resetAllReports,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  maxDueDate,
  setMaxDueDate,
  selectedBusinessesIDs,
  setSelectedBusinessesIDs,
}) {
  const columnHeaders = [
    'Order ID',
    'Transaction ID',
    'POS Order ID',
    'Date',
    'Source',
    'Customer',
    'Location',
    'Status',
    'Subtotal',
    'Tip($)',
    'Fees($)',
    'Tax($)',
    'Customer Paid($)',
    'Points Earned',
    'Points Redeemed',
    'Total Credits Applied($)',
    'Offer Credits($)',
    'Loyalty Credits($)',
    'Head Office Credits($)',
    'Global Store Credits($)',
    'Head Office Coupon Credits($)',
    'Store Coupon Credits($)',
    'Combo Discount($)',
    'Payment Type',
    'Card Type',
    'Payment Tender',
    'Order type',
    'Loyalty Quantity',
  ];
  const columnNames = [
    'order_id',
    'transaction_id',
    'pos_order_id',
    'datetime',
    'source',
    'customer',
    'business_name',
    'status',
    'subtotal',
    'tip',
    'fees',
    'tax',
    'customer_paid',
    'points_earned',
    'points_redeemed',
    'total_credits_applied',
    'offer_credits_applied',
    'loyalty_credits_applied',
    'global_credits_applied_smooth',
    'global_credits_applied_store',
    'coupon_credits_applied_smooth',
    'coupon_credits_applied_store',
    'combo_discount',
    'payment_type',
    'card_type',
    'payment_tender',
    'order_type',
    'loyalty_quantity',
  ];
  const columnTypes = [
    'string',
    'string',
    'string',
    'string',
    'string',
    'string',
    'string',
    'string',
    'money',
    'money',
    'money',
    'money',
    'money',
    'string',
    'string',
    'money',
    'money',
    'money',
    'money',
    'money',
    'money',
    'money',
    'money',
    'string',
    'string',
    'string',
    'string',
    'string',
  ];
  const columnWidth = [
    '120px',
    '170px',
    '160px',
    '150px',
    '110px',
    '250px',
    '150px',
    '120px',
    '120px',
    '110px',
    '170px',
    '170px',
    '170px',
    '170px',
    '150px',
    '150px',
    '150px',
    '150px',
    '150px',
    '150px',
    '150px',
    '150px',
    '150px',
    '150px',
    '150px',
    '150px',
    '150px',
    '150px',
  ];

  const columnHeaderIsSortableList = [
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
  ];
  const getExportHeaders = (headers, fields) => {
    const exportedHeaders = [];
    headers.forEach((header, index) =>
      exportedHeaders.push({ label: header, key: fields[index] })
    );
    return exportedHeaders;
  };
  const searchField = '';
  useEffect(() => {
    setExportingFileName('transaction_report');
    setExportingHeaders(getExportHeaders(columnHeaders, columnNames));
    setMessage('Select a date to generate a report');
    return () => {
      clearTransactionReport();
      setExportingData([]);
      updateReportsRequestParams({
        page_number: 1,
        page_size: 25,
      });
      setMessage('Select a date to generate a report');
      resetAllReports();
    };
  }, []);
  useEffect(() => {
    if (allTransactions?.length === 0) {
      setExportingData([]);
      setMessage('No Data Found');
    }
  }, [allTransactions]);
  return (
    <>
      <ReportSearch
        generateReport={getTransactionReport}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        selectedBusinessesIDs={selectedBusinessesIDs}
        setSelectedBusinessesIDs={setSelectedBusinessesIDs}
        reportType='transaction'
        maxDueDate={maxDueDate}
        setMaxDueDate={setMaxDueDate}
      />

      {allTransactions?.length > 0 && (
        <div style={{ marginTop: '30px' }}>
          <Suspense fallback={<div>Loading...</div>}>
            <GenericTable
              dataList={allTransactions}
              columnHeaders={columnHeaders}
              columnHeaderIsSortableList={columnHeaderIsSortableList}
              columnNames={columnNames}
              columnWidth={columnWidth}
              columnTypes={columnTypes}
              searchField={searchField}
              placeholder=''
              requestParams={requestParams}
              requestParamsType={'transactionReport'}
              handleClickRow={null}
              handleCheckboxSelect={null}
              checkboxList={[]}
              setCheckboxList={null}
              setExportingData={() => setExportingData(allTransactions)}
            />
          </Suspense>
        </div>
      )}
    </>
  );
}
function mapStateToProps(state) {
  return {
    allTransactions: state.ReportTableReducer.allTransactions,
    requestParams: state.ReportTableReducer.requestParams,
  };
}
export default connect(mapStateToProps, actions)(TransactionReportContainer);
