import {
  GET_ORDER_HISTORY_OF_MERCHANT_REQUEST,
  GET_ORDER_HISTORY_OF_MERCHANT_SUCCESS,
  GET_ORDER_HISTORY_OF_MERCHANT_FAILURE,
} from './types';
import api from '../../../api';

import renderQueryString from '../../../utils/renderQueryString';

export function updateRedirectPage(redirectPage) {
  return function (dispatch) {
    dispatch({
      type: 'REDIRECT_USER_TO_RELATED_PAGE_FROM_ORDER_HISTORY_DETAIL',
      redirectPage: redirectPage,
    });
  };
}
export function updateOrderId(selectedOrderId) {
  return function (dispatch) {
    dispatch({
      type: 'UPDATE_ORDER_HISTORY_OF_MERCHANT_ORDERID',
      selectedOrderId: selectedOrderId,
    });
  };
}
export function updateCurrentPage(currentPage) {
  return function (dispatch) {
    dispatch({
      type: 'UPDATE_ORDER_HISTORY_OF_MERCHANT_CURRENT_PAGE',
      currentPage: currentPage,
    });
  };
}
export function updatedRequestedParams(requestedParams) {
  return function (dispatch) {
    dispatch({
      type: 'UPDATE_ORDER_HISTORY_OF_MERCHANT_REQUESTED_PARAMS',
      requestedParams: requestedParams,
    });
  };
}
export function getOrderHistoryOfMerchant(params) {
  return function (dispatch, getState) {
    let requestedParams;
    if (params) {
      requestedParams = params;
    } else {
      requestedParams = getState().orderHistory.requestedParams;
    }
    dispatch({
      type: GET_ORDER_HISTORY_OF_MERCHANT_REQUEST,
    });
    delete requestedParams?.has_next_page;
    delete requestedParams?.sorting_option;
    delete requestedParams?.search_string;
    api
      .get(`/orders${renderQueryString(requestedParams)}`)
      .then((response) => {
        dispatch({
          type: GET_ORDER_HISTORY_OF_MERCHANT_SUCCESS,
          orders: response.data.data,
          meta: response.data.meta,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_ORDER_HISTORY_OF_MERCHANT_FAILURE,
        });
      });
  };
}
