import React from 'react';
import styled from 'styled-components';

const StyledOuterBox = styled.div`
  width: 20px;
  height: 20px;
  background-color: ${props => props.colour || props.theme.black};
  margin: auto;
`;

const StyledInnerBox = styled.div`
  height: 18px;
  width: 18px;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  margin: auto;
  background-color: ${props => props.colour || props.theme.white};
`;

const shadeColour = (color, percent) => {
  var float = parseInt(color.slice(1), 16),
    bound = percent < 0 ? 0 : 255,
    percent = percent < 0 ? percent * -1 : percent,
    red = float >> 16,
    green = (float >> 8) & 0x00ff,
    blue = float & 0x0000ff;
  return (
    '#' +
    (
      0x1000000 +
      (Math.round((bound - red) * percent) + red) * 0x10000 +
      (Math.round((bound - green) * percent) + green) * 0x100 +
      (Math.round((bound - blue) * percent) + blue)
    )
      .toString(16)
      .slice(1)
  );
};

const ColourBox = props => {
  const {colour} = props;
  return (
    <StyledOuterBox colour={colour}>
      <StyledInnerBox colour={colour ? shadeColour(colour, 0.35) : null} />
    </StyledOuterBox>
  );
};

export default ColourBox;
