import React, {useState} from 'react';
import styled from 'styled-components';
import Card from '../../../components/Card/Card';
import AppIntegrationForm from './AppIntegrationForm';

const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize;
`;

const SubtTitle = styled.div`
  font-size: 16px;
  text-transform: capitalize;
  padding-left: 20px;
`;

const Content = styled.div`
  font-weight: 600;
  text-transform: capitalize;

`;



const Wrapper = styled.div`
  display: grid;
  grid-gap: 10px;
`;


const AppIntegration = ({
  appIntegration,
  delimiter,
  handleSubmit
}) => {

  const renderForm = (integrationKey) => {
    return (
      <Wrapper>
        <SubtTitle>{appIntegration[integrationKey]['provider']}</SubtTitle>
        <AppIntegrationForm 
                appIntegration = {appIntegration[integrationKey]}
                delimiter = {delimiter}
                handleSubmit = {handleSubmit} 
        />
      </Wrapper>
        )}

    return (
        <Card
            style={{
              borderBottomLeftRadius: '20px',
              borderBottomRightRadius: '20px',
            }}
        >
          {Object.keys(appIntegration).map( (integrationKey,index) => {
          return (
              <Wrapper key={index}>
                <Title>
                  {integrationKey.replace(/_/g, " ")}
                </Title>
                <Content>
                    {renderForm(integrationKey)}
                </Content>
              </Wrapper>
          )
          })}
      </Card>
    );
};
export default AppIntegration;
