import React, { useEffect, useRef, useState, useCallback } from 'react';
import GenericTable from '../../../../components/Table/GenericTable';
import moment from 'moment';
import OrderHistoryFilter from './OrderHistoryFilter';
import {
  CardWrapper,
  NoDataFoundWrraper,
} from './styles/orderHistoryListStyles';
import { LoadingBlueLarge } from 'merchant-portal-components';

LoadingBlueLarge;
export const OrderHistory = ({
  orders,
  requestParams,
  handleFilter,
  exportHeaders,
  setExportData,
  setCurrentPage,
  updateOrderId,
  updatedRequestedParams,
  getOrderHistoryOfMerchant,
  ordersOfMerchantIsRequesting,
}) => {
  const orderHistoryTableRef = useRef(null);
  const [checkboxList, setCheckboxList] = useState([]);
  const [customizedOrderList, setCustomizedOrderList] = useState([]);
  const [totalCheckbox, setTotalCheckbox] = useState(false);
  const columnHeaders = [
    'checkbox',
    'Date Placed',
    'Order #',
    'Customer',
    'Total',
    'Order Type',
    'Location',
    'Order Due',
    'Payment Status',
    'POS #',
  ];
  const columnNames = [
    'checkbox',
    'order_created_at',
    'order_id',
    'm_customer_name',
    'total',
    'order_type',
    'business_name',
    'm_order_due',
    'status',
    'pos_order_id',
  ];
  const columnTypes = [
    'checkbox',
    'string',
    'string',
    'string',
    'money',
    'string',
    'string',
    'string',
    'string',
    'string',
  ];
  const columnWidth = [
    '4%',
    '10%',
    '8%',
    '8%',
    '10%',
    '10%',
    '18%',
    '10%',
    '14%',
    '6%',
  ];
  const columnHeaderIsSortableList = [
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ];
  const searchField = '';
  const customizeSelectedOrders = (selectedOrders) => {
    let newCustomizedOrderList = selectedOrders.map((order) => {
      const formatedCreateAt = moment(order.order_created_at).format(
        'MM/DD/YYYY'
      );
      const formatedOrderType =
        order.order_type.charAt(0).toUpperCase() + order.order_type.slice(1);
      const formatedNewStatus =
        order.status.charAt(0).toUpperCase() + order.status.slice(1);
      const newOrder = {
        order_created_at: formatedCreateAt,
        order_id: order.order_id,
        m_customer_name: `${order.customer.first_name} ${order.customer.last_name}`,
        total: order.total,
        order_type: formatedOrderType,
        business_name: order.business_name,
        m_order_due: order.is_future_order ? 'Scheduled' : 'ASAP',
        status: formatedNewStatus,
        pos_order_id: order.pos_order_id,
      };
      return newOrder;
    });
    return newCustomizedOrderList;
  };
  const updateOrderList = useCallback(() => {
    const newCustomizedOrderList = customizeSelectedOrders(orders);
    setExportData(newCustomizedOrderList);
    setCustomizedOrderList(newCustomizedOrderList);
  }, [orders]);
  useEffect(() => {
    updateOrderList();
  }, [updateOrderList]);
  useEffect(() => {
    window.scrollTo({
      top: orderHistoryTableRef.current.offsetTop + -400,
      behavior: 'smooth',
    });
  }, [orders]);
  const handleClickRow = (selectedRow) => {
    updateOrderId(selectedRow.order_id);
    setCurrentPage('detail');
  };
  const handleCheckboxSelect = (items) => {
    let modifiedOrders = [];
    items.forEach((status, index) => {
      if (status) {
        modifiedOrders.push(orders[index]);
      }
    });
    const customizedOrders = customizeSelectedOrders(modifiedOrders);
    setExportData(customizedOrders);
  };
  return (
    <CardWrapper ref={orderHistoryTableRef}>
      <OrderHistoryFilter handleFilter={handleFilter} />
      {ordersOfMerchantIsRequesting ? (
        <LoadingBlueLarge />
      ) : customizedOrderList.length > 0 ? (
        <GenericTable
          dataList={customizedOrderList}
          columnHeaders={columnHeaders}
          columnHeaderIsSortableList={columnHeaderIsSortableList}
          columnNames={columnNames}
          columnWidth={columnWidth}
          columnTypes={columnTypes}
          searchField={searchField}
          placeholder=''
          requestParams={requestParams}
          requestParamsType={'orderHistory'}
          handleClickRow={handleClickRow}
          handleCheckboxSelect={handleCheckboxSelect}
          checkboxId={'order_id'}
          exportHeaders={exportHeaders}
          exprotData={null}
          exportFileName={'Order_History'}
          totalCheckbox={totalCheckbox}
          setTotalCheckbox={setTotalCheckbox}
          checkboxList={checkboxList}
          setCheckboxList={setCheckboxList}
          updateAction={getOrderHistoryOfMerchant}
          updateRequestParams={updatedRequestedParams}
        />
      ) : (
        <NoDataFoundWrraper>No search result found</NoDataFoundWrraper>
      )}
    </CardWrapper>
  );
};

export default OrderHistory;
