export const formatDataSummaryView = (productOverrides) => {
  const groupedByVariantWithProps = {};

  productOverrides.forEach((override) => {
    const key = `${override.product_variant_id}_${override.price}`;

    if (groupedByVariantWithProps[key]) {
      groupedByVariantWithProps[key].businesses.push(override.business_id);
    } else {
      groupedByVariantWithProps[key] = {
        product_variant_id: override.product_variant_id,
        price: override.price,
        start_time: override.start_time,
        end_time: override.end_time,
        businesses: [override.business_id],
      };
    }
  });

  const businessGroups = {};
  Object.values(groupedByVariantWithProps).forEach((variant) => {
    // sort business arrays.
    variant.businesses = variant.businesses.sort();

    const key = variant.businesses.reduce((acc, id) => {
      acc += `_${id}`;
      return acc;
    }, '');

    const { businesses: variantBusinesses, ...variantOptions } = variant;
    if (businessGroups[key]) {
      businessGroups[key].prices.push({ ...variantOptions });
    } else {
      businessGroups[key] = {
        businesses: variantBusinesses,
        prices: [{ ...variantOptions }],
      };
    }
  });

  return businessGroups;
};

export const finalStructureSummaryData = (
  productOverrides,
  productId,
  productVariantsInitialValues,
  formattedBusinesses,
  allVariants
) => {
  const response = formatDataSummaryView(productOverrides);

  let productVariantsModified = [];
  allVariants.map((variant) => {
    productVariantsInitialValues.map((prVariant) => {
      if (variant.id === prVariant.product_variant_id) {
        productVariantsModified.push({
          ...prVariant,
          name: variant.name,
          label: variant.label,
        });
      }
    });
  });

  let finalDataStructure = [];
  if (formattedBusinesses) {
    const formattedBusinessesModified = formattedBusinesses.reduce(
      (acc, val) => {
        acc[val.id] = val.business_name;
        return acc;
      },
      {}
    );

    const variantNameMap = productVariantsModified.reduce((acc, val) => {
      acc[val.product_variant_id] = val.name;
      return acc;
    }, {});

    let responseArray = Object.values(response);

    finalDataStructure = responseArray.map((val) => ({
      ...val,
      businesses: val.businesses.map((businessId) => ({
        id: businessId,
        name: formattedBusinessesModified[businessId],
      })),

      prices: val.prices.map((priceObj) => ({
        ...priceObj,
        name: variantNameMap[priceObj.product_variant_id],
      })),
    }));
  }

  return finalDataStructure;
};
