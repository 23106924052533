const getActionArray = initialFormValues =>
  initialFormValues.action_url
    ? !initialFormValues.action_url.toLowerCase().startsWith('http://')
      ? initialFormValues.action_url
          .substring(initialFormValues.action_url.indexOf('//') + 2)
          .split('/')
      : null
    : null;

export default getActionArray;
