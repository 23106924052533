import styled from 'styled-components';
export const HeaderWrapper = styled.div`
  display: grid;
  grid-template-columns: 150px 170px 110px 110px 1fr 1fr 80px 120px;
  grid-gap: 10px;
  border-bottom: 3px solid #ededed;
  padding: 10px 0;
`;
export const RowWrapper = styled.div`
  display: grid;
  grid-template-columns: 150px 170px 110px 110px 1fr 1fr 80px 120px;
  grid-gap: 10px;
  border-bottom: 1px solid #ededed;
  padding: 16px 0;
  background-color: white;
  &:hover {
    background-color: #f1f1fa;
    cursor: pointer;
  }
`;
export const CellWrapper = styled.div`
  width: 100%;
  padding: ${(props) => (props.padding ? props.padding : 0)};
  font-weight: ${(props) => (props.isBold ? '600' : 'normal')};
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  cursor: ${(props) => (props.pointer ? 'pointer' : 'default')};
  text-align: ${(props) => (props.align ? props.align : 'left')};
  &:hover {
    cursor: pointer;
  }
`;
export const ElapsedWrapper = styled.div`
  width: 100%;
  padding: 0;
  font-weight: ${(props) => (props.isBold ? '600' : 'normal')};
  color: ${(props) => (props.isExpired ? '#c63d23' : '#2440ae')};
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
`;

export const Badge = styled.span`
  width: 100%;
  border-radius: 12px;
  padding: 4px 15px;
  color: '#191818';
  border: ${(props) =>
    props.status === -1
      ? '1px solid #CECECE'
      : props.status === 0
      ? 'none'
      : props.status === 1
      ? 'none'
      : props.status === 2
      ? 'none'
      : props.status === 3
      ? 'none'
      : props.status === 4
      ? 'none'
      : props.status === 5
      ? 'none'
      : 'none'};
  background-color: ${(props) =>
    props.status === -1
      ? 'transparent'
      : props.status === 0
      ? '#ffe047'
      : props.status === 1
      ? '#6ea7fe'
      : props.status === 2
      ? '#00dac6'
      : props.status === 3
      ? '#ff9f0a'
      : props.status === 4
      ? '#b305ad'
      : props.status === 5
      ? '#CECECE'
      : props.status === 6
      ? '#bb86fc'
      : 'white'};
`;
