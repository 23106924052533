import {createStore, applyMiddleware} from 'redux';
import reduxThunk from 'redux-thunk';
import rootReducer from './rootReducer';
import {composeWithDevTools} from 'redux-devtools-extension';

let createStoreWithMiddleware;

if (ENV !== 'production') {
  createStoreWithMiddleware = composeWithDevTools(applyMiddleware(reduxThunk))(
    createStore
  );
} else {
  createStoreWithMiddleware = applyMiddleware(reduxThunk)(createStore);
}

const store = createStoreWithMiddleware(rootReducer);

export default store;
