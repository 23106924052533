import {
  GET_TAGS_REQUEST,
  GET_TAGS_SUCCESS,
  GET_TAGS_FAILURE,
  CREATE_TAG_REQUEST,
  CREATE_TAG_SUCCESS,
  CREATE_TAG_FAILURE,
  EDIT_TAG_REQUEST,
  EDIT_TAG_SUCCESS,
  EDIT_TAG_FAILURE,
  SHOW_TAG_FORM,
  HIDE_TAG_FORM,
  SHOW_DELETE_TAG_MODAL,
  HIDE_DELETE_TAG_MODAL,
  DELETE_TAG_REQUEST,
  DELETE_TAG_SUCCESS,
  DELETE_TAG_FAILURE,
  RESET_TAGS_REDUCER_STATE
} from './tagsTypes';

const defaultState = {
  requestingGetTags: false,
  requestingCreateTag: false,
  requestingEditTag: false,
  requestingDeleteTag: false,
  tagFormIsVisible: false,
  isEditing: false,
  tagToEdit: false,
  deleteTagModalIsVisible: false,
  error: null
};

export default function(state = {...defaultState, allTags: false}, action) {
  switch (action.type) {
    case GET_TAGS_REQUEST:
      return {
        ...state,
        requestingGetTags: true
      };
    case GET_TAGS_SUCCESS:
      return {
        ...state,
        requestingGetTags: false,
        allTags: action.tags
      };
    case GET_TAGS_FAILURE:
      return {
        ...state,
        requestingGetTags: false,
        error: action.error
      };
    case CREATE_TAG_REQUEST:
      return {
        ...state,
        requestingCreateTag: true
      };
    case CREATE_TAG_SUCCESS:
      return {
        ...state,
        requestingCreateTag: false
      };
    case CREATE_TAG_FAILURE:
      return {
        ...state,
        requestingCreateTag: false
      };
    case EDIT_TAG_REQUEST:
      return {
        ...state,
        requestingEditTag: true
      };
    case EDIT_TAG_SUCCESS:
      return {
        ...state,
        requestingEditTag: false
      };
    case EDIT_TAG_FAILURE:
      return {
        ...state,
        requestingEditTag: false
      };
    case SHOW_TAG_FORM:
      return {
        ...state,
        tagFormIsVisible: true,
        isEditing: action.tagToEdit ? true : false,
        tagToEdit: action.tagToEdit ? action.tagToEdit : false
      };
    case HIDE_TAG_FORM:
      return {
        ...state,
        tagFormIsVisible: false,
        isEditing: false,
        tagToEdit: false
      };
    case SHOW_DELETE_TAG_MODAL:
      return {
        ...state,
        deleteTagModalIsVisible: true,
        tagIdToDelete: action.tagIdToDelete
      };
    case HIDE_DELETE_TAG_MODAL:
      return {
        ...state,
        deleteTagModalIsVisible: false,
        tagIdToDelete: false
      };
    case DELETE_TAG_REQUEST:
      return {
        ...state,
        requestingDeleteTag: true
      };
    case DELETE_TAG_SUCCESS:
      return {
        ...state,
        requestingDeleteTag: false
      };
    case DELETE_TAG_FAILURE:
      return {
        ...state,
        requestingDeleteTag: false
      };
    case RESET_TAGS_REDUCER_STATE:
      return {...state, ...defaultState};
    default:
      return state;
  }
}
