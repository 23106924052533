import {CREATE_ALERT, CLEAR_ALERT} from './types';

export function createAlert(alert) {
  return {
    type: CREATE_ALERT,
    alert
  };
}

export function clearAlert() {
  return {
    type: CLEAR_ALERT
  };
}
