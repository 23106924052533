import React, { useState, useEffect } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { withStyles } from '@material-ui/core/styles';
import { Icon } from '@material-ui/core';
import styled from 'styled-components';

const ErrorMessage = styled.a`
  color: rgb(244, 67, 54);
  font-size: 12px;
`;

const styles = (theme) => ({
  input: {
    fontFamily: theme.typography.fontFamily.main,
  },
});

const SingleDatePicker = ({
  field,
  form: { setFieldValue, errors },
  getValueOnChange,
  classes,
  setError,
  error,
  value,
  onChange,
  ...rest
}) => {
  const [selectedDate, handleDateChange] = useState(value);

  useEffect(() => {
    setFieldValue(field.name, selectedDate);
  }, [selectedDate]);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        disableToolbar
        format="MM/dd/yyyy"
        value={selectedDate}
        onChange={handleDateChange}
        {...rest}
        //keyboardIcon={<Icon style={{ display: 'none' }} />}
        minDate="01/01/1920"
        error={error}
        helperText={false}
        inputVariant="outlined"
        placeholder="MM/DD/YYYY"
        variant="inline"
        disableFuture={true}
        autoOk={true}
      />
      {error ? <ErrorMessage>{error}</ErrorMessage> : null}
    </MuiPickersUtilsProvider>
  );
};

export default withStyles(styles)(SingleDatePicker);
