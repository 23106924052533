import {
  GET_CATEGORIES,
  PATCH_ACTIVE_CATEGORY,
  LOAD_EDIT_CATEGORY_PAGE,
  LOAD_CREATE_CATEGORY_PAGE,
  LOAD_LIST_CATEGORY_PAGE,
  REDIRECT_LIST_PAGE_ON_SAVE,
  HANDLE_CATEGORY_SAVE_REQUEST,
  HANDLE_CATEGORY_SAVE_REQUEST_FAILURE,
  RESET_CATEGORY_REDUX_STATE,
  SORT_CATEGORY_LIST,
  SORT_SUB_CATEGORY_LIST,
  REQUEST_SORT_CATEGORY_LIST,
  SUBCATEGORY_HOURS_VALIDATION_ERROR,
  SET_PRODUCT_CATEGORY_ASSIGNMENT_MODAL_IS_VISIBLE,
  UPDATE_SORTED_CATEGORIES_LIST,
  SORT_PRODUCT_LIST,
} from './categoryTypes';
import { api } from '../../../api';
import { createAlert } from '../../Alert/actions';
import moment from 'moment';
import { getSearchUrl } from '../../../utils/purePayload';

export function getCategories(
  requestParams = {
    page_number: 1,
    page_size: '25',
    sorting_option: 'title-asc',
    search_string: '',
    has_next_page: true,
  }
) {
  return function (dispatch) {
    const urlwithOutSearchString=`/menu/categories?page_number=${requestParams.page_number}&page_size=${requestParams.page_size}`
    const url = getSearchUrl(urlwithOutSearchString, requestParams.search_string, 3);
    return api
      .get(
        url
        )
      .then((response) => {
        const hasNextPage = response.data.meta.has_next_page;
        dispatch({
          type: GET_CATEGORIES,
          payload: response.data.data,
          meta: response.data.meta,
          hasNextPage: hasNextPage,
        });
      })
      .catch((err) =>
        dispatch(
          createAlert({
            type: 'error',
            message: err?.response?.data?.errors?.message || 'An error occurred trying to get the categories',
          })
        )
      );
  };
}

export function toggleCategory(category, isActive) {
  return function (dispatch) {
    return api
      .patch(`/menu/categories/${category.id}`, { is_active: isActive })
      .then((response) => {
        dispatch({
          type: PATCH_ACTIVE_CATEGORY,
          payload: { id: category.id, isActive },
        });
        dispatch(
          createAlert({
            type: 'success',
            message: 'Category updated successfully',
          })
        );
      })
      .catch(
        createAlert({
          type: 'error',
          message: 'An error occurred trying to update the category',
        })
      );
  };
}

export function initiateEditCategory(category) {
  return function (dispatch) {
    return api
      .get(`/menu/categories/${category.id}?include=products`)
      .then((response) => {
        const categoriesData = response.data.data;
        let arrayLength = 0;
        let arrayLengthFinal = 0;
        const apiArray = [];
        const allProducts = [];
        const finalList = {};
        finalList[category.id] = [];
        const commonIndex = {};
        commonIndex[category.id] = [];
        let indx = 0;
        if (categoriesData.sub_categories.length !== 0) {
          categoriesData.sub_categories.forEach((subCategory) => {
            arrayLength = arrayLength + subCategory.products.length;
            finalList[subCategory.id] = [];
            commonIndex[subCategory.id] = [];
            subCategory.products.forEach((product) => {
              allProducts.push(product);
              apiArray.push(api.get(`/menu/products/${product.id}/categories`));
            });
          });
        } else {
          categoriesData.products.forEach((product) => {
            allProducts.push(product);
            apiArray.push(api.get(`/menu/products/${product.id}/categories`));
          });
        }
        Promise.all(apiArray).then((productList) => {
          const updatedProducts = allProducts.map((product, index) => ({
            ...product,
            associatedCategories: productList[index].data.data,
          }));

          var updatedProductsFinal = updatedProducts.reduce((unique, o) => {
            if (!unique.some((obj) => obj.id === o.id)) {
              unique.push(o);
            }
            return unique;
          }, []);

          updatedProductsFinal.forEach((product) => {
            product.associatedCategories.forEach((associatedItem) => {
              let postionAssociated = associatedItem.position;
              if (
                finalList[associatedItem.category_id] ||
                commonIndex[associatedItem.category_id]
              ) {
                if (postionAssociated !== 125 || postionAssociated !== 0) {
                  const indexPosition = postionAssociated - 1;

                  if (
                    typeof finalList[associatedItem.category_id][
                      indexPosition
                    ] !== 'undefined'
                  ) {
                    postionAssociated = 125;
                  }
                }
                if (postionAssociated == 125 || postionAssociated == 0) {
                  commonIndex[associatedItem.category_id][indx] = product;
                  indx = indx + 1;
                } else {
                  finalList[associatedItem.category_id][postionAssociated - 1] =
                    product;
                }
              }
            });
          });
          Object.keys(commonIndex).forEach((keyValue) => {
            if (commonIndex[keyValue].length > 0)
              finalList[keyValue] = commonIndex[keyValue].concat(
                finalList[keyValue]
              );
          });

          Object.keys(finalList).forEach((keyValue) => {
            arrayLengthFinal = arrayLengthFinal + finalList[keyValue].length;
          });
          if (
            arrayLengthFinal == arrayLength ||
            arrayLengthFinal > arrayLength
          ) {
            Object.keys(finalList).forEach((keyValue) => {
              let count = 1;
              let products = [];
              if (finalList[keyValue].length > 0) {
                finalList[keyValue].forEach((product, index) => {
                  if (product.position !== index + 1 || product.is_changed) {
                    products.push({
                      product_id: product.id,
                      position: count,
                    });
                  }
                  count = count + 1;
                });
                return api.put(`/menu/categories/${keyValue}/products`, {
                  products,
                });
              }
            });
          }
          if (categoriesData.sub_categories.length !== 0) {
            response.data.data.sub_categories.forEach((subCat, index) => {
              response.data.data.sub_categories[index].products =
                finalList[subCat.id];
            });
          } else {
            categoriesData.products = finalList[category.id];
          }
          dispatch({
            type: LOAD_EDIT_CATEGORY_PAGE,
            payload: categoriesData,
            productsAssociatedToCategory: finalList[category.id],
          });
        });
      });
  };
}

export function loadCreateCategoryPage() {
  return {
    type: LOAD_CREATE_CATEGORY_PAGE,
  };
}

export function loadListCategoryPage() {
  return {
    type: LOAD_LIST_CATEGORY_PAGE,
  };
}

//subcategory position validation
export function validateSubCatPosition(categorySubCategories) {
  let error = [];

  const subCatPosition = [];
  categorySubCategories.map((subCat) => {
    subCatPosition.push(parseInt(subCat.position));
  });

  var counts = {};
  subCatPosition.forEach(function (x) {
    counts[x] = (counts[x] || 0) + 1;
  });

  Object.keys(counts).forEach((val) => {
    if (val == 0) {
      error.push({ error: 'Position should be greater than zero' });
    }
  });

  Object.values(counts).forEach((val) => {
    if (val > 1) {
      error.push({ error: 'Subcategories may not have the same position' });
    }
  });

  Object.keys(counts).forEach((val) => {
    if (val > categorySubCategories.length) {
      error.push({
        error: 'Position may not be bigger than the number of subcategories',
      });
    }
  });
  return error;
}

//subcategory hours validation
export function validateSubCatyegoriesHours(values) {
  const categoryStartTime = values.categoryStartTime;
  const categoryEndTime = values.categoryEndTime;
  let error = [];

  values.categorySubCategories.map((subCategory) => {
    if (
      subCategory.start_time < categoryStartTime ||
      subCategory.end_time > categoryEndTime
    ) {
      error.push({ subCategoryId: subCategory.id, name: subCategory.name });
    }
  });
  return error;
}
function getUniqueArray(originalArray) {
  const strigifiedArray = originalArray.map((a) => JSON.stringify(a));
  const uniqueStringifiedArray = [...new Set(strigifiedArray)];
  const uniqueArray = uniqueStringifiedArray.map((a) => JSON.parse(a));
  return uniqueArray;
}
export function handleCategorySave(values, category, productValues = []) {
  return function (dispatch) {
    const errorHours = validateSubCatyegoriesHours(values);
    if (errorHours.length) {
      // dispatch({
      //   type: SUBCATEGORY_HOURS_VALIDATION_ERROR,
      //   payload: errorHours
      // });
      dispatch(
        createAlert({
          type: 'error',
          message:
            'Sub category time must be between parent category time for the following sub categories: ' +
            errorHours.map((err) => err.name).join(', '),
        })
      );
      return;
    }

    if (values.categorySubCategories.length) {
      const errors = validateSubCatPosition(values.categorySubCategories);
      if (errors.length) {
        dispatch(
          createAlert({
            type: 'error',
            message: Object.values(errors[0]),
          })
        );
        return;
      }
    }

    dispatch({
      type: HANDLE_CATEGORY_SAVE_REQUEST,
    });

    let mainCategoryPromise;

    if (category.id) {
      mainCategoryPromise = api.patch(`/menu/categories/${category.id}`, {
        name: values.categoryName,
        image_url: values.categoryImageUrl,
        start_time: values.categoryStartTime.format('HH:mm:ss'),
        end_time: values.categoryEndTime.format('HH:mm:ss'),
        is_active: values.isCategoryActive,
      });
    } else {
      mainCategoryPromise = api.post(`/menu/categories`, {
        name: values.categoryName,
        image_url: values.categoryImageUrl,
        start_time: values.categoryStartTime.format('HH:mm:ss'),
        end_time: values.categoryEndTime.format('HH:mm:ss'),
        is_active: values.isCategoryActive,
      });
    }
    const subCategories = getUniqueArray(values.categorySubCategories);
    return mainCategoryPromise
      .then((response) => {
        return Promise.all(
          subCategories.map((subCategory, subCategoryIndex) => {
            if (subCategory.id) {
              return api.patch(`/menu/categories/${subCategory.id}`, {
                name: subCategory.name,
                image_url: subCategory.image_url ? subCategory.image_url : null,
                start_time: moment(subCategory.start_time).format('HH:mm:ss'),
                end_time: moment(subCategory.end_time).format('HH:mm:ss'),
                is_active: subCategory.is_active,
                parent_id: subCategory.parent_id,
                position: parseInt(subCategory.position),
              });
            } else {
              return api.post(`/menu/categories`, {
                name: subCategory.name,
                image_url: subCategory.image_url ? subCategory.image_url : null,
                start_time: moment(subCategory.start_time).format('HH:mm:ss'),
                end_time: moment(subCategory.end_time).format('HH:mm:ss'),
                is_active: subCategory.is_active,
                parent_id: response.data.data.id,
                position: parseInt(subCategory.position),
              });
            }
          })
        )
          .then((responses) => {
            Promise.all(
              productValues.map((product, index) => {
                const body = {
                  categories: [
                    ...product.associatedCategories
                      .filter(
                        (category, index, self) =>
                          category.category_id !== response.data.data.id &&
                          index ===
                            self.findIndex(
                              (c) => c.category_id === category.category_id
                            )
                      )
                      .map((category) => ({
                        category_id: category.category_id,
                        position: category.position,
                      })),
                    {
                      category_id:
                        responses[product.subCategoryIndex].data.data.id,
                      position: 125,
                    },
                  ],
                };

                return api.put(`/menu/products/${product.id}/categories`, body);
              })
            ).then(() => {
              dispatch({
                type: REDIRECT_LIST_PAGE_ON_SAVE,
              });
              dispatch(
                createAlert({
                  type: 'success',
                  message: 'Category saved successfully',
                })
              );
            });
          })
          .catch((err) => {
            dispatch({
              type: HANDLE_CATEGORY_SAVE_REQUEST_FAILURE,
            });
            dispatch(
              createAlert({
                type: 'error',
                message: err?.response?.data?.errors?.message || 
                  Object.values(err.response.data.errors.details) +
                  ' in sub categories',
              })
            );
          });
      })
      .catch((err) => {
        dispatch({
          type: HANDLE_CATEGORY_SAVE_REQUEST_FAILURE,
        });
        dispatch(
          createAlert({
            type: 'error',
            message: err.response?.data.errors.details.length
              ? err.response.data.errors.details.map(
                  (detail) => Object.values(detail) + ' in category'
                )
              : Object.values(err.response.data.errors.details) +
                ' in category',
          })
        );
      });
  };
}

export function resetCategoryReduxState() {
  return {
    type: RESET_CATEGORY_REDUX_STATE,
  };
}

export function sortCategories(
  categoriesList,
  callback,
  updatedParentCategory
) {
  return function (dispatch) {
    dispatch({
      type: REQUEST_SORT_CATEGORY_LIST,
    });
    let modifiedCategories = [];

    categoriesList.forEach((category, index) => {
      if (category.position !== index + 1 || category.is_changed) {
        modifiedCategories.push({ ...category, position: index + 1 });
      }
    });
    return Promise.all(
      modifiedCategories.map((category, newPosition) => {
        if (updatedParentCategory) {
          return api.patch(`/menu/categories/${category.id}`, {
            name: category.name,
            image_url: '',
            start_time: moment(category.start_time).format('HH:mm:ss'),
            end_time: moment(category.end_time).format('HH:mm:ss'),
            is_active: category.is_active,
            parent_id: category.parent_id,
            position: parseInt(category.position), //position: newPosition + 1
          });
        } else {
          return api.patch(`/menu/categories/${category.id}`, {
            name: category.name,
            image_url: '',
            start_time: category.start_time,
            end_time: category.end_time,
            is_active: category.is_active,
            parent_id: category.parent_id,
            position: category.position, //position: newPosition + 1
          });
        }
      })
    )
      .then(() => {
        !updatedParentCategory
          ? dispatch({
              type: SORT_CATEGORY_LIST,
              payload: categoriesList,
            })
          : dispatch({
              type: SORT_SUB_CATEGORY_LIST,
              payload: updatedParentCategory,
            });
        dispatch(
          createAlert({
            type: 'success',
            message: updatedParentCategory
              ? 'Subcategories successfully updated'
              : 'Categories successfully updated',
          })
        );
        dispatch(getCategories());
        callback();
      })
      .catch((err) =>
        dispatch(
          createAlert({
            type: 'error',
            message: updatedParentCategory
              ? 'An error occurred trying to sort subcategories'
              : 'An error occurred trying to sort categories',
          })
        )
      );
  };
}
export function sortProducts(
  productList,
  callback,
  updatedParentCategory,
  associatedCategoryId,
  subCategoryIndex,
  formHeadName
) {
  return function (dispatch) {
    dispatch({
      type: REQUEST_SORT_CATEGORY_LIST,
    });
    let products = [];

    productList.forEach((product, index) => {
      if (product.position !== index + 1 || product.is_changed) {
        products.push({ product_id: product.id, position: index + 1 });
      }
    });
    return api
      .put(`/menu/categories/${associatedCategoryId}/products`, { products })
      .then(() => {
        dispatch(
          createAlert({
            type: 'success',
            message: subCategoryIndex
              ? 'Subcategories successfully updated'
              : 'Categories successfully updated',
          })
        );
        if (formHeadName == 'subcategory') {
          updatedParentCategory.sub_categories[subCategoryIndex].products =
            productList;
        } else {
          updatedParentCategory.products = productList;
        }

        dispatch({
          type: SORT_PRODUCT_LIST,
          editingCategory: { ...updatedParentCategory },
          productsAssociatedToCategory: productList,
        });
        callback();
      })
      .catch((err) =>
        dispatch(
          createAlert({
            type: 'error',
            message: updatedParentCategory
              ? 'An error occurred trying to sort subcategories'
              : 'An error occurred trying to sort categories',
          })
        )
      );
  };
}

export function setProductCategoryAssignmentModalIsVisible(
  values,
  productCategoryAssignmentModalIsVisible
) {
  return function (dispatch) {
    if (productCategoryAssignmentModalIsVisible) {
      const errorHours = validateSubCatyegoriesHours(values);
      if (errorHours.length) {
        // dispatch({
        //   type: SUBCATEGORY_HOURS_VALIDATION_ERROR,
        //   payload: errorHours
        // });
        dispatch(
          createAlert({
            type: 'error',
            message:
              'Sub category time must be between parent category time for the following sub categories: ' +
              errorHours.map((err) => err.name).join(', '),
          })
        );
        return;
      }

      if (values.categorySubCategories.length) {
        const errors = validateSubCatPosition(values.categorySubCategories);
        if (errors.length) {
          dispatch(
            createAlert({
              type: 'error',
              message: Object.values(errors[0]),
            })
          );
          return;
        }
      }
    }
    dispatch({
      type: SET_PRODUCT_CATEGORY_ASSIGNMENT_MODAL_IS_VISIBLE,
      productCategoryAssignmentModalIsVisible,
    });
  };
}

export function updateCategoriesRequestParams(requestParams) {
  return {
    type: 'UPDATE_CATEGORIES_REQUEST_PARAMS',
    payload: requestParams,
  };
}

export function updateSortedCategoriesList(categoriesList, sortOrder) {
  return {
    type: UPDATE_SORTED_CATEGORIES_LIST,
    allCategories: categoriesList,
    sortOrder: sortOrder,
  };
}
