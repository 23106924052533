import React, { Component } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import * as actions from './actions';
import BreadCrumb from '../../../components/BreadCrumb/BreadCrumb';
import styled from 'styled-components';
import { Charts } from '../components/DashboardElements';
import { LoadingBlueLarge } from 'merchant-portal-components';
import SummaryBar from './components/Summary';
import Transactions from './components/Transactions';
import Sales from './components/Sales';
import TransactionsPerHour from './components/TransactionsPerHour';
import OrderTypes from './components/OrderTypes';
import intervalOptions from '../utils/intervalOptions';
// import { Select } from "merchant-portal-components";
import { TitleArea } from '../../../components/Table/TableElements';
import { formatLocations } from '../../../utils/childLocations/formatLocations';
import FilterButton from '../../../components/FilterModal/FilterButton';
import LocationFilterModal from '../../../components/FilterModal/LocationFilterModal';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import ReactGA from 'react-ga';
const paymentTypeOptions = [
  { value: 'all', label: 'All' },
  { value: 'mobile', label: 'Order Ahead' },
  { value: 'scan', label: 'Scan to Pay' },
];
const Title = styled.div`
  font-size: 35px;
  font-weight: bold;
  margin-top: 25px;
`;
const SearchWrapper = styled.div`
  margin: 25px 0;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  grid-gap: 30px;
`;

class TransactionsDashboard extends Component {
  state = {
    business_ids: [],
    business_names: [],
    type: 'all',
    interval: intervalOptions[1],
    selectedLength: 0,
    isLocationFilterModalOpen: false,
  };

  componentDidMount() {
    this.getAllTransactionDashboardData();
    track.page('dashboard-transactions');
    ReactGA.pageview(
      window.location.pathname,
      undefined,
      'Transactions Summary'
    );
    const allBusinessIDs = this.props.allBusinesses.map(
      (business) => business.id
    );
    this.setState({ business_ids: allBusinessIDs });
  }

  componentDidUpdate(prevProps, prevState) {
    // if (prevState !== this.state) {
    //   this.getAllTransactionDashboardData();
    // }
    if (!prevProps.token && this.props.token) {
      this.getAllTransactionDashboardData();
    }
    if (prevState.business_ids !== this.state.business_ids) {
      this.setState({
        selectedLength: this.state.business_ids.length,
      });
      this.getAllTransactionDashboardData();
    }
    if (prevState.interval !== this.state.interval) {
      this.getAllTransactionDashboardData();
    }
    if (prevState.type !== this.state.type) {
      this.getAllTransactionDashboardData();
    }
  }

  getAllTransactionDashboardData = () => {
    const { business_ids, interval, type } = this.state;
    const params = {
      interval_type: interval.value.interval_type,
      interval_value: interval.value.interval_value,
      business_ids,
      type,
    };
    this.props.getSummary(params);
    this.props.getTransactions(params);
    this.props.getSales(params);
    this.props.getTransactionsPerHour(params);
    this.props.getOrderTypes(params);
  };

  updateFilteredLocation = (business_ids) => {
    this.setState({ business_ids: business_ids });
  };
  updateOpenLocationModal = (isOpen) => {
    this.setState({ isLocationFilterModalOpen: isOpen });
  };
  selectOrderType = (e) => {
    this.setState({ type: e.target.value });
  };
  selectInterval = (e) => {
    const selectedItem = intervalOptions.find(
      (item) => item.value.interval_value === e.target.value.interval_value
    );
    this.setState({ interval: selectedItem });
  };
  render() {
    const { allBusinesses, transactionsDashboard } = this.props;
    const {
      business_ids,
      type,
      interval,
      isLocationFilterModalOpen,
      selectedLength,
    } = this.state;
    const {
      summaryData,
      transactionsData,
      salesData,
      transactionsPerHourData,
      orderTypesData,
      loading,
    } = transactionsDashboard;
    let businessOptions = [{ id: 'all', name: 'All Locations' }];
    if (allBusinesses) {
      businessOptions = [...businessOptions, ...allBusinesses];
    }
    return (
      <div>
        <Title>Transactions</Title>
        <SearchWrapper>
          <FilterButton
            message={
              business_ids.length === 0 ||
              allBusinesses.length === business_ids.length
                ? 'All Locations Selected'
                : `Selected Locations (${selectedLength})`
            }
            setIsOpen={this.updateOpenLocationModal}
          />
          <FormControl variant='outlined' size='small'>
            <InputLabel id='demo-simple-select-outlined-label'>
              Order Type
            </InputLabel>
            <Select
              labelId='demo-simple-select-outlined-label'
              id='demo-simple-select-outlined'
              value={type}
              onChange={this.selectOrderType}
              label='Order Type'
              style={{
                backgroundColor: 'white',
              }}
            >
              {paymentTypeOptions.map((item) => (
                <MenuItem value={item.value}>{item.label}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl variant='outlined' size='small'>
            <InputLabel id='demo-simple-select-outlined-label'>
              Time Period
            </InputLabel>
            <Select
              labelId='demo-simple-select-outlined-label'
              id='demo-simple-select-outlined'
              value={interval.value}
              onChange={this.selectInterval}
              label='Time Period'
              style={{ backgroundColor: 'white' }}
            >
              {intervalOptions.map((item) => (
                <MenuItem value={item.value}>{item.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </SearchWrapper>
        {summaryData && loading ? (
          <div>
            {summaryData ? (
              <SummaryBar data={summaryData} />
            ) : (
              <LoadingBlueLarge />
            )}

            <Charts>
              {transactionsData ? (
                <Transactions
                  data={transactionsData}
                  stateInterval={this.state.interval.value}
                />
              ) : (
                <LoadingBlueLarge />
              )}

              {salesData ? (
                <Sales
                  data={salesData}
                  stateInterval={this.state.interval.value}
                />
              ) : (
                <LoadingBlueLarge />
              )}
            </Charts>

            <Charts>
              {transactionsPerHourData ? (
                <TransactionsPerHour data={transactionsPerHourData} />
              ) : (
                <LoadingBlueLarge />
              )}

              {orderTypesData ? (
                <OrderTypes data={orderTypesData} />
              ) : (
                <LoadingBlueLarge />
              )}
            </Charts>
          </div>
        ) : (
          <LoadingBlueLarge />
        )}
        {isLocationFilterModalOpen && (
          <LocationFilterModal
            placeholder='Enter Internal Location Name'
            searchLabel='Search by Internal Location Name'
            filteredLocations={business_ids}
            setFilteredLocations={this.updateFilteredLocation}
            isOpen={isLocationFilterModalOpen}
            setIsOpen={this.updateOpenLocationModal}
            emptyIsEqualToAll={true}
            isEmptyAccepted={false}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    transactionsDashboard: state.transactionsDashboard,
    transactionsForm: state.form.TransactionsFilterSelection,
    token: state.accountReducer.token,
    allBusinesses: state.businessLocationsList.businessLocationsOfMerchant,
  };
}

export default connect(mapStateToProps, actions)(TransactionsDashboard);
