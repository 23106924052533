import React, { Component } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import * as actions from "./actions";
import {
  makeCircleObject,
  makeRectangleObject,
  makePolygonObject,
  addOverlaysToGoogleMaps,
  removeOverlaysFromGoogleMaps
} from "./helpers";

class GoogleMapsWithOverlayZones extends Component {
  state = {
    id: 0 // used to keep track of new created shapes
  };

  componentDidMount() {
    this.props.resetReduxStates(); // want to reset everything.
    const mapRef = this.refs.displayMap;
    const node = ReactDOM.findDOMNode(mapRef);

    const { selectedLocations } = this.props;
    const zoom = this.props.zoom ? this.props.zoom : 17;

    let center = this.props.center
      ? this.props.center
      : { lat: -34.397, lng: 150.644 }; // the view center // go to Australia if not provided
    if (selectedLocations && selectedLocations.length) {
      center = this.getBounds(selectedLocations).getCenter();
    }

    this.displayMap = new google.maps.Map(node, {
      zoom: zoom,
      center: center,
      zoomControl: true,
      disableDefaultUI: true
    });

    if (selectedLocations && selectedLocations.length) {
      this.addMarkers(selectedLocations);
    }

    if (this.props.isSearchEnabled) {
      let input = document.getElementById("pac-input");
      let searchBox = new google.maps.places.SearchBox(input);
      this.displayMap.controls[google.maps.ControlPosition.TOP_LEFT].push(
        input
      );

      var markers = [];

      searchBox.addListener("places_changed", () => {
        let places = searchBox.getPlaces();

        markers.forEach(function(marker) {
          marker.setMap(null);
        });
        markers = [];

        var position = {
          lat: places[0].geometry.location.lat(),
          lng: places[0].geometry.location.lng()
        };

        const marker = new google.maps.Marker({
          position: position
        });

        marker.setMap(this.displayMap);
        markers.push(marker);

        if (places.length == 0) {
          return;
        }

        // For each place, get the icon, name and location.
        let bounds = new google.maps.LatLngBounds();
        places.forEach(function(place) {
          if (!place.geometry) {
            return;
          }

          if (place.geometry.viewport) {
            // Only geocodes have viewport.
            bounds.union(place.geometry.viewport);
          } else {
            bounds.extend(place.geometry.location);
          }
        });

        this.displayMap.fitBounds(bounds);
      });
    }

    // default options. Fill colour is yellow.
    let shapeOptions = {
      fillColor: "#ffff00",
      strokeWeight: 0,
      fillOpacity: 0.3,
      editable: true,
      draggable: true,
      zIndex: 1
    };

    this.drawingManager = new google.maps.drawing.DrawingManager({
      drawingMode: null,
      drawingControl: true,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [
          google.maps.drawing.OverlayType.POLYGON,
          google.maps.drawing.OverlayType.CIRCLE,
          google.maps.drawing.OverlayType.RECTANGLE
        ]
      },
      circleOptions: shapeOptions,
      rectangleOptions: shapeOptions,
      polygonOptions: shapeOptions
    });
    // if drawing is allowed, set drawing manager to map (activate it). else don't
    const isDrawingAllowed = this.props.allowDrawing ? this.displayMap : null;
    this.drawingManager.setMap(isDrawingAllowed);

    google.maps.event.addListener(
      this.drawingManager,
      "circlecomplete",
      circle => {
        this.handleReduxStoreOverlays(makeCircleObject(circle));
      }
    );

    google.maps.event.addListener(
      this.drawingManager,
      "rectanglecomplete",
      rectangle => {
        this.handleReduxStoreOverlays(makeRectangleObject(rectangle));
      }
    );

    google.maps.event.addListener(
      this.drawingManager,
      "polygoncomplete",
      polygon => {
        this.handleReduxStoreOverlays(makePolygonObject(polygon));
      }
    );
    if (this.props.googleMapsWithOverlayZones.overlayZones.length) {
      removeOverlaysFromGoogleMaps(
        this.props.googleMapsWithOverlayZones.overlayZones
      );
    }
    // drawing shape colour. if none is provided, google will resort to black
    const editingColour = this.props.editingColour;
    shapeOptions = {
      fillColor: editingColour,
      strokeWeight: 0,
      fillOpacity: 0.3,
      editable: true,
      draggable: true,
      zIndex: 1
    };
    this.drawingManager.setOptions({
      options: {
        circleOptions: shapeOptions,
        rectangleOptions: shapeOptions,
        polygonOptions: shapeOptions
      }
    });

    // helper to add overlays to google maps
    let storeOverlays = addOverlaysToGoogleMaps(
      this.props.overlayObjects,
      this.displayMap
    );
    // if shape is editable, add listeners.
    // non-editable can't have listeners
    storeOverlays.forEach(overlay => {
      if (overlay.isEditable) {
        this.props.updateEditOverlayObject(overlay);
        this.props.updateDrawnOverlayObjects([overlay]);
        this.addResizeEventListnerExist(overlay);
      }
    });
    this.props.addOverlayZoneObjs(storeOverlays);
  }

  getBounds = selectedLocations => {
    let bounds = new google.maps.LatLngBounds();
    selectedLocations.forEach(location => bounds.extend(location));
    return bounds;
  };

  addMarkers = selectedLocations => {
    selectedLocations.forEach(location => {
      const marker = new google.maps.Marker({ position: location });
      marker.setMap(this.displayMap);
    });
  };

  // reset state on unmount.
  componentWillUnmount() {
    this.props.resetReduxStates();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.allowDrawing != this.props.allowDrawing) {
      const isDrawingAllowed = this.props.allowDrawing ? this.displayMap : null;
      this.drawingManager.setMap(isDrawingAllowed);
    }
    if (prevProps.overlayObjects != this.props.overlayObjects) {
      let notEditingOverlays = [];
      let shouldAddEditOverlay = false;
      if (this.props.googleMapsWithOverlayZones.overlayZones.length) {
        // get all non editing overlays
        notEditingOverlays = this.props.googleMapsWithOverlayZones.overlayZones.filter(
          overlay => {
            if (!overlay.isEditable) {
              return overlay;
            }
          }
        );
        // all non-editing overlays should be removed on new props
        removeOverlaysFromGoogleMaps(notEditingOverlays);
        const { editOverlayObj } = this.props.googleMapsWithOverlayZones;

        // find the editing overlay
        let incomingEditOverlayObj = this.props.overlayObjects.find(
          overlay => overlay.id == editOverlayObj.id
        );

        // if editable overlay is no longer editable, remove it as well
        if (incomingEditOverlayObj && !incomingEditOverlayObj.isEditable) {
          shouldAddEditOverlay = true;
          removeOverlaysFromGoogleMaps([editOverlayObj]);
        }
      }

      const editingColour = this.props.editingColour;
      const shapeOptions = {
        fillColor: editingColour,
        strokeWeight: 0,
        fillOpacity: 0.3,
        editable: true,
        draggable: true,
        zIndex: 1
      };
      this.drawingManager.setOptions({
        options: {
          circleOptions: shapeOptions,
          rectangleOptions: shapeOptions,
          polygonOptions: shapeOptions
        }
      });
      let overlaysToAdd = [];
      let editableOverlays = [];
      if (!notEditingOverlays.length) {
        // edge case: if only one overlay and its editable
        if (this.props.overlayObjects.length == 1) {
          if (this.props.overlayObjects[0].isEditable) {
            editableOverlays.push(this.props.overlayObjects[0]);
          } else {
            overlaysToAdd.push(this.props.overlayObjects[0]);
          }
        } else {
          overlaysToAdd = this.props.overlayObjects;
        }
      } else {
        this.props.overlayObjects.forEach(overlayObject => {
          let found = true;
          notEditingOverlays.forEach(notEditingOverlay => {
            if (overlayObject.id == notEditingOverlay.id) {
              found = false;
            }
          });
          if (!found) {
            overlaysToAdd.push(overlayObject); // not part of editable overlays list
          } else {
            editableOverlays.push(overlayObject); // is part of editable overlays
          }
        });
      }

      // should add the editable overlay to google maps (because it was editable, now it's not)
      if (shouldAddEditOverlay) {
        const { editOverlayObj } = this.props.googleMapsWithOverlayZones;

        let incomingEditOverlayObj = this.props.overlayObjects.find(
          overlay => overlay.id == editOverlayObj.id
        );
        overlaysToAdd.push(incomingEditOverlayObj);
      }

      if (
        !Object.keys(this.props.googleMapsWithOverlayZones.editOverlayObj)
          .length
      ) {
        // const { editOverlayObj } = this.props.googleMapsWithOverlayZones;
        let incomingEditOverlayObj = this.props.overlayObjects.find(
          overlay => overlay.isEditable
        );
        if (incomingEditOverlayObj) {
          overlaysToAdd.push(incomingEditOverlayObj);
        }
      }
      // helper to add overlays
      let storeOverlays = addOverlaysToGoogleMaps(
        overlaysToAdd,
        this.displayMap
      );
      // if editable, add listeners
      storeOverlays.forEach(overlay => {
        if (overlay.isEditable) {
          this.props.updateEditOverlayObject(overlay);
          this.props.updateDrawnOverlayObjects([overlay]);
          this.addResizeEventListnerExist(overlay);
        }
      });
      this.props.addOverlayZoneObjs(storeOverlays);
      editableOverlays.forEach(editableOverlay => {
        // check if editable overlay visibility property changed and update accordingly
        const { editOverlayObj } = this.props.googleMapsWithOverlayZones;
        if (Object.keys(editOverlayObj).length) {
          editOverlayObj.mapObj.setOptions({
            visible: editableOverlay.isVisible
          });
        }
      });
    }
  }

  // to do just before adding to redux store
  handleReduxStoreOverlays = overlayObj => {
    let { id } = this.state;
    // check if max drawn is 'capped'. If true, remove oldest element and push the new one
    let currentOverlaysDrawn = this.props.googleMapsWithOverlayZones
      .drawnOverlayZones;
    if (currentOverlaysDrawn.length == this.props.maxDrawnOverlaysAllowed) {
      currentOverlaysDrawn.shift().mapObj.setMap(null);
    }
    overlayObj = {
      ...overlayObj,
      id: this.state.id,
      editId: this.state.id
    };
    this.setState({ id: ++id });
    this.addResizeEventListner(overlayObj);
    currentOverlaysDrawn.push(overlayObj);
    this.props.updateDrawnOverlayObjects(currentOverlaysDrawn);
    this.drawingManager.setDrawingMode(null);
  };

  updateOverlayObjectInReduxStore = newOverlayObj => {
    let currentOverlaysDrawn = this.props.googleMapsWithOverlayZones
      .drawnOverlayZones;
    currentOverlaysDrawn = currentOverlaysDrawn.map(overlay =>
      overlay.id == newOverlayObj.id ? newOverlayObj : overlay
    );
    this.props.updateDrawnOverlayObjects(currentOverlaysDrawn);
  };

  addResizeEventListner = overlayObject => {
    if (overlayObject.type == "circle") {
      let isBeingDragged = false;
      google.maps.event.addListener(overlayObject.mapObj, "dragstart", () => {
        isBeingDragged = true; // state to help determine if dragging has ended, then do redux call
      });
      google.maps.event.addListener(overlayObject.mapObj, "dragend", () => {
        const newOverlayObject = {
          id: overlayObject.id,
          ...makeCircleObject(overlayObject.mapObj)
        };
        this.updateOverlayObjectInReduxStore(newOverlayObject);

        isBeingDragged = false; // drag has ended
      });
      google.maps.event.addListener(
        overlayObject.mapObj,
        "radius_changed",
        () => {
          if (!isBeingDragged) {
            const newOverlayObject = {
              id: overlayObject.id,
              ...makeCircleObject(overlayObject.mapObj)
            };
            this.updateOverlayObjectInReduxStore(newOverlayObject);
          }
        }
      );
      google.maps.event.addListener(
        overlayObject.mapObj,
        "center_changed",
        () => {
          if (!isBeingDragged) {
            const newOverlayObject = {
              id: overlayObject.id,
              ...makeCircleObject(overlayObject.mapObj)
            };
            this.updateOverlayObjectInReduxStore(newOverlayObject);
          }
        }
      );
    } else if (overlayObject.type == "rectangle") {
      let isBeingDragged = false;
      google.maps.event.addListener(overlayObject.mapObj, "dragstart", () => {
        isBeingDragged = true;
      });
      google.maps.event.addListener(overlayObject.mapObj, "dragend", () => {
        const newOverlayObject = {
          id: overlayObject.id,
          ...makeRectangleObject(overlayObject.mapObj)
        };
        this.updateOverlayObjectInReduxStore(newOverlayObject);

        isBeingDragged = false;
      });
      google.maps.event.addListener(
        overlayObject.mapObj,
        "bounds_changed",
        () => {
          if (!isBeingDragged) {
            const newOverlayObject = {
              id: overlayObject.id,
              ...makeRectangleObject(overlayObject.mapObj)
            };
            this.updateOverlayObjectInReduxStore(newOverlayObject);
          }
        }
      );
    } else if (overlayObject.type == "polygon") {
      overlayObject.mapObj.getPaths().forEach((path, index) => {
        let isBeingDragged = false;
        google.maps.event.addListener(overlayObject.mapObj, "dragstart", () => {
          isBeingDragged = true;
        });
        google.maps.event.addListener(overlayObject.mapObj, "dragend", () => {
          const newOverlayObject = {
            id: overlayObject.id,
            ...makePolygonObject(overlayObject.mapObj)
          };
          this.updateOverlayObjectInReduxStore(newOverlayObject);

          isBeingDragged = false;
        });
        // insert_at is for existing vertices that moved
        google.maps.event.addListener(path, "insert_at", () => {
          const newOverlayObject = {
            id: overlayObject.id,
            ...makePolygonObject(overlayObject.mapObj)
          };

          this.updateOverlayObjectInReduxStore(newOverlayObject);
        });
        // set_at is for newly created vertices that are now part of the shape
        google.maps.event.addListener(path, "set_at", () => {
          if (!isBeingDragged) {
            const newOverlayObject = {
              id: overlayObject.id,
              ...makePolygonObject(overlayObject.mapObj)
            };

            this.updateOverlayObjectInReduxStore(newOverlayObject);
          }
        });
      });
    }
  };

  // same as above, but listeners are for existing shapes (for editing existing shapes)
  addResizeEventListnerExist = overlayObject => {
    if (overlayObject.type == "circle") {
      let isBeingDragged = false;
      google.maps.event.addListener(overlayObject.mapObj, "dragstart", () => {
        isBeingDragged = true;
      });
      google.maps.event.addListener(overlayObject.mapObj, "dragend", () => {
        const newOverlayObject = {
          id: overlayObject.id,
          ...makeCircleObject(overlayObject.mapObj)
        };
        this.props.updateEditOverlayObject(newOverlayObject);

        isBeingDragged = false;
      });
      google.maps.event.addListener(
        overlayObject.mapObj,
        "radius_changed",
        () => {
          if (!isBeingDragged) {
            const newOverlayObject = {
              id: overlayObject.id,
              ...makeCircleObject(overlayObject.mapObj)
            };
            this.props.updateEditOverlayObject(newOverlayObject);
          }
        }
      );
      google.maps.event.addListener(
        overlayObject.mapObj,
        "center_changed",
        () => {
          if (!isBeingDragged) {
            const newOverlayObject = {
              id: overlayObject.id,
              ...makeCircleObject(overlayObject.mapObj)
            };
            this.props.updateEditOverlayObject(newOverlayObject);
          }
        }
      );
    } else if (overlayObject.type == "rectangle") {
      let isBeingDragged = false;
      google.maps.event.addListener(overlayObject.mapObj, "dragstart", () => {
        isBeingDragged = true;
      });
      google.maps.event.addListener(overlayObject.mapObj, "dragend", () => {
        const newOverlayObject = {
          id: overlayObject.id,
          ...makeRectangleObject(overlayObject.mapObj)
        };
        this.props.updateEditOverlayObject(newOverlayObject);

        isBeingDragged = false;
      });
      google.maps.event.addListener(
        overlayObject.mapObj,
        "bounds_changed",
        () => {
          if (!isBeingDragged) {
            const newOverlayObject = {
              id: overlayObject.id,
              ...makeRectangleObject(overlayObject.mapObj)
            };
            this.props.updateEditOverlayObject(newOverlayObject);
          }
        }
      );
    } else if (overlayObject.type == "polygon") {
      overlayObject.mapObj.getPaths().forEach((path, index) => {
        let isBeingDragged = false;
        google.maps.event.addListener(overlayObject.mapObj, "dragstart", () => {
          isBeingDragged = true;
        });
        google.maps.event.addListener(overlayObject.mapObj, "dragend", () => {
          const newOverlayObject = {
            id: overlayObject.id,
            ...makePolygonObject(overlayObject.mapObj)
          };
          this.props.updateEditOverlayObject(newOverlayObject);

          isBeingDragged = false;
        });
        google.maps.event.addListener(path, "insert_at", () => {
          const newOverlayObject = {
            id: overlayObject.id,
            ...makePolygonObject(overlayObject.mapObj)
          };

          this.props.updateEditOverlayObject(newOverlayObject);
        });
        google.maps.event.addListener(path, "set_at", () => {
          if (!isBeingDragged) {
            const newOverlayObject = {
              id: overlayObject.id,
              ...makePolygonObject(overlayObject.mapObj)
            };

            this.props.updateEditOverlayObject(newOverlayObject);
          }
        });
      });
    }
  };

  render() {
    const mapStyle = {
      width: "100%",
      height: 400
    };

    return (
      <div>
        {this.props.isSearchEnabled && (
          <input
            id="pac-input"
            className="controls"
            type="text"
            placeholder="Search for an address"
            style={{
              width: "300px",
              height: "30px",
              margin: "5px",
              padding: "0 5px"
            }}
          />
        )}
        <div ref="displayMap" style={mapStyle}>
          Loading Map...
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    googleMapsWithOverlayZones: state.googleMapsWithOverlayZones
  };
};

export default connect(
  mapStateToProps,
  actions
)(GoogleMapsWithOverlayZones);
