import React, { useState } from 'react';
import { Formik, Field } from 'formik';
import {
  RenderTextField,
  RenderSelect,
  required,
} from '../../../components/FormElements/FormikElements';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '../../../components/Icon/Icon';
import { Button, ButtonRow } from '../../../components/Button/Button';
import styled from 'styled-components';
import Alert from '../../Alert/Alert';
import PasswordVerifier from '../../PasswordVerifier/PasswordVerifier';
import Typography from '@material-ui/core/Typography';
import { StyledForm, SectionWrapper } from './Styles';
import Modal from '../../../components/Modal/Modal';
import {
  validateEmail,
  validatePasswordField,
  validateConfirmPasswordField,
  validateBusinessField,
} from '../OrderTabletUsersActions';

const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 20px;
  margin-bottom: 5%;
  font-weight: 500;
`;

function AddNewUser(props) {
  const {
    businessesLocations,
    employeeLocations,
    addNewEmployee,
    open,
    close,
    passwordVerifier,
  } = props;
  const [state, setState] = useState({
    passwordMaskState: true,
    passwordConfirmMaskState: true,
  });

  const availableLocations =
    businessesLocations && employeeLocations
      ? businessesLocations.filter(
          (business) => !employeeLocations.includes(business.name)
        )
      : null;

  const isSaveButtonDisabled = (values) =>
    Object.keys(values).some((key) => values[key] === '') ||
    values.passwordConfirm !== values.password;

  return (
    <Modal
      open={open}
      onClose={close}
      style={{ borderRadius: '25px' }}
      disableBackdropClick
    >
      <div>
        <Formik
          initialValues={{
            first_name: '',
            last_name: '',
            email: '',
            password: '',
            passwordConfirm: '',
            business_id: '',
          }}
        >
          {({ values }) => (
            <SectionWrapper style={{ width: '500px', margin: 20 }}>
              <Alert />
              <MessageWrapper>Add New User</MessageWrapper>
              <StyledForm>
                <Field
                  name="first_name"
                  placeholder="First name"
                  component={RenderTextField}
                  validate={required}
                />
                <Field
                  name="last_name"
                  placeholder="Last name"
                  component={RenderTextField}
                  validate={required}
                />
                <Field
                  name="email"
                  placeholder="Enter email"
                  component={RenderTextField}
                  validate={() => validateEmail(values)}
                />
                <Field
                  type={state.passwordMaskState ? 'password' : 'text'}
                  name="password"
                  placeholder="Create New Password"
                  component={RenderTextField}
                  validate={() =>
                    validatePasswordField(values, passwordVerifier)
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon
                          className={
                            state.passwordMaskState
                              ? 'fas fa-eye-slash'
                              : 'fas fa-eye'
                          }
                          style={{ color: 'grey' }}
                          onClick={() =>
                            setState({
                              ...state,
                              passwordMaskState: !state.passwordMaskState,
                            })
                          }
                        />
                      </InputAdornment>
                    ),
                  }}
                />
                <PasswordVerifier
                  password={values.password}
                  passwordConfirm={values.passwordConfirm}
                />
                <Field
                  type={state.passwordConfirmMaskState ? 'password' : 'text'}
                  name="passwordConfirm"
                  placeholder="Confirm New Password"
                  component={RenderTextField}
                  validate={() =>
                    validateConfirmPasswordField(values, passwordVerifier)
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon
                          className={
                            state.passwordConfirmMaskState
                              ? 'fas fa-eye-slash'
                              : 'fas fa-eye'
                          }
                          style={{ color: 'grey' }}
                          onClick={() =>
                            setState({
                              ...state,
                              passwordConfirmMaskState:
                                !state.passwordConfirmMaskState,
                            })
                          }
                        />
                      </InputAdornment>
                    ),
                  }}
                />
                <Field
                  name="business_id"
                  placeholder="Select Location"
                  component={RenderSelect}
                  validate={() =>
                    validateBusinessField(
                      values,
                      businessesLocations,
                      employeeLocations
                    )
                  }
                  options={businessesLocations.map((business) => ({
                    label: !availableLocations.includes(business)
                      ? `${business.name} - Already Assigned`
                      : business.name,
                    value: business.id,
                    isDisabled: !availableLocations.includes(business)
                      ? true
                      : false,
                  }))}
                  availableLocations={availableLocations}
                />
                <ButtonRow
                  style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}
                >
                  <div onClick={() => close()}>
                    <Typography
                      style={{
                        color: 'black',
                        fontWeight: 550,
                        cursor: 'pointer',
                      }}
                    >
                      Cancel
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: 'block',
                      marginLeft: 'auto',
                      marginRight: 0,
                    }}
                  >
                    <Button
                      disabled={isSaveButtonDisabled(values)}
                      onClick={() => {
                        if (values.passwordConfirm === values.password) {
                          delete values.passwordConfirm;
                          addNewEmployee(values, close);
                          // close();
                        }
                      }}
                    >
                      Save
                    </Button>
                  </div>
                </ButtonRow>
              </StyledForm>
            </SectionWrapper>
          )}
        </Formik>
      </div>
    </Modal>
  );
}

export default AddNewUser;
