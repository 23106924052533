import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { LoadingBlueLarge } from 'merchant-portal-components';
import PaginationControl from '../Pagination/PaginationControl';
import { Button } from 'merchant-portal-components';
import { Checkbox, TextField, InputAdornment } from '@material-ui/core';
import { Dialog } from '@material-ui/core';
import {
  StyledCard,
  HeaderWrapper,
  SearchWrapper,
  SectionTitle,
  LocationsCounter,
  RowWrapper,
  TH,
  TD,
  PaginationWrapper,
  ButtonWrapper,
  Left,
  Right,
  CancelButton,
  CloseIcon,
} from './filterModalStyles';
import * as actions from '../../modules/MenuManagement/Products/productsActions';
import { paginate } from './paginate';

const initiallRequestParams = {
  page_number: 1,
  page_size: '10',
  sorting_option: 'title-asc',
};
const FilterProductModal = ({
  allProducts,
  requestParams,
  filteredProducts,
  setFilteredProducts,
  isOpen,
  setIsOpen,
  placeholder,
  searchLabel,
  formField,
  isEmptyAccepted,
  getProducts,
  updateProductsRequestParams,
  clearProductsList,
}) => {
  delete requestParams.has_next_page;
  //
  const [selectedProducts, setSelectedProducts] = useState(
    filteredProducts || []
  );
  useEffect(() => {
    clearProductsList();
    getProducts({
      page_number: 1,
      page_size: '4000',
      search_string: '',
    });
    updateProductsRequestParams(initiallRequestParams);
    return () => {
      updateProductsRequestParams(initiallRequestParams);
    };
  }, []);
  //
  const updateInitials = useCallback(() => {
    if (allProducts && filteredProducts.length === 0 && isEmptyAccepted) {
      const newAllProducts = allProducts.map((product) => product.id);
      setSelectedProducts(newAllProducts.sort());
    }
  }, [allProducts]);
  useEffect(() => {
    updateInitials();
  }, [updateInitials]);

  const [currentPageProducts, setCurrentPageProducts] = useState([]);
  const [productListLength, setProductListLength] = useState([]);
  const [searchedProducts, setSearchedProducts] = useState([]);
  const [searchString, setSearchString] = useState('');
  const filterProductList = useCallback(() => {
    let filteredList = allProducts;
    if (searchString) {
      filteredList = filteredList?.filter((item) =>
        item.name?.toLowerCase().includes(searchString?.toLowerCase())
      );
    }
    let paginatedList = paginate(
      filteredList,
      requestParams.page_number,
      requestParams.page_size
    );
    setSearchedProducts(filteredList);
    setCurrentPageProducts(paginatedList);
    setProductListLength(filteredList.length);
  }, [
    requestParams.page_number,
    requestParams.page_size,
    selectedProducts,
    JSON.stringify(allProducts),
  ]);
  useEffect(() => {
    filterProductList();
  }, [filterProductList]);
  useEffect(() => {
    if (allProducts?.length > 0) {
      const filteredList = allProducts?.filter((item) =>
        item.name?.toLowerCase().includes(searchString?.toLowerCase())
      );
      setSearchedProducts(filteredList);
      const paginatedList = paginate(filteredList, 1, requestParams.page_size);
      updateProductsRequestParams({ ...requestParams, page_number: 1 });
      setCurrentPageProducts(paginatedList);
      setProductListLength(filteredList.length);
    }
  }, [searchString, allProducts]);
  ///
  useEffect(() => {}, []);
  const handleAssignProducts = (e, id) => {
    let newSelectedProducts = [...selectedProducts];
    let isSelected = selectedProducts.includes(id);
    if (isSelected) {
      newSelectedProducts = selectedProducts.filter(
        (currentId) => currentId !== id
      );
    } else {
      newSelectedProducts.push(id);
    }
    setSelectedProducts(newSelectedProducts);
  };
  const handleAssignAllProducts = (e) => {
    let isSelected = e.target.checked;
    let newSelectedProducts = [];
    if (isSelected) {
      searchedProducts.map((product) => {
        newSelectedProducts.push(product.id);
      });
    }
    setSelectedProducts(newSelectedProducts);
  };
  const handleAddProducts = () => {
    const isAllProductSelected = allProducts.length === selectedProducts.length;
    let newSelectedProducts =
      isAllProductSelected && isEmptyAccepted ? [] : selectedProducts;
    if (formField) {
      setFilteredProducts(formField, newSelectedProducts);
    } else {
      setFilteredProducts(newSelectedProducts);
    }
    setIsOpen(false);
  };
  const handleReset = () => {
    if (filteredProducts.length === 0 && !isEmptyAccepted) {
      const newAllProducts = allProducts.map((product) => product.id);
      setSelectedProducts(newAllProducts.sort());
    } else {
      setSelectedProducts(filteredProducts.sort());
    }
  };
  const isSaveDisabled = () => {
    let isDisabled = true;
    let isEqual = _.isEqual(filteredProducts.sort(), selectedProducts.sort());

    if (allProducts) {
      if (isEmptyAccepted && !isEqual) {
        isDisabled = false;
      } else if (
        !isEmptyAccepted &&
        selectedProducts.length !== 0 &&
        !isEqual
      ) {
        isDisabled = false;
      }
    }
    return isDisabled;
  };
  return (
    <Dialog open={isOpen} fullWidth maxWidth='md'>
      <StyledCard>
        <HeaderWrapper padding='0'>
          <div>
            <SectionTitle>Products</SectionTitle>
            <LocationsCounter>
              {`${selectedProducts.length} products selected`}
            </LocationsCounter>
          </div>
          <div style={{ textAlign: 'right' }}>
            <CloseIcon
              className='fa fa-times'
              onClick={() => setIsOpen(false)}
            ></CloseIcon>
          </div>
        </HeaderWrapper>
        <SearchWrapper>
          <TextField
            id='outlined-basic'
            label={searchLabel}
            placeholder={placeholder}
            variant='outlined'
            style={{ width: '100%' }}
            value={searchString}
            onChange={(e) => setSearchString(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <i className='fa fa-search' style={{ color: '#b5b5b5' }}></i>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position='end'>
                  {`${productListLength} results`}
                </InputAdornment>
              ),
            }}
          />
        </SearchWrapper>
        <RowWrapper>
          <TH>
            <Checkbox
              checked={searchedProducts.length === selectedProducts.length}
              onChange={handleAssignAllProducts}
              color='primary'
              style={{
                transform: 'scale(1.3)',
              }}
            />
          </TH>
          <TH>Select All Products</TH>
          {/* <TH>Active</TH> */}
        </RowWrapper>
        {currentPageProducts.map((product, index) => (
          <RowWrapper
            key={index}
            hoverBGColor='#f1f1fa'
            onClick={(e) => handleAssignProducts(e, product.id)}
          >
            <TD bgColor='yellow'>
              <Checkbox
                checked={selectedProducts.includes(product.id)}
                onChange={(e) => handleAssignProducts(e, product.id)}
                color='primary'
                style={{
                  transform: 'scale(1.3)',
                }}
              />
            </TD>
            <TD isAssigned={selectedProducts.includes(product.id)}>
              {product.name}

              {!product.is_active && (
                <>
                  <span
                    style={{
                      marginLeft: '5px',
                      marginRight: '5px',
                    }}
                  >
                    {'-'}
                  </span>
                  <span>Inactive</span>
                </>
              )}
            </TD>
            {/* <TD isAssigned={selectedProducts.includes(product.id)}>
              <Checkbox
                checked={product.is_active}
                color='primary'
                style={{
                  transform: 'scale(1.2)',
                }}
              />
            </TD> */}
          </RowWrapper>
        ))}
        <PaginationWrapper>
          <PaginationControl
            response={{
              ...currentPageProducts,
              page: requestParams.page_number,
              page_size: requestParams.page_size,
              count: allProducts.length,
            }}
            requestParams={requestParams}
            // updateAction={getProducts}
            type='products'
          />
        </PaginationWrapper>
        <ButtonWrapper>
          <Left>
            <CancelButton onClick={handleReset}>Cancel</CancelButton>
          </Left>
          <Right>
            <Button onClick={handleAddProducts} disabled={isSaveDisabled()}>
              Save
            </Button>
          </Right>
        </ButtonWrapper>
      </StyledCard>
    </Dialog>
  );
};
function mapStateToProps(state) {
  return {
    allProducts: state.productsReducer.allProducts,
    requestParams: state.productsReducer.requestParams,
  };
}
export default connect(mapStateToProps, actions)(FilterProductModal);
