import { UPDATE_PASSWORD_REQUEST, UPDATE_PASSWORD_RESPONSE } from './types';

export default function(
  state = {
    isUpdating: false,
    updatePasswordSuccess: false,
    updatePasswordError: false,
    authUserRedux: null
  },
  action
) {
  switch (action.type) {
    case UPDATE_PASSWORD_REQUEST:
      return { ...state, isUpdating: true };
    case UPDATE_PASSWORD_RESPONSE:
      return {
        ...state,
        isUpdating: false,
        updatePasswordSuccess: action.success,
        updatePasswordError: action.error
      };
    case 'GET_AUTH_USER_BUSINESS_ACCOUNTS_SUCCESS':
      return {
        ...state,
        authUserRedux: action.payload
      };
    default:
      return state;
  }
}
