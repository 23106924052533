import React, { useEffect, useCallback, useState } from 'react';
import styled from 'styled-components';
import ChooseOptionVariants from './ChooseOptionVariants';
import NoOptionVariantsSelected from './NoOptionVariantsSelected';
import OptionPricesForm from './OptionPricesForm';
import { Box } from '@material-ui/core';
import { TabContext, TabPanel } from '@material-ui/lab';
import { LoadingBlueLarge } from 'merchant-portal-components';
import { flatten } from 'flat';
const Wrapper = styled.div`
  display: grid;
  grid-gap: 24px;
  margin-top: 25px;
  border-radius: 20px;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
`;
const EmptyMessageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  color: darkgray;
  font-size: 20px;
`;
const AngeledTabList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;
const AngeledTab = styled.li`
  display: inline;
  text-align: 'left';
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  padding: 8px 30px 8px 10px;
  color: ${(props) => (props.isSelected ? '#1e3697' : '#848484')};
  background-color: #fcfcfc;
  cursor: pointer;
  clip-path: polygon(0 0, 85% 0, 100% 100%, 0% 100%);
  box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.09);
`;
const Price = ({
  options,
  variants,
  optionPrices,
  isRequesting,
  itemOverrides,
  isRequestingItemOverrides,
  formattedBusinesses,
  isOptionOverridesModal,
  setIsOptionOverridesModal,
  accociatedProductList,
  getAssociatedProductForOption,
  initiateEditProductWizard,
  getItemsOverrides,
  getProductAssignedBusinesses,
  getOptionPrices,
  putOptionPrices,
  filterOptionsByVariants,
  clearOptionItems,
}) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedItems, setSelectedItems] = useState();
  const [hasSubOption, setHasSubOption] = useState();
  const [selectedOptionOrSubOptionId, setSelectedOptionOrSubOptionId] =
    useState(null);
  const [page, setPage] = useState();
  useEffect(() => {
    if (selectedOption !== null) {
      getProductAssignedBusinesses(selectedOption);
      getOptionPrices(selectedOption);
      getAssociatedProductForOption(selectedOption.id);
    } else {
      clearOptionItems();
    }
  }, [selectedOption]);

  useEffect(() => {
    if (selectedOption) {
      getItemsOverrides(selectedOption, selectedItems);
    }
  }, [selectedItems]);
  const handleSave = (itemsToSave) => {
    putOptionPrices(itemsToSave, selectedOption, selectedOptionOrSubOptionId);
  };
  const [isOptionDisabled, setIsOptionDisabled] = useState(false);
  const [tabValue, setTabValue] = useState('1');
  const handleTabChange = (e) => {
    setTabValue(e.target.value.toString());
  };
  const hasPrices = (selectedItems) => {
    let hasPrices = false;
    if (selectedItems && selectedItems?.length > 0) {
      if (selectedItems[0].prices?.length > 0) {
        hasPrices = true;
      }
      if (
        selectedItems[0].sub_items &&
        selectedItems[0].sub_items[0].prices.length > 0
      ) {
        hasPrices = true;
      }
    }
    return hasPrices;
  };
  return (
    <div>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={tabValue}>
          <AngeledTabList>
            <AngeledTab
              value="1"
              isSelected={tabValue === '1' ? true : false}
              onClick={handleTabChange}
            >
              {'Option Pricing'}
            </AngeledTab>
            {/* <AngeledTab
              value="2"
              isSelected={tabValue === '2' ? true : false}
              onClick={handleTabChange}
            >
              {'Overrides'}
            </AngeledTab> */}
          </AngeledTabList>
          <TabPanel
            value="1"
            style={{
              backgroundColor: '#fff',
              borderStartEndRadius: '20px',
              borderEndEndRadius: '20px',
              borderEndStartRadius: '20px',
              paddingTop: '50px',
              paddingBottom: '50px',
              boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.09)',
            }}
          >
            <ChooseOptionVariants
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              options={options}
              variants={variants}
              optionPrices={optionPrices}
              isRequestingItemOverrides={isRequestingItemOverrides}
              isOptionDisabled={isOptionDisabled}
              accociatedProductList={accociatedProductList}
              setSelectedOptionOrSubOptionId={setSelectedOptionOrSubOptionId}
              selectedOptionOrSubOptionId={selectedOptionOrSubOptionId}
              getAssociatedProductForOption={getAssociatedProductForOption}
              initiateEditProductWizard={initiateEditProductWizard}
              filterOptionsByVariants={filterOptionsByVariants}
              hasSubOption={hasSubOption}
              setHasSubOption={setHasSubOption}
            />
          </TabPanel>
          {/* <TabPanel
            value="2"
            style={{
              backgroundColor: '#fff',
              borderStartEndRadius: '20px',
              borderEndEndRadius: '20px',
              borderEndStartRadius: '20px',
              paddingTop: '50px',
              paddingBottom: '50px',
              boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.09)',
            }}
          >
            Item Two
          </TabPanel> */}
        </TabContext>
      </Box>
      <Wrapper>
        {selectedItems && selectedItems[0]?.prices?.length === 0 && (
          <EmptyMessageWrapper>
            {'Looks like this option is not associated to any product/variant'}
          </EmptyMessageWrapper>
        )}
        {hasPrices(selectedItems) && (
          <OptionPricesForm
            includedPick={optionPrices.included_pick}
            items={selectedItems}
            handleSave={handleSave}
            isRequesting={isRequesting}
            setSelectedOption={setSelectedOption}
            itemOverrides={itemOverrides}
            selectedOption={selectedOption}
            optionPrices={optionPrices}
            isRequestingItemOverrides={isRequestingItemOverrides}
            formattedBusinesses={formattedBusinesses}
            isOptionOverridesModal={isOptionOverridesModal}
            setIsOptionOverridesModal={setIsOptionOverridesModal}
            page={page}
            setPage={setPage}
            isOptionDisabled={isOptionDisabled}
            setIsOptionDisabled={setIsOptionDisabled}
          />
        )}
      </Wrapper>
    </div>
  );
};

export default Price;
