import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import fetchOnParamChange from '../../HOCs/fetchOnParamChange';
import * as actions from '../OfferEngineActions';
import BreadCrumb from '../../../components/BreadCrumb/BreadCrumb';
import OffersEngineList from './OffersEngineList';
import { Button } from 'merchant-portal-components';
import { sort } from '../../../utils/sort/sort';
import OfferSortModal from '../components/OfferSortModal';
import CreateOfferTypeModal from '../components/CreateOfferTypeModal';

let OffersEngineListContainer = ({
  OfferEngineReducer,
  toggleOfferAvailability,
  initiateCreateOffer,
  initiateEditOfferEngine,
  meta,
  updateRequestParams,
  updateOffersRequestParams,
  getOffersEngine,
  sortOffer,
  updateSortedOffersList,
  offersList,
  updateOfferReorderingRequestParams,
  allOffersPositions,
  updateOffersPosition,
  offerReorderRequestParams,
  getOffersPosition,
}) => {
  useEffect(() => {
    getOffersPosition();
  }, []);

  const { allOffersEngine, requestingGetOffers, requestParams } =
    OfferEngineReducer;
  const [searchString, setSearchString] = useState('');
  const [isCreateOfferTypeOpen, setIsCreateOfferTypeOpen] = useState(false);
  const [isSortModalOpen, setIsSortModalOpen] = useState(false);
  useEffect(() => {
    let debounce = setTimeout(() => {
      const newRequestParams = {
        ...requestParams,
        page_number: 1,
        search_string: searchString,
      };
      if (searchString.length > 2 || searchString === '') {
        getOffersEngine(newRequestParams);
      }
      updateOffersRequestParams(newRequestParams);
    }, 1000);
    return () => {
      clearTimeout(debounce);
      updateOffersRequestParams({
        page_number: 1,
        page_size: '25',
        sorting_option: 'title-asc',
        search_string: '',
        has_next_page: true,
      });
    };
  }, [searchString]);
  return (
    <Fragment>
      <BreadCrumb />
      <OffersEngineList
        allOffers={allOffersEngine}
        setSearchString={setSearchString}
        searchString={searchString}
        requestParams={requestParams}
        updateRequestParams={updateRequestParams}
        getOffersEngine={getOffersEngine}
        initiateEditOfferEngine={initiateEditOfferEngine}
        initiateCreateOffer={initiateCreateOffer}
        toggleOfferAvailability={toggleOfferAvailability}
        setIsSortModalOpen={setIsSortModalOpen}
        setIsCreateOfferTypeOpen={setIsCreateOfferTypeOpen}
        requestingGetOffers={requestingGetOffers}
      />
      {isSortModalOpen && (
        <OfferSortModal
          offersList={offersList}
          setIsSortModalOpen={setIsSortModalOpen}
          allOffersPositions={allOffersPositions}
          offerReorderRequestParams={offerReorderRequestParams}
          updateOfferReorderingRequestParams={
            updateOfferReorderingRequestParams
          }
          updateOffersPosition={updateOffersPosition}
        />
      )}
      {isCreateOfferTypeOpen && (
        <CreateOfferTypeModal
          offersList={offersList}
          setIsCreateOfferTypeOpen={setIsCreateOfferTypeOpen}
          allOffersPositions={allOffersPositions}
          offerReorderRequestParams={offerReorderRequestParams}
          updateOfferReorderingRequestParams={
            updateOfferReorderingRequestParams
          }
          updateOffersPosition={updateOffersPosition}
          initiateEditOfferEngine={initiateEditOfferEngine}
          initiateCreateOffer={initiateCreateOffer}
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  OfferEngineReducer: state.OfferEngineReducer,
  offersList: state.OfferEngineReducer.offersList,
  isSavingOfferPositions: state.OfferEngineReducer.isSavingOfferPositions,
  offerReorderRequestParams: state.OfferEngineReducer.offerReorderRequestParams,
  meta: state.OfferEngineReducer.meta,
});

export default connect(mapStateToProps, actions)(OffersEngineListContainer);
