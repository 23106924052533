import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Button } from 'merchant-portal-components';
import _ from 'lodash';
import {
  FormGroup,
  FormControlLabel,
  TextField,
  Checkbox,
  Switch,
  RadioGroup,
  Radio,
  Tooltip,
  Collapse,
  IconButton,
} from '@material-ui/core';
import {
  StyledCard,
  SectionTitle,
  SectionSubTitle,
  FieldLabel,
  CentralizedWrapper,
  CurrencyInputWrapper,
  CurrencyInput,
  Symbol,
  ButtonWrapper,
  Left,
  Right,
  CancelButton,
} from './Styles/ServiceFeeDetailStyles';
import styled from 'styled-components';

const PencilIcon = styled.i`
  color: #fff;
  cursor: pointer;
  &:hover {
    color: #6f6f76;
  }
`;

const StyledBtn = styled.button`
  border: none;
  border-radius: 25px;
  min-height: 45px;
  padding: 0 20px;
  color: #fff;
  font-weight: 600;
  background: #2440ae;
  margin-right: inherit;
  min-width: 160px;
  cursor: pointer;
`;

const validationSchema = Yup.object().shape({
  display_name: Yup.string().required('Required'),
  order_types: Yup.array().required('Required'),
  config: Yup.object().shape({
    fee_amount: Yup.number().when('type', {
      is: 'percent',
      then: Yup.number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .min(0)
        .max(100, 'No more than 100')
        .required('Required'),
      otherwise: Yup.number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .min(0)
        .required('Required'),
    }),
    min_fee_amount: Yup.number()
      .transform((value) => (!value ? null : value))
      .nullable(true),
    max_fee_amount: Yup.number()
      .transform((value) => (!value ? null : value))
      .nullable(true),
    threshold: Yup.number()
      .transform((value) => (!value ? null : value))
      .nullable(true),
    threshold_fee: Yup.number()
      .transform((value) => (!value ? null : value))
      .nullable(true),
  }),
});
const ServiceFeeDetail = (props) => {
  const { isEditing, editingFee } = props.feeReducer;
  const { orderTypesList, createFee, editFee } = props;
  const [orderIDsList, setOrderIDsList] = useState([]);
  const [minFee, setMinFee] = useState(false);
  const [maxFee, setMaxFee] = useState(false);
  const [thresholdFee, setThresholdFee] = useState(false);
  //
  const [minDollarSign, setMinDollarSign] = useState(false);
  const [maxDollarSign, setMaxDollarSign] = useState(false);
  const [thresholdDollarSign, setThresholdDollarSign] = useState(false);
  const [thresholdFeeDollarSign, setThresholdFeeDollarSign] = useState(false);
  //
  const [minBorderColor, setMinBorderColor] = useState('#cdcdcd');
  const [maxBorderColor, setMaxBorderColor] = useState('#cdcdcd');
  const [thresholdBorderColor, setThresholdBorderColor] = useState('#cdcdcd');
  const [thresholdFeeBorderColor, setThresholdFeeBorderColor] =
    useState('#cdcdcd');
  const [showTxtFld, setShowTxtFld] = useState(false);
  const initialOrderTypes = () => {
    const orderTypes =
      isEditing &&
      editingFee.order_types
        .map((type) => {
          const filteredList = orderTypesList.filter(
            (item) =>
              item.type
                .toLowerCase()
                .replaceAll('_', '')
                .replaceAll(' ', '') ===
              type.toLowerCase().replaceAll('_', '').replaceAll(' ', '')
          );
          return filteredList[0]?.id;
        })
        .sort();
    return orderTypes;
  };
  const serviceFee = useMemo(() => {
    let initialServiceFee = {};
    initialServiceFee.display_name = isEditing ? editingFee.display_name : '';
    initialServiceFee.internal_name = isEditing ? editingFee.internal_name : '';
    initialServiceFee.is_active = isEditing ? editingFee.is_active : true;
    initialServiceFee.is_taxable = isEditing ? editingFee.is_taxable : true;
    initialServiceFee.order_types = isEditing ? initialOrderTypes() : [];
    initialServiceFee.config = {
      type: isEditing ? editingFee.config.type : '',
      fee_amount: isEditing ? editingFee.config.fee_amount : '',
      threshold: isEditing ? editingFee.config.threshold || '' : '',
      threshold_fee: isEditing ? editingFee.config.threshold_fee || '' : '',
      max_fee_amount: isEditing ? editingFee.config.max_fee_amount || '' : '',
      min_fee_amount: isEditing ? editingFee.config.min_fee_amount || '' : '',
    };
    return initialServiceFee;
  }, [editingFee]);
  const resetToggles = useCallback(() => {
    if (
      serviceFee.config.min_fee_amount ||
      serviceFee.config.min_fee_amount === 0
    ) {
      setMinFee(true);
      setMinDollarSign(true);
      setMinBorderColor('black');
    } else {
      setMinFee(false);
      setMinBorderColor('#cdcdcd');
    }
    if (
      serviceFee.config.max_fee_amount ||
      serviceFee.config.max_fee_amount === 0
    ) {
      setMaxFee(true);
      setMaxDollarSign(true);
      setMaxBorderColor('black');
    } else {
      setMaxFee(false);
      setMaxBorderColor('#cdcdcd');
    }
    if (serviceFee.config.threshold || serviceFee.config.threshold === 0) {
      setThresholdFee(true);
      setThresholdDollarSign(true);
      setThresholdBorderColor('black');
    } else {
      setThresholdFee(false);
      setThresholdBorderColor('#cdcdcd');
    }
    if (
      serviceFee.config.threshold_fee ||
      serviceFee.config.threshold_fee === 0
    ) {
      setThresholdFee(true);
      setThresholdFeeDollarSign(true);
      setThresholdFeeBorderColor('black');
    } else {
      setThresholdFeeDollarSign(false);
      setThresholdFeeBorderColor('#cdcdcd');
    }
  }, [editingFee]);

  useEffect(() => {
    resetToggles();
  }, [resetToggles]);
  const createOrderTypeIdList = useCallback(() => {
    let orderIDsList = [];
    orderTypesList.map((order) => {
      orderIDsList.push(order?.id);
    });
    setOrderIDsList(orderIDsList);
  }, [orderTypesList]);
  useEffect(() => {
    createOrderTypeIdList();
  }, [createOrderTypeIdList]);

  const handleOrderTypeChange = (
    orderTypeValue,
    currentOrderTypeList,
    setFieldValue
  ) => {
    let newOrderList = [];
    if (orderTypeValue === -1) {
      if (_.isEqual(orderIDsList.sort(), currentOrderTypeList.sort())) {
        newOrderList = [];
      } else {
        newOrderList = orderIDsList;
      }
    } else {
      if (currentOrderTypeList.includes(orderTypeValue)) {
        const valueIndex = currentOrderTypeList.sort().indexOf(orderTypeValue);
        currentOrderTypeList.splice(valueIndex, 1);
        newOrderList = currentOrderTypeList;
      } else {
        currentOrderTypeList.push(orderTypeValue);
        newOrderList = currentOrderTypeList;
      }
    }
    setFieldValue('order_types', newOrderList.sort());
  };
  const handleCapFocus = (fieldName, fieldValue) => {
    if (fieldName === 'config.min_fee_amount') {
      setMinDollarSign(true);
      setMinBorderColor('black');
    } else if (fieldName === 'config.max_fee_amount') {
      setMaxBorderColor('black');
      setMaxDollarSign(true);
    } else if (fieldName === 'config.threshold') {
      setThresholdDollarSign(true);
      setThresholdBorderColor('black');
    } else {
      setThresholdFeeDollarSign(true);
      setThresholdFeeBorderColor('black');
    }
  };
  const handleCapBlur = (fieldName, fieldValue) => {
    if (fieldName === 'config.min_fee_amount') {
      if (fieldValue) {
        setMinBorderColor('black');
        setMinDollarSign(true);
      } else {
        setMinDollarSign(false);
        setMinBorderColor('#cdcdcd');
      }
    } else if (fieldName === 'config.max_fee_amount') {
      if (fieldValue) {
        setMaxDollarSign(true);
        setMaxBorderColor('black');
      } else {
        setMaxDollarSign(false);
        setMaxBorderColor('#cdcdcd');
      }
    } else if (fieldName === 'config.threshold') {
      if (fieldValue) {
        setThresholdDollarSign(true);
        setThresholdBorderColor('black');
      } else {
        setThresholdDollarSign(false);
        setThresholdBorderColor('#cdcdcd');
      }
    } else {
      if (fieldValue) {
        setThresholdFeeDollarSign(true);
        setThresholdFeeBorderColor('black');
      } else {
        setThresholdFeeDollarSign(false);
        setThresholdFeeBorderColor('#cdcdcd');
      }
    }
  };
  const handleToggleConfigs = (
    checked,
    fieldName,
    fieldValue,
    setFieldValue,
    fieldName2 = null,
    fieldValue2 = null
  ) => {
    if (fieldName === 'config.min_fee_amount') {
      setMinFee(!checked);
      setFieldValue(fieldName, '');
      setMinDollarSign(false);
      setMinBorderColor('#cdcdcd');
    } else if (fieldName === 'config.max_fee_amount') {
      setMaxFee(!checked);
      setFieldValue(fieldName, '');
      setMaxDollarSign(false);
      setMaxBorderColor('#cdcdcd');
    } else {
      setThresholdFee(!checked);
      setFieldValue(fieldName, '');
      setFieldValue(fieldName2, '');
      setThresholdDollarSign(false);
      setThresholdFeeDollarSign(false);
      setThresholdBorderColor('#cdcdcd');
      setThresholdFeeBorderColor('#cdcdcd');
    }
  };
  const isFormDirty = (values) => {
    let newServiceFee = { ...serviceFee };
    newServiceFee.order_types = initialOrderTypes();
    return !_.isEqual(newServiceFee, values);
  };
  const handleResetForm = (setValues) => {
    let newServiceFee = { ...serviceFee };
    newServiceFee.order_types = initialOrderTypes();
    setValues(newServiceFee, false);
    resetToggles();
  };

  return (
    <StyledCard>
      <Formik
        initialValues={serviceFee}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={(values) => {
          let serviceFee = { ...values };
          serviceFee.internal_name = serviceFee.display_name;
          if (
            (!serviceFee.config.min_fee_amount &&
              serviceFee.config.min_fee_amount !== 0) ||
            serviceFee.config.type !== 'percent'
          ) {
            delete serviceFee.config.min_fee_amount;
          }
          if (
            (!serviceFee.config.max_fee_amount &&
              serviceFee.config.max_fee_amount !== 0) ||
            serviceFee.config.type !== 'percent'
          ) {
            delete serviceFee.config.max_fee_amount;
          }
          if (
            !serviceFee.config.threshold &&
            serviceFee.config.threshold !== 0
          ) {
            delete serviceFee.config.threshold;
            delete serviceFee.config.threshold_fee;
          }
          if (
            !serviceFee.config.threshold_fee &&
            serviceFee.config.threshold_fee !== 0
          ) {
            delete serviceFee.config.threshold;
            delete serviceFee.config.threshold_fee;
          }
          if (isEditing) {
            editFee(serviceFee, editingFee?.id);
          } else {
            createFee(serviceFee);
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          setValues,
        }) => (
          <Form
            onSubmit={handleSubmit}
            onReset={() => handleResetForm(setValues)}
          >
            {console.log('values:', values)}
            <CentralizedWrapper>
              <TextField
                id='outlined-name'
                label='Service Fee Title'
                name='display_name'
                value={values.display_name}
                variant='outlined'
                placeholder='Service Fee Title'
                onChange={handleChange}
                onBlur={handleBlur}
                style={{
                  width: '300px',
                }}
                error={errors.display_name && touched.display_name}
              />
              <Tooltip
                style={{ marginRight: 'auto' }}
                title='This title will be visible to the customer on the checkout and order history screen.'
                placement='right-start'
              >
                <i
                  style={{ marginLeft: '5px' }}
                  className='fal fa-info-circle'
                ></i>
              </Tooltip>
            </CentralizedWrapper>
            {errors.display_name && touched.display_name && (
              <div
                style={{
                  color: 'red',
                  fontSize: '12px',
                  marginTop: '3px',
                }}
              >
                {errors.display_name}
              </div>
            )}
            <SectionTitle margin='35px 0 0 0'>Service Fee Types</SectionTitle>
            <RadioGroup
              aria-label='gender'
              name='config.type'
              value={values.config.type}
              onChange={handleChange}
            >
              <div>
                <FormControlLabel
                  value='percent'
                  control={<Radio color='primary' />}
                  label={<FieldLabel>Percentage of Subtotal</FieldLabel>}
                />
              </div>
              <div>
                <FormControlLabel
                  value='fixed'
                  control={<Radio color='primary' />}
                  label={<FieldLabel>Fixed Dollar Amount</FieldLabel>}
                />
              </div>
            </RadioGroup>
            <Collapse in={values?.config?.type ? true : false}>
              {isEditing && !showTxtFld && (
                <div>
                  <label>
                    {values?.config?.type === 'percent'
                      ? 'Service Fee Percentage'
                      : 'Service Fee Amount'}
                    <IconButton style={{ fontSize: '13px' }} size='small'>
                      {values?.config?.type === 'percent' ? '%' : '$'}
                    </IconButton>{' '}
                    : {editingFee?.config?.fee_amount}
                  </label>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <StyledBtn
                    onClick={() => {
                      setShowTxtFld(true);
                    }}
                  >
                    Edit &nbsp;&nbsp;
                    <PencilIcon className='fa fa-pencil' />
                  </StyledBtn>
                </div>
              )}
              {(!isEditing || showTxtFld) && (
                <TextField
                  id='outlined-name'
                  type='number'
                  name='config.fee_amount'
                  label={
                    values.config.type === 'percent'
                      ? 'Service Fee Percentage'
                      : 'Service Fee Amount'
                  }
                  value={values.config.fee_amount}
                  variant='outlined'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  InputProps={{
                    startAdornment: (
                      <IconButton size='small'>
                        {values.config.type === 'percent' ? '%' : '$'}
                      </IconButton>
                    ),
                  }}
                  error={
                    errors.config?.fee_amount && touched.config?.fee_amount
                  }
                  style={{ width: '300px', marginTop: '16px' }}
                />
              )}
              {errors.config?.fee_amount && touched.config?.fee_amount && (
                <div
                  style={{ color: 'red', fontSize: '12px', marginTop: '3px' }}
                >
                  {errors.config?.fee_amount}
                </div>
              )}
              <div style={{ marginTop: '24px', marginBottom: '36px' }}>
                <Checkbox
                  name='is_taxable'
                  checked={values.is_taxable}
                  onChange={handleChange}
                  color='primary'
                  style={{
                    marginLeft: 0,
                    marginRight: '14px',
                    marginBottom: '3px',
                    padding: 0,
                  }}
                />
                <FieldLabel weight='normal'>Fee is Taxable</FieldLabel>
              </div>
              <SectionTitle>Order Type</SectionTitle>
              <SectionSubTitle>Select all that apply</SectionSubTitle>

              <FormGroup>
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color='primary'
                        checked={_.isEqual(
                          orderIDsList?.sort(),
                          values?.order_types?.sort()
                        )}
                        onChange={() =>
                          handleOrderTypeChange(
                            -1,
                            values?.order_types,
                            setFieldValue
                          )
                        }
                      />
                    }
                    label={
                      <FieldLabel weight='600'>All Order Types</FieldLabel>
                    }
                  />
                </div>
                {orderTypesList.map((order) => (
                  <div key={order?.id}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color='primary'
                          name='order_types'
                          checked={
                            values.order_types.includes(-1)
                              ? true
                              : values.order_types.includes(order.id)
                          }
                          onChange={() =>
                            handleOrderTypeChange(
                              order.id,
                              values.order_types,
                              setFieldValue
                            )
                          }
                          onBlur={handleBlur}
                        />
                      }
                      label={<FieldLabel>{order.type}</FieldLabel>}
                    />
                  </div>
                ))}
              </FormGroup>
              {errors.order_types && touched.order_types && (
                <div
                  style={{ color: 'red', fontSize: '12px', marginTop: '3px' }}
                >
                  {errors.order_types}
                </div>
              )}
              <SectionTitle margin='35px 0 0 0'>Service Fee Cap</SectionTitle>
              <FormGroup>
                {values.config?.type && values.config?.type === 'percent' && (
                  <>
                    <div>
                      <FormControlLabel
                        control={
                          <Switch
                            name='minFee'
                            checked={minFee}
                            onChange={() =>
                              handleToggleConfigs(
                                minFee,
                                'config.min_fee_amount',
                                values.config.min_fee_amount,
                                setFieldValue
                              )
                            }
                            color='primary'
                          />
                        }
                        style={{ marginTop: '19px' }}
                        label={
                          <FieldLabel>
                            Minimum Service Fee
                            <Tooltip
                              style={{ marginRight: 'auto' }}
                              title='The minimum $ amount to charge on an order regardless of your Service Fee percentage.'
                              placement='right-start'
                            >
                              <i
                                style={{ marginLeft: '5px' }}
                                className='fal fa-info-circle'
                              ></i>
                            </Tooltip>
                          </FieldLabel>
                        }
                      />
                    </div>
                    {minFee && (
                      <>
                        <CentralizedWrapper>
                          <FieldLabel>
                            The minimum Service Fee possible is
                          </FieldLabel>
                          <CurrencyInputWrapper
                            width={170}
                            borderColor={minBorderColor}
                          >
                            <Symbol>{minDollarSign && '$'}</Symbol>
                            <CurrencyInput
                              type='number'
                              step='any'
                              name='config.min_fee_amount'
                              value={values.config.min_fee_amount}
                              onFocus={() => {
                                handleCapFocus(
                                  'config.min_fee_amount',
                                  values.config.min_fee_amount
                                );
                              }}
                              onBlur={() =>
                                handleCapBlur(
                                  'config.min_fee_amount',
                                  values.config.min_fee_amount
                                )
                              }
                              placeholder={minDollarSign ? '' : 'e.g. $1.00'}
                              onChange={handleChange}
                            />
                          </CurrencyInputWrapper>
                          {errors.config?.min_fee_amount &&
                            touched.config?.min_fee_amount && (
                              <div
                                style={{
                                  color: 'red',
                                  fontSize: '12px',
                                  marginTop: '3px',
                                }}
                              >
                                {errors.config?.min_fee_amount}
                              </div>
                            )}
                        </CentralizedWrapper>
                      </>
                    )}
                    <div>
                      <FormControlLabel
                        control={
                          <Switch
                            name='maxFee'
                            checked={maxFee}
                            onChange={() =>
                              handleToggleConfigs(
                                maxFee,
                                'config.max_fee_amount',
                                values.config.max_fee_amount,
                                setFieldValue
                              )
                            }
                            color='primary'
                          />
                        }
                        style={{ marginTop: '19px' }}
                        label={
                          <FieldLabel>
                            Maximum Service Fee
                            <Tooltip
                              style={{ marginRight: 'auto' }}
                              title='The maximum $ amount to charge on an order regardless of your Service Fee percentage.'
                              placement='right-start'
                            >
                              <i
                                style={{ marginLeft: '5px' }}
                                className='fal fa-info-circle'
                              ></i>
                            </Tooltip>
                          </FieldLabel>
                        }
                      />
                    </div>
                    {maxFee && (
                      <CentralizedWrapper>
                        <FieldLabel>
                          The maximum Service Fee possible is
                        </FieldLabel>
                        <div>
                          <CurrencyInputWrapper
                            width={170}
                            borderColor={maxBorderColor}
                          >
                            <Symbol>
                              {(maxDollarSign ||
                                values.config.max_fee_amount) &&
                                '$'}
                            </Symbol>
                            <CurrencyInput
                              type='number'
                              step='any'
                              name='config.max_fee_amount'
                              value={values.config.max_fee_amount}
                              onFocus={() => {
                                handleCapFocus(
                                  'config.max_fee_amount',
                                  values.config.max_fee_amount
                                );
                              }}
                              onBlur={() =>
                                handleCapBlur(
                                  'config.max_fee_amount',
                                  values.config.max_fee_amount
                                )
                              }
                              placeholder={maxDollarSign ? '' : 'e.g. $10.00'}
                              onChange={handleChange}
                            />
                          </CurrencyInputWrapper>

                          {errors.config?.max_fee_amount &&
                            touched.config?.max_fee_amount && (
                              <div
                                style={{
                                  color: 'red',
                                  fontSize: '12px',
                                  marginTop: '3px',
                                }}
                              >
                                {errors.config?.max_fee_amount}
                              </div>
                            )}
                        </div>
                      </CentralizedWrapper>
                    )}
                  </>
                )}
                <div>
                  <FormControlLabel
                    control={
                      <Switch
                        name='thresholdFee'
                        checked={thresholdFee}
                        onChange={() =>
                          handleToggleConfigs(
                            thresholdFee,
                            'config.threshold',
                            values.config.threshold,
                            setFieldValue,
                            'config.threshold_fee',
                            values.config.threshold_fee
                          )
                        }
                        color='primary'
                      />
                    }
                    style={{ marginTop: '19px' }}
                    label={
                      <FieldLabel>
                        Service Fee Threshold
                        <Tooltip
                          style={{ marginRight: 'auto' }}
                          title='The subtotal dollar limit in which a reduced Service Fee should be charged on an order. The reduced Service Fee should be equal to or less than the maximum Service Fee (if applicable).'
                          placement='right-start'
                        >
                          <i
                            style={{ marginLeft: '5px' }}
                            className='fal fa-info-circle'
                          ></i>
                        </Tooltip>
                      </FieldLabel>
                    }
                  />
                  {thresholdFee && (
                    <>
                      <CentralizedWrapper>
                        <FieldLabel>Any subtotal above </FieldLabel>
                        <div>
                          <CurrencyInputWrapper
                            width={170}
                            borderColor={thresholdBorderColor}
                          >
                            {thresholdDollarSign ||
                            values.config.threshold !== '' ? (
                              <Symbol>{'$'}</Symbol>
                            ) : (
                              <span></span>
                            )}
                            <CurrencyInput
                              type='number'
                              step='any'
                              name='config.threshold'
                              value={values.config.threshold}
                              onFocus={() => {
                                handleCapFocus(
                                  'config.threshold',
                                  values.config.threshold
                                );
                              }}
                              onBlur={() =>
                                handleCapBlur(
                                  'config.threshold',
                                  values.config.threshold
                                )
                              }
                              placeholder={
                                thresholdDollarSign ? '' : 'e.g. $50.00'
                              }
                              onChange={handleChange}
                            />
                          </CurrencyInputWrapper>
                          {errors.config?.threshold &&
                            touched.config?.threshold && (
                              <div
                                style={{
                                  color: 'red',
                                  fontSize: '12px',
                                  marginTop: '3px',
                                }}
                              >
                                {errors.config?.threshold}
                              </div>
                            )}
                        </div>
                        <FieldLabel>will have a Service Fee of </FieldLabel>
                        <div>
                          <CurrencyInputWrapper
                            width={170}
                            borderColor={thresholdFeeBorderColor}
                          >
                            {thresholdFeeDollarSign ||
                            values.config.threshold_fee !== '' ? (
                              <Symbol>{'%'}</Symbol>
                            ) : (
                              <span></span>
                            )}
                            <CurrencyInput
                              type='number'
                              step='any'
                              name='config.threshold_fee'
                              value={values.config.threshold_fee}
                              onFocus={() => {
                                handleCapFocus(
                                  'config.threshold_fee',
                                  values.config.threshold_fee
                                );
                              }}
                              onBlur={() =>
                                handleCapBlur(
                                  'config.threshold_fee',
                                  values.config.threshold_fee
                                )
                              }
                              placeholder={
                                thresholdFeeDollarSign ? '' : 'e.g. %10'
                              }
                              onChange={handleChange}
                            />
                          </CurrencyInputWrapper>
                          {errors.config?.threshold_fee &&
                            touched.config?.threshold_fee && (
                              <div
                                style={{
                                  color: 'red',
                                  fontSize: '12px',
                                  marginTop: '3px',
                                }}
                              >
                                {errors.config?.threshold_fee}
                              </div>
                            )}
                        </div>
                      </CentralizedWrapper>
                    </>
                  )}
                </div>
              </FormGroup>
            </Collapse>
            <ButtonWrapper>
              <Left>
                {/* <CancelButton type='reset'>Cancel</CancelButton> */}
              </Left>
              <Right>
                <Button
                  type='submit'
                  disabled={
                    !(Object.keys(errors).length === 0 && isFormDirty(values))
                  }
                >
                  Save
                </Button>
              </Right>
            </ButtonWrapper>
          </Form>
        )}
      </Formik>
      <CancelButton onClick={props.loadServiceFeeListPage}>Cancel</CancelButton>
    </StyledCard>
  );
};
export default ServiceFeeDetail;
