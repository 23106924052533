import React, { Fragment, useState } from 'react';
import {
  RenderTextField,
  required,
} from '../../../../../../components/FormElements/FormikElements';
import styled from 'styled-components';
import { Formik, Form, Field } from 'formik';
import { Collapse } from '@material-ui/core';
import ChevronUpIcon from '../../../../../../components/Icon/ChevronUpIcon';
import ChevronDownIcon from '../../../../../../components/Icon/ChevronDownIcon';
import { Button, ButtonRow } from 'merchant-portal-components';
import ConfirmCancelModal from '../../../../../MenuManagement/Products/ProductWizard/components/ConfirmCancelModal';
import {
  Title,
  TitleAndCollapseButton,
  Card,
  FormWrapper,
  FieldWrapper,
  Headline,
} from '../../AddressAndDetails/components/styles';

const ProviderName = styled.div`
  font-weight: 600;
  text-transform: capitalize;
`;

const PaymentIntegrationForm = ({
  businessId,
  paymentIntegrationSchema,
  initialValues,
  handleSubmit,
  deactivateSection,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [confirmCancelModalIsVisible, setConfirmCancelModalIsVisible] =
    useState(false);

  return (
    <Card disabled={businessId && !deactivateSection ? false : 'true'}>
      <Formik initialValues={initialValues} enableReinitialize={true}>
        {({ values, handleReset, dirty, isValid, setFieldValue }) => (
          <Fragment>
            <TitleAndCollapseButton>
              <Title
                style={{ textTransform: 'capitalize' }}
                disabled={businessId && !deactivateSection ? false : 'true'}
              >
                Integrations
              </Title>
              {!isExpanded ? (
                <ChevronDownIcon
                  disabled={businessId && !deactivateSection ? false : true}
                  style={{
                    opacity: businessId && !deactivateSection ? 'none' : '0.5',
                  }}
                  onClick={() => {
                    setIsExpanded(true);
                  }}
                />
              ) : (
                <ChevronUpIcon
                  disabled={dirty}
                  onClick={() => {
                    setIsExpanded(false);
                  }}
                />
              )}
            </TitleAndCollapseButton>
            <Collapse in={isExpanded}>
              <Form>
                <FormWrapper>
                  <FieldWrapper grid='1fr 1fr 1.5fr 2fr'>
                    <Headline>Payment Type</Headline>
                    <Headline>Provider</Headline>
                  </FieldWrapper>
                  {values?.credit_card &&
                    values?.credit_card?.provider !== 'moneris' && (
                      <FieldWrapper grid='1fr 1fr 1.5fr 2fr'>
                        <div>Credit Card</div>
                        <ProviderName>
                          {values.credit_card.provider}
                        </ProviderName>
                        {paymentIntegrationSchema.credit_card[
                          values.credit_card.provider
                        ].map((config, index) => {
                          return (
                            <Field
                              key={index}
                              name={`credit_card.configuration.${config.name}`}
                              component={RenderTextField}
                              label={
                                /^sub/.test(config.name)
                                  ? `${config.name
                                      .split('_')
                                      .join(' ')
                                      .slice(3)} *`
                                  : `${config.name.split('_').join(' ')} *`
                              }
                              validate={required}
                              disabled={
                                values?.credit_card?.provider === 'stripe'
                              }
                            />
                          );
                        })}
                      </FieldWrapper>
                    )}
                  {values.gift_card && (
                    <FieldWrapper grid='1fr 1fr 1fr 2fr'>
                      <div>Gift Card</div>
                      <ProviderName>{values.gift_card.provider}</ProviderName>
                      {paymentIntegrationSchema.gift_card[
                        values.gift_card.provider
                      ].map((config, index) => {
                        return (
                          <Field
                            key={index}
                            name={`gift_card.configuration.${config.name}`}
                            component={RenderTextField}
                            label={`${config.name.split('_').join(' ')} *`}
                            validate={required}
                          />
                        );
                      })}
                    </FieldWrapper>
                  )}
                  <ButtonRow
                    justifyContent='space-between'
                    style={{
                      margin: '24px 40px 24px 40px',
                      width: 'inherit',
                    }}
                  >
                    <Button
                      secondary
                      onClick={() => {
                        dirty
                          ? setConfirmCancelModalIsVisible(true)
                          : setIsExpanded(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      disabled={
                        !dirty ||
                        !isValid ||
                        values?.credit_card?.provider === 'stripe'
                      }
                      onClick={() => {
                        handleSubmit(values);
                      }}
                    >
                      Save
                    </Button>
                  </ButtonRow>
                  {confirmCancelModalIsVisible && (
                    <ConfirmCancelModal
                      onCancel={() => setConfirmCancelModalIsVisible(false)}
                      onConfirm={() => {
                        handleReset();
                        setConfirmCancelModalIsVisible(false);
                        setIsExpanded(false);
                      }}
                    />
                  )}
                </FormWrapper>
              </Form>
            </Collapse>
          </Fragment>
        )}
      </Formik>
    </Card>
  );
};

export default PaymentIntegrationForm;
