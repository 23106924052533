import styled from 'styled-components';
import Multiselect from 'react-widgets/lib/Multiselect';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import simpleNumberLocalizer from 'react-widgets-simple-number';
import NumberPicker from 'react-widgets/lib/NumberPicker';

simpleNumberLocalizer();

export const ModalBody = styled.div`
  background: ${(props) => props.theme.white};
  display: grid;
  grid-gap: ${(props) => (props.gridGap ? props.gridGap : '24px')};
  border-radius: 2px;
  padding: ${(props) => (props.padding ? props.padding : '16px')};
  width: ${(props) =>
    props.small ? '400px' : props.medium ? '600px ' : '800px'};
  p {
    font-size: 18px;
  }
`;

export const FormGrid = styled.div`
  display: grid;
  grid-gap: 20px;
`;

export const FormRow = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: ${(props) => (props.alt ? '1fr' : '1fr 1fr')};
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;

export const StyledP = styled.p`
  font-weight: ${(props) => (props.semiBold ? '600' : 'inherit')};
  font-size: ${(props) => props.size || 'inherit'};
`;

export const BatchDetailPage = styled.div`
  margin-bottom: 30px;
  display: grid;
  grid-gap: 16px;
  @media print {
    margin-top: -15px;
    margin-left: -15px;
    margin-right: -15px;
    font-size: 14px;
    page-break-after: always;
  }
`;

export const StyledLabel = styled.div`
  font-weight: 600;
  z-index: 8;
`;

export const ComponentCard = styled.div`
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.25);
  background-color: ${(props) => props.theme.white};
  padding: 0 0 37px 0;
  border-radius: 20px;
`;

export const BoldCapitalizedLabel = styled.div`
  padding: 10px 0;
  font-weight: bold;
  color: ${(props) => props.theme.darkBlue};
  text-transform: uppercase;
`;

export const Input = styled.input`
  font-size: 16px !important;
  background-color: ${(props) =>
    props.light ? props.theme.white : props.theme.lightGray};
  border: none;
  border-radius: none;
  height: 40px;
  width: 100%;
  padding: 10px;
  color: ${(props) => props.theme.darkBlue};
  font-weight: 600;
  &:focus {
    outline: none !important;
    border: 1px solid ${(props) => props.theme.blue};
  }
`;

export const TextArea = styled.textarea`
  font-size: 1em;
  background-color: ${(props) => props.theme.lightGray};
  border: none;
  border-radius: none;
  height: 100px;
  width: 100%;
  padding: 10px;
  color: ${(props) => props.theme.darkBlue};
  &:focus {
    outline: none !important;
    border: 1px solid ${(props) => props.theme.blue};
  }
`;

export const Label = styled.label`
  color: ${(props) => props.theme.darkBlue};
  text-align: left;
  margin: 0;
  ${(props) =>
    props.vertical
      ? `grid-row: 1;
  font-size: 12px;`
      : null}
`;

export const SelectLabel = styled.label`
  color: ${(props) => props.theme.darkBlue};
  background-image: linear-gradient(bottom, white 60%, '#f2f2f2' 40%);
  background-image: -o-linear-gradient(bottom, white 60%, '#f2f2f2' 40%);
  background-image: -moz-linear-gradient(bottom, white 60%, '#f2f2f2' 40%);
  background-image: -webkit-linear-gradient(bottom, white 60%, '#f2f2f2' 40%);
  background-image: -ms-linear-gradient(bottom, white 60%, '#f2f2f2' 40%);
  text-align: left;
  margin: 0px 4px 0px 4px;
  top: ${(props) => (props.inLine ? '11px' : '0px')};
  position: relative;
  z-index: 3;
  font-size: 12px;

  ${({ whiteBackground }) =>
    whiteBackground &&
    `
    background-color: white;
  `}
`;

export const ErrorMessage = styled.div`
  font-size: 12px;
  line-height: 12px;
  color: rgb(244, 67, 54);
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  padding-top: 5px;
  font-weight: normal;
`;

export const StyledMultiselect = styled(Multiselect)`
  font-size: 1em !important;
  border: none;
  border-radius: none;
  height: 100%;
  width: 100%;
  padding: 0px 5px 5px 5px;
  .rw-list {
    max-height: 158px;
  }
  .rw-select-list {
    /* background-color: red; */
  }
  .rw-input {
    /* background-color: yellow; */
  }
  .rw-virtual-list-wrapper {
    /* background-color: green; */
  }
`;

export const TransactionsTitle = styled.h5`
  font-weight: 600;
  margin: 16px 0;
  font-size: 1.25rem;
  text-align: center;
  color: #1f3354;
`;

export const Title = styled.div`
  text-align: center;
  font-weight: bold;
  font-size: 38px;
`;

export const Wrapper = styled.div`
  padding: 0 37px 37px 37px;
`;

export const ParamsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
`;

export const DatePickerWrapper = styled.div`
  display: grid;
  grid-gap: 15px;
  grid-template-columns: 1fr 1fr;
  align-items: flex-end;
`;

export const DatePickerLabel = styled.p`
  font-weight: bold;
  width: 60%;
  text-align: left;
  margin: 0;
`;

export const HrLine = styled.hr`
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
`;

export const TimePicker = styled(DateTimePicker)`
  font-size: 12px;
  font-weight: bold;
  color: ${(props) => props.theme.darkBlue} !important;

  input {
    color: ${(props) => props.theme.darkBlue} !important;
    font-weight: bold !important;
  }
  .rw-input {
    background-color: ${(props) => props.theme.lightGray};
  }
  .rw-input[readonly] {
    cursor: default;
  }
  .rw-widget-container {
    background-color: #ccc;
    border: none !important;
    border-radius: 0px !important;
    min-width: 100px;
  }
  .rw-select-bordered {
    background-color: ${(props) => props.theme.lightGray};
  }
  .rw-widget-input,
  .rw-filter-input {
    box-shadow: none;
  }
`;

export const StyledNumberPicker = styled(NumberPicker)`
  .rw-widget-container {
    background-color: ${(props) => props.theme.lightGray};
    border-radius: 0px;
    border: ${(props) => props.theme.borderBlue};
  }
  .rw-input {
    background-color: ${(props) => props.theme.lightGray};
    box-shadow: none;
    color: hsl(0, 0%, 20%);
    font-weight: 800;
  }

  .rw-select-bordered {
    border-left: ${(props) => props.theme.borderBlue};
    background-color: ${(props) => props.theme.lightGray};
  }
`;

export const StyledDateTimePicker = styled(DateTimePicker)`
  .rw-widget-container {
    background-color: ${(props) => props.theme.white};
    border-radius: 4px;
    border: ${(props) => props.theme.border};
    min-height: 60px;
    :focus {
      border: ${(props) => props.theme.blackBorder};
    }
  }
  .rw-input {
    background-color: ${(props) => props.theme.white};
    box-shadow: none;
    color: hsl(0, 0%, 20%);
    font-weight: 800;
  }
  .rw-select-bordered {
    border-left: ${(props) => props.theme.border};
    background-color: ${(props) => props.theme.white};
  }
  .rw-input[readonly] {
    cursor: default;
  }
`;
