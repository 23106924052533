import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as tagsActions from './tagsActions';
import BreadCrumb from '../../components/BreadCrumb/BreadCrumb';
import TagIndex from './components/TagIndex';
import TagForm from './components/TagForm';
import { Modal } from 'merchant-portal-components';
import ConfirmDeleteModal from '../../components/Modal/ConfirmDeleteModal';
import { Button, ButtonRow } from 'merchant-portal-components';

class TagManagerContainer extends Component {
  componentDidMount() {
    track.page('app-settings-tags');
  }
  handleSubmit = (formValues) => {
    const { tagsReducer, editTag, createTag } = this.props;
    const { isEditing, tagToEdit } = tagsReducer;
    if (isEditing) {
      editTag(formValues, tagToEdit.tag_id);
    } else {
      createTag(formValues);
    }
  };

  transformDetails = (details) => {
    if (details) {
      return Object.keys(details).map((detailKey) => ({
        key: detailKey,
        value: details[detailKey],
      }));
    } else {
      return [];
    }
  };

  componentWillUnmount() {
    this.props.resetTagsReducerState();
  }

  render() {
    const {
      tagsReducer,
      showTagForm,
      showDeleteTagModal,
      hideDeleteTagModal,
      deleteTag,
    } = this.props;

    const {
      allTags,
      tagFormIsVisible,
      tagToEdit,
      tagIdToDelete,
      requestingDeleteTag,
      deleteTagModalIsVisible,
      isEditing,
      requestingCreateTag,
      requestingEditTag,
    } = tagsReducer;

    const initialValues = isEditing
      ? {
          name: tagToEdit.name,
          value: tagToEdit.value,
          type: tagToEdit.type,
          detail: this.transformDetails(tagToEdit.detail),
        }
      : { type: 'product', detail: [] };
    return (
      <div>
        <div style={{ display: 'grid', gridTemplateColumns: '2fr 1fr' }}>
          <BreadCrumb pageName="Tags" />
          <div
            style={{
              margin: 'auto',
              marginRight: 0,
            }}
          >
            <ButtonRow justifyContent="flex-end">
              <Button onClick={() => showTagForm()}>Create Tag</Button>
            </ButtonRow>
          </div>
        </div>

        {allTags.length > 0 && (
          <TagIndex
            allTags={allTags}
            showTagForm={showTagForm}
            showDeleteTagModal={showDeleteTagModal}
          />
        )}
        {tagFormIsVisible && (
          <Modal fullWidth>
            <TagForm
              initialValues={initialValues}
              onSubmit={this.handleSubmit}
              isRequesting={requestingCreateTag || requestingEditTag}
            />
          </Modal>
        )}
        {deleteTagModalIsVisible && (
          <ConfirmDeleteModal
            cancel={hideDeleteTagModal}
            deleteAction={() => deleteTag(tagIdToDelete)}
            requestingDelete={requestingDeleteTag}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tagsReducer: state.tagsReducer,
  };
};

export default connect(mapStateToProps, tagsActions)(TagManagerContainer);
