import React from 'react';
import styled from 'styled-components';
import { PatternFormat } from 'react-number-format';
import {
  SectionWrapper,
  SectionTitle,
  InfoWrapper,
  InfoTitle,
  InfoDesc,
} from './styles/styles';

const StyledGuestSpan = styled.span`
  color: black;
`;
const CustomerInfo = ({ orderDetails }) => {
  return (
    <SectionWrapper>
      <SectionTitle>Customer Information</SectionTitle>
      <InfoWrapper>
        <div>
          <InfoTitle>Customer</InfoTitle>
          <InfoDesc>{orderDetails?.customerName}</InfoDesc>
          <InfoDesc>
            {orderDetails?.customerEmail}{' '}
            {orderDetails?.isGuestCustomer && (
              <StyledGuestSpan>(Guest)</StyledGuestSpan>
            )}
          </InfoDesc>

          <InfoDesc>
            <PatternFormat
              value={orderDetails?.customerPhone}
              format='+1 (###) ###-####'
              allowEmptyFormatting
              mask='_'
              style={{
                border: 'none',
                fontSize: '14px',
              }}
              displayType='text'
            />
          </InfoDesc>
        </div>
        <div>
          <InfoTitle>Company Name</InfoTitle>
          <InfoDesc>{orderDetails?.companyName}</InfoDesc>
        </div>
        {orderDetails?.customerAddress && (
          <div>
            <InfoTitle>Delivery Address</InfoTitle>
            <InfoDesc>{orderDetails?.customerAddress}</InfoDesc>
          </div>
        )}
      </InfoWrapper>
    </SectionWrapper>
  );
};

export default CustomerInfo;
