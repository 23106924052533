import React from "react";
import {
    TrHead,
    Th,
} from '../../../../components/Table/TableElements';


function TableHeader(props) {

    const {
        columns,
    } = props;

    return (
        columns ?
            <TrHead>
                {
                    columns.map((data) =>
                        <Th align='left'>{data}</Th>)
                }
            </TrHead>
        : null
    )
}

export default TableHeader;