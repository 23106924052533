import {
  GET_APP_INTGERATION_CONFIG_SCHEMA,
  UPDATE_APP_INTEGRATION_CONFIG,
} from './AppIntegrationTypes';
import { api } from '../../api';
import { createAlert } from '../../modules/Alert/actions';
import flat from 'flat';

const TRANSACTIONAL_EMAIL = 'transactional_email';

const formatAppIntegration = (rawAppIntegrationConfig, delimiter) => {
  const appIntegrationToHide = [
    'firebase',
    'media',
    'push_android',
    'push_ios',
  ];

  let formattedAppIntegration = {};
  let flattenConfig = {};
  rawAppIntegrationConfig
    .filter((integration) => !appIntegrationToHide.includes(integration.type))
    .map((config) => {
      flattenConfig = flat(config.configuration, { delimiter: delimiter });
      formattedAppIntegration[config.type] = {
        ...config,
        configuration: { ...flattenConfig },
      };
    });

  return formattedAppIntegration;
};

export function getAppIntegrationConfig(delimiter) {
  return function (dispatch) {
    api
      .get('/app-integrations?page_size=1000&page_number=1')
      .then((response) => {
        dispatch({
          type: GET_APP_INTGERATION_CONFIG_SCHEMA,
          appIntegration: formatAppIntegration(response.data.data, delimiter),
        });
      })
      .catch((error) => console.log(error));
  };
}

export function updateAppIntegrationConfig(
  updatedAppIntegration,
  appIntegrationPatchSchema,
  delimiter
) {
  const { id, type } = updatedAppIntegration;

  const { configuration } = updatedAppIntegration;
  let unFlattenedConfig = flat.unflatten(configuration, {
    delimiter: delimiter,
  });

  if (type == TRANSACTIONAL_EMAIL) {
    unFlattenedConfig = {
      ...unFlattenedConfig,
      templates: unFlattenedConfig.templates.reduce((acc, template) => {
        return (acc = { ...acc, ...template });
      }, {}),
    };

    const templates = unFlattenedConfig.templates;
    Object.keys(templates).forEach((key) => {
      templates[key] = Object.keys(templates[key]).reduce((acc, key1) => {
        if (
          (key1 === 'bcc' && !templates[key][key1]) ||
          (key1 === 'cc' && !templates[key][key1])
        ) {
          acc[key1] = [];
        } else {
          acc[key1] = templates[key][key1];
        }

        return acc;
      }, {});
    });
  }

  let appIntegrationPayload = {};
  appIntegrationPatchSchema.map((key) => {
    if (key == 'configuration') {
      appIntegrationPayload[key] = { ...unFlattenedConfig };
    } else {
      appIntegrationPayload[key] = updatedAppIntegration[key];
    }
  });

  return (dispatch) => {
    api
      .patch(`/app-integrations/${id}`, appIntegrationPayload, {
        maxRedirects: 0,
      })
      .then((response) => {
        dispatch({
          type: UPDATE_APP_INTEGRATION_CONFIG,
          updatedAppIntegration: updatedAppIntegration,
        });
        dispatch(
          createAlert({
            type: 'success',
            message: `${updatedAppIntegration.type.split('_').join(' ')} - ${
              updatedAppIntegration.provider
            } configurations updated successfully`,
          })
        );
      })
      .catch((error) => console.log(error));
  };
}
