import React from "react";
import { connect } from "react-redux";
import { reduxForm, getFormValues, Field } from "redux-form";
import Modal from "../../../../../components/Modal/Modal";
import { Button } from "../../../../../components/Button/Button";
import styled from "styled-components";
import {
  renderTextField,
  required,
  maxLength
} from "../../../../../components/FormElements/ReduxForm";
import {
  Title,
  ModalBody
} from "../../../../../components/FormElements/FormElements";

const HorizontalLine = styled.hr`
  width: 100%;
  border: 0;
  border-top: 2px solid #d8d8d8;
  opacity: 0.4;
`;

const Content = styled.div`
  padding: 0 150px;
`;

const Notes = styled.div`
  font-weight: 600;
  padding: 0 0 10px 0;
`;

const RewardsForm = styled.div`
  padding: 20px 0 0 0;
`;

const TypeAndAmountWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
`;

const RewardType = styled.p`
  font-weight: 600;
  padding: 10px;
`;

const NotesFieldWrapper = styled.div`
  padding: 30px 0;
`;

const CannotBeUndoneMessage = styled.div`
  text-align: center;
  color: ${props => props.theme.blue};
  font-size: 14px;
`;

const AreYouSureMessage = styled.div`
  text-align: center;
  color: ${props => props.theme.darkBlue};
  font-weight: 600;
`;

const ButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 50px;
  padding: 30px 0 0 0;
`;

const NevermindWrapper = styled.div`
  text-align: right;
`;

// limiting to 400 because also attaching login name to API to keep track
// API limits comments chars to 512. leaving 112 chars for login name

const notesMaxChars = maxLength(400);

export let SendRewardsModal = props => {
  const {
    customerName,
    handleSendRewardsClick,
    toggleModalVisibility,
    formValues,
    isRequesting,
    typeOfReward,
    amountRange,
    normalize
  } = props;
  return (
    <Modal data-test="component-Modal">
      <div className="modal">
        <ModalBody padding="30px 0 40px 0">
          <Content>
            <Notes data-test="notes-customerName">
              You are about to send {typeOfReward} to {customerName}
            </Notes>

            <RewardsForm>
              <TypeAndAmountWrapper>
                <div>
                  <p>Type Of Reward</p>
                  <RewardType>{typeOfReward}</RewardType>
                </div>
                <div>
                  <p>*Amount of Reward</p>
                  <Field
                    data-test="field-amountOfReward"
                    name="amountOfReward"
                    validate={[required, amountRange]}
                    component={renderTextField}
                    normalize={normalize}
                  />
                </div>
              </TypeAndAmountWrapper>
              <NotesFieldWrapper>
                <p>*Notes</p>
                <Field
                  data-test="field-rewardNotes"
                  validate={[required, notesMaxChars]}
                  name="rewardNotes"
                  component={renderTextField}
                  rows={4}
                />
              </NotesFieldWrapper>
            </RewardsForm>
            <CannotBeUndoneMessage>
              This action cannot be undone
            </CannotBeUndoneMessage>
            <AreYouSureMessage>
              Are you sure you want to send?
            </AreYouSureMessage>
            <ButtonsWrapper>
              <NevermindWrapper>
                <Button
                  data-test="button-nevermind"
                  type="button"
                  secondary
                  onClick={toggleModalVisibility}
                >
                  CANCEL
                </Button>
              </NevermindWrapper>
              <div>
                <Button
                  data-test="button-send"
                  type="button"
                  onClick={() => handleSendRewardsClick(props.formValues)}
                  disabled={
                    isRequesting ||
                    !formValues ||
                    (formValues &&
                      (required(formValues.amountOfReward) ||
                        required(formValues.rewardNotes) ||
                        amountRange(formValues.amountOfReward)))
                  }
                >
                  {isRequesting ? "POSTING..." : "YES, SEND"}
                </Button>
              </div>
            </ButtonsWrapper>
          </Content>
        </ModalBody>
      </div>
    </Modal>
  );
};

let SendRewardsModalForm = reduxForm({
  form: "sendRewardsModalForm",
  destroyOnUnmount: true
})(SendRewardsModal);

SendRewardsModalForm = connect(state => ({
  formValues: getFormValues("sendRewardsModalForm")(state)
}))(SendRewardsModalForm);

export default SendRewardsModalForm;
